import { useEffect, useState, useContext } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import ModalPlantillaMotivoTicket from "../../modals/plantillas/ModalPlantillaMotivoTicket";
import { SocketContext } from "../../context/SocketContext";
import MotivoTicketService from "../../services/motivoTicket.service";
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const PlantillaMotivoTicketsScreen = () => {
    const [items, setItems] = useState([]);
    const { sockets } = useContext( SocketContext );

    const actualizar = async() =>{
        await MotivoTicketService.getAll({all: true,emp: 'nubicom'}).then((resp)=>{
            setItems(resp)
        })
    }
    const columns = [
        {
            name: "id_mt",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "motivo_mt",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cuerpo_mt",
            label: "Descripcion",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_mt',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                    return <Chip label="Activo" color="success" />
                    }
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { !tableMeta.rowData[3] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];

    const onGuardar = () =>{
        actualizar();
        sockets?.emit('newMotivoTK');
    }

    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_mt === item)
            showModal(ModalPlantillaMotivoTicket, {item: searchItem, onGuardar: onGuardar, type: 'Nubicom SRL'});
        }else{
            showModal(ModalPlantillaMotivoTicket, {onGuardar: onGuardar, type: 'Nubicom SRL'});
        }
    };

    const onEliminar = (itemID) => {
        const searchItem = items.find((sItem)=> sItem.id_mt === itemID);
        if(searchItem?.id_mt){
            showModal(ConfirmationDialog, {
                title: `${searchItem?.isDeleted_pmail ? 'Activar' : 'Eliminar'} plantilla de ticket`,
                message: `¿Esta seguro que desea ${searchItem?.isDeleted_pmail ? 'activar' : 'eliminar'} la plantilla de ticket?`,
                onConfirm: async() => {
                    await MotivoTicketService.destroy(searchItem.id_mt).then(() => {
                        actualizar();
                    });
                }
            });
        }
    };

    useEffect(() => {
        actualizar()
        document.title = 'Plantilla de Tickets'
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3}>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Plantilla de Tickets</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PlantillaMotivoTicketsScreen