import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectEstadoReprogramar = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps,
    isMulti
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const options = [
        {
            value: 'SITUACION CLIMATICA',
            label: 'SITUACION CLIMATICA'
        },
        {
            value: 'PENDIENTE DE EQUIPO',
            label: 'PENDIENTE DE EQUIPO',
        },
        {
            value: 'INSTALACION CANCELADA',
            label: 'INSTALACION CANCELADA'
        },
        {
            value: 'PENDIENTE POR MATERIALES/SOPORTE',
            label: 'PENDIENTE POR MATERIALES/SOPORTE',
        },
        {
            value: 'NO SE ENCONTRO EL DOMICILIO',
            label: 'NO SE ENCONTRO EL DOMICILIO'
        },
        {
            value: 'CLIENTE AUSENTE',
            label: 'CLIENTE AUSENTE',
        },
        {
            value: 'CANCELADO POR EL EMPLEADO',
            label: 'CANCELADO POR EL EMPLEADO'
        },
        {
            value: 'POR OPERACIONES',
            label: 'POR OPERACIONES',
        },
        {
            value: 'CORTE DE RUTA',
            label: 'CORTE DE RUTA',
        },
        {
            value: 'POR FALLA DE VEHICULO',
            label: 'POR FALLA DE VEHICULO',
        },
        {
            value: 'ACCIDENTE DEL PERSONAL TECNICO',
            label: 'ACCIDENTE DEL PERSONAL TECNICO',
        },
        {
            value: 'POR AUSENCIA DEL PERSONAL TECNICO',
            label: 'POR AUSENCIA DEL PERSONAL TECNICO',
        },
        {
            value: 'NODO CAIDO',
            label: 'NODO CAIDO',
        },
        {
            value: 'CORTE DE LUZ ZONAL O DOMICILIO',
            label: 'CORTE DE LUZ ZONAL O DOMICILIO',
        },
        {
            value: 'POR PRIORIDAD TAREAS',
            label: 'POR PRIORIDAD TAREAS',
        },
        {
            value: 'OTROS',
            label: 'OTROS',
        },
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = options.find(it=>it.value === va);
                if(item){
                    return {
                        value:item.value,
                        label:item.label
                    };
                }
                return null
            }))
        }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectEstadoReprogramar.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectEstadoReprogramar;