import {useMemo, useState} from 'react';
import {ButtonBasic, ConsultaCard, SelectEstadoCliente, SelectEstadoConsulta, SelectEstadoNoLeInteresa, SelectServicioSoftland} from '../../components/index';
import { useNavigate } from 'react-router-dom';
import ConsultasService from '../../services/consultas.service';
import { Box, Divider, Grid, Typography, IconButton, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {toast} from 'react-toastify';

const ModalConsultaContacto = ({
    show,
    hideModal,
    item,
    nueva,
    calendar,
    cuenta,
    nombreCuenta,
    onGuardar,
    direccionCompleta,
    coordCompleta,
    estadoCliente
}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState(null);
    const [estadoId, setEstadoId] = useState(estadoCliente);
    const [subEstadoId, setSubEstadoId] = useState(null);
    const [observaciones, setObservaciones] = useState('');
    const handleClose = () => hideModal();
    const onSubmit = async(data) => {
        if(estadoId === 'No le interesa el servicio' && !subEstadoId){
            toast.error('Debe seleccionar un Sub-Estado.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
            return '';
        }
        setIsLoading(true);
        const form = {
            ...data,
            planConsulta_con:plan,
            observaciones_con: observaciones,
            estadoConsulta_con: estadoId
        };
        if(item?.tipoMapa === 'fibra'){
            form.observaciones_con = `${observaciones}. Consulta realizada con la cobertura de FO.`
        }
        if(item && item?.cuenta){
            form.nombreContacto_con = item?.nombre_c;
            form.contactoCuenta_con = item?.cuenta;
        }else if(item && item?.id_cxe && item?.id_c){
            form.contactEmpresa_con= item.id_cxe;
            form.contactoId_con= item.id_c;
            form.nombreContacto_con = item?.nombreCompleto_c;
        }
        if(cuenta){
            form.contactoCuenta_con = cuenta;
            form.nombreContacto_con = nombreCuenta;
        }
        if(estadoId === 'No le interesa el servicio'){
            form.subEstadoConsulta_con = subEstadoId;
        }
        if(direccionCompleta){
            form.direccionConsulta_con = direccionCompleta;
        }
        if(coordCompleta){
            form.coordenadasConsulta_con = coordCompleta;
        }
        if(item?.cobertura > 0){
            form.bajadaMaxMb_con = item?.cobertura;
        }
        if(item?.canal_con){
            form.canal_con = item?.canal_con;
        }else{
            form.canal_con = '01';
        }
        if(item?.segmento_con){
            form.segmento_con = item?.segmento_con;
        }else{
            form.segmento_con = 'MAS';
        }
        if(item?.lugarId_t || item?.lugarId_c){
            form.localidadId_con = item?.lugarId_t || item?.lugarId_c;
        }
        await ConsultasService.register(form).then(()=>{
            toast.success('Se registro la consulta correctamente.', {
                position: "top-right",
                autoClose: 3500,
                pauseOnHover: false,
            });
            handleClose();
            if(cuenta){
                onGuardar()
            }else{
                navigate('/contactos');
            }
        })
        .catch(()=>{
            setIsLoading(false);
        })
    };

    const onClosed = () =>{
        handleClose()
        /* if((!calendar && !cuenta)){
            navigate('/contactos')
        } */
    }
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                }
            },
            color: 'gray',
        }
    }), [])
    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {item ? 'Detalle de la Consulta' : 'Registrar Consulta'}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 1}} />
                <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: 'column',width: '100%',padding:1}}>
                    { nueva ? (
                        <Box sx={{width: '100%'}}>
                            <Typography sx={{color: '#000'}}>Motivo de consulta</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInput}
                                multiline
                                value={observaciones}
                                onChange={(e)=>setObservaciones(e.target.value)}
                            />
                            <Typography sx={{color: '#000'}}>Estado</Typography>
                            <SelectEstadoConsulta
                                name='estadoId'
                                id='estadoId'
                                value={estadoId}
                                todos
                                onChange={(pl)=>setEstadoId(pl.value)}
                                disabled={(estadoCliente === 'Sin Disponibilidad para la venta' || estadoCliente === 'Sin Cobertura')}
                            />
                            {estadoId === 'No le interesa el servicio' &&(
                                <>
                                    <Typography sx={{color: '#000'}}>Sub-Estado</Typography>
                                    <SelectEstadoNoLeInteresa
                                        name='subEstadoId'
                                        id='subEstadoId'
                                        value={subEstadoId}
                                        onChange={(pl)=>setSubEstadoId(pl.value)}
                                    />
                                </>
                            )}
                            <Typography sx={{color: '#000'}}>Plan</Typography>
                            <SelectServicioSoftland
                                name='planId'
                                id='planId'
                                value={plan}
                                onChange={(pl)=>setPlan(pl.value)}
                            />
                        </Box>
                    ):(
                        <ConsultaCard item={item} />
                    )}
                </Box>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1,marginBottom:0.5}}>
                    {nueva ?(
                        <>
                            <ButtonBasic
                                onClick={()=>onClosed()}
                                textButton='CANCELAR'
                                variant='wk'
                                color='cancel'
                            />
                            <ButtonBasic
                                onClick={()=>onSubmit()}
                                isLoading={isLoading}
                                textButton='GUARDAR'
                                sx={{marginLeft: 1}}
                                variant='wk'
                                color='nextStep'
                                isDisabled={!estadoId || !plan}
                            />
                        </>
                    ):(
                        <ButtonBasic
                            onClick={()=>onClosed()}
                            textButton='CERRAR'
                            variant='wk'
                            color='cancel'
                        />
                    )}
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalConsultaContacto;