import axios from 'axios';
const base = 'pyme';

function registerPreServicio(data) {
    return axios.post([base, 'preservicio'].join('/'), data);
}

const PymeService = {
    registerPreServicio,
};
export default PymeService;