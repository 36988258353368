import { Box, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
    CardDashboardTotal,
    CardEstadoTicket,
    CardTotalEstadoTk,
    DateRange } from "../components";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TablaTicketService from "../services/tablaTicket.service";
import FechaFunction from "../function/FechaFunction";
import { useNavigate } from "react-router-dom";
import { showModal } from "react-redux-modal-provider";
import ContactosAction from '../store/actions/contactos';
import { connect } from "react-redux";
import ModalCrearTicketNubicomV2 from "../modals/ticket/ModalCrearTicketNubicomV2";

const HomeScreen = ({
    iniciarContacto,
    iniciarContrato
}) => {
    const [tickets, setTickets] = useState();
    const navigate = useNavigate();
    const onClickTicketNubi = () => {
        showModal(ModalCrearTicketNubicomV2);
    }
    const [fechaSelect, setFechaSelect] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    const actualizar = (data) => {
        TablaTicketService.countTicketTic(data).then((c)=>{
            setTickets(c)
        })
    }
    const nuevoContacto = () =>{
        iniciarContacto(null);
        iniciarContrato(null);
        navigate('/contactos/nuevonubi');
    }

    useEffect(() => {
        const fechas={
            fecIni:FechaFunction.format(fechaSelect.startDate, 'yyyy-MM-dd'),
            fecFin:FechaFunction.format(fechaSelect.endDate, 'yyyy-MM-dd'),
        }
        actualizar(fechas)
    }, [fechaSelect])
    useEffect(() => {
        document.title = `Zeta-CRM`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={20}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography className="text-gray-900 font-bold" variant="h1">Dashboard</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DateRange
                        minDate={new Date('2022-06-01')}
                        initianDate={fechaSelect}
                        onChange={(e)=>setFechaSelect(e)}
                    />
                </Grid>
                <Grid item xs={12} md={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:2}}>
                    {/* <ButtonBasic
                        variant="wk"
                        color="downloadHome"
                        textButton="Descargar"
                        iconStart={<SaveAltIcon />}
                        sx={{border: 1,background: '#fff',color: '#15294b'}}
                    /> */}
                </Grid>
                <Grid item xs={12} md={6} lg={3} p={1} mt={4} display='flex' justifyContent='center'>
                    <CardDashboardTotal
                        cantidad={tickets?.creados?.length || 0}
                        texto='Tickets Creados'
                        icon={<ConfirmationNumberOutlinedIcon sx={{color: '#0364ff',fontSize: 45}} />}
                        items={tickets?.creados}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} p={1} mt={4}  display='flex' justifyContent='center'>
                    <CardDashboardTotal
                        cantidad={tickets?.etiquetas?.length || 0}
                        texto='Etiquetas'
                        icon={<DiscountOutlinedIcon sx={{color: '#0364ff',fontSize: 45}} />}
                        items={tickets?.etiquetas}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} p={1} mt={4} display='flex' justifyContent='center'>
                    <CardDashboardTotal
                        cantidad={tickets?.notificacionesInd?.length || 0}
                        texto='Asig. Individual'
                        icon={<PersonOutlineOutlinedIcon sx={{color: '#0364ff',fontSize: 45}} />}
                        items={tickets?.notificacionesInd}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} p={1} mt={4} display='flex' justifyContent='center'>
                    <CardDashboardTotal
                        cantidad={tickets?.notificacionesGroup?.length || 0}
                        texto='Asig. Grupal'
                        icon={<GroupOutlinedIcon sx={{color: '#0364ff',fontSize: 45}} />}
                        items={tickets?.notificacionesGroup}
                    />
                </Grid>
                <Grid item xs={12} md={6} p={1} mt={4}>
                    <CardTotalEstadoTk
                        titulo='Tickets Abiertos'
                        total={tickets?.abiertos}
                        noVencidos={tickets?.abiertosNoVencidos}
                        vencidos={tickets?.abiertosVencidos}
                    />
                </Grid>
                <Grid item xs={12} md={6} p={1} mt={4}>
                    <CardTotalEstadoTk
                        titulo='Tickets Cerrados'
                        total={tickets?.cerrado}
                        noVencidos={tickets?.cerradoNoVencidos}
                        vencidos={tickets?.cerradoVencidos}
                    />
                </Grid>
                <Grid item xs={12} p={1} mt={4}>
                    <CardEstadoTicket estado={tickets} />
                </Grid>
            </Grid>
            <SpeedDial
                ariaLabel="addSpeed"
                sx={{
                    position: 'absolute',
                    bottom:{xs: 25, md: 30},
                    right:{xs: 20, md: 80},
                    zIndex: 99,
                    '&:hover':{
                        '& .MuiSpeedDialAction-staticTooltipLabel':{
                            background: '#e6f0ff',
                            marginRight: -1.2,
                            borderTopLeftRadius: 17,
                            borderBottomLeftRadius: 17,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            boxShadow: 0,
                            width:140,
                            height:49,
                            display:'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderLeft:2,
                            borderBottom: 2,
                            borderTop: 2,
                            color: '#0364ff',
                            borderColor: '#0364ff',
                        },
                        '& .MuiButtonBase-root.MuiFab-root.MuiFab-circular':{
                            background: '#e6f0ff',
                            boxShadow:0,
                        },
                        '& .MuiSpeedDialAction-staticTooltip.MuiSpeedDialAction-tooltipPlacementLeft':{
                            background: '#e6f0ff',
                            height:49,
                            marginBottom:1,
                            borderTopRightRadius:17,
                            borderRight:2,
                            borderBottom: 2,
                            borderTop: 2,
                            color: '#0364ff',
                            borderColor: '#0364ff',
                            zIndex: 9999
                        }
                    },
                    '& .MuiSpeedDialAction-staticTooltipLabel':{
                        background: '#e6f0ff',
                        marginRight: -1,
                        borderTopLeftRadius: 17,
                        borderBottomLeftRadius: 17,
                        boxShadow: 0,
                        width:140,
                        height:49,
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    '& .MuiButtonBase-root.MuiFab-circular':{
                        background: '#e6f0ff',
                        boxShadow:0
                    },
                    '& .MuiSpeedDialAction-staticTooltip.MuiSpeedDialAction-tooltipPlacementLeft':{
                        height:49,
                        marginBottom:1,
                        borderTopRightRadius:17
                    }
                }}
                icon={<SpeedDialIcon sx={{color: '#000'}} />}
                FabProps={{
                    sx: {
                        bgcolor: '#fff !important',
                        border: 3,
                        borderColor: '#dfe2e6',
                        '&:hover': {
                            bgcolor: '#fff !important',
                            boxShadow: 4,
                        },
                    }
                }}
            >
                <SpeedDialAction
                    key='addhistoria'
                    icon={<ConfirmationNumberIcon sx={{color: '#0364ff'}} />}
                    tooltipTitle='Nuevo Ticket'
                    onClick={()=>onClickTicketNubi()}
                    tooltipOpen
                />
                <SpeedDialAction
                    key='addAviso'
                    icon={<PersonAddAlt1Icon sx={{color: '#0364ff'}} />}
                    tooltipTitle='Nuevo Contacto'
                    onClick={()=>nuevoContacto()}
                    tooltipOpen
                />
            </SpeedDial>
        </Box>
    )
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);