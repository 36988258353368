import { useContext } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material'
import { SocketContext } from '../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FechaFunction from '../../function/FechaFunction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { showModal } from 'react-redux-modal-provider';
import ModalRecordatorioById from '../../modals/recordatorios/ModalRecordatorioById';
const CardNotifyGral = ({item,onCloseModal}) => {
    const navigate = useNavigate();
    const { sockets } = useContext( SocketContext );
    const redirect = ()=>{
        if(!item?.visto_notfg){
            sockets?.emit('newNotificationsGral', item?.id_notfg)
        }
        const separados = item?.redirect_notfg?.split('-');
        if(separados?.length > 0){
            switch (separados[0]) {
                case 'reportes':
                    navigate('/reports')
                    break;
                case 'recordatorios':
                    showModal(ModalRecordatorioById,{id: separados[1]})
                    break;
                default:
                    navigate('/')
                    break;
            }
            if(onCloseModal){
                onCloseModal()
            }
        }
    }
    return (
        <Card
            sx={{background: item?.visto_notfg ? '#fff !important' : '#e6f0ff !important', boxShadow: 3,marginTop: 2, cursor: 'pointer' }}
            onClick={()=>redirect()}
        >
            <Grid container p={2}>
                <Grid item xs={2} sx={{display:'flex',justifyContent: 'center',alignItems:'center',marginLeft:-2,marginRight:1}}>
                    {item.empresa_notfg === 'nubicom' &&(
                        <img src="/img/nubi-cirucular.png" alt="Icon" style={{width: '40px', height: '40px'}} />
                    )}
                    {item.empresa_notfg === 'ids' &&(
                        <img src="/img/ids-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_notfg === 'strong' &&(
                        <img src="/img/strong-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_notfg === 'cvv' &&(
                        <img src="/img/911-logo.png" alt="Icon" style={{width: '40px', height: '50px'}} />
                    )}
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#15294b',fontWeight: 'bold'}} component='div'>{item?.senduser?.nombre_p || ''} 
                                <Box fontWeight='normal' display='inline'>{item?.descripcion_notfg}</Box>
                            </Typography>
                        </Box>
                        <Box display='flex'>
                            <AccessTimeOutlinedIcon sx={{color:'gray'}} />
                            <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.createdAt))}</Typography>
                        </Box>
                        {item?.visto_notfg && (
                            <Box display='flex'>
                                <RemoveRedEyeIcon sx={{color:'gray'}} />
                                <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.visto_notfg))}</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardNotifyGral


