import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputDropZone, InputDropZoneSLimite, InputEditQuill } from '../../components';
import ProcesosService from '../../services/procesos.service';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ModalProcesos = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [html1, setHtml1] = useState(null);
    const [html2, setHtml2] = useState(null);
    const [video1, setVideo1] = useState(null);
    const [video2, setVideo2] = useState(null);
    const [video3, setVideo3] = useState(null);

    const [formAll, setFormAll] = useState({
        nombre_pro: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        descripcion_pro: {
            texto: '',
            textoErr: 'La descripcion es requerida.',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {createdEmp_mt: 'NUBICOM'};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_pro','descripcion_pro']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(html1){
                form.html1_pro = html1
            }else{
                form.html1_pro = 'vacio'
            }
            if(html2){
                form.html2_pro = html2
            }else{
                form.html2_pro = 'vacio'
            }
            if(video1){
                form.video1_pro = video1
            }else{
                form.video1_pro = 'vacio'
            }
            if(video2){
                form.video2_pro = video2
            }else{
                form.video2_pro = 'vacio'
            }
            if(video3){
                form.video3_pro = video3
            }else{
                form.video3_pro = 'vacio'
            }
            if(item?.id_pro > 0) {
                form.id_pro = item.id_pro
                ProcesosService.update(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }else{
                ProcesosService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.nombre_pro,'nombre_pro');
                onChangeForm(item?.descripcion_pro || '','descripcion_pro');
                setHtml1(item?.html1_pro)
                setHtml2(item?.html2_pro)
                setVideo1(item?.video1_pro)
                setVideo2(item?.video2_pro)
                setVideo3(item?.video3_pro)
            }, 100);
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_pro > 0 ? 'Editar Proceso' : 'Registrar Proceso'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.nombre_pro.texto}
                                error={formAll.nombre_pro.estadoErr}
                                helperText={formAll.nombre_pro.estadoErr && formAll.nombre_pro.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'nombre_pro')}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Descripcion</Typography>
                            <InputEditQuill
                                template={formAll.descripcion_pro.texto}
                                onChangeText={(e)=>onChangeForm(e, 'descripcion_pro')}
                            />
                            {/* <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.descripcion_pro.texto}
                                error={formAll.descripcion_pro.estadoErr}
                                helperText={formAll.descripcion_pro.estadoErr && formAll.descripcion_pro.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'descripcion_pro')}
                                sx={styleInput}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            {!html1 ? (
                                <>
                                    <Typography sx={{color: '#000'}}>Diagrama 1</Typography>
                                    <Box sx={{width: 172}}>
                                        <InputDropZone
                                            id="html1"
                                            accept='text/html'
                                            defaultImage={html1}
                                            onChange={setHtml1}
                                            setFoto={setHtml1}
                                            isDeletedNow={!html1}
                                            backgroundImage="fileUpload"
                                        />
                                    </Box>
                                </>
                            ):(
                                <>
                                    <Typography sx={{color: '#000'}}>Diagrama 1</Typography>
                                    <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                        <img src='/img/diagramaFlujo.jpg' width={190} height={170} />
                                        <IconButton
                                            sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                            onClick={()=>setHtml1(null)}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                        </IconButton>
                                    </Card>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            {!html2 ? (
                                <>
                                    <Typography sx={{color: '#000'}}>Diagrama 2</Typography>
                                    <Box sx={{width: 172}}>
                                        <InputDropZone
                                            id="html2"
                                            accept='text/html'
                                            defaultImage={html2}
                                            onChange={setHtml2}
                                            setFoto={setHtml2}
                                            isDeletedNow={!html2}
                                            backgroundImage="fileUpload"
                                        />
                                    </Box>
                                </>
                            ):(
                                <>
                                    <Typography sx={{color: '#000'}}>Diagrama 2</Typography>
                                    <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                        <img src='/img/diagramaFlujo.jpg' width={190} height={170} />
                                        <IconButton
                                            sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                            onClick={()=>setHtml2(null)}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                        </IconButton>
                                    </Card>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            {!video1 ? (
                                <>
                                    <Typography sx={{color: '#000'}}>Video 1</Typography>
                                    <Box sx={{width: 172}}>
                                        <InputDropZoneSLimite
                                            id="video1"
                                            accept='video/*'
                                            defaultImage={video1}
                                            onChange={setVideo1}
                                            setFoto={setVideo1}
                                            isDeletedNow={!video1}
                                            backgroundImage="fileUpload"
                                        />
                                    </Box>
                                </>
                            ):(
                                <>
                                    <Typography sx={{color: '#000'}}>Video 1</Typography>
                                    <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                        <img src='/img/videoPlay.jpg' width={190} height={170} />
                                        <IconButton
                                            sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                            onClick={()=>setVideo1(null)}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                        </IconButton>
                                    </Card>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            {!video2 ? (
                                <>
                                    <Typography sx={{color: '#000'}}>Video 2</Typography>
                                    <Box sx={{width: 172}}>
                                        <InputDropZoneSLimite
                                            id="video2"
                                            accept='video/*'
                                            defaultImage={video2}
                                            onChange={setVideo2}
                                            setFoto={setVideo2}
                                            isDeletedNow={!video2}
                                            backgroundImage="fileUpload"
                                        />
                                    </Box>
                                </>
                            ):(
                                <>
                                    <Typography sx={{color: '#000'}}>Video 2</Typography>
                                    <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                        <img src='/img/videoPlay.jpg' width={190} height={170} />
                                        <IconButton
                                            sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                            onClick={()=>setVideo2(null)}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                        </IconButton>
                                    </Card>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            {!video3 ? (
                                <>
                                    <Typography sx={{color: '#000'}}>Video 3</Typography>
                                    <Box sx={{width: 172}}>
                                        <InputDropZoneSLimite
                                            id="video3"
                                            accept='video/*'
                                            defaultImage={video3}
                                            onChange={setVideo3}
                                            setFoto={setVideo3}
                                            isDeletedNow={!video3}
                                            backgroundImage="fileUpload"
                                        />
                                    </Box>
                                </>
                            ):(
                                <>
                                    <Typography sx={{color: '#000'}}>Video 3</Typography>
                                    <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                        <img src='/img/videoPlay.jpg' width={190} height={170} />
                                        <IconButton
                                            sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                            onClick={()=>setVideo3(null)}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                        </IconButton>
                                    </Card>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalProcesos