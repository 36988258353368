import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import TicketService from "../../services/tickets.service";
import CardViewTicket from "../card/CardViewTicket";
import TablaTicketService from "../../services/tablaTicket.service";
import CardSearchNav from "../card/CardSearchNav";

const TabAllSearch = ({textSearch,handleClose}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [resultSearch, setResultSearch] = useState(false);

    const handleSearchTicket = async() => {
        if(textSearch?.length >= 2){
            setIsLoading(true);
            await TablaTicketService.searchGlobal({search: textSearch}).then((data)=>{
                setOptions({
                    ticketsArr: data?.ticketsArr?.map((item) =>{
                        return {
                            ...item,
                            tituloCard: `${item.id_tk} - ${item.titulo_tk}`,
                            descripCard: item.cuerpo_tk,
                            empresaCard: item?.createdEmp_tk,
                            keyCard: `${item?.id_tk}Ticket`
                        }
                    }),
                    serviciosArr: data?.serviciosArr?.map((item) =>{
                        return {
                            ...item,
                            tituloCard: `${item?.id_t} - ${item?.nombre_t}`,
                            descripCard: item?.softland ? `Servicio en Softland` : '',
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_t}Ticket`
                        }
                    }),
                    preServiciosArr: data?.preServiciosArr?.map((item) =>{
                        return {
                            ...item,
                            tituloCard: `${item?.id_t} - ${item?.nombre_t}`,
                            descripCard: item?.softland ? `Servicio en Softland` : 'Pre-Servicio',
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_t}Ticket`
                        }
                    }),
                    cuentasArr: data?.cuentasArr?.map((item) =>{
                        if(!item?.id_c){
                            return {
                                ...item,
                                tituloCard: item?.nombre_completo,
                                descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                empresaCard: 'NUBICOM',
                                keyCard: `${item?.cuenta}Cuenta`
                            }
                        }
                        return {
                            ...item,
                            tituloCard: item?.nombreCompleto_c,
                            descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_c}Contacto`
                        }
                    }),
                    potClientesArr: data?.potClientesArr?.map((item) =>{
                        if(!item?.id_c){
                            return {
                                ...item,
                                tituloCard: item?.nombre_completo,
                                descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                empresaCard: 'NUBICOM',
                                keyCard: `${item?.cuenta}Cuenta`
                            }
                        }
                        return {
                            ...item,
                            tituloCard: item?.nombreCompleto_c,
                            descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_c}Contacto`
                        }
                    }),
                    tagsArr: data?.tagsArr?.map((item) =>{
                        return {
                            ...item,
                            tituloCard: `Ticket #${item?.nroTk}`,
                            descripCard: `${item?.comentario ? 'Comentario:' : 'Cuerpo:'} ${item?.cuerpo?.replace(/<[^>]*>?/gm, '')}`,
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.nroTk}${item?.comentario || 'tk'}`
                        }
                    })
                })
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        const delayWrite = setTimeout(() => {
            handleSearchTicket()
        }, 500)
        return () => clearTimeout(delayWrite)
    }, [textSearch])
    return (
        <Grid container>
            { !isLoading ? (
                <>
                    {((options?.ticketsArr && options?.ticketsArr?.length > 0)
                        || (options?.serviciosArr && options?.serviciosArr?.length > 0)
                        || (options?.preServiciosArr && options?.preServiciosArr?.length > 0)
                        || (options?.cuentasArr && options?.cuentasArr?.length > 0)
                        || (options?.potClientesArr && options?.potClientesArr?.length > 0)
                        || (options?.tagsArr && options?.tagsArr?.length > 0))
                    ? (
                        <>
                            {options?.ticketsArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h5">TICKETS</Typography>
                                    </Grid>
                                    {options?.ticketsArr?.map((tk)=>(
                                        <Grid item xs={12} key={`${tk?.id_tk}tk`}>
                                            <CardViewTicket key={tk?.id_tk} item={tk} onCloseModal={handleClose} />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {options?.serviciosArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h5">SERVICIOS</Typography>
                                    </Grid>
                                    {options?.serviciosArr?.map((option)=>(
                                        <Grid item xs={12} key={`${option?.keyCard}serv`}>
                                            <CardSearchNav
                                                item={option}
                                                descripcion={option?.descripCard}
                                                titulo={option?.tituloCard}
                                                key={option?.keyCard}
                                                onCLoseModal={handleClose}
                                                textSearch={textSearch}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {options?.preServiciosArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h5">PRE-SERVICIOS</Typography>
                                    </Grid>
                                    {options?.preServiciosArr?.map((option)=>(
                                        <Grid item xs={12} key={`${option?.keyCard}pserv`}>
                                            <CardSearchNav
                                                item={option}
                                                descripcion={option?.descripCard}
                                                titulo={option?.tituloCard}
                                                key={option?.keyCard}
                                                onCLoseModal={handleClose}
                                                textSearch={textSearch}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {options?.cuentasArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h5">CLIENTES</Typography>
                                    </Grid>
                                    {options?.cuentasArr?.map((option)=>(
                                        <Grid item xs={12} key={`${option?.keyCard}cue`}>
                                            <CardSearchNav
                                                item={option}
                                                descripcion={option?.descripCard}
                                                titulo={option?.tituloCard}
                                                key={option?.keyCard}
                                                onCLoseModal={handleClose}
                                                textSearch={textSearch}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {options?.potClientesArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h6">POTENCIALES CLIENTES</Typography>
                                    </Grid>
                                    {options?.potClientesArr?.map((option)=>(
                                        <Grid item xs={12} key={`${option?.keyCard}pcli`}>
                                            <CardSearchNav
                                                item={option}
                                                descripcion={option?.descripCard}
                                                titulo={option?.tituloCard}
                                                key={option?.keyCard}
                                                onCLoseModal={handleClose}
                                                textSearch={textSearch}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                            {options?.tagsArr?.length > 0 &&(
                                <>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold',ml:2,textDecoration: 'underline'}} variant="h6">TAGS</Typography>
                                    </Grid>
                                    {options?.tagsArr?.map((option)=>(
                                        <Grid item xs={12} key={`${option?.keyCard}tag`}>
                                            <CardSearchNav
                                                item={option}
                                                descripcion={option?.descripCard}
                                                titulo={option?.tituloCard}
                                                key={option?.keyCard}
                                                onCLoseModal={handleClose}
                                                textSearch={textSearch}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </>
                    ):(
                        <Grid item xs={12} key='sinTk'>
                            <Box sx={{background: '#ebedf0',p:2,borderRadius:3}}>
                                <Typography sx={{color: '#15294b',textAlign: 'center',marginTop:2,marginBottom:2}} variant='h5'>No se encontraron resultados</Typography>
                            </Box>
                        </Grid>
                    )}
                </>
            ):(
                <Grid item xs={12} key='circletabtk' sx={{display:'flex', justifyContent:'center', alignItems: 'center', minHeight:50}}>
                    <CircularProgress size={40} sx={{color: '#0364ff'}} />
                </Grid>
            )}
        </Grid>
    )
}

export default TabAllSearch