import { useContext } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material'
import { SocketContext } from '../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FechaFunction from '../../function/FechaFunction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const CardNotificationsMentions = ({item,onCloseModal}) => {
    const navigate = useNavigate();
    const { sockets } = useContext( SocketContext );
    const redirect = ()=>{
        if(!item?.visto_ment){
            sockets?.emit('newViewMentions', item?.id_ment)
        }
        if(onCloseModal){
            onCloseModal();
        }
        if(item?.empresa_ment === 'strong'){
            navigate(`/ticket/${item?.ticketId_ment}/strongsystems`)
        }else{
            navigate(`/ticket/${item?.ticketId_ment}/${item?.empresa_ment}`)
        }
    }
    return (
        <Card
            sx={{background: item?.visto_ment ? '#fff !important' : '#e6f0ff !important', boxShadow: 3,marginTop: 2, cursor: 'pointer' }}
            onClick={()=>redirect()}
        >
            <Grid container p={2}>
                <Grid item xs={2} sx={{display:'flex',justifyContent: 'center',alignItems:'center',marginLeft:-2,marginRight:1}}>
                    {item.empresa_ment === 'nubicom' &&(
                        <img src="/img/nubi-cirucular.png" alt="Icon" style={{width: '40px', height: '40px'}} />
                    )}
                    {item.empresa_ment === 'ids' &&(
                        <img src="/img/ids-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_ment === 'strong' &&(
                        <img src="/img/strong-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_ment === 'cvv' &&(
                        <img src="/img/911-logo.png" alt="Icon" style={{width: '40px', height: '50px'}} />
                    )}
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#15294b'}} component='div'>{item?.senduser?.nombre_p || ''} 
                                <Box fontWeight='bold' display='inline'>Te menciono en</Box>
                                {`${item?.commentId_ment ? 'un comentario d' : ''}`}el ticket
                                <Box fontWeight='bold' display='inline'>{` #${item?.ticketId_ment}`}</Box>
                            </Typography>
                        </Box>
                        <Box display='flex'>
                            <AccessTimeOutlinedIcon sx={{color:'gray'}} />
                            <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.createdAt))}</Typography>
                        </Box>
                        {item?.visto_ment && (
                            <Box display='flex'>
                                <RemoveRedEyeIcon sx={{color:'gray'}} />
                                <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.visto_ment))}</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardNotificationsMentions