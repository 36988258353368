import axios from 'axios';
const base = 'corpo';

function getAll(query=null){
    return axios.get(base,{ params: query });
}

function registerContacto(data) {
    return axios.post([base, 'contacto'].join('/'), data);
}

function updateContacto(data) {
    return axios.put([base, 'contacto', data.id_c].join('/'), data);
}

function registerPreServicio(data) {
    return axios.post([base, 'preservicio'].join('/'), data);
}

function registerContactoConsulta(data) {
    return axios.post([base, 'register'].join('/'), data);
}

const CorporativoService = {
    getAll,
    registerContacto,
    updateContacto,
    registerPreServicio,
    registerContactoConsulta
};
export default CorporativoService;