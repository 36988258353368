import { useState, useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, SelectGrupos, SelectUser, SelectTiempoRecordatorio, SelectCantTiempoRecordatorio } from '../../components';

function ModalAlertaRecordatorio({
    show,
    hideModal,
    setAlertasArr
}) {
    const handleClose = () => hideModal();
    const [tipo, setTipo] = useState('Grupo');
    const [tipoId, setTipoId] = useState(null);
    const [tipoIdNombre, setTipoIdNombre] = useState(null);
    const [tipoTiempo, setTipoTiempo] = useState('m');
    const [cantTiempo, setCantTiempo] = useState(1);
    const onSubmit = async() =>{
        const form = {
            id: uuidv4(),
            tipo_alerec: tipo,
            tipoId_alerec: tipoId,
            tipoTiempo_alerec: tipoTiempo,
            cantTiempo_alerec: cantTiempo,
            tipoIdNombre
        }
        let textAviso_alerec = '';
        switch (tipoTiempo) {
            case 'm':
                textAviso_alerec = `${cantTiempo} minuto${cantTiempo > 0 ? 's' : ''} antes`
                break;
            case 'd':
                textAviso_alerec = `${cantTiempo} día${cantTiempo > 0 ? 's' : ''} antes`
                break;
            case 'h':
                textAviso_alerec = `${cantTiempo} hora${cantTiempo > 0 ? 's' : ''} antes`
                break;
            default:
                break;
        }
        form.textAviso_alerec = textAviso_alerec;
        setAlertasArr((prev) => prev.concat([form]));
        hideModal()
    }

    const styleBtnSelect = useMemo(() => ({
        border:1,borderColor: '#15294b', color: '#15294b',background:'white',
        '&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleBtnNone = useMemo(() => ({
        background:'#dfe2e6',boxShadow:0,'&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nueva Alerta
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{flex:1,textAlign: 'start', color: '#000'}}>
                                Seleccione el tipo:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    minWidth: 200,
                                    background:'#dfe2e6',
                                    p:1,
                                    borderRadius:3,
                                    mb:2
                                }}
                            >
                                <ButtonBasic
                                    textButton='Usuario'
                                    sx={tipo === 'Usuario' ? styleBtnSelect : styleBtnNone}
                                    onClick={()=>{setTipoId(null); setTipo('Usuario')}}
                                />
                                <ButtonBasic
                                    textButton='Grupo'
                                    sx={[{marginLeft:0},tipo === 'Grupo' ? styleBtnSelect : styleBtnNone]}
                                    onClick={()=>{setTipoId(null); setTipo('Grupo')}}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {tipo === 'Usuario' ?(
                                <>
                                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',mb: 1}}>
                                        Seleccione el usuario:
                                    </Typography>
                                    <SelectUser
                                        filter='all'
                                        value={tipoId}
                                        onChange={(opt) => {setTipoIdNombre(opt?.label);setTipoId(opt?.value)}}
                                    />
                                </>
                            ):(
                                <>
                                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',mb: 1}}>
                                        Seleccione el grupo:
                                    </Typography>
                                    <SelectGrupos
                                        allGroup
                                        value={tipoId}
                                        onChange={(opt) => {setTipoIdNombre(opt?.label); setTipoId(opt?.value)}}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{flex:1,textAlign: 'start', color: '#000'}}>
                                Seleccione el tiempo:
                            </Typography>
                            <SelectTiempoRecordatorio
                                value={tipoTiempo}
                                onChange={(e)=>{setCantTiempo(1); setTipoTiempo(e.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{flex:1,textAlign: 'start', color: '#000'}}>
                                Seleccione la cantidad:
                            </Typography>
                            <SelectCantTiempoRecordatorio
                                tiempo={tipoTiempo}
                                value={cantTiempo}
                                onChange={(e)=>setCantTiempo(e.value)}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Divider sx={{background: 'gray'}} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalAlertaRecordatorio;