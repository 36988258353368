import { AppBar, Badge, Box, IconButton, Stack, styled, TextField, Toolbar, Tooltip, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NavigationMenu from '../menu/NavigationMenu';
import { ColorModeContext, tokens } from '../../themes/themes';
import { useContext } from 'react';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useState } from 'react';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { showModal } from 'react-redux-modal-provider';
import ModalNotificacionesZeta from '../../modals/notificaciones/ModalNotificacionesZeta';
import ModalNotificaciones911 from '../../modals/notificaciones/ModalNotificaciones911';
import MenuUsuarioNav from '../menu/MenuUsuarioNav';
import InputSearchNavV2 from '../inputs/InputSearchNavV2';
import usePermisos from '../../hooks/usePermisos';
import ModalAccionesDirEntrega from '../../modals/contacto/ModalAccionesDirEntrega';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
})
const Navbar = ({
  onOpenDrawerChange,
  onOpenDrawerChangeResponsive,
  onLogout,
  countNotifi,
  countNotifi911
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [selectInputSearch, setSelectInputSearch] = useState(null)
  const onOpenModalNotifyZeta = () => showModal(ModalNotificacionesZeta);
  const onOpenModalNotify911 = () => showModal(ModalNotificaciones911);
  const onOpenModalEditDirEnt = () => showModal(ModalAccionesDirEntrega);
  const permisos = usePermisos();

  return (
    <AppBar position='sticky' sx={{backgroundColor: '#fff',marginBottom:1,zIndex: 2}}>
      <StyledToolbar>
        {/* Sidebar Normal */}
        <Box sx={{flex:2, display: {xs: 'none',lg: 'block'}}}>
          <MenuIcon sx={{color: '#494949'}} onClick={()=>onOpenDrawerChange()} />
        </Box>
        {/* Sidebar Responsive */}
        <Box sx={{flex:1, display: {xs: 'block', lg: 'none'}}}>
          <MenuIcon sx={{color: '#494949'}} onClick={()=>onOpenDrawerChangeResponsive()} />
        </Box>
        <Box sx={{flex:3, display: {xs: 'none', md:'flex'}}}>
        <InputSearchNavV2
          value={selectInputSearch}
          onChange={(e)=>setSelectInputSearch(e.value)}
        />
        </Box>
        <Box sx={{flex:1, flexDirection: 'row', justifyContent: 'right'}}>
          <Stack direction='row' justifyContent='right'>
          {/* <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon color='info' />
            ) : (
              <LightModeOutlinedIcon color='info' />
            )}
          </IconButton> */}
          {/* <IconButton aria-label="police" onClick={()=>onOpenModalNotify911()}>
              <Badge
                badgeContent={countNotifi911 || 0}
                sx={{
                  '& span':{
                    '&.MuiBadge-badge':{
                      background: '#d55353',
                      color:'white'
                    }
                  }
                }}>
                <LocalPoliceIcon sx={{color: '#74767f'}} />
              </Badge>
          </IconButton> */}
              <Tooltip title='ACCIONES RAPIDAS'>
                <IconButton aria-label="dirEnt" onClick={()=>onOpenModalEditDirEnt()}>
                  <SettingsSuggestIcon sx={{color: '#74767f', fontSize: 25}} />
                </IconButton>
              </Tooltip>
            <IconButton aria-label="zeta" onClick={()=>onOpenModalNotifyZeta()} sx={{mr:1}}>
                <Badge
                  badgeContent={countNotifi || 0}
                  sx={{
                    '& span':{
                      '&.MuiBadge-badge':{
                        background: '#0364ff',
                        color:'white'
                      }
                    }
                  }}>
                  <NotificationsNoneOutlinedIcon sx={{color: '#74767f'}} />
                </Badge>
            </IconButton>
            <MenuUsuarioNav onLogout={onLogout} />
          </Stack>
        </Box>
      </StyledToolbar>
    </AppBar>
  )
}

export default Navbar