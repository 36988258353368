import { useRef, useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import InfoIcon from '@mui/icons-material/Info';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { useNavigate } from 'react-router-dom';
import ConsultasService from '../../services/consultas.service';
import { showModal } from 'react-redux-modal-provider';
import ModalConsultaCamDomicilio from '../../modals/consulta/ModalConsultaCamDomicilio';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import ModalChangeServicioTicket from '../../modals/ticket/ModalChangeServicioTicket';

const MenuOptServicio = ({servicio,ticket,estadoTicket,onGuardar}) => {
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const irServicio = () =>{
    if(Array.isArray(servicio) && servicio?.length > 0){
      navigate(`/servicio/${servicio[0]?.numero}/cuenta`);
    }else{
      navigate(`/servicio/${servicio?.contrato || servicio?.numero}/cuenta`);
    }
    setIsOpen(false);
  }
  const onOpenChangeServicio = () => {
    const form = {contactoCuenta_con: null,nroServicio: null,nroTicket: ticket}
    if(Array.isArray(servicio) && servicio?.length > 0){
        form.contactoCuenta_con = servicio?.[0]?.cuenta;
        form.nroServicio = servicio?.[0]?.contrato || servicio?.[0]?.numero;
    }else{
        form.contactoCuenta_con = servicio?.cuenta;
        form.nroServicio = servicio?.contrato || servicio?.numero;
    }
    showModal(ModalChangeServicioTicket,{item: form, onGuardar: onGuardar})
  }
  const nuevaCobertura = async() => {
    if(servicio){
      setIsLoading(true);
      let form = {}
      if(Array.isArray(servicio) && servicio?.length > 0){
        form = {
          contactoCuenta_con: servicio?.[0]?.cuenta,
          nombreContacto_con: servicio?.[0]?.descripcion ? servicio?.[0]?.descripcion : `${servicio?.[0]?.contrato - servicio?.[0]?.nombre}`,
          direccionConsulta_con: servicio?.direccion_servicio || servicio?.ubicacion?.domicilio || 'S/D',
          coordenadasConsulta_con: `${servicio?.latitud || servicio?.[0]?.ubicacion?.latitud}, ${servicio?.longitud || servicio?.[0]?.ubicacion?.longitud}`,
          nroServicio: servicio?.[0]?.contrato || servicio?.[0]?.numero,
          ticketId_com: ticket
        }
      }else{
        form = {
          contactoCuenta_con: servicio?.cuenta,
          nombreContacto_con: servicio?.descripcion ? servicio?.descripcion : `${servicio?.contrato - servicio?.nombre}`,
          direccionConsulta_con: servicio?.direccion_servicio || servicio?.ubicacion?.domicilio || 'S/D',
          coordenadasConsulta_con: `${servicio?.latitud || servicio?.ubicacion?.latitud}, ${servicio?.longitud || servicio?.ubicacion?.longitud}`,
          nroServicio: servicio?.contrato || servicio?.numero,
          ticketId_com: ticket
        }
      }
      await ConsultasService.registerConsultaCobertura(form).then(()=>{
        setIsLoading(false)
        setIsOpen(false);
      }).catch(()=>{
        setIsLoading(false);
      })
    }
  }

  const onOpenModalCamDomicilio = () => {
    if(servicio){
      let form = {};
      if(Array.isArray(servicio) && servicio?.length > 0){
        form = {
          contactoCuenta_con: servicio?.[0]?.cuenta,
          nombreContacto_con: servicio?.[0]?.descripcion,
          direccionConsulta_con: servicio?.[0]?.ubicacion?.domicilio || 'S/D',
          coordenadasConsulta_con: `${servicio?.[0]?.ubicacion?.latitud}, ${servicio?.[0]?.ubicacion?.longitud}`,
          nroServicio: servicio?.[0]?.numero,
          ticketId_com: ticket
        }
      }else{
        form = {
          contactoCuenta_con: servicio?.cuenta,
          nombreContacto_con: servicio?.descripcion || servicio?.nombre,
          direccionConsulta_con: servicio?.ubicacion?.domicilio || servicio?.direccion_servicio || 'S/D',
          coordenadasConsulta_con: `${servicio?.ubicacion?.latitud || servicio?.latitud }, ${servicio?.ubicacion?.longitud || servicio?.longitud}`,
          nroServicio: servicio?.numero || servicio?.contrato,
          ticketId_com: ticket
        }
      }
      showModal(ModalConsultaCamDomicilio,{item: form})
    }
  }

  return (
    <>
      <InfoIcon ref={ref} sx={{color: '#0364ff',fontSize: 27, cursor: 'pointer'}} onClick={() => setIsOpen(!isOpen)} />
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 225, maxWidth: '100%',background: '#fff !important' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>irServicio()}
            disabled={isLoading}
          >
            <ListItemIcon>
              <AssignmentTurnedInOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Ver Servicio" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>onOpenChangeServicio()}
            /* disabled={estadoTicket !== 'ABIERTO' || isLoading} */
            disabled={isLoading}
          >
            <ListItemIcon>
              <SocialDistanceIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Cambiar Servicio" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>nuevaCobertura()}
            disabled={((!servicio?.latitud || !servicio?.longitud) && (!servicio?.ubicacion?.latitud || !servicio?.ubicacion?.longitud) && (!servicio?.[0]?.ubicacion?.latitud || !servicio?.[0]?.ubicacion?.longitud)) || isLoading}
          >
            <ListItemIcon>
              <NetworkCheckIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Consultar cobertura" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          {servicio?.segmento !== 'Gobierno' &&(
            <MenuItem
              sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
              onClick={()=>onOpenModalCamDomicilio()}
              disabled={estadoTicket !== 'ABIERTO' || isLoading}
            >
              <ListItemIcon>
                <AddHomeOutlinedIcon sx={{ color: '#15294b' }} />
              </ListItemIcon>
              <ListItemText primary="Cambio de Domicilio" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )}
      </Menu>
    </>
  );
}

export default MenuOptServicio