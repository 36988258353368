import { useEffect, useState } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import PlantillaEmailService from "../../services/plantillaEmails.service";
import { showModal } from "react-redux-modal-provider";
import ModalPlantillaEmail from "../../modals/plantillas/ModalPlantillaEmail";
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const PlantillaEmailScreen = () => {
    const [items, setItems] = useState([]);
    const actualizar = () =>{
        PlantillaEmailService.getAll({all: true}).then((resp)=>{
            setItems(resp)
        })
    }
    const columns = [
        {
            name: "id_pmail",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "asunto_pmail",
            label: "Asunto",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "correo_pmail",
            label: "Correo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_pmail',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                    return <Chip label="Activo" color="success" />
                    }
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenModalEdit(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { !tableMeta.rowData[3] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onOpenModalNew = () => {
        showModal(ModalPlantillaEmail,{onGuardar: actualizar})
    }
    const onOpenModalEdit = (id) => {
        const item = items.find((e)=>e.id_pmail === id)
        showModal(ModalPlantillaEmail,{item:item, onGuardar: actualizar})
    }
    const onEliminar = (item) => {
        const searchItem = items.find((sItem)=> sItem.id_pmail === item);
        if(searchItem?.id_pmail){
            showModal(ConfirmationDialog, {
                title: `${searchItem?.isDeleted_pmail ? 'Activar' : 'Eliminar'} plantilla de email`,
                message: `¿Esta seguro que desea ${searchItem?.isDeleted_pmail ? 'activar' : 'eliminar'} la plantilla de email?`,
                onConfirm: async() => {
                    await PlantillaEmailService.destroy(searchItem.id_pmail).then(() => {
                        actualizar();
                    });
                }
            });
        }
    };
    useEffect(() => {
        actualizar();
        document.title = 'Plantilla Emails'
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={5}>
                <Grid item xs={6}>
                    <Typography variant="h1" sx={{color: '#000'}}>Plantilla Emails</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30, color: '#fff'}}
                        onClick={()=>onOpenModalNew()}
                        variant='wk'
                        color='nextStep'
                    />
                </Grid>
                <Grid item xs={12} mt={2} mb={10}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PlantillaEmailScreen