import { Card, Typography } from '@mui/material'
import React from 'react'

const CardDashInfo = ({value, label, color = 'green'}) => {
    return (
        <Card
            sx={{
                background: '#fff !important',
                minHeight: 75,
                width: '100%',
                boxShadow: 4,
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography
                sx={{
                    color: color,
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 'bold'
                }}
            >
                {value || 0}
            </Typography>
            <Typography sx={{color: '#000',textAlign: 'center',fontSize: 14}}>
                {label || ''}
            </Typography>
        </Card>
    )
}

export default CardDashInfo
