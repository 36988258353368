import axios from 'axios';

function gralTicket(data) {
    return axios.post('dashboard/gral/ticket', data);
}

const DashboardService = {
    gralTicket
};
export default DashboardService;
