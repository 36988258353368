import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill,SelectCanalesSoftland,SelectEstadoConsulta, SelectLugaresCalendar } from '../../components';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import CorporativoService from '../../services/corporativo.service';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ModalNewPreServicioCorporativo = ({show, hideModal, contacto, onGuardar,tipo}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tipoRegistro, setTipoRegistro] = useState(tipo || 'Consulta');
    const handleClose = () => hideModal();
    const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false});
    const [createTkPrefa, setCreateTkPrefa] = useState(false);

    const [formAll, setFormAll] = useState({
        nombreCompleto_c: {
            texto: null,
            textoErr: 'La razon socual es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        canalcomu_c: {
            texto: null,
            textoErr: 'El canal es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nombreCompletoContacto1_t: {
            texto: null,
            textoErr: 'El nombre es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        emailContacto1_t: {
            texto: null,
            textoErr: 'El correo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        celularContacto1_t: {
            texto: null,
            textoErr: 'El celular es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nombre_t: {
            texto: null,
            textoErr: 'El nombre es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        grupoId: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        observacionesTkPrefa: {
            texto: '<p><strong>Solicitud de prefactibilidad.</strong></p>',
            textoErr: 'La observacion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        barrio_t: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        calle_t: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        direccion_t: {
            texto: null,
            textoErr: 'La direccion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        codpostal_t: {
            texto: null,
            textoErr: 'La localidad es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        latitud_t: {
            texto: null,
            textoErr: 'La latitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        estadoConsulta: {
            texto: null,
            textoErr: 'El estado es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        lugarId_t: {
            texto: null,
            textoErr: 'El lugar es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        observacionesConsulta: {
            texto: null,
            textoErr: 'La observacion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = { segmento_con: 'COR',tipoRegistro };
        if(contacto?.id_c){
            form.idPotCliente = contacto?.id_c
        }
        if(contacto?.cuenta){
            form.idCuenta = contacto?.cuenta;
        }
        let isOk = false;
        setIsLoading(true);
        const inputRequired = [
            'nombreCompleto_c',
            'canalcomu_c',
            'codCanal_tk',
        ];
        if(tipoRegistro === 'Consulta'){
            inputRequired.push('observacionesConsulta','estadoConsulta')
        }else{
            form.createPreServ = true;
            form.createTkPrefa = createTkPrefa;
            inputRequired.push('nombre_t')
            if(createTkPrefa){
                inputRequired.push('observacionesTkPrefa')
            }
            form.tipoRegistro = 'Contacto'
        }
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
            if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
                form[name] = formAll[name].texto
            }else{
                if(inputRequired.includes(name)){
                    isOk = true;
                    onChangeFormSetError(name)
                }
            }
        }))
        if(!isOk){
            form.canal_con = formAll.canalcomu_c.texto;
            form.nombre_t = `${contacto?.nombreCompleto_c || contacto?.nombre_completo} - ${formAll.nombre_t.texto?.toUpperCase()}`
            form.direccion_t = `${formAll.barrio_t.texto || 'S/B'} - ${formAll.calle_t.texto || 'S/C'}`;
            await CorporativoService.registerContactoConsulta(form).then(()=>{
                if(onGuardar){
                    onGuardar();
                }
                handleClose();
            })
            .catch(()=>{
                setIsLoading(false);
            })
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(contacto){
            setTimeout(() => {
                onChangeForm(contacto?.nombreCompleto_c || contacto?.cliente || contacto?.nombre , 'nombreCompleto_c')
                onChangeForm('', 'nombre_t')
                if((contacto?.barrio_c || contacto?.direccion_c)){
                    onChangeForm(`${contacto?.barrio_c || ''} - ${contacto?.direccion_c || ''}`, 'direccion_t')
                }else{
                    onChangeForm(contacto?.ubicacion?.direccion || contacto?.direcFac || '-', 'direccion_t')
                }
                onChangeForm(contacto?.celular_c || contacto?.contacto?.telefono || contacto?.telefono || '-', 'celular_t')
                onChangeForm(contacto?.email_c || contacto?.contacto?.email || contacto?.email || '', 'contactoEmail_t')
            }, 100);
        }
    }, [contacto])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                    border:0,
                    height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                }
            },
            color: 'gray',
        }
    }), [])
    const onSubmitCoordenadas = () =>{
        if(coordenadas.texto && coordenadas.texto?.length > 10){
            setIsLoading(true)
            const quitarEspacios = coordenadas.texto.replace(/ /g, "")
            const separador = quitarEspacios.split(',')
            if(separador.length === 2){
                const validCoord = `${separador[0]}, ${separador[1]}`
                const valid = AuxiliarFunction.isValidCoordinates(validCoord)
                if(valid){
                    setIsLoading(false)
                    onChangeForm(separador[0],'latitud_t')
                    onChangeForm(separador[1],'longitud_t')
                    onChangeForm(`${separador[0]},${separador[1]}`,'coordenadas_t')
                    setCoordenadas({texto: coordenadas.texto, estadoErr: false})
                }else{
                    setIsLoading(false)
                    setCoordenadas({texto: coordenadas.texto, estadoErr: true})
                }
            }else{
                setIsLoading(false)
                setCoordenadas({texto: coordenadas.texto, estadoErr: true})
            }
        }
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Pre-Servicio Corporativo
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h5'>Cliente:</Typography>
                            <Card sx={{background: '#0364ff !important', p:1,boxShadow:2,borderRadius:3,minHeight:110}}>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Nombre Completo: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.nombreCompleto_c || contacto?.nombre_completo || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Correo: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.email_c || contacto?.contacto?.email || contacto?.email || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Celular: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.celular_c || contacto?.contacto?.telefono || contacto?.telefono || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Direccion: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{formAll.direccion_t.texto || '-'}</Box></Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Typography sx={{color: '#000', fontWeight: 'bold',mt: 1, ml: 3}} variant='h5'>Tipo de registro</Typography>
                            <Card sx={{background: '#fff !important',ml: 2, mr: 2, boxShadow: 4,borderRadius: 4}}>
                                <Grid container p={2}>
                                    <Grid item xs={12} sm={5}>
                                        <Typography sx={{color: '#000',marginLeft:1,fontSize:14, opacity: 0.6,marginTop:1}}>Datos obligatorios según el tipo de registro</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5.9} ml={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <ToggleButtonGroup
                                        color="primary"
                                        value={tipoRegistro}
                                        exclusive
                                        onChange={(e)=>setTipoRegistro(e.target.value)}
                                        aria-label="Platform"
                                        >
                                            <ToggleButton
                                                value="Consulta"
                                                sx={{
                                                background: '#e7e7e9',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                                                '&.MuiToggleButton-root':{
                                                    border:2,
                                                },
                                                '&.Mui-selected':{
                                                    background: '#e6f0ff',
                                                    color: '#0364FF',
                                                    borderColor: '#0364FF',
                                                    border:2,
                                                    '&:hover':{
                                                    background: '#e6f0ff',
                                                    color: '#0364FF',
                                                    borderColor: '#0364FF',
                                                    border:2,
                                                    }
                                                },
                                                '&:hover':{
                                                    background: '#e6f0ff',
                                                    color: '#0364FF',
                                                    borderColor: '#0364FF',
                                                    border:2,
                                                }
                                                }}
                                            >
                                                Consulta
                                            </ToggleButton>
                                            <ToggleButton
                                                value="Pre-Servicio"
                                                sx={{
                                                background: '#e7e7e9',borderWidth:2,borderWidth:1,fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                                                '&.MuiToggleButton-root':{
                                                    border:2,
                                                },
                                                '&.Mui-selected':{
                                                    background: '#e6f8f2',
                                                    color: '#018155',
                                                    borderColor: '#018155',
                                                    border:2,
                                                    '&:hover':{
                                                    background: '#e6f8f2',
                                                    color: '#018155',
                                                    borderColor: '#018155',
                                                    border:2,
                                                    }
                                                },
                                                '&:hover':{
                                                    background: '#e6f8f2',
                                                    color: '#018155',
                                                    borderColor: '#018155',
                                                    border:2,
                                                }
                                                }}
                                            >
                                                Pre-Servicio
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        { tipoRegistro === 'Pre-Servicio' ? (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt: 1}} variant='h5'>Direccion de instalación</Typography>
                                    <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                        <Grid container mb={1} mt={1} spacing={1}>
                                            <Grid item xs={12}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad Calendario</Box>
                                                </Typography>
                                            </Box>
                                            <SelectLugaresCalendar
                                                value={formAll.lugarId_t.texto}
                                                isInvalid={formAll.lugarId_t.estadoErr}
                                                onChange={(e)=>{
                                                onChangeForm(e?.value,'lugarId_t')
                                                onChangeForm(e?.codLocalidad,'codpostal_t')
                                                onChangeForm(e?.codProvincia,'codprovincia_t')
                                                }}
                                            />
                                            {formAll.lugarId_t.estadoErr && (
                                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                                            )}
                                            </Grid>
                                            <Grid item xs={12} md={5.9}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                                                </Typography>
                                                </Box>
                                                <Typography sx={{color: '#000'}}> </Typography>
                                                <TextField
                                                    fullWidth
                                                    autoComplete='off'
                                                    sx={styleInputHeight}
                                                    value={formAll.barrio_t.texto}
                                                    onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                                                    InputLabelProps={{shrink: formAll.barrio_t.texto}}
                                                    error={formAll.barrio_t.estadoErr}
                                                    helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                                                />
                                            </Grid>
                                            <Grid xs={12} md={5.9} ml={0.6} mt={1}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                                                </Typography>
                                                </Box>
                                                <TextField
                                                fullWidth
                                                autoComplete='off'
                                                sx={styleInputHeight}
                                                value={formAll.calle_t.texto}
                                                onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                                                InputLabelProps={{shrink: formAll.calle_t.texto}}
                                                error={formAll.calle_t.estadoErr}
                                                helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                                            </Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            InputLabelProps={{shrink: true}}
                                            value={coordenadas?.texto}
                                            error={formAll.latitud_t.estadoErr || coordenadas?.estadoErr}
                                            helperText={formAll.latitud_t.estadoErr && formAll.latitud_t.textoErr}
                                            onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                                            InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={()=>onSubmitCoordenadas()}>
                                                    <GpsFixedIcon sx={{color: '#000'}} size={35} />
                                                </IconButton>
                                            ),
                                            }}
                                        />
                                        {coordenadas?.estadoErr && (
                                            <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                                        )}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Typography sx={{color: '#000',fontWeight: 'bold',mt:2,textAlign: 'center'}} variant='h5'>Datos del contacto</Typography>
                                    <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3,display: 'flex'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                                                </Typography>
                                                </Box>
                                                <Typography sx={{color: '#000'}}> </Typography>
                                                <TextField
                                                    fullWidth
                                                    autoComplete='off'
                                                    sx={styleInputHeight}
                                                    value={formAll.nombreCompletoContacto1_t.texto}
                                                    onChange={(e)=>onChangeForm(e.target.value,'nombreCompletoContacto1_t')}
                                                    InputLabelProps={{shrink: formAll.nombreCompletoContacto1_t.texto}}
                                                    error={formAll.nombreCompletoContacto1_t.estadoErr}
                                                    helperText={formAll.nombreCompletoContacto1_t.estadoErr && formAll.nombreCompletoContacto1_t.textoErr}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4.9}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Celular</Box>
                                                </Typography>
                                                </Box>
                                                <Typography sx={{color: '#000'}}> </Typography>
                                                <TextField
                                                    fullWidth
                                                    autoComplete='off'
                                                    sx={styleInputHeight}
                                                    value={formAll.celularContacto1_t.texto}
                                                    onChange={(e)=>onChangeForm(e.target.value,'celularContacto1_t')}
                                                    InputLabelProps={{shrink: formAll.celularContacto1_t.texto}}
                                                    error={formAll.celularContacto1_t.estadoErr}
                                                    helperText={formAll.celularContacto1_t.estadoErr && formAll.celularContacto1_t.textoErr}
                                                />
                                            </Grid>
                                            <Grid xs={12} md={6.9} ml={0.6} mt={1}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                                                </Typography>
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    autoComplete='off'
                                                    sx={styleInputHeight}
                                                    value={formAll.emailContacto1_t.texto}
                                                    onChange={(e)=>onChangeForm(e.target.value,'emailContacto1_t')}
                                                    InputLabelProps={{shrink: formAll.emailContacto1_t.texto}}
                                                    error={formAll.emailContacto1_t.estadoErr}
                                                    helperText={formAll.emailContacto1_t.estadoErr && formAll.emailContacto1_t.textoErr}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Canal</Box>
                                                </Typography>
                                                </Box>
                                                <SelectCanalesSoftland
                                                    isInvalid={formAll.canalcomu_c.estadoErr}
                                                    value={formAll.canalcomu_c.texto}
                                                    onChange={(e)=>{
                                                        onChangeForm(e.value,'canalcomu_c')
                                                        onChangeForm(e.value,'codCanal_tk')
                                                    }}
                                                />
                                                {formAll.canalcomu_c.estadoErr && (
                                                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt:2}} variant='h5'>Pre-Servicio y Ticket de prefactibilidad</Typography>
                                    <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                        <Grid container mb={1} mt={1} spacing={1}>
                                            <Grid item xs={12}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>
                                                        {`Nombre: ${contacto?.nombreCompleto_c || contacto?.nombre_completo} - ${formAll.nombre_t.texto || ''}`}
                                                    </Box>
                                                </Typography>
                                                </Box>
                                                <Typography sx={{color: '#000'}}> </Typography>
                                                <TextField
                                                    fullWidth
                                                    autoComplete='off'
                                                    sx={styleInputHeight}
                                                    value={formAll.nombre_t.texto}
                                                    onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
                                                    InputLabelProps={{shrink: formAll.nombre_t.texto}}
                                                    error={formAll.nombre_t.estadoErr}
                                                    helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <FormControlLabel
                                                labelPlacement='start'
                                                control={
                                                    <Checkbox
                                                        onClick={()=>setCreateTkPrefa(!createTkPrefa)}
                                                        checked={createTkPrefa}
                                                        sx={{
                                                            color: '#0247B5',
                                                            ml: 1,
                                                            '&.Mui-checked': {
                                                                color: '#0247B5',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="¿Desea crear el ticket de prefactibilidad?"
                                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                                                />
                                            </Grid>
                                            {createTkPrefa && (
                                                <Grid item xs={12} mt={2}>
                                                    <Typography sx={{color: '#000'}}>Descripción: Ticket de prefactibilidad</Typography>
                                                    <InputEditQuill
                                                        id="Comentario"
                                                        template={formAll.observacionesTkPrefa.texto}
                                                        etiqueta
                                                        onChangeText={(html)=>{
                                                            onChangeForm(html,'observacionesTkPrefa')
                                                        }}
                                                    />
                                                    {formAll.observacionesTkPrefa.estadoErr && (
                                                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesTkPrefa.textoErr}</Typography>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Card>
                                </Grid>
                            </>
                        ):(
                            <Grid item xs={12}>
                                <Card sx={{background: '#fff !important', borderRadius: 3,boxShadow: 4}}>
                                    <Box p={2}>
                                        <Box display='flex' pt={1}>
                                            <LibraryBooksIcon sx={{color: '#000',fontSize: 30}} />
                                            <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>
                                                Nueva Consulta
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={1} mt={1}>
                                        <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Estado</Box>
                                            </Typography>
                                            </Box>
                                            <SelectEstadoConsulta
                                            name='estadoId'
                                            id='estadoId'
                                            value={formAll.estadoConsulta.texto}
                                            todos
                                            isInvalid={formAll?.estadoConsulta?.estadoErr}
                                            onChange={(pl)=>onChangeForm(pl.value,'estadoConsulta')}
                                            />
                                            {formAll?.estadoConsulta?.estadoErr && (
                                            <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>
                                                {formAll.estadoConsulta.textoErr}
                                            </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad Calendario</Box>
                                                </Typography>
                                            </Box>
                                            <SelectLugaresCalendar
                                                value={formAll.lugarId_t.texto}
                                                isInvalid={formAll.lugarId_t.estadoErr}
                                                onChange={(e)=>{
                                                onChangeForm(e?.value,'lugarId_t')
                                                onChangeForm(e?.codLocalidad,'codpostal_t')
                                                onChangeForm(e?.codProvincia,'codprovincia_t')
                                                }}
                                            />
                                            {formAll.lugarId_t.estadoErr && (
                                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                                            </Typography>
                                            </Box>
                                            <Typography sx={{color: '#000'}}> </Typography>
                                            <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.barrio_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                                            InputLabelProps={{shrink: formAll.barrio_t.texto}}
                                            error={formAll.barrio_t.estadoErr}
                                            helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                                            </Typography>
                                            </Box>
                                            <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.calle_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                                            InputLabelProps={{shrink: formAll.calle_t.texto}}
                                            error={formAll.calle_t.estadoErr}
                                            helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                                                </Typography>
                                            </Box>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                sx={styleInputHeight}
                                                InputLabelProps={{shrink: true}}
                                                value={coordenadas?.texto}
                                                onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                                                InputProps={{
                                                    endAdornment: (
                                                    <IconButton onClick={()=>onSubmitCoordenadas()}>
                                                        <GpsFixedIcon sx={{color: '#000'}} size={35} />
                                                    </IconButton>
                                                    ),
                                                }}
                                            />
                                            {coordenadas?.estadoErr && (
                                                <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Canal</Box>
                                            </Typography>
                                            </Box>
                                            <SelectCanalesSoftland
                                                isInvalid={formAll.canalcomu_c.estadoErr}
                                                value={formAll.canalcomu_c.texto}
                                                onChange={(e)=>{
                                                    onChangeForm(e.value,'canalcomu_c')
                                                    onChangeForm(e.value,'codCanal_tk')
                                                }}
                                            />
                                            {formAll.canalcomu_c.estadoErr && (
                                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Motivo de consulta</Box>
                                            </Typography>
                                            </Box>
                                            <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInput}
                                            multiline
                                            value={formAll.observacionesConsulta.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'observacionesConsulta')}
                                            InputLabelProps={{shrink: formAll.observacionesConsulta.texto}}
                                            error={formAll.observacionesConsulta.estadoErr}
                                            helperText={formAll.observacionesConsulta.estadoErr && formAll.observacionesConsulta.textoErr}
                                            />
                                        </Grid>
                                        </Grid>
                                    </Box>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton={tipoRegistro === 'Pre-Servicio' ? 'Crear Pre-Servicio' : 'Crear Consulta'}
                        iconStart={<PersonAddIcon />}
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNewPreServicioCorporativo