import { useContext, useState, useMemo } from 'react';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import {toast} from 'react-toastify';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, SelectPrioridadTkNubicom, InputEditQuill, CardListAlerta, DateSingle } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import ModalAlertaRecordatorio from './ModalAlertaRecordatorio';
import RecordatorioService from '../../services/recordatorio.service';

function ModalCrearRecordatorio({
    show,
    hideModal,
    onGuardar,
    nombreCompleto = '',
    contactoId = false,
    cuentaId = false,
    servicioId = false,
    preServicioId = false,
    consultaId = false,
    ticketId = false,
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [tipoProgramTime, setTipoProgramTime] = useState('12:00');
    const [alertasArr, setAlertasArr] = useState([]);
    const { sockets } = useContext( SocketContext );
    const [formAll, setFormAll] = useState({
        titulo_rec: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        observaciones_rec: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        fechaProgramado_rec: {
            texto: null,
            textoErr: 'La fecha es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        prioridad_rec: {
            texto: 'NORMAL',
            textoErr: 'La prioridad es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        estado_rec: {
            texto: 'A Realizar',
            textoErr: 'El estado es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    })
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }

    const onClickNewAlerta = () => {
        showModal(ModalAlertaRecordatorio,{
            alertasArr: alertasArr,
            setAlertasArr: setAlertasArr
        });
    }

    const onSubmit = async() =>{
        setIsLoading(true);
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = [
            'titulo_rec',
            'fechaProgramado_rec',
            'prioridad_rec',
            'estado_rec'
        ];
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            const now = moment();
            const newForm = {
                ...form,
                fechaProgramado_rec: `${formAll.fechaProgramado_rec.texto} ${tipoProgramTime}:00`,
                contactoId_rec: contactoId || null,
                cuentaId_rec: cuentaId || null,
                servicioId_rec: servicioId || null,
                preServicioId_rec: preServicioId || null,
                consultaId_rec: consultaId || null,
                ticketId_rec: ticketId || null,
            }
            let bandera = false;
            if(alertasArr?.length > 0){
                alertasArr.forEach((e)=>{
                    const fechaProg = moment(newForm.fechaProgramado_rec).subtract(e?.cantTiempo_alerec, e?.tipoTiempo_alerec).format('YYYY-MM-DD HH:mm:ss')
                    if(moment(fechaProg).isBefore(now)){
                        bandera = true;
                    }else{
                        e.fechaAlerta_alerec = fechaProg;
                    }
                })
                newForm.alertas = alertasArr;
            }
            if(bandera){
                toast.warn('Las fechas deben ser superior a la actual.', {
                    position: "top-right",
                    autoClose: 2500,
                    pauseOnHover: false,
                });
                setIsLoading(false)
            }else{
                await RecordatorioService.register(newForm).then(()=>{
                    if(onGuardar){
                        onGuardar(true);
                    }
                    hideModal();
                })
                .catch(()=>{
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }
        }else{
            setIsLoading(false)
        }
    }
    const onDeletedAlerta = (id) => {
        const filtro = alertasArr.filter((e)=>e.id !== id);
        setAlertasArr(filtro)
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45,
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '& .Mui-disabled':{
                borderRadius: 2,
                background: '#f2f2f2',
                height: 4.7,
                marginTop:0.1,
                color: '#000 !important',
                border: 1,
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "gray",
        },
    }), [])
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4,
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Nuevo Recordatorio - #${ticketId || consultaId || preServicioId || servicioId || contactoId || cuentaId} ${nombreCompleto}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={8} mt={0}>
                            <Typography sx={{color: '#000',fontSize: 14}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.titulo_rec.texto}
                                onChange={(e)=>onChangeForm(e.target.value, 'titulo_rec')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={0}>
                            <Typography sx={{color: '#000',fontSize: 14}}>Prioridad</Typography>
                            <SelectPrioridadTkNubicom
                                value={formAll.prioridad_rec.texto}
                                isInvalid={formAll.prioridad_rec.estadoErr}
                                onChange={(opt) => onChangeForm(opt.value,'prioridad_rec')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={0}>
                            <Typography sx={{color: '#000',fontSize: 14}}>Fecha Programada</Typography>
                            <DateSingle
                                minDate={new Date()}
                                fullWidth
                                value={formAll.fechaProgramado_rec.texto}
                                onChange={(e)=>onChangeForm(e,'fechaProgramado_rec')}
                                isFullWidth
                                dropdownMode="select"
                                yearDropdownItemNumber={15}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1.9} mt={0}>
                            <Typography sx={{color: '#000',fontSize: 14}}>Horario</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInput}
                                value={tipoProgramTime}
                                onChange={(e)=>setTipoProgramTime(e.target.value)}
                                type='time'
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputEditQuill
                                template={formAll.observaciones_rec.texto}
                                onChangeText={(text)=>onChangeForm(text, 'observaciones_rec')}
                            />
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <Divider sx={{background: 'gray'}} />
                        </Grid>
                        <Grid item xs={12} md={6.5} mt={1}>
                            <Typography sx={{flex:1,textAlign: 'end', color: '#000',fontWeight: 'bold'}} variant='h4'>
                                ALERTAS
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5.5} sx={{display: 'flex', justifyContent: 'end'}}>
                            <ButtonBasic
                                variant='wk'
                                color='nextStep'
                                textButton='Nueva Alerta'
                                iconStart={<AddIcon />}
                                onClick={()=>onClickNewAlerta('')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {alertasArr?.length > 0 ? (
                                <Grid container spacing={1}>
                                    {alertasArr?.map((itm)=>(
                                        <Grid item xs={12} md={4}>
                                            <CardListAlerta item={itm} onDeletedAlerta={onDeletedAlerta} />
                                        </Grid>
                                    ))}
                                </Grid>
                            ):(
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Box
                                        sx={{
                                            background: '#f6f8fc',
                                            minHeight: 50,
                                            minWidth: 100,
                                            borderRadius: 5,
                                            padding: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography sx={{color: '#808b9e', fontWeight: 'bold', textAlign: 'center'}}>
                                            Sin alertas programadas
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Divider sx={{background: 'gray'}} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={
                            !formAll.titulo_rec.texto
                            || !formAll.estado_rec.texto
                            || !formAll.prioridad_rec.texto
                            || !formAll.fechaProgramado_rec.texto
                            || alertasArr?.length === 0
                        }
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalCrearRecordatorio;