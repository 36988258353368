import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Badge, Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardNotifications, CardNotificationsMentions } from '../../components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuOptionsNotificaciones from '../../components/menu/MenuOptionsNotificaciones';
import { useSelector } from 'react-redux';
import UserService from '../../services/personas.service';
import DialogTitleDefault from '../default/DialogTitleDefault';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ModalNotificaciones911 = ({show, hideModal, item, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notifyTkArr, setNotifyTkArr] = useState([]);
    const [notifyMentionArr, setNotifyMention] = useState([]);
    const notifitk911 = useSelector(state => state.default.notifitk911);
    const notificom911 = useSelector(state => state.default.notificom911);
    const [value, setValue] = useState(0);
    const handleClose = () => hideModal();
    const handleChange = (event, newValue) => setValue(newValue);
    const [pageCountNotifyTk, setPageCountNotifyTk] = useState(0);
    const [hasMoreTk, setHasMoreTk] = useState(true)
    const [pageCountNotifyEtiqueta, setPageCountNotifyEtiqueta] = useState(0);
    const [hasMoreEtiqueta, setHasMoreEtiqueta] = useState(true)
    const cargarMasTk = (modal) =>{
        setIsLoading(true)
        UserService.getAll({type : 'notifications',page: pageCountNotifyTk}).then((response) =>{
            if(!modal){
                setNotifyTkArr((prev) => prev.concat(response.content));
                setHasMoreTk((pageCountNotifyTk+1) < response.totalPages);
            }else{
                setNotifyTkArr(response.content);
                setPageCountNotifyTk(0)
                setHasMoreTk(true)
            }
            setIsLoading(false)
        })
        .catch(()=>{
            setIsLoading(false)
        })
    }
    const cargarMasEtiquetas = (modal) =>{
        setIsLoading(true)
        UserService.getAll({type : 'mentions',page: pageCountNotifyEtiqueta}).then((response) =>{
            if(!modal){
                setNotifyMention((prev) => prev.concat(response.content));
                setHasMoreEtiqueta((pageCountNotifyEtiqueta+1) < response.totalPages);
            }else{
                setNotifyMention(response.content);
                setPageCountNotifyEtiqueta(0)
                setHasMoreEtiqueta(true)
            }
            setIsLoading(false)
        })
        .catch(()=>{
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if(notifitk911?.content?.length > 0){
            setNotifyTkArr(notifitk911.content)
        }else{
            setNotifyTkArr([])
        }
        if(notifitk911?.totalPages === 0){
            setHasMoreTk(false)
        }
    }, [notifitk911])
    useEffect(() => {
        if(pageCountNotifyTk > 0){
            cargarMasTk();
        }else{
            if(notifitk911?.content?.length > 0){
                setNotifyTkArr(notifitk911.content)
            }else{
                setNotifyTkArr([])
            }
        }
    }, [pageCountNotifyTk])

    useEffect(() => {
        if(notificom911?.content?.length > 0){
            setNotifyMention(notificom911.content)
        }else{
            setNotifyMention([])
        }
        if(notificom911?.totalPages === 0){
            setHasMoreEtiqueta(false)
        }
    }, [notificom911])

    useEffect(() => {
        if(pageCountNotifyEtiqueta > 0){
            cargarMasEtiquetas();
        }else{
            if(notificom911?.content?.length > 0){
                setNotifyMention(notificom911.content)
            }else{
                setNotifyMention([])
            }
        }
    }, [pageCountNotifyEtiqueta])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Notificaciones CVV
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent sx={{maxHeight: '75vh'}} className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                    sx={{
                                        '& .MuiTabs-indicator':{
                                            background: '#0364ff'
                                        },
                                        '& .MuiButtonBase-root':{
                                            color: '#6b788e',
                                            fontWeight: 'bold'
                                        },
                                        '& .MuiButtonBase-root.Mui-selected':{
                                            color: '#0364ff'
                                        }
                                    }}
                                >
                                    <Tab
                                        label={
                                            <Badge
                                                badgeContent={notifitk911?.count || 0}
                                                sx={{
                                                    '& span':{
                                                        '&.MuiBadge-badge':{
                                                            background: '#0364ff',
                                                            color:'white',
                                                            marginLeft:-0.9,
                                                            position: 'unset'
                                                        },
                                                    }
                                                }}
                                            >
                                                Tickets
                                            </Badge>}
                                        {...a11yProps(0)}
                                        sx={{ minWidth: "fit-content", flex: 1 }}
                                    />
                                    <Tab
                                        label={
                                            <Badge
                                                badgeContent={notificom911?.count || 0}
                                                sx={{
                                                    '& span':{
                                                        '&.MuiBadge-badge':{
                                                            background: '#0364ff',
                                                            color:'white',
                                                            marginLeft:-0.6,
                                                            position: 'unset'
                                                        },
                                                    }
                                                }}
                                            >
                                                Etiquetas
                                            </Badge>}
                                        {...a11yProps(1)}
                                        sx={{ minWidth: "fit-content", flex: 1 }}
                                    />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={1} display='flex' justifyContent='center' alignItems='center'>
                            <MenuOptionsNotificaciones />
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={0}>
                                {notifyTkArr?.length > 0 &&(
                                    notifyTkArr.map((noti)=>( <CardNotifications item={noti} onCloseModal={handleClose} /> ))
                                )}
                                {hasMoreTk && (
                                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center',marginTop:3}}>
                                        <ButtonBasic
                                            textButton='Cargas mas'
                                            onClick={()=>setPageCountNotifyTk((prev)=> prev+1)}
                                            variant='wk'
                                            color='nextStep'
                                            sx={{width: 300}}
                                        />
                                    </Box>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {notifyMentionArr?.length > 0 &&(
                                    notifyMentionArr.map((noti)=>( <CardNotificationsMentions item={noti} onCloseModal={handleClose} /> ))
                                )}
                                {hasMoreEtiqueta && notifyMentionArr?.length >= 10 && (
                                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center',marginTop:3}}>
                                        <ButtonBasic
                                            textButton='Cargas mas'
                                            onClick={()=>setPageCountNotifyEtiqueta((prev)=> prev+1)}
                                            variant='wk'
                                            color='nextStep'
                                            sx={{width: 300}}
                                        />
                                    </Box>
                                )}
                            </TabPanel>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{width: 200}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNotificaciones911