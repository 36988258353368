import { Box, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";
import FechaFunction from "../../function/FechaFunction"
import TextEstadoTicketIndv from "../text/TextEstadoTicketIndv"

const CardViewTicket = ({item,onCloseModal}) => {
    const navigate = useNavigate();
    const redirectTk = () =>{
        if(onCloseModal){
            onCloseModal();
        }
        if(item?.ticket?.createdEmp_tk){
            switch (item?.ticket?.createdEmp_tk) {
                case 'NUBICOM':
                    navigate(`/ticket/${item?.ticket?.id_tk}/nubicom`);
                    break;
                case 'STRONG':
                    navigate(`/ticket/${item?.ticket?.id_tk}/strongsystems`);
                    break;
                case 'IDS':
                    navigate(`/ticket/${item?.ticket?.id_tk}/ids`);
                    break;
                case 'CVV':
                    navigate(`/ticket/${item?.ticket?.id_tk}/cvv`);
                    break;
                default:
                    break;
            }
        }else{
            switch (item?.createdEmp_tk) {
                case 'NUBICOM':
                    navigate(`/ticket/${item?.id_tk}/nubicom`);
                    break;
                case 'STRONG':
                    navigate(`/ticket/${item?.id_tk}/strongsystems`);
                    break;
                case 'IDS':
                    navigate(`/ticket/${item?.id_tk}/ids`);
                    break;
                case 'CVV':
                    navigate(`/ticket/${item?.id_tk}/cvv`);
                    break;
                default:
                    break;
            }
        }
    }
    const onClickNameCliente = (it) => {
        if(it?.cuenta){
            setTimeout(() => {
                navigate(`/contactos/${it?.cuenta}/cuenta`);
            }, 400);
        }else if(it?.id_c){
            setTimeout(() => {
                navigate(`/contactos/${it?.id_c}/nubicom`);
            }, 400);
        }
    }
    const onClickNameServicio = (it) => {
        if(it?.cuentaContratoId_txt){
            setTimeout(() => {
                navigate(`/servicio/${it?.cuentaContratoId_txt}/cuenta`);
            }, 400);
        }else if(it?.tratoId_txt){
            setTimeout(() => {
                navigate(`/pre-servicio/${it?.tratoId_txt}/nubicom`);
            }, 400);
        }else if(it?.id_t){
            setTimeout(() => {
                navigate(`/pre-servicio/${it?.id_t}/nubicom`);
            }, 400);
        }
    }
    return (
        <Box
            sx={{background: '#fff !important',border: 1,borderColor: '#ebedf0',borderBottomColor: '#c2c7d0',p:1,'&:hover':{background: '#dfe2e6 !important',cursor: 'pointer'}}}
            onClick={()=>redirectTk()}
        >
            <Grid container>
                <Grid item xs={2} sm={1.5} display='flex' justifyContent='center' alignItems='center'>
                    {(item?.ticket?.createdEmp_tk === 'NUBICOM' || item?.createdEmp_tk === 'NUBICOM') ?(
                        <Box sx={{width: 50,height: 50 , border:2, borderColor: '#D2D5D9', borderRadius: 50,display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            <img src="/img/nubi-cirucular.png" width={45} height={45} />
                        </Box>
                    ):(
                        <Box sx={{width: 50,height: 50 , border:2, borderColor: '#D2D5D9', borderRadius: 50,display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            {(item?.ticket?.createdEmp_tk === 'STRONG' || item?.createdEmp_tk === 'STRONG') &&( <img src="/img/strong-logo.png" width={35} height={40} /> )}
                            {(item?.ticket?.createdEmp_tk === 'IDS' || item?.createdEmp_tk === 'IDS') &&( <img src="/img/logo_login.png" width={35} height={40} /> )}
                            {(item?.ticket?.createdEmp_tk === 'CVV' || item?.createdEmp_tk === 'CVV') &&( <img src="/img/911-logo.png" width={35} height={40} /> )}
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} sm={8.5}>
                    <Box display='flex' flexDirection='column'>
                        <Typography sx={{color: '#15294b', fontWeight: 'bold'}}>{`# ${item?.ticket?.id_tk || item?.id_tk} - ${item?.ticket?.titulo_tk || item?.titulo_tk}`}</Typography>
                        {item?.clientes?.length > 0 && (
                            <Box display='flex' flexDirection='row'>
                                { item?.clientes?.map((cli)=>(
                                    <Typography
                                        sx={{ml:2, color: '#0364ff',fontWeight: 'bold', fontSize: 10,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                        variant='h5'
                                        onClick={()=>onClickNameCliente(cli)}
                                    >
                                        {cli?.nombre || cli?.nombreCompleto_c}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                        {item?.ticket?.clientes?.length > 0 && (
                            <Box display='flex' flexDirection='row'>
                                { item?.ticket?.clientes?.map((cli)=>(
                                    <Typography
                                        sx={{ml:2, color: '#0364ff',fontWeight: 'bold', fontSize: 10,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                        variant='h5'
                                        onClick={()=>onClickNameCliente(cli)}
                                    >
                                        {cli?.nombre || cli?.nombreCompleto_c}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                        {item?.ticketxtrato?.length > 0 && (
                            <Box display='flex' flexDirection='row'>
                                { item?.ticketxtrato?.map((cli)=>(
                                    <Typography
                                        sx={{ml:2, color: '#0364ff',fontWeight: 'bold', fontSize: 11,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                        variant='h5'
                                        onClick={()=>onClickNameServicio(cli)}
                                    >
                                        {cli?.cuentaContratoId_txt ?(
                                            `Servicio #${cli?.cuentaContratoId_txt}`
                                        ):(
                                            `Pre-Servicio #${cli?.tratoId_txt}`
                                        )}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                        {item?.ticket?.ticketxtrato?.length > 0 && (
                            <Box display='flex' flexDirection='row'>
                                { item?.ticket?.ticketxtrato?.map((cli)=>(
                                    <Typography
                                        sx={{ml:2, color: '#0364ff',fontWeight: 'bold', fontSize: 11,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                        variant='h5'
                                        onClick={()=>onClickNameServicio(cli)}
                                    >
                                        {cli?.cuentaContratoId_txt ?(
                                            `Servicio #${cli?.cuentaContratoId_txt}`
                                        ):(
                                            `Pre-Servicio #${cli?.tratoId_txt}`
                                        )}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                            <Box display='flex' flexDirection='row'>
                                { item?.id_t && (
                                    <Typography
                                        sx={{ml:2, color: '#0364ff',fontWeight: 'bold', fontSize: 11,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                        variant='h5'
                                        onClick={()=>onClickNameServicio(item)}
                                    >
                                        {!item?.cuentaContratoId_txt ? (
                                            `Pre-Servicio #${item?.id_t}`
                                        ):(
                                            `Servicio #${item?.id_t}`
                                        )}
                                    </Typography>
                                )}
                            </Box>
                        {(item?.ticket?.createdAt || item?.createdAt) && (
                            <Typography sx={{color: '#15294b',ml:2}}>{FechaFunction.format(new Date(item?.ticket?.createdAt || item?.createdAt),'dd/MM/yyyy HH:mm:ss')}</Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
                    <TextEstadoTicketIndv estado={item?.ticket?.estado_tk || item?.estado_tk} cerrado={item?.ticket?.cerrado_tk || item?.cerrado_tk} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CardViewTicket