import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ButtonBasic, InputSearchDireccionEntrega } from '../../components';
import CloseIcon from '@mui/icons-material/Close';
import TicketService from '../../services/tickets.service';
import { toast } from 'react-toastify';

function ModalChangeServicioTicket({id, show, hideModal,item,onGuardar}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [servicio, setServicio] = useState(null);
    const onSubmit = async() =>{
        const form = {old:item, new: servicio}
        await TicketService.changeServicioTicket(form).then((resp)=>{
            if(resp?.estado === 'ok'){
                toast.success('Se realizó el cambio de servicio correctamente.', {
                    position: "top-right",
                    autoClose: 3500,
                    pauseOnHover: false,
                });
                if(onGuardar){
                    onGuardar()
                }
                handleClose()
            }
        })
    }
    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'70%',lg: '50%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {`Por favor indique el servicio para el ticket #${item?.nroTicket}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 1}} />
                <Typography sx={{color: '#000',textAlign: 'center'}} variant='h5'>Buscar Servicio</Typography>
                <InputSearchDireccionEntrega
                    onClick={(e)=>setServicio(e)}
                />
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!item?.nroTicket || !item?.nroServicio || !servicio}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalChangeServicioTicket.propTypes = {
    show: PropTypes.bool
};

ModalChangeServicioTicket.defaultProps = {
    show: false
};

export default ModalChangeServicioTicket;