import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';

const ModalCancelarPreTicketView = ({show, hideModal, item}) => {
    const handleClose = () => hideModal();

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`CORREO INVALIDO #${item?.idCorreo_ptke} - ${item?.subject_ptke}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', border: 1, borderColor: '#000', p: 1}}>
                                <Typography sx={{color: '#000', textAlign:'center',mb: 0.5, fontWeight: 'bold'}} variant='h5'>MOTIVO</Typography>
                                <Typography sx={{color: '#000'}}>{item?.motivoCancelado_ptke}</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', border: 1, borderColor: '#000', p: 1}}>
                                <Typography sx={{color: '#000',textAlign:'center',mb: 0.5, fontWeight: 'bold'}} variant='h5'>DESCRIPCION</Typography>
                                <Typography sx={{color: '#000'}}>{item?.observacion_ptke}</Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='CERRAR'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{borderRadius: '8px !important'}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCancelarPreTicketView;