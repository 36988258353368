import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Typography } from '@mui/material';
import { ButtonBasic, CardNotifications, CardNotificationsMentions, CardViewTicket  } from '../../components';

const ModalDashTickets = ({show, hideModal, texto, items}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {texto}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    {items && items?.length > 0 && (
                        <>
                            {texto === 'Tickets Creados' &&(
                                items.map((it)=> <CardViewTicket item={it} onCloseModal={handleClose} key={it?.id_t} /> )
                            )}
                            {texto === 'Etiquetas' &&(
                                items.map((it)=> <CardNotificationsMentions item={it} onCloseModal={handleClose} key={it?.id_ment} /> )
                            )}
                            {texto === 'Asig. Grupal' &&(
                                items.map((it)=> <CardNotifications item={it} onCloseModal={handleClose} key={it?.id_notf} /> )
                            )}
                            {texto === 'Asig. Individual' &&(
                                items.map((it)=> <CardNotifications item={it} onCloseModal={handleClose} key={it?.id_notf} /> )
                            )}
                        </>
                    )}
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{width: 200}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalDashTickets