import { Box, Card, Grid, Typography } from "@mui/material";

const CardPreServicioItem = ({item}) => {
    return (
        <Card
            sx={{background:'#fff !important', boxShadow: 3,p:2,marginTop: 2,borderRadius:4 }}
        >
            <Typography sx={{fontWeight: 'bold', color: '#000',fontSize: 15}}>{`Nombre: ${item?.nombreServicio_ds}`}</Typography>
            <Grid container spacing={1} mt={0.5}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 14}} component='div'>Tipo:<Box fontWeight='normal' display='inline' ml={0.5}>{item?.tipoServicio_ds}</Box></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 14}} component='div'>Precio:<Box fontWeight='normal' display='inline' ml={0.5}>{`${item?.importeServicio_ds} ${item?.moneda_ds}`}</Box></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 14}} component='div'>Cantidad:<Box fontWeight='normal' display='inline' ml={0.5}>{item?.cantidad_ds}</Box></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 14}} component='div'>Vigencia:<Box fontWeight='normal' display='inline' ml={0.5}>{item?.vigencia_ds}</Box></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 14}} component='div'>Meses:<Box fontWeight='normal' display='inline' ml={0.5}>{item?.cantMeses_ds}</Box></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Observaciones:<Box fontWeight='normal' display='inline'>{item?.obs_ds}</Box></Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardPreServicioItem