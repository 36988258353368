import axios from 'axios';
const base = 'gobierno';

function registerContacto(data) {
    return axios.post([base, 'contacto'].join('/'), data);
}

function updateContacto(data) {
    return axios.put([base, 'contacto', data.id_c].join('/'), data);
}

function registerPreServicio(data) {
    return axios.post([base, 'preservicio'].join('/'), data);
}

const GobiernoService = {
    registerContacto,
    updateContacto,
    registerPreServicio,
};
export default GobiernoService;