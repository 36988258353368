import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectTratosxContacto } from '../../components';
import ContactosService from '../../services/contactos.service';

const ModalAddHistorico = ({show, hideModal, onGuardar, data}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        accion_lc: {
            texto: null,
            textoErr: 'La descripcion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        servicioId_lc: {
            texto: null,
            textoErr: 'El Servicio es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        preServicioId_lc: {
            texto: null,
            textoErr: 'El PreServicio es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['accion_lc']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(data?.cuentaId){
                form.cuentaId_lc = data?.cuentaId;
            }
            if(data?.contactoId){
                form.contactoId_lc = data?.contactoId;
            }
            if(data?.preServicioId){
                form.preServicioId_lc = data?.preServicioId;
            }
            if(data?.servicioId){
                form.servicioId_lc = data?.servicioId;
            }
            setIsLoading(false);
            await ContactosService.registerHistorico(form).then(()=>{
                if(onGuardar){
                    onGuardar()
                }
                setIsLoading(false);
                handleClose();
            }).catch(()=>{
                setIsLoading(false);
            })
        }else{
            setIsLoading(false)
        }
    }

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Historico
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        {!data?.hiddenSelect && (
                            <Grid item xs={12}>
                                <Typography sx={{color: '#000'}}>Servicio</Typography>
                                <SelectTratosxContacto
                                    id="contratosId"
                                    type='nubicom'
                                    isClearable
                                    filtro={data}
                                    onChange={(opt) => {
                                        if(opt?.contrato){
                                            onChangeForm(opt.value, 'servicioId_lc')
                                            onChangeForm(null, 'preServicioId_lc')
                                        }else if(opt?.value){
                                            onChangeForm(null, 'servicioId_lc')
                                            onChangeForm(opt.value, 'preServicioId_lc')
                                        }else{
                                            onChangeForm(null, 'servicioId_lc')
                                            onChangeForm(null, 'preServicioId_lc')
                                        }
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Descripcion</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.accion_lc.texto}
                                error={formAll.accion_lc.estadoErr}
                                helperText={formAll.accion_lc.estadoErr && formAll.accion_lc.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'accion_lc')}
                                sx={styleInput}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalAddHistorico;