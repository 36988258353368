import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectCantTiempoRecordatorio = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps,
    isMulti,
    tiempo = 'm'
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [dynamicOptions, setDynamicOptions] = useState([]);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const generateOptions = (type) => {
        let range = [];
        switch (type) {
            case 'm':
                range = Array.from({ length: 59 }, (_, i) => ({
                    value: i + 1,
                    label: `${i + 1}`
                }));
                break;
            case 'h':
                range = Array.from({ length: 23 }, (_, i) => ({
                    value: i + 1,
                    label: `${i + 1}`
                }));
                break;
            case 'd':
                range = Array.from({ length: 5 }, (_, i) => ({
                    value: i + 1,
                    label: `${i + 1}`
                }));
                break;
            default:
                range = [];
        }
        return range;
    };
    useEffect(() => {
        if (tiempo) {
            const newOptions = generateOptions(tiempo);
            setDynamicOptions(newOptions);
        } else {
            setDynamicOptions([]);
        }
    }, [tiempo]);
    useEffect(() => {
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(dynamicOptions.find((opt) => opt.value === value));
        }else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = dynamicOptions.find(it=>it.value === va);
                if(item){
                    return {
                        value:item.value,
                        label:item.label
                    };
                }
                return null
            }))
        }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={dynamicOptions}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectCantTiempoRecordatorio.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectCantTiempoRecordatorio;