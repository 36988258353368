import { Box, Button, IconButton } from '@mui/material';
import React, {useState, useCallback, useEffect} from 'react'
import {useDropzone} from 'react-dropzone';
import styled from '@emotion/styled';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
const StylesThumbContainer = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    img{
        object-fit: cover;
    }
`;

const StylesMessages = styled.div`
    font-size: small;
    height: 14;

    .dropzone-accepted{
        font-weight: bold;
    }
    .dropzone-rejected{
        color: red;
    }
`;

function InputImgDocument({
    id,
    defaultImage,
    backgroundImage,
    enablePreview,
    accept,
    onChange,
    setFoto,
    isDeletedNow
}) {
    const [files, setFiles] = useState([]);
    const [detalleDocument, setDetalleDocument] = useState(null);
    const [visible, setVisible] = useState(false);
    const extensionPermitida = ['png','jpg','jpeg','x-png'];
    const extensionPermitidaVideo = ['wmv','mp4','avi','mov'];
    const extensionPermitidaDoc = ['pdf','doc','docx','txt','xls','xlsx'];
    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles);
        setFiles(acceptedFiles?.map(file => {
            if(file?.size < 20000000){
                const nombreCortado = file?.name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setVisible(true)
                    return Object.assign(file, {preview: URL.createObjectURL(file)})
                }
                if(extensionPermitidaVideo.includes( extension )){
                    setDetalleDocument(`${file?.name || ''} - ${file?.size || ''}`)
                    return Object.assign('/img/tarjeta-tarea.png', {preview: '/img/tarjeta-tarea.png'})
                }
                if(extensionPermitidaDoc.includes( extension )){
                    setDetalleDocument(`${file?.name || ''} - ${file?.size || ''}`)
                    if(extension === 'pdf'){
                        return Object.assign('/img/pdf.png', {preview: '/img/pdf.png'})
                    }
                    if((extension === 'doc' || extension === 'docx')){
                        return Object.assign('/img/doc.png', {preview: '/img/doc.png'})
                    }
                    if(extension === 'txt'){
                        return Object.assign('/img/txt.png', {preview: '/img/txt.png'})
                    }
                    if((extension === 'xls' || extension === 'xlsx')){
                        return Object.assign('/img/xls.png', {preview: '/img/xls.png'})
                    }
                    return Object.assign('/img/foto.png', {preview: '/img/foto.png'})
                }
                return Object.assign('/img/sin_datos.png', {preview: '/img/sin_datos.png'})
            }
            setVisible(false)
            return Object.assign(backgroundImage, {preview: backgroundImage})
        }
        ));

    }, [])
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        maxFiles:1,
        accept: accept,
        multiple: false
    })
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file?.path} className='text-gray-800'>
            {`${file?.path} ${file?.size} bytes`}
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file?.path} className='text-gray-800'>
            {`${file?.path} ${file?.size} bytes`}
            <ul>
                {errors.map(e => (
                <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const thumbs = files?.map(file => (
        <StylesThumbContainer key={file?.name}>
            {file?.preview === 'dniFrente' && (
                <div className="imgDocument" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src='/img/dniFrente.png' />
                </div>
            )}
            {file?.preview === 'dniDorso' && (
                <div className="imgDocument" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src='/img/dniDorso.png' />
                </div>
            )}
            {file?.preview === 'documentImg' && (
                <div className="imgDocumentFile" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src='/img/documentImg.png' />
                </div>
            )}
            {file?.preview !== 'dniFrente' && file?.preview !== 'dniDorso' && file?.preview !== 'documentImg' &&(
                <Box sx={{display: 'flex',justifyContent: 'center', position: 'relative',border: 3, borderColor: '#15294b',borderRadius: 1}}>
                    <img
                        className="imgDocument"
                        src={file?.preview}
                        alt=""
                        style={{opacity:0.8}}
                    />
                    {(defaultImage?.length > 0 || defaultImage?.preview || visible) &&(
                        <IconButton
                            sx={{
                                zIndex: 9999,
                                display: 'inline-flex',
                                position: 'absolute',
                                top: '1%',
                                right: '3%',
                                background: '#fafbfb',
                                boxShadow:4,
                                color: '#15294b',
                                '&:hover':{background: '#fce7e7',color: '#e53939'}
                            }}
                            onClick={()=>{
                                setFiles([Object.assign(backgroundImage, {preview: backgroundImage})])
                                setVisible(false)
                                setFoto(null)
                                setDetalleDocument(null)
                            }}
                        >
                            <DeleteIcon sx={{fontSize: 25}} />
                        </IconButton>
                    )}
                    <IconButton
                        sx={{
                            zIndex: 9999,
                            display: 'inline-flex',
                            position: 'absolute',
                            top: '35%',
                            boxShadow:5,
                            left: '40%',
                            background: '#02b879',
                            '&:hover':{background: '#02b879'}
                        }}
                    >
                        <TaskAltIcon sx={{color: 'white',fontSize: 55}} />
                    </IconButton>
                </Box>
            )}
        </StylesThumbContainer>
    ));

    useEffect(() => {
        if(isDeletedNow){
            setFiles([Object.assign(backgroundImage, {preview: backgroundImage})])
            setVisible(false)
            setFoto(null)
        }
    }, [isDeletedNow])
    return (
        <section>
            <div {...getRootProps()}>
                <input {...getInputProps()} id={id} key={id} />
                {
                    files?.length === 0 && backgroundImage && (
                        defaultImage?.length > 0 ? (
                            <img className="imgDocument" src={defaultImage} alt="" />
                        ) : (
                            <>
                            {backgroundImage === 'dniFrente' && (
                                <div className="imgDocument" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src='/img/dniFrente.png' />
                                </div>
                            )}
                            {backgroundImage === 'dniDorso' && (
                                <div className="imgDocument" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src='/img/dniDorso.png' />
                                </div>
                            )}
                            {backgroundImage === 'documentImg' && (
                                <div className="imgDocumentFile" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src='/img/documentImg.png' />
                                </div>
                            )}
                            {backgroundImage !== 'dniFrente' && backgroundImage !== 'dniDorso' && backgroundImage !== 'documentImg' &&(
                                <img className="imgDocument" src={backgroundImage} alt="" />
                            )}
                            </>
                        )
                    )
                }
                {
                    (files?.length === 0 && !backgroundImage) && (
                        isDragActive ?
                        ( <p>Drop the files here ...</p> ):
                        ( <p>Drag n drop some files here, or click to select files</p> )
                    )
                }
                {
                    files?.length > 0 && !enablePreview && (
                        <i className="fas fa-file" />
                    )
                }
                {
                    files?.length > 0 && enablePreview && (
                        thumbs
                    )
                }
            </div>
            <StylesMessages>
                {visible ?(
                    <>
                    <div className="dropzona-rejected">{fileRejectionItems}</div>
                    </>
                ):(
                    detalleDocument && (
                        <div className="dropzone-accepted">
                            <li style={{color: '#000'}}>{detalleDocument}</li>
                        </div>
                    )
                )}
            </StylesMessages>
        </section>
    )
}

InputImgDocument.defaultProps = {
    accept: 'image/jpeg, image/png, image/jpg, image/svg, image/x-png',
    backgroundImage: null,
    defaultImage: null,
    enablePreview: true,
    id: '',
    onChange: () => {},
};

export default InputImgDocument;