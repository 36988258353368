import { useState, useEffect } from "react";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material"
import ProcesosService from "../../services/procesos.service";
import { useNavigate } from 'react-router-dom';
import { ButtonBasic, InputReadQuill } from "../../components";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ProcesosScreen = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState(null)
    const actualizar = async() =>{
        await ProcesosService.getAll().then((resp)=>{
            setItems(resp)
        })
    }
    useEffect(() => {
        actualizar();
        document.title = `Procesos`;
    }, [])
    const onClickProceso = (idProceso) =>{
        navigate(`/procesos/${idProceso}`)
    }
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={20} spacing={2}>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Procesos</Typography>
                </Grid>
                { items?.length > 0 && (
                    items?.map((it)=>(
                        <Grid item xs={12} md={4} key={`proceso${it.id_pro}`}>
                            <Card sx={{background: '#fff !important',boxShadow: 4,minHeight:30,borderRadius: 4}} className='cardDashboardTotal'>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h5" component="div" sx={{color: '#000', fontWeight: 'bold', textAlign: 'center' }}>
                                                {it?.nombre_pro}
                                            </Typography>
                                            <Divider sx={{mt:-1,background: 'gray'}} />
                                        </Grid>
                                        <Grid item xs={12} sx={{minHeight: 50, display:'flex', alignItems: 'center', justifyContent:'center',overflow: 'auto'}}>
                                            <InputReadQuill template={it?.descripcion_pro || ''} />
                                            {/* <Typography gutterBottom variant="h6 " component="div" sx={{color: '#000', textAlign: 'center' }}>
                                                {it?.descripcion_pro}
                                            </Typography> */}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonBasic
                                                textButton="Ver Proceso"
                                                fullWidth
                                                className='buttonCardDashboardTotal'
                                                disableElevation
                                                sx={{borderRadius: 20, background: '#fff',color:'#0364ff',fontWeight: 'bold', fontSize: 14,
                                                    '&:hover':{
                                                        background: '#0364ff'
                                                    }
                                                }}
                                                iconEnd={<ArrowForwardIcon sx={{display: 'none'}} className='iconCardDashboardTotal' />}
                                                onClick={()=>onClickProceso(it?.id_pro)}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    )
}

export default ProcesosScreen