import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EventIcon from '@mui/icons-material/Event';
import ListIcon from '@mui/icons-material/List';

const MenuOptionsConsulta = ({
  onRedirectConsulta,
  onClickNewRecordatorio,
  onClickViewRecordatorios
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon sx={{color: 'gray'}} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 225, maxWidth: '100%',background: '#fff !important' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>{setIsOpen(false); onRedirectConsulta()}}
          >
            <ListItemIcon>
              <ContentPasteGoOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Realizar venta" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>{ setIsOpen(false); onClickNewRecordatorio()}}
          >
            <ListItemIcon>
              <EventIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Crear recordatorio" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem
            sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }}
            onClick={()=>{setIsOpen(false); onClickViewRecordatorios()}}
          >
            <ListItemIcon>
              <ListIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Ver recordatorios" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
      </Menu>
    </>
  );
}

export default MenuOptionsConsulta