import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import FibraNapService from '../../services/fibraNap.service';
import { toast } from 'react-toastify';

const ModalCargarCoberturaRangoFO = ({show, hideModal, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [rango, setRango] = useState(80);
    const onSubmit = async() => {
        if(rango > 0){
            setIsLoading(true)
            await FibraNapService.registerCoberturaFO({rango}).then(()=>{
                if(onGuardar){
                    onGuardar();
                }
                toast.success('Se registro el nuevo rango de cobertura.', {
                    position: "top-right",
                    autoClose: 3500,
                    pauseOnHover: false,
                });
                handleClose();
            }).catch(()=>{
                setIsLoading(false)
            })
        }else{
            toast.error('El rango no puede ser 0.', {
                position: "top-right",
                autoClose: 3500,
                pauseOnHover: false,
            });
        }
    }
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Ingrese el nuevo rango de cobertura
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
                            <Typography sx={{color: '#000'}}>Ingrese un número</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={rango}
                                onChange={(e)=>setRango(e.target.value)}
                                sx={styleInput}
                                type='number'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!rango}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCargarCoberturaRangoFO;