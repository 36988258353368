import { useEffect, useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import FechaFunction from '../../function/FechaFunction';
import { Box, Card, Collapse, Divider, Typography } from '@mui/material';
import ButtonBasic from '../buttons/ButtonBasic';
import TextInfoTicket from '../text/TextInfoTicket';
import usePermisos from '../../hooks/usePermisos';

const ListInfoCam = ({
    tr,
    onModal,
    ticket,
    onModalPrefa,
    onModalItems
})=>{
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [detCamara, setDetCamara] = useState()
    const permisos = usePermisos();
    const excluirEstados = [
        'PENDIENTE DE ALTA EN SOFTLAND POR VALIDACION DE DOCUMENTACION',
        'INSTALADO',
        'NUEVO PRE-SERVICIO CORPORATIVO',
        'NUEVO PRE-SERVICIO GOBIERNO',
        'INSTALACION CANCELADA',
        'CANCELADO POR FALTA DE DOCUMENTACIÓN'
    ];
    useEffect(() => {
        if(tr && tr?.tipoMaterial_t === "CAMARA"){
            setDetCamara(tr)
        }
    }, [])
    return (
        <Box pl={2} pr={2} mt={1}>
            <ButtonBasic
                fullWidth
                onClick={()=>setOpen(!open)}
                textButton={detCamara ? tr?.nombre_t : `Pre-Servicio: #${tr?.id_t}`}
                sx={{
                    background: '#fff',
                    border: 1,
                    borderColor:'gray',
                    marginBottom: 1,
                    '&:hover':{
                        background: '#d1d1d1',
                        border: 1,
                        borderColor:'gray',
                    }
                }}
            />
            <Collapse in={open} sx={{border:1,borderColor:'#dfe2e6',borderRadius:3,boxShadow:3}}>
                <Box p={1}>
                    { detCamara ?(
                        <>
                            <li className="list-group-item">
                                <span>
                                    <i className="fas fa-video mr-1"></i>
                                </span>
                                <b>CVV</b>
                                <span className="float-right">
                                    {detCamara?.nombre_c}
                                </span>
                            </li>
                            <li className="list-group-item">
                                <span>
                                    <i className="fas fa-video mr-1"></i>
                                </span>
                                <b>Proyecto</b>
                                <span className="float-right">
                                    {detCamara?.proyecto_t}
                                </span>
                            </li>
                            <li className="list-group-item">
                                <span>
                                    <i className="fas fa-video mr-1"></i>
                                </span>
                                <b>Estado</b>
                                <span className="float-right">
                                    {detCamara?.estadoCamara_t}
                                </span>
                            </li>
                            {detCamara?.tipoCam_dcam || detCamara?.tecnologiaCam_dcam &&(
                                <li className="list-group-item">
                                    <span>
                                        <i className="fas fa-video mr-1"></i>
                                    </span>
                                        <b>Tipo</b>
                                    <span className="float-right">
                                            {`${detCamara?.tipoCam_dcam || ''} - ${detCamara?.tecnologiaCam_dcam || ''}`}
                                    </span>
                                </li>
                            )}
                            <li className="list-group-item">
                                <span>
                                    <i className="fas fa-video mr-1"></i>
                                </span>
                                    <b>Coordenadas</b>
                                <span className="float-right">
                                        {`${detCamara?.latitud_t || ''} - ${detCamara?.longitud_t || ''}`}
                                </span>
                            </li>
                        </>
                    ):(
                        <Box display='flex' justifyContent='center' flexDirection='column'>
                            { tr?.categoria_t &&
                                tr?.categoria_t !== 'COR' &&
                                !tr?.fechaInstalacionReal_t &&
                                (permisos['tratos.nubiConfirmInst'] || permisos['instalacion.cancelada.nubicom'] || permisos['instalacion.negativa.nubicom']) &&
                                (tr?.prefactibilidadRF_t !== 'NEGATIVA' || tr?.prefactibilidadFO_t !== 'NEGATIVA') &&
                                tr?.confirmPrefa_t !== 'NO' &&
                            (
                                <ButtonBasic
                                    onClick={() => onModal(tr?.id_t)}
                                    textButton='Confirmar Instalacion'
                                    variant='wk'
                                    color='nextStep'
                                    sx={{marginBottom: 1}}
                                    isDisabled={ticket?.cerrado_tk || ticket?.motivotk?.tipoTareaId_mt !== 3}
                                />
                            )}
                            {(permisos['prefa-change'] &&
                                (tr?.prefactibilidadRF_t === 'NEGATIVA' && tr?.prefactibilidadFO_t === 'NEGATIVA') &&
                                ticket?.motivo_tk === 65 &&
                                !ticket?.cerrado_tk ) &&
                            (
                                <ButtonBasic
                                    onClick={() => onModalPrefa(tr?.id_t)}
                                    textButton='Confirmar Prefactibilidad'
                                    variant='wk'
                                    color='nextStep'
                                    sx={{marginBottom: 1}}
                                    isDisabled={ticket?.cerrado_tk}
                                />
                            )}
                            { tr?.categoria_t &&
                                tr?.categoria_t !== 'COR' &&
                                !tr?.fechaInstalacionReal_t &&
                                permisos['prefa-change'] &&
                                (tr?.prefactibilidadRF_t === 'POSITIVA' || tr?.prefactibilidadFO_t === 'POSITIVA') &&
                                tr?.confirmPrefa_t === 'NO' &&
                            (
                                <ButtonBasic
                                    onClick={() => onModalItems(tr?.id_t)}
                                    textButton='Confirmar Items'
                                    variant='wk'
                                    color='nextStep'
                                    sx={{marginBottom: 1}}
                                    isDisabled={ticket?.cerrado_tk}
                                />
                            )}
                            <TextInfoTicket titulo='Nombre' descripcion={tr?.nombre_t} onClick={()=>navigate(`/pre-servicio/${tr?.id_t}/nubicom`)} />
                            <Divider sx={{background: '#8993a4',marginBottom:1}} />
                            {tr?.nivelVip_t && (
                                <>
                                    <TextInfoTicket titulo='Nivel VIP' descripcion={tr?.nivelVip_t} />
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                </>
                            )}
                            <TextInfoTicket titulo='Estado' descripcion={tr?.estadoServicio_t} />
                            <Divider sx={{background: '#8993a4',marginBottom:1}} />
                            {tr?.subEstadoServicio_t && (
                                <>
                                    <TextInfoTicket titulo='Sub-Estado' descripcion={tr?.subEstadoServicio_t} />
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                </>
                            )}
                            {tr?.fechaInstalacionReal_t&&(
                                <>
                                    <TextInfoTicket titulo='Fecha Instalacion' descripcion={FechaFunction.format( tr?.fechaInstalacionReal_t, 'dd/MM/yyyy' )} />
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                </>
                            )}
                            <TextInfoTicket titulo='Celular' descripcion={tr?.celular_t} />
                            <Divider sx={{background: '#8993a4',marginBottom:1}} />
                            <TextInfoTicket titulo='Email' descripcion={tr?.contactoEmail_t} />
                            <Divider sx={{background: '#8993a4',marginBottom:1}} />
                            <TextInfoTicket titulo='Categoria' descripcion={tr?.categoria_t} />
                            <Divider sx={{background: '#8993a4',marginBottom:1}} />
                            <TextInfoTicket titulo='Domicilio' descripcion={tr?.direccion_t} />
                            {tr?.prefactibilidadRF_t && (
                                <>
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                    <TextInfoTicket titulo='Prefactibilidad RF' descripcion={tr?.prefactibilidadRF_t} />
                                </>
                            )}
                            {tr?.prefactibilidadFO_t && (
                                <>
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                    <TextInfoTicket titulo='Prefactibilidad FO' descripcion={tr?.prefactibilidadFO_t} />
                                </>
                            )}
                            {tr && (tr?.lugarNombre_t || tr?.nombre_l) && (
                                <>
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                    <TextInfoTicket titulo='Lugar' descripcion={tr?.lugarNombre_t || tr?.nombre_l} />
                                </>
                            )}
                            {tr && tr?.tratoserv?.length > 0 &&(
                                tr.tratoserv.map((e)=>(
                                    <>
                                    <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                    <Card sx={{background: '#fff !important',border: 1,borderColor: '#0364ff'}}>
                                        <Typography sx={{textAlign: 'center', color: '#0247b5', fontWeight: 'bold'}}>
                                            {e?.nombreServicio_ds}
                                        </Typography>
                                        <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                        <TextInfoTicket titulo='Importe' descripcion={`${e?.importeServicio_ds} ${e?.moneda_ds}`} />
                                        <Divider sx={{background: '#8993a4',marginBottom:1}} />
                                        <TextInfoTicket titulo='Cantidad' descripcion={e?.cantidad_ds} />
                                    </Card>
                                    </>
                                ))
                            )}
                        </Box>
                    )}
                    {/* {tr && (tr?.lugarNombre_t || tr?.nombre_l) && (
                        <li className="list-group-item">
                            <span>
                                <i className="fas fa-map-marked-alt mr-1" />
                                <b></b>
                            </span>
                            <span className="float-right">
                                {}
                            </span>
                        </li>
                    )}
                    {tr?.obs_t &&(
                        <li className="list-group-item">
                            <b>Observaciones</b>
                            <span className="float-right">
                                {tr?.obs_t}
                            </span>
                        </li>
                    )} */}
                </Box>
            </Collapse>
        </Box>
    )
}

export default ListInfoCam