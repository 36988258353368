import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import DialogTitleDefault from '../default/DialogTitleDefault';

const ModalNotifyFilter = ({show, hideModal, filtro, setFiltro}) => {
    const handleClose = () => hideModal();
    const [newFiltro, setNewFiltro] = useState(filtro);
    const onClickFilter = (nombreKey) => {
        setNewFiltro({
            ...newFiltro,
            [nombreKey]: !newFiltro[nombreKey]
        })
    }
    const onSubmit = () => {
        setFiltro(newFiltro)
        handleClose()
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Filtro de Notificaciones
                    </Typography>
                    <Divider sx={{background: 'gray',mt: 1}} />
                </DialogTitleDefault>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={()=>onClickFilter('ticket')}
                                        checked={newFiltro?.ticket}
                                        sx={{
                                            color: '#0247B5',
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="Notificaciones de Tickets"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                sx={{marginTop:2}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={()=>onClickFilter('etiquetas')}
                                        checked={newFiltro?.etiquetas}
                                        sx={{
                                            color: '#0247B5',
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="Notificaciones de Etiquetas"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                sx={{marginTop:2}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={()=>onClickFilter('gral')}
                                        checked={newFiltro?.gral}
                                        sx={{
                                            color: '#0247B5',
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="Notificaciones de Generales"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                sx={{marginTop:2}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={()=>onClickFilter('soloNoVistas')}
                                        checked={newFiltro?.soloNoVistas}
                                        sx={{
                                            color: '#0247B5',
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="No vistas"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                sx={{marginTop:2}}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNotifyFilter