import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, InputDropZone, SelectTipoTecnologiaKmz } from '../../components';
import KmzService from '../../services/kmz.service';

const ModalCargarKmz = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [archivo, setArchivo] = useState(null);
    const [formAll, setFormAll] = useState({
        tipo: {
            texto: null,
            textoErr: 'El tipo es requerido',
            estadoErr: false,
            tipo: 'string'
        }
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['tipo']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            form.kmz = archivo;
            await KmzService.cargarKmzRF(form).then((resp)=>{
                setIsLoading(false);
                if(onGuardar){
                    onGuardar();
                }
                handleClose();
            }).catch(()=>{
                setIsLoading(false)
            })
        }else{
            setIsLoading(false)
        }
    }

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Subir archivo KMZ - Radio Enlace
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Tecnologia</Typography>
                            <SelectTipoTecnologiaKmz
                                fullWidth
                                autoComplete='off'
                                value={formAll.tipo.texto}
                                error={formAll.tipo.estadoErr}
                                helperText={formAll.tipo.estadoErr && formAll.tipo.textoErr}
                                onChange={(e)=>onChangeForm(e?.value, 'tipo')}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
                            <Typography sx={{color: '#000'}}>Archivo KMZ</Typography>
                            <Box sx={{width: 172}}>
                                <InputDropZone
                                    id="kmz"
                                    accept='.kmz, application/vnd.google-earth.kmz'
                                    onChange={setArchivo}
                                    setFoto={setArchivo}
                                    isDeletedNow={!archivo}
                                    backgroundImage="fileUpload"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCargarKmz;