import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectMotivoCorreoInvalido } from '../../components';
import PreTicketsService from '../../services/preTickets.service';

const ModalCancelarPreTicket = ({show, hideModal, item, onGuardar, hideModalPtk}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [motivoInvalido, setMotivoInvalido] = useState(null)
    const [obs, setObs] = useState(null)
    const handleClose = () => hideModal();
    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            id_ptke: item?.id_ptke,
            motivoCancelado_ptke: motivoInvalido,
            observacion_ptke: obs
        }
        await PreTicketsService.correoInvalido(form).then(()=>{
            hideModalPtk()
            onGuardar()
            handleClose()
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Eliminar Correo #${item?.idCorreo_ptke} - ${item?.subject_ptke}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000', alignItems:'center',mb: 0.5}}>* Seleccione el motivo:</Typography>
                            <SelectMotivoCorreoInvalido
                                value={motivoInvalido}
                                onChange={(e)=> setMotivoInvalido(e.label) }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>* Descripcion</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={obs}
                                error={!obs || obs?.length <= 0}
                                helperText={(!obs || obs?.length <= 0) && 'La observacion es requerida'}
                                onChange={(e)=>setObs(e.target.value)}
                                sx={styleInput}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='CERRAR'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{borderRadius: '8px !important'}}
                    />
                    <ButtonBasic
                        textButton='ELIMINAR CORREO'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='error'
                        isDisabled={!motivoInvalido || (!obs || obs?.length <= 0)}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCancelarPreTicket;