import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

const TextEstadoTicketIndv = ({estado,cerrado}) => {
    const [info, setInfo] = useState({
        color: '#74767f',
        background: '#e7e7e9',
        texto: 'En Proceso'
    })
    useEffect(() => {
        switch (estado) {
            case 'ABIERTO':
                setInfo({
                    color: '#018155',
                    background: '#E6F8F2',
                    texto: 'Abierto'
                })
                break;
            case 'CERRADO':
                setInfo({
                    color: '#74767f',
                    background: '#e7e7e9',
                    texto: 'Cerrado'
                })
                break;
            case 'PORVENCER':
                setInfo({
                    color: '#be7200',
                    background: '#fff2d1',
                    texto: 'Por Vencer'
                })
                break;
            case 'VENCEHOY':
                setInfo({
                    color: '#be7200',
                    background: '#fff2d1',
                    texto: 'Vence Hoy'
                })
                break;
            case 'VENCIDO':
                if(cerrado){
                    setInfo({
                        color: '#9d0b0b',
                        background: '#fce7e7',
                        texto: 'Cerrado-Vencido'
                    })
                }else{
                    setInfo({
                        color: '#9d0b0b',
                        background: '#fce7e7',
                        texto: 'Vencido'
                    })
                }
                break;
            default:
                break;
        }
    }, [estado])

    return (
        <Typography
            sx={{fontWeight: 'bold',color: info?.color,height:23,fontSize:12, width: 115,background: info?.background,display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
            component='div'
        >
            <Box fontWeight='bold' display='inline' sx={{fontSize: 15,color: info?.color,mr:0.2}}>•</Box>{info?.texto}
        </Typography>
    )
}

export default TextEstadoTicketIndv