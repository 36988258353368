import { useEffect, useState } from "react"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import AccionesService from "../../services/acciones.service";
import ModalPermisos from "../../modals/administrador/ModalPermisos";

const PermisosScreen = () => {
    const [items, setItems] = useState([]);
    const actualizar = async () => {
        await AccionesService.getAllAutorizados().then((resp)=>{
            setItems(resp)
        })
    };
    useEffect(() => {
        actualizar();
        document.title = 'Permisos';
    }, [])

    const columns = [
        {
            name: "id_r",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_r",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "personalNom",
            label: "Personal",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "acciones",
            label: "Permisos",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>console.log(tableMeta.rowData[0])}>
                                <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];

    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_r === item)
            showModal(ModalPermisos, {item: searchItem, onGuardar: actualizar});
        }else{
            showModal(ModalPermisos, {onGuardar: actualizar});
        }
    };


    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Permisos del sistema</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PermisosScreen