import axios from 'axios';
const baseRF = 'kmz/rf';
const baseFO = 'kmz/fo';

function coberturaRF(query = null) {
    return axios.get([baseRF, 'cobertura'].join('/'), {
        params: query
    });
}

function obtenerPoligonosRF() {
    return axios.get([baseRF,'poligonos'].join('/'));
}

function obtenerHistorialKMZRF() {
    return axios.get([baseRF,'historial'].join('/'));
}

function cargarKmzRF(data) {
    const form = new FormData();
    form.append('tipo',data.tipo);
    if(data?.kmz){
        if(Array.isArray(data.kmz) && data.kmz.length>0){
            form.append('kmz',data.kmz[0]);
        } else {
            form.append('kmz',data.kmz);
        }
    }
    return axios.post(`${baseRF}/kmz`, form,{
        headers: { 'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`}
    });
}

function coberturaFO(query = null) {
    return axios.get([baseFO, 'cobertura'].join('/'), {
        params: query
    });
}

function prefaCoberturaFO(query = null) {
    return axios.get([baseFO, 'prefa'].join('/'), {
        params: query
    });
}

function obtenerNapsFO() {
    return axios.get([baseFO,'naps'].join('/'));
}

function obtenerZonasFO() {
    return axios.get([baseFO,'zonas'].join('/'));
}

function obtenerHistorialKMZFO() {
    return axios.get([baseFO,'historial'].join('/'));
}

function cargarKmzFO(data) {
    const form = new FormData();
    if(data?.kmz){
        if(Array.isArray(data.kmz) && data.kmz.length>0){
            form.append('kmz',data.kmz[0]);
        } else {
            form.append('kmz',data.kmz);
        }
    }
    return axios.post(`${baseFO}/kmz`, form,{
        headers: { 'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`}
    });
}

const KmzService = {
    coberturaRF,
    obtenerPoligonosRF,
    obtenerHistorialKMZRF,
    cargarKmzRF,
    coberturaFO,
    obtenerNapsFO,
    obtenerZonasFO,
    obtenerHistorialKMZFO,
    cargarKmzFO,
    prefaCoberturaFO
};
export default KmzService;
