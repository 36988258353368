import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { ButtonBasic, InputDropZone } from '../../components';
import UserService from '../../services/personas.service';
import DownloadFile from '../../services/downloadFile.service';
import AuxiliarFunction from '../../function/AuxiliarFunction';

const ModalAddAsesores = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [perfilImg, setPerfilImg] = useState(null);
    const [perfilImgExist, setPerfilImgExist] = useState(null);
    const [contratoImg, setContratoImg] = useState(null);
    const [contratoImgExist, setContratoImgExist] = useState(null);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png,application/pdf';
    const acceptInputFile2 = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png';

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const [formAll, setFormAll] = useState({
        nombre_p: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        dni_p: {
            texto: null,
            textoErr: 'El DNI es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        email_p: {
            texto: null,
            textoErr: 'El correo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        telPersonal_p: {
            texto: null,
            textoErr: 'El telefono es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        domicilio_p: {
            texto: null,
            textoErr: 'El domicilio es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuentaBancaria_p: {
            texto: null,
            textoErr: 'El CBU/ALIAS es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {createdEmp_mt: 'NUBICOM'};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_p','dni_p','email_p','telPersonal_p','domicilio_p','cuentaBancaria_p']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        if((!perfilImg && !perfilImgExist) || (!contratoImg && !contratoImgExist)){
            isOk = true;
        }else{
            form.foto_p = perfilImg;
            form.contratoAsesor_p = contratoImg;
        }
        }))
        if(!isOk){
            if(item?.id_p > 0) {
                form.id_p = item.id_p
                await UserService.updateAsesor(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }else{
                await UserService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }
    const descargarFile = async() =>{
        const separar = contratoImgExist.split('url=')
        await AuxiliarFunction.downloadFile(DownloadFile.fileNubi({id:`asesores/${separar[1]}`}),`${separar[1] || 'no-name'}`)
    }
    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.nombre_p,'nombre_p')
                onChangeForm(item?.dni_p,'dni_p')
                onChangeForm(item?.email_p,'email_p')
                onChangeForm(item?.telPersonal_p,'telPersonal_p')
                onChangeForm(item?.domicilio_p,'domicilio_p')
                onChangeForm(item?.cuentaBancaria_p,'cuentaBancaria_p')
                if(item?.contratoAsesor_p){
                    setContratoImgExist(item?.contratoAsesor_p)
                }
                if(item?.foto_p){
                    setPerfilImgExist(item?.foto_p)
                }
            }, 100);
        }
    }, [item])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_p > 0 ? 'Editar Asesor' : 'Registrar Asesor'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Nombre Completo <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.nombre_p.texto}
                                error={formAll.nombre_p.estadoErr}
                                helperText={formAll.nombre_p.estadoErr && formAll.nombre_p.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'nombre_p')}
                                sx={styleInputHeight}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                CUIT <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                sx={styleInputHeight}
                                autoComplete='off'
                                type='number'
                                value={formAll.dni_p.texto}
                                onChange={(e)=>onChangeForm(Number(e.target.value),'dni_p')}
                                InputLabelProps={{shrink: formAll.dni_p.texto}}
                                inputProps={{ min: 1000000 , inputProps: { min: 1000000 } }}
                                error={formAll.dni_p.estadoErr}
                                helperText={formAll.dni_p.estadoErr && formAll.dni_p.textoErr}
                                FormHelperTextProps={{ className: '-mt-1' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Telefono <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                sx={styleInputHeight}
                                autoComplete='off'
                                type='number'
                                value={formAll.telPersonal_p.texto}
                                onChange={(e)=>onChangeForm(e.target.value,'telPersonal_p')}
                                InputLabelProps={{shrink: formAll.telPersonal_p.texto}}
                                error={formAll.telPersonal_p.estadoErr}
                                helperText={formAll.telPersonal_p.estadoErr && formAll.telPersonal_p.textoErr}
                                InputProps={{ inputProps: { min: 100000000 } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Correo <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.email_p.texto}
                                onChange={(e)=>onChangeForm(e.target.value,'email_p')}
                                InputLabelProps={{shrink: formAll.email_p.texto}}
                                error={formAll.email_p.estadoErr}
                                helperText={formAll.email_p.estadoErr && formAll.email_p.textoErr}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Cuenta bancaria <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.cuentaBancaria_p.texto}
                                onChange={(e)=>onChangeForm(e.target.value,'cuentaBancaria_p')}
                                InputLabelProps={{shrink: formAll.cuentaBancaria_p.texto}}
                                error={formAll.cuentaBancaria_p.estadoErr}
                                helperText={formAll.cuentaBancaria_p.estadoErr && formAll.cuentaBancaria_p.textoErr}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Domicilio <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.domicilio_p.texto}
                                onChange={(e)=>onChangeForm(e.target.value,'domicilio_p')}
                                InputLabelProps={{shrink: formAll.domicilio_p.texto}}
                                error={formAll.domicilio_p.estadoErr}
                                helperText={formAll.domicilio_p.estadoErr && formAll.domicilio_p.textoErr}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Foto de perfil <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            {!perfilImgExist ? (
                                <Box sx={{width: 172}}>
                                    <InputDropZone
                                        id="perfilImg"
                                        defaultImage={perfilImg}
                                        onChange={setPerfilImg}
                                        setFoto={setPerfilImg}
                                        isDeletedNow={!perfilImg}
                                        backgroundImage="fileUpload"
                                        accept={acceptInputFile2}
                                    />
                                </Box>
                            ):(
                                <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                    <img src={perfilImgExist} width={190} height={170} />
                                    <IconButton
                                        sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                        onClick={()=>setPerfilImgExist(null)}
                                    >
                                        <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                    </IconButton>
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                Contrato de servicio <Box sx={{fontWeight: 'bold',display: 'inline' }}>*</Box>
                                </Typography>
                            </Box>
                            { !contratoImgExist ? (
                                <Box sx={{width: 172}}>
                                    <InputDropZone
                                        id="contratoImg"
                                        defaultImage={contratoImg}
                                        onChange={setContratoImg}
                                        setFoto={setContratoImg}
                                        isDeletedNow={!contratoImg}
                                        backgroundImage="fileUpload"
                                        accept={acceptInputFile}
                                    />
                                </Box>
                            ):(
                                <Card sx={{background:'#fff !important',p:1, borderRadius:3,boxShadow:4,position: 'relative'}}>
                                    <img src='/img/pdf.png' width={190} height={170} />
                                    <IconButton
                                        sx={{top:4,position: 'absolute',right: 4,background:'white',boxShadow:4,'&:hover':{background:'#fce7e7',boxShadow:4}}}
                                        onClick={()=>setContratoImgExist(null)}
                                    >
                                        <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                    </IconButton>
                                    <IconButton
                                        sx={{top:4,position: 'absolute',left: 4,background:'white',boxShadow:4,'&:hover':{background:'#e6f8f2',boxShadow:4}}}
                                        onClick={()=>descargarFile()}
                                    >
                                        <DownloadIcon sx={{color: '#02b879'}} />
                                    </IconButton>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalAddAsesores