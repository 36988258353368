import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';

const ButtonBasic = ({
    tooltipText,
    children,
    isLoading,
    onClick,
    iconStart,
    iconEnd,
    variant='contained',
    color="secondary",
    textButton = '',
    isDisabled,
    ...otherProps
}) => {
    return (
        <React.Fragment>
            {tooltipText ? (
                <Tooltip title={tooltipText}>
                    <LoadingButton
                        loading={isLoading}
                        startIcon={iconStart}
                        endIcon={iconEnd}
                        onClick={onClick}
                        variant={variant}
                        color={color}
                        loadingPosition={iconStart ? "start": iconEnd ? 'end' : 'center' }
                        disabled={isDisabled}
                        {...otherProps}
                    >
                        {textButton}
                    </LoadingButton>
                </Tooltip>
            ):(
                <LoadingButton
                    loading={isLoading}
                    startIcon={iconStart}
                    endIcon={iconEnd}
                    onClick={onClick}
                    variant={variant}
                    color={color}
                    loadingPosition={iconStart ? "start": iconEnd ? 'end' : 'center' }
                    disabled={isDisabled}
                    {...otherProps}
                >
                    {textButton}
                </LoadingButton>
            )}
        </React.Fragment>
    )
}


export default ButtonBasic