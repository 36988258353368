import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Avatar, Box, Card, Divider, Grid, LinearProgress, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import FechaFunction from '../../function/FechaFunction';
import ObjetivosService from '../../services/objetivo.service';
import { styled } from '@mui/system';

const ModalVerObjetivo = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const handleClose = () => hideModal();

    const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: '#e0e0e0',
        '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            background: 'linear-gradient(90deg, rgba(0,61,153,1) 0%, rgba(0,101,255,1) 50%)',
        },
    }));
    const actualizar = async() =>{
        await ObjetivosService.getAll({keyObj : item?.keyObj_obj}).then((response) =>{
            setItems(response)
        })
    }
    useEffect(() => {
        actualizar()
    }, [])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`${item?.titulo_obj || ''} - ${item?.tipoTiempo_obj || ''}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1} sx={{minHeight: 300}}>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', boxShadow: 4, padding: 1, borderRadius: 3}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Typography sx={{color: '#000', fontSize: 17, fontWeight: 'bold'}}>
                                            Inicio
                                        </Typography>
                                        <Typography sx={{color: '#000', fontSize: 15}}>
                                            {item?.fechaIni}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Typography sx={{color: '#000', fontSize: 17, fontWeight: 'bold'}}>
                                            Fin
                                        </Typography>
                                        <Typography sx={{color: '#000', fontSize: 15}}>
                                            {item?.fechaFin}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Typography sx={{color: '#000', fontSize: 17, fontWeight: 'bold'}}>
                                            Categoria
                                        </Typography>
                                        <Typography sx={{color: '#000', fontSize: 15}}>
                                            {item?.tipoObj_obj}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Typography sx={{color: '#000', fontSize: 17, fontWeight: 'bold'}}>
                                            Cantidad
                                        </Typography>
                                        <Typography sx={{color: '#000', fontSize: 15}}>
                                            {item?.cantObj_obj}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', boxShadow: 4, padding: 1, borderRadius: 3}}>
                                {items.map((eTkCDep) =>{
                                    let fechaCompletado = false;
                                    if(eTkCDep?.fechaCompletado_obj){
                                        const fechaFin = new Date(eTkCDep.fechaCompletado_obj);
                                        fechaFin.setHours(fechaFin.getHours() + 3)
                                        fechaCompletado = FechaFunction.format(fechaFin,'dd/MM/yyyy HH:mm:ss');
                                    }
                                    return (
                                        <Box sx={{p: 1,mt: 1,'&:hover':{background: '#d1d1d1', borderRadius: 2, cursor: 'pointer'}}}>
                                            <Grid container>
                                                <Grid item xs={3} sm={1.5} md={1.5} lg={1.9} xl={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Avatar src={eTkCDep?.usuarioAsignado?.foto_p} />
                                                </Grid>
                                                <Grid item xs={9} lg={10} xl={10.5}>
                                                    <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                        {eTkCDep?.usuarioAsignado?.nombre_p || '-'}
                                                    </Typography>
                                                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <Box sx={{width: '80%',mt: 0}}>
                                                            <GradientLinearProgress
                                                                variant="determinate"
                                                                value={ (eTkCDep?.contador_obj / item?.cantObj_obj) * 100}
                                                            />
                                                            {eTkCDep?.fechaCompletado_obj && (
                                                                <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                                    {`Completado el ${fechaCompletado}`}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <Box sx={{width: '20%', mt: -1}}>
                                                            <Typography sx={{textAlign: 'center', ml: 1, color: '#000',fontWeight: 'bold',fontSize: 19,mt: -0.5}}>
                                                                {eTkCDep?.contador_obj || 0}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        variant='wk'
                        color='cancel'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalVerObjetivo