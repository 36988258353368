import {useCallback} from 'react'
import {useDropzone} from 'react-dropzone';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const InputFileNotaConsulta = ({
    id,
    backgroundImage,
    accept,
    onChange,
}) => {
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const extensionPermitidaVideo = ['wmv','mp4','avi','mov'];
    const extensionPermitidaDoc = ['pdf','doc','docx','txt','xls','xlsx','kmz','lpp'];
    const onDrop = useCallback(acceptedFiles => {
        const newFiles = acceptedFiles?.map(file => {
            if(file?.size < 20000000){
                const nombreCortado = file?.name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    return Object.assign(file, {preview: URL.createObjectURL(file),id:uuidv4()})
                }else if(extensionPermitidaVideo.includes( extension )){
                    return Object.assign(file, {preview: URL.createObjectURL(file),id:uuidv4()})
                }else if(extensionPermitidaDoc.includes( extension )){
                    if(extension === 'pdf'){
                        return Object.assign(file, {preview: '/img/pdf.png',id:uuidv4()})
                    }
                    if((extension === 'doc' || extension === 'docx')){
                        return Object.assign(file, {preview: '/img/doc.png',id:uuidv4()})
                    }
                    if(extension === 'txt'){
                        return Object.assign(file, {preview: '/img/txt.png',id:uuidv4()})
                    }
                    if((extension === 'xls' || extension === 'xlsx')){
                        return Object.assign(file, {preview: '/img/xls.png',id:uuidv4()})
                    }
                    return Object.assign(file, {preview: '/img/documentAll.png',id:uuidv4()})
                }
                return Object.assign('/img/documentAll.png', {preview: '/img/documentAll.png',id:uuidv4()})

            }
            return Object.assign(backgroundImage, {preview: backgroundImage})
        })
        onChange(newFiles)
    }, [])
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        maxFiles:5,
        accept: accept,
        multiple: true
    })
    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} id={id} key={id} />
            <IconButton>
                <AttachFileIcon sx={{color: '#15294b'}} />
            </IconButton>
        </div>
    )
}

InputFileNotaConsulta.defaultProps = {
    accept: 'image/jpeg, image/png, image/jpg, image/svg, image/x-png',
    backgroundImage: null,
    defaultImage: null,
    enablePreview: true,
    id: '',
    onChange: () => {},
};

export default InputFileNotaConsulta;