import { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { ButtonBasic, CardServicioSoftland } from '../../../components'
import { showModal } from 'react-redux-modal-provider'
import ModalTicketsServicio from '../../../modals/ticket/ModalTicketsServicio'

const TabCuentaServicio = ({tratos,ticketsAll}) => {
    const [tickets, setTickets] = useState([])
    const [servicios, setServicios] = useState([])
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const initialCard = () => {
        if(tratos?.length > 0){
            const newData = tratos.slice(0, 25);
            setData([...newData]);
            setCurrentIndex(25);
            if( 25 >= tratos.length){
                setHasMoreData(false);
            }else{
                setHasMoreData(true);
            }
        }else{
            setData([])
        }
    }
    const handleClick = () => {
        const endIndex = currentIndex + 25;
        const newData = tratos.slice(currentIndex, endIndex);
        setData(prevData => [...prevData, ...newData]);
        setCurrentIndex(endIndex);
        if(endIndex >= tratos.length){
            setHasMoreData(false);
        }else{
            setHasMoreData(true);
        }
    };

    useEffect(() => {
        if(Array.isArray(ticketsAll) && ticketsAll?.length > 0){
            setTickets(ticketsAll)
        }else{
            setTickets([])
        }
        initialCard()
    }, [ticketsAll])
    const onClickTk = (id) => {
        const findTicket = tickets.filter((e)=>e?.cuentaContratoId_txt === id);
        showModal(ModalTicketsServicio, {items: findTicket,servicioId: id});
    }
    return (
        <Box>
            <Grid container spacing={1}>
                {Array.isArray(data) && data?.length > 0 && (
                    <>
                        {data?.map((preS)=>(
                            <Grid item xs={12} key={`${preS?.contrato}grid`}>
                                <CardServicioSoftland
                                    item={preS}
                                    key={preS?.contrato}
                                    onClickTk={onClickTk}
                                />
                            </Grid>
                        ))}
                        {hasMoreData && (
                            <Grid item xs={12} display='flex' justifyContent='center' alignItems='center'>
                                <ButtonBasic
                                    onClick={()=>handleClick()}
                                    textButton='Cargar más'
                                    variant='wk'
                                    color='nextStep'
                                    sx={{width: 250}}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Box>
    )
}

export default TabCuentaServicio