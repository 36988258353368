import axios from 'axios';
const base = 'recordatorios';

function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function getById(id) {
    return axios.get([base,'rec', id].join('/'));
}

function register(data) {
    return axios.post(`${base}/nuevo`, data);
}

function registerComentario(data) {
    return axios.post(`${base}/comentario/${data?.recordatorioId_comrec}`, data);
}

function updateEstado(data) {
    return axios.post(`${base}/estado/${data?.id}`, data);
}

function getInfoContacto(query = null) {
    return axios.get(`${base}/info`, {
        params: query
    });
}

const RecordatorioService = {
    getAll,
    getById,
    register,
    registerComentario,
    updateEstado,
    getInfoContacto
};
export default RecordatorioService;
