import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ButtonBasic, SelectEstadoBaja, SelectEstadoNoLeInteresaPreServicio, SelectEstadoPreServicio, SelectEstadoReprogramar, SelectLugaresCalendar, SelectSubEstadoEntidadExterna, SelectSubEstadoNapNoDisponible } from '../../components';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TratoService from '../../services/tratos.service';

function ModalEstadoPreServicio({show, hideModal, item,onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [estadoSelect, setEstadoSelect] = useState(item?.estadoServicio_t || null);
    const [subEstado, setSubEstado] = useState(item?.subEstadoServicio_t || null);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        if(item?.id_t && estadoSelect){
            await TratoService.changeEstadoPreServicio({
                id_t: item?.id_t,
                estadoServicio_t: estadoSelect,
                subEstadoServicio_t: subEstado,
            }).then((resp)=>{
                if(resp?.id_t){
                    if(onGuardar){
                        onGuardar()
                    }
                    handleClose()
                }
            })
        }
        setIsLoading(true)
    };

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            Cambiar estado del servicio
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom:2}} />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Box sx={{width: 300}}>
                        <Typography sx={{color: '#000', fontWeight: 'bold',mb: 2}}>{`ESTADO ACTUAL: ${item?.estadoServicio_t}`}</Typography>
                        <Typography sx={{color: '#000'}}>Nuevo estado:</Typography>
                        <SelectEstadoPreServicio
                            value={estadoSelect}
                            onChange={(e)=>{setSubEstado(null); setEstadoSelect(e.value)}}
                            restringir
                        />
                        {estadoSelect === 'NAP NO DISPONIBLE' && (
                            <>
                                <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                <SelectSubEstadoNapNoDisponible
                                    value={subEstado}
                                    onChange={(e)=>setSubEstado(e.value)}
                                />
                            </>
                        )}
                        {estadoSelect === 'ACCION SUPEDITADA A ENTIDAD EXTERNA' && (
                            <>
                                <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                <SelectSubEstadoEntidadExterna
                                    value={subEstado}
                                    onChange={(e)=>setSubEstado(e.value)}
                                />
                            </>
                        )}
                        {estadoSelect === 'NO LE INTERESA' && (
                            <>
                                <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                <SelectEstadoNoLeInteresaPreServicio
                                    value={subEstado}
                                    onChange={(e)=>setSubEstado(e.value)}
                                    restringir
                                />
                            </>
                        )}
                        {estadoSelect === 'A REPROGRAMAR' && (
                            <>
                                <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                <SelectEstadoReprogramar
                                    value={subEstado}
                                    onChange={(e)=>setSubEstado(e.value)}
                                    restringir
                                />
                            </>
                        )}
                    </Box>
                </Box>
                <Divider color='gray' sx={{marginTop:2}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        isDisabled={!estadoSelect}
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalEstadoPreServicio.propTypes = {
    show: PropTypes.bool
};

ModalEstadoPreServicio.defaultProps = {
    show: false
};

export default ModalEstadoPreServicio;