import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardViewFilePreTicket } from '../../components';
import { PhotoConsumer } from 'react-photo-view';
import { showModal } from 'react-redux-modal-provider';
import ModalCancelarPreTicket from './ModalCancelarPreTicket';
import ModalCreatedTkToPtk from './ModalCreatedTkToPtk';
import ModalVincularPreTktoTK from './ModalVincularPreTktoTK';

const ModalVerPreTicket = ({show, hideModal, item, onGuardar,hideModalPtk}) => {
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('token');
    const handleClose = () => hideModal();
    const twoHandleClose = () => {
        if(hideModalPtk){
            hideModalPtk()
        }
        handleClose();
    }
    const onSubmitInvalid = async() => {
        showModal(ModalCancelarPreTicket,{item: item, onGuardar: onGuardar, hideModalPtk: twoHandleClose})
    }
    const onSubmitCreated = async() => {
        showModal(ModalCreatedTkToPtk,{item: item, onGuardar: onGuardar, hideModalPtk: twoHandleClose})
    }
    const onSubmitVincular = async() => {
        showModal(ModalVincularPreTktoTK,{item: item, onGuardar: onGuardar, hideModalPtk: twoHandleClose})
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Correo #${item?.idCorreo_ptke} - ${item?.subject_ptke}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:-1}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>De:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{item?.from_ptke}</Box></Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:0}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Asunto:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{item?.subject_ptke}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center',minHeight: 320}}>
                            <Divider sx={{background: 'gray',height: 2,width: '100%',mt: 0,mb:1}} />
                            <PhotoConsumer
                                key={`https://zeta.strongsystems.com.ar:8000/api/img?url=pretickets/${item?.imgBody_ptke}&token=${token}`}
                                src={`https://zeta.strongsystems.com.ar:8000/api/img?url=pretickets/${item?.imgBody_ptke}&token=${token}`}
                                intro={item?.imgBody_ptke}
                            >
                                <Card sx={{width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff !important', boxShadow: 4}}>
                                    <img
                                        src={`https://zeta.strongsystems.com.ar:8000/api/img?url=pretickets/${item?.imgBody_ptke}&token=${token}`}
                                        alt='dniFrente'
                                        style={{width: '90%', height: 320}}
                                    />
                                </Card>
                            </PhotoConsumer>
                        </Grid>
                        { item?.adjptk?.length > 0 && (
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
                                <Divider sx={{background: 'gray',height: 2,width: '80%',mt: 1,mb:1}} />
                                <Typography sx={{color: '#000', fontWeight: 'bold', textAlign:'center'}} variant='h5'>ARCHIVOS ADJUNTOS</Typography>
                                    <CardViewFilePreTicket arrFiles={item?.adjptk} />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='CERRAR'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{borderRadius: '8px !important'}}
                    />
                    <ButtonBasic
                        textButton='CORREO INVALIDO'
                        onClick={()=>onSubmitInvalid()}
                        isLoading={isLoading}
                        variant='wk'
                        color='error'
                    />
                    <ButtonBasic
                        textButton='CREAR TICKET'
                        onClick={()=>onSubmitCreated()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        sx={{borderRadius: '8px !important'}}
                    />
                    <ButtonBasic
                        textButton='VINCULAR TICKET'
                        onClick={()=>onSubmitVincular()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        sx={{borderRadius: '8px !important', background:'green', '&:hover':{background: 'green'}}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalVerPreTicket;