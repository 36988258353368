import axios from 'axios';

const base = 'contactos';
function getAll(query=null){
    return axios.get(base,{ params: query });
}

function register(data) {
    return axios.post(base, data);
}

function registerCambioDomicilio(data) {
    return axios.post('contactos/cambio/titular', data);
}

function registerCuentaCambioDomicilio(data) {
    return axios.post('contactos/cambio/cuentatitular', data);
}

function registerConsulta(data) {
    return axios.post('contactos/consulta', data);
}

function getById(id,query = null) {
    return axios.get([base, id].join('/'),{ headers: query });
}

function update(data) {
    return axios.put([base, data.id_c].join('/'), data);
}

function cancelarCambioTitular(id,data = null) {
    return axios.patch([base, id].join('/cambio/titular/'),data);
}

function registerInfo(data) {
    const form = new FormData();
    if(data?.id_c){
        form.append('id_c',data.id_c);
    }
    if(data?.type){
        form.append('type',data.type);
    }
    if(data?.cuenta){
        form.append('cuenta',data.cuenta);
    }
    if(data?.fnacimiento_icon){
        form.append('fnacimiento_icon',data.fnacimiento_icon);
    }
    if(data?.tipoCuit_icon){
        form.append('tipoCuit_icon',data.tipoCuit_icon);
    }
    if(Array.isArray(data?.dniFrente) && data?.dniFrente?.length>0){
        form.append('dniFrente',data.dniFrente[0]);
    } else {
        form.append('dniFrente',data.dniFrente);
    }
    if(Array.isArray(data?.dniDorso) && data?.dniDorso?.length>0){
        form.append('dniDorso',data.dniDorso[0]);
    } else {
        form.append('dniDorso',data.dniDorso);
    }
    if(Array.isArray(data?.constanciaAfip) && data?.constanciaAfip?.length>0){
        form.append('constanciaAfip',data.constanciaAfip[0]);
    } else {
        form.append('constanciaAfip',data.constanciaAfip);
    }
    if(Array.isArray(data?.contratoSocualEstatuto) && data?.contratoSocualEstatuto?.length>0){
        form.append('contratoSocualEstatuto',data.contratoSocualEstatuto[0]);
    } else {
        form.append('contratoSocualEstatuto',data.contratoSocualEstatuto);
    }
    if(Array.isArray(data?.desigActaActualizada) && data?.desigActaActualizada?.length>0){
        form.append('desigActaActualizada',data.desigActaActualizada[0]);
    } else {
        form.append('desigActaActualizada',data.desigActaActualizada);
    }
    if(Array.isArray(data?.poderApoderado) && data?.poderApoderado?.length>0){
        form.append('poderApoderado',data.poderApoderado[0]);
    } else {
        form.append('poderApoderado',data.poderApoderado);
    }
    if(Array.isArray(data?.dniFrenteApoderado) && data?.dniFrenteApoderado?.length>0){
        form.append('dniFrenteApoderado',data.dniFrenteApoderado[0]);
    } else {
        form.append('dniFrenteApoderado',data.dniFrenteApoderado);
    }
    if(Array.isArray(data?.dniDorsoApoderado) && data?.dniDorsoApoderado?.length>0){
        form.append('dniDorsoApoderado',data.dniDorsoApoderado[0]);
    } else {
        form.append('dniDorsoApoderado',data.dniDorsoApoderado);
    }
    return axios.post('contactos/info', form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getImg(query = null) {
    return axios.get('img', { params: query });
}

function getByIdCuenta(id,query = null) {
    return axios.get([base, id].join('/cuenta/'),{ headers: query });
}
function updateCuenta(data) {
    return axios.put([base, data.cuenta].join('/cuenta/'), data);
}

function registerCuenta(data) {
    return axios.post('contactos/cuenta', data);
}

function registerCuentaExistente(data) {
    return axios.post('contactos/contrato', data);
}

function updateCuentaSoftland(data) {
    return axios.post('contactos/update/cuenta', data);
}

function registerHistorico(data) {
    return axios.post('contactos/contacto/logs', data);
}


const ContactosService = {
    getAll,
    register,
    getById,
    update,
    registerInfo,
    getImg,
    getByIdCuenta,
    updateCuenta,
    registerCuenta,
    registerCuentaExistente,
    registerConsulta,
    registerCambioDomicilio,
    cancelarCambioTitular,
    registerCuentaCambioDomicilio,
    updateCuentaSoftland,
    registerHistorico
};
export default ContactosService;