import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const colorTokens = {
    grey: {
        0: "#FFFFFF",
        10: "#F6F6F6",
        50: "#F0F0F0",
        100: "#E0E0E0",
        200: "#C2C2C2",
        300: "#A3A3A3",
        400: "#858585",
        500: "#666666",
        600: "#4D4D4D",
        700: "#333333",
        800: "#1A1A1A",
        900: "#0A0A0A",
        1000: "#000000",
    },
    primary: {
        50: "#E6FBFF",
        100: "#CCF7FE",
        200: "#99EEFD",
        300: "#66E6FC",
        400: "#33DDFB",
        500: "#00D5FA",
        600: "#00A0BC",
        700: "#006B7D",
        800: "#00353F",
        900: "#001519",
    },
};
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
            grey: {
                100: "#e0e0e0",
                200: "#c2c2c2",
                300: "#a3a3a3",
                400: "#858585",
                500: "#666666",
                600: "#525252",
                700: "#3d3d3d",
                800: "#292929",
                900: "#141414",
            },
            primary: {
                100: "#d0d1d5",
                200: "#a1a4ab",
                300: "#727681",
                400: "#1F2A40",
                500: "#141b2d",
                600: "#101624",
                700: "#0c101b",
                800: "#080b12",
                900: "#040509",
            },
            greenAccent: {
                100: "#dbf5ee",
                200: "#b7ebde",
                300: "#94e2cd",
                400: "#70d8bd",
                500: "#4cceac",
                600: "#3da58a",
                700: "#2e7c67",
                800: "#1e5245",
                900: "#0f2922",
            },
            redAccent: {
                100: "#f8dcdb",
                200: "#f1b9b7",
                300: "#e99592",
                400: "#e2726e",
                500: "#db4f4a",
                600: "#af3f3b",
                700: "#832f2c",
                800: "#58201e",
                900: "#2c100f",
            },
            blueAccent: {
                100: "#e1e2fe",
                200: "#c3c6fd",
                300: "#a4a9fc",
                400: "#868dfb",
                500: "#6870fa",
                600: "#535ac8",
                700: "#3e4396",
                800: "#2a2d64",
                900: "#151632",
            },
            siderbar: {
                background: '#0F1222'
            },
            navBar:{
                background: '#1F2A40',
                background2: '#333d51'
            }
            }
        : {
            grey: {
                100: "#141414",
                200: "#292929",
                300: "#3d3d3d",
                400: "#525252",
                500: "#666666",
                600: "#858585",
                700: "#a3a3a3",
                800: "#c2c2c2",
                900: "#e0e0e0",
            },
            primary: {
                100: "#040509",
                200: "#080b12",
                300: "#0c101b",
                400: "#d1d1d1", // manually changed
                500: "#141b2d",
                600: "#1F2A40",
                700: "#727681",
                800: "#a1a4ab",
                900: "#d0d1d5",
            },
            greenAccent: {
                100: "#0f2922",
                200: "#1e5245",
                300: "#2e7c67",
                400: "#3da58a",
                500: "#4cceac",
                600: "#70d8bd",
                700: "#94e2cd",
                800: "#b7ebde",
                900: "#dbf5ee",
            },
            redAccent: {
                100: "#2c100f",
                200: "#58201e",
                300: "#832f2c",
                400: "#af3f3b",
                500: "#db4f4a",
                600: "#e2726e",
                700: "#e99592",
                800: "#f1b9b7",
                900: "#f8dcdb",
            },
            blueAccent: {
                100: "#151632",
                200: "#2a2d64",
                300: "#3e4396",
                400: "#535ac8",
                500: "#6870fa",
                600: "#868dfb",
                700: "#a4a9fc",
                800: "#c3c6fd",
                900: "#e1e2fe",
            },
            siderbar: {
                background: '#fff'
            },
            navBar:{
                background: '#ffffff'
            }
        }),
});
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
            ? {
                // palette values for dark mode
                primary: {
                    dark: colorTokens.primary[200],
                    main: colorTokens.primary[500],
                    light: colorTokens.primary[800],
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colorTokens.grey[100],
                    main: colorTokens.grey[200],
                    mediumMain: colorTokens.grey[300],
                    medium: colorTokens.grey[400],
                    light: colorTokens.grey[700],
                },
                background: {
                    default: colors.primary[500],
                },
                verdeSecondary:{
                    main: '#02B879'
                },
                backgroundSiderbar: {
                    default: colorTokens.grey[900],
                    alt: colorTokens.grey[800],
                },
            }
            : {
                // palette values for light mode
                primary: {
                    dark: colorTokens.primary[700],
                    main: colorTokens.primary[500],
                    light: colorTokens.primary[50],
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colorTokens.grey[700],
                    main: colorTokens.grey[500],
                    mediumMain: colorTokens.grey[400],
                    medium: colorTokens.grey[300],
                    light: colorTokens.grey[50],
                },
                background: {
                    default: colorTokens.grey[10],
                    alt: colorTokens.grey[0],
                },
                verdeSecondary:{
                    main: '#02B879'
                },
                backgroundSiderbar: {
                    default: "#343a40",
                },
            }),
        },
        components: {
            MuiTextField:{
                styleOverrides:{
                    root:{
                        borderColor: '#000'
                    },
                }
            },
            MuiButton:{
                defaultProps: {},
                variants: [
                    {
                        props: { variant: 'wk' },
                        style: {
                        backgroundColor: '#DADAFB',
                        '&:hover': {
                            backgroundColor: '#6a69e7',
                            opacity: 0.9,
                            color: 'white'
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'cancel' },
                        style: {
                        borderRadius: 20,
                        backgroundColor: '#a5a1a1',
                        '&:hover': {
                            backgroundColor: '#616161',
                            opacity: 0.9,
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'error' },
                        style: {
                        backgroundColor: '#f56d63',
                        '&:hover': {
                            backgroundColor: '#e71c0d',
                            opacity: 0.9,
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'send' },
                        style: {
                        backgroundColor: '#5C5BE5',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#5C5BE5',
                            opacity: 0.9,
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'blanco' },
                        style: {
                        backgroundColor: '#fff',
                        color: '#000',
                        '&:hover': {
                            backgroundColor: '#fff',
                            color: '#000',
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'downloadHome' },
                        style: {
                        backgroundColor: '#ebedf0',
                        color: '#6b788e',
                        borderRadius: 20,
                        borderWidth:10,
                        borderColor: '#000',
                        border: 1,
                        '&:hover': {
                            backgroundColor: '#c2c7d0',
                            color: '#6b788e',
                        },
                        "&:disabled": {
                            backgroundColor: 'gray',
                            opacity: 0.5,
                            color: '#e6f0ff'
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'nextStep' },
                        style: {
                        backgroundColor: '#0364ff',
                        color: '#fff',
                        borderRadius: 20,
                        borderWidth:10,
                        borderColor: '#000',
                        border: 1,
                        '&:hover': {
                            backgroundColor: '#0247b5',
                            color: '#fff',
                        },
                        "&:disabled": {
                            backgroundColor: '#9d9ea4',
                            opacity: 0.5,
                            color: '#e6f0ff'
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'nextWhite' },
                        style: {
                        backgroundColor: '#fff',
                        color: '#0364ff',
                        borderRadius: 20,
                        borderWidth:10,
                        borderColor: '#000',
                        border: 1,
                        '&:hover': {
                            backgroundColor: '#e6f0ff',
                            color: '#0364ff',
                        },
                        "&:disabled": {
                            backgroundColor: '#9d9ea4',
                            opacity: 0.5,
                            color: '#e6f0ff'
                        }
                        },
                    },
                    {
                        props: { variant: 'wk', color: 'prevStep' },
                        style: {
                        backgroundColor: '#fff',
                        color: '#0247b5',
                        borderRadius: 20,
                        borderWidth:1,
                        borderColor: '#0247b5',
                        border: 1,
                        '&:hover': {
                            backgroundColor: '#e6f0ff',
                            color: '#0247b5',
                        },
                        "&:disabled": {
                            backgroundColor: '#9d9ea4',
                            opacity: 0.5,
                            color: '#e6f0ff'
                        }
                        },
                    },
                ],
                styleOverrides:{
                    root:{
                        borderRadius:7,
                        textTransform: 'none'
                    },
                }
            },
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'white !important',
                        color: '#15294b !important',
                        border: 4
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'white !important',
                        color: '#15294b !important'
                    },
                },
            },
            MUIDataTableSelectCell: {
                styleOverrides: {
                    root: {
                    backgroundColor: 'white !important',
                    color: '#15294b !important'
                    },
                },
            },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                    '& .MuiToolbar-root': {
                        backgroundColor: 'white !important',
                        color: '#15294b !important'
                    },
                    },
                },
            },
        },
        typography: {
            fontSize: 12,
            fontFamily: 'Manrope',
            h1: {
                fontSize: 40,
            },
            h2: {
                fontSize: 32,
            },
            h3: {
                fontSize: 24,
            },
            h4: {
                fontSize: 20,
            },
            h5: {
                fontSize: 16,
            },
            h6: {
                fontSize: 14,
            },
        }
    }
}

export const ColorModeContext = createContext({
    toggleColorMode: () => {},
});
export const useMode = () => {
    const [mode, setMode] = useState("dark");
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
            setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),[]
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
};