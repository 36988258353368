import { useState,useEffect } from 'react';
import { Box, Card, Checkbox, Grid, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
const CardItemServicios = ({
    item,
    servicioSelect,
    setServicioSelect,
    coberturaMaxima,
    permisoEsp,
    disabledPrefa = false,
    permisoPrefa
}) => {
    const [mbBajada, setmbBajada] = useState(0)
    const [precio, setPrecio] = useState(0)
    useEffect(() => {
        const splite = item?.bajada?.split('.')
        if(splite?.length > 0){
            setmbBajada(splite[0])
        }
    }, [item])
    const onClickCard = () =>{
        if(coberturaMaxima >= mbBajada || permisoEsp){
            setServicioSelect({...item,prefactibilidad: 'POSITIVA'})
        }else if(!disabledPrefa && permisoPrefa){
            if(item?.tipo?.trim() === 'PY'){
                setServicioSelect({...item, prefactibilidad: 'NEGATIVA'})
            }
        }
    }
    useEffect(() => {
        if(item?.precio){
            const numero = Number(item?.precio)
            if(!isNaN(numero)){
                setPrecio(Math.round(numero * 100) / 100);
            }
        }
    }, [item])

    return (
        <Card
            sx={{
                background: '#fff !important',
                boxShadow: 3,
                marginTop: 2,
                opacity:(
                    coberturaMaxima >= mbBajada ||
                    permisoEsp ||
                    (servicioSelect?.cod_producto === item?.cod_producto && servicioSelect?.prefactibilidad === 'NEGATIVA' )
                ) ? 1 : 0.5
            }}
        >
            <Grid container p={3}>
                <Grid item xs={2} md={2} display='flex' justifyContent='center' alignItems='center'>
                    <Checkbox
                        checked={servicioSelect?.cod_producto === item?.cod_producto}
                        icon={<RadioButtonUncheckedOutlinedIcon sx={{color: 'gray',fontSize: 30}} />}
                        checkedIcon={<CheckCircleIcon sx={{color: '#02b879', fontSize: 30}} />}
                        onClick={()=>onClickCard()}
                    />
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography sx={{color: 'gray',marginTop:-1}} variant='h6'>{item?.tipo_descripcion}</Typography>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginTop:1}} variant='h4'>{item?.nombre}</Typography>
                    { servicioSelect?.cod_producto === item?.cod_producto && servicioSelect?.prefactibilidad === 'NEGATIVA' && (
                        <>
                            <Typography sx={{color: 'red',marginTop:1,fontWeight: 'bold',fontSize: 13}} variant='h6'>
                                SIN COBERTURA
                            </Typography>
                            <Typography sx={{color: 'red',marginTop:0,fontSize: 12}} variant='h6'>
                                Se solicitará un estudio de prefactibilidad en RF y FO.
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={3} md={3} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h4'>{`${mbBajada} ${item?.unidad_bajada}`}</Typography>
                    {precio > 0 && (
                        <Box
                            sx={{
                                border: 1,
                                borderColor: 'gray',
                                borderRadius:50,
                                minWidth:95,
                                color: '#6b788e',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                minHeight:30,
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                mt:1,
                                fontSize: 12
                            }}
                        >
                            {`$ ${precio} /mes`}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardItemServicios