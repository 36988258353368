import { showModal } from 'react-redux-modal-provider';
import DialogTitleDefault from '../default/DialogTitleDefault';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ButtonBasic } from '../../components';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import ModalCrearTicketStrong from './ModalCrearTicketStrong';
import ModalCrearTicketIDS from './ModalCrearTicketIDS';
import ModalCrearTicketNubicomMasivos from './ModalCrearTicketNubicomMasivos';
import ModalCrearTicketNubicomV2 from './ModalCrearTicketNubicomV2';

function ModalNewTickets({show, hideModal,onGuardar,permisos}) {

    const handleClose = () => hideModal();
    const onClickTicketNuevoCam = () => {
        showModal(ModalCrearTicketIDS, { onGuardar: onGuardar });
        hideModal()
    }
    const onClickTicketNuevoServiceStr = () => {
        showModal(ModalCrearTicketStrong, { onGuardar: onGuardar });
        hideModal()
    }
    const onClickTicketNubi = () => {
        showModal(ModalCrearTicketNubicomV2, { onGuardar: onGuardar });
        hideModal()
    }
    const onClickTicketNubiMasivo = () => {
        showModal(ModalCrearTicketNubicomMasivos, { onGuardar: onGuardar });
        hideModal()
    }
    const styleBtn = useMemo(() => ({
        mt:5,
        border:2,
        borderRadius:5,
        borderColor: '#0364ff',
        color: '#0364ff',
        fontWeight: 'bold',
        width: 180,
        '&:hover':{
            color: '#0364ff',
            background: '#e6f0ff'
        }
    }), [])
    const styleBtn2 = useMemo(() => ({
        mt:1,
        border:2,
        borderRadius:5,
        borderColor: '#0364ff',
        color: '#0364ff',
        fontWeight: 'bold',
        width: 180,
        '&:hover':{
            color: '#0364ff',
            background: '#e6f0ff'
        }
    }), [])
    const styleCard = useMemo(() => ({
        background: '#fff !important',
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection: 'column',
        p:3,
        boxShadow:0,
        border:1,
        borderColor: 'gray',
        borderRadius:3,
        minHeight: 270,
        width: 250,
        '&:hover':{
            boxShadow: 6
        }
    }), [])
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                    Nuevo Ticket
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container>
                        <Grid item xs={12} sm={4} p={1} display='flex' justifyContent='center' alignItems='center'>
                            <Card sx={styleCard}>
                                <img src='/img/nubicom.png' style={{width: 150,height: 55,marginTop:47}} />
                                <ButtonBasic
                                    textButton='Clientes Nubicom'
                                    color='blanco'
                                    variant='wk'
                                    sx={styleBtn}
                                    onClick={()=>onClickTicketNubi()}
                                />
                                <ButtonBasic
                                    textButton='Tickets Masivos'
                                    color='blanco'
                                    variant='wk'
                                    sx={styleBtn2}
                                    onClick={()=>onClickTicketNubiMasivo()}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} p={1} display='flex' justifyContent='center' alignItems='center'>
                            <Card sx={styleCard}>
                                <img src='/img/strong-logo.png' style={{width: 120,height: 120,marginTop:0}} />
                                <ButtonBasic
                                    textButton='Servicios Strong'
                                    color='blanco'
                                    isDisabled
                                    variant='wk'
                                    /* sx={styleBtn} */
                                    sx={{
                                        mt:5,
                                        border:2,
                                        borderRadius:5,
                                        borderColor: '#a6aebb',
                                        color: '#a6aebb !important',
                                        fontWeight: 'bold',
                                        width: 180,
                                        '&:hover':{
                                            color: '#a6aebb',
                                            background: '#a6aebb'
                                        }
                                    }}
                                    onClick={()=>onClickTicketNuevoServiceStr()}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} p={1} display='flex' justifyContent='center' alignItems='center'>
                            <Card sx={styleCard}>
                                <img src='/img/logo2.png' style={{width: 120,height: 120,marginTop:0}} />
                                <ButtonBasic
                                    textButton='Camaras CVV'
                                    isDisabled
                                    color='blanco'
                                    variant='wk'
                                    /* sx={styleBtn} */
                                    sx={{
                                        mt:5,
                                        border:2,
                                        borderRadius:5,
                                        borderColor: '#a6aebb',
                                        color: '#a6aebb !important',
                                        fontWeight: 'bold',
                                        width: 180,
                                        '&:hover':{
                                            color: '#a6aebb',
                                            background: '#a6aebb'
                                        }
                                    }}
                                    onClick={()=>onClickTicketNuevoCam()}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        sx={{width: '80%',mb:1}}
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalNewTickets;