
import RouteValidate from './routes/RouteValidate';
import ModalProvider from 'react-redux-modal-provider';
import {ColorModeContext, useMode} from './themes/themes'
import store from './store/index';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import { CssBaseline, StyledEngineProvider,ThemeProvider } from '@mui/material';
import { PhotoProvider } from 'react-photo-view';
import SocketProvider from './context/SocketContext';
import "react-datepicker/dist/react-datepicker.css";
import 'react-photo-view/dist/index.css';
const App = () => {
    const [theme, colorMode] = useMode();
    return (
        <ColorModeContext.Provider value={colorMode}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <PhotoProvider>
                        <Provider store={store}>
                            <CssBaseline />
                            <SocketProvider>
                                <BrowserRouter>
                                    <RouteValidate />
                                    <ModalProvider />
                                </BrowserRouter>
                            </SocketProvider>
                        </Provider>
                    </PhotoProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ColorModeContext.Provider>
    )
}

export default App