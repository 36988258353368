import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectAcciones, SelectUser } from '../../components';
import AccionesService from '../../services/acciones.service';

const ModalPermisos = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        nombre_r: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        acciones: {
            texto: [],
            textoErr: 'Las acciones son requeridas',
            estadoErr: false,
            tipo: 'string'
        },
        personas: {
            texto: [],
            textoErr: 'El personal es requerido',
            estadoErr: false,
            tipo: 'string'
        }
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if(!value || value === ''){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_r','acciones','personas']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(item?.id_r > 0) {
                form.id_r = item.id_r
                AccionesService.update(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(true);
                    });
            }else{
                AccionesService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(true);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(item){
            onChangeForm(item?.nombre_r,'nombre_r')
            if(item.users?.length > 0){
                onChangeForm(item.users?.map(ur=>ur?.id_p),'personas')
            }
            if(item.autorizado?.length > 0){
                onChangeForm(item.autorizado?.map(au=>au?.accionId_rxa),'acciones')
            }
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_r > 0 ? 'Editar Rol' : 'Registrar Rol'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.nombre_r.texto}
                                error={formAll.nombre_r.estadoErr}
                                helperText={formAll.nombre_r.estadoErr && formAll.nombre_r.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'nombre_r')}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Acciones</Typography>
                            <SelectAcciones
                                value={formAll.acciones.texto}
                                onChange={(opt)=>onChangeForm(opt.map(op=>(op.value)),'acciones')}
                                isMulti
                            />
                            {formAll.acciones.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.acciones.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Usuarios</Typography>
                            <SelectUser
                                value={formAll.personas.texto}
                                filter='all'
                                onChange={(opt) => onChangeForm(opt.map(op=>(op.value)),'personas')}
                                isMulti
                            />
                            {formAll.personas.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.personas.textoErr}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={formAll?.acciones?.texto?.length === 0 || formAll?.personas?.texto?.length === 0}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalPermisos