import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components';

const Styles = styled.div`
    .ql-toolbar{
        display: none;
    }
    .ql-container.ql-snow{
        border-color: transparent !important;
    }
    .ql-container{
        color: #000;
    }
    span.mention {
        color: blue;
        background: lightskyblue;
    }
`;

const InputReadQuill =({onChangeText,template,id}) => {
    const [value, setValue] = useState(template || '')
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline','strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'header': [2, 3, false] }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    }
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent','script','background','color','mention'
    ]
    const onChangeTemplete = (newEditor) =>{
        setValue(newEditor)
        if(onChangeText){
            onChangeText(newEditor)
        }
    }
    useEffect(() => {
        setValue(template || '')
    }, [template])

    return (
        <Styles>
            <ReactQuill
                id={id}
                theme="snow"
                value={value}
                onChange={onChangeTemplete}
                modules={modules}
                formats={formats}
                readOnly
            />
        </Styles>
    )
}

export default InputReadQuill
