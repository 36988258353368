import axios from 'axios';

const base = 'contactos';
function getAllContacto(query=null){
    return axios.get(base,{ params: query });
}

function registerContacto(data) {
    return axios.post(base, data);
}

function registerConsulta(data) {
    return axios.post('contactos/consulta', data);
}

function registerImg(data) {
    const form = new FormData();
    form.append('id_c',data.id_c);
    form.append('type','nubicom');
    form.append('dniFrente',data.dniFrente);
    form.append('dniDorso',data.dniDorso);
    form.append('tipoCuit_icon',data.tipoCuit_icon);
    return axios.post('contactos/info', form,{
        headers: {
            "Accept": 'application/json',
            "mimeType": "multipart/form-data",
            "Content-Type": `multipart/form-data;`
        },
    });
}

const AsesorService = {
    getAllContacto,
    registerContacto,
    registerConsulta,
    registerImg
};
export default AsesorService;
