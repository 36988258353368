import {useCallback, useState,useMemo} from 'react'
import { Autocomplete } from '@react-google-maps/api';
import styled from 'styled-components';
import ButtonBasic from '../buttons/ButtonBasic';
import {useWindowWidth} from '@react-hook/window-size';
import {showModal} from 'react-redux-modal-provider';
import ModalInsertCood from '../../modals/mapa/ModalInsertCood';
import { TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const StylesContainer = styled.div`

    .control-panel-map-type{
        position: absolute;
        bottom: ${props => props.isMovil?'16px':'16px'};
        right: 10px;
        flex-direction: column;
        display: flex;
    }
    .control-panel-zoom{
        position: absolute;
        bottom: ${props => `${props.bottomMin}px`};
        right: 10px;
        flex-direction: column;
        display: flex;
    }
`;

function ControlPanel({
    bottomMin,
    coordinates,
    zoom,
    map,
    options,
    mapTypeId,
    isSearchable,

    onChangeMapType,
    onChangeZoom,
    onSearch,
    insertCoord
}) {
    const onlyWidth = useWindowWidth();
    const [searchBox, setSearchBox] = useState(null);

    const onClickMapType = (newMapTypeId) => {
        if((mapTypeId === 'roadmap' && newMapTypeId === 'satellite') || (mapTypeId === 'satellite' && newMapTypeId === 'satellite')){
            onChangeMapType('hybrid');
        } else {
            onChangeMapType(newMapTypeId);
        }
    }

    const onLoadSearchBox = useCallback(function callback(newSearchBox) {
        setSearchBox(newSearchBox);
    }, []);

    const onPlaceChanged = () => {
        const field = searchBox.getPlace();
        if(field.geometry){
            const newCoords = {
                lat: field.geometry.location.lat(),
                lng: field.geometry.location.lng(),
            }
            map.setCenter(newCoords);
            onSearch({
                coordenadas: newCoords,
                direccion: field.address_components[0].long_name,
            })
        }
    };

    const defaultBounds = {
        north: coordinates.lat + 0.1,
        south: coordinates.lat - 0.1,
        east: coordinates.lng + 0.1,
        west: coordinates.lng - 0.1,
    };
    const modalCoordenadas = (coord) =>{
        map.setCenter(coord);
        onSearch({
            coordenadas: coord,
            direccion: coord,
        })
    }

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: '#000',
            borderRadius:20,
            fontSize: 15,
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderRadius:20,
            borderColor: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                borderRadius:20,
                color: '#000',
                borderColor: '#fff'
            },
            '&:hover':{
                border: 1,
                borderRadius:20,
                background: '#fff',
                borderColor: '#fff',
                boxShadow: 3
            },
            '&.Mui-focused': {
                '&:hover':{
                    background: '#fff',
                    borderRadius:20,
                    border:0
                }
            },
            background: '#fff',
            color: '#000',
            borderRadius:20,
            height: 35,
            boxShadow: 4
        }
    }), [])
    const styleToggle = useMemo(() => ({
        background: '#fff !important',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,
        '&.MuiToggleButton-root':{
            border:3,
            background: '#fff !important',
        },
        '&.Mui-selected':{
            background: '#fff !important',
            color: '#0364FF',
            borderColor: '#0364FF',
            border:3,
            opacity:1,
            '&:hover':{
                background: '#fff !important',
                color: '#0364FF',
                borderColor: '#0364FF',
                border:3,
            }
        },
        '&:hover':{
            background: '#fff !important',
            color: '#0364FF',
            borderColor: '#0364FF',
            border:3,
        }
    }),[]);
    const isMovil = onlyWidth < 992;
    const handleChange = (event, nextView) => {
        onChangeMapType(nextView);
    };
    return (
        <StylesContainer isMovil={isMovil} bottomMin={bottomMin}>
            <div className="control-panel-map-type">
                <ToggleButtonGroup
                    orientation="vertical"
                    value={mapTypeId}
                    exclusive
                    onChange={handleChange}
                    sx={{background: '#fff !important',borderRadius: 2}}
                    >
                    <ToggleButton
                        value="roadmap"
                        aria-label="roadmap"
                        sx={styleToggle}
                    >
                        <Typography sx={{fontSize: 13,fontWeight: 'bold'}}>Normal</Typography>
                    </ToggleButton>
                    <ToggleButton
                        value="hybrid"
                        aria-label="hybrid"
                        sx={styleToggle}
                    >
                        <Typography sx={{fontSize: 13,fontWeight: 'bold'}}>Satelite</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {
                isSearchable && (
                    <Autocomplete
                        onLoad={onLoadSearchBox}
                        onPlaceChanged={onPlaceChanged}
                        options={{
                            bounds: defaultBounds,
                            origin: coordinates,
                            componentRestrictions: {country: "AR"}
                        }}
                    >
                        <TextField
                            className="mt-2"
                            type="text"
                            autoComplete='off'
                            InputProps={{
                                type: 'search',
                                startAdornment:(
                                    <SearchIcon sx={{color: '#000',opacity: 0.8}} size={20} />
                                )
                            }}
                            sx={{
                                ...styleInput,
                                boxSizing: `border-box`,
                                width: {xs:`250px`,md: '300px'},
                                padding: `0 12px`,
                                borderRadius: `10px`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "15px",
                            }}
                        />
                    </Autocomplete>
                )
            }
        </StylesContainer>
    )
}

ControlPanel.defaultProps = {
    bottomMin: 10,
    isSearchable: true,

    onChangeMapType: () => {},
    onSearch: () => {},
    onChangeZoom: () => {},
}

export default ControlPanel

