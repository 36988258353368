import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FechaFunction from '../../function/FechaFunction';

const LogsTicketOld = ({
    item,
    type,
    handleClose
}) => {
    const navigate = useNavigate();
    const redirect = ()=>{
        if(handleClose){
            handleClose()
        }
        switch (type) {
            case 'nubicom':
                navigate(`/ticket/${item.id_tk}/nubicom`)
                break;
            case 'strong':
                navigate(`/ticket/${item.id_tk}/strongsystems`)
                break;
            case 'ids':
                navigate(`/ticket/${item.id_tk}/ids`)
                break;
            case 'cvv':
                navigate(`/ticket/${item.id_tk}/ids`)
                break;
            default:
                navigate(`/ticket/${item.id_tk}/cvv`)
                break;
        }
    }
    return (
        <Card sx={{background: '#fff !important',boxShadow: 5, borderRadius:3, cursor: 'pointer'}} onClick={()=> redirect()}>
            <Grid container p={1}>
                <Grid item xs={3} md={2}>
                    <Box
                        sx={{borderRadius:50, height:70, width:70, border: 'none',boxShadow:4, background: 'gray',display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Typography sx={{color: '#fff'}}>{item.id_tk}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9} display='flex' justifyContent='left' flexDirection='column'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',mt: 1}} variant='h5'>{`Titulo: ${item.titulo_tk}`}</Typography>
                    <Typography sx={{color: 'gray', textAlign:'right',mt:3,fontSize: 12}}>
                        {item && item.cerrado_tk ? (
                            `- Estado: Cerrado ${FechaFunction.format(new Date(item.cerrado_tk),'dd/MM/yyyy HH:mm:ss')}`
                        ):(
                            `- Estado: Abierto ${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

LogsTicketOld.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default LogsTicketOld;
