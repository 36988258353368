import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill, SelectInsertEmail, SelectRemitenteEmail } from '../../components';
import PlantillaEmailService from "../../services/plantillaEmails.service";

const ModalPlantillaEmail = ({show, hideModal, item,onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [remitente, setRemitente] = useState('helpdesk@nubicom.com.ar');
    const [asunto, setAsunto] = useState('');
    const [emails, setEmails] = useState([]);
    const [cuerpo, setCuerpo] = useState('');
    const handleClose = () => hideModal();
    useEffect(() => {
        if(item?.id_pmail > 0){
            setTimeout(() => {
                setRemitente('helpdesk@nubicom.com.ar')
                setAsunto(item?.asunto_pmail || '')
                setEmails(item?.ccArr_pmail || [])
                setCuerpo(item?.cuerpo_pmail || '')
            }, 200);
        }
    }, [item])

    const onSubmit = () => {
        if(remitente && asunto && cuerpo){
            setIsLoading(true)
            const form = {
                correo_pmail: 'helpdesk@nubicom.com.ar',
                asunto_pmail: asunto,
                ccArr_pmail: emails,
                cuerpo_pmail: cuerpo
            }
            if(item?.id_pmail > 0){
                form.id_pmail = item?.id_pmail
                PlantillaEmailService.update(form).then(()=>{
                    onGuardar()
                    handleClose()
                }).catch(()=>{
                    setIsLoading(false)
                })
            }else{
                PlantillaEmailService.register(form).then(()=>{
                    onGuardar()
                    handleClose()
                }).catch(()=>{
                    setIsLoading(false)
                })
            }
        }
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_pmail > 0 ? 'Editar Plantilla Email' : 'Nueva Plantilla Email'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center',flexDirection: 'column'}}>
                        <Typography sx={{color: '#000'}} variant='h6'>Remitente</Typography>
                        <SelectRemitenteEmail
                            value={remitente}
                            onChange={(e)=>setRemitente(e.value)}
                            disabled
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>CC</Typography>
                        <SelectInsertEmail
                            id="selectEmails"
                            value={emails}
                            onChange={setEmails}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>Asunto</Typography>
                        <TextField
                            id='cuerpoEmail'
                            key='cuerpoEmail'
                            autoComplete='off'
                            focused
                            InputProps={{ style:{fontSize:14,color: '#000'} }}
                            value={asunto}
                            onChange={(e)=>setAsunto(e.target.value)}
                            sx={{
                                '& fieldset': {
                                    borderRadius: 1
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'gray',
                                    },
                                }
                            }}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>Cuerpo</Typography>
                        <InputEditQuill
                            id="Comentario"
                            template={ cuerpo ||''}
                            onChangeText={(html)=>{
                                setCuerpo(html)
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        variant='wk'
                        color='nextStep'
                        isLoading={isLoading}
                        isDisabled={!remitente || !asunto || !cuerpo || isLoading}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalPlantillaEmail