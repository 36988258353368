import * as DateFns from 'date-fns';
import es from 'date-fns/locale/es';

function isDate(value){
    return DateFns.isDate(value);
}
function nowDateLarge(){
    return DateFns.format(new Date(), 'PPPP',{locale: es});
}
/**
 * convierte el string en un tipo Date si es posible
 * @param {String} string
 * @returns Date
 */
function parse(string){
    if(DateFns.isDate(string)){
        return string;
    }
    if (typeof string === 'string'){
        const parts = String(string).split(' ');
        if(parts.length <= 1){
            return DateFns.startOfDay(DateFns.parseISO(string));
        }
    } else if (typeof string === 'number'){
        return DateFns.fromUnixTime(string);
    }
    return DateFns.parseISO(string);
}
/**
 * Convierte un Date/String en un formato de entrada
 * @param {Date|string} date
 * @param {string} pattern
 * @returns
 */
function format(date,pattern = 'yyyy-MM-dd'){
    const aux = parse(date);
    return DateFns.format(aux, pattern, {locale: es})
}

function rangeWeek(date = new Date){
    return {
        start: DateFns.startOfWeek(date,{
            weekStartsOn:1,
        }),
        end: DateFns.endOfWeek(date)
    };
}

function rangeMonth(date = new Date){
    return {
        start: DateFns.startOfMonth(date),
        end: DateFns.endOfMonth(date)
    };
}

function rangeByType(type,date=new Date){
    let newRange;
    switch(type){
        case 'month':
        case 'dayGridMonth':
            newRange= rangeMonth(date);
            break;
        case 'week':
        case 'timeGridWeek':
            newRange = rangeWeek(date);
            break;
        case 'day':
        case 'timeGridDay':
            newRange = {
                start: date,
                end: date,
            }
            break;
        default:
            newRange = {
                start: date,
                end: date,
            }
            break;
    }
    return newRange;
}

function rangePrev(range,type='month',ignoreSunday = true){
    let newRange;
    let date = range.start;
    switch(type){
        case 'month':
        case 'dayGridMonth':
            date = DateFns.subMonths(date,1);
            newRange= rangeMonth(date);
            break;
        case 'week':
        case 'timeGridWeek':
            date = DateFns.subWeeks(date,1);
            newRange = rangeWeek(date);
            break;
        case 'day':
        case 'timeGridDay':
            date = DateFns.subDays(date,1);
            if(DateFns.isSunday(date) && ignoreSunday){
                date = DateFns.subDays(date,1);
            }
            newRange = {
                start: date,
                end: date,
            }
            break;
        default:
            newRange = range;
            break;
    }
    return newRange;
}

function rangeNext(range,type='month',ignoreSunday = true){
    let newRange;
    let date = range.start;
    switch(type){
        case 'month':
        case 'dayGridMonth':
            date = DateFns.addMonths(date,1);
            newRange= rangeMonth(date);
            break;
        case 'week':
        case 'timeGridWeek':
            date = DateFns.addWeeks(date,1);
            newRange = rangeWeek(date);
            break;
        case 'day':
        case 'timeGridDay':
            date = DateFns.addDays(date,1);
            if(DateFns.isSunday(date) && ignoreSunday){
                date = DateFns.addDays(date,1);
            }
            newRange = {
                start: date,
                end: date,
            }
            break;
        default:
            newRange = range;
            break;
    }
    return newRange;
}

function description(range,type){
    let response;
    switch(type){
        case 'month':
        case 'dayGridMonth':
            response= format(range.start,'MMMM yy');
            break;
        case 'week':
        case 'timeGridWeek':
            if(DateFns.isSameMonth(range.start,range.end)){
                response= `${format(range.start,'d')} - ${format(range.end,'d MMMM yy')}`;
            } else {
                response= `${format(range.start,'d MMM')} - ${format(range.end,'d MMM yy')}`;
            }
            break;
        case 'day':
        case 'timeGridDay':
            response= format(range.end,'d MMMM yy');
            break;
        default:
            response = '';
            break;
    }
    return String(response).toUpperCase();
}

function getUnix(value){
    return DateFns.getUnixTime(parse(value));
}

/**
 * Comparar fechas
 * @param {Date|string} dateLeft
 * @param {Date|string} dateRight
 * @param {string} type month,week,day,date,time,datetime
 */
function isSame(dateLeft,dateRight,type='date'){
    switch (type) {
        case 'date':
            return DateFns.isSameDay(parse(dateLeft),parse(dateRight));
        default:
            break;
    }
    return false;
}

/*
    Calcular entre dos fechas
useEffect(() => {
    const day1 = new Date("2021/11/22 09:00:00");
    const day2 = new Date("2021/12/22 11:02:00");
    const difference = day2.getTime()-day1.getTime();

    let days=0
    let hours=0
    let minutes=0
    let seconds=0
    let totalHours=0
    let totalMinutes=0
    let totalSeconds=0;

    totalSeconds = Number(Math.floor(difference / 1000));
    totalMinutes = Number(Math.floor(totalSeconds / 60));
    totalHours = Number(Math.floor(totalMinutes / 60));
    days = Number(Math.floor(totalHours / 24));
  
    seconds = Number(totalSeconds % 60);
    minutes = Number(totalMinutes % 60);
    hours = Number(totalHours % 24);
    
}, []) */




/**
 * Devuelve el tiempo transcurrido
 * @param {Date|String} date 
 * @returns 
 */
function timeAgo(date){
    const before = parse(date);
    if(!DateFns.isValid(before)){
        return '-';
    }
    const now = new Date;
    const minutes = Math.abs(DateFns.differenceInMinutes(before,now));
    if(minutes<60){
        if(minutes === 0){
            return 'recien';
        }
        return `${minutes} minutos`;
    }
    const hours = Math.abs(DateFns.differenceInHours(before,now));
    if(hours<24){
        return `${hours} horas `; 
    }
    const days = Math.abs(DateFns.differenceInDays(before,now));
    if(days<30){
        return `${days} dias `; 
    }
    const months = Math.abs(DateFns.differenceInMonths(before,now));
    if(months<12){
        return `${months} meses `; 
    }
    return format(before,'dd/MM/yy');
}

function timeAgoMentions(date){
    const before = parse(date);
    if(!DateFns.isValid(before)){
        return '-';
    }
    const now = new Date;
    const minutes = Math.abs(DateFns.differenceInMinutes(before,now));
    if(minutes<60){
        if(minutes === 0){
            return 'recien';
        }
        return `Hace ${minutes} minutos`;
    }
    const hours = Math.abs(DateFns.differenceInHours(before,now));
    if(hours<24){
        return `Hace ${hours} horas `;
    }

    return format(new Date(date),'PPPPpp');
}

function addDaysDate(date,cantidad){
    const result = DateFns.addDays(date, cantidad)

    return format(result);
}

function diffDays(date1,date2){
        const day1 = new Date(date1);
        const day2 = new Date(date2);
        const difference = day2.getTime()-day1.getTime();
        let days=0
        let totalHours=0
        let totalMinutes=0
        let totalSeconds=0;
        totalSeconds = Number(Math.floor(difference / 1000));
        totalMinutes = Number(Math.floor(totalSeconds / 60));
        totalHours = Number(Math.floor(totalMinutes / 60));
        days = Number(Math.floor(totalHours / 24));
        return ( days + 1 )
}

function subtractYears(date1,years){
    const restarAnios = DateFns.subYears(date1,years)
    return restarAnios
}

const FechaFunction = {
    isDate,
    description,
    getUnix,
    isSame,
    parse,
    format,
    rangeByType,
    rangeWeek,
    rangeMonth,
    rangePrev,
    rangeNext,
    timeAgo,
    addDaysDate,
    nowDateLarge,
    diffDays,
    timeAgoMentions,
    subtractYears
};
export default FechaFunction;