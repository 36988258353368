import { useState, useEffect, useMemo } from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import ContactosService from '../../../services/contactos.service';
import { ButtonBasic } from '../../../components';
import usePermisos from '../../../hooks/usePermisos';
import {toast} from 'react-toastify';

const TabCuentaEditar = ({contacto,onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false)
    const permisos = usePermisos()
    const [formAll, setFormAll] = useState({
        cuenta: {
            texto: null,
            textoErr: 'El nro de cuenta es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        email: {
            texto: null,
            textoErr: 'El correo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        telefono: {
            texto: null,
            textoErr: 'El telefono es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        telefono2: {
            texto: null,
            textoErr: 'El telefono2 es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '')){
            formOld[input].estadoErr = true
        }else{
            if(input === 'email'){
                if(validateEmail(value)){
                    formOld[input].estadoErr = false
                }else{
                    formOld[input].estadoErr = true
                }
            }else{
                formOld[input].estadoErr = false
            }
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
            },
            '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
            },
            '&.Mui-focused': {
            '&:hover':{
                border:0,
                height: 40
            }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    useEffect(() => {
        if(contacto){
            onChangeForm(contacto?.contacto?.email?.replace(/[\r\n]+/g, '')?.trim() || contacto?.email?.replace(/[\r\n]+/g, '')?.trim() || '', 'email')
            onChangeForm(contacto?.contacto?.telefono || contacto?.telefono || '', 'telefono')
            onChangeForm(contacto?.cuenta || '', 'cuenta')
            if(((contacto?.contacto?.telefono2 && contacto?.contacto?.telefono2 !== '') || (contacto?.telefono2 && contacto?.telefono2 !== ''))){
                onChangeForm(contacto?.contacto?.telefono2 || contacto?.telefono2 || '', 'telefono2')
            }
        }
    }, [contacto])
    const onSubmitContacto = async() => {
        const form = {type: 'nubicom'};
        if(contacto?.contacto?.email || contacto?.email){
            form.oldEmail = contacto?.contacto?.email || contacto?.email
        }
        if(contacto?.contacto?.telefono || contacto?.telefono){
            form.oldTelefono = contacto?.contacto?.telefono || contacto?.telefono
        }
        if(((contacto?.contacto?.telefono2 && contacto?.contacto?.telefono2 !== '') || (contacto?.telefono2 && contacto?.telefono2 !== ''))){
            form.oldTelefono2 = contacto?.contacto?.telefono2 || contacto?.telefono2
        }
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['email', 'telefono','cuenta']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
            propertyNames.forEach((name)=>{
            if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
                form[name] = formAll[name].texto
            }else{
                if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
                }
            }
        }))
        if(!isOk){
            await ContactosService.updateCuentaSoftland({
                ...form
            }).then((resp)=>{
                setIsLoading(false);
                if(onGuardar){
                    onGuardar();
                }
                toast.success(resp?.msg || 'Se realizó el cambio correctamente en softland.', {
                    position: "top-right",
                    autoClose: 3500,
                    pauseOnHover: false,
                });
            }).catch(()=>{
                setIsLoading(false);
            })
        }else{
            setIsLoading(false);
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Correo</Typography>
                <TextField
                    fullWidth
                    sx={styleInputHeight}
                    autoComplete='off'
                    value={formAll.email.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'email')}
                    error={formAll.email.estadoErr}
                    helperText={formAll.email.estadoErr && formAll.email.textoErr}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Celular</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    type='number'
                    value={formAll.telefono.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'telefono')}
                    InputLabelProps={{shrink: formAll.telefono.texto}}
                    error={formAll.telefono.estadoErr}
                    helperText={formAll.telefono.estadoErr && formAll.telefono.textoErr}
                    InputProps={{ inputProps: { min: 100000000 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Telefono (Opcional)</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    type='number'
                    value={formAll.telefono2.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'telefono2')}
                    InputLabelProps={{shrink: formAll.telefono2.texto}}
                    InputProps={{ inputProps: { min: 100000000 } }}
                />
            </Grid>
            {permisos['softland.cambioCorreoTelefono'] && (
                <Grid item xs={12} display='flex' justifyContent='end'>
                    <ButtonBasic
                        textButton="Guardar"
                        variant="wk"
                        color="nextStep"
                        sx={{width: 150}}
                        onClick={()=>onSubmitContacto()}
                        isLoading={isLoading}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default TabCuentaEditar