import { useEffect, useMemo, useState } from 'react';
import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import BadgeIcon from '@mui/icons-material/Badge';
import { ButtonBasic, DateSingle, InputSearchContactNubicom, MapBase, MarkerServicio, SelectCanalesSoftland, SelectCategoriaSoftland, SelectIvaSoftland, SelectLocalidadesSoftland, SelectLugaresCalendar, SelectTipoDocSoftland } from '../../../components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExploreIcon from '@mui/icons-material/Explore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContactosService from '../../../services/contactos.service';
import ContactosAction from '../../../store/actions/contactos';
import usePermisos from '../../../hooks/usePermisos';
import FechaFunction from '../../../function/FechaFunction';

const FormPaso1Screen = ({
  item,
  contacto,
  nextStep,
  iniciarContacto,
  iniciarContrato,
  contrato,
  ticket
}) => {
  const permisos = usePermisos();
  const [isLoading, setIsLoading] = useState(false);
  const [isDni, setIsDni] = useState('96');
  const [contactoExistSoft, setContactoExistSoft] = useState(false);
  const [nroIdContacto, setNroIdContacto] = useState(null);
  const [tipoCliente, setTipoCliente] = useState('softland');
  const [existeSoftland, setExisteSoftland] = useState(false);
  const [existePotCliente, setExistePotCliente] = useState(false);
  const [nroTicket, setNroTicket] = useState(null)
  const [mapLocation, setMapLocation] = useState({
    lat: -24.789251,
    lng: -65.410393
  });
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [formAll, setFormAll] = useState({
    apellido_c: {
      texto: null,
      textoErr: 'El apellido es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombre_c: {
      texto: null,
      textoErr: 'El nombre es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    tipoDoc_c: {
      texto: null,
      textoErr: 'El tipo de documento es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    documento_c: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    fnacimiento_icon: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    categoria_cxe: {
      texto: null,
      textoErr: 'La categoria es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    iva_c: {
      texto: null,
      textoErr: 'El iva es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    canalcomu_c: {
      texto: null,
      textoErr: 'El canal es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    celular_c: {
      texto: null,
      textoErr: 'El celular es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    telefono_c: {
      texto: null,
      textoErr: 'El telefono es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    email_c: {
      texto: null,
      textoErr: 'El correo es requerido',
      estadoErr: false,
      tipo: 'email'
    },
    cpostal_c: {
      texto: null,
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    provincia_c: {
      texto: null,
      textoErr: 'La provincia es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    barrio_c: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    direccion_c: {
      texto: null,
      textoErr: 'La dirección es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    lugarId_t: {
      texto: 1,
      textoErr: 'El lugar del calendario es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    lugarId_c: {
      texto: 1,
      textoErr: 'El lugar del calendario es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    barrio_t: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    calle_t: {
      texto: null,
      textoErr: 'La dirección es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    estadoCliente_c: {
      texto: null,
      textoErr: 'El estado de la consulta es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    latitud_c: {
      texto: null,
      textoErr: 'Las coordenadas son requerida',
      estadoErr: false,
      tipo: 'string'
    },
    longitud_c: {
      texto: null,
      textoErr: 'La longitud es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    coordenadas_c: {
      texto: null,
      textoErr: 'La coordenada es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompleto_c: {
      texto: null,
      textoErr: 'El nombre completo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompletoContacto1_c: {
      texto: null,
      textoErr: 'El nombre completo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    dniContacto1_c: {
      texto: null,
      textoErr: 'El dni es requerido',
      estadoErr: false,
      tipo: 'string'
    },
  });
  const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const onChangeForm = (value,input) =>{
    let formOld = formAll;
    if(!formOld[input]){
      formOld[input] = {
        texto: value,
        textoErr: `El ${input} es requerido`,
        estadoErr: false,
        tipo: 'string'
      }
    }else{
      formOld[input].texto = value
    }
    if((!value || value === '') && input !== 'telefono_c'){
      formOld[input].estadoErr = true
    }else{
      if(input === 'email_c'){
        if(validateEmail(value)){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else if(input === 'documento_c'){
        if(value > 1000000){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else{
        formOld[input].estadoErr = false
      }
    }
    setFormAll({...formOld})
  }
  const onChangeFormSetError = (input,clear) =>{
    let formOld = formAll;
    formOld[input].estadoErr = !clear ? true : false
    setFormAll({...formOld})
  }
  const styleInput = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 14,
      marginTop: -0.4
    },
    '& fieldset': {
        borderRadius: 2,
        height: 50,
        border: 1,
        borderColor: 'gray',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          border:1,
          color: '#000',
          height: 50,
        },
        '&:hover':{
          border: 0.1,
          borderRadius: 2,
          borderColor: 'gray',
          height: 45,
        },
        '&.Mui-focused': {
          '&:hover':{
            border: 0,
            borderRadius: 2,
            height: 45,
          }
        },
        color: 'gray',
        height: 45,
    }
  }), [])
  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
      marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border:1,
        height: 45,
        color: '#000'
      },
      '&:hover':{
        border: 1,
        borderRadius: 2,
        borderColor: 'gray',
        height: 40
      },
      '&.Mui-focused': {
        '&:hover':{
          border:0,
          height: 40
        }
      },
      color: 'gray',
      height: 40
    }
  }), [])
  const onSelectContactSoft = (datos) =>{
    if(datos?.cuenta){
      setContactoExistSoft(true);
      setExisteSoftland(true);
      setExistePotCliente(false);
      setNroIdContacto({cuentaId: datos?.cuenta})
      setIsDni(datos?.dni_cuit?.codigo || '96')
      onChangeForm(datos?.nombre_completo || '', 'apellido_c')
      onChangeForm(datos?.nombre_completo || '', 'nombre_c')
      onChangeForm(datos?.nombre_completo || '', 'nombreCompleto_c')
      onChangeForm(datos?.dni_cuit?.numero || '', 'documento_c')
      onChangeForm(datos?.dni_cuit?.codigo || '96', 'tipoDoc_c')
      onChangeForm(datos?.categoria?.codigo, 'categoria_cxe')
      onChangeForm(datos?.contacto?.email?.trim() || '', 'email_c')
      onChangeForm(datos?.contacto?.telefono || '', 'celular_c')
      onChangeForm(datos?.iva?.codigo || 'C', 'iva_c')
      onChangeForm(datos?.ubicacion?.direccion || '', 'direccion_c')
      onChangeForm(datos?.ubicacion?.codigo_postal || '4400', 'cpostal_c')
      onChangeForm(datos?.ubicacion?.codigo_provincia || '917', 'provincia_c')
      onChangeForm(datos?.ubicacion?.codigo_postal || '4400', 'codpostal_t')
      onChangeForm(datos?.ubicacion?.codigo_provincia || '917', 'codprovincia_t')
      onChangeForm('010', 'condicionPago_c')
      onChangeForm(datos?.lista_precio?.codigo, 'listPrecio_c')
      onChangeForm(datos?.cuenta, 'cuentaNueva')
      onChangeForm(datos?.ubicacion?.direccion || '', 'barrio_c')
      onChangeForm('', 'telefono_c')
      onChangeForm(datos?.ubicacion?.latitud_c || '', 'latitud_c')
      onChangeForm(datos?.ubicacion?.longitud_c || '', 'longitud_c')
      onChangeForm(datos?.lugarId_c || 1, 'lugarId_c')
      onChangeForm(datos?.lugarId_c || 1, 'lugarId_t')
    }else{
      setContactoExistSoft(false);
      setExisteSoftland(false);
      setExistePotCliente(false);
    }
  }
  const onSubmitContacto = async() => {
    if((contacto?.id_c || contacto?.cuenta)){
      nextStep();
    }else{
      const form = {
        ...item,
        ...contacto,
        ...contrato,
        type: 'nubicom',
        listaPrecioSoft_t: contrato?.lista_precios?.codigo,
        tipoConSoft_t: contrato?.tipo,
        empresaSoft_t: 'EMP001',
        codpostal_t: contrato?.ubicacion?.cod_postal || '4400',
        codprovincia_t: contrato?.ubicacion?.cod_provincia || '917',
        nroTicket: nroTicket,
      };
      let isOk = false;
      setIsLoading(true);
      const inputRequired = ['tipoDoc_c','documento_c', 'categoria_cxe','iva_c', 'canalcomu_c',
      'celular_c', 'email_c', 'cpostal_c', 'provincia_c', 'barrio_c', 'direccion_c','lugarId_t' ];
      if(isDni === '96'){
        inputRequired.push('apellido_c', 'nombre_c');
      }else{
        inputRequired.push('nombreCompleto_c', 'nombreCompletoContacto1_c', 'dniContacto1_c');
      }
      const propertyNames = Object.keys(formAll);
      await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
          form[name] = formAll[name].texto
        }else{
          if(inputRequired.includes(name)){
            isOk = true;
            onChangeFormSetError(name)
          }
        }
      }))
      if(!isOk){
        form.listPrecio_c = contrato?.lista_precios?.codigo;
        form.listaPrecioSoft_t = contrato?.lista_precios?.codigo;
        form.tipoConSoft_t = contrato?.tipo;
        form.latitud_c = contrato?.ubicacion?.latitud || '-24.789251';
        form.longitud_c = contrato?.ubicacion?.longitud || '-65.410393';
        form.empresaId_cxe = 1;
        if(isDni === '96'){
          form.nombreCompleto_c = `${form.apellido_c.toUpperCase()} ${form.nombre_c.toUpperCase()}`;
        }else{
          form.nombreCompleto_c = form?.nombreCompleto_c?.toUpperCase();
        }
        if(form?.nombreCompletoContacto1_c){
          form.nombreCompletoContacto1_c = form?.nombreCompletoContacto1_c?.toUpperCase();
        }
        form.cambDomCuentaId_t = contrato?.cuenta;
        form.cambDomServicioId_t = contrato?.numero;
        form.servicioIdSoftland_ds = contrato?.articulo?.codigo;
        form.tipoServicio_ds = contrato?.articulo?.tipo;
        form.nombreServicio_ds = contrato?.articulo?.nombre;
        form.moneda_ds = contrato?.articulo?.moneda;
        form.importeServicio_ds = contrato?.articulo?.precio;
        form.anchoBajadaServicio_ds = contrato?.articulo?.bajada;
        form.anchoSubidaServicio_ds = contrato?.articulo?.subida;
        const chunks = contrato?.ubicacion?.domicilio?.split('-');
        if(chunks?.length === 0){
          form.barrio_t = 'S/B';
          form.calle_t = 'S/C'
        }else if(chunks?.length === 1){
          const arr = [chunks.shift()];
          form.barrio_t = arr[0] || 'S/B';
          form.calle_t = 'S/C'
        }else if(chunks?.length >= 2){
          const arr2 = [chunks?.shift(), chunks?.join('-')];
          form.barrio_t = arr2[0] || 'S/B';
          form.calle_t = arr2[1] || 'S/C'
        }
        if(contactoExistSoft){
          form.nombreCompleto_c = form.apellido_c.toUpperCase();
          await ContactosService.registerCuentaCambioDomicilio(form).then((response)=>{
            const recuperarDatos={
              ...form,
              ...response,
            }
            recuperarDatos.cuentaNueva = form.cuentaNueva;
            recuperarDatos.cuenta = response.cuentaId_t;
            recuperarDatos.iva_c = form.iva_c;
            iniciarContacto(recuperarDatos);
            setIsLoading(false);
            nextStep();
          })
        }else{
          if(tipoCliente === 'potcliente'){
            form.id_c = nroIdContacto?.contactoId;
          }
          await ContactosService.registerCambioDomicilio(form).then((response)=>{
            const recuperarDatos={
                ...form,
                ...response,
            }
            recuperarDatos.contactoXempId_t = response.id_cxe;
            recuperarDatos.id_cxe = response.id_cxe;
            recuperarDatos.id_c = response.id_cxe;
            recuperarDatos.iva_c = form.iva_c;
            recuperarDatos.idNuevoContacto = response.id_c;
            iniciarContacto(recuperarDatos);
            setIsLoading(false);
            nextStep();
          })
          .finally(()=>setIsLoading(false));
        }
      }else{
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    if(contrato?.ubicacion?.latitud && contrato?.ubicacion?.longitud){
      setMapLocation({
        lat: Number(contrato?.ubicacion?.latitud),
        lng: Number(contrato?.ubicacion?.longitud),
      })
    }
  }, [contrato])
  const cleanRegistro = () =>{
    setExisteSoftland(false);
    setExistePotCliente(false);
    setNroIdContacto(null)
    setIsDni('96')
    onChangeForm('', 'apellido_c')
    onChangeForm('', 'nombre_c')
    onChangeForm('', 'nombreCompleto_c')
    onChangeForm('', 'documento_c')
    onChangeForm('96', 'tipoDoc_c')
    onChangeForm('', 'categoria_cxe')
    onChangeForm('', 'email_c')
    onChangeForm('', 'celular_c')
    onChangeForm('C', 'iva_c')
    onChangeForm('', 'direccion_c')
    onChangeForm('', 'cpostal_c')
    onChangeForm('', 'provincia_c')
    onChangeForm('', 'codpostal_t')
    onChangeForm('', 'codprovincia_t')
    onChangeForm('', 'condicionPago_c')
    onChangeForm('', 'listPrecio_c')
    onChangeForm('', 'cuentaNueva')
    onChangeForm('', 'barrio_c')
    onChangeForm('', 'telefono_c')
    onChangeForm('', 'latitud_c')
    onChangeForm('', 'longitud_c')
    onChangeForm('', 'nombreCompletoContacto1_c')
    onChangeForm('', 'dniContacto1_c')
  }
  const onSelectContactPotCliente = (datos) =>{
    if(datos?.id_c){
      setExisteSoftland(false);
      setExistePotCliente(true);
      setNroIdContacto({contactoId: datos?.id_c})
      setIsDni(datos?.tipoDoc_c || '96')
      onChangeForm(datos?.apellido_c || '', 'apellido_c')
      onChangeForm(datos?.nombre_c || '', 'nombre_c')
      onChangeForm(datos?.nombreCompleto_c || '', 'nombreCompleto_c')
      onChangeForm(datos?.documento_c || '', 'documento_c')
      onChangeForm(datos?.tipoDoc_c || '96', 'tipoDoc_c')
      onChangeForm(datos?.categoria_cxe, 'categoria_cxe')
      onChangeForm(datos?.email_c?.trim() || '', 'email_c')
      onChangeForm(datos?.celular_c || '', 'celular_c')
      onChangeForm(datos?.iva_c || 'C', 'iva_c')
      onChangeForm(datos?.direccion_c || '', 'direccion_c')
      onChangeForm(datos?.cpostal_c || '4400', 'cpostal_c')
      onChangeForm(datos?.provincia_c || '917', 'provincia_c')
      onChangeForm(contrato?.ubicacion?.cod_postal || '4400', 'codpostal_t')
      onChangeForm(contrato?.ubicacion?.cod_provincia || '917', 'codprovincia_t')
      onChangeForm('010', 'condicionPago_c')
      onChangeForm(contrato?.lista_precios?.codigo, 'listPrecio_c')
      onChangeForm('', 'cuentaNueva')
      onChangeForm(datos?.barrio_c || '', 'barrio_c')
      onChangeForm(datos?.telefono_c || '', 'telefono_c')
      onChangeForm(datos?.latitud_c || '', 'latitud_c')
      onChangeForm(datos?.longitud_c || '', 'longitud_c')
      onChangeForm(datos?.lugarId_c || 1, 'lugarId_c')
      onChangeForm(datos?.lugarId_c || 1, 'lugarId_t')
      onChangeForm(datos?.nombreCompletoContacto1_c || '', 'nombreCompletoContacto1_c')
      onChangeForm(datos?.dniContacto1_c || '', 'dniContacto1_c')
    }else{
      setContactoExistSoft(false);
      setExisteSoftland(false);
      setExistePotCliente(false);
    }
  }
  useEffect(() => {
    if(ticket){
      setNroTicket(ticket)
    }
  }, [])
  useEffect(() => {
    cleanRegistro()
  }, [tipoCliente])

  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2}>
        <Grid xs={12} md={12} lg={5.9}>
          <Card sx={{background: '#fff !important'}}>
            <Box display='flex' justifyContent='space-between' p={1}>
              <Box display='flex' pl={2} pt={1}>
                  <BadgeIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Datos Personales</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                  <ButtonBasic
                    textButton='Limpiar registro'
                    variant="wk"
                    color="downloadHome"
                    sx={{border: 1,marginTop:0.5}}
                    onClick={()=>cleanRegistro()}
                  />
              </Box>
            </Box>
            <Grid container p={2} spacing={1}>
              <Grid item xs={12} display='flex' justifyContent='center'>
                <FormControl>
                  <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={tipoCliente}
                      onChange={(e) => setTipoCliente(e.target.value)}
                      row
                  >
                      <FormControlLabel
                          value="nuevo"
                          control={ <Radio sx={{color: '#000'}} />}
                          label="Nuevo Cliente"
                          sx={{color: '#000'}}
                      />
                      <FormControlLabel
                          value="potcliente"
                          control={ <Radio sx={{color: '#000'}} /> }
                          label="Potencial Cliente"
                          sx={{color: '#000'}}
                      />
                      <FormControlLabel
                          value="softland"
                          control={ <Radio sx={{color: '#000'}} /> }
                          label="Cliente Existente"
                          sx={{color: '#000'}}
                      />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                { tipoCliente === 'softland' && (
                  <InputSearchContactNubicom
                    soloCuentaSoft
                    onClick={(e)=>onSelectContactSoft(e)}
                  />
                )}
                { tipoCliente === 'potcliente' && (
                  <InputSearchContactNubicom
                    soloPotCliente
                    onClick={(e)=>onSelectContactPotCliente(e)}
                  />
                )}
              </Grid>
              { (tipoCliente === 'nuevo' || existeSoftland || existePotCliente) &&(
                <>
                  {existeSoftland ? (
                    <Grid item xs={12} mt={2}>
                        <TextField
                          fullWidth
                          autoComplete='off'
                          label='Nombre Completo'
                          sx={styleInput}
                          value={formAll.nombreCompleto_c.texto}
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                  ):(
                    <>
                      { isDni === '80' ? (
                        <Grid item xs={12} md={12}>
                          <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                              * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Razón Social</Box>
                            </Typography>
                          </Box>
                          <TextField
                            fullWidth
                            autoComplete='off'
                            sx={styleInputHeight}
                            value={formAll.nombreCompleto_c.texto}
                            onChange={(e)=>onChangeForm(e.target.value,'nombreCompleto_c')}
                            InputLabelProps={{shrink: formAll.nombreCompleto_c.texto}}
                            error={formAll.nombreCompleto_c.estadoErr}
                            helperText={formAll.nombreCompleto_c.estadoErr && formAll.nombreCompleto_c.textoErr}
                          />
                        </Grid>
                      ):(
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              autoComplete='off'
                              label='Apellido'
                              sx={styleInput}
                              value={formAll.apellido_c.texto}
                              onChange={(e)=>onChangeForm(e.target.value,'apellido_c')}
                              InputLabelProps={{shrink: formAll.apellido_c.texto}}
                              error={formAll.apellido_c.estadoErr}
                              helperText={formAll.apellido_c.estadoErr && formAll.apellido_c.textoErr}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              autoComplete='off'
                              label='Nombre'
                              sx={styleInput}
                              value={formAll.nombre_c.texto}
                              onChange={(e)=>onChangeForm(e.target.value,'nombre_c')}
                              InputLabelProps={{shrink: formAll.nombre_c.texto}}
                              error={formAll.nombre_c.estadoErr}
                              helperText={formAll.nombre_c.estadoErr && formAll.nombre_c.textoErr}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  { isDni === '80' && (
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          background: '#fff !important',
                          borderRadius: 1,
                          borderStyle: 'solid',
                          borderWidth:1,
                          borderColor: '#000',
                          boxShadow: 4,
                          padding: 1
                        }}
                      >
                        <Typography sx={{color: '#000',fontWeight: 'bold', fontSize: 14}}>* Apoderado/Responsable</Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={8}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                              </Typography>
                            </Box>
                            <TextField
                              fullWidth
                              autoComplete='off'
                              sx={styleInputHeight}
                              value={formAll.nombreCompletoContacto1_c.texto}
                              onChange={(e)=>onChangeForm(e.target.value,'nombreCompletoContacto1_c')}
                              InputLabelProps={{shrink: formAll.nombreCompletoContacto1_c.texto}}
                              error={formAll.nombreCompletoContacto1_c.estadoErr}
                              helperText={formAll.nombreCompletoContacto1_c.estadoErr && formAll.nombreCompletoContacto1_c.textoErr}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                * <Box sx={{fontWeight: 'normal',display: 'inline' }}>DNI</Box>
                              </Typography>
                            </Box>
                            <TextField
                              fullWidth
                              autoComplete='off'
                              sx={styleInputHeight}
                              value={formAll.dniContacto1_c.texto}
                              onChange={(e)=>onChangeForm(e.target.value,'dniContacto1_c')}
                              InputLabelProps={{shrink: formAll.dniContacto1_c.texto}}
                              error={formAll.dniContacto1_c.estadoErr}
                              helperText={formAll.dniContacto1_c.estadoErr && formAll.dniContacto1_c.textoErr}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={4} md={4}>
                    <Typography sx={{color: '#000'}}>Tipo Doc.</Typography>
                    <SelectTipoDocSoftland
                      isInvalid={formAll.tipoDoc_c.estadoErr}
                      value={formAll.tipoDoc_c.texto}
                      onChange={(e)=>{
                        onChangeForm(e.value,'tipoDoc_c')
                        if(e.value === '96'){
                          onChangeForm('C','iva_c')
                        }else{
                          onChangeForm('I','iva_c')
                        }
                        setIsDni(e.value)
                      }}
                      disabled={existeSoftland}
                    />
                    {formAll.tipoDoc_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoDoc_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} mt={2.5}>
                    <TextField
                      label='Numero'
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      type='number'
                      value={formAll.documento_c.texto}
                      onChange={(e)=>onChangeForm(Number(e.target.value),'documento_c')}
                      InputLabelProps={{shrink: formAll.documento_c.texto}}
                      inputProps={{ readOnly: existeSoftland,min: 1000000, inputProps: { min: 1000000 } }}
                      error={formAll.documento_c.estadoErr}
                      helperText={formAll.documento_c.estadoErr && formAll.documento_c.textoErr}
                      FormHelperTextProps={{ className: '-mt-1' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{color: '#000'}}>Fecha de Nacimiento</Typography>
                    <DateSingle
                      fullWidth
                      maxDate={FechaFunction.subtractYears(new Date(),18)}
                      value={formAll.fnacimiento_icon.texto}
                      onChange={(e)=>onChangeForm(e,'fnacimiento_icon')}
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      isFullWidth
                      dropdownMode="select"
                      yearDropdownItemNumber={15}
                    />
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Typography sx={{color: '#000'}}>Categoria</Typography>
                    <SelectCategoriaSoftland
                      isInvalid={formAll.categoria_cxe.estadoErr}
                      value={formAll.categoria_cxe.texto}
                      onChange={(e)=>onChangeForm(e.value,'categoria_cxe')}
                    />
                    {formAll.categoria_cxe.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.categoria_cxe.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Typography sx={{color: '#000'}}>IVA</Typography>
                    <SelectIvaSoftland
                      filter
                      isInvalid={formAll.iva_c.estadoErr}
                      value={formAll.iva_c.texto}
                      onChange={(e)=>onChangeForm(e.value,'iva_c')}
                      disabled={isDni === '96'}
                      tipoDoc={isDni}
                    />
                    {formAll.iva_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.iva_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Typography sx={{color: '#000'}}>Canal</Typography>
                    <SelectCanalesSoftland
                      isInvalid={formAll.canalcomu_c.estadoErr}
                      value={formAll.canalcomu_c.texto}
                      onChange={(e)=>onChangeForm(e.value,'canalcomu_c')}
                    />
                    {formAll.canalcomu_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={2} ml={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold'}} variant='h5'>Datos de Contacto</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} mt={1}>
                    <TextField
                      fullWidth
                      label='Celular'
                      autoComplete='off'
                      sx={styleInput}
                      type='number'
                      value={formAll.celular_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'celular_c')}
                      InputLabelProps={{shrink: formAll.celular_c.texto}}
                      error={formAll.celular_c.estadoErr}
                      helperText={formAll.celular_c.estadoErr && formAll.celular_c.textoErr}
                      InputProps={{ inputProps: { min: 100000000 } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} mt={1}>
                    <TextField
                      fullWidth
                      label='Telefono'
                      autoComplete='off'
                      sx={styleInput}
                      type='number'
                      value={formAll.telefono_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'telefono_c')}
                      InputLabelProps={{shrink: formAll.telefono_c.texto}}
                      error={formAll.telefono_c.estadoErr}
                      helperText={formAll.telefono_c.estadoErr && formAll.telefono_c.textoErr}
                      InputProps={{ inputProps: { min: 100000000 } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} mt={1}>
                    <TextField
                      fullWidth
                      label='Email'
                      autoComplete='off'
                      sx={styleInput}
                      value={formAll.email_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'email_c')}
                      InputLabelProps={{shrink: formAll.email_c.texto}}
                      inputProps={{ readOnly: existeSoftland }}
                      error={formAll.email_c.estadoErr}
                      helperText={formAll.email_c.estadoErr && formAll.email_c.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} ml={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold'}} variant='h5'>Domicilio de Facturación</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={{color: '#000'}}>Localidad</Typography>
                    <SelectLocalidadesSoftland
                      value={formAll.cpostal_c.texto}
                      isInvalid={formAll.cpostal_c.estadoErr}
                      onChange={(e)=>{
                        onChangeForm(e.value,'cpostal_c')
                        onChangeForm(e.provinciaId,'provincia_c')
                      }}
                    />
                    {formAll.cpostal_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cpostal_c.textoErr}</Typography>
                    )}
                  </Grid>
                  { existeSoftland ? (
                    <Grid item xs={7} mt={2.5}>
                      <TextField
                        fullWidth
                        label='Domicilio'
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll?.direccion_c?.texto || ''}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  ):(
                    <>
                      <Grid item xs={7} mt={2.5}>
                        <TextField
                          fullWidth
                          label='Barrio'
                          autoComplete='off'
                          sx={styleInputHeight}
                          value={formAll.barrio_c.texto}
                          onChange={(e)=>onChangeForm(e.target.value,'barrio_c')}
                          InputLabelProps={{shrink: formAll.barrio_c.texto}}
                          error={formAll.barrio_c.estadoErr}
                          helperText={formAll.barrio_c.estadoErr && formAll.barrio_c.textoErr}
                        />
                      </Grid>
                      <Grid item xs={6} mt={1}>
                        <TextField
                          fullWidth
                          autoComplete='off'
                          label='Direccion'
                          sx={styleInput}
                          value={formAll.direccion_c.texto}
                          onChange={(e)=>onChangeForm(e.target.value,'direccion_c')}
                          InputLabelProps={{shrink: formAll.direccion_c.texto}}
                          error={formAll.direccion_c.estadoErr}
                          helperText={formAll.direccion_c.estadoErr && formAll.direccion_c.textoErr}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Card>-
        </Grid>
        <Grid xs={12} md={12} lg={6} ml={0.5} mr={0.5} mb={1}>
          <Card sx={{background: '#fff !important'}}>
              {/* <Box display='flex' pt={1}>
                <LocationOnIcon sx={{color: '#000',fontSize: 30}} />
                <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Direccion de instalación</Typography>
              </Box> */}
              <Grid container mb={1} mt={1} pl={2}>
                {/* <Grid item xs={11}>
                  <Typography sx={{color: '#000'}}>Localidad calendario</Typography>
                  <SelectLugaresCalendar
                    value={formAll.lugarId_t.texto}
                    isInvalid={formAll.lugarId_t.estadoErr}
                    onChange={(e)=>{
                      onChangeForm(e.codProvincia,'codprovincia_t')
                      onChangeForm(e.codLocalidad,'codpostal_t')
                      onChangeForm(e.value,'lugarId_t')
                      onChangeForm(e.value,'lugarId_c')
                    }}
                  />
                  {formAll.lugarId_t.estadoErr && (
                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                  )}
                </Grid> */}
                <Grid item xs={12}>
                  <Box display='flex' pt={1} mb={2}>
                    <ExploreIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Mapa</Typography>
                  </Box>
                </Grid>
                <Grid xs={12} display='flex' justifyContent='center' alignItems='center'>
                  <Box display='flex' justifyContent='center' alignItems='center' sx={{width: '100%'}}>
                    <MapBase
                      id="mapaComercial"
                      options={{
                          maxZoom: 19,
                          minZoom: 10,
                      }}
                      zoom={15}
                      coordinates={mapLocation}
                      mapContainerStyle={{
                          marginTop: 0,
                          marginBottom: 10,
                          width: '90%',
                          height: 250
                      }}
                      isSearchable={false}
                    >
                      <MarkerServicio coordenadas={mapLocation} />
                    </MapBase>
                  </Box>
                </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Card sx={{background: '#fff !important'}}>
            <Typography sx={{color: '#000',textAlign: 'center', fontWeight: 'bold'}}>
              Al continuar se creara el pre-servicio y se enviara el correo electronico con los siguientes archivos:
            </Typography>
            <Typography sx={{color: '#000',textAlign: 'center', fontWeight: 'bold'}}>
              * CONTRATO
            </Typography>
            <Typography sx={{color: '#000',textAlign: 'center', fontWeight: 'bold'}}>
              * CONSTANCIA DE RECEPCION DE EQUIPOS
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          color='downloadHome'
          sx={{border:1, borderColor: '#000'}}
          onClick={()=>nroTicket ? navigate(`/ticket/${nroTicket}/nubicom`) : navigate('/')}
        />
        <ButtonBasic
          textButton='Continuar'
          iconEnd={<ArrowForwardIcon />}
          isLoading={isLoading}
          onClick={()=>onSubmitContacto()}
          variant='wk'
          color='nextStep'
          sx={{border:1, borderColor: '#fff',marginLeft: 1}}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso1Screen);