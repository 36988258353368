import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectFilterTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const options = [
        {
            value: 'todos',
            label: 'Mis Tickets'
        },
        {
            value: 'individual',
            label: 'Mis Tickets personales'
        },
        {
            value: 'grupal',
            label: 'Mis Tickets grupales'
        },
        {
            value: 'creado',
            label: 'Mis Tickets creados'
        }
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectFilterTicket.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectFilterTicket;