import { useRef, useState } from 'react';
import { Menu, Avatar, Box, Typography, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import ButtonBasic from '../buttons/ButtonBasic';
import LogoutIcon from '@mui/icons-material/Logout';
const MenuUsuarioNav = ({
    onLogout
}) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector((state) => state.auth.currentUser);
    return (
        <>
        <Avatar
            ref={ref}
            onClick={() => setIsOpen(true)}
            sx={{boxShadow:3,border:1,borderColor:'#9d9ea4','&:hover': {cursor: 'pointer'}}}
            src={user?.foto_p}
        />

        <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClose={() => setIsOpen(false)}
            PaperProps={{
                sx: {width: 270,background: '#fff !important',boxShadow: 8 }
            }}
        >
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column',mt:1}}>
                <Avatar
                    src={user?.foto_p}
                    sx={{width:90, height:90,boxShadow:6}}
                />
                <Typography sx={{color: '#15294b',mt:2,fontWeight: 'bold'}} variant='h5'>{user?.nombre_p}</Typography>
                <Typography sx={{color: '#15294b',mt:1}}>{`${user?.puesto_p || ''} - ${user?.razonSocial_p || ''}`}</Typography>
                <Typography sx={{color: '#15294b',mt: 1}}>{user?.email_p}</Typography>
            </Box>
            <Divider sx={{background: 'gray',mb:1}} />
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                {user?.grupos?.length > 0 &&(
                    user?.grupos?.map((gr,index)=>(
                        <Typography key={`${index}TextG`} sx={{color: '#15294b',fontWeight: 'bold'}}>{`Grupo ${index +1}: ${gr?.nombre_g}`}</Typography>
                    ))
                )}
            </Box>
            <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center',mt:2}}>
                <ButtonBasic
                    textButton='Cerrar Sesion'
                    onClick={()=>onLogout()}
                    variant="wk"
                    color='nextWhite'
                    sx={{width: 200,fontWeight: 'bold', border:1,borderColor: '#a6aebb',color: '#15294b'}}
                    iconStart={<LogoutIcon />}
                />
            </Box>
        </Menu>
        </>
    );
}

export default MenuUsuarioNav