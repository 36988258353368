import { useContext } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material'
import { SocketContext } from '../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FechaFunction from '../../function/FechaFunction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const CardNotifications = ({item,onCloseModal}) => {
    const navigate = useNavigate();
    const { sockets } = useContext( SocketContext );
    const redirect = ()=>{
        if(!item?.visto_notf){
            sockets?.emit('newNotifications', item?.id_notf)
        }
        if(item?.empresa_notf === 'strong'){
            navigate(`/ticket/${item?.ticketId_notf}/strongsystems`)
        }else{
            navigate(`/ticket/${item?.ticketId_notf}/${item?.empresa_notf}`)
        }
        if(onCloseModal){
            onCloseModal()
        }
    }
    return (
        <Card
            sx={{background: item?.visto_notf ? '#fff !important' : '#e6f0ff !important', boxShadow: 3,marginTop: 2, cursor: 'pointer' }}
            onClick={()=>redirect()}
        >
            <Grid container p={2}>
                <Grid item xs={2} sx={{display:'flex',justifyContent: 'center',alignItems:'center',marginLeft:-2,marginRight:1}}>
                    {item.empresa_notf === 'nubicom' &&(
                        <img src="/img/nubi-cirucular.png" alt="Icon" style={{width: '40px', height: '40px'}} />
                    )}
                    {item.empresa_notf === 'ids' &&(
                        <img src="/img/ids-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_notf === 'strong' &&(
                        <img src="/img/strong-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresa_notf === 'cvv' &&(
                        <img src="/img/911-logo.png" alt="Icon" style={{width: '40px', height: '50px'}} />
                    )}
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#15294b'}} component='div'>{item?.senduser?.nombre_p || ''} 
                                <Box fontWeight='bold' display='inline'>{`${item?.tipoDestino_notf === 'DNI' ? 'Te asigno' : ''} `}
                                <Box fontWeight='bold' display='inline'>{`${item?.tipoDestino_notf === 'GRUPO' ? 'Asigno al grupo' : ''} ${item?.sendgrupo?.nombre_g || ''} `}</Box>
                                </Box> en el ticket
                                <Box fontWeight='bold' display='inline'>{` #${item?.ticketId_notf}`}</Box>
                            </Typography>
                        </Box>
                        <Box display='flex'>
                            <AccessTimeOutlinedIcon sx={{color:'gray'}} />
                            <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.createdAt))}</Typography>
                        </Box>
                        {item?.visto_notf && (
                            <Box display='flex'>
                                <RemoveRedEyeIcon sx={{color:'gray'}} />
                                <Typography sx={{color: 'gray',ml:0.5}}>{FechaFunction.timeAgoMentions(new Date(item?.visto_notf))}</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardNotifications