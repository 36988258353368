import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Tooltip, tooltipClasses, Typography } from "@mui/material"
import { TableBase } from "../../components"
import FechaFunction from "../../function/FechaFunction";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { styled } from '@mui/material/styles';
import TratoService from "../../services/tratos.service";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));
const PreServiciosPendientesScreen = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const actualizar = async () => {
        await TratoService.getAll({
            type: 'nubicom',
            nivelBonificacion: true
        }).then((r)=>{
            setItems(r)
        })
    };

    const onOpenVer = (idCliente) => {
        const item = items.find((it)=> it.id_t === idCliente)
        switch (item?.empresa_t) {
            case 'NUBICOM':
                navigate(`/pre-servicio/${item.id_t}/nubicom`);
                break;
            case 'STRONG':
                navigate(`/pre-servicio/${item.id_t}/strong`);
                break;
            case 'IDS':
                navigate(`/pre-servicio/${item.id_t}/ids`);
                break;
            default:
                navigate(`/pre-servicio/${item.id_t}/nubicom`);
                break;
        }
    }

    const columns = [
        {
            name: "id_t",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                display:true,
                viewColumns: true
            }
        },
        {
            name: "createdAt",
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return FechaFunction.format(value,'dd/MM/yyyy');
                }
            }
        },
        {
            label: "Nombre",
            name: "nombre_t",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'direccion_t',
            label: "Direccion"
        },
        {
            label: "Autorizacion",
            name: "nivelAutorizacion_t",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Estado",
            name: "estadoAutorizado",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <LightTooltip  title={tableMeta.rowData[5] === 'PENDIENTE' ? "Aprobar/Rechazar Pre-Servicio" : 'Ver Pre-Servicio'} placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVer(tableMeta.rowData[0])}>
                                    <AssignmentTurnedInIcon fontSize="medium" sx={{color: '#02b879'}} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </>
                )
            }
        }
    ];
    useEffect(() => {
        actualizar();
        document.title = `Autorizaciones Pendientes`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={4}>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography className="text-gray-900 font-bold" variant="h3">Autorizaciones Pendientes Pre-Servicios</Typography>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PreServiciosPendientesScreen