import MUIDataTable from 'mui-datatables'

const TableBase = ({title= 'Tabla',columns,data,options}) => {
  /* Opciones de la tabla
    customToolbarSelect: CustomToolbar =>componente buttons,
    selectableRows:"multiple", "single", "none".,
  */
  const optionsDefaults = {
      filterType: 'dropdown',
      responsive: 'standard',
      print: false,
      download: false,
      selectableRows:'none',
      /* onTableChange: (action:any, state:any) => {
        if(action === 'filterChange'){
          console.dir(state?.displayData);
          console.dir(data);
        }else if(action === 'rowSelectionChange'){
          console.dir(state?.selectedRows?.data);
          console.dir(data);
        }
        console.log(action)
      }, */
      textLabels: {
        body: {
          noMatch: "No se encontro ninguna coincidencia",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Pagina Siguiente",
          previous: "Pagina Anterior",
          rowsPerPage: "Filas por Hoja:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Mostrar/Ocultar columnas",
          filterTable: "Filtrar",
        },
        filter: {
          all: "Todos",
          title: `Filtro : `,
          reset: "Restablecer",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
    },
    ...options
  }
  return (
      <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={optionsDefaults}
      />
  )
}

export default TableBase