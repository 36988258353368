import React, { useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import { isBefore } from 'date-fns';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FechaFunction from '../../function/FechaFunction';

const DateRange = ({minDate,onChange,isDisabled,initianDate,isClearable}) => {
    const [startDate, setStartDate] = useState(initianDate?.startDate);
    const [endDate, setEndDate] = useState(initianDate?.endDate);
    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <TextField
                onClick={onClick}
                value={valueField}
                ref={ref}
                readOnly
                autoComplete='off'
                focused
                InputProps={{
                    startAdornment: (
                        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',alignContent:'center', backgroundColor:'#b1cfff',height:{xs:41,xl:42},width:50,marginLeft:-1.7,borderTopLeftRadius:4,borderBottomLeftRadius:5}}>
                            <InputAdornment position="start">
                                <CalendarMonthIcon sx={{color: '#0364ff',marginLeft:1}} />
                            </InputAdornment>
                        </Box>
                    ),
                }}
                sx={{
                    '& fieldset': {
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        borderColor: '#b1cfff',
                        },
                    },
                    '& input': {
                        color: '#000',
                        background: '#fff',
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        height:10,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    },
                }}
            />
        )
    );
    const onChangeData = (destino,fecha) =>{
        if(destino === 'start'){
            const anterior = isBefore(endDate,fecha)
            setStartDate(fecha)
            if(anterior){
                setEndDate(fecha)
                onChange({
                    startDate: fecha,
                    endDate: fecha
                })
            }else{
                onChange({
                    startDate: fecha,
                    endDate
                })
            }
        }else{
            setEndDate(fecha)
            onChange({
                startDate,
                endDate: fecha
            })
        }
    }
    useEffect(() => {
        if(initianDate?.startDate){
            setStartDate( FechaFunction.parse(initianDate.startDate))
        }
        if(initianDate?.endDate){
            setEndDate( FechaFunction.parse(initianDate.endDate))
        }
    }, [initianDate])
    return (
        <Box display='flex'>
            <Box display='block'>
                <Typography sx={{color: '#000',fontWeight: 'bold'}}>Desde</Typography>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => onChangeData('start',date)}
                    dateFormat="P"
                    disabled={isDisabled}
                    customInput={<CustomInput />}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate || new Date()}
                    popperPlacement="auto"
                    isClearable={isClearable && startDate}
                />
            </Box>
            <Box display='block' ml={2}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}}>Hasta</Typography>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => onChangeData('end',date)}
                    dateFormat="P"
                    disabled={isDisabled || !startDate}
                    customInput={<CustomInput />}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    popperPlacement="auto"
                    isClearable={isClearable && endDate}
                />
            </Box>
        </Box>
    );
}

export default DateRange;
