import { Box } from '@mui/material'

const Feed = ({children}) => {
  return (
    <Box flex={1} sx={{height: '99vh',overflow: 'hidden',border:0,borderRadius:2,overflowX:'hidden',paddingRight:1}} className='bodyScreen'>
      {children}
    </Box>
  )
}

export default Feed