import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectCantDias = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    defaultProps,
    isMulti
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const options = [
        { value: 7, label: 'Ultimos 7 días' },
        { value: 30, label: 'Ultimos 30 días' },
        { value: 60, label: 'Ultimos 60 días' },
        { value: 90, label: 'Ultimos 90 días' },
        { value: 120, label: 'Ultimos 120 días' },
        { value: 180, label: 'Ultimos 180 días' },
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        } else if(Array.isArray(value)){
        setSelectedValue(value.map(va=>{
            const item = options.find(it=>it.value === va);
            if(item){
                return {
                    value:item.value,
                    label:item.label
                };
            }
            return null
        }))
    }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={disabled}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectCantDias.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectCantDias;