import axios from 'axios';
const base = 'logs';

function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}
function getAllTareas(query = null) {
    return axios.get(`${base}/tareas`, {
        params: query
    });
}

function getAllGral(query = null) {
    return axios.get('logs/gral', {
        params: query
    });
}

function getAllContacto(query = null) {
    return axios.get('logs/contacto', {
        params: query
    });
}

function ticketNotifyViewAll911() {
    return axios.post('logs/ticketnotify911/allview');
}

function comentarioNotifyViewAll911() {
    return axios.post('logs/comentarionotify911/allview');
}

function notifyViewAll911() {
    return axios.post('logs/notify911/allview');
}

function ticketNotifyViewAllZeta() {
    return axios.post('logs/ticketnotifyzeta/allview');
}
function ticketNotifyGralViewAllZeta() {
    return axios.post('logs/notifygralzeta/allview');
}
function etiquetaNotifyViewAllZeta() {
    return axios.post('logs/etiquetanotifyzeta/allview');
}

function notifyViewAllZeta() {
    return axios.post('logs/notifyzeta/allview');
}

const LogService = {
    getAll,
    getAllTareas,
    getAllGral,
    getAllContacto,
    ticketNotifyViewAll911,
    comentarioNotifyViewAll911,
    notifyViewAll911,
    ticketNotifyViewAllZeta,
    etiquetaNotifyViewAllZeta,
    notifyViewAllZeta,
    ticketNotifyGralViewAllZeta
};

export default LogService;