import { Box, Card, Grid, Typography } from '@mui/material';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const CardTotalEstadoTk = ({titulo,total,vencidos,noVencidos}) => {

    return (
        <Card sx={{background: '#fff !important',padding: 2,borderRadius:5}}>
            <Box sx={{display: 'flex',alignItems: 'center'}}>
                <Box sx={{borderWidth: 2,borderStyle: 'dashed !important',borderColor: '#1d5ae5 !important', width:45, height: 45,border: 1,borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ConfirmationNumberOutlinedIcon sx={{color: '#1d5ae5',fontSize: 35}} />
                </Box>
                <Typography sx={{color: '#000',marginLeft: 2}} variant='h4'>{titulo}</Typography>
            </Box>
            <Grid container mt={3}>
                <Grid item xs={4} display='flex'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{total || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#74767f',height:30, width: 100,background: '#e7e7e9',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#74767f'}}>• </Box> Total
                    </Typography>
                </Grid>
                <Grid item xs={4} display='flex'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{noVencidos || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#018155',height:30, width: 110,background: '#E6F8F2',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#018155'}}>• </Box> No Vencidos
                    </Typography>
                </Grid>
                <Grid item xs={4} display='flex'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{vencidos || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#9d0b0b',height:30, width: 100,background: '#fce7e7',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#9d0b0b'}}>• </Box> Vencido
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardTotalEstadoTk