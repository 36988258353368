import { useEffect, useState } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import NovedadesService from "../../services/novedades.service";
import ModalAddNovedad from "../../modals/asesores/ModalAddNovedad";

const NovedadesScreen = () => {
    const [items, setItems] = useState([]);
    const actualizar = async() =>{
        await NovedadesService.getAll({all : true}).then((response) =>{
            setItems(response)
        })
    }

    const columns = [
        {
            name: "id_nov",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "titulo_nov",
            label: "Titulo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cuerpo_nov",
            label: "Cuerpo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "nombre_p",
            label: "Creado Por",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "fechaLimit_nov",
            label: "Fecha Limite",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_nov',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value) return <Chip label="Activo" color="success" />
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { !tableMeta.rowData[5] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () => actualizar();

    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_nov === item)
            showModal(ModalAddNovedad, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalAddNovedad, {onGuardar: onGuardar});
        }
    };
    const onEliminar = (item) => {
        const searchItem = items.find((sItem)=> sItem.id_nov === item)
        showModal(ConfirmationDialog, {
            title: `${searchItem?.activo_nov ? 'Activar' : 'Desactivar'} Novedad`,
            message: `¿Esta seguro que desea ${searchItem?.activo_nov ? 'activar' : 'desactivar'} la novedad?`,
            onConfirm: () => {
                NovedadesService.destroy(searchItem.id_nov).then(() => {
                    onGuardar();
                });
            }
        });
    };
    useEffect(() => {
        actualizar();
        document.title = 'Asesores-Novedades';
    }, [])


    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Novedades</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Nuevo Asesor"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default NovedadesScreen