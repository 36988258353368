import { Box, Card, Grid, Typography } from "@mui/material";
import { showModal } from "react-redux-modal-provider";
import { useNavigate } from "react-router-dom";
import ButtonBasic from "../buttons/ButtonBasic";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ModalTicketsPreServicio from "../../modals/ticket/ModalTicketsPreServicio";
import ApiSoftland from "../../services/apis.softland.service";

const CardPreServicio = ({
    item,
    cuenta,
    permisos,
    datosOK,
    datosFaltantes
}) => {
    const navigate = useNavigate();
    const onOpenModal = () => {
        showModal(ModalTicketsPreServicio, {item: item, history:navigate});
    };
    const onOpenSoftland = () =>{
        if(cuenta){
            navigate('/softland/nuevo/cuenta',{state:{item:item, cuenta:cuenta}});
        }else{
            navigate('/softland/nuevo/cliente',{state:{item:item}});
        }
    }
    const checkVinculacion = async() => {
        await ApiSoftland.checkServicioSoftland(item?.id_t);
    }

    return (
        <Card
            sx={{background:'#fff !important', boxShadow: 3,p:2,marginTop: 2,borderRadius:4 }}
        >
            <Typography sx={{fontWeight: 'bold', color: '#000',fontSize: 15}}>{`Pre-Servicio #${item?.id_t || '0'} - ${item?.nombre_t}`}</Typography>
            {item?.nivelVip_t && (
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Nivel VIP: <Box fontWeight='normal' display='inline'>{item?.nivelVip_t}</Box></Typography>
                </Box>
            )}
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box fontWeight='normal' display='inline'>{item?.direccion_t}</Box></Typography>
            </Box>
            {item?.coordenadas_t && (
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas: <Box fontWeight='normal' display='inline'>{item?.coordenadas_t}</Box></Typography>
                </Box>
            )}
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Servicios: <Box fontWeight='normal' display='inline'>{item?.nombrePlan}</Box></Typography>
            </Box>
            {item?.estadoServicio_t && (
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Estado: <Box fontWeight='normal' display='inline'>{item?.estadoServicio_t}</Box></Typography>
                </Box>
            )}
            {item?.subEstadoServicio_t && (
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Sub-Estado: <Box fontWeight='normal' display='inline'>{item?.subEstadoServicio_t}</Box></Typography>
                </Box>
            )}
            <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ButtonBasic
                        iconStart={<LocalOfferOutlinedIcon sx={{color: '#0364ff'}} />}
                        textButton="Ver Tickets"
                        variant="wk"
                        color='nextWhite'
                        sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                        onClick={()=>onOpenModal()}
                    />
                </Grid>
                <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <ButtonBasic
                        iconStart={<LocalMallOutlinedIcon sx={{color: '#0364ff'}} />}
                        textButton="Ver Pre-Servicio"
                        variant="wk"
                        color='nextWhite'
                        sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                        onClick={()=>navigate(`/pre-servicio/${item?.id_t}/nubicom`)}
                    />
                </Grid>
                { permisos['softland.nubiPost']
                    && item?.tipoConSoft_t
                    && (item?.tipoConSoft_t !== 'NCORP' && item?.tipoConSoft_t !== 'NGOBI')
                    && !item?.isEventual_t && (
                    <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center',flexDirection:'column'}}>
                        <ButtonBasic
                            iconStart={<SendOutlinedIcon sx={{color: '#fff',transform:'rotate(-40deg)',marginTop:-0.3}} />}
                            textButton="Enviar datos a Softland"
                            variant="wk"
                            color='nextStep'
                            isDisabled={
                                item?.isDeleted_t
                                || (item?.prefactibilidadRF_t === 'NEGATIVA' || item?.prefactibilidadFO_t === 'NEGATIVA')
                                || !datosOK
                                || (!item?.fechaInstalacionReal_t && !item?.cambDomServicioId_t)
                                || ((item?.nivelAutorizacion_t === 'bonificacion_nivel2' || item?.nivelAutorizacion_t === 'bonificacion_nivel3') && !item?.autorizadoxPersonalId_t && item?.estadoServicio_t !== 'NO AUTORIZADO - VIP')
                                || (item?.cambDomCuentaId_t && (!item?.camTitularConstancia_t || !item?.camTitularContrato_t))
                            }
                            sx={{width: 220}}
                            onClick={()=>onOpenSoftland()}
                        />
                        {item?.isDeleted_t ? (
                            <Typography sx={{color: 'red'}}>Pre-Servicio Cancelado.</Typography>
                        ):(
                            <>
                                {(item?.prefactibilidadRF_t === 'NEGATIVA' && item?.prefactibilidadFO_t === 'NEGATIVA') && (
                                    <Typography sx={{color: 'red'}}>Prefactibilidad Negativa.</Typography>
                                )}
                                {!item?.fechaInstalacionReal_t && !item?.cambDomServicioId_t && (
                                    <Typography sx={{color: 'red'}}>Sin confirmación de instalación.</Typography>
                                )}
                                {!datosOK && (
                                    <Typography sx={{color: 'red'}}>Por favor complete los datos faltantes.</Typography>
                                )}
                                {datosFaltantes && (
                                    <Typography sx={{color: 'red'}}>{datosFaltantes}</Typography>
                                )}
                                {(item?.nivelAutorizacion_t === 'bonificacion_nivel2' || item?.nivelAutorizacion_t === 'bonificacion_nivel3') && !item?.autorizadoxPersonalId_t && (
                                    <Typography sx={{color: 'red'}}>Pendiente de aprobación.</Typography>
                                )}
                                {(item?.cambDomCuentaId_t && !item?.camTitularConstancia_t) && (
                                    <Typography sx={{color: 'red'}}>Falta el contrato firmado.</Typography>
                                )}
                                {(item?.cambDomCuentaId_t && !item?.camTitularContrato_t) && (
                                    <Typography sx={{color: 'red'}}>{`Falta la constancia de recepcion de equipos en Servicio #${item?.cambDomServicioId_t}.`}</Typography>
                                )}
                            </>
                        )}
                    </Grid>
                )}
                {permisos['contactos.corporativo.created'] && item?.tipoConSoft_t === 'NCORP' &&(
                    <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center',flexDirection:'column'}}>
                        <ButtonBasic
                            iconStart={<SendOutlinedIcon sx={{color: '#0364ff',transform:'rotate(-40deg)',marginTop:-0.3}} />}
                            textButton="Consultar Vinculacion"
                            variant="wk"
                            color='nextWhite'
                            sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                            isDisabled={!item?.id_t}
                            onClick={()=>checkVinculacion()}
                        />
                    </Grid>
                )}
                {permisos['contactos.gobierno.created'] && item?.tipoConSoft_t === 'NGOBI' &&(
                    <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center',flexDirection:'column'}}>
                        <ButtonBasic
                            iconStart={<SendOutlinedIcon sx={{color: '#0364ff',transform:'rotate(-40deg)',marginTop:-0.3}} />}
                            textButton="Consultar Vinculacion"
                            variant="wk"
                            color='nextWhite'
                            isDisabled={!item?.id_t}
                            sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                            onClick={()=>checkVinculacion()}
                        />
                    </Grid>
                )}
                {item?.isEventual_t && item?.tipoConSoft_t === 'NPYME' &&(
                    <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'center', alignItems:'center',flexDirection:'column'}}>
                        <ButtonBasic
                            iconStart={<SendOutlinedIcon sx={{color: '#0364ff',transform:'rotate(-40deg)',marginTop:-0.3}} />}
                            textButton="Consultar Vinculacion"
                            variant="wk"
                            color='nextWhite'
                            isDisabled={!item?.id_t}
                            sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                            onClick={()=>checkVinculacion()}
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    )
}

export default CardPreServicio