import {useState, useEffect,Fragment} from 'react';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ContactosService from '../../services/contactos.service';
import CardSearchNav from '../card/CardSearchNav';
import CorporativoService from '../../services/corporativo.service';

const InputSearchContactNubicom = ({
    value,
    disabled,
    onChange,
    isClearable,
    errorText,
    onClick,
    borderChange,
    soloCuentaSoft,
    soloPotCliente,
    corporativo,
    ...props
}) => {

    const [textSearch, setTextSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSearchContacto = async(query) => {
        setIsLoading(true);
        if(corporativo){
            await CorporativoService.getAll({search: query,type: 'all'}).then((data)=>{
                setOptions(
                    data.map((item) =>{
                        if(!item?.id_c){
                            return {
                                ...item,
                                tituloCard: item?.nombre_completo,
                                descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                empresaCard: 'NUBICOM',
                                keyCard: `${item?.cuenta}Cuenta`
                            }
                        }
                        return {
                            ...item,
                            tituloCard: item?.nombreCompleto_c,
                            descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_c}Contacto`
                        }
                    })
                )
            }).finally(() => {
                setIsLoading(false);
            });
        }else{
            await ContactosService.getAll({search: query}).then((data)=>{
                if(soloCuentaSoft){
                    const dataFilter = data?.filter((etc)=>etc?.cuenta)
                    if(dataFilter?.length > 0){
                        setOptions(
                            dataFilter?.map((item) =>{
                                return {
                                    ...item,
                                    tituloCard: item?.nombre_completo,
                                    descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                    empresaCard: 'NUBICOM',
                                    keyCard: `${item?.cuenta}Cuenta`
                                }
                            })
                        )
                    }else{
                        setOptions([])
                    }
                }else if(soloPotCliente){
                    const dataFilter = data?.filter((etc)=>!etc?.cuenta)
                    if(dataFilter?.length > 0){
                        setOptions(
                            dataFilter?.map((item) =>{
                                return {
                                    ...item,
                                    tituloCard: item?.nombreCompleto_c,
                                    descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                                    empresaCard: 'NUBICOM',
                                    keyCard: `${item?.id_c}Contacto`
                                }
                            })
                        )
                    }else{
                        setOptions([])
                    }
                }else{
                    setOptions(
                        data.map((item) =>{
                            if(!item?.id_c){
                                return {
                                    ...item,
                                    tituloCard: item?.nombre_completo,
                                    descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                    empresaCard: 'NUBICOM',
                                    keyCard: `${item?.cuenta}Cuenta`
                                }
                            }
                            return {
                                ...item,
                                tituloCard: item?.nombreCompleto_c,
                                descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                                empresaCard: 'NUBICOM',
                                keyCard: `${item?.id_c}Contacto`
                            }
                        })
                    )
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if(textSearch?.length > 2){
            const delayWrite = setTimeout(() => {
                handleSearchContacto(textSearch)
            }, 600)
            return () => clearTimeout(delayWrite)
        }
    }, [textSearch])

    return (
        <Box sx={{display: 'flex'}}>
            <Autocomplete
                id="asynchronous-demo"
                freeSolo
                isOptionEqualToValue={(option, value) => option?.tituloCard === value?.tituloCard}
                getOptionLabel={(option) => `${option?.tituloCard} ${option?.descripCard}`}
                options={options}
                filterOptions={(options) => options}
                loading={isLoading}
                disableClearable
                fullWidth
                sx={{
                    marginTop: 0.5,
                    marginLeft: -0.2,
                    '& fieldset': {
                        borderRadius: borderChange ? 2 : 7,
                        borderColor: 'gray',
                        borderWidth: 2,
                        height: 43.5,
                        color: '#000',
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline':{
                        borderRadius: borderChange ? 2 : 7,
                        borderColor: '#808080 !important',
                        border: 1,
                        color: '#000 !important',
                        background: '#f2f2f2',
                    },
                    '& input': {
                        color: '#000',
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        focused
                        autoComplete='off'
                        {...params}
                        onChange={(e)=>setTextSearch(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment:(
                                <SearchIcon sx={{color: '#000',marginTop:-1}} size={20} />
                            ),
                            endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress sx={{color: '#000',marginTop:-1}} size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                            ),
                        }}
                        sx={{
                            '& fieldset': {
                                borderRadius: 20
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: 'gray',
                                },
                            },
                            '& input': {
                                marginTop: -1,
                            },
                        }}
                    />
                )}
                renderOption={(props,option)=>{
                    let cortarTitulo = option?.tituloCard;
                    let cortarDescr = option?.descripCard?.replace(/<[^>]*>?/gm, '');
                    cortarDescr = cortarDescr?.replace(/&nbsp[;]?/ig, '');
                    if(cortarTitulo?.length > 30){
                        cortarTitulo = `${cortarTitulo?.substring(0,32)}...`
                    }
                    if(cortarDescr?.length > 80){
                        cortarDescr = `${cortarDescr?.substring(0,80)}...`
                    }
                    return (
                        <CardSearchNav
                            itemsProsp={props}
                            item={option}
                            descripcion={cortarDescr}
                            titulo={cortarTitulo}
                            key={option?.keyCard}
                            onClick={onClick}
                        />
                    )
                }}
            />
        </Box>
    );
};

InputSearchContactNubicom.defaultProps = {
    isClearable: false,
    value: null
};

export default InputSearchContactNubicom;