import axios from 'axios';
const base = 'procesos';

function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function getById(id,) {
    return axios.get([base, id].join('/'));
}

function register(data) {
    const form = new FormData();
    form.append('nombre_pro',data?.nombre_pro);
    form.append('descripcion_pro',data?.descripcion_pro);
    if(data?.html1_pro){
        if(Array.isArray(data.html1_pro) && data.html1_pro.length>0){
            form.append('html1',data.html1_pro[0]);
        } else {
            form.append('html1',data.html1_pro);
        }
    }
    if(data?.html2_pro){
        if(Array.isArray(data.html2_pro) && data.html2_pro.length>0){
            form.append('html2',data.html2_pro[0]);
        } else {
            form.append('html2',data.html2_pro);
        }
    }
    if(data?.video1_pro){
        if(Array.isArray(data.video1_pro) && data.video1_pro.length>0){
            form.append('video1',data.video1_pro[0]);
        } else {
            form.append('video1',data.video1_pro);
        }
    }
    if(data?.video2_pro){
        if(Array.isArray(data.video2_pro) && data.video2_pro.length>0){
            form.append('video2',data.video2_pro[0]);
        } else {
            form.append('video2',data.video2_pro);
        }
    }
    if(data?.video3_pro){
        if(Array.isArray(data.video3_pro) && data.video3_pro.length>0){
            form.append('video3',data.video3_pro[0]);
        } else {
            form.append('video3',data.video3_pro);
        }
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function update(data) {
    const form = new FormData();
    form.append('nombre_pro',data?.nombre_pro);
    form.append('descripcion_pro',data?.descripcion_pro);
    if(data?.html1_pro){
        if(Array.isArray(data.html1_pro) && data.html1_pro.length>0){
            form.append('html1',data.html1_pro[0]);
        } else {
            form.append('html1',data.html1_pro);
        }
    }
    if(data?.html2_pro){
        if(Array.isArray(data.html2_pro) && data.html2_pro.length>0){
            form.append('html2',data.html2_pro[0]);
        } else {
            form.append('html2',data.html2_pro);
        }
    }
    if(data?.video1_pro){
        if(Array.isArray(data.video1_pro) && data.video1_pro.length>0){
            form.append('video1',data.video1_pro[0]);
        } else {
            form.append('video1',data.video1_pro);
        }
    }
    if(data?.video2_pro){
        if(Array.isArray(data.video2_pro) && data.video2_pro.length>0){
            form.append('video2',data.video2_pro[0]);
        } else {
            form.append('video2',data.video2_pro);
        }
    }
    if(data?.video3_pro){
        if(Array.isArray(data.video3_pro) && data.video3_pro.length>0){
            form.append('video3',data.video3_pro[0]);
        } else {
            form.append('video3',data.video3_pro);
        }
    }
    return axios.put([base, data.id_pro].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const ProcesosService = {
    getAll,
    getById,
    register,
    update,
    destroy
};
export default ProcesosService;
