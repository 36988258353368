import { Grid } from "@mui/material"
import { CardConsultaV2 } from "../../../components"

const TabCuentaConsultas = ({consultas, actualizar, actualizarRecordatorios}) => {

    return (
        <Grid container spacing={2}>
            {consultas && consultas?.length > 0 && (
                consultas.map((con)=>(
                    <CardConsultaV2 con={con} actualizar={actualizar} actualizarRecordatorios={actualizarRecordatorios} />
                ))
            )}
        </Grid>
    )
}

export default TabCuentaConsultas