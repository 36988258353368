import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import FechaFunction from "../../function/FechaFunction";
import GroupsIcon from '@mui/icons-material/Groups';
const LogsTimeCard = ({
    item,
    keys
}) => {
    let texto;
    if(item){
        switch (item?.isDeleted_uxt) {
            case true:
            case 1:
                if(item.tipo_uxt === 'DNI'){
                    texto=`estuvo asignado a el ticket por ${item?.transcurso}.`;
                }else if(item.tipo_uxt === 'GRUPO'){
                    texto=`estuvo asignado a el ticket por ${item?.transcurso}.`;
                }
            break;
            case false:
            case 0:
                if(item.tipo_uxt === 'DNI'){
                    texto=`lleva asignado al ticket por ${item?.transcurso}.`;

                }else if(item.tipo_uxt === 'GRUPO'){
                    texto=`lleva asignado al ticket por ${item?.transcurso}.`;
                }
            break;
            default:
                texto='Se ha producido un error.';
            break;
        }
    }
    return (
        <Box className="log-card mt-3" mb={2}>
            <Box display='flex' alignItems='center'>
                {item?.foto_p ? (
                    <Avatar src={item?.foto_p} sx={{width:30, height:30,background: '#dfe2e6'}} />
                ):(
                    <Box sx={{background: '#ebedf0',width:30, height:30,borderRadius:50,display: 'flex', justifyContent:'center',alignItems: 'center',boxShadow:3}}>
                        <GroupsIcon sx={{color: '#243757'}} />
                    </Box>
                )}
                <Typography sx={{color: '#8993a4',ml:0.5,fontWeight: 'bold',fontSize:13}} component='div'>{item?.nombre_p || item?.nombre_g}
                    <Box display='inline' sx={{color: '#8993a4',fontSize:11,fontWeight: 'normal'}}>- Autor</Box>
                    <Box display='inline' sx={{color: '#15294b',fontSize:13, ml:0.3,fontWeight: 'normal'}}>{texto}</Box>
                </Typography>
            </Box>
            <Box display='flex' justifyContent='end' mt={1}>
                <Typography sx={{color: '#15294b',fontSize:11}}>{`${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`}</Typography>
            </Box>
            <Divider sx={{background: 'gray',marginBottom:2,marginTop:2}} />
        </Box>
    )
}

LogsTimeCard.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default LogsTimeCard;
