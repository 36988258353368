import axios from 'axios';
const base = 'niveladmtk';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function update(data) {
    return axios.put([base, data.id_natk].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const NivelAdmTkService = {
    getAll: getAll,
    register: register,
    update: update,
    destroy: destroy
};
export default NivelAdmTkService;
