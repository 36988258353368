import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import "quill-mention";
import 'react-quill/dist/quill.snow.css'
import "quill-mention/dist/quill.mention.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
const Styles = styled.div`
    .ql-toolbar{
        padding-bottom: 15px;
    }
    .ql-container{
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        color: #000;
    }
    .ql-editor{
        height: 180px;
    }
    .ql-container.ql-snow{
        background: #fff;
    }
    .ql-snow.ql-toolbar {
        display: block;
        background: #e6f0ff;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        position: sticky!important;
        top: 0;
        z-index: 1;
    }
    span.mention {
        color: blue;
        background: lightskyblue;
    }
`;


const InputEditQuill =({onChangeText,template,id,etiqueta}) => {
    const [value, setValue] = useState(template || '')
    const users = useSelector(state => state.default.usuarioSelect);
    const hashtags = useSelector(state => state.default.hashtags);
    const [usersArr, setUsersArr] = useState([]);
    const [hashtagsArr, setHashtagsArr] = useState([]);

    useEffect(() => {
        if(users?.length > 0){
            setUsersArr(users);
        }
    }, [users])
    useEffect(() => {
        if(hashtags?.length > 0){
            setHashtagsArr(hashtags);
        }
    }, [hashtags])
    const searchPerson = (searchTerm) => {
        const uss =  usersArr?.filter(person => (person.value.toLowerCase()).includes(searchTerm.toLowerCase()));
        return uss;
    }
    const searchHash = (searchTerm) => {
        const uss =  hashtagsArr?.filter(hash => (hash.value.toLowerCase()).includes(searchTerm.toLowerCase()));
        return uss;
    }

    const modules = useMemo(() => ({
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            minChars: 2,
            mentionDenotationChars: ["@",'#'],
            source: function(searchTerm, renderList,mentionChar) {
                if(mentionChar === '@'){
                    const matchedPeople = searchPerson(searchTerm);
                    renderList(matchedPeople);
                }else if(mentionChar === '#'){
                    const matchedPeople = searchHash(searchTerm);
                    renderList(matchedPeople);
                }else{
                    renderList([])
                }
            }
        },
        toolbar: [
            ['bold', 'italic', 'underline','strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'header': [2, 3, false] }],
            [{ 'align': [] }],
            [{ 'color': ['#ff0000', '#00ff00', '#0000ff', '#000000', '#ffffff'], 'default': '#ff0000' }, { 'background': [] }],
            ['clean']
        ],
    }), [usersArr,hashtagsArr])
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent','background','color','align','mention'
    ]
    const onChangeTemplete = (newEditor) =>{
        onChangeText(newEditor)
        setValue(newEditor)
    }

    useEffect(() => {
        onChangeText(template)
        setValue(template)
    }, [template])
    return (
        <Styles>
            {usersArr?.length > 0 && hashtagsArr?.length > 0 ? (
                <ReactQuill
                    id={id}
                    theme="snow"
                    value={value}
                    onChange={onChangeTemplete}
                    modules={modules}
                    formats={formats}
                />
            ):(
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress sx={{color: "#0364ff"}} />
                </Box>
            )}
        </Styles>
    )
}

export default InputEditQuill