import axios from 'axios';
const base = 'consultas';

function getAll(query = null) {
    return axios.get(base, { params: query });
}

function register(data) {
    return axios.post(base, data);
}

function registerConsultaCobertura(data) {
    return axios.post(`${base}/cobertura`, data);
}

function registerConsultaCoberturaCamDomicilio(data) {
    return axios.post(`${base}/cobcamdom`, data);
}

function infoClienteConsulta(data) {
    return axios.post(`${base}/info`, data);
}

function consultarVinculacion(id) {
    return axios.get([base,'vinculacion', id].join('/'));
}

function realizarVinculacion(data) {
    return axios.post(`${base}/vincular`, data);
}

function forzarVinculacion(data) {
    return axios.post(`${base}/vincular-force`, data);
}

function registerNota(data) {
    const form = new FormData();
    form.append('consultaId_nc',data.consultaId_nc);
    form.append('cuerpo_nc',data.cuerpo_nc);
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    return axios.post(`${base}/nota`, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function validarTarjeta(data) {
    return axios.post(`${base}/validar-card`, data);
}

const ConsultasService = {
    getAll,
    register,
    registerConsultaCobertura,
    registerConsultaCoberturaCamDomicilio,
    infoClienteConsulta,
    consultarVinculacion,
    realizarVinculacion,
    forzarVinculacion,
    registerNota,
    validarTarjeta
};
export default ConsultasService;
