import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardRecordatorio } from '../../components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { showModal } from 'react-redux-modal-provider';
import ModalCrearRecordatorio from './ModalCrearRecordatorio';
import RecordatorioService from '../../services/recordatorio.service';

function ModalRecordatoriosConsulta({
    id,
    show,
    hideModal,
    consulta
}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [recordatorios, setRecordatorios] = useState([]);
    const actualizarRecordatorios = async() =>{
        await RecordatorioService.getAll({consultaId: consulta?.id_con}).then((resp)=>{
            setRecordatorios(resp)
        })
    }
    useEffect(() => {
        actualizarRecordatorios();
    }, []);


    const onOpenModalNewRecordatorio = () => {
        showModal(ModalCrearRecordatorio,{
            onGuardar: actualizarRecordatorios,
            nombreCompleto: consulta?.nombreContacto_con,
            contactoId: consulta?.contactoId_con,
            cuentaId: consulta?.contactoCuenta_con,
            servicioId: consulta?.cuentaServicioId_con,
            preServicioId: consulta?.preServicioId_con,
            consultaId: consulta?.id_con,
            ticketId: null,
        });
    }

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Recordatorios de Consulta #${consulta?.id_con}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', justifyContent: 'right'}}>
                                <ButtonBasic
                                    textButton='Crear Recordatorio'
                                    variant='wk'
                                    color='nextStep'
                                    onClick={()=>onOpenModalNewRecordatorio()}
                                />
                            </Box>
                            <Grid container spacing={1}>
                                {recordatorios?.length > 0 && (
                                    recordatorios.map((recs)=>(
                                        <Grid item xs={12}>
                                            <CardRecordatorio
                                                key={recs?.id_rec}
                                                item={recs}
                                                onCloseModal={handleClose}
                                                onGuardar={actualizarRecordatorios}
                                            />
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        </Box>
                </DialogContent>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic onClick={()=>handleClose()} textButton='Cerrar' variant='wk' color='cancel' sx={{mb:2,width: 100}} />
                </Box>
            </Box>
        </Dialog>
    );
}

ModalRecordatoriosConsulta.propTypes = {
    show: PropTypes.bool
};

ModalRecordatoriosConsulta.defaultProps = {
    show: false
};

export default ModalRecordatoriosConsulta;