import { Box, Divider, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TextMark from '../text/TextMark';

const CardSearchNav = ({itemsProsp,item,titulo,descripcion,setOpen,onClick,onCLoseModal,textSearch}) => {
    const navigate = useNavigate();
    const redirectOnClick = () =>{
        if(onClick){
            onClick(item)
        }else{
            if(item?.id_tk > 0){
                switch (item?.createdEmp_tk) {
                    case 'NUBICOM':
                        navigate(`/ticket/${item?.id_tk}/nubicom`);
                        break;
                    case 'STRONG':
                        navigate(`/ticket/${item?.id_tk}/strongsystems`);
                        break;
                    case 'IDS':
                        navigate(`/ticket/${item?.id_tk}/ids`);
                        break;
                    case 'CVV':
                        navigate(`/ticket/${item?.id_tk}/cvv`);
                        break;
                    default:
                        navigate(`/ticket/${item?.id_tk}/nubicom`);
                        break;
                }
            }
            if(item?.cuenta){
                navigate(`/contactos/${item.cuenta}/cuenta`)
            }
            if(item?.id_c){
                navigate(`/contactos/${item.id_c}/nubicom`)
            }
            if(item?.id_t){
                if(item?.cuenta){
                    navigate(`/servicio/${item?.id_t}/cuenta`)
                }else{
                    navigate(`/pre-servicio/${item?.id_t}/nubicom`)
                }
            }
            if(item?.nroTk){
                navigate(`/ticket/${item?.nroTk}/nubicom`);
            }
            if(onCLoseModal){
                onCLoseModal()
            }
        }
        if(setOpen){
            setOpen(false)
        }
    }
    function extractNumbersFromDescription(descripcion) {
        const matches = descripcion.match(/DNI\/CUIT: (\d+)/);
        return matches ? matches[1] : '';
    }
    function extractAccountNumberFromDescription(descripcion) {
        const matches = descripcion.match(/N° CUENTA: (\d+)/);
        return matches ? matches[1] : '';
    }
    function isExpectedFormat(descripcion) {
        return /^DNI\/CUIT: \d+ - N° CUENTA: \d+$/.test(descripcion);
    }
    function hasAccountNumber(descripcion) {
        return /N° CUENTA: \d+/.test(descripcion);
    }
    return (
        <Box onClick={()=>redirectOnClick()}>
            <Grid container {...itemsProsp} sx={{display:'flex',justifyContent: 'center',alignItems:'center'}}>
                <Grid item xs={2} sx={{display:'flex',justifyContent: 'center',alignItems:'center',marginLeft:-2,marginRight:1}}>
                    {item.empresaCard === 'NUBICOM' &&(
                        <img src="/img/nubi-cirucular.png" alt="Icon" style={{width: '40px', height: '40px'}} />
                    )}
                    {item.empresaCard === 'IDS' &&(
                        <img src="/img/ids-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresaCard === 'STRONG' &&(
                        <img src="/img/strong-logo.png" alt="Icon" style={{width: '40px', height: '45px'}} />
                    )}
                    {item.empresaCard === 'CVV' &&(
                        <img src="/img/911-logo.png" alt="Icon" style={{width: '40px', height: '50px'}} />
                    )}
                </Grid>
                <Grid item xs={10}>
                    <strong style={{fontSize: 12, marginTop:-9,color: '#000'}}>
                        <TextMark texto={titulo || ''} textMark={textSearch || ''} />
                    </strong>
                    <br />
                    <span>
                        <small className='' style={{ color: '#000' }}>
                            {isExpectedFormat(descripcion) ? (
                                <>
                                    <TextMark texto={`DNI/CUIT: `} textMark={textSearch || ''} />
                                    {hasAccountNumber(descripcion) ? (
                                        <>
                                            <strong>
                                                <TextMark
                                                    texto={extractNumbersFromDescription(descripcion)}
                                                    textMark={textSearch || ''}
                                                />
                                            </strong>
                                            <TextMark
                                                texto={` - N° CUENTA: `}
                                                textMark={textSearch || ''}
                                            />
                                            <strong>
                                                <TextMark
                                                    texto={extractAccountNumberFromDescription(descripcion)}
                                                    textMark={textSearch || ''}
                                                />
                                            </strong>
                                        </>
                                    ) : (
                                        <strong>
                                            <TextMark
                                                texto={extractNumbersFromDescription(descripcion)}
                                                textMark={textSearch || ''}
                                            />
                                        </strong>
                                    )}
                                </>
                            ) : (
                                <>
                                    {extractNumbersFromDescription(descripcion) ? (
                                        <>
                                            <TextMark texto={`DNI/CUIT: `} textMark={textSearch || ''} />
                                            <strong>
                                                <TextMark
                                                    texto={extractNumbersFromDescription(descripcion) || 'S/N'}
                                                    textMark={textSearch || ''}
                                                />
                                            </strong>
                                        </>
                                    ):(
                                        <TextMark texto={descripcion || ''} textMark={textSearch || ''} />
                                    )}
                                </>
                            )}
                        </small>
                    </span>
                </Grid>
            </Grid>
                <Divider sx={{color: '#000', background: 'gray'}} />
        </Box>
    )
}

export default CardSearchNav