import { useState, useMemo, useEffect } from 'react';
import { Box, Card, Divider, Grid, IconButton, TextField, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles';
import { ButtonBasic, TableBase } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import ReportesService from '../../services/reportes.service';
import FechaFunction from '../../function/FechaFunction';
import DownloadIcon from '@mui/icons-material/Download';
import { showModal } from 'react-redux-modal-provider';
import ModalNuevoReporte from '../../modals/reporte/ModalNuevoReporte';
import ModalReporteDownload from '../../modals/reporte/ModalReporteDownload';
import ShareIcon from '@mui/icons-material/Share';
import ModalCompartirReporte from '../../modals/reporte/ModalCompartirReporte';
import usePermisos from '../../hooks/usePermisos';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import DownloadFile from '../../services/downloadFile.service';
import ConfirmationDialog from '../../modals/default/ConfirmationDialog';
import TodayIcon from '@mui/icons-material/Today';
import ModalVerReporte from '../../modals/reporte/ModalVerReporte';
import EditIcon from '@mui/icons-material/Edit';
import ModalReporteProgramado from '../../modals/reporte/ModalReporteProgramado';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ReportesScreen = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => setValue(newValue);
    const [textSearch, setTextSearch] = useState('');
    const [reportView, setReportView] = useState([]);
    const [reportAll, setReportAll] = useState([]);
    const [listReport, setListReport] = useState([]);
    const [listReportProgramados, setListReportProgramados] = useState([]);
    const permisos = usePermisos()
    const actualizarReportAll = async() =>{
        await ReportesService.getAll()
        .then((response)=>{
            setReportAll(response);
        })
    }
    const actualizarListReports = async() =>{
        await ReportesService.listaReport()
        .then((resp)=>{
            const filtro1 = resp.filter((e)=> (e?.tipo_lr === 'NORMAL' || e?.pathLocation_lr) )
            setListReport(filtro1);
            const filtro2 = resp.filter((e)=>['DIA','NUMERO'].includes(e?.tipo_lr) && !e?.pathLocation_lr)
            setListReportProgramados(filtro2)
        })
    }
    const actualizar = () =>{
        actualizarReportAll()
    }
    const onOpenModalNewReport = ()=>{
        showModal(ModalNuevoReporte,{onGuardar: actualizar, permisos: permisos})
    }
    const onOpenModalEditReport = (dataInfo)=>{
        showModal(ModalNuevoReporte,{item: dataInfo, onGuardar: actualizar, permisos: permisos})
    }
    const onOpenModalDownloadReport = (report)=>{
        showModal(ModalReporteDownload,{reporte: report, actualizarListReports: actualizarListReports,permisos:permisos})
    }
    const onOpenModalCompartirReport = (report)=>{
        showModal(ModalCompartirReporte,{reporte: report, onGuardar: actualizar})
    }
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
            width: {xs:'120%', sm: 350, md: 450}
        }
    }), [])
    useEffect(() => {
        actualizar();
        actualizarListReports()
        document.title = 'Reportes';
    }, [])
    useEffect(() => {
        const totalRep = reportAll;
        let repotViewFilter = totalRep;
        switch (value) {
            case 0:
                repotViewFilter = totalRep;
                break;
            case 1:
                const filtroAct1 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'potencialcliente')
                repotViewFilter = filtroAct1;
                break;
            case 2:
                const filtroAct2 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'pre-servicio')
                repotViewFilter = filtroAct2;
                break;
            case 3:
                const filtroAct3 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'ticket')
                repotViewFilter = filtroAct3;
                break;
            case 4:
                const filtroAct4 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'consultas')
                repotViewFilter = filtroAct4;
                break;
            case 5:
                const filtroAct5 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'tags')
                repotViewFilter = filtroAct5;
                break;
            default:
                repotViewFilter = [];
                break;
        }
        setReportAll(totalRep)
        setReportView(repotViewFilter)
    }, [value,reportAll])

    const inSearchText = (text) =>{
        let repotViewFilter = reportAll;
        if(text?.length > 0){
            switch (value) {
                case 0:
                    repotViewFilter = reportAll;
                    break;
                case 1:
                    const filtroAct1 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'potencialcliente')
                    repotViewFilter = filtroAct1;
                    break;
                case 2:
                    const filtroAct2 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'pre-servicio')
                    repotViewFilter = filtroAct2;
                    break;
                case 3:
                    const filtroAct3 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'ticket')
                    repotViewFilter = filtroAct3;
                    break;
                case 4:
                    const filtroAct4 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'consultas')
                    repotViewFilter = filtroAct4;
                    break;
                case 5:
                    const filtroAct5 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'tags')
                    repotViewFilter = filtroAct5;
                    break;
                default:
                    repotViewFilter = [];
                    break;
            }
            const filtro = repotViewFilter?.filter((tkv)=> tkv?.titulo_rts?.toUpperCase()?.includes(text?.toUpperCase()))
            setReportView(filtro)
        }else{
            switch (value) {
                case 0:
                    repotViewFilter = reportAll;
                    break;
                case 1:
                    const filtroAct1 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'potencialcliente')
                    repotViewFilter = filtroAct1;
                    break;
                case 2:
                    const filtroAct2 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'pre-servicio')
                    repotViewFilter = filtroAct2;
                    break;
                case 3:
                    const filtroAct3 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'ticket')
                    repotViewFilter = filtroAct3;
                    break;
                case 4:
                    const filtroAct4 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'consultas')
                    repotViewFilter = filtroAct4;
                    break;
                case 5:
                    const filtroAct5 = repotViewFilter.filter((tk)=> tk.tipo_rts === 'tags')
                    repotViewFilter = filtroAct5;
                    break;
                default:
                    repotViewFilter = [];
                    break;
            }
            setReportView(repotViewFilter)
        }
        setTextSearch(text)
    }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
        },
    }));
    const descargarFile = async(itemId) =>{
        const buscador = listReport.find((e)=>e?.id_lr === itemId)
        await AuxiliarFunction.downloadFile(DownloadFile.fileNubi({id:`listareportes/${buscador?.pathLocation_lr}`}),`${buscador?.['report.titulo_rts'] || 'no-name'}`)
    }
    const onEliminar = (itemId) => {
        showModal(ConfirmationDialog, {
            title: 'Cancelacion de Reporte',
            message: `¿Esta seguro que desea cancelar el reporte?`,
            onConfirm: async() => {
                await ReportesService.destroyListReport(itemId).then(()=>{
                    actualizarListReports()
                })
            }
        });
    };
    const onOpenDateRange = (itemId) => {
        const buscador = listReport.find((e)=>e?.id_lr === itemId)
        showModal(ModalVerReporte,{item: buscador});
    };

    const columns = [
        {
            name: "id_lr",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "createdAt",
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return FechaFunction.format(new Date(value),'dd/MM/yyyy HH:mm:ss');
                }
            }
        },
        {
            label: "Nombre",
            name: "report.titulo_rts",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: 'Estado',
            name: 'estado_lr',
        },
        {
            name: "tipo_lr",
            label: "tipo",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "pathLocation_lr",
            label: "pathLocation_lr",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Box display='flex'>
                        {(tableMeta.rowData[4] === 'NORMAL' || tableMeta.rowData[5]) && (
                            <LightTooltip  title="Rango de Fechas" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenDateRange(tableMeta.rowData[0])}>
                                    <TodayIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {((tableMeta.rowData[4] === 'DIA' || tableMeta.rowData[4] === 'NUMERO') && !tableMeta.rowData[5] && tableMeta.rowData[3] === 'PENDIENTE') && (
                            <LightTooltip  title="Editar" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenEditProgramado(tableMeta.rowData[0])}>
                                    <EditIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {tableMeta.rowData[3] === 'PENDIENTE' && (
                            <LightTooltip  title="Eliminar" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                    <DeleteIcon fontSize="medium" sx={{color: '#e01010'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {tableMeta.rowData[3] === 'EN PROCESO' && (
                            <LightTooltip  title="¡Ya casi!" placement="top">
                                <IconButton color='primary' aria-label="ver">
                                    <AccessTimeIcon fontSize="medium" sx={{color: '#b39500'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {tableMeta.rowData[3] === 'COMPLETADO' && (
                            <LightTooltip  title="Descargar" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>descargarFile(tableMeta.rowData[0])}>
                                    <FileDownloadIcon fontSize="medium" sx={{color: '#018155'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                    </Box>
                )
            }
        }
    ];
    const onOpenNewProgramado = () => {
        showModal(ModalReporteProgramado,{onGuardar: actualizarListReports,reporteArr: reportAll});
    };
    const onOpenEditProgramado = (itemId) => {
        const buscador = listReportProgramados.find((e)=>e?.id_lr === itemId)
        showModal(ModalReporteProgramado,{onGuardar: actualizarListReports,reporteArr: reportAll,item: buscador});
    };
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={4}>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Tabla de Reportes</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Nuevo Reporte"
                        sx={{width: 200, height: 30, color: '#fff'}}
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        onClick={()=>onOpenModalNewReport()}
                        variant='wk'
                        color='nextStep'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            sx={{
                                '& .MuiTabs-indicator':{
                                    background: '#0364ff'
                                },
                                '& .MuiButtonBase-root':{
                                    color: '#6b788e',
                                    fontWeight: 'bold'
                                },
                                '& .MuiButtonBase-root.Mui-selected':{
                                    color: '#0364ff'
                                }
                            }}
                        >
                            <Tab label='Todos' {...a11yProps(0)} />
                            <Tab label='Potenciales Clientes' {...a11yProps(1)} />
                            <Tab label='Pre-Servicios' {...a11yProps(2)} />
                            <Tab label='Tickets' {...a11yProps(3)} />
                            <Tab label='Consultas' {...a11yProps(4)} />
                            <Tab label='Tags' {...a11yProps(5)} />
                            <Tab label='Mis reportes' {...a11yProps(6)} />
                            <Tab label='Programados' {...a11yProps(7)} />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    {[0,1,2,3,4,5].includes(value) && (
                        <Card sx={{background: '#fff !important'}}>
                            <Grid container>
                                <Grid item xs={10} sm={10} p={1}>
                                    <TextField
                                        focused
                                        autoComplete='off'
                                        value={textSearch}
                                        onChange={(e)=>inSearchText(e.target.value)}
                                        InputProps={{
                                            type: 'search',
                                            startAdornment:(
                                                <SearchIcon sx={{color: '#000'}} size={20} />
                                            ),
                                        }}
                                        sx={styleInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} display='flex' alignItems='center' justifyContent='center' p={1}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}}>{`${reportView?.length || '0'} resultados`}</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{background: 'gray'}} />
                            <Grid container>
                                <Grid item xs={5} sm={5} p={1.5}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',ml:{xs:0,sm:10}}}>Reportes</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} display='flex' alignItems='center'>
                                    <Divider orientation='vertical' sx={{background: 'gray'}} />
                                    <Typography sx={{color: '#000',fontWeight: 'bold',ml:{xs:0,sm:10}}}>Descripcion</Typography>
                                </Grid>
                                <Grid item xs={2} sm={3} display='flex' alignItems='center'>
                                    <Divider orientation='vertical' sx={{background: 'gray'}} />
                                    <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',ml:4}}>Ultimo acceso</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{background: 'gray'}} />
                            <Box mb={5}>
                                {reportView && reportView?.length > 0 &&(
                                    reportView?.map((it)=>{
                                        return (
                                            <Box
                                                sx={{
                                                    background: '#fff !important',border: 1,borderColor: '#ebedf0',borderBottomColor: '#c2c7d0',p:1,
                                                    '&:hover':{background: '#e7e7e9 !important'}
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={5} display='flex' justifyContent='left' alignItems='center' onClick={()=>onOpenModalEditReport(it)}>
                                                        <Typography sx={{color: '#000',fontWeight: 'bold',fontSize:13}}>{it?.titulo_rts}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} display='flex' justifyContent='center' alignItems='center' onClick={()=>onOpenModalEditReport(it)}>
                                                        <Typography sx={{color: '#000',fontSize:13}}>{it?.descripcion_rts || '-'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography sx={{color: '#000',fontSize:13,ml: 1.5}}>{
                                                            it?.ultimaDescarga_rts ?
                                                            FechaFunction.format(new Date(it?.ultimaDescarga_rts), 'dd/MM/yyyy HH:mm')
                                                            : '-'
                                                        }</Typography>
                                                        <Box sx={{display: 'flex'}}>
                                                            {!it?.compartido && (
                                                                <IconButton
                                                                    sx={{boxShadow: 3, marginLeft: 2}}
                                                                    onClick={()=>onOpenModalCompartirReport(it)}
                                                                >
                                                                    <ShareIcon sx={{color: '#000'}} />
                                                                </IconButton>
                                                            )}
                                                            <IconButton
                                                                sx={{boxShadow: 3, marginLeft: 2}}
                                                                onClick={()=>onOpenModalDownloadReport(it)}
                                                            >
                                                                <DownloadIcon sx={{color: '#000'}} />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    })
                                )}
                            </Box>
                        </Card>
                    )}
                    {value === 6 && (
                        <Card sx={{background: '#fff !important'}}>
                            <TableBase title='Resultados' columns={columns} data={listReport} />
                        </Card>
                    )}
                    {value === 7 && (
                        <Card sx={{background: '#fff !important',padding: 2}}>
                            <Box sx={{width: '100%', display:'flex', justifyContent: 'right',mb: 2}}>
                                <ButtonBasic
                                    textButton='Nueva Programación'
                                    variant='wk'
                                    color='nextStep'
                                    onClick={()=>onOpenNewProgramado()}
                                />
                            </Box>
                            <TableBase title='Resultados' columns={columns} data={listReportProgramados} />
                        </Card>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ReportesScreen