import { useEffect, useState } from "react"
import { Box, Chip, Grid, Typography } from "@mui/material"
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { useNavigate } from "react-router-dom";
import KmzService from "../../services/kmz.service";
import ModalCargarKmz from "../../modals/kmz/ModalCargarKmz";

const TablaRadioEnlaceScreen = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate()
    const actualizar = async() =>{
        await KmzService.obtenerPoligonosRF().then((resp)=>{
            setItems(resp);
        }).catch(()=>{
            navigate('/')
        })
    }

    const columns = [
        {
            name: "id_pol",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_pol",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "tipoTecnologia_pol",
            label: "Tecnologia",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "tipoCobertura_pol",
            label: "Cobertura",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_pol',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                    return <Chip label="Activo" color="success" />
                    }
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
    ];
    const onGuardar = () => actualizar();

    const onOpenEditar = () => {
        showModal(ModalCargarKmz, { onGuardar: onGuardar});
    };

    useEffect(() => {
        actualizar();
        document.title = `Paneles RF`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Tabla Paneles</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Subir KMZ"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TablaRadioEnlaceScreen