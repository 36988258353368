import { useContext, useState, useMemo, useEffect } from 'react';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, SelectMotivoTicket, InputEditQuill, CardUploadFile, InputDropZoneComment } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import TicketService from '../../services/tickets.service';

function ModalCrearTicketStrong({show, hideModal,onGuardar,tk}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const { sockets } = useContext( SocketContext );
    const [files, setFiles] = useState([]);
    const [filesCompress, setFilesCompress] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const [formAll, setFormAll] = useState({
        titulo_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuerpo_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        MotivoTicketId: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoId_uxt: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipo_uxt: {
            texto: 'GRUPO',
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        }
    })
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }

    const onCompressImg = async(imgx) =>{
        const nombreCortado = imgx?.name?.split('.');
        let extension = nombreCortado[ nombreCortado?.length - 1 ];
        extension = extension?.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx?.id
                    result.preview=imgx?.preview
                    setIsLoading(false);
                    setFilesCompress(old => [...old,result]);
                }
            })
        }else{
            setFilesCompress(old => [...old,imgx]);
        }
    }

    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }

    const onSubmit = async() =>{
        setIsLoading(true);
        const form = {
            ticketIndv: true,
            propertys: 'strong',
            createdEmp_tk: 'STRONG',
            estado_tk: 'EN PROCESO',
            prioridad_tk: 'A DEFINIR',
        };
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['titulo_tk', 'cuerpo_tk','MotivoTicketId','tipoId_uxt', 'tipo_uxt']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(tk){
                form.ticketIdOld_tk = tk?.ticketIdOld_tk;
            }
            if(filesCompress?.length > 0){
                form.filesCompress = filesCompress;
            }
            TicketService.register(form).then(()=>{
                sockets?.emit( 'newTicket',formAll?.destinoDNI?.texto);
                if(tk){
                    sockets?.emit('ticketStrRefresh',`strong${tk?.id_tk}`);
                }
                if(onGuardar){
                    onGuardar(true);
                }
                hideModal();
            })
            .catch(()=>{
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])

    useEffect(() => {
        if(tk){
            onChangeForm(tk.motivo_tk?.MotivoTicketId)
            onChangeForm(tk?.motivotk?.grupoId_mt, 'tipoId_uxt')
        }
    }, [tk])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Ticket - STRONG
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Motivo del Ticket</Typography>
                            <SelectMotivoTicket
                                id="MotivoTicketId"
                                value={formAll.MotivoTicketId.texto}
                                isDisabled={tk}
                                emp='strong'
                                isInvalid={formAll.MotivoTicketId.estadoErr}
                                onChange={(opt) => {
                                    onChangeForm(opt.value, 'MotivoTicketId');
                                    onChangeForm(opt?.descripcion || '', 'cuerpo_tk');
                                    onChangeForm(opt.destino, 'tipoId_uxt');
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.titulo_tk.texto}
                                onChange={(e)=>onChangeForm(e.target.value, 'titulo_tk')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputEditQuill
                                template={formAll.cuerpo_tk.texto}
                                onChangeText={(text)=>onChangeForm(text, 'cuerpo_tk')}
                            />
                        </Grid>
                        { filesCompress?.length > 0 &&(
                            filesCompress.map((fil)=>(
                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                            </Grid>
                            ))
                        )}
                    </Grid>
                    { filesCompress?.length < 5 &&(
                        <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                            <InputDropZoneComment
                                id="otImg"
                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                setFoto={setFiles}
                                accept={acceptInputFile}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalCrearTicketStrong;