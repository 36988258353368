import axios from 'axios';

function ticketExcel(query = null) {
    return axios.get('download/excelcvv',{
        responseType: 'blob',
        params: query
    });
}
function ticketPdf(query = null) {
    return axios.get('download/pdfcvv',{
        responseType: 'blob',
        params: query
    });
}

function contratoNubiPdf(query = null) {
    return axios.get('download/pdfnubicom',{
        responseType: 'blob',
        params: query
    });
}

function constanciaCuitNubi(query = null) {
    return axios.get('download/constancianubi',{
        responseType: 'blob',
        params: query
    });
}

function reportExcelNubi(query = null) {
    return axios.get('download/reportnubi',{
        responseType: 'blob',
        params: query
    });
}

function fileNubi(query = null) {
    return axios.get('download/filenubi',{
        responseType: 'blob',
        params: query
    });
}

function constanciaEquiposNubiPdf(query = null) {
    return axios.get('download/pdfconstancia',{
        responseType: 'blob',
        params: query
    });
}

function reportExcelNubiV2(data = null) {
    return axios.post('download/reportnubiv2',data);
}

function updateReportExcelNubiV2(data) {
    return axios.put(['download','reportnubiv2', data?.id_lr].join('/'), data);
}

const DownloadFile = {
    ticketExcel,
    ticketPdf,
    contratoNubiPdf,
    constanciaCuitNubi,
    reportExcelNubi,
    fileNubi,
    constanciaEquiposNubiPdf,
    reportExcelNubiV2,
    updateReportExcelNubiV2
};
export default DownloadFile;
