import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputSearchDireccionEntrega, InputSearchIdDirEntOne, SelectLocalidadesSoftland } from '../../components';
import DialogTitleDefault from '../default/DialogTitleDefault';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import {toast} from 'react-toastify';
import ApiSoftland from '../../services/apis.softland.service';

const ModalDirEntregaUpdate = ({show, hideModal}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [codigoPostalId, setCodigoPostal] = useState(null);
    const [servicioId, setServicioId] = useState(null);
    const [domicilio, setDomicilio] = useState(null);
    const [latitud, setLatitud] = useState(null);
    const [longitud, setLongitud] = useState(null);
    const handleClose = () => hideModal();
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                    border:0,
                    height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    const onSubmit = async() => {
        const form = {
            contrato: servicioId,
            cod_postal: codigoPostalId,
            direccion: domicilio
        }
        setIsLoading(true);
        if(latitud?.length > 0 || longitud?.length > 0){
            const validCoord = `${latitud?.trim()}, ${longitud?.trim()}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                form.latitud = latitud?.trim();
                form.longitud = longitud?.trim();
                await ApiSoftland.updateDirEntrega(form).then((resp)=>{
                    toast.success(resp?.msg || '-', {
                        position: "top-right",
                        autoClose: 2500,
                        pauseOnHover: false,
                    });
                    setIsLoading(false);
                    handleClose()
                }).catch(()=>{
                    setIsLoading(false);
                })
            }else{
                toast.error('Las coordenadas no son validas.', {
                    position: "top-right",
                    autoClose: 2500,
                    pauseOnHover: false,
                });
            }
        }else{
            await ApiSoftland.updateDirEntrega(form).then((resp)=>{
                toast.success(resp?.msg || '-', {
                    position: "top-right",
                    autoClose: 2500,
                    pauseOnHover: false,
                });
                setIsLoading(false);
                handleClose()
            }).catch(()=>{
                setIsLoading(false);
            })
        }

    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Editar Contacto - Softland
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent sx={{maxHeight: '75vh'}} className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Buscar Servicio</Typography>
                            <InputSearchIdDirEntOne
                                onClick={(e)=>{
                                    setServicioId(e?.contrato)
                                    setCodigoPostal(e?.codpostal)
                                    setDomicilio(e?.direccion_servicio || e?.direccion_cliente || 'S/D')
                                    setLatitud(e?.latitud)
                                    setLongitud(e?.longitud)
                                }}
                            />
                            <Divider sx={{background: 'gray'}} />
                        </Grid>
                        <Grid item xs={12} md={5} lg={5}>
                            <Typography sx={{color: '#000'}}>Codigo Postal</Typography>
                            <SelectLocalidadesSoftland
                                value={codigoPostalId}
                                onChange={(e)=>{
                                    setCodigoPostal(e?.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            <Typography sx={{color: '#000'}}>Domicilio</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={domicilio}
                                onChange={(e)=>setDomicilio(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Latitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={latitud}
                                onChange={(e)=>setLatitud(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Longitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={longitud}
                                onChange={(e)=>setLongitud(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{width: 150}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={!codigoPostalId}
                        sx={{width: 150}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalDirEntregaUpdate