
import AuthService from "../../services/auth.service"
import types from "../types/types"
import store from '..';
import Cookies from 'js-cookie'


export const startLogin = (email, pass,rememberMe) => (dispatch) => {
    return AuthService.login(email,pass).then((resp)=>{
        localStorage.setItem('token',resp.token)
        localStorage.setItem('tokenNubi',resp?.tokenNubi)
        localStorage.setItem('tokenStr',resp?.tokenStr)
        if(rememberMe){
            const form = {
                rememberMe,
                email,
                pass
            }
            Cookies.set('auth', JSON.stringify(form))
        }else{
            Cookies.remove('auth')
        }
        dispatch(login({
            token: resp.token,
            tokenNubi :resp.tokenNubi,
            tokenStr: resp?.tokenStr,
            usuario :resp.usuario,
        }))
        return Promise.resolve();
    })
}

export const userme = () => (dispatch) => {
    const {token} = store.getState().auth;
    return AuthService.usermeTK({token})
    .then((data) => {
        dispatch({
            type: types.authUserMe,
            payload: { currentUser: data },
        });
        return Promise.resolve();
    })
};

export const logout = () => (dispatch) => {
    return dispatch({
        type: types.authStartLogout,
        payload: {}
    });
};

export const startLogout = () => ({
    type: types.hideDrawer
})

const login = (user) => ({
    type: types.authLogin,
    payload: user
})
