import { useEffect, useMemo, useState } from 'react';
import { Box, Card, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonBasic, InputEditQuill, SelectCanalesSoftland, SelectGrupos, SelectIvaSoftland, SelectLocalidadesSoftland, SelectLugaresCalendar, SelectTipoDocSoftland } from '../../../components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import InfContactoService from '../../../services/infContacto.service';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
import usePermisos from '../../../hooks/usePermisos';
import CallIcon from '@mui/icons-material/Call';
import GobiernoService from '../../../services/gobierno.service';
import StoreIcon from '@mui/icons-material/Store';
import RouterIcon from '@mui/icons-material/Router';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const FormPaso1Screen = () => {
  const permisos = usePermisos();
  const [isLoading, setIsLoading] = useState(false);
  const [nroIdContacto, setNroIdContacto] = useState(null);
  const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false})

  const navigate = useNavigate()
  const [formAll, setFormAll] = useState({
    nombreCompleto_c: {
      texto: null,
      textoErr: 'La razon socual es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    canalcomu_c: {
        texto: null,
        textoErr: 'El canal es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    codCanal_tk: {
      texto: null,
      textoErr: 'El canal es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompletoContacto1_t: {
        texto: null,
        textoErr: 'El nombre es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    emailContacto1_t: {
        texto: null,
        textoErr: 'El correo es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    celularContacto1_t: {
        texto: null,
        textoErr: 'El celular es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    nombre_t: {
        texto: null,
        textoErr: 'El nombre es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    grupoId: {
        texto: null,
        textoErr: 'El grupo es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    documento_c: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    iva_c: {
      texto: null,
      textoErr: 'El iva es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    celular_c: {
      texto: null,
      textoErr: 'El celular es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    email_c: {
      texto: null,
      textoErr: 'El correo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    cpostal_c: {
      texto: null,
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    barrio_t: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    calle_t: {
      texto: null,
      textoErr: 'La calle es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    latitud_c: {
      texto: null,
      textoErr: 'La latitud es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    observacionesTarea: {
      texto: '<p><strong>Solicitud de prefactibilidad.</strong></p>',
      textoErr: 'La observaciones son requeridas',
      estadoErr: false,
      tipo: 'string'
    },
  });
  const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const onChangeForm = (value,input) =>{
    let formOld = formAll;
    if(!formOld[input]){
      formOld[input] = {
        texto: value,
        textoErr: `El ${input} es requerido`,
        estadoErr: false,
        tipo: 'string'
      }
    }else{
      formOld[input].texto = value
    }
    if((!value || value === '') && input !== 'telefono_c'){
      formOld[input].estadoErr = true
    }else{
      if(input === 'email_c' || input === 'emailContacto1_t'){
        if(validateEmail(value)){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else{
        formOld[input].estadoErr = false
      }
    }
    setFormAll({...formOld})
  }
  const onChangeFormSetError = (input,clear) =>{
    let formOld = formAll;
    formOld[input].estadoErr = !clear ? true : false
    setFormAll({...formOld})
  }

  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
      marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border:1,
        height: 45,
        color: '#000'
      },
      '&:hover':{
        border: 1,
        borderRadius: 2,
        borderColor: 'gray',
        height: 40
      },
      '&.Mui-focused': {
        '&:hover':{
          border:0,
          height: 40
        }
      },
      color: 'gray',
      height: 40
    }
  }), [])

  const onSubmitContacto = async() => {
    const form = {};
    let isOk = false;
    setIsLoading(true);
    const inputRequired = [
      'nombreCompleto_c',
      'canalcomu_c',
      'codCanal_tk',
      'nombreCompletoContacto1_t',
      'emailContacto1_t',
      'celularContacto1_t',
      'nombre_t',
      'grupoId',
      'observacionesTarea'
    ];
    const propertyNames = Object.keys(formAll);
    await Promise.resolve(
      propertyNames.forEach((name)=>{
      if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
        form[name] = formAll[name].texto
      }else{
        if(inputRequired.includes(name)){
          isOk = true;
          onChangeFormSetError(name)
        }
      }
    }))
    if(!isOk){
      form.nombre_t = `${formAll.nombreCompleto_c.texto?.toUpperCase() || ''} - ${ formAll.nombre_t.texto?.toUpperCase() || ''}`
      GobiernoService.registerContacto(form).then((response)=>{
        setIsLoading(false)
        if(response?.id_c){
          navigate(`/contactos/${response?.id_c}/nubicom`)
        }else{
          navigate('/contactos/gobierno')
        }
      })
      .finally(()=>setIsLoading(false));
    }else{
      setIsLoading(false);
    }
  }


  useEffect(() => {
    if(nroIdContacto?.cuentaId || nroIdContacto?.contactoId ){
        InfContactoService.getAll(nroIdContacto).then((ress)=>{
            if(ress?.fnacimiento_icon){
              onChangeForm(ress.fnacimiento_icon,'fnacimiento_icon')
            }else{
              onChangeForm(null,'fnacimiento_icon')
            }
        })
    }
  }, [nroIdContacto])

  const onSubmitCoordenadas = () =>{
    setIsLoading(true)
    const quitarEspacios = coordenadas.texto.replace(/ /g, "")
    const separador = quitarEspacios.split(',')
    if(separador.length === 2){
        const validCoord = `${separador[0]}, ${separador[1]}`
        const valid = AuxiliarFunction.isValidCoordinates(validCoord)
        if(valid){
          setIsLoading(false)
          onChangeForm(separador[0],'latitud_c')
          onChangeForm(separador[1],'longitud_c')
          setCoordenadas({texto: coordenadas.texto, estadoErr: false})
        }else{
            setIsLoading(false)
            setCoordenadas({texto: coordenadas.texto, estadoErr: true})
        }
    }else{
        setIsLoading(false)
        setCoordenadas({texto: coordenadas.texto, estadoErr: true})
    }
  }
  useEffect(() => {
    if(coordenadas?.texto?.length > 12){
      onSubmitCoordenadas()
    }else{
      onChangeForm(null,'latitud_c')
      onChangeForm(null,'longitud_c')
      setCoordenadas({texto: null, estadoErr: true})
    }
  }, [coordenadas])
  return (
    <Box sx={{overflow: 'overlay',height: '85vh'}} className='side'>
      <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center'}} variant='h3'>NUEVO CONTACTO GOBIERNO/EVENTUAL</Typography>
      <Grid container mt={2}>
        <Grid xs={12} md={12} lg={5.9} mb={1}>
          <Card sx={{background: '#fff !important',borderRadius: 3,boxShadow: 2}}>
            <Box display='flex' justifyContent='space-between' p={1}>
              <Box display='flex' pl={2} pt={1}>
                  <StoreIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Datos de la Compañia</Typography>
              </Box>
            </Box>
            <Grid container p={2} spacing={1}>
              <Grid item xs={12} md={12}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Razón Social</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formAll.nombreCompleto_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'nombreCompleto_c')}
                  InputLabelProps={{shrink: formAll.nombreCompleto_c.texto}}
                  error={formAll.nombreCompleto_c.estadoErr}
                  helperText={formAll.nombreCompleto_c.estadoErr && formAll.nombreCompleto_c.textoErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>CUIT</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  type='number'
                  value={formAll.documento_c.texto}
                  onChange={(e)=>onChangeForm(Number(e.target.value),'documento_c')}
                  InputLabelProps={{shrink: formAll.documento_c.texto}}
                  inputProps={{ min: 1000000 ,inputProps: { min: 1000000 } }}
                  error={formAll.documento_c.estadoErr}
                  helperText={formAll.documento_c.estadoErr && formAll.documento_c.textoErr}
                  FormHelperTextProps={{ className: '-mt-1' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>IVA</Box>
                  </Typography>
                </Box>
                <SelectIvaSoftland
                  filter
                  isInvalid={formAll.iva_c.estadoErr}
                  value={formAll.iva_c.texto}
                  onChange={(e)=>onChangeForm(e.value,'iva_c')}
                  tipoDoc='80'
                />
                {formAll.iva_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.iva_c.textoErr}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Canal</Box>
                  </Typography>
                </Box>
                <SelectCanalesSoftland
                  isInvalid={formAll.canalcomu_c.estadoErr}
                  value={formAll.canalcomu_c.texto}
                  onChange={(e)=>{
                    onChangeForm(e.value,'canalcomu_c')
                    onChangeForm(e.value,'codCanal_tk')
                  }}
                />
                {formAll.canalcomu_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Celular</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  type='number'
                  value={formAll.celular_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'celular_c')}
                  InputLabelProps={{shrink: formAll.celular_c.texto}}
                  error={formAll.celular_c.estadoErr}
                  helperText={formAll.celular_c.estadoErr && formAll.celular_c.textoErr}
                  InputProps={{ inputProps: { min: 100000000 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formAll.email_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'email_c')}
                  InputLabelProps={{shrink: formAll.email_c.texto}}
                  error={formAll.email_c.estadoErr}
                  helperText={formAll.email_c.estadoErr && formAll.email_c.textoErr}
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <Box display='flex' alignItems='center'>
                    <CallIcon sx={{color: '#000',fontSize: 20}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 0.5}} variant='h5'>Datos del Contacto</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formAll.nombreCompletoContacto1_t.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'nombreCompletoContacto1_t')}
                  InputLabelProps={{shrink: formAll.nombreCompletoContacto1_t.texto}}
                  error={formAll.nombreCompletoContacto1_t.estadoErr}
                  helperText={formAll.nombreCompletoContacto1_t.estadoErr && formAll.nombreCompletoContacto1_t.textoErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Celular</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  type='number'
                  value={formAll.celularContacto1_t.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'celularContacto1_t')}
                  InputLabelProps={{shrink: formAll.celularContacto1_t.texto}}
                  error={formAll.celularContacto1_t.estadoErr}
                  helperText={formAll.celularContacto1_t.estadoErr && formAll.celularContacto1_t.textoErr}
                  InputProps={{ inputProps: { min: 100000000 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formAll.emailContacto1_t.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'emailContacto1_t')}
                  InputLabelProps={{shrink: formAll.emailContacto1_t.texto}}
                  error={formAll.emailContacto1_t.estadoErr}
                  helperText={formAll.emailContacto1_t.estadoErr && formAll.emailContacto1_t.textoErr}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} md={12} lg={5.9} ml={0.5} mr={0.5} mb={1}>
          <Card sx={{background: '#fff !important', borderRadius: 3,boxShadow: 2}}>
            <Box p={2}>
              <Box display='flex' pt={1}>
                  <LocationOnIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Dirección de instalación</Typography>
              </Box>
              <Grid container mb={1} mt={1} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad</Box>
                        </Typography>
                    </Box>
                    <SelectLocalidadesSoftland
                      value={formAll.cpostal_c.texto}
                      isInvalid={formAll.cpostal_c.estadoErr}
                      onChange={(e)=>{
                        onChangeForm(e.value,'cpostal_c')
                        onChangeForm(e.provinciaId,'provincia_c')
                      }}
                    />
                    {formAll.cpostal_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cpostal_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      InputLabelProps={{shrink: true}}
                      value={coordenadas?.texto}
                      onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={()=>onSubmitCoordenadas()}>
                            <GpsFixedIcon sx={{color: '#000'}} size={35} />
                          </IconButton>
                        ),
                      }}
                    />
                    {coordenadas?.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5.9}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                      </Typography>
                    </Box>
                    <Typography sx={{color: '#000'}}> </Typography>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.barrio_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                      InputLabelProps={{shrink: formAll.barrio_t.texto}}
                      error={formAll.barrio_t.estadoErr}
                      helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                    />
                  </Grid>
                  <Grid xs={12} md={5.9} ml={0.6} mt={1}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.calle_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                      InputLabelProps={{shrink: formAll.calle_t.texto}}
                      error={formAll.calle_t.estadoErr}
                      helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                    />
                  </Grid>
              </Grid>
            </Box>
          </Card>
          <Card sx={{background: '#fff !important', mt: 2,borderRadius: 3,boxShadow: 2}}>
            <Box p={2}>
                <Box display='flex' pt={1}>
                    <RouterIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 16}}>Pre-Servicio y Ticket de prefactibilidad</Typography>
                </Box>
                <Grid container mb={1} mt={1} spacing={1}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>
                          {`Nombre: ${ formAll.nombreCompleto_c.texto || ''} - ${ formAll.nombre_t.texto || ''}`}
                        </Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.nombre_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
                      InputLabelProps={{shrink: formAll.nombre_t.texto}}
                      error={formAll.nombre_t.estadoErr}
                      helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Seleccione el destinatario</Box>
                      </Typography>
                    </Box>
                    <SelectGrupos
                      empresaId={1}
                      isInvalid={formAll.grupoId.estadoErr}
                      value={formAll.grupoId.texto}
                      onChange={(e)=>onChangeForm(e.value,'grupoId')}
                    />
                    {formAll.grupoId.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.grupoId.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Typography sx={{color: '#000'}}>Descripción: Ticket de prefactibilidad</Typography>
                    <InputEditQuill
                        id="Comentario"
                        template={formAll.observacionesTarea.texto}
                        etiqueta
                        onChangeText={(html)=>{
                            onChangeForm(html,'observacionesTarea')
                        }}
                    />
                    {formAll.observacionesTarea.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesTarea.textoErr}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          color='prevStep'
          onClick={()=>navigate(-2)}
          sx={{border:1, borderColor: '#000'}}
        />
          <ButtonBasic
            textButton='Crear Contacto'
            iconStart={<PersonAddIcon />}
            isLoading={isLoading}
            onClick={()=>onSubmitContacto()}
            variant='wk'
            color='nextStep'
            sx={{border:1, borderColor: '#fff',marginLeft: 1}}
          />
      </Box>
    </Box>
  )
}

export default FormPaso1Screen;