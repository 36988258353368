import { Box, Card, Grid, Typography } from '@mui/material'

const CardTabInfoTicket = ({texto,cantidad,icon,backgroundIcon}) => {
    return (
        <Card sx={{background: '#fff !important',borderRadius:5,boxShadow: 4,p:1}}>
            <Grid container mt={1} mb={1}>
                <Grid item xs={3} display='flex' justifyContent='center' alignItems='center'>
                    <Card sx={{height: 50, width:50,background: backgroundIcon ,display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
                        {icon}
                    </Card>
                </Grid>
                <Grid item xs={6} display='flex' justifyContent='start' alignItems='center'>
                    <Typography sx={{color: '#000'}} variant='h4'>{texto}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h2'>{cantidad}</Typography>
                        <Typography sx={{color: '#000',marginTop: 3,marginLeft: 1}} variant='h5'>Total</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardTabInfoTicket