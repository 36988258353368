import MUIDataTable from "mui-datatables";
import { useState } from "react";


const BasicTable = ({dataAll}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = ["Nombre", "Total"];
    const data = dataAll?.map((e)=>[e.label,e.value]);
    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
        print: false,
        download: false,
        selectableRows:'none',
        toolbar: null,
        customToolbar: null,
        display: false,
        viewColumns: false,
        search: false,
        filter: false,
        rowsPerPageOptions: [5],
        rowsPerPage: 5,
        onRowClick: (rowData, rowMeta) => {
            const selectedIndex = selectedRows.indexOf(rowData[0]);
            if (selectedIndex === -1) {
                setSelectedRows([...selectedRows, rowData[0]]);
            } else {
                const newSelectedRows = [...selectedRows];
                newSelectedRows.splice(selectedIndex, 1);
                setSelectedRows(newSelectedRows);
            }
        },
        textLabels: {
            body: {
            noMatch: "No se encontro ninguna coincidencia",
            toolTip: "Ordenar",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
            },
            pagination: {
            next: "Pagina Siguiente",
            previous: "Pagina Anterior",
            rowsPerPage: "Filas por Hoja:",
            displayRows: "de",
            },
            toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Mostrar/Ocultar columnas",
            filterTable: "Filtrar",
            },
            filter: {
            all: "Todos",
            title: `Filtro : `,
            reset: "Restablecer",
            },
            viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
            text: "fila(s) seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar filas seleccionadas",
            },
        },
    };
    return(
        <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        />
    )
}
export default BasicTable
