import * as yup from 'yup';

function armadoCategoria(categoria) {
    let tipoContrato = '';
    let listaPrecio = '';
    switch (categoria) {
        case 'MAS':
        case 'MASIN':
        case 'HO01      ':
        case 'HO    ':
            tipoContrato= 'HOGAR';
            listaPrecio = 'HO01      ';
            break;
        case 'COR':
        case 'COR1      ':
        case 'CORP  ':
            tipoContrato= 'NCORP';
            listaPrecio = 'COR1      ';
            break;
        case 'GOB':
        case 'GOB01      ':
        case 'GO    ':
            tipoContrato= 'NGOBI';
            listaPrecio = 'GOB1      ';
            break;
        case 'PYM':
        case 'PYMIN':
        case 'PY01      ':
        case 'PY    ':
            tipoContrato= 'NPYME';
            listaPrecio = 'PY01      ';
            break;
        default:
            tipoContrato= 'HOGAR';
            listaPrecio = 'HO01      ';
            break;
    }
    return {
        tipoContrato,
        listaPrecio
    }
}

function tipoServicioSoft(tipoServicio) {

    let servicioSoft = '';

    switch (tipoServicio) {
        case 'HO    ':
            servicioSoft= 'NUBIHOGAR';
            break;
        case 'SAI   ':
            servicioSoft= 'SERVICOS ASOCIADOS A  LA INSTALACION';
            break;
        case 'GO    ':
            servicioSoft= 'NUBIGOB';
            break;
        case 'PY    ':
            servicioSoft= 'NUBI PyMES';
            break;
        case 'CORP  ':
            servicioSoft= 'NUBICORP';
            break;
        default:
            servicioSoft= 'NUBIHOGAR';
            break;
    }
    return servicioSoft
}

async function verificarContacto(contacto) {
    const schema = yup.object().shape({
        nombreCompleto_c: yup.string().required().min(5).max(250),
        direccion_c: yup.string().required().min(3),
        celular_c: yup.string().required().min(8).max(35),
        email_c: yup.string().email().required(),
        tipoDoc_c: yup.string().required().min(2),
        documento_c: yup.string().required().min(7).max(15),
        pais_c: yup.string().required().min(2),
        provincia_c: yup.string().required().min(2),
        cpostal_c: yup.string().required().min(2),
        zona_c: yup.string().required().min(2),
        vendedor_c: yup.string().required().min(2),
        cobrador_c: yup.string().required().min(2),
        condicionPago_c: yup.string().required().min(2),
        listPrecio_c: yup.string().required().min(2),
        barrio_c: yup.string().required(),
        iva_c: yup.string().required()
    });
    try {
        await schema.validate(contacto, { abortEarly: false });
        return { estado: 'ok', error: [] };
    } catch (validationError) {
        const errors = validationError?.inner?.map(error => error?.path?.replace(/_c$/, ''));
        return { estado: 'error', error: errors };
    }
    /* const verifi = await schema.isValid(contacto)
    return verifi; */
}

async function verificarContrato(contrato) {
    const schema = yup.object().shape({
        fechaAlta: yup.date().required(),
        empresaSoft_t: yup.string().required().min(6),
        tipoConSoft_t: yup.string().required().min(4),
        contratistaSoft_t: yup.string().required().min(2),
        listaPrecioSoft_t: yup.string().required().min(5),
        direccion_t: yup.string().required()
    });
    try {
        await schema.validate(contrato, { abortEarly: false });
        return { estado: 'ok', error: [] };
    } catch (validationError) {
        const errors = validationError?.inner?.map(error => error?.path?.replace(/_t$/, ''));
        return { estado: 'error', error: errors };
    }
    /* const verifi = await schema.isValid(contrato)
    return verifi; */
}

async function verificarItemContrato(itemContrato) {
    const schema = yup.object().shape({
        tipoServicio_ds: yup.string().required().min(4),
        servicioIdSoftland_ds: yup.string().required().min(4),
        importeServicio_ds: yup.string().required().min(8),
        cantidad_ds: yup.string().required().min(6),
        vigencia_ds: yup.string().required().min(1),
        vigencia_desde: yup.date().required(),
        vigencia_hasta: yup.date().required()
    });
    try {
        await schema.validate(itemContrato, { abortEarly: false });
        return { estado: 'ok', error: [] };
    } catch (validationError) {
        const errors = validationError?.inner?.map(error => error?.path?.replace(/_ds$/, ''));
        return { estado: 'error', error: errors };
    }
}

const validarYFormatearImporte = (objeto) => {
    if(typeof objeto.importe !== 'number') return;
    objeto.importe = objeto.importe.toFixed(6);
}

const AuxSoftland = {
    armadoCategoria,
    tipoServicioSoft,
    verificarContacto,
    verificarContrato,
    verificarItemContrato,
    validarYFormatearImporte
}

export default AuxSoftland;