import { useState, useEffect, useMemo } from "react"
import { Box, Card, Grid, TextField, Typography, IconButton } from "@mui/material"
import { MapBase, MarkerFibraNaps, MarkerFibraCliente } from "../../components"
import KmzService from "../../services/kmz.service"
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxiliarFunction from "../../function/AuxiliarFunction"

const MapaFibraOpticaAllScreen = () => {
    const [items, setItems] = useState([]);
    const [coordenadas, setCoordenadas] = useState({texto:'',lat: null,lng: false})
    const [isLoadingPrefa, setIsLoadingPrefa] = useState(false);
    const [rangoRadio, setRangoRadio] = useState(80);
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState({
        lat: -24.789251,
        lng: -65.410393
    });
    const [mapLocation, setMapLocation] = useState({
        lat: -24.789251,
        lng: -65.410393
    });
    const [prefactibilidad, setPrefactibilidad] = useState(null);

    const actualizar = async() =>{
        await KmzService.obtenerNapsFO().then((resp)=>{
            setItems(resp);
        })
    }
    useEffect(() => {
        actualizar()
    }, [])

    const onPrefa = async(newCoord) =>{
        setCoordenadas({texto:`${newCoord.lat}, ${newCoord.lng}`,lat: newCoord.lat, lng:newCoord.lng})
        setIsLoadingPrefa(true)
        await KmzService.coberturaFO({lat: newCoord.lat, lng:newCoord.lng}).then((r)=>{
            setPrefactibilidad(r);
            if(r?.rango){
                setRangoRadio(r?.rango)
            }
            setIsLoadingPrefa(false)
        }).catch((err)=>{
            setIsLoadingPrefa(false)
        })
    }

    const onClickMap = async(newCoord,moverCamara) => {
        if(moverCamara){
            setMapLocation(newCoord)
        }
        await onPrefa(newCoord)
        setPrefactibilidadLocation(newCoord);
    }

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), []);

    const onSubmitCoordenadas = () =>{
        setIsLoadingPrefa(true)
        const quitarEspacios = coordenadas.texto.replace(/ /g, "")
        const separador = quitarEspacios.split(',')
        if(separador.length === 2){
            const validCoord = `${separador[0]}, ${separador[1]}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                setIsLoadingPrefa(false)
                onClickMap({lat:Number(separador[0]), lng:Number(separador[1])},true)
                setCoordenadas({texto: coordenadas.texto, estadoErr: false,lat: separador[0], lng:separador[1]})
            }else{
                setIsLoadingPrefa(false)
                setCoordenadas({texto: coordenadas.texto, estadoErr: true,lat: coordenadas.lat, lng:coordenadas.lng})
            }
        }else{
            setIsLoadingPrefa(false)
            setCoordenadas({texto: coordenadas.texto, estadoErr: true,lat: coordenadas.lat, lng:coordenadas.lng})
        }
    }
    useEffect(() => {
        document.title = `Cobertura FO`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center' spacing={1}>
                <Grid item xs={12} md={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Mapa fibra optica</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{background: '#fff !important',p:2,borderRadius: 5}}>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            sx={styleInputHeight}
                            InputLabelProps={{shrink: true}}
                            value={coordenadas?.texto}
                            onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false,lat: coordenadas.lat, lng:coordenadas.lng})}
                            InputProps={{
                                endAdornment: (
                                <IconButton onClick={()=>onSubmitCoordenadas()}>
                                    <GpsFixedIcon sx={{color: '#000'}} size={35} />
                                </IconButton>
                                ),
                            }}
                        />
                        {coordenadas?.estadoErr && (
                            <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                        )}
                    </Card>
                </Grid>
                {/* <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenModal()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid> */}
                <Grid item xs={12} md={3} mt={2}>
                    <Card sx={{background: '#fff !important',minHeight: 250,borderRadius:2,boxShadow: 3,p:1}}>
                        {prefactibilidad?.cobertura && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{color: '#000',textAlign: 'center',fontWeight: 'bold'}} variant="h4">{`${prefactibilidad?.cobertura}`}</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} mb={-1}>
                                    <Typography sx={{color: '#000',textAlign: 'center',fontWeight: 'bold'}} variant="h6">{`Radio: ${prefactibilidad?.rango}`}</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{color: '#000',textAlign: 'center'}} variant="h6">{`Coordenadas: ${coordenadas.lat},${coordenadas.lng}`}</Typography>
                                </Grid>
                                {Array.isArray(prefactibilidad?.naps) && prefactibilidad?.naps?.length > 0 && (
                                    prefactibilidad?.naps.map((it)=>(
                                        <Grid item xs={4} md={6}>
                                            <Card sx={{background: '#fff !important', boxShadow: 3, borderRadius: 2,p:1}}>
                                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                                    <Typography sx={{color: '#000'}} component='div'>Nombre: <Box fontWeight='bold' display='inline'>{it?.nombre_fnap}</Box></Typography>
                                                </Box>
                                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                                    <Typography sx={{color: '#000'}} component='div'>Distancia: <Box fontWeight='bold' display='inline'>{it?.distanciaReal|| it?.distanciaMts}</Box> Mts</Typography>
                                                </Box>
                                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                                    <Typography sx={{color: '#000'}} component='div'>Zona: <Box fontWeight='bold' display='inline'>{it?.sector|| '-'}</Box></Typography>
                                                </Box>
                                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                                    <Typography sx={{color: '#000'}} component='div'>Puertos Usados: <Box fontWeight='bold' display='inline'>{`${it?.usados}/${it?.puertos}`}</Box></Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} md={9} mt={2}>
                    <Card sx={{background: '#fff !important', boxShadow: 3, borderRadius:2,p:1}}>
                        <MapBase
                            mapContainerStyle={{
                                width: '100%',
                                height: 510
                            }}
                            onClick={(e)=>onClickMap(e,true)}
                            isSearchable={false}
                            options={{
                                maxZoom: 23,
                                minZoom: 8,
                            }}
                            zoom={16}
                            coordinates={mapLocation}
                        >
                            <MarkerFibraCliente
                                item={prefactibilidadLocation}
                                key='onCLickMapFO'
                                rangoBd={rangoRadio}
                            />
                            {(Array.isArray(items) && items?.length > 0) && (
                                items.map((it)=>(
                                    <MarkerFibraNaps
                                        coordenadas={{lat: Number(it?.latitud),lng: Number(it?.longitud)}}
                                        key={it?.id_fnap}
                                        item={it}
                                    />
                                ))
                            )}
                        </MapBase>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MapaFibraOpticaAllScreen