import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { TextInfoTicket } from '../../components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import TabCuentaTicket from './tabs/TabCuentaTicket';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import ContactosAction from '../../store/actions/contactos';
import ContactosService from '../../services/contactos.service';
import InfContactoService from '../../services/infContacto.service';
import TabCuentaConsultas from './tabs/TabCuentaConsultas';
import ConsultasService from '../../services/consultas.service';
import LogService from '../../services/log.service';
import TabCuentaHistorico from './tabs/TabCuentaHistorico';
import FileAdjContactoService from '../../services/fileAdjContacto.service';
import TabCuentaDocumentacion from './tabs/TabCuentaDocumentacion';
import TabCuentaPreServicio from './tabs/TabCuentaPreServicio';
import TabCuentaServicio from './tabs/TabCuentaServicio';
import TabCuentaEditar from './tabs/TabCuentaEditar';
import TabCuentaTareas from './tabs/TabCuentaTareas';
import CalendarioV2Service from '../../services/calendarioV2.service';
import RecordatorioService from '../../services/recordatorio.service';
import TabCuentaRecodatorios from './tabs/TabCuentaRecodatorios';

function TabPanel({ children, value, index, ...other }) {
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          style={{maxHeight:'79vh', overflow: 'auto'}}
          className='side'
      >
      {value === index && (
          <Box sx={{ p: 3 }}>{children}</Box>
      )}
      </div>
  );
}
function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CuentaFindByIdScreen = ({ iniciarContacto }) => {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contacto, setContacto] = useState();
  const [contactoImg, setContactoImg] = useState(null);
  const [consultas, setConsultas] = useState([])

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [value, setValue] = useState(0);
  const [logs, setLogs] = useState([]);
  const [tareas, setTareas] = useState([]);
  const [filesAdj, setFilesAdj] = useState([])
  const [recordatorios, setRecordatorios] = useState([]);

  const traerInfoContServicio = async() => {
    await InfContactoService.getAll({cuentaId: id}).then((respp)=>{
      setIsLoading(false)
      setContactoImg(respp)
    }).catch(()=>{
      setIsLoading(false)
    })
    setIsLoading(false)
  }
  const actualizar = async() =>{
    setIsLoading(true)
    await ContactosService.getByIdCuenta(id,{type: 'multi'}).then((response)=>{
      setContacto(response)
      iniciarContacto(response)
      traerInfoContServicio()
      setIsLoading(false)
    })
    .catch((e)=>{
      if(e?.response?.data?.msg){
        navigate('/')
      }
    })
    .finally(()=>{
        setIsLoading(false)
    })
  }
  const actualizarHistorico = async() =>{
    await LogService.getAllContacto({cuentaId: id}).then((resp)=>{
      setLogs(resp)
    })
  }
  const actualizarConsultas = async() =>{
    await ConsultasService.getAll({cuenta:id,webV2: true}).then((resp)=>{
        setConsultas(resp)
    })
  }
  const actualizarFileAdjContacto = async() => {
    await FileAdjContactoService.getAll({cuentaId: id}).then((resp)=>{
      setFilesAdj(resp)
    })
  }
  const actualizarTareas = async() =>{
    await CalendarioV2Service.tareasCalendar({cuentaId: id}).then((resp)=>{
      setTareas(resp)
    })
  }
  const actualizarRecordatorios = async() =>{
    await RecordatorioService.getAll({cuentaId: id}).then((resp)=>{
        setRecordatorios(resp)
    })
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onGuardar = () =>{
    actualizar();
    actualizarHistorico();
    actualizarConsultas();
    actualizarFileAdjContacto();
    actualizarTareas();
    actualizarRecordatorios();
  }
  useEffect(() => {
    actualizar();
    actualizarHistorico();
    actualizarConsultas();
    actualizarFileAdjContacto();
    actualizarTareas();
    actualizarRecordatorios();
    document.title = `Cuenta #${id}`;
  }, [id])
  return (
    <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
      <Grid container p={3} mb={2} spacing={1} >
        <Grid item xs={12} md={3}>
          <Card sx={{background: '#fff !important',p:2,borderRadius:4,boxShadow: "0px -3px 0px 0px #0364ff"}}>
            <IconButton sx={{boxShadow: 3}} onClick={()=>navigate(-1)}>
              <ArrowBackIcon sx={{color: '#000'}} />
            </IconButton>
            <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
              <Box sx={{background: '#e6f0ff', borderRadius:50, width:50, height: 50, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <AccountCircleIcon sx={{color: '#0364ff',fontSize: 40}} />
              </Box>
              <Typography sx={{color: '#0364ff',fontWeight: 'bold',textAlign: 'center',mb: 2}} variant='h4'>{contacto?.nombre_completo}</Typography>
              <TextInfoTicket titulo='Cliente ID' descripcion={contacto?.cuenta || '-'} />
              <TextInfoTicket titulo='Total Ticket' descripcion={contacto?.ticketsGral?.length || contacto?.tickets?.length || '0'} />
              <TextInfoTicket titulo='Categoria' descripcion={contacto?.categoria?.descripcion || contacto?.segmento || '-'} />
            </Box>
              <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
              <Typography sx={{color: '#000',fontWeight: 'bold',mb:2}} variant='h5'>Datos personales</Typography>
              <TextInfoTicket titulo='DNI/CUIT' descripcion={contacto?.dni_cuit?.numero || contacto?.dni || '-'} />
              <TextInfoTicket titulo='Email' descripcion={contacto?.contacto?.email || contacto?.email || '-'} />
              <TextInfoTicket titulo='Telefono' descripcion={contacto?.contacto?.telefono || contacto?.telefono || '-'} />
              <TextInfoTicket titulo='Telefono2' descripcion={contacto?.contacto?.telefono2 || contacto?.telefono2 || '-'} />
              <TextInfoTicket titulo='Domicilio de facturacion' descripcion={contacto?.ubicacion?.direccion || contacto?.direcFac || '-'} />
              {contacto?.ubicacion?.latitud && contacto?.ubicacion?.longitud && (
                  <TextInfoTicket titulo='Coordenadas' descripcion={`${contacto?.ubicacion?.latitud},${contacto?.ubicacion?.longitud}`} />
              )}
              {contacto?.latitud && contacto?.longitud && (
                  <TextInfoTicket titulo='Coordenadas' descripcion={`${contacto?.latitud},${contacto?.longitud}`} />
              )}
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          <Card sx={{background: '#fff !important',p:2,borderRadius:4}}>
            <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    sx={{
                        '& .MuiTabs-indicator':{
                            background: '#0364ff'
                        },
                        '& .MuiButtonBase-root':{
                          color: '#6b788e',
                          fontWeight: 'bold'
                        },
                        '& .MuiButtonBase-root.Mui-selected':{
                            color: '#0364ff'
                        }
                    }}
                >
                    <Tab label="Tickets" {...a11yProps(0)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Consultas" {...a11yProps(1)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Pre-Servicios" {...a11yProps(2)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Servicios" {...a11yProps(3)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Datos" {...a11yProps(4)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Historico" {...a11yProps(5)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Documentación" {...a11yProps(6)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Recordatorios" {...a11yProps(7)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Tareas" {...a11yProps(8)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TabCuentaTicket tickets={contacto?.ticketsGral || contacto?.tickets || []} cuenta={id} nombreCompleto={contacto?.nombre_completo} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TabCuentaConsultas
                  consultas={consultas}
                  actualizar={actualizarConsultas}
                  actualizarRecordatorios={actualizarRecordatorios}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabCuentaPreServicio preServicios={contacto?.precontrato || contacto?.preServicios || []} cuenta={id} datosOK={true} contacto={contacto} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TabCuentaServicio
                tratos={contacto?.contratos || contacto?.servicios || []}
                ticketsAll={contacto?.tickets || []}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TabCuentaEditar contacto={contacto} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <TabCuentaHistorico
                historico={logs}
                infoCliente={{ cuentaId: id,type: 'nubicom'}}
                onGuardar={actualizarHistorico}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <TabCuentaDocumentacion infoIni={contactoImg} filesAdj={filesAdj} token={token} onGuardar={onGuardar} consulta={{cuenta: id}} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <TabCuentaRecodatorios
                recordatoriosAll={recordatorios}
                cuenta={contacto?.cuenta}
                nombreCompleto={contacto?.cliente}
                onGuardar={actualizarRecordatorios}
              />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <TabCuentaTareas tareas={tareas} />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CuentaFindByIdScreen)