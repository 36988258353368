import axios from 'axios';
const base = 'saisoft';
function getAll(query = null) {
    return axios.get(base, { params: query });
}

function register(data) {
    return axios.post(base, data);
}

function update(data) {
    return axios.put([base, data.id_ss].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const SaiSoftlandService = {
    getAll,
    register,
    update,
    destroy
};
export default SaiSoftlandService;
