import { Avatar, Box, Divider, Typography } from "@mui/material";
import FechaFunction from "../../function/FechaFunction";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputReadQuill from "../richText/InputReadQuill";

const LogsCard = ({
    item,
    keyst
}) => {
    let texto;
    if(item){
        switch (item?.accion_lt || item?.accion_lc) {
            case 'ALTA':
                texto=`CREO el ticket.`;
                break;
            case 'CIERRE':
                texto=`realizó el CIERRE del ticket.`;
                break;
            case 'REABRIO':
                texto=`ABRIO nuevamente el ticket.`;
                break;
            case 'ASIGNOP':
                texto=`ASIGNO el ticket a ${item?.asignoNombre_p || item?.creadoNombre_p}.`;
                break;
            case 'ASIGNOG':
                texto=`ASIGNO el ticket al grupo ${item?.asignoNombre_g || item?.creadoNombre_g}.`;
                break;
            case 'RESOLUCION':
                texto=`realizó la RESOLUCION del ticket.`;
                break;
            case 'MODIFICO':
                texto=`realizó las siguientes modificaciones en el ticket.`;
                break;
            case 'REAPERTURA':
                texto=`realizó la REAPERTURA del ticket.`;
                break;
            case 'MODIFICOLC':
                texto=`realizó las siguientes modificaciones en el contacto.`;
            case 'MODIFICODIRENTLC':
                texto=`realizó las siguientes modificaciones en el servicio.`;
                break;
            case 'ALTACONSULTALC':
                texto=`realizó el alta del contacto por consulta externa.`;
                break;
            case 'ALTALC':
                texto=`realizó el alta del contacto.`;
                break;
            case 'FNACIMIENTOLC':
                texto=`cargo la fecha de nacimiento.`;
                break;
            case 'IMGLC':
                texto=`cargo las imagenes del documento.`;
                break;
            case 'CONSTAFIPLC':
                texto=`cargo la constancia de afip.`;
                break;
            case 'PLANTILLAINI':
                texto=`uso una plantilla de ticket.`;
                break;
            default:
                texto = item?.accion_lt || item?.accion_lc || ''
                break;
        }
        if(item?.servicioId_lc){
            texto = `Servicio #${item?.servicioId_lc} - ${texto}`
        }else if(item?.preServicioId_lc){
            texto = `Pre-Servicio #${item?.preServicioId_lc} - ${texto}`
        }
    }
    return (
        <Box className="log-card mt-3" key={keyst} mb={2}>
            <Box display='flex' alignItems='center'>
                <Avatar src={item?.creadoFoto_p} sx={{width:30, height:30,background: '#dfe2e6'}} />
                <Typography sx={{color: '#8993a4',ml:0.5,fontWeight: 'bold',fontSize:13}} component='div'>{item?.creado?.nombre_p || item?.creadoNombre_p}
                    <Box display='inline' sx={{color: '#8993a4',fontSize:11,fontWeight: 'normal'}}>- Autor</Box>
                    <Box display='inline' sx={{color: '#15294b',fontSize:13, ml:0.3,fontWeight: 'normal'}}>{texto}</Box>
                </Typography>
            </Box>
            { item?.logs?.length > 0 && (
                <Box mt={2} mb={1}>
                    <Table aria-label="simple table" sx={{border: 2,borderColor: 'gray',borderCollapse: 'unset',borderRadius:2}}>
                        <TableHead>
                            <TableRow className='table-header-row'>
                                <TableCell align="center" sx={{background: '#e6f0ff !important',color:'#15294b !important',fontWeight: 'bold',fontSize:13,borderTopLeftRadius:7,p:0,width: 15}}>Campo</TableCell>
                                <TableCell align="center" sx={{background: '#e6f0ff !important',color:'#15294b !important',fontWeight: 'bold',fontSize:13,p:0,width: 150}}>
                                {(item?.accion_lt === 'PLANTILLAINI' || item?.accion_lc === 'PLANTILLAINI') ? 'Original' : 'Antes'}
                                </TableCell>
                                <TableCell align="center" sx={{background: '#e6f0ff !important',color:'#15294b !important',fontWeight: 'bold',fontSize:13,borderTopRightRadius:7,p:0,width: 150}}>Ahora</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { item?.logs?.map((lg)=>(
                                <>
                                    {lg?.titulo === 'Cuerpo' ? (
                                        <TableRow
                                            key={lg?.titulo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell align="center" sx={{p:0,color:'red'}}>{lg?.titulo}</TableCell>
                                            <TableCell align="center" sx={{p:0,color:'red'}}><InputReadQuill template={lg.old || '-'} /></TableCell>
                                            <TableCell align="center" sx={{p:0,color:'red'}}><InputReadQuill template={lg.nuevo || '-'} /></TableCell>
                                        </TableRow>
                                    ):(
                                        <TableRow
                                            key={lg?.titulo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell align="center" sx={{p:0,color:'red'}}>{lg?.titulo}</TableCell>
                                            <TableCell align="center" sx={{p:0,color:'red'}}>{lg.old}</TableCell>
                                            <TableCell align="center" sx={{p:0,color:'red'}}>{lg.nuevo}</TableCell>
                                        </TableRow>
                                    )}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
            <Box display='flex' justifyContent='end' mt={1}>
                <Typography sx={{color: '#15294b',fontSize:11}}>{`${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`}</Typography>
            </Box>
            <Divider sx={{background: 'gray',marginBottom:2,marginTop:2}} />
        </Box>
    )
}

export default LogsCard;
