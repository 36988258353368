import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import {toast} from 'react-toastify';
import ConsultasService from '../../services/consultas.service';

const ModalValidarTarjeta = ({show, hideModal}) => {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [codigoCard, setCodigoCard] = useState('');
    const [resultado, setResultado] = useState(null);

    const onSubmit = async() => {
        setIsLoading(true);
        await ConsultasService.validarTarjeta({codigo: codigoCard}).then((resp)=>{
            setResultado(resp)
            setIsLoading(false)
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        VALIDAR TARJETA
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Ingrese el codigo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={codigoCard}
                                onChange={(e)=>setCodigoCard(e.target.value)}
                            />
                        </Grid>
                        {resultado?.estado && (
                            <Grid item xs={12}>
                                <Card sx={{background: '#fff !important', boxShadow: 4, borderRadius: 2, padding: 1}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: 0.5, marginLeft: 1}}>
                                        <Typography sx={{color: '#000'}} component='div'>Estado: <Box fontWeight='bold' display='inline'>{resultado?.estado}</Box></Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: 0.5, marginLeft: 1}}>
                                        <Typography sx={{color: '#000'}} component='div'>Tipo: <Box fontWeight='bold' display='inline'>{resultado?.tipo}</Box></Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: 0.5, marginLeft: 1}}>
                                        <Typography sx={{color: '#000'}} component='div'>Codigo: <Box fontWeight='bold' display='inline'>{resultado?.codigo}</Box></Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!codigoCard || codigoCard?.length < 5}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalValidarTarjeta