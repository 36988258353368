import { Box, Grid, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"

const TextEstadoServicio = ({descripcion}) => {
    const [info, setInfo] = useState({
        color: '#74767f',
        background: '#e7e7e9',
        texto: 'En Proceso'
    })
    useEffect(() => {
        switch (descripcion) {
            case 'Servicio Habilitado':
                setInfo({
                    color: '#018155',
                    background: '#E6F8F2',
                    texto: 'Habilitado'
                })
                break;
            default:
                setInfo({
                    color: '#9d0b0b',
                    background: '#fce7e7',
                    texto: descripcion
                })
                break;
        }
    }, [descripcion])

    return (
        <Box sx={{border: 1.8, borderColor: info?.color, borderRadius:50,background: info?.background,pl:1,pr:1}}>
            <Typography
                sx={{fontWeight: 'bold',color: info?.color,height:23,fontSize:12,background: info?.background,display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                component='div'
            >
                <Box fontWeight='bold' display='inline' sx={{fontSize: 15,color: info?.color,mr:0.2}}>•</Box>{info?.texto}
            </Typography>
        </Box>
    )
}

export default TextEstadoServicio