import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,Collapse, useTheme, Badge } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { HomeOutlined } from '@mui/icons-material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { useSelector } from 'react-redux';
import { tokens } from '../../themes/themes';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DiscountIcon from '@mui/icons-material/Discount';
import MapIcon from '@mui/icons-material/Map';
import CableIcon from '@mui/icons-material/Cable';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Groups2Icon from '@mui/icons-material/Groups2';
import BallotIcon from '@mui/icons-material/Ballot';
import usePermisos from '../../hooks/usePermisos';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

const SidebarResponsive = ({
  openDrawer,
  onOpenDrawerChange,
  countNotifiTk
}) => {
  const {pathname} = useLocation();
  const user = useSelector((state) => state.auth.currentUser);
  const [openCorporativo, setOpenCorporativo] = useState(false);
  const handleClickCorporativo = () => setOpenCorporativo(!openCorporativo);
  const [openGobierno, setOpenGobierno] = useState(false);
  const handleClickGobierno = () => setOpenGobierno(!openGobierno);
  const [openPlanillas, setOpenPlanillas] = useState(false);
  const handleClickPlanillas = () => setOpenPlanillas(!openPlanillas);
  const [openFibraOptica, setOpenFibraOptica] = useState(false);
  const handleClickFibraOptica = () => setOpenFibraOptica(!openFibraOptica);
  const [openRadioEnlace, setOpenRadioEnlace] = useState(false);
  const handleClickRadioEnlace = () => setOpenRadioEnlace(!openRadioEnlace);
  const [openAdmTicket, setOpenAdmTicket] = useState(false);
  const handleClickAdmTicket = () => setOpenAdmTicket(!openAdmTicket);
  const [openAdministrador, setOpenAdministrador] = useState(false);
  const handleClickAdministrador = () => setOpenAdministrador(!openAdministrador);
  const [openAnalitica, setOpenAnalitica] = useState(false);
  const handleClickAnalitica = () => setOpenAnalitica(!openAnalitica);
  const [openAsesor, setOpenAsesor] = useState(false);
  const handleClickAsesor = () => setOpenAsesor(!openAsesor);
  const permisos = usePermisos();
  const navigate = useNavigate();
  const styleSelect = { background:'#0364ff',borderRadius:10 }
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const ItemList = ({info}) => (
    <ListItem
      disablePadding
      sx={[{
        width: '90%',
        marginTop: openDrawer && info.primary && -2,
        marginLeft:1,
        paddingLeft: openDrawer && info.collapse && 4,
        '&:hover':{
          borderRadius:20,
          background: '#02378c',
          color: '#fff'
        }
      },pathname === info.path ? styleSelect:{color: 'gray'}
      ]}
    >
      <ListItemButton
        onClick={()=>{
          onOpenDrawerChange()
          navigate(info.path)
        }}>
        <ListItemIcon sx={{marginLeft: 1}}>
          {info.icon &&(
            info.icon
          )}
        </ListItemIcon>
        <ListItemText primary={info.name} />
        {info.name === 'Tickets' && (
          <Badge
            className={`${!openDrawer && 'hidden' } textSide`}
            sx={{position: 'absolute', right: 30,
              '& span':{
                '&.MuiBadge-badge':{
                  background: '#e01010',
                  color:'white',
                  fontWeight: 'bold',
                  fontSize: 12
                }
              }
            }}
            badgeContent={countNotifiTk}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
  const CollapseItemsList = ({info}) => (
    <ListItem disablePadding sx={[{marginLeft:1}, pathname === info.path ? styleSelect:{color: 'gray'}]}>
        <ListItemButton onClick={()=>info.onClick()}>
          <ListItemIcon sx={{marginLeft: 1}}>
            {info.icon &&(
              info.icon
            )}
          </ListItemIcon>
          <ListItemText primary={info.name} />
          {info.onRef ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
  )
  return (
    <Drawer
      anchor='left'
      open={openDrawer}
      onClose={()=>onOpenDrawerChange()}
    >
      <Box
        sx={{
          width: '300px',
          background: colors.siderbar.background,
        }}
      >
        <Box className="pt-1 h-full w-full">
          <Box className="flex gap-x-4 items-center" sx={{marginTop: 2,display: 'flex',marginLeft: 2}}>
            <img
              src={theme.palette.mode === "dark" ? "/img/Zeta-white.png" : "/img/Zeta-black.png"}
              className={`cursor-pointer duration-500 ml-3 mt-1`}
              style={{height:35}}
            />
            <Typography
              className={`text-white origin-left font-medium text-xl duration-200 ${
                !openDrawer && "scale-0"
              }`}
              sx={{marginTop: 1,marginLeft: 1}}
            >
              -
            </Typography>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', marginTop: 3,marginLeft: 3}} className='ml-6 mt-6'>
              <Avatar src={user?.foto_p || '/img/default-profile'} sx={{width:50, height: 50}} />
              <Typography
                className={`text-white origin-left font-medium text-sm duration-200 ml-3 text-center`}
                sx={{marginLeft: 1}}
              >
                {user?.nombre_p || ''}
              </Typography>
          </Box>
          <Box p={1} sx={{height: '78vh',overflow: 'auto',overflowX:'hidden',marginTop: 2}} className='side'>
            {(user?.razonSocial_p && user?.razonSocial_p !== 'Asesor') ? (
              <List>
              <ItemList info={{path: '/', name: 'Dashboard', primary: true, icon: <HomeOutlined />}} key='dashboard' />
              <ItemList info={{path: '/tickets', name: 'Tickets', icon: <ConfirmationNumberIcon />}} key='ticket' />
              { permisos['preTicket.NubiGetAll'] && (
                <ItemList info={{path: '/pretickets', name: 'Pre-Tickets', icon: <ConfirmationNumberIcon />}} key='preticket' />
              )}
              <ItemList info={{path: '/contactos', name: 'Potenciales clientes', icon: <Groups2Icon />}} key='contactos' />
              <ItemList info={{path: '/tabla/consultas', name: 'Consultas', icon: <Groups2Icon />}} key='tablaconsultas' />
              <ItemList info={{path: '/pre/servicios', name: 'Pre-Servicios', icon: <PersonPinCircleIcon />}} key='tratos' />
              {permisos['contactos.corporativo.getAll'] && (
                <>
                  <CollapseItemsList info={{path: '/corporativos', name: 'Corporativos', onClick:handleClickCorporativo, onRef:openCorporativo, icon: <FolderIcon />  }} />
                  <Collapse in={openCorporativo} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ItemList info={{path: '/tabla/consultas/corporativos', name: 'Consultas', collapse: true, icon: <DiscountIcon />}} key='corportativosconsult' />
                      <ItemList info={{path: '/contactos/corporativos', name: 'Potenciales Clientes', collapse: true, icon: <Groups2Icon />}} key='corportativos' />
                    </List>
                  </Collapse>
                </>
              )}
              {permisos['contactos.gobierno.getAll'] && (
                <>
                  <CollapseItemsList info={{path: '/gobierno', name: 'Gobierno', onClick:handleClickGobierno, onRef:openGobierno, icon: <FolderIcon />  }} />
                  <Collapse in={openGobierno} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ItemList info={{path: '/tabla/consultas/gobierno', name: 'Consultas', collapse: true, icon: <DiscountIcon />}} key='gobiernoconsult' />
                      <ItemList info={{path: '/contactos/gobierno', name: 'Potenciales Clientes', collapse: true, icon: <Groups2Icon />}} key='gobierno' />
                    </List>
                  </Collapse>
                </>
              )}
              { (permisos['autorizar_bonificacion_nivel1'] || permisos['autorizar_bonificacion_nivel2'] || permisos['autorizar_bonificacion_nivel3']) && (
                <ItemList info={{path: '/pre/servicios/pendientes', name: 'Autorizaciones Pendientes', icon: <PersonPinCircleIcon />}} key='pretratos' />
              )}
              {(permisos['cierreTicket.NubiABM'] || permisos['saiSoftlandNubi.ABM'] || permisos['motivoticket.abmNubi'] || permisos['plantillaEmailNubi.ABM'] ) && (
                <>
                  <CollapseItemsList info={{path: '/plantillas', name: 'Plantillas', onClick:handleClickPlanillas, onRef:openPlanillas, icon: <FolderIcon />  }} />
                  <Collapse in={openPlanillas} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {permisos['motivoticket.abmNubi'] &&(
                        <ItemList info={{path: '/plantilla/motivos', name: 'Plantillas Ticket', collapse: true, icon: <DescriptionIcon />}} key='plantillaticket' />
                      )}
                      {permisos['cierreTicket.NubiABM'] &&(
                        <ItemList info={{path: '/plantilla/cierreticket', name: 'Cierre Ticket', collapse: true, icon: <AssignmentTurnedInIcon />}} key='cierreticket' />
                      )}
                      {permisos['saiSoftlandNubi.ABM'] &&(
                        <ItemList info={{path: '/plantilla/bonificaciones', name: 'Bonificaciones', collapse: true, icon: <DiscountIcon />}} key='bonificaciones' />
                      )}
                      {permisos['plantillaEmailNubi.ABM'] &&(
                        <ItemList info={{path: '/plantilla/emails', name: 'Plantilla Email', collapse: true, icon: <MarkAsUnreadIcon />}} key='emails' />
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              <ItemList info={{path: '/reports', name: 'Reportes', icon: <AnalyticsIcon />}} key='reports' />
              <ItemList info={{path: '/recordatorios', name: 'Recordatorios', icon: <AnalyticsIcon />}} key='recordatorios' />
              {(permisos['tipoTicket.nubiABM'] || permisos['hashtag.NubiABM'] || permisos['ticket.detProblema.ABM'] ) && (
                <>
                  <CollapseItemsList info={{path: '/administrar', name: 'Administrar Ticket', onClick:handleClickAdmTicket, onRef:openAdmTicket, icon: <DisplaySettingsIcon />  }} />
                  <Collapse in={openAdmTicket} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {permisos['tipoTicket.nubiABM'] &&(
                        <>
                          <ItemList info={{path: '/administrar/tipo-ticket', name: 'Tipo', collapse: true, icon: <MapIcon />}} key='tipoTicket' />
                          <ItemList info={{path: '/administrar/sub-tipo-ticket', name: 'Sub-Tipo', collapse: true, icon: <MapIcon />}} key='subTipoTicket' />
                          <ItemList info={{path: '/administrar/nivel-administrativo', name: 'Nivel Administrativo', collapse: true, icon: <MapIcon />}} key='nivelAdministrativo' />
                          <ItemList info={{path: '/administrar/motivo-baja', name: 'Motivo de Baja', collapse: true, icon: <MapIcon />}} key='motivoDeBaja' />
                          <ItemList info={{path: '/administrar/sub-cierre-ticket', name: 'Sub-Cierre', collapse: true, icon: <MapIcon />}} key='subCierreTicket' />
                        </>
                      )}
                      { permisos['hashtag.NubiABM'] &&(
                        <ItemList info={{path: '/administrar/tags', name: 'Tags', collapse: true, icon: <MapIcon />}} key='tags' />
                      )}
                      {permisos['ticket.detProblema.ABM'] && (
                        <>
                          <ItemList info={{path: '/administrar/articulo-falla', name: 'Segmento Detalle Adicional', collapse: true, icon: <MapIcon />}} key='articuloFalla' />
                          <ItemList info={{path: '/administrar/problematica', name: 'Problematica', collapse: true, icon: <MapIcon />}} key='problematica' />
                          <ItemList info={{path: '/administrar/solucion-tecnica', name: 'Solucion Tecnica', collapse: true, icon: <MapIcon />}} key='solucionTecnica' />
                        </>
                      )}
                      {permisos['motivoCancelarPreTicket.NubiABM'] && (
                        <ItemList info={{path: '/administrar/email-invalido', name: 'Motivo Correo Invalido', collapse: true, icon: <MapIcon />}} key='emailInvalido' />
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              {permisos['fibraNav.nubicom.getAll'] && (
                <>
                  <CollapseItemsList info={{path: '/fibraoptica', name: 'Fibra Optica', onClick:handleClickFibraOptica, onRef:openFibraOptica, icon: <CableIcon />  }} />
                  <Collapse in={openFibraOptica} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ItemList info={{path: '/fibra-optica/naps', name: 'Tabla Naps', collapse: true, icon: <MapIcon />}} key='fibratabla' />
                      {/* <ItemList info={{path: '/fibra-optica/zonas', name: 'Tabla Zonas', collapse: true, icon: <MapIcon />}} key='fibratablazonas' /> */}
                      {/* <ItemList info={{path: '/fibra-optica/historial/kmz', name: 'Historial KMZ', collapse: true, icon: <MapIcon />}} key='fibrahistorial' /> */}
                      <ItemList info={{path: '/fibra-optica/rango/cobertura', name: 'Tabla Cobertura', collapse: true, icon: <MapIcon />}} key='fibratablacobertura' />
                      <ItemList info={{path: '/fibra-optica/mapa', name: 'Cobertura Naps', collapse: true, icon: <MapIcon />}} key='fibramapa' />
                      <ItemList info={{path: '/fibra-optica/mapa-total', name: 'Cobertura Naps Total', collapse: true, icon: <MapIcon />}} key='fibramapatotal' />
                    </List>
                  </Collapse>
                </>
              )}
              {permisos['radioEnlace.nubicom.kmz'] && (
                <>
                  <CollapseItemsList info={{path: '/radio-enlace', name: 'Radio Enlace', onClick:handleClickRadioEnlace, onRef:openRadioEnlace, icon: <CableIcon />  }} />
                  <Collapse in={openRadioEnlace} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ItemList info={{path: '/radio-enlace/tabla', name: 'Tabla Paneles', collapse: true, icon: <MapIcon />}} key='radioEnlaceTabla' />
                      <ItemList info={{path: '/radio-enlace/historial/kmz', name: 'Historial KMZ', collapse: true, icon: <MapIcon />}} key='historyKmz' />
                    </List>
                  </Collapse>
                </>
              )}
              {( permisos['rol.All'] || permisos['usuarios.abm'] || permisos['grupo.abm']) &&(
                <>
                  <CollapseItemsList info={{path: '/administrador', name: 'Administrador', onClick:handleClickAdministrador, onRef:openAdministrador, icon: <AdminPanelSettingsIcon />  }} />
                  <Collapse in={openAdministrador} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      { permisos['rol.All'] && (
                        <ItemList info={{path: '/administrador/permisos', name: 'Permisos', collapse: true, icon: <MapIcon />}} key='admPermisos' />
                      )}
                      {(permisos['usuarios.abm'] || permisos['grupo.abm']) && (
                        <ItemList info={{path: '/administrador/grupos', name: 'Grupos Adm', collapse: true, icon: <GroupsIcon />}} key='admGrupos' />
                      )}
                      {(permisos['procesos.updated'] || permisos['procesos.created']) && (
                        <ItemList info={{path: '/tabla/procesos', name: 'Tabla Procesos', collapse: true, icon: <BallotIcon />}} key='tablaProcesos' />
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              {( permisos['analitica.usuarioId'] || permisos['analitica.general']) &&(
                <>
                  <CollapseItemsList info={{path: '/analitica', name: 'Analítica', onClick:handleClickAnalitica, onRef:openAnalitica, icon: <AdminPanelSettingsIcon />  }} />
                  <Collapse in={openAnalitica} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      { permisos['analitica.usuarioId'] && (
                        <ItemList info={{path: '/analitica/personal', name: 'Personal', collapse: true, icon: <MapIcon />}} key='anaPersonal' />
                      )}
                      {permisos['analitica.general'] && (
                        <ItemList info={{path: '/analitica/general', name: 'General', collapse: true, icon: <GroupsIcon />}} key='anaGeneral' />
                      )}
                    </List>
                  </Collapse>
                </>
              )}
              {( permisos['asesores.NubiGetAll'] || permisos['asesores.NubiABM']) &&(
                <>
                  <CollapseItemsList info={{path: '/asesores', name: 'Asesores', onClick:handleClickAsesor, onRef:openAsesor, icon: <AdminPanelSettingsIcon />  }} />
                  <Collapse in={openAsesor} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ItemList info={{path: '/asesores/personal', name: 'Personal', collapse: true, icon: <MapIcon />}} key='asesorPersonal' />
                      <ItemList info={{path: '/asesores/novedades', name: 'Novedades', collapse: true, icon: <MapIcon />}} key='asesorNovedades' />
                      <ItemList info={{path: '/asesores/objetivos', name: 'Objetivos', collapse: true, icon: <MapIcon />}} key='asesorObjetivos' />
                      <ItemList info={{path: '/asesores/general', name: 'General', collapse: true, icon: <GroupsIcon />}} key='asesorGeneral' />
                    </List>
                  </Collapse>
                </>
              )}
              <ItemList info={{path: '/vista/grupos', name: 'Grupos', icon: <GroupsIcon />}} key='gruposview' />
              <ItemList info={{path: '/procesos', name: 'Procesos', icon: <BallotIcon />}} key='procesos' />
              </List>
            ):(
              <List>
                <ItemList info={{path: '/', name: 'Dashboard', primary: true, icon: <HomeOutlined />}} key='dashboard' />
                <ItemList info={{path: '/contactos', name: 'Potenciales clientes', icon: <Groups2Icon />}} key='contactos' />
                <ItemList info={{path: '/tabla/consultas', name: 'Consultas', icon: <Groups2Icon />}} key='tablaconsultas' />
                <ItemList info={{path: '/pre/servicios', name: 'Pre-Servicios', icon: <PersonPinCircleIcon />}} key='tratos' />
              </List>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default SidebarResponsive