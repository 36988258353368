import { useState, useEffect } from 'react';
import { Box, Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, CardItemServicios } from '../../components';
import ApiSoftland from '../../services/apis.softland.service';

function ModalSelectPlanPrefa({
    show,
    hideModal,
    onGuardar,
    preServicio
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [arrPlanes, setArrPlanes] = useState([]);
    const [planSelect, setPlanSelect] = useState(null);
    const [tecnologiaPrefa, setTecnologiaPrefa] = useState('RF');

    const obtenerPlan = async() => {
        setIsLoading(true);
        await ApiSoftland.getProductos(`PY${tecnologiaPrefa}`).then((resp)=>{
            setIsLoading(false);
            setArrPlanes(resp)
        })
    }
    useEffect(() => {
        obtenerPlan()
        setPlanSelect(null);
    }, [tecnologiaPrefa])

    const onSubmit = () => {
        const form = {
            servicioIdSoftland_ds: planSelect?.cod_producto,
            tipoServicio_ds: planSelect?.tipo,
            nombreServicio_ds: planSelect?.nombre,
            moneda_ds: 'USD',
            importeServicio_ds: planSelect?.precio,
            cantidad_ds: '1.0000',
            anchoBajadaServicio_ds:planSelect?.bajada,
            anchoSubidaServicio_ds:planSelect?.subida,
            tipoAnchoBajada_ds:planSelect?.unidad_bajada,
            tipoAnchoSubida_ds:planSelect?.unidad_subida,
            vigencia_ds: 'N',
            cantMeses_ds: '0'
        }
        onGuardar(form)
        handleClose()
    }
    useEffect(() => {
        obtenerPlan()
    }, [])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Seleccionar Plan
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
                                <Card sx={{p: 2,background: '#fff !important', boxShadow: 3, borderRadius: 3, display:'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
                                    <Typography sx={{color: '#000', fontWeight: 'bold',fontSize: 17}}>
                                        Tecnología
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={tecnologiaPrefa}
                                            onChange={(e) => setTecnologiaPrefa(e.target.value)}
                                            row
                                        >
                                            <FormControlLabel
                                                value="RF"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#000',
                                                            '&.Mui-checked': {
                                                                color: '#0364ff',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="RF"
                                                sx={{color: '#000'}}
                                            />
                                            <FormControlLabel
                                                value="FO"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#000',
                                                            '&.Mui-checked': {
                                                                color: '#0364ff',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="FO"
                                                sx={{color: '#000'}}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Card>
                            </Grid>
                            {arrPlanes?.length > 0 && (
                                arrPlanes.map((serv)=>(
                                    <Grid item xs={12}>
                                        <CardItemServicios
                                            key={serv.cod_producto}
                                            item={serv}
                                            servicioSelect={planSelect}
                                            setServicioSelect={setPlanSelect}
                                            coberturaMaxima={tecnologiaPrefa === 'RF' ? preServicio?.mbPrefaRF_t || 0 : preServicio?.mbPrefaFO_t || 0}
                                            disabledPrefa
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Confirmar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={!planSelect}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalSelectPlanPrefa;