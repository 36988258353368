import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import MotivoTicketService from '../../services/motivoTicket.service';


const SelectMotivoTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    isDisabled,
    emp,
    isMulti,
    instalacion,
    contrato,
    contratoSoft,
    filterExist
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const onSelectValue = (ev) => {
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_mt === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        }else{
            setSelectedValue(ev);
            onChange(ev);
        }
    };
    useEffect(() => {
        if(emp){
            MotivoTicketService.getAll({ emp:emp }).then((resp)=>{
                setItems(resp)
            })
        }else{
            MotivoTicketService.getAll().then((resp)=>{
                setItems(resp)
            })
        }
    }, [])

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id_mt,
                label: item.motivo_mt,
                descripcion: item.cuerpo_mt,
                destino: item.grupoId_mt,
                tipoTicket: item.tipoTicketId_mt,
                subTipoTicket: item.subTipoTicketId_mt,
                nivelAdm: item.nivelAdmTkId_mt,
                motivoBaja: item.motivoBajaTk_mt,
                dayResolucion: item.dayResolucion_mt,
                tipoTareaId: item.tipoTareaId_mt,
                ...item
            };
        });
        if(instalacion){
            const filterNewOptions = newOptions.filter((it) => it?.tipoTareaId === 3)
            setOptions(filterNewOptions);
        }else if(contrato){
            const filterNewOptions = newOptions.filter((it) => it?.tipoTareaId === 3 || it?.tipoTareaId === 5)
            setOptions(filterNewOptions);
        }else if(contratoSoft){
            if((contratoSoft === 210475 || contratoSoft === '210475')){
                const filterNewOptions = newOptions.filter((it) => it?.label?.includes('TICKET MAESTRO') && it?.label?.includes('CORTE') )
                setOptions(filterNewOptions);
            }else if((contratoSoft === 475221 || contratoSoft === '475221')){
                const filterNewOptions = newOptions.filter((it) => it?.label?.includes('TICKET MAESTRO') && it?.label?.includes('MANTENIMIENTO') )
                setOptions(filterNewOptions);
            }else{
                const filterNewOptions = newOptions.filter((it) => [1,2,4,5,6]?.includes(it?.tipoTareaId) || [40,94]?.includes(it?.value) )
                setOptions(filterNewOptions);
            }
        }else if(filterExist){
            const filterNewOptions = newOptions.filter(item => filterExist.includes(item?.value));
            setOptions(filterNewOptions);
        }else{
            setOptions(newOptions);
        }
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>{
                    const item = items.find(it=>it.id_mt === va);
                    if(item){
                        return {
                            label:item.motivo_mt,
                            value:item.id_mt,
                            ...item
                        };
                    }
                    return null
                }))
        }else{
            setSelectedValue(null)
        }
    }, [items,value,contrato,contratoSoft,filterExist]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            isDisabled={isDisabled}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectMotivoTicket.defaultProps = {
    isClearable: false
};

export default SelectMotivoTicket;
