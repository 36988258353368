import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ButtonBasic, CardRecordatorio, LogsCard, LogsTicketOld, LogsTimeCard } from '../../components';
import CloseIcon from '@mui/icons-material/Close';
import TicketService from '../../services/tickets.service';
import ActionsCvvService from '../../services/actionsCvv.service';
import MotivoTicketService from '../../services/motivoTicket.service';
import LogsTicketsService from '../../services/logsTickets.service';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { showModal } from 'react-redux-modal-provider';
import ModalCrearRecordatorio from '../recordatorios/ModalCrearRecordatorio';
import RecordatorioService from '../../services/recordatorio.service';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function ModalTicketIdLogs({
    id,
    show,
    hideModal,
    ticketId,
    ticketIdOld,
    type,
    nombreCompleto,
    contactoId,
    preServicioId,
    servicioId,
    cuentaId,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [logs, setLogs] = useState([]);
    const [userTicket, setUserTicket] = useState([]);
    const [logsRecorrido, setlogsRecorrido] = useState([]);
    const [key, setKey] = useState('tickets');
    const [motivoTk, setMotivoTk] = useState([]);
    const [value, setValue] = useState(0);
    const [recordatorios, setRecordatorios] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const actualizar = () =>{
        if(type === 'cvv'){
            ActionsCvvService.infoTicket(ticketId).then((response) => {
                setLogs(response);
            })
            ActionsCvvService.infoUserTicket(ticketId).then((resp) => {
                setUserTicket(resp)
            })
            if(ticketIdOld){
                ActionsCvvService.infoOldTicket(ticketIdOld).then((data)=>{
                    setlogsRecorrido(data)
                })
            }
        }else{
            MotivoTicketService.getAll({emp: type}).then((respM)=>{
                setMotivoTk(respM)
            })
            LogsTicketsService.getAll({tickets: ticketId, type}).then((response) => {
                setLogs(response);
            })
            TicketService.getAllUxT({id: ticketId, type}).then((resp) => {
                setUserTicket(resp)
            })
            if(ticketIdOld){
                LogsTicketsService.getAll({logs: ticketIdOld,type}).then((data)=>{
                    setlogsRecorrido(data)
                })
            }
        }

    }
    useEffect(() => {
        actualizar();
        actualizarRecordatorios();
    }, []);

    const actualizarRecordatorios = async() =>{
        await RecordatorioService.getAll({ticketId: ticketId}).then((resp)=>{
            setRecordatorios(resp)
        })
    }

    const onOpenModalNewRecordatorio = () => {
        showModal(ModalCrearRecordatorio,{
            onGuardar: actualizarRecordatorios,
            nombreCompleto: nombreCompleto,
            contactoId: contactoId,
            cuentaId: cuentaId,
            servicioId: servicioId,
            preServicioId: preServicioId,
            ticketId: ticketId,
            consultaId: null,
        });
    }

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Historial del Ticket #${ticketId}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="fullWidth"
                            sx={{
                                '& .MuiTabs-indicator':{
                                    background: '#0364ff'
                                },
                                '& .MuiTab-fullWidth':{
                                    color: '#6b788e',
                                    fontWeight: 'bold'
                                },
                                '& .MuiTab-fullWidth.Mui-selected':{
                                    color: '#0364ff'
                                }
                            }}
                        >
                            <Tab label="Historial" {...a11yProps(0)} />
                            <Tab label="Tiempo" {...a11yProps(1)} />
                            <Tab label="Recordatorios" {...a11yProps(2)} />
                            <Tab label="Relacion" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {logs?.length > 0 &&
                            logs?.map((lg)=> <LogsCard item={lg} keyst={lg.id_lt} motivoTk={motivoTk} /> )
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {userTicket &&
                            userTicket?.map((ulg)=>{
                                return <LogsTimeCard item={ulg} keys={ulg.id_uxt} />
                            })
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', justifyContent: 'right'}}>
                                <ButtonBasic
                                    textButton='Crear Recordatorio'
                                    variant='wk'
                                    color='nextStep'
                                    onClick={()=>onOpenModalNewRecordatorio()}
                                />
                            </Box>
                            <Grid container spacing={1}>
                                {recordatorios?.length > 0 && (
                                    recordatorios.map((recs)=>(
                                        <Grid item xs={12}>
                                            <CardRecordatorio
                                                key={recs?.id_rec}
                                                item={recs}
                                                onCloseModal={handleClose}
                                                onGuardar={actualizarRecordatorios}
                                            />
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {logsRecorrido && logsRecorrido.length > 0 ? (
                            logsRecorrido?.map((lg)=>{
                                return <LogsTicketOld item={lg} key={lg.id_tk} type={type} handleClose={handleClose} />
                            })
                        ):(
                            <Box mt={4}>
                                <Typography sx={{color: 'gray',textAlign: 'center'}}>{`El ticket #${ticketId} no tiene relacion con otro ticket.`}</Typography>
                            </Box>
                        )}
                    </TabPanel>
                </DialogContent>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic onClick={()=>handleClose()} textButton='Cerrar' variant='wk' color='cancel' sx={{mb:2,width: 100}} />
                </Box>
            </Box>
        </Dialog>
    );
}

ModalTicketIdLogs.propTypes = {
    show: PropTypes.bool
};

ModalTicketIdLogs.defaultProps = {
    show: false
};

export default ModalTicketIdLogs;