import { useEffect, useState, useContext } from "react"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { SocketContext } from "../../context/SocketContext";
import FechaFunction from "../../function/FechaFunction";
import SaiSoftlandService from "../../services/saiSoftland.service";
import ModalPlantillaBonificacion from "../../modals/plantillas/ModalPlantillaBonificacion";

const PlantillaBonificacionSAIScreen = () => {
    const [items, setItems] = useState([]);
    const { sockets } = useContext( SocketContext );

    const actualizar = () =>{
        SaiSoftlandService.getAll({all: true}).then((resp)=>{
            setItems(resp)
        })
    }
    const columns = [
        {
            name: "id_ss",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_ss",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "segmento_ss",
            label: "Segmento",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "vigenciaMes_ss",
            label: "Duracion (Meses)",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "tipoServicioDesc_ss",
            label: "Tipo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "nivelBonificacion_ss",
            label: "Nivel",
            options: {
                filter: true,
                sort: true,
            }
        },/*
        {
            name: 'fechaIni_ss',
            label: "Inicio",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? FechaFunction.format(value, 'dd-MM-yyyy') : 'S/F'
                )
            }
        },
        {
            name: 'fechaFin_ss',
            label: "Fin",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? FechaFunction.format(value, 'dd-MM-yyyy') : 'S/F'
                )
            }
        }, */
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>console.log(tableMeta.rowData[0])}>
                                <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () =>{
        actualizar();
        sockets?.emit('canales');
    }
    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_ss === item)
            showModal(ModalPlantillaBonificacion, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalPlantillaBonificacion, {onGuardar: onGuardar});
        }
    };

    useEffect(() => {
        actualizar();
        document.title = 'Bonificaciones SAI Softland'
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3}>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Bonificaciones SAI Softland</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PlantillaBonificacionSAIScreen