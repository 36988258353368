import { useContext, useState, useMemo, useEffect } from 'react';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, SelectGrupos, SelectUser, SelectPrioridadTkNubicom, SelectMotivoTicket, DateSingle, InputEditQuill, CardUploadFile, InputDropZoneComment, SelectEmpresas, InputSearchDireccionEntrega, SelectMotivoBajaTk, SelectCanalesSoftland, SelectNivelAdmTicket } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import FechaFunction from '../../function/FechaFunction';
import TicketService from '../../services/tickets.service';

function ModalCrearTicketNubicomV2({show, hideModal,onGuardar,ticketIdOld}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const { sockets } = useContext( SocketContext );
    const [files, setFiles] = useState([]);
    const [filesCompress, setFilesCompress] = useState([]);
    const [contactoCrm, setContactoCrm] = useState(null);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const [formAll, setFormAll] = useState({
        titulo_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuerpo_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        MotivoTicketId: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        empresaId_mt:{
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoId_uxt: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipo_uxt: {
            texto: 'GRUPO',
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        prioridad_tk: {
            texto: 'NORMAL',
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        subTipoTicketId_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        fechavencimiento_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nivelAdmTkId_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoTicketId_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        motivoBajaTk_tk: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuentaContratoId_txt: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        id_t: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        destinoDNI: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        codCanal_tk: {
            texto: null,
            textoErr: 'El canal es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    })
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const cambiarFecha = (date) =>{
        if(!date){
            date = 0;
        }
        const newDate = FechaFunction.addDaysDate(new Date(),date)
        onChangeForm(newDate, 'fechavencimiento_tk')
    }
    const onCompressImg = async(imgx) =>{
        const nombreCortado = imgx?.name?.split('.');
        let extension = nombreCortado[ nombreCortado?.length - 1 ];
        extension = extension?.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx?.id
                    result.preview=imgx?.preview
                    setIsLoading(false);
                    setFilesCompress(old => [...old,result]);
                }
            })
        }else{
            setFilesCompress(old => [...old,imgx]);
        }
    }

    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    const onSubmit = async() =>{
        setIsLoading(true);
        const form = {
            ticketIndv: true,
            propertys: 'nubicom',
            createdEmp_tk: 'NUBICOM',
            estado_tk: 'ABIERTO',
        };
        let isOk = false;
        setIsLoading(true);
        const inputRequired = [
            'titulo_tk',
            'cuerpo_tk',
            'MotivoTicketId',
            'tipoId_uxt',
            'tipo_uxt',
            'prioridad_tk',
            'subTipoTicketId_tk',
            'fechavencimiento_tk',
            'tipoTicketId_tk',
            'codCanal_tk'
        ]
        if(formAll?.cuentaContratoId_txt.texto){
            inputRequired.push('cuentaContratoId_txt')
        }else{
            inputRequired.push('id_t')
        }
        if(formAll?.nivelAdmTkId_tk?.texto === 10){
            inputRequired.push('motivoBajaTk_tk')
        }
        if((formAll?.MotivoTicketId?.texto === 33 || formAll?.MotivoTicketId?.texto === 48)){
            inputRequired.push('nivelAdmTkId_tk')
        }
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(ticketIdOld){
                form.ticketIdOld_tk = ticketIdOld;
            }
            if(filesCompress?.length > 0){
                form.filesCompress = filesCompress;
            }
            if(contactoCrm?.cuenta){
                form.cuentaId_txt = contactoCrm?.cuenta
            }
            if(contactoCrm?.cuentaId_t){
                form.cuentaId_txt = contactoCrm?.cuentaId_t
            }
            if(contactoCrm?.contactoId_t){
                form.contactoId_txt = contactoCrm?.contactoId_t
            }
            TicketService.register(form).then(()=>{
                sockets?.emit( 'newTicket',formAll?.destinoDNI?.texto);
                if(onGuardar){
                    onGuardar()
                }
                hideModal();
            })
            .catch(()=>{
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
        }else{
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])

    const styleBtnSelect = useMemo(() => ({
        border:1,borderColor: '#15294b', color: '#15294b',background:'white',
        '&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleBtnNone = useMemo(() => ({
        background:'#dfe2e6',boxShadow:0,'&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45,
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '& .Mui-disabled':{
                borderRadius: 2,
                background: '#f2f2f2',
                height: 4.7,
                marginTop:0.1,
                color: '#000 !important',
                border: 1,
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "gray",
        },
    }), [])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Ticket - NUBICOM
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000',fontSize:12}}>Seleccione el destinatario</Typography>
                            <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center', width:250,background:'#dfe2e6',p:1,borderRadius:3,mb:2}}>
                                {/* <ButtonBasic
                                    textButton='Personal'
                                    sx={formAll?.tipo_uxt?.texto === 'DNI' ? styleBtnSelect : styleBtnNone}
                                    onClick={()=>onChangeForm('DNI', 'tipo_uxt')}
                                /> */}
                                <ButtonBasic
                                    textButton='Grupo'
                                    sx={[{marginLeft:0},formAll?.tipo_uxt?.texto === 'GRUPO' ? styleBtnSelect : styleBtnNone]}
                                    onClick={()=>onChangeForm('GRUPO', 'tipo_uxt')}
                                />
                            </Box>
                        </Grid>
                        { formAll.tipo_uxt.texto === 'DNI' ? (
                            <Grid item xs={12} sm={6} md={8}>
                                <Box sx={{width: '100%',mt:1}}>
                                    <Typography sx={{color: '#000'}}>Personal</Typography>
                                    <SelectUser
                                        id="selectUser"
                                        filter="select"
                                        value={formAll.tipoId_uxt.texto}
                                        isInvalid={formAll.tipoId_uxt.estadoErr}
                                        onChange={(opt) => {
                                            onChangeForm(opt.value, 'tipoId_uxt')
                                            onChangeForm(opt.dni, 'destinoDNI')
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ):(
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{width: '100%',mt:1}}>
                                        <Typography sx={{color: '#000'}}>Empresa</Typography>
                                        <SelectEmpresas
                                            value={formAll.empresaId_mt.texto}
                                            isInvalid={formAll.empresaId_mt.estadoErr}
                                            onChange={(opt) => onChangeForm(opt.value, 'empresaId_mt')}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box sx={{width: '100%',mt:1}}>
                                        <Typography sx={{color: '#000'}}>Grupo</Typography>
                                        <SelectGrupos
                                            id="selectGrupo"
                                            value={formAll.tipoId_uxt.texto}
                                            isInvalid={formAll.tipoId_uxt.estadoErr}
                                            empresaId={formAll.empresaId_mt.texto}
                                            onChange={(opt) => {
                                                onChangeForm(opt.value, 'tipoId_uxt')
                                                onChangeForm(opt.value, 'destinoDNI')
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography sx={{color: '#000',fontSize:12}}>Servicios</Typography>
                            <InputSearchDireccionEntrega
                                onClick={(opt)=>{
                                    onChangeForm(null, 'MotivoTicketId');
                                    onChangeForm(null, 'titulo_tk');
                                    onChangeForm('GRUPO', 'tipo_uxt');
                                    onChangeForm(null || '', 'cuerpo_tk');
                                    onChangeForm(null, 'tipoId_uxt');
                                    onChangeForm(null, 'tipoTicketId_tk');
                                    onChangeForm(null, 'subTipoTicketId_tk');
                                    onChangeForm(null, 'nivelAdmTkId_tk');
                                    onChangeForm(null, 'motivoBajaTk_tk');
                                    cambiarFecha(0)
                                    setContactoCrm(opt)
                                    if(opt?.id_t){
                                        onChangeForm(null, 'cuentaContratoId_txt')
                                        onChangeForm(opt.id_t, 'id_t')
                                    }else{
                                        onChangeForm(opt?.contrato, 'cuentaContratoId_txt')
                                        onChangeForm(null, 'id_t')
                                    }
                                }}
                                    borderChange={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Motivo del Ticket</Typography>
                            <SelectMotivoTicket
                                id="MotivoTicketId"
                                value={formAll.MotivoTicketId.texto}
                                emp='nubicom'
                                isDisabled={(!formAll.id_t.texto && !formAll.cuentaContratoId_txt.texto )}
                                isInvalid={formAll.MotivoTicketId.estadoErr}
                                contrato={formAll.id_t.texto}
                                contratoSoft={formAll.cuentaContratoId_txt.texto}
                                onChange={(opt) => {
                                    onChangeForm(opt.value, 'MotivoTicketId');
                                    onChangeForm(opt.label, 'titulo_tk');
                                    onChangeForm('GRUPO', 'tipo_uxt');
                                    onChangeForm(opt.descripcion || '', 'cuerpo_tk');
                                    onChangeForm(opt.destino, 'tipoId_uxt');
                                    onChangeForm(opt.tipoTicket, 'tipoTicketId_tk');
                                    onChangeForm(opt.subTipoTicket, 'subTipoTicketId_tk');
                                    onChangeForm(opt.nivelAdm, 'nivelAdmTkId_tk');
                                    onChangeForm(opt.motivoBaja, 'motivoBajaTk_tk');
                                    onChangeForm(opt.empresaId_mt, 'empresaId_mt');
                                    cambiarFecha(opt.dayResolucion)
                                }}
                            />
                        </Grid>
                        {(formAll?.MotivoTicketId?.texto === 33 || formAll?.MotivoTicketId?.texto === 48) && (
                            <Grid item xs={12} sm={6} md={4} mt={0.5}>
                                <Typography sx={{color: '#000',fontSize:12}}>Nivel Administrativo</Typography>
                                <SelectNivelAdmTicket
                                    subTipoTicket={formAll.subTipoTicketId_tk.texto}
                                    value={formAll.nivelAdmTkId_tk.texto}
                                    isInvalid={formAll.nivelAdmTkId_tk.estadoErr}
                                    onChange={(opt) => onChangeForm(opt.value,'nivelAdmTkId_tk')}
                                />
                                {formAll.nivelAdmTkId_tk.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:10}}>Campo obligatorio</Typography>
                                )}
                            </Grid>
                        )}
                        {formAll.nivelAdmTkId_tk.texto === 10 && (
                            <Grid item xs={12} sm={6} md={4} mt={0.5}>
                                <Typography sx={{color: '#000',fontSize:12}}>Motivo de Baja</Typography>
                                <SelectMotivoBajaTk
                                    isDisabled={formAll.nivelAdmTkId_tk.texto !== 10}
                                    nivelAdmTk={4}
                                    value={formAll.motivoBajaTk_tk.texto}
                                    isInvalid={formAll.motivoBajaTk_tk.estadoErr}
                                    onChange={(opt) => onChangeForm(opt.value,'motivoBajaTk_tk')}
                                />
                                {formAll.motivoBajaTk_tk.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:10}}>Campo obligatorio</Typography>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Prioridad</Typography>
                            <SelectPrioridadTkNubicom
                                value={formAll.prioridad_tk.texto}
                                isInvalid={formAll.prioridad_tk.estadoErr}
                                onChange={(opt) => onChangeForm(opt.value,'prioridad_tk')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.titulo_tk.texto}
                                onChange={(e)=>onChangeForm(e.target.value, 'titulo_tk')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000',fontSize:12}}>Fecha de vencimiento</Typography>
                            <DateSingle
                                value={formAll.fechavencimiento_tk.texto}
                                onChange={(fecha)=> onChangeForm(fecha, 'fechavencimiento_tk')}
                                minDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000',fontSize:12}}>Canal</Typography>
                            <SelectCanalesSoftland
                                isInvalid={formAll.codCanal_tk.estadoErr}
                                value={formAll.codCanal_tk.texto}
                                onChange={(e)=>onChangeForm(e.value,'codCanal_tk')}
                            />
                            {formAll.codCanal_tk.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.codCanal_tk.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <InputEditQuill
                                template={formAll.cuerpo_tk.texto}
                                onChangeText={(text)=>onChangeForm(text, 'cuerpo_tk')}
                            />
                        </Grid>
                        { filesCompress?.length > 0 &&(
                            filesCompress.map((fil)=>(
                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                            </Grid>
                            ))
                        )}
                    </Grid>
                    { filesCompress?.length < 5 &&(
                        <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                            <InputDropZoneComment
                                id="otImg"
                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                setFoto={setFiles}
                                accept={acceptInputFile}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={(!formAll.cuentaContratoId_txt.texto && !formAll.id_t.texto )|| !formAll.tipoTicketId_tk.texto || !formAll.subTipoTicketId_tk.texto || !formAll.tipoId_uxt.texto}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalCrearTicketNubicomV2;