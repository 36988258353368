import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ButtonBasic, DateRange, Select, SelectCantDias, SelectRangoFechaCreated, SelectRangoFechaPotCliente, SelectRangoFechaPreServicios, SelectRangoFechaTicket } from '../../components';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import DownloadFile from '../../services/downloadFile.service';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment'

const ModalRepDownRangoFecha = ({show, hideModal, tipo,rangoFechasArray, setRangoFechasArray}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tipoRango, setTipoRango] = useState('rango');
    const [itemsCampo, setItemsCampo] = useState(null);
    const [itemCampoId, setItemCampoId] = useState(null);
    const [itemArrayId, setItemArrayId] = useState(rangoFechasArray?.map((e)=> e?.keyCampo) || []);
    const [fechaSelect, setFechaSelect] = useState({
        startDate: null,
        endDate: null
    });
    const [cantDias, setCantDias] = useState(null);
    const handleClose = () => hideModal();
    const onSubmit = () => {
        setIsLoading(true);
        const fechaActual = moment().format("YYYY-MM-DD");
        const form = {
            fechaIni: fechaActual,
            fechaFin: fechaActual,
            keyCampo: itemCampoId,
            itemsCampo: itemsCampo
        }
        if(tipoRango === 'rango'){
            form.fechaIni = moment(fechaSelect.startDate).format("YYYY-MM-DD");
            form.fechaFin = moment(fechaSelect.endDate).format("YYYY-MM-DD");
        }else {
            form.fechaIni = moment().subtract(cantDias, "days").format("YYYY-MM-DD");
            form.fechaFin =  fechaActual;
        }
        setIsLoading(false)
        setRangoFechasArray(oldArray => [...oldArray, form]);
        handleClose();
    };

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Rango de Fecha
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Box sx={{width: '85%'}}>
                                <Typography sx={{color: '#000',textAlign:'start'}}>Campo a filtrar:</Typography>
                                {tipo === 'ticket' && (
                                    <SelectRangoFechaTicket
                                        value={itemCampoId}
                                        filtro={itemArrayId}
                                        onChange={(e) => {
                                            setItemCampoId(e.value)
                                            setItemsCampo(e)
                                        }}
                                    />
                                )}
                                {tipo === 'pre-servicio' && (
                                    <SelectRangoFechaPreServicios
                                        value={itemCampoId}
                                        filtro={itemArrayId}
                                        onChange={(e) => {
                                            setItemCampoId(e.value)
                                            setItemsCampo(e)
                                        }}
                                    />
                                )}
                                {tipo === 'potencialcliente' && (
                                    <SelectRangoFechaCreated
                                        value={itemCampoId}
                                        filtro={itemArrayId}
                                        onChange={(e) => {
                                            setItemCampoId(e.value)
                                            setItemsCampo(e)
                                        }}
                                    />
                                )}
                                {tipo === 'consultas' && (
                                    <SelectRangoFechaCreated
                                        value={itemCampoId}
                                        filtro={itemArrayId}
                                        onChange={(e) => {
                                            setItemCampoId(e.value)
                                            setItemsCampo(e)
                                        }}
                                    />
                                )}
                                {tipo === 'tags' && (
                                    <SelectRangoFechaCreated
                                        value={itemCampoId}
                                        filtro={itemArrayId}
                                        onChange={(e) => {
                                            setItemCampoId(e.value)
                                            setItemsCampo(e)
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='center'>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={tipoRango}
                                    onChange={(e) => setTipoRango(e.target.value)}
                                    row
                                >
                                    <FormControlLabel
                                        value="rango"
                                        control={ <Radio sx={{color: '#000'}} />}
                                        label="Rango de fechas"
                                        sx={{color: '#000'}}
                                    />
                                    <FormControlLabel
                                        value="meses"
                                        control={ <Radio sx={{color: '#000'}} /> }
                                        label="Ultimos días"
                                        sx={{color: '#000'}}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='center' mt={-1}>
                            {tipoRango === 'rango' &&(
                                <DateRange
                                    minDate={new Date('2023-01-01')}
                                    initianDate={fechaSelect}
                                    onChange={(e)=>setFechaSelect(e)}
                                />
                            )}
                            {tipoRango === 'meses' &&(
                                <Box sx={{width: '85%'}}>
                                    <SelectCantDias
                                        value={cantDias}
                                        onChange={(e)=>setCantDias(e.value)}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={((!fechaSelect?.startDate || !fechaSelect?.endDate) && tipoRango === 'rango') || (tipoRango === 'meses' &&  !cantDias) || !itemsCampo || !itemCampoId}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalRepDownRangoFecha