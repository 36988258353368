import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { showModal } from 'react-redux-modal-provider';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { ButtonBasic, CardCuentaProducto, TextInfoTicket } from '../../components';
import TratoService from '../../services/tratos.service';
import TabCuentaTicket from '../contacto/tabs/TabCuentaTicket';
import MikrotikV2Service from '../../services/mikrotikV2.service';
import CalendarioV2Service from '../../services/calendarioV2.service';
import TabCuentaTareas from '../contacto/tabs/TabCuentaTareas';
import TabCuentaConsultas from '../contacto/tabs/TabCuentaConsultas';
import ConsultasService from '../../services/consultas.service';
import ApiSoftland from '../../services/apis.softland.service';
import ConfirmationDialog from '../../modals/default/ConfirmationDialog';
import TabCuentaHistorico from '../contacto/tabs/TabCuentaHistorico';
import LogService from '../../services/log.service';
import FechaFunction from '../../function/FechaFunction';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import ReportesService from '../../services/reportes.service';
import TabCuentaRecodatorios from '../contacto/tabs/TabCuentaRecodatorios';
import RecordatorioService from '../../services/recordatorio.service';
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ServicioCuentaVerScreen = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [infoCliente, setInfoCliente] = useState(null);
    const [trato, setTrato] = useState();
    const [items, setItems] = useState();
    const [extensiones, setExtensiones] = useState();
    const [tickets, setTickes] = useState([]);
    const [equipo, setEquipo] = useState(null);
    const [tareas, setTareas] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [localBd, setLocalBd] = useState(null);
    const [logs, setLogs] = useState([]);
    const [recordatorios, setRecordatorios] = useState([]);

    const groupBy = (objectArray, property) => {
        return objectArray.reduce(function (acc, obj) {
            const keyGB = obj[property];
            if (!acc[keyGB]) {
                acc[keyGB] = [];
            }
            acc[keyGB].push(obj);
            return acc;
        }, {});
    }
    const actualizar = async() =>{
        await TratoService.getByIdCuenta(id).then((r)=>{
            setTrato(r);
            setItems([])
            setExtensiones([])
            setTickes([])
            setEquipo(null)
            setTareas([])
            setConsultas([])
            setLocalBd(null)
        })
        .catch((e)=>{
            if(e?.response?.data?.msg){
                navigate('/')
            }
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }
    const traerInfoMikrotik = async() => {
        await MikrotikV2Service.obtenerServicioId({id:id}).then((resp)=>{
            setEquipo(resp)
        })
    }
    const actualizarTareas = async() =>{
        await CalendarioV2Service.tareasCalendar({cuentaContratoId: id}).then((resp)=>{
            setTareas(resp)
        })
    }
    const actualizarHistorico = async() =>{
        await LogService.getAllContacto({servicio: id}).then((resp)=>{
            setLogs(resp)
        })
    }
    const onGuardar = () => {
        actualizar();
        actualizarTareas();
        traerInfoMikrotik();
        actualizarHistorico();
        actualizarRecordatorios();
    }
    const onNuevaCobertura = async() =>{
        if((trato?.latitud && trato?.longitud) || (items?.length > 0 && items[0]?.descripcion)){
            const form = {
                contactoCuenta_con: trato?.cuenta,
                nombreContacto_con: trato?.cliente,
                direccionConsulta_con: trato?.direccion_servicio,
                coordenadasConsulta_con: `${trato?.latitud}, ${trato?.longitud}`,
                nroServicio: id,
            }
            if(items?.length > 0 && items[0]?.descripcion){
                form.contactoCuenta_con = items[0]?.cuenta;
                form.nombreContacto_con = items[0]?.descripcion;
                form.direccionConsulta_con = items[0]?.ubicacion?.domicilio || 'S/D';
                form.coordenadasConsulta_con = `${items[0]?.ubicacion?.latitud}, ${items[0]?.ubicacion?.longitud}`;
            }
            await ConsultasService.registerConsultaCobertura(form).then(()=>{
                setIsLoading(false);
                toast.success('Se realizó la consulta de cobertura correctamente.', {
                    position: "top-right",
                    autoClose: 3500,
                    pauseOnHover: false,
                });
                onGuardar();
            }).catch(()=>{
                setIsLoading(false);
            })
        }
    }
    const onOpenModalAltaBajaDirEntrega = async(idDirEnt) => {
        showModal(ConfirmationDialog, {
            title: `${trato?.estado_direntrega ? 'Activar' : 'Eliminar'} la direccion de entrega`,
            message: `¿Esta seguro que desea dar de ${trato?.estado_direntrega ? 'alta' : 'baja'} la direccion de entrega?`,
            onConfirm: async() => {
                await ApiSoftland.altaBajaDireccionEntrega({idDirEnt: idDirEnt}).then(async()=>{
                    await actualizar()
                })
            }
        });
    }
    const onReporteServicioId = async() => {
        await AuxiliarFunction.downloadFile(
            ReportesService.reporteServicioId(id),
            id
        )
    }
    useEffect(() => {
        actualizar().then(()=>{
            actualizarTareas();
            traerInfoMikrotik();
            actualizarHistorico();
            actualizarRecordatorios();
        })
        document.title = `Servicio #${id}`;
    }, [id])
    useEffect(() => {
        if(trato && trato[id] && trato[id]?.length > 0){
            setInfoCliente({
                nombre: trato[id][0]?.descripcion,
                numero: trato[id][0]?.numero,
                cuenta: trato[id][0]?.cuenta,
                vip: trato[id][0]?.vip
            })
            setItems(trato[id])
            trato[id].map((tk)=>{
                if(tk.ticket)setTickes(tk.ticket)
                return ''
            })
        }else{
            setInfoCliente({
                nombre: trato?.cliente,
                numero: trato?.contrato,
                cuenta: trato?.cuenta,
                vip: trato?.vip
            })
            setTickes(trato?.ticket || [])
        }
        setConsultas(trato?.consultas || [])
        setLocalBd(trato?.localBd || null)
    }, [trato])

    useEffect(() => {
        if(items?.length > 0){
            const filtro = items.filter((it)=> !it?.ticket)
            const cantidad = groupBy(filtro,'extension')
            if(Object.keys(cantidad).length > 0 ){
                setExtensiones(cantidad)
            }
        }else{
            setExtensiones([])
        }
    }, [items])

    const actualizarRecordatorios = async() =>{
        await RecordatorioService.getAll({servicioId: id}).then((resp)=>{
            setRecordatorios(resp)
        })
    }
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
        <Grid container p={3} mb={5} spacing={1}>
            <Grid item xs={12} md={3}>
            <Card sx={{background: '#fff !important',p:2,borderRadius:4,boxShadow: "0px -3px 0px 0px #15294b"}}>
                <IconButton sx={{boxShadow: 3}} onClick={()=>navigate(-1)}>
                <ArrowBackIcon sx={{color: '#15294b'}} />
                </IconButton>
                <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',mb: 1, flexDirection: 'column'}}>
                    <Box sx={{background: '#f5f6f7', borderRadius:80, width:80, height: 80, display:'flex', justifyContent: 'center', alignItems: 'center',border:1 , borderColor: '#15294b'}}>
                        <LocalMallOutlinedIcon sx={{color: '#15294b',fontSize: 70}} />
                    </Box>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'center',mt: 1}} variant='h4'>
                        {`Servicio #${infoCliente?.numero || trato?.contrato || '-'}`}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column',mb: 2}}>
                    <ButtonBasic
                        textButton='Descargar Reporte'
                        iconStart={<BackupTableIcon />}
                        onClick={()=>onReporteServicioId()}
                        variant='wk'
                        color='nextWhite'
                        sx={{border: 1}}
                    />
                </Box>
                <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
                <TextInfoTicket titulo='Cuenta' descripcion={infoCliente?.cuenta || trato?.cuenta || '-'} />
                {infoCliente?.vip || trato?.vip && (
                    <TextInfoTicket titulo='Nivel VIP' descripcion={infoCliente?.vip || trato?.vip || '-'} />
                )}
                <TextInfoTicket titulo='Total Ticket' descripcion={tickets?.length} />
                {items?.length > 0 && (
                    <TextInfoTicket titulo='Total Items' descripcion={items?.length -1 || '0'} />
                )}
                <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
                {items?.length > 0 && (items[0]?.ubicacion?.latitud && items[0]?.ubicacion?.longitud) && (
                    <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',mb: 1, flexDirection: 'column'}}>
                        <ButtonBasic
                            textButton='Consultar Cobertura'
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1}}
                            onClick={()=>onNuevaCobertura()}
                            isLoading={isLoading}
                        />
                    </Box>
                )}
                {(trato?.latitud && trato?.longitud) && (
                    <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',mb: 1, flexDirection: 'column'}}>
                        <ButtonBasic
                            textButton='Consultar Cobertura'
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1}}
                            onClick={()=>onNuevaCobertura()}
                            isLoading={isLoading}
                        />
                    </Box>
                )}
                {equipo &&(
                    <>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Equipo Instalado</Typography>
                        <TextInfoTicket titulo='Tecnologia' descripcion={equipo?.tecServicio_tk?.toUpperCase() || '-'} />
                        <TextInfoTicket titulo='Nodo' descripcion={equipo?.nodoName_tk || '-'} />
                        <TextInfoTicket titulo={equipo?.tecServicio_tk === 'fibra' ? 'Nap' : 'Panel'} descripcion={equipo?.panelName_tk || '-'} />
                        {equipo?.tecServicio_tk === 'fibra' && (
                        <TextInfoTicket titulo='Puerto' descripcion={equipo?.puertoNap_tk || '-'} />
                        )}
                        <TextInfoTicket titulo='Equipo' descripcion={equipo?.equipo || '-'} />
                        <TextInfoTicket titulo='Mac' descripcion={equipo?.mac || '-'} />
                        <TextInfoTicket titulo='IP' descripcion={equipo?.wan || '-'} />
                        <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
                    </>
                )}
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{background: '#e6f0ff', borderRadius:50, width:35, height: 35, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <AccountCircleIcon sx={{color: '#15294b',fontSize: 25}} />
                    </Box>
                    <Typography
                        sx={{
                            color: '#15294b',fontWeight: 'bold',textAlign: 'center',ml: 1,
                            '&:hover':{cursor: 'pointer', color: '#0364ff'}
                        }}
                        variant='h6'
                        onClick={()=> navigate(`/contactos/${infoCliente?.cuenta || trato?.cuenta}/cuenta`)}
                    >
                        {infoCliente?.nombre || trato?.cliente}
                    </Typography>
                </Box>
                {(localBd?.autorizadoxPersonalId_t?.nombre_p && localBd?.fechaAutorizado_t) &&(
                    <>
                        <Divider sx={{background: '#8993a4 !important',marginBottom:2,width: '100%'}} />
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'left',mb: 1}} variant='h6'>Autorización</Typography>
                        <TextInfoTicket titulo='Autorizado Por:' descripcion={localBd?.autorizadoxPersonalId_t?.nombre_p} />
                        <TextInfoTicket titulo='Fecha Autorizado:' descripcion={FechaFunction.format( new Date(localBd?.fechaAutorizado_t), 'dd/MM/yyyy HH:mm:ss')} />
                    </>
                )}
            </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <Card sx={{background: '#fff !important',p:2,borderRadius:4}}>
                    <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            sx={{
                                '& .MuiTabs-indicator':{
                                    background: '#0364ff'
                                },
                                '& .MuiButtonBase-root':{
                                color: '#6b788e',
                                fontWeight: 'bold'
                                },
                                '& .MuiButtonBase-root.Mui-selected':{
                                    color: '#0364ff'
                                }
                            }}
                        >
                            <Tab label="Tickets" {...a11yProps(0)} sx={{ minWidth: "150px" }}/>
                            {items && items.length > 0 ? (
                                <Tab label="Items" {...a11yProps(1)} sx={{ minWidth: "150px" }}/>
                            ):(
                                <Tab label="Datos" {...a11yProps(1)} sx={{ minWidth: "150px" }}/>
                            )}
                            <Tab label="Consultas" {...a11yProps(2)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Tareas" {...a11yProps(3)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Historico" {...a11yProps(4)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Recordatorio" {...a11yProps(5)} sx={{ minWidth: "150px" }}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TabCuentaTicket
                            tickets={tickets}
                            onGuardar={onGuardar}
                            servicioNewTk={{
                                nombreCompleto: infoCliente?.nombre,
                                cuentaId: infoCliente?.cuenta,
                                cuentaContratoId: id
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {extensiones && Object.keys(extensiones).length > 0 ? (
                            Object.entries(extensiones).map((([keys,info])=>(
                                <div className='border bg-gray col-11 ml-5' style={{borderRadius: 10,margin:5}}>
                                    <CardCuentaProducto
                                        item={info}
                                        key={keys}
                                        numero={keys}
                                        onGuardar={actualizar}
                                    />
                                </div>
                            )))
                        ):(
                            <Card sx={{background: '#fff !important',mb: 3,border:1, borderColor: '#000',borderRadius: 4}}>
                                <Box sx={{ background: trato?.estado_direntrega === 'I' ? '#fce7e7' : '#e6f8f2', p: 2, display: 'flex',alignItems: 'center'}}>
                                    <Grid container>
                                        <Grid item xs={12} md={10}>
                                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mr:2}} variant='h5'>{`${trato?.contrato} - ${trato?.nombre}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <ButtonBasic
                                                textButton={trato?.estado_direntrega === 'I' ? 'DAR DE ALTA' : 'DAR DE BAJA'}
                                                variant='wk'
                                                color={trato?.estado_direntrega === 'I' ? 'nextStep' : 'error'}
                                                sx={{border: 1, borderColor: '#fff', minWidth: 150,borderRadius: '10px !important'}}
                                                onClick={()=>onOpenModalAltaBajaDirEntrega(trato?.contrato)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{trato?.direccion_servicio || ''}</Box></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>CCPP:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{`${trato?.codpostal || ''} - ${trato?.localidad || ''}`}</Box></Typography>
                                        </Box>
                                    </Grid>
                                    {trato?.latitud && (
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{`${trato?.latitud}, ${trato?.longitud}`}</Box></Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Segmento:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{trato?.segmento || ''}</Box></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Estado:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{trato?.estado_direntrega === 'A' ? 'Activo' : 'Inactivo' || ''}</Box></Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TabCuentaConsultas
                            consultas={consultas}
                            actualizar={actualizar}
                            actualizarRecordatorios={actualizarRecordatorios}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TabCuentaTareas tareas={tareas} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <TabCuentaHistorico
                            historico={logs}
                            infoCliente={{
                                cuentaId: infoCliente?.cuenta || trato?.cuenta,
                                hiddenSelect: true,
                                servicioId: id
                            }}
                            onGuardar={actualizarHistorico}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <TabCuentaRecodatorios
                            recordatoriosAll={recordatorios}
                            cuenta={infoCliente?.cuenta}
                            servicioId={infoCliente?.numero}
                            nombreCompleto={infoCliente?.cliente || infoCliente?.nombre}
                            onGuardar={actualizarRecordatorios}
                        />
                    </TabPanel>
                </Card>
            </Grid>
        </Grid>
        </Box>
    )
}

export default ServicioCuentaVerScreen