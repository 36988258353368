import axios from 'axios';
const base = 'infcontacto';
function getAll(query = null) {
    return axios.get('infcontacto/info', {
        params: query
    });
}

const InfContactoService = {
    getAll
};
export default InfContactoService;
