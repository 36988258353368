import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputSearchIdCompleto, SelectIvaSoftland, SelectLocalidadesSoftland } from '../../components';
import ApiSoftland from '../../services/apis.softland.service';
import {toast} from 'react-toastify';
import AuxiliarFunction from '../../function/AuxiliarFunction';

const ModalNoVeoServicio = ({show, hideModal}) => {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [descripcion, setDescripcion] = useState('');
    const [direccion, setDireccion] = useState('');
    const [codIva, setCodIva] = useState(null);
    const [codPostal, setCodPostal] = useState(null);
    const [servicioId, setServicioId] = useState(null);
    const [latitud, setLatitud] = useState(null);
    const [longitud, setLongitud] = useState(null);

    const onSubmit = async() => {
        const form = {
            contrato: servicioId,
            direccion: direccion,
            cod_iva: codIva,
            cod_postal: codPostal
        };
        setIsLoading(true);
        if(latitud?.length > 0 || longitud?.length > 0){
            const validCoord = `${latitud?.trim()}, ${longitud?.trim()}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                form.latitud = latitud?.trim();
                form.longitud = longitud?.trim();
                await ApiSoftland.createDirEntExistCompleto(form).then((resp)=>{
                    toast.success(resp?.msg || 'Se realizo la carga correctamente.', {
                        position: "top-right",
                        autoClose: 4500,
                        pauseOnHover: false,
                    });
                    setIsLoading(false);
                    handleClose()
                }).catch(()=>{
                    setIsLoading(false);
                })
            }else{
                toast.error('Las coordenadas no son validas.', {
                    position: "top-right",
                    autoClose: 4500,
                    pauseOnHover: false,
                });
            }
        }else{
            await ApiSoftland.createDirEntExistCompleto(form).then((resp)=>{
                toast.success(resp?.msg || 'Se realizo la carga correctamente.', {
                    position: "top-right",
                    autoClose: 4500,
                    pauseOnHover: false,
                });
                setIsLoading(false);
                handleClose()
            }).catch(()=>{
                setIsLoading(false);
            })
        }
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        ¡NO ENCUENTRO UN SERVICIO!
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Buscar Servicio</Typography>
                            <InputSearchIdCompleto
                                onClick={(e)=>{
                                    setServicioId(e?.contrato)
                                    setDescripcion(e?.nombre)
                                    setDireccion(e?.direccion_servicio || e?.direccion_cliente || '')
                                    setCodIva(e?.cod_iva || 'C' )
                                    setCodPostal(e?.codpostal || '4400' )
                                    setLatitud(e?.latitud || '')
                                    setLongitud(e?.longitud || '')
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={descripcion}
                                onChange={(e)=>console.log(e)}
                                inputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Direccion</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={direccion}
                                onChange={(e)=>setDireccion(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Localidad</Typography>
                            <SelectLocalidadesSoftland
                                value={codPostal}
                                onChange={(e)=>setCodPostal(e?.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>IVA</Typography>
                            <SelectIvaSoftland
                                value={codIva}
                                onChange={(e)=>setCodIva(e?.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Latitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={latitud}
                                onChange={(e)=>setLatitud(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Longitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={longitud}
                                onChange={(e)=>setLongitud(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!servicioId}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNoVeoServicio