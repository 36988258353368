import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, InputSearchDirEntregaMulti, SelectTicketXServicio} from '../../components';
import PreTicketsService from '../../services/preTickets.service';
import { useNavigate } from 'react-router-dom';

const ModalVincularPreTktoTK = ({show, hideModal, item ,onGuardar,hideModalPtk}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ticketId, setTicketId] = useState(null);
    const [contactoId, setContactoId] = useState(null);
    const [servicioId, setServicioId] = useState(null);
    const handleClose = () => hideModal();
    const navigate = useNavigate();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            preTicketId: item?.id_ptke,
            ticketId: ticketId,
            ...contactoId
        }
        await PreTicketsService.vincularPreTkToServicio(form).then(()=>{
            if(onGuardar){
                onGuardar()
            }
            setIsLoading(false);
            handleClose();
            if(hideModalPtk){
                hideModalPtk();
            }
            navigate(`/ticket/${ticketId}/nubicom`);
        })
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Vincular pre-ticket en un ticket
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputSearchDirEntregaMulti
                                onClick={(e)=>{
                                    if(e?.softland){
                                        setServicioId({tipo: 'servicio',numero: e?.contrato});
                                        setContactoId({ cuentaId: e?.cuenta })
                                    }else{
                                        setServicioId({tipo: 'pre-servicio',numero: e?.id_t});
                                        if(e?.cuentaId_t){
                                            setContactoId({ cuentaId: e?.cuentaId_t })
                                        }else{
                                            setContactoId({ contactoId: e?.contactoId_t})
                                        }
                                    }
                                    setTicketId(null);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectTicketXServicio
                                filtro={servicioId}
                                onChange={(e)=>setTicketId(e?.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={!ticketId || !item?.id_ptke}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalVincularPreTktoTK