import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

const DialogTitleDefault = ({ children, onClose, ...other }) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2,textAlign:'center' }} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}
export default DialogTitleDefault