import { Badge, Box, Card, Fab, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import {
    CardDashboardTotal,
    CardEstadoTicket,
    CardTotalEstadoTk,
    DateRange } from "../../components";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TablaTicketService from "../../services/tablaTicket.service";
import FechaFunction from "../../function/FechaFunction";
import { useNavigate } from "react-router-dom";
import { showModal } from "react-redux-modal-provider";
import TabTicketsScreen from "./TabTicketsScreen";

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{maxHeight:'70vh', overflow: 'auto'}}
            className='side'
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AnaliticsGralScreen = () => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        document.title = `Analitica - General`;
    }, [])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={1}>
                <Grid item xs={12}>
                    <Typography className="text-gray-900 font-bold" variant="h3">Analítica General</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{background: '#fff !important',p:2,borderRadius:4}}>
                        <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                sx={{
                                    '& .MuiTabs-indicator':{
                                        background: '#0364ff'
                                    },
                                    '& .MuiButtonBase-root':{
                                    color: '#6b788e',
                                    fontWeight: 'bold'
                                    },
                                    '& .MuiButtonBase-root.Mui-selected':{
                                        color: '#0364ff'
                                    }
                                }}
                            >
                                <Tab label="Tickets" {...a11yProps(0)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                                <Tab label="Consultas" {...a11yProps(1)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                                <Tab label="Pre-Servicios" {...a11yProps(2)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <TabTicketsScreen
                            />
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AnaliticsGralScreen;