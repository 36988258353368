import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LugaresService from '../../services/lugares.service'
import MotivoBajaTkService from '../../services/motivoBajaTk.service';
import MotivoTicketService from '../../services/motivoTicket.service';
import NivelAdmTkService from '../../services/nivelAdmTicket.service';
import SubTipoTicketService from '../../services/subTipoTicket.service';
import TipoTicketService from '../../services/tipoTicket.service';
import TratoService from '../../services/tratos.service';
import SubMotivoTicketService from '../../services/subMotivoTicket.service';
import UserService from '../../services/personas.service';
import ActionsCvvService from '../../services/actionsCvv.service';
import HashTagService from '../../services/hashTag.service';
import NotifyService from '../../services/notify.service';

export const actualizarLugares = createAsyncThunk(
    "default/lugares", async () => LugaresService.getAll()
);
export const actualizarTratosCam = createAsyncThunk(
    "default/tratos", async () => TratoService.getAll({type: 'cvv'})
);

export const actualizarMotivoTK = createAsyncThunk(
    "default/motivoTK", async () => MotivoTicketService.getAll({type: 'multi'})
);

export const actualizarSubMotivoTK = createAsyncThunk(
    "default/subMotivoTK", async () => SubMotivoTicketService.getAll()
);

export const actualizaTipoTK = createAsyncThunk(
    "default/tipoTK", async () => TipoTicketService.getAll()
);

export const actualizaSubTipoTK = createAsyncThunk(
    "default/subTipoTK", async () => SubTipoTicketService.getAll()
);

export const actualizarNivelAdmTK = createAsyncThunk(
    "default/nivelAdmTk", async () => NivelAdmTkService.getAll()
);

export const actualizarMotivoBajaTK = createAsyncThunk(
    "default/motivoBajaTK", async () => MotivoBajaTkService.getAll()
);
export const actualizarUsuariosSelect = createAsyncThunk(
    "default/usuarioSelect", async () => UserService.getAll({type : 'etiqueta'})
);
export const actualizarMentions = createAsyncThunk(
    "default/mentions", async () => NotifyService.getAll({type : 'mention'})
);
export const actualizarNotifiTicket = createAsyncThunk(
    "default/notifitk", async () => NotifyService.getAll({type : 'ticket'})
);
export const actualizarNotifyGral = createAsyncThunk(
    "default/notifygral", async () => NotifyService.getAll({type : 'gral'})
);
export const actualizarNotifyTk911 = createAsyncThunk(
    "default/notifitk911", async () => ActionsCvvService.obtenerNotifyTk911()
);
export const actualizarNotifiCom911 = createAsyncThunk(
    "default/notificom911", async () => ActionsCvvService.obtenerNotifyCom911()
);
export const actualizarHashtags = createAsyncThunk(
    "default/hashtags", async () => HashTagService.getAll({type : 'rich'})
);
export const defaultSlice = createSlice({
    name: 'default',
    initialState: {
        lugares: [],
        tratoscam: [],
        motivoTk: [],
        subMotivoTk:[],
        tipoTk: [],
        subTipoTk: [],
        nivelAdmTk: [],
        motivoBajaTk: [],
        mentions: [],
        notifiTk: [],
        notifyGral: [],
        usuarioSelect: [],
        notifitk911: [],
        notificom911: [],
        hashtags: [],
    },
    reducers: {
    },
    extraReducers: (builder) => {

        builder.addCase(actualizarLugares.fulfilled, (state, { payload }) => {
            state.lugares = payload;
        });
        builder.addCase(actualizarTratosCam.fulfilled, (state, { payload }) => {
            state.tratoscam = payload;
        });
        builder.addCase(actualizarMotivoTK.fulfilled, (state, { payload }) => {
            state.motivoTk = payload;
        });
        builder.addCase(actualizarSubMotivoTK.fulfilled, (state, { payload }) => {
            state.subMotivoTk = payload;
        });
        builder.addCase(actualizaTipoTK.fulfilled, (state, { payload }) => {
            state.tipoTk = payload;
        });
        builder.addCase(actualizaSubTipoTK.fulfilled, (state, { payload }) => {
            state.subTipoTk = payload;
        });
        builder.addCase(actualizarNivelAdmTK.fulfilled, (state, { payload }) => {
            state.nivelAdmTk = payload;
        });
        builder.addCase(actualizarMotivoBajaTK.fulfilled, (state, { payload }) => {
            state.motivoBajaTk = payload;
        });
        builder.addCase(actualizarUsuariosSelect.fulfilled, (state, { payload }) => {
            state.usuarioSelect = payload;
        });
        builder.addCase(actualizarMentions.fulfilled, (state, { payload }) => {
            state.mentions = payload;
        });
        builder.addCase(actualizarNotifiTicket.fulfilled, (state, { payload }) => {
            state.notifiTk = payload;
        });
        builder.addCase(actualizarNotifyGral.fulfilled, (state, { payload }) => {
            state.notifyGral = payload;
        });
        builder.addCase(actualizarNotifyTk911.fulfilled, (state, { payload }) => {
            state.notifitk911 = payload;
        });
        builder.addCase(actualizarNotifiCom911.fulfilled, (state, { payload }) => {
            state.notificom911 = payload;
        });
        builder.addCase(actualizarHashtags.fulfilled, (state, { payload }) => {
            state.hashtags = payload;
        });
    }
});


export default defaultSlice.reducer
