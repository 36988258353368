import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, InputSearchDirEntregaMulti} from '../../components';

const ModalDirEntregaMulti = ({show, hideModal, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [contactoId, setContactoId] = useState(null);
    const [servicioId, setServicioId] = useState(null);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {...contactoId, ...servicioId}
        onGuardar(form)
        setIsLoading(false)
        handleClose()
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Seleccione el servicio
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputSearchDirEntregaMulti
                                onClick={(e)=>{
                                    if(e?.softland){
                                        setServicioId({cuentaContratoId_txt: e?.contrato})
                                        setContactoId({ cuentaId: e?.cuenta,type: 'nubicom',nombre: e?.tituloCard })
                                    }else{
                                        setServicioId({id_t: e?.id_t})
                                        if(e?.cuentaId_t){
                                            setContactoId({ cuentaId: e?.cuentaId_t,type: 'nubicom',nombre: e?.tituloCard })
                                        }else{
                                            setContactoId({ contactoId: e?.contactoId_t,type: 'nubicom',nombre: e?.tituloCard})
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalDirEntregaMulti