import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { SelectArtFallaTk, SelectProblematicaTk, SelectSolucionTecTk, ButtonBasic, SelectLineaVista } from '../../components';
import TicketService from '../../services/tickets.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';


const ModalTicketDetalleAdicional = ({show, hideModal, ticket, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [articuloFallaId, setArticuloFallaId] = useState(ticket?.articuloFallaId_tk || null);
    const [problematicaTkId, setProblematicaTkId] = useState(ticket?.problematicaTkId_tk || null);
    const [solucionTecTkId, setSolucionTecTkId] = useState(ticket?.solucionTecTkId_tk || null);
    const [derivoAsis, setDerivoAsis] = useState(ticket?.derivoAsis_tk === 'SI' ? true : false);
    const [router, setRouter] = useState(ticket?.router_tk === 'CON ROUTER' ? true : false);
    const [lineaDeVista, setLineaDeVista] = useState(ticket?.lineaVista_tk || null);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            id_tk: ticket?.id_tk,
            articuloFallaId_tk: articuloFallaId,
            problematicaTkId_tk: problematicaTkId,
            solucionTecTkId_tk: solucionTecTkId,
            derivoAsis_tk: derivoAsis ? 'SI' : 'NO',
            router_tk: router ? 'CON ROUTER' : 'SIN ROUTER',
            lineaVista_tk: lineaDeVista,
        };
        await TicketService.detalleAdicionalTicket(form).then(()=>{
            if(onGuardar){
                onGuardar();
            }
            setIsLoading(false)
            handleClose();
        }).catch(()=>{
            setIsLoading(false)
        })
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Ticket: Detalles Adicionales
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Segmento Detalle Adicional</Typography>
                            <SelectArtFallaTk
                                value={articuloFallaId}
                                onChange={(e)=> setArticuloFallaId(e?.value || null) }
                                isClearable
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Problematica</Typography>
                            <SelectProblematicaTk
                                isMulti
                                segDetAdId={articuloFallaId}
                                value={problematicaTkId}
                                onChange={(opt)=>setProblematicaTkId(opt?.map(op=>(op?.value)))}
                                isClearable
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Solucion Tecnica</Typography>
                            <SelectSolucionTecTk
                                isMulti
                                segDetAdId={articuloFallaId}
                                value={solucionTecTkId}
                                onChange={(opt)=>setSolucionTecTkId(opt?.map(op=>(op?.value)))}
                                isClearable
                            />
                        </Grid>
                        {(ticket?.motivo_tk === 40 || ticket?.motivo_tk === 36 || ticket?.motivo_tk === 7) && (
                            <Grid item xs={12}>
                                <Typography sx={{color: '#000'}}>Resultado linea de vista:</Typography>
                                <SelectLineaVista
                                    value={lineaDeVista}
                                    onChange={(e)=>setLineaDeVista(e?.value || null)}
                                    isClearable
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} ml={-2}>
                            <FormControlLabel
                                labelPlacement='start'
                                control={
                                    <Checkbox
                                        onClick={()=>setDerivoAsis(!derivoAsis)}
                                        checked={derivoAsis}
                                        sx={{
                                            color: '#0247B5',
                                            ml: 2,
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="¿Derivo en asistencia?"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                            />
                        </Grid>
                        <Grid item xs={12} ml={-2}>
                            <FormControlLabel
                                labelPlacement='start'
                                control={
                                    <Checkbox
                                        onClick={()=>setRouter(!router)}
                                        checked={router}
                                        sx={{
                                            color: '#0247B5',
                                            ml: 2,
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                label="¿Router?"
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalTicketDetalleAdicional