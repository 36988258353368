import { useEffect, useMemo, useState } from 'react'
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { ButtonBasic } from '../../../components'
import TratoService from '../../../services/tratos.service';

const FormPaso4Screen = ({
  contacto,
  contrato,
  previousStep,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [itemCuadrillas, setItemCuadrillas] = useState([])
  const obtenerCuadrillas = async() => {
    await TratoService.obtenerCuadrillasDisponibles().then((resp)=>{
      setItemCuadrillas(resp)
    })
  }
  useEffect(() => {
    obtenerCuadrillas()
  }, [])

  const onSubmit = async() => {

  };


  return (
    <Box sx={{overflow: 'overlay',height: '76vh', marginTop:3}} className='side'>
        <Grid container mt={2} p={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
          <Grid item xs={12} md={8}>
            <Card sx={{background: '#fff !important'}}>
              <Typography sx={{color: '#000'}}>Calendario</Typography>
            </Card>
          </Grid>
        </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15} marginTop={2}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          color='prevStep'
          isLoading={isLoading}
        />
        <ButtonBasic
          textButton='Siguiente'
          onClick={()=>onSubmit()}
          variant='wk'
          color='nextStep'
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso4Screen);