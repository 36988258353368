import { useState, useEffect } from "react";
import { Box, Card, Grid, Typography } from "@mui/material"
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ContactosAction from '../../../store/actions/contactos';
import {ButtonBasic, CardItemServicios} from "../../../components";
import ApiSoftland from "../../../services/apis.softland.service";
import usePermisos from "../../../hooks/usePermisos";

const FormPaso3Screen = ({
  contacto,
  contrato,
  nextStep,
  previousStep,
  iniciarContrato,
}) => {
  const permisos = usePermisos();
  const [servicios, setServicios] = useState([]);
  const [planIdArr, setPlanIdArr] = useState(null);

  const filtrarPorLetraF = (arrayProductos,comienzaConF = false) => {
    return arrayProductos.filter(prod => {
      const nombreProd = prod?.nombre?.toLowerCase();
      if (comienzaConF) {
        return nombreProd.startsWith('f') || nombreProd.includes('fo');
      } else {
        return !nombreProd.startsWith('f') && !nombreProd.includes('fo');
      }
    });
  }
  const traerProductos = async(categoria) =>{
    if(contacto?.tipoMapa === 'fibra'){
      await ApiSoftland.getProductos('SFO   ').then((resp)=>{
        if(resp?.length > 0){
          setServicios(resp)
        }else{
          setServicios([])
        }
      })
    }else{
      await ApiSoftland.getProductos(categoria).then((resp)=>{
        if(resp?.length > 0){
          const filtro = filtrarPorLetraF(resp,false);
          /* const filtrarServicioS = filtro?.filter((e)=>e?.nombre !== 'Plan HOGAR S - 7 Mb')
          setServicios(filtrarServicioS); */
          setServicios(filtro);
        }else{
          setServicios([]);
        }
      })
    }
  }
  useEffect(() => {
    if(contacto?.categoria_cxe){
        let categoria = '';
        switch (contacto?.categoria_cxe) {
            case 'PYM':
                categoria= 'PY'
                break;
            case 'GOB':
                categoria= 'GO'
                break;
            case 'VIP':
            case 'MAS':
                categoria= 'HO'
                break;
            case 'COR':
                categoria= 'CORP'
                break;
            default:
                break;
        }
        traerProductos(categoria)
    }
    setPlanIdArr(null)
  }, [contacto])

  const onSubmit = () => {
    iniciarContrato({
      ...contrato,
      servicios: [{
        servicioIdSoftland_ds: planIdArr?.cod_producto,
        tipoServicio_s:  planIdArr?.tipo,
        nombre_s: planIdArr?.nombre,
        importe_s: planIdArr?.precio || 0,
        anchoBajada_s: planIdArr?.bajada || 0,
        anchoSubida_s: planIdArr?.subida || 0,
        tipoAnchoBajada_s: planIdArr?.unidad_bajada || '',
        tipoAnchoSubida_s: planIdArr?.unidad_subida || '',
        moneda_s:  planIdArr?.moneda || 'USD'
      },
      {
        "id": uuidv4(),
        "tipo_descripcion": "SERVICOS ASOCIADOS A  LA INSTALACION",
        "nombre": "Bonificacion Adhesión a débito automático VISA - MAS",
        "nombre_s": "Bonificacion Adhesión a débito automático VISA - MAS",
        "importe_s": ((planIdArr?.precio || 0) * -1).toFixed(6),
        "moneda_s": "USD",
        "cantidad_s": "0.1000",
        "tipoServicio_s": "SAI   ",
        "id_s": 10,
        "servicioIdSoftland_ds": "1010",
        "anchoBajada_s": "",
        "anchoSubida_s": "",
        "tipoAnchoBajada_s": "",
        "tipoAnchoSubida_s": "",
        "obs_s": "asd",
        "cantMeses_ds": 12,
        "vigencia_ds": "S",
        "nivelBonificacion": "bonificacion_nivel1"
      }],
      precio: planIdArr?.precio || 0,
      requiereAutorizacion: 'NO'
    })
    nextStep();
  };
  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Grid xs={12} md={12} lg={6.6} ml={1} mb={5}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12}>
                <Box display='flex' pl={2} pt={1}>
                  <ShoppingBagOutlinedIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Servicios</Typography>
                </Box>
                <Box display='flex' pl={2} pt={1}>
                  <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                    {`Seleccionar un plan -  Cobertura máxima ${contacto?.cobertura || ''} Mb`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {servicios?.length > 0 && (
                  servicios.map((serv)=>(
                    <CardItemServicios
                      key={serv.cod_producto}
                      item={serv}
                      servicioSelect={planIdArr}
                      setServicioSelect={setPlanIdArr}
                      coberturaMaxima={contacto?.cobertura}
                      permisoEsp={permisos['sinCobertura.nubicom']}
                    />
                  ))
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15} marginTop={2}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          color='prevStep'
        />
        <ButtonBasic
          textButton='Continuar'
          iconEnd={<ArrowForwardIcon />}
          onClick={()=>onSubmit()}
          variant='wk'
          color='nextStep'
          isDisabled={!planIdArr}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso3Screen);