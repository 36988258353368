import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, SelectNodosMikrotikV2, SelectPanelesMikrotikV2 } from '../../components';
import MikrotikV2Service from '../../services/mikrotikV2.service';

const ModalTicketNodoPanel = ({show, hideModal, ticket, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [panelName, setPanelName] = useState(ticket?.panelName_tk || null);
    const [nodoName, setNodoName] = useState(ticket?.nodoName_tk || null);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            id_tk: ticket?.id_tk
        };
        if(nodoName){
            form.nodoName_tk = nodoName;
            if(panelName){
                form.panelName_tk = panelName;
            }
        }
        await MikrotikV2Service.cargarNodoPanelTicket(form).then(()=>{
            if(onGuardar){
                onGuardar();
            }
            setIsLoading(false)
            handleClose();
        }).catch(()=>{
            setIsLoading(false)
        })
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Ticket Tecnico: Nodos y Paneles
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nodo</Typography>
                            <SelectNodosMikrotikV2
                                value={nodoName}
                                onChange={(e)=>{
                                    setNodoName(e.value)
                                    setPanelName(null)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Paneles</Typography>
                            <SelectPanelesMikrotikV2
                                value={panelName}
                                onChange={(e)=>setPanelName(e.value)}
                                isDisabled={!nodoName}
                                nodoName={nodoName}
                                modalTicket
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalTicketNodoPanel