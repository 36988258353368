import {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectCanalesCalendar, SelectLugaresCalendar } from '../../components';
import Modal from '@mui/material/Modal';
import CalendarioV2Service from '../../services/calendarioV2.service';
import CloseIcon from '@mui/icons-material/Close';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import {toast} from 'react-toastify';

function ModalCrearTarea({show, hideModal, ticket,contratoSoft,tareas, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        cuentaContactoId: {
            texto: null,
            textoErr: 'El nro de cuenta es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuentaContratoId: {
            texto: null,
            textoErr: 'El nro de contrato es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        contactoId: {
            texto: null,
            textoErr: 'El nro de contacto es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        contratoId: {
            texto: null,
            textoErr: 'El nro de contrato es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        motivoTareaId: {
            texto: null,
            textoErr: 'El motivo de tarea es obligatorio',
            estadoErr: false,
            tipo: 'string'
        },
        motivoTareaObservaciones: {
            texto: null,
            textoErr: 'La observacion del motivo de tarea es obligatoria',
            estadoErr: false,
            tipo: 'string'
        },
        tipoTarea: {
            texto: null,
            textoErr: 'El tipo de tarea es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        lugarId: {
            texto: null,
            textoErr: 'El lugar es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        contrato: {
            texto: null,
            textoErr: 'El contrato es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nuevaDireccion: {
            texto: null,
            textoErr: 'El nuevo domicilio es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nuevaCoordenadas: {
            texto: null,
            textoErr: 'Las coordenadas son requeridas',
            estadoErr: false,
            tipo: 'string'
        },
        tipoCanalId: {
            texto: null,
            textoErr: 'El canal es requerido.',
            estadoErr: false,
            tipo: 'string'
        },
    });

    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
    let formOld = formAll;
    formOld[input].estadoErr = !clear ? true : false
    setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {ticketId: ticket?.id_tk};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = [
            'tipoTarea',
            'motivoTareaId',
            'motivoTareaObservaciones',
            'lugarId',
            'tipoCanalId'
        ];
        if(ticket?.motivo_tk === 40 || ticket?.motivo_tk === 36 || ticket?.motivo_tk === 7){
            inputRequired.push('nuevaDireccion','nuevaCoordenadas');
        }
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
            propertyNames.forEach((name)=>{
            if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
                form[name] = formAll[name].texto
            }else{
                if(inputRequired.includes(name)){
                    isOk = true;
                    onChangeFormSetError(name)
                }
            }
        }))
        if(!isOk){
            if(ticket?.motivo_tk === 40 || ticket?.motivo_tk === 36 || ticket?.motivo_tk === 7){
                const coordenadasLimpias = form.nuevaCoordenadas.replace(/ /g, "");
                const valid = AuxiliarFunction.isValidCoordinates(coordenadasLimpias);
                if(valid){
                    const separador = coordenadasLimpias.split(',');
                    if(separador?.length === 2){
                        form.nuevaLat = separador[0];
                        form.nuevaLng = separador[1];
                    }
                    await CalendarioV2Service.registerTareaCalendar(form).then(()=>{
                        if(onGuardar){
                            onGuardar()
                        }
                        setIsLoading(false);
                        handleClose()
                    })
                    .catch(()=>{
                        setIsLoading(false);
                    })
                }else{
                    toast.error('Verifique las coordenadas', {
                        position: "top-right",
                        autoClose: 4500,
                        pauseOnHover: false,
                    });
                    setIsLoading(false);
                }
            }else{
                await CalendarioV2Service.registerTareaCalendar(form).then(()=>{
                    if(onGuardar){
                        onGuardar()
                    }
                    setIsLoading(false);
                    handleClose()
                })
                .catch(()=>{
                    setIsLoading(false);
                })
            }
        }else{
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(ticket){
            if(ticket?.ticketxtrato?.length > 0){
                if(ticket?.ticketxtrato[0]?.cuentaContratoId_txt){
                    onChangeForm(ticket?.ticketxtrato[0]?.cuentaContratoId_txt,'cuentaContratoId')
                    if(contratoSoft && contratoSoft?.length > 0){
                        contratoSoft?.map((contr)=>{
                            if(contr[ticket?.ticketxtrato[0]?.cuentaContratoId_txt]){
                                onChangeForm(contr[ticket?.ticketxtrato[0]?.cuentaContratoId_txt][0]?.cuenta,'cuentaContactoId')
                            }
                            return '';
                        })
                    }
                    onChangeForm(ticket?.ticketxtrato[0]?.cuentaContratoId_txt,'contrato')
                }else{
                    onChangeForm(ticket?.ticketxtrato[0]?.tratoId_txt,'contrato')
                    onChangeForm(ticket?.ticketxtrato[0]?.tratoId_txt,'contratoId')
                }

                if(ticket?.ticketxtrato[0]?.tratos?.cuentaId_t){
                    onChangeForm(ticket?.ticketxtrato[0]?.tratos?.cuentaId_t,'cuentaContactoId')
                }else if(ticket?.ticketxtrato[0]?.tratos?.contactoId_t){
                    onChangeForm(ticket?.ticketxtrato[0]?.tratos?.contactoId_t,'contactoId')
                }

                if(ticket?.ticketxtrato[0]?.tratos?.lugarId_t){
                    onChangeForm(ticket?.ticketxtrato[0]?.tratos?.lugarId_t,'lugarId')
                }
            }
            onChangeForm(ticket?.motivotk?.tipoTareaId_mt,'tipoTarea')
            onChangeForm(ticket?.motivo_tk,'motivoTareaId')
            onChangeForm(ticket?.cuerpo_tk,'motivoTareaObservaciones')
            if((ticket?.motivo_tk === 40 || ticket?.motivo_tk === 36 || ticket?.motivo_tk === 7) && Array.isArray(ticket?.comentarios) && ticket?.comentarios?.length > 0){
                const ultimoComentario = ticket.comentarios.find((com)=> com?.cuerpo_com?.includes('</strong>.</p><p><strong>NUEVO DOMICILIO:</strong> ' ))
                if(ultimoComentario){
                    const separador = ultimoComentario?.cuerpo_com.split('</strong>.</p><p><strong>NUEVO DOMICILIO:</strong> ')
                    if(separador?.length === 2){
                        const separador2 = separador[1].split('</p><p><strong>COORDENADAS:</strong>')
                        if(separador2?.length === 2){
                            onChangeForm(separador2[0],'nuevaDireccion');
                            const coordenadas = separador2[1].replace( /(<([^>]+)>)/ig, '').trim();
                            onChangeForm(coordenadas,'nuevaCoordenadas');
                        }
                    }
                }
            }
        }
    }, [ticket])
    useEffect(() => {
        if(tareas && tareas?.length > 0){
            if(tareas[0].lugarId_t){
                onChangeForm(tareas[0].lugarId_t,'lugarId')
            }
        }
    }, [tareas])
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12} p={1}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            Registrar Nueva Tarea
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider color='gray' sx={{marginBottom:2}} />
                        <Typography sx={{color: '#000'}}>Canal</Typography>
                        <SelectCanalesCalendar
                            id="canalId"
                            name="canalId"
                            value={formAll?.tipoCanalId?.texto || null}
                            isInvalid={formAll?.tipoCanalId?.estadoErr}
                            onChange={(opt)=>onChangeForm(opt.value,'tipoCanalId')}
                        />
                        {formAll.tipoCanalId.estadoErr && (
                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoCanalId.textoErr}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography sx={{color: '#000'}}>Lugar Calendar</Typography>
                        <SelectLugaresCalendar
                            id="lugarId"
                            name="lugarId"
                            value={formAll?.lugarId?.texto || null}
                            isInvalid={formAll?.lugarId?.estadoErr}
                            onChange={(opt)=>onChangeForm(opt.value,'lugarId')}
                        />
                        {formAll.lugarId.estadoErr && (
                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId.textoErr}</Typography>
                        )}
                    </Grid>
                    {ticket?.motivo_tk === 40 && (
                        <>
                            <Grid item xs={12}>
                                <Typography sx={{color: '#000'}}>Nuevo Domicilio</Typography>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    value={formAll.nuevaDireccion.texto}
                                    error={formAll.nuevaDireccion.estadoErr}
                                    helperText={formAll.nuevaDireccion.estadoErr && formAll.nuevaDireccion.textoErr}
                                    onChange={(e)=>onChangeForm(e.target.value, 'nuevaDireccion')}
                                    sx={styleInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nuevas Coordenadas</Typography>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    value={formAll.nuevaCoordenadas.texto}
                                    error={formAll.nuevaCoordenadas.estadoErr}
                                    helperText={formAll.nuevaCoordenadas.estadoErr && formAll.nuevaCoordenadas.textoErr}
                                    onChange={(e)=>onChangeForm(e.target.value, 'nuevaCoordenadas')}
                                    sx={styleInput}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <Divider color='gray' sx={{marginTop:2}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        variant='wk'
                        color='nextStep'
                        sx={{ml:1}}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalCrearTarea.propTypes = {
    show: PropTypes.bool
};

ModalCrearTarea.defaultProps = {
    show: false
};

export default ModalCrearTarea;