import axios from 'axios';

function getPaises() {
    return axios.get('soft/paises')
}

function getProvincias() {
    return axios.get('soft/provincias')
}

function getLocalidades() {
    return axios.get('soft/localidades')
}

function getZonas() {
    return axios.get('soft/zonas')
}

function getIva() {
    return axios.get('soft/iva')
}

function getVendedores() {
    return axios.get('soft/vendedores')
}

function getCobradores() {
    return axios.get('soft/cobradores')
}

function getCondicionpago() {
    return axios.get('soft/condicionpago')
}

function getListaprecios() {
    return axios.get('soft/listaprecios')
}

function getProductos(filter) {
    return axios.get(['soft/productos', filter].join('/'));
}

function getContratos(arry = null) {
    return axios.get('soft/contratos',{ headers: { arr:arry } })
}

function getContratistas() {
    return axios.get('soft/contratistas')
}

function registerDireccionEntrega(data) {
    return axios.post('soft/dirent/new', data);
}

function altaBajaDireccionEntrega(data) {
    return axios.post('soft/dirent/estado', data);
}

function checkServicioSoftland(id) {
    return axios.post(`soft/vicular/${id}`, {});
}

function updateDirEntrega(data) {
    return axios.put(`soft/dirent/${data?.contrato}`, data);
}

function createDirEntExistCompleto(data) {
    return axios.post('soft/dirent/add', data);
}

const ApiSoftland = {
    getPaises,
    getProvincias,
    getLocalidades,
    getZonas,
    getIva,
    getVendedores,
    getCobradores,
    getCondicionpago,
    getListaprecios,
    getProductos,
    getContratos,
    getContratistas,
    registerDireccionEntrega,
    altaBajaDireccionEntrega,
    checkServicioSoftland,
    updateDirEntrega,
    createDirEntExistCompleto
};
export default ApiSoftland;
