import {useState, useEffect,useMemo} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import SubTipoTicketService from '../../services/subTipoTicket.service';

const SelectSubTipoTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    tipoTicket,
    isDisabled,
    isMulti,
    emp,
    filterActivos
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const subTipoTk = useSelector(state => state.default.subTipoTk);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_sttk === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_sttk === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {
        if(emp){
            SubTipoTicketService.getAll({emp}).then((resp)=>{
                setItems(resp)
            })
        }else{
            setItems(subTipoTk);
        }
    }, [subTipoTk]);

    useEffect(() => {
        let filtros =items;
        if(tipoTicket){
            filtros = items.filter((it)=> it.tipoTicketId_sttk === tipoTicket )
        }
        if(filterActivos){
            filtros = items.filter((it)=> filterActivos.includes(it.id_sttk) )
        }
        const newOptions = filtros.map((item) => {
            return {
                value: item.id_sttk,
                label: item.nombre_sttk,
                resolucion: item.resolucion_sttk
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_sttk === va);
                if(item){
                    return {
                        label:item.nombre_sttk,
                        value:item.id_sttk,
                    };
                }
                return null
            }))
        }else{
            setSelectedValue(null);
        }
    }, [items,tipoTicket,value,filterActivos])
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectSubTipoTicket.defaultProps = {
    isClearable: false
};

export default SelectSubTipoTicket;
