import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import MotivoBajaTkService from '../../services/motivoBajaTk.service';

const SelectMotivoBajaTk = ({id, value, onChange, isInvalid, isClearable,nivelAdmTk,isDisabled,emp,isMulti}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const motivoBajaTk = useSelector(state => state.default.motivoBajaTk);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    useEffect(() => {
        if(emp){
            MotivoBajaTkService.getAll({emp}).then((resp)=>{
                setItems(resp)
            })
        }else{
            setItems(motivoBajaTk);
        }
    }, [motivoBajaTk,emp]);

    useEffect(() => {
        let filtro = items;
        if(nivelAdmTk){
            filtro = items.filter((it)=>it.nivelAdmId_mbtk === nivelAdmTk)
        }
        const newOptions = filtro.map((item) => {
            return {
                value: item.id_mbtk,
                label: item.nombre_mbtk
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_mbtk === va);
                if(item){
                    return {
                        label:item.nombre_mbtk,
                        value:item.id_mbtk,
                    };
                }
                return null
            }))
        }else{
            setSelectedValue(null);
        }
    }, [items,nivelAdmTk])
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectMotivoBajaTk.defaultProps = {
    isClearable: false
};

export default SelectMotivoBajaTk;
