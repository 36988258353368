import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import ContactosService from "../../services/contactos.service";
import CardSearchNav from "../card/CardSearchNav";

const TabContacto = ({textSearch,handleClose}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleSearchContacto = async() => {
        if(textSearch?.length >= 2){
            setIsLoading(true);
            await ContactosService.getAll({search: textSearch, typeSearch: 'soft'}).then((data)=>{
                if(data?.length > 0){
                    setOptions(
                        data?.map((item) =>{
                            if(!item?.id_c){
                                return {
                                    ...item,
                                    tituloCard: item?.nombre_completo,
                                    descripCard: `DNI/CUIT: ${item?.dni_cuit?.numero} - N° CUENTA: ${item?.cuenta}`,
                                    empresaCard: 'NUBICOM',
                                    keyCard: `${item?.cuenta}Cuenta`
                                }
                            }
                            return {
                                ...item,
                                tituloCard: item?.nombreCompleto_c,
                                descripCard: `DNI/CUIT: ${item?.documento_c || 'S/N'}`,
                                empresaCard: 'NUBICOM',
                                keyCard: `${item?.id_c}Contacto`
                            }
                        })
                    )
                }else{
                    setOptions([])
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        const delayWrite = setTimeout(() => {
            handleSearchContacto()
        }, 500)
        return () => clearTimeout(delayWrite)
    }, [textSearch])
    return (
        <Grid container>
            { !isLoading ? (
                <>
                    {options && options?.length > 0 ? (
                        options?.map((option)=>(
                            <Grid item xs={12} key={`${option?.keyCard}c`}>
                                <CardSearchNav
                                    item={option}
                                    descripcion={option?.descripCard}
                                    titulo={option?.tituloCard}
                                    key={option?.keyCard}
                                    onCLoseModal={handleClose}
                                    textSearch={textSearch}
                                />
                            </Grid>
                        ))
                    ):(
                        <Grid item xs={12} key='sinCli'>
                            <Box sx={{background: '#ebedf0',p:2,borderRadius:3}}>
                                <Typography sx={{color: '#15294b',textAlign: 'center',marginTop:2,marginBottom:2}} variant='h5'>No se encontraron resultados</Typography>
                            </Box>
                        </Grid>
                    )}
                </>
            ):(
                <Grid item xs={12} key='circletabcont' sx={{display:'flex', justifyContent:'center', alignItems: 'center', minHeight:50}}>
                    <CircularProgress size={40} sx={{color: '#0364ff'}} />
                </Grid>
            )}
        </Grid>
    )
}

export default TabContacto