import {useState, useEffect, useMemo} from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import { ButtonBasic, SelectSaiSoftlandZeta } from '../../components';
import { Box, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

function ModalSAIPrefa({show, hideModal,add,setAdd,serviciosAdd,precioPlan,tipoServicio = 'MAS'}) {
    const [isLoading, setIsLoading] = useState(false);
    const [datos, setDatos] = useState(add || {});
    const [codigo, setCodigo] = useState(false);
    const [cantidad, setCantidad] = useState(false);
    const [precio, setPrecio] = useState(false);
    const [moneda, setMoneda] = useState(false);
    const [obs, setObs] = useState('');
    const [primerBonificacion, setPrimerBonificacion] = useState(null);
    const handleClose = () => hideModal();
    const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
    ]
    const onChangeCantPrecio = (opt) =>{
        setDatos(opt)
        setPrecio(-Math.abs(precioPlan))
        setMoneda('USD')
        setCantidad(opt.porcentaje_ss)
        setCodigo(opt?.value)
    }
    const onSubmit = () => {
        if(!add?.id){
                const form = {
                    id: uuidv4(),
                    tipo_descripcion: datos?.tipoServicioDesc_ss,
                    nombre: datos.label,
                    nombre_s: datos.label,
                    importe_s: precio,
                    moneda_s: moneda,
                    cantidad_s:cantidad,
                    tipoServicio_s: datos?.tipoServicio_ss,
                    id_s: datos.value,
                    servicioIdSoftland_ds: datos.idSoftServicio_ss,
                    anchoBajada_s: '',
                    anchoSubida_s: '',
                    tipoAnchoBajada_s: '',
                    tipoAnchoSubida_s: '',
                    obs_s: obs,
                    cantMeses_ds: datos?.vigenciaMes_ss || 0,
                    vigencia_ds: 'S',
                    nivelBonificacion: datos?.nivelBonificacion_ss || 'bonificacion_nivel1'
                }
                setAdd(prev => [...prev,form]);
                handleClose();
        }else{
            const filtro = serviciosAdd.filter((serv)=> serv.id !== add.id)
            const index = serviciosAdd.findIndex((serv)=> serv.id === add.id)
            const form = {
                id: uuidv4(),
                tipo_descripcion: datos?.tipoServicioDesc_ss || datos?.tipo_descripcion,
                nombre: datos.label || datos?.nombre || datos?.nombre_s,
                nombre_s: datos.label || datos?.nombre_s,
                importe_s: precio,
                moneda_s: moneda,
                cantidad_s:cantidad,
                tipoServicio_s: datos.tipoServicio_ss || datos?.tipoServicio_s,
                id_s: datos?.value || datos?.id_s,
                servicioIdSoftland_ds: datos?.idSoftServicio_ss || datos?.servicioIdSoftland_ds,
                anchoBajada_s: '',
                anchoSubida_s: '',
                tipoAnchoBajada_s: '',
                tipoAnchoSubida_s: '',
                obs_s: obs,
                cantMeses_ds: datos?.vigenciaMes_ss || datos?.cantMeses_ds || 0,
                vigencia_ds: 'S',
            }
            const result = insert(filtro,index,form)
            setAdd(result);
            handleClose();
        }
    };
    useEffect(() => {
        if(add?.id){
            setPrecio(add.importe_s)
            setMoneda(add.moneda_s)
            setCantidad(add.cantidad_s)
            setCodigo(add.id_s)
            setObs(add.obs_s)
            setDatos(add)
        }
    }, []);
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), [])
    useEffect(() => {
        if(serviciosAdd?.length > 0){
            setPrimerBonificacion(serviciosAdd[0])
        }
    }, [serviciosAdd])

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {add?.id ? 'Editar servicio adicional' : 'Agregar servicio adicional'}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 1}} />
                <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Box sx={{width: '100%'}}>
                        <Typography sx={{color: '#000'}}>Bonificacion</Typography>
                        <SelectSaiSoftlandZeta
                            id='producto'
                            value={codigo}
                            addSAI
                            type={tipoServicio}
                            serviciosAdd={serviciosAdd}
                            primerBonificacion={primerBonificacion}
                            onChange={(opt)=>onChangeCantPrecio(opt)}
                        />
                    </Box>
                    <Typography sx={{color: '#000'}}>Observaciones</Typography>
                    <TextField
                        value={obs}
                        autoComplete='off'
                        fullWidth
                        onChange={(e)=>setObs(e.target.value)}
                        sx={styleInput}
                    />
                </Box>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        variant='wk'
                        color='nextStep'
                        isDisabled={!codigo}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalSAIPrefa;