import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react'
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player';
import DownloadFile from '../../services/downloadFile.service';
import AuxiliarFunction from '../../function/AuxiliarFunction';

const CardViewFilePreTicket = ({arrFiles}) => {
    const descargarFile = async(item) =>{
        await AuxiliarFunction.downloadFile(DownloadFile.fileNubi({id:`pretickets/${item?.path_attptke}`}),`${item?.nombre_attptke || 'no-name'}`)
    }
    const token = localStorage.getItem('token');
    return (
        <Grid container sx={{display: 'flex', flexDirection: 'row'}}>
            { arrFiles?.map((img)=>{
                const urlNubi = `https://zeta.strongsystems.com.ar:8000/api/img?url=pretickets/${img?.pathFile || img?.path_attptke}`
                const tipe = img?.path_attptke?.split('.');
                if(tipe[tipe?.length - 1] === 'jpg' || tipe[tipe?.length - 1] === 'png' || tipe[tipe?.length - 1] === 'jpeg' || tipe[tipe?.length - 1] === 'svg' || tipe[tipe?.length - 1] === 'x-png'){
                    return (
                        <Grid item xs={6} sm={6} md={4} className='ml-2 mt-1 cursor-pointer'>
                            <PhotoConsumer
                                key={`IMG${img?.nombre_attptke}`}
                                src={`${urlNubi}&token=${token}`}
                                intro={img?.nombre_attptke}
                            >
                                <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                    <img
                                        src={`${urlNubi}&token=${token}`}
                                        alt={`${urlNubi}&token=${token}`}
                                        style={{width:50,height:50}}
                                    />
                                    <Typography className="text-center text-gray-600 ml-1" style={{color: '#000',fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                        {img?.nombre_attptke}
                                    </Typography>
                                </Card>
                            </PhotoConsumer>
                        </Grid>
                    )
                }
                if(tipe[tipe?.length - 1] === 'wmv' || tipe[tipe?.length - 1] === 'mp4' || tipe[tipe?.length - 1] === 'avi' || tipe[tipe?.length - 1] === 'mov'){
                    return(
                        <Grid item xs={12} sm={6} md={4} lg={4} className='ml-2 mt-1 cursor-pointer'>
                            <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                <Box>
                                    <ReactPlayer
                                        key={`Video${img?.nombre_attptke}`}
                                        url={`${urlNubi}&token=${token}`}
                                        width='155px'
                                        height='60px'
                                        controls
                                    />
                                </Box>
                                <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis',color: '#000'}}>
                                    {img?.nombre_attptke}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                }
                if(tipe[tipe?.length - 1] === 'pdf'){
                    return(
                        <Grid item xs={12} sm={6} md={4} lg={4} className='ml-2 mt-1 cursor-pointer'>
                            <Card
                                sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                aria-hidden="true"
                                onClick={()=>descargarFile(img)}
                            >
                                <img
                                    src='/img/pdf.png'
                                    alt='pdf.png'
                                    style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                />
                                <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis',color: '#000',width: '50%'}}>
                                    {img?.nombre_attptke}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                }
                if(tipe[tipe?.length - 1] === 'doc' || tipe[tipe?.length - 1] === 'docx'){
                    return(
                        <Grid item xs={12} sm={6} md={4} className='ml-2 mt-1 cursor-pointer'>
                            <Card
                                sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                aria-hidden="true"
                                onClick={()=>descargarFile(img)}
                            >
                                <img
                                    src='/img/doc.png'
                                    alt='doc.png'
                                    style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                />
                                <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis',color: '#000'}}>
                                    {img?.nombre_attptke}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                }
                if(tipe[tipe?.length - 1] === 'xls' || tipe[tipe?.length - 1] === 'xlsx'){
                    return(
                        <Grid item xs={12} sm={6} md={4} className='ml-2 mt-1 cursor-pointer'>
                            <Card
                                sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                aria-hidden="true"
                                onClick={()=>descargarFile(img)}
                            >
                                <img
                                    src='/img/xls.png'
                                    alt='xls.png'
                                    style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                />
                                <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis',color: '#000'}}>
                                    {img?.nombre_attptke}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                }
                if(tipe[tipe?.length - 1] === 'txt'){
                    return(
                        <Grid item xs={12} sm={6} md={4} className='ml-2 mt-1 cursor-pointer'>
                            <Card
                                sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                aria-hidden="true"
                                onClick={()=>descargarFile(img)}
                            >
                                <img
                                    src='/img/txt.png'
                                    alt='txt.png'
                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                />
                                <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis',color: '#000'}}>
                                    {img?.nombre_attptke}
                                </Typography>
                            </Card>
                        </Grid>
                    )
                }
                return ''
            })}
        </Grid>
    )
}

export default CardViewFilePreTicket
