import {useState,useContext, useEffect} from 'react';
import Compressor from 'compressorjs';
import { Box, Card, Checkbox, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { SocketContext } from '../../context/SocketContext';
import TratoService from '../../services/tratos.service';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic,CardUploadFile,DateSingle, InputDropZone, InputDropZoneComment, InputEditQuill, InputEditQuillSinEtiqueta, SelectEstadoNoLeInteresaPreServicio, SelectEstadoPreServicio, SelectEstadoReprogramar, SelectInsertEmail, SelectSubEstadoEntidadExterna, SelectSubEstadoInstCancelada, SelectSubEstadoNapNoDisponible } from '../../components';
import FechaFunction from '../../function/FechaFunction';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ModalConfirmInstalacion({
    show,
    hideModal,
    ticketId,
    tratoId,
    onGuardar,
    nombreCliente,
    trato
}) {
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [commentTicket, setCommentTicket] = useState(ticketId);
    const [asigTk, setAsigTk] = useState(ticketId);
    const [fecha, setFecha] = useState(FechaFunction.format(new Date()));
    const [otImg, setOtImg] = useState(null);
    const [files, setFiles] = useState([]);
    const [filesCompress, setFilesCompress] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png'
    const [estadoSelect, setEstadoSelect] = useState(null);
    const [subEstado, setSubEstado] = useState(null);
    const [tecnologiaPrefa, setTecnologiaPrefa] = useState(null);
    const [asunto, setAsunto] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailDestino, setEmailDestino] = useState([]);
    const [cuerpoCorreo, setCuerpoCorreo] = useState('');
    const [cuerpoDA, setCuerpoDA] = useState('');
    const [value, setValue] = useState(0);
    const [infoInst, setInfoInst] = useState(null);

    const handleClose = () => hideModal();
    const { sockets } = useContext( SocketContext );
    const onCompressImg = async(imgx,ot) =>{
        const nombreCortado = imgx.name.split('.');
        let extension = nombreCortado[ nombreCortado.length - 1 ];
        extension = extension.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx.id
                    result.preview=imgx.preview
                    setIsLoading(false);
                    if(ot){
                        setOtImg(result)
                    }else{
                        setFilesCompress(old => [...old,result]);
                    }
                }
            })
        }
    }


    const onSubmit = async() =>{
        if(value === 0){
            const form = {
                sendEmail,
                commentTicket,
                asigTk,
                fecha,
                ticketId,
                id_t:tratoId,
                otImg,
                filesCompress
            }
            setIsLoading(true)
            await TratoService.confirmarInstalacionNubi(form).then(()=>{
                hideModal();
                if(ticketId){
                    sockets?.emit( 'ticketNubiRefresh', `nubicom${ticketId}` );
                }
                if(onGuardar){
                    onGuardar();
                }
            })
            .catch((err)=>{
                setIsLoading(false)
            })
        }else if(value === 1){
            setIsLoading(true);
            const formV1 = {
                ticketId: ticketId,
                tratoId: tratoId,
                estadoSelect,
                subEstado,

                asunto: asunto,
                nombreCliente: nombreCliente,
                cuerpoCorreo: cuerpoCorreo,

                ticketIdDA: infoInst?.ticketIdDA,
                cuerpoDA: cuerpoDA,
                filesCompress
            }
            if(emailDestino?.length > 0){
                let stringDestino = ''
                emailDestino?.forEach((emailDes,index)=>{
                    if(validateEmail(emailDes?.label)){
                        if(index === 0){
                            stringDestino = `${emailDes?.label}`
                        }else{
                            stringDestino = `${stringDestino}, ${emailDes?.label}`
                        }
                    }
                })
                formV1.destino = stringDestino
            }
            if(emails && emails?.length > 0){
                formV1.ccArr = emails;
            }
            await TratoService.confirmNegativaInst(formV1).then((resp)=>{
                if(resp?.id_t){
                    hideModal();
                    setIsLoading(false);
                    if(ticketId){
                        sockets?.emit( 'ticketNubiRefresh', `nubicom${ticketId}` );
                    }
                    if(onGuardar){
                        onGuardar();
                    }
                }
            }).catch((errV1)=>{
                console.log({errV1})
                setIsLoading(false);
            })
        }else if(value === 2){
            setIsLoading(true);
            const formV2 = {
                ticketId: ticketId,
                tratoId: tratoId,
                subEstado,

                ticketIdDA: infoInst?.ticketIdDA,
                cuerpoDA: cuerpoDA,
                filesCompress
            }
            await TratoService.confirmCanceladaInst(formV2).then((resp)=>{
                if(resp?.id_t){
                    hideModal();
                    setIsLoading(false);
                    if(ticketId){
                        sockets?.emit( 'ticketNubiRefresh', `nubicom${ticketId}` );
                    }
                    if(onGuardar){
                        onGuardar();
                    }
                }
            }).catch((errV2)=>{
                console.log({errV2})
                setIsLoading(false);
            })
        }else{
            console.log('No se selecciono ninguna opcion valida')
        }
    }
    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])
    useEffect(() => {
        if(otImg && otImg?.length > 0){
            onCompressImg(otImg[0],true)
        }
    }, [otImg])
    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    const handleChange = (event, newValue) => {
        setFiles([]);
        setFilesCompress([]);
        switch (newValue) {
            case 0:
                setEstadoSelect(null);
                setSubEstado(null);
                setCuerpoDA('');
                break;
            case 1:
                const correoPlantilla = infoInst?.plantillaCorreoInstNoExitosa;
                setAsunto(correoPlantilla?.asunto_pmail);
                setEmails(correoPlantilla?.ccArr_pmail);
                setCuerpoCorreo(correoPlantilla?.cuerpo_pmail);
                setCuerpoDA('')
                if(infoInst?.tecnologia){
                    setTecnologiaPrefa(infoInst?.tecnologia)
                }
                break;
            case 2:
                setEstadoSelect(null);
                setSubEstado(null);
                setCuerpoDA('');
                break;
            default:
                break;
        }
        setValue(newValue);
    };

    useEffect(() => {
        if(trato?.contactoEmail_t && validateEmail(trato?.contactoEmail_t?.replace(/[\r\n]+/g, '')?.trim())){
            setEmailDestino([{
                value: trato?.contactoEmail_t?.replace(/[\r\n]+/g, '')?.trim(),
                label: trato?.contactoEmail_t?.replace(/[\r\n]+/g, '')?.trim()
            }])
        }
    }, [])

    const infoNoExitosa = async() => {
        await TratoService.getByIdPrevConfirmInst(tratoId).then((respT)=>{
            const correoPlantilla = respT?.plantillaCorreoInstNoExitosa;
            setAsunto(correoPlantilla?.asunto_pmail);
            setEmails(correoPlantilla?.ccArr_pmail);
            setCuerpoCorreo(correoPlantilla?.cuerpo_pmail);
            if(respT?.tecnologia){
                setTecnologiaPrefa(respT?.tecnologia)
            }
            setInfoInst(respT)
        })
    }
    useEffect(() => {
        infoNoExitosa()
    }, [])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Confirmar Instalacion Pre-Servicio #${tratoId}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Box sx={{ borderBottom: 1, borderColor: '#6b788e'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="fullWidth"
                            sx={{
                                '& .MuiTabs-indicator':{
                                    background: '#0364ff'
                                },
                                '& .MuiButtonBase-root':{
                                color: '#6b788e',
                                fontWeight: 'bold'
                                },
                                '& .MuiButtonBase-root.Mui-selected':{
                                    color: '#0364ff'
                                }
                            }}
                        >
                            <Tab label="POSITIVA" {...a11yProps(0)} sx={{ flex: 1 }}/>
                            <Tab label="NEGATIVA" {...a11yProps(1)} sx={{ flex: 1 }}/>
                            <Tab label="CANCELADA" {...a11yProps(2)} sx={{ flex: 1 }}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container>
                            <Grid item xs={12} sm={6} display='flex' justifyContent='center'>
                                <Box sx={{p:1}}>
                                <Typography sx={{color: '#000'}}>Fecha de Instalacion</Typography>
                                    <DateSingle
                                        maxDate={new Date()}
                                        value={fecha}
                                        onChange={date => setFecha(date)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={sendEmail}
                                                onClick={()=>setSendEmail(!sendEmail)}
                                                sx={{
                                                    color: '#000',
                                                    '&.Mui-checked': {
                                                        color: '#000',
                                                    },
                                                }}
                                            />
                                        }
                                        label="¿Desea enviar el correo de bienvenida al cliente?"
                                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        sx={{marginTop:0.5}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={commentTicket}
                                                onClick={()=>setCommentTicket(!commentTicket)}
                                                sx={{
                                                    color: '#000',
                                                    '&.Mui-checked': {
                                                        color: '#000',
                                                    },
                                                }}
                                            />
                                        }
                                        label="¿Desea confirmar la instalacion en el ticket?"
                                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        sx={{marginTop:0.5}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={asigTk}
                                                onClick={()=>setAsigTk(!asigTk)}
                                                sx={{
                                                    color: '#000',
                                                    '&.Mui-checked': {
                                                        color: '#000',
                                                    },
                                                }}
                                            />
                                        }
                                        label="¿Desea asignar el ticket a administración?"
                                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        sx={{marginTop:0.5}}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={12} display='flex' justifyContent='center'>
                                <Typography sx={{color: '#000',mt:2,mb:2,fontWeight: 'bold'}} variant='h4'>IMAGENES</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} display='flex' justifyContent='center' flexDirection='column'>
                                <Typography sx={{color: '#000'}}>Orden de Trabajo</Typography>
                                <Box sx={{width: 172}}>
                                    <InputDropZone
                                        id="otImg"
                                        defaultImage={otImg}
                                        onChange={setOtImg}
                                        setFoto={setOtImg}
                                        isDeletedNow={!otImg}
                                        backgroundImage="fileUpload"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Grid container mt={1}>
                                    { filesCompress?.length > 0 &&(
                                        filesCompress.map((fil)=>(
                                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                                            </Grid>
                                        ))
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box mt={2} mb={2}>
                            { filesCompress?.length <= 5 &&(
                                <InputDropZoneComment
                                    id="otImg"
                                    onChange={(e)=>setFiles((old)=>[...old,...e])}
                                    setFoto={setFiles}
                                    accept={acceptInputFile}
                                />
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Card sx={{background: '#fff !important', boxShadow: 4,p: 1, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                            Tecnología
                                        </Typography>
                                        {infoInst?.tecnologia ? (
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={tecnologiaPrefa}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value={tecnologiaPrefa}
                                                        control={ <Radio sx={{ color: '#000', '&.Mui-checked': {color: '#0364ff'} }} /> }
                                                        label={tecnologiaPrefa}
                                                        sx={{color: '#000'}}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        ):(
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={tecnologiaPrefa}
                                                    onChange={(e) => {setEstadoSelect(null); setTecnologiaPrefa(e.target.value)}}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="RF"
                                                        control={ <Radio sx={{ color: '#000', '&.Mui-checked': {color: '#0364ff'} }} /> }
                                                        label="RF"
                                                        sx={{color: '#000'}}
                                                    />
                                                    <FormControlLabel
                                                        value="FO"
                                                        control={ <Radio sx={{ color: '#000', '&.Mui-checked': {color: '#0364ff'} }} /> }
                                                        label="FO"
                                                        sx={{color: '#000'}}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Typography sx={{color: '#000'}}>Estado del Pre-Servicio:</Typography>
                                    <SelectEstadoPreServicio
                                        value={estadoSelect}
                                        onChange={(e)=>{setSubEstado(null); setSubEstado(null); setEstadoSelect(e.value)}}
                                        negativaFO={tecnologiaPrefa === 'FO'}
                                        negativaRF={tecnologiaPrefa === 'RF'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {estadoSelect === 'ACCION SUPEDITADA A ENTIDAD EXTERNA' && (
                                        <>
                                            <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                            <SelectSubEstadoEntidadExterna
                                                value={subEstado}
                                                onChange={(e)=>setSubEstado(e.value)}
                                                restringir
                                            />
                                        </>
                                    )}
                                    {estadoSelect === 'NAP NO DISPONIBLE' && (
                                        <>
                                            <Typography sx={{color: '#000'}}>Sub-Estado:</Typography>
                                            <SelectSubEstadoNapNoDisponible
                                                value={subEstado}
                                                onChange={(e)=>setSubEstado(e.value)}
                                                restringir
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                </Grid>
                                { estadoSelect && estadoSelect !== 'NAP NO DISPONIBLE' && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Card sx={{background: '#fff !important', p: 1, borderRadius: 3, boxShadow: 4}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{color: '#000', fontWeight: 'bold', textAlign: 'center', fontSize: 18}}>
                                                            Correo Instalación no exitosa
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                                            Destino <Box sx={{fontWeight: 'bold',display: 'inline',color: 'red' }}>
                                                                *
                                                            </Box>
                                                        </Typography>
                                                        <SelectInsertEmail
                                                            id="selectEmails"
                                                            value={emailDestino}
                                                            onChange={setEmailDestino}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography sx={{color: '#000'}} variant='h6'>CC</Typography>
                                                        <SelectInsertEmail
                                                            id="selectEmails"
                                                            value={emails}
                                                            onChange={setEmails}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                                            Asunto <Box sx={{fontWeight: 'bold',display: 'inline',color: 'red' }}>
                                                                *
                                                            </Box>
                                                        </Typography>
                                                        <TextField
                                                            id='cuerpoEmail'
                                                            key='cuerpoEmail'
                                                            autoComplete='off'
                                                            focused
                                                            fullWidth
                                                            InputProps={{ style:{fontSize:14,color: '#000'} }}
                                                            value={asunto}
                                                            onChange={(e)=>setAsunto(e.target.value)}
                                                            sx={{
                                                                '& fieldset': { borderRadius: 2 },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'gray',
                                                                    },
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                                            Cuerpo <Box sx={{fontWeight: 'bold',display: 'inline',color: 'red' }}>
                                                                *
                                                            </Box>
                                                        </Typography>
                                                        <InputEditQuillSinEtiqueta
                                                            id="Comentario"
                                                            template={ cuerpoCorreo ||''}
                                                            onChangeText={(html)=>{
                                                                setCuerpoCorreo(html)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={12} mt={2}>
                                            <Card sx={{background: '#fff !important', p: 1, borderRadius: 3, boxShadow: 4}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{color: '#000', fontWeight: 'bold', textAlign: 'center', fontSize: 17}}>
                                                            {`Comentario Ticket #${ticketId} ${infoInst?.ticketIdDA ? '- #' : ''}${infoInst?.ticketIdDA || ''}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                                            Cuerpo <Box sx={{fontWeight: 'bold',display: 'inline',color: 'red' }}>
                                                                *
                                                            </Box>
                                                        </Typography>
                                                        <InputEditQuill
                                                            id="ComentarioDA"
                                                            template={ cuerpoDA ||''}
                                                            onChangeText={(html)=>{
                                                                setCuerpoDA(html)
                                                            }}
                                                        />
                                                    </Grid>
                                                    { filesCompress?.length > 0 &&(
                                                        filesCompress.map((fil)=>(
                                                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                                                            </Grid>
                                                        ))
                                                    )}
                                                </Grid>
                                                { filesCompress?.length < 5 &&(
                                                    <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                                                        <InputDropZoneComment
                                                            id="otImg"
                                                            onChange={(e)=>setFiles((old)=>[...old,...e])}
                                                            setFoto={setFiles}
                                                            accept={acceptInputFile}
                                                        />
                                                    </Box>
                                                )}
                                            </Card>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <Typography sx={{color: '#000'}}>Estado del Pre-Servicio:</Typography>
                                <SelectSubEstadoInstCancelada
                                    value={subEstado}
                                    onChange={(e)=>setSubEstado(e?.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} mt={2}>
                                <Card sx={{background: '#fff !important', p: 1, borderRadius: 3, boxShadow: 4}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', textAlign: 'center', fontSize: 17}}>
                                                {`Comentario Ticket #${ticketId} ${infoInst?.ticketIdDA ? '- #' : ''}${infoInst?.ticketIdDA || ''}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{color: '#000',fontWeight: 'normal'}} component='div'>
                                                Cuerpo <Box sx={{fontWeight: 'bold',display: 'inline',color: 'red' }}>
                                                    *
                                                </Box>
                                            </Typography>
                                            <InputEditQuill
                                                id="ComentarioDA1"
                                                template={ cuerpoDA ||''}
                                                onChangeText={(html)=>{
                                                    setCuerpoDA(html)
                                                }}
                                            />
                                        </Grid>
                                        { filesCompress?.length > 0 &&(
                                            filesCompress.map((fil)=>(
                                                <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                                    <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                    { filesCompress?.length < 5 &&(
                                        <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                                            <InputDropZoneComment
                                                id="otImg"
                                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                                setFoto={setFiles}
                                                accept={acceptInputFile}
                                            />
                                        </Box>
                                    )}
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        variant='wk'
                        color='nextStep'
                        isLoading={isLoading}
                        isDisabled={
                            isLoading
                            || value === 0 && ( !otImg || !fecha )
                            || value === 1 && (
                                estadoSelect !== 'NAP NO DISPONIBLE' && (
                                    !emailDestino
                                    || !asunto
                                    || !cuerpoCorreo
                                    || cuerpoCorreo?.length < 6
                                )
                            )
                            || value === 1 && (
                                estadoSelect !== 'NAP NO DISPONIBLE' && (!cuerpoDA || cuerpoDA?.length < 6)
                            )
                            || value === 1 && ( infoInst?.tecnologia === 'FO' && !subEstado )
                            || value === 2 && ( !subEstado )
                            || value === 2 && ( (!cuerpoDA || cuerpoDA?.length < 6) )
                        }
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalConfirmInstalacion;
