import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, Typography } from '@mui/material';
import Lottie from "lottie-react";
import confirmAsist from "../../lottie/confirmAsist.json";
import Typist from 'react-typist';
import { useState, useEffect } from 'react';
const ModalLoadingSoftland = ({show, hideModal}) => {
    const [texts, setTexts] = useState([
        'Cargando datos...',
        'Procesando información...',
        'Creando Cuenta...',
        'Creando servicio...',
        'Creando Items del servicio...',
        'Actualizando informacion...',
        'Finalizando el proceso de alta...',
    ]);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const handleClose = () => hideModal();
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 8600);
        setTimeout(() => {
            handleClose()
        }, 60000);
        return () => clearInterval(interval);
    }, [texts]);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Lottie animationData={confirmAsist} loop={true} />
                            <Typist key={currentTextIndex}>
                                <Typography sx={{color: '#000', textAlign:'center'}} variant='h4'>
                                    {texts[currentTextIndex]}
                                </Typography>
                            </Typist>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default ModalLoadingSoftland;