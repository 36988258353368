import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateMonth, DateRangeMax, SelectTipoAsignadoObj, SelectTipoObj, SelectTipoTiempo, SelectUser } from '../../components';
import NovedadesService from '../../services/novedades.service';
import ObjetivosService from '../../services/objetivo.service';

const ModalAddObjetivo = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cantidad, setCantidad] = useState(1);
    const [categoria, setCategoria] = useState(null);
    const [titulo, setTitulo] = useState('');
    const [ttiempo, setTtiempo] = useState('Diario');
    const [individual, setIndividual] = useState([]);
    const handleClose = () => hideModal();
    const [fechas, setFechas] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const onSubmit = async() => {
        const fechaInicioUTC = new Date(fechas.startDate);
        fechaInicioUTC.setUTCHours(0, 0, 1, 0);
        const fechaFinUTC = new Date(fechas.endDate);
        fechaFinUTC.setUTCHours(23, 59, 59, 999);
        const fechaInicioArgentina = new Date(fechaInicioUTC.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));
        const fechaFinArgentina = new Date(fechaFinUTC.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));

        const form = {
            titulo_obj: titulo,
            tipoTiempo_obj: ttiempo,
            tipoObj_obj: categoria,
            cantObj_obj: cantidad,
            fechaIni_obj: fechaInicioArgentina.toISOString(),
            fechaFin_obj: fechaFinArgentina.toISOString(),
            individual: individual
        };
        await ObjetivosService.register(form).then(()=>{
            hideModal();
            if(onGuardar){
                onGuardar();
            }
        })
};

    useEffect(() => {
        if(item){
            setTimeout(() => {
                setTitulo(item?.cuerpo_nov)
            }, 100);
        }
    }, [item])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])

    useEffect(() => {
        const fechaActual = new Date()
        let startDate = fechaActual;
        let endDate = fechaActual;
        switch (ttiempo) {
            case 'Diario':
                startDate = startDate.setDate(startDate.getDate() + 1);
                setFechas({
                    startDate: new Date(startDate),
                    endDate: new Date(startDate)
                })
                break;
            case 'Semanal':
                startDate = startDate.setDate(startDate.getDate() + 1);
                endDate = endDate.setDate(fechaActual.getDate() + 6);
                setFechas({
                    startDate: new Date(startDate),
                    endDate: new Date(endDate)
                })
                break;
            case 'Mensual':
                const firstDay = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 1);
                const lastDay = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 2, 0);
                setFechas({
                    startDate: firstDay,
                    endDate: lastDay
                })
                break;
            default:
                break;
        }
    }, [ttiempo])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_obj > 0 ? 'Editar Objetivo' : 'Registrar Objetivo'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1} sx={{minHeight: 300}}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={titulo}
                                error={!titulo}
                                helperText={!titulo && 'El titulo es requerido'}
                                onChange={(e)=>setTitulo(e.target.value)}
                                sx={styleInputHeight}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Categoria</Typography>
                            <SelectTipoObj
                                value={categoria}
                                onChange={(e)=>setCategoria(e?.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Cantidad</Typography>
                            <TextField
                                fullWidth
                                sx={styleInputHeight}
                                autoComplete='off'
                                type='number'
                                value={cantidad}
                                onChange={(e)=>setCantidad(Number(e.target.value))}
                                InputLabelProps={{shrink: cantidad}}
                                inputProps={{ min: 1 , inputProps: { min: 1 } }}
                                error={!cantidad}
                                helperText={!cantidad && 'La cantidad es requerida'}
                                FormHelperTextProps={{ className: '-mt-1' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000', fontWeight: 'bold'}}>Tiempo del Objetivo</Typography>
                            <Card sx={{background: '#fff !important', padding: 2, boxShadow: 4}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{color: '#000'}}>Tipo</Typography>
                                        <SelectTipoTiempo
                                            value={ttiempo}
                                            onChange={(e)=>setTtiempo(e?.value)}
                                        />
                                    </Grid>
                                    {ttiempo === 'Diario' && (
                                        <Grid item xs={12} md={8}>
                                            <DateRangeMax
                                                minDate={new Date()}
                                                initianDate={fechas}
                                                onChange={(e)=>{
                                                    const startDate = new Date(e?.startDate)
                                                        setFechas({
                                                            startDate: startDate,
                                                            endDate: startDate,
                                                        })
                                                }}
                                                isDisabledHasta
                                            />
                                        </Grid>
                                    )}
                                    {ttiempo === 'Semanal' && (
                                        <Grid item xs={12} md={8}>
                                            <DateRangeMax
                                                minDate={new Date()}
                                                initianDate={fechas}
                                                onChange={(e)=>{
                                                    const fechaActual = e?.startDate;
                                                        let startDate = new Date(fechaActual);
                                                        let endDate = new Date(startDate);
                                                        endDate.setDate(endDate.getDate() + 6);
                                                        setFechas({
                                                            startDate: startDate,
                                                            endDate: endDate
                                                        });
                                                }}
                                                isDisabledHasta
                                            />
                                        </Grid>
                                    )}
                                    {ttiempo === 'Mensual' &&(
                                        <Grid item xs={12} md={8} sx={{marginTop: {xs: 0, md: 0}}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Mes:</Typography>
                                            <DateMonth
                                                onChange={(e)=> setFechas(e) }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', boxShadow: 4,p: 2}}>
                                <Typography sx={{color: '#000', textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>
                                    Asignación Individual
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography sx={{color: '#000'}}>Usuarios</Typography>
                                        <SelectUser
                                            filter='asesores'
                                            isMulti
                                            value={individual}
                                            onChange={(opt) => setIndividual(opt.map(op=>(op.value)))}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalAddObjetivo