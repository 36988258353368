import axios from 'axios';
const base = 'comentarios';

function register(data) {
    const form = new FormData();
    form.append('ticketId_com',data.ticketId_com);
    form.append('cuerpo_com',data.cuerpo_com);

    if(data.isVisible_com || data.isVisible_com === false){
        form.append('isVisible_com',data.isVisible_com);
    }
    if(data.propertys){
        form.append('propertys',data.propertys);
    }
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}
function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function registerEmail(data) {
    return axios.post('comentarios/email', data)
}

const ComentarioTicketService = {
    register,
    update,
    registerEmail
};
export default ComentarioTicketService;
