import types from "../types/types";

const ticketReducer = (state = {reloading:false,reloadingInd:false,reloadingGroup:false}, action) => {

    switch (action.type) {
        case types.ticketReloadStart:
            return {
                ...state,
                reloading: true
            }
        case types.ticketReloadFinish:
            return {
                ...state,
                reloading: false
            }
        case types.ticketReloadIndStart:
            return {
                ...state,
                reloadingInd: true
            }
        case types.ticketReloadIndFinish:
            return {
                ...state,
                reloadingInd: false
            }
        case types.ticketReloadGroupStart:
            return {
                ...state,
                reloadingGroup: true
            }
        case types.ticketReloadGroupFinish:
            return {
                ...state,
                reloadingGroup: false
            }
        default:
            return state;
    }
}

export default ticketReducer;