import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, InputDropZone } from '../../components';
import KmzService from '../../services/kmz.service';

const ModalCargarKmzFO = ({show, hideModal, item, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [archivo, setArchivo] = useState(null);
    const onSubmit = async() => {
        await KmzService.cargarKmzFO({kmz: archivo}).then((resp)=>{
            setIsLoading(false);
            if(onGuardar){
                onGuardar();
            }
            handleClose();
        }).catch(()=>{
            setIsLoading(false)
        })
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='xs' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Subir archivo KMZ - Fibra Optica
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
                            <Typography sx={{color: '#000'}}>Archivo KMZ</Typography>
                            <Box sx={{width: 172}}>
                                <InputDropZone
                                    id="kmz"
                                    accept='.kmz, application/vnd.google-earth.kmz'
                                    onChange={setArchivo}
                                    setFoto={setArchivo}
                                    isDeletedNow={!archivo}
                                    backgroundImage="fileUpload"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!archivo}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCargarKmzFO;