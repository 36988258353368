import { useState } from 'react';
import PropTypes from 'prop-types';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import { ButtonBasic } from '../../components';
import { Box, Dialog, DialogActions, DialogContent, Typography,Divider } from '@mui/material';
import DialogTitleDefault from '../default/DialogTitleDefault';

const ModalInsertCood = ({show, hideModal, onGuardar}) => {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [coordenadas, setCoordenadas] = useState('')
    const onSubmit = () =>{
        setIsLoading(true)
        const quitarEspacios = coordenadas.replace(/ /g, "")
        const separador = quitarEspacios.split(',')
        if(separador.length === 2){
            const validCoord = `${separador[0]}, ${separador[1]}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                onGuardar({lat:Number(separador[0]),lng:Number(separador[1])})
                handleClose()
            }else{
                setIsLoading(false)
                setErr(true)
            }
        }else{
            setIsLoading(false)
            setErr(true)
        }
    }
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Insertar las coordenadas
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <input
                        className='form-control'
                        id="nombre"
                        name="nombre"
                        type="text"
                        autoComplete='off'
                        value={coordenadas}
                        onChange={(e)=>setCoordenadas(e.target.value)}
                    />
                    {err && (
                        <span className='text-red ml-5' style={{textAlign: 'center'}}>No es una coordenada valida</span>
                    )}
                </DialogContent>
                <DialogActions>
                    <ButtonBasic onClick={handleClose} textButton='CANCELAR' />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}

ModalInsertCood.propTypes = {
    show: PropTypes.bool
};

ModalInsertCood.defaultProps = {
    show: false
};

export default ModalInsertCood;
