import { Grid } from '@mui/material'
import { ButtonBasic, CardPreServicio } from '../../../components'
import usePermisos from '../../../hooks/usePermisos';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalNewPreServicioCorporativo from '../../../modals/corporativo/ModalNewPreServicioCorporativo';
import ModalNewPreServicioGobierno from '../../../modals/gobierno/ModalNewPreServicioGobierno';
import ModalNewDireccionEntregaEventual from '../../../modals/eventual/ModalNewDireccionEntregaEventual';
import ModalNewPreServicioPyme from '../../../modals/pyme/ModalNewPreServicioPyme';

const TabCuentaPreServicio = ({preServicios, cuenta,datosOK,datosFaltantes,contacto,onGuardar}) => {
    const permisos = usePermisos();
    const onOpenModalNewPreServicio = () => {
        showModal(ModalNewPreServicioCorporativo,{contacto: contacto, onGuardar: onGuardar})
    }

    const onOpenModalNewPreServicioGobierno = () => {
        showModal(ModalNewPreServicioGobierno,{contacto: contacto, onGuardar: onGuardar})
    }

    const onOpenModalNewPreServicioEventual = () => {
        showModal(ModalNewDireccionEntregaEventual,{contacto: contacto, onGuardar: onGuardar})
    }

    const onOpenModalNewPreServicioPymeEventual = () => {
        showModal(ModalNewPreServicioPyme,{contacto: contacto, onGuardar: onGuardar})
    }

    return (
        <Grid container spacing={0}>
            {permisos['contactos.corporativo.created']&& (contacto?.categoria?.codigo === 'COR' || contacto?.segmento === 'Corporativo' || (contacto?.contxemp?.length > 0 && contacto?.contxemp[0]?.categoria_cxe === 'COR')) &&(
                <Grid item xs={12} display='flex' justifyContent='end'>
                    <ButtonBasic
                        textButton='Nuevo Pre-Servicio'
                        variant='wk'
                        color='nextStep'
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        onClick={()=>onOpenModalNewPreServicio()}
                    />
                </Grid>
            )}
            { permisos['contactos.gobierno.created'] && (
                contacto?.categoria?.codigo === 'GOB'
                || contacto?.segmento === 'Gobierno'
                || (contacto?.contxemp?.length > 0 && contacto?.contxemp[0]?.categoria_cxe === 'GOB')
            ) &&(
                <Grid item xs={12} display='flex' justifyContent='end'>
                    <ButtonBasic
                        textButton='Nuevo Pre-Servicio-Gobierno'
                        variant='wk'
                        color='nextStep'
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        onClick={()=>onOpenModalNewPreServicioGobierno()}
                    />
                </Grid>
            )}
            { permisos['contactos.direccion-entrega.created']
                && cuenta
                && ((contacto?.categoria?.codigo === 'COR' || contacto?.segmento === 'Corporativo' )
                || (contacto?.categoria?.codigo === 'GOB' || contacto?.segmento === 'Gobierno' )
                || (contacto?.categoria?.codigo === 'PY' || contacto?.categoria?.codigo === 'PYM' || contacto?.segmento === 'Pymes')
            ) &&(
                <Grid item xs={12} display='flex' justifyContent='end'>
                    <ButtonBasic
                        textButton='Nuevo Servicio Eventual'
                        variant='wk'
                        color='nextStep'
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        onClick={()=>onOpenModalNewPreServicioEventual()}
                    />
                </Grid>
            )}
            { permisos['preservicio.pyme-eventual.created']
                && (contacto?.contxemp?.length > 0
                && contacto?.contxemp[0]?.categoria_cxe === 'PYM')
                && !cuenta
            && (
                <Grid item xs={12} display='flex' justifyContent='end'>
                    <ButtonBasic
                        textButton='Nuevo Pre-Servicio Eventual'
                        variant='wk'
                        color='nextStep'
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        onClick={()=>onOpenModalNewPreServicioPymeEventual()}
                    />
                </Grid>
            )}
            {preServicios && preServicios?.length > 0 && (
                preServicios.map((preS)=>(
                    <Grid item xs={12} key={`${preS?.id_t}gridd`}>
                        <CardPreServicio item={preS} cuenta={cuenta} permisos={permisos} datosOK={datosOK} datosFaltantes={datosFaltantes} />
                    </Grid>
                ))
            )}
        </Grid>
    )
}

export default TabCuentaPreServicio