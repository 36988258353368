import React, { useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import { Box, InputAdornment, TextField } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FechaFunction from '../../function/FechaFunction';

const DateSingle = ({onChange,isDisabled,value,showTimeSelect,isFullWidth,...props}) => {
    const [fecha, setFecha] = useState(value?FechaFunction.parse(value):null);

    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <TextField
                {...props}
                onClick={onClick}
                autoComplete='off'
                value={valueField}
                ref={ref}
                readOnly
                fullWidth={isFullWidth}
                focused
                InputProps={{
                    startAdornment: (
                        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',alignContent:'center', backgroundColor:'#d1d1d1',height:{xs:41,xl:42},width:50,marginLeft:-1.7,borderTopLeftRadius:4,borderBottomLeftRadius:5}}>
                            <InputAdornment position="start">
                                <CalendarMonthIcon sx={{color: 'gray',marginLeft:1}} />
                            </InputAdornment>
                        </Box>
                    ),
                }}
                sx={{
                    '& fieldset': {
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        borderColor: '#d1d1d1',
                        },
                    },
                    '& input': {
                        color: '#000',
                        background: '#fff',
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        height:10,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    },
                }}
            />
        )
    );
    const onDateSelected = (newDate) => {
        setFecha(newDate);
        if (onChange) {
            if(FechaFunction.isDate(newDate)){
                onChange(FechaFunction.format(newDate));
            } else {
                onChange(null);
            }
        }
    };

    useEffect(() => {
        if(typeof value === 'string'){
            setFecha(FechaFunction.parse(value))
        }else{
            setFecha(null)
        }
    }, [value])
    return (
        <DatePicker
            selected={fecha}
            onChange={(date) => onDateSelected(date)}
            dateFormat={showTimeSelect?"dd/MM/yyyy h:mm aa":"P"}
            showTimeSelect={showTimeSelect}
            disabled={isDisabled}
            customInput={<CustomInput />}
            popperPlacement="auto"
            {...props}
        />
    );
}

export default DateSingle;
