import { useEffect, useState } from "react"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import AccionesService from "../../services/acciones.service";
import ModalPermisos from "../../modals/administrador/ModalPermisos";
import UserService from "../../services/personas.service";
import GrupoService from "../../services/grupo.service";
import ModalCanalPrivado from "../../modals/administrador/ModalCanalPrivado";
import ModalGrupos from "../../modals/administrador/ModalGrupos";
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";

const GruposScreen = () => {
    const [itemsCP, setItemsCP] = useState([]);
    const [itemsG, setItemsG] = useState([]);
    const datosCanalPriv = async () => {
        await UserService.getAll({type : 'select'}).then((response) => {
            setItemsCP(response);
        })
    };
    const datosGrupos = async () => {
        await GrupoService.getAll().then((r)=>{
            setItemsG(r)
        })
    };
    useEffect(() => {
        datosCanalPriv();
        datosGrupos();
        document.title = 'Privados - Grupos';
    }, [])

    const columnsCanalPriv = [
        {
            name: "id_p",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_p",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "dni_p",
            label: "DNI",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminarCP(tableMeta.rowData[0])}>
                                <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const columnsGrupo = [
        {
            name: "id_g",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_g",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "personal",
            label: "Integrantes",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditarGrupo(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminarG(tableMeta.rowData[0])}>
                                <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onOpenEditarCanalPriv = (item) => {
        if(item){
            const searchItem = itemsCP.find((sItem)=> sItem.id_p === item)
            showModal(ModalPermisos, {item: searchItem, onGuardar: datosCanalPriv});
        }else{
            showModal(ModalPermisos, {onGuardar: datosCanalPriv});
        }
    };
    const onOpenEditarGrupo = (item) => {
        if(item){
            const searchItem = itemsG.find((sItem)=> sItem.id_g === item)
            showModal(ModalGrupos, {item: searchItem, onGuardar: datosGrupos});
        }else{
            showModal(ModalGrupos, {onGuardar: datosGrupos});
        }
    };
    const onOpenNewCP = () =>{
        showModal(ModalCanalPrivado,{onGuardar: datosCanalPriv})
    }
    const onEliminarCP = (itemId) => {
        const item = itemsCP.find((itcp)=> itcp?.id_p  === itemId)
        showModal(ConfirmationDialog, {
            title: 'Eliminar Canal Privado',
            message: `¿Esta seguro que desea eliminar el Canal Privado de ${item.nombre_p} ?`,
            onConfirm: () => {
                UserService.cpBaja(item.id_p).then(() => {
                    datosCanalPriv();
                });
            }
        });
    };
    const onEliminarG = (itemId) => {
        const item = itemsG.find((itcp)=> itcp?.id_g  === itemId)
        showModal(ConfirmationDialog, {
            title: 'Eliminar Grupo',
            message: `¿Esta seguro que desea eliminar el Grupo ${item?.nombre_g} ?`,
            onConfirm: () => {
                GrupoService.destroy(item?.id_g).then(() => {
                    datosGrupos();
                });
            }
        });
    };
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} spacing={2}>
            <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className="text-gray-900 font-bold" variant="h2">Canal Privado</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                            <ButtonBasic
                                textButton="NUEVO"
                                sx={{width: 200, height: 30}}
                                onClick={()=>onOpenNewCP()}
                                variant='wk'
                                color="nextStep"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                            <TableBase
                                title='Resultados'
                                columns={columnsCanalPriv}
                                data={itemsCP}
                                options={{
                                    onChangePage: (e)=> console.log('Nro de pagina',e),
                                    onChangeRowsPerPage: (ee)=>console.log('Cantidad de filas a mostrar',ee),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className="text-gray-900 font-bold" variant="h2">Grupos</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                            <ButtonBasic
                                textButton="NUEVO"
                                sx={{width: 200, height: 30}}
                                onClick={()=>onOpenEditarGrupo()}
                                variant='wk'
                                color="nextStep"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                            <TableBase title='Resultados' columns={columnsGrupo} data={itemsG} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GruposScreen