import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectCantidadSoftland = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps,
    isDisabled
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const options = [
        {
            value: '0.0500',
            label: '5%'
        },
        {
            value: '0.1000',
            label: '10%'
        },
        {
            value: '0.1500',
            label: '15%'
        },
        {
            value: '0.2000',
            label: '20%'
        },
        {
            value: '0.2500',
            label: '25%'
        },
        {
            value: '0.3000',
            label: '30%'
        },
        {
            value: '0.3500',
            label: '35%'
        },
        {
            value: '0.4000',
            label: '40%'
        },
        {
            value: '0.4500',
            label: '45%'
        },
        {
            value: '0.5000',
            label: '50%'
        },
        {
            value: '0.5500',
            label: '55%'
        },
        {
            value: '0.6000',
            label: '60%'
        },
        {
            value: '0.6500',
            label: '65%'
        },
        {
            value: '0.7000',
            label: '70%'
        },
        {
            value: '0.7500',
            label: '75%'
        },
        {
            value: '0.8000',
            label: '80%'
        },
        {
            value: '0.8500',
            label: '85%'
        },
        {
            value: '0.9000',
            label: '90%'
        },
        {
            value: '0.9000',
            label: '95%'
        },
        {
            value: '1.0000',
            label: '100%'
        },
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
            isDisabled={isDisabled}
        />
    );
};

SelectCantidadSoftland.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectCantidadSoftland;