import axios from 'axios';
const base = 'problematicatk';

function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function update(data) {
    return axios.put([base, data.id_ptk].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const ProblematicaTkService = {
    getAll,
    register,
    update,
    destroy
};
export default ProblematicaTkService;
