import axios from 'axios';
const base = 'usuarios';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}
function register(data) {
    const form = new FormData();
    form.append('createdEmp_mt','NUBICOM');
    form.append('cuentaBancaria_p',data?.cuentaBancaria_p);
    form.append('dni_p',data?.dni_p);
    form.append('domicilio_p',data?.domicilio_p);
    form.append('email_p',data?.email_p);
    form.append('nombre_p',data?.nombre_p);
    form.append('telPersonal_p',data?.telPersonal_p);
    if(data?.foto_p){
        if(Array.isArray(data.foto_p) && data.foto_p.length>0){
            form.append('foto_p',data.foto_p[0]);
        } else {
            form.append('foto_p',data.foto_p);
        }
    }
    if(data?.contratoAsesor_p){
        if(Array.isArray(data.contratoAsesor_p) && data.contratoAsesor_p.length>0){
            form.append('contratoAsesor_p',data.contratoAsesor_p[0]);
        } else {
            form.append('contratoAsesor_p',data.contratoAsesor_p);
        }
    }
    return axios.post(base, form,{
        headers: { 'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`}
    });
}

function cpAlta(data) {
    return axios.post('usuarios/cpAlta', data);
}

function cpBaja(id) {
    return axios.delete(['usuarios/cpBaja', id].join('/'));
}

function updateAsesor(data) {
    const form = new FormData();
    form.append('createdEmp_mt','NUBICOM');
    form.append('cuentaBancaria_p',data?.cuentaBancaria_p);
    form.append('dni_p',data?.dni_p);
    form.append('domicilio_p',data?.domicilio_p);
    form.append('email_p',data?.email_p);
    form.append('id_p',data?.id_p);
    form.append('nombre_p',data?.nombre_p);
    form.append('telPersonal_p',data?.telPersonal_p);
    if(data?.foto_p){
        if(Array.isArray(data.foto_p) && data.foto_p.length>0){
            form.append('foto_p',data.foto_p[0]);
        } else {
            form.append('foto_p',data.foto_p);
        }
    }
    if(data?.contratoAsesor_p){
        if(Array.isArray(data.contratoAsesor_p) && data.contratoAsesor_p.length>0){
            form.append('contratoAsesor_p',data.contratoAsesor_p[0]);
        } else {
            form.append('contratoAsesor_p',data.contratoAsesor_p);
        }
    }
    return axios.put(['usuarios/asesor/update', data?.id_p].join('/'), form,{
        headers: { 'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`}
    });
}

function deleteAsesor(id) {
    return axios.put(['usuarios/asesor/delete', id].join('/'));
}

const UserService = {
    getAll,
    cpAlta,
    cpBaja,
    register,
    updateAsesor,
    deleteAsesor
};
export default UserService;