import { useState, useMemo, useEffect } from 'react';
import AuxSoftland from '../../../function/AuxSoftland';
import {toast} from 'react-toastify';
import ContactosAction from '../../../store/actions/contactos';
import { connect } from 'react-redux';
import { Box, Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateSingle, SelectCanalesSoftland, SelectCategoriaSoftland, SelectCobradorSoftland, SelectIvaSoftland, SelectListPrecioSoftland, SelectLocalidadesSoftland, SelectPaisesSoftland, SelectProvinciasSoftland, SelectTipoDocSoftland, SelectVendedoresSoftland, SelectZonasSoftland } from '../../../components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfContactoService from '../../../services/infContacto.service';
import DownloadFile from '../../../services/downloadFile.service';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { PhotoConsumer } from 'react-photo-view';
import { useNavigate } from 'react-router-dom';

const FormPaso1SinCuenta = ({
  contacto,
  contrato,
  nextStep,
  iniciarContacto,
  iniciarContrato
}) => {
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const [datosTrato, setDatosTrato] = useState(contrato);
  const [formContacto, setFormContacto] = useState(contacto);
  const [imgContacto, setImgContacto] = useState(null);
  const navigate = useNavigate()
  const actualizarDataContacto = async() =>{
      await InfContactoService.getAll({contactoId:contrato.contactoId_t}).then((resp)=>{
          setImgContacto(resp)
      })
  }
  const onChangeFormContacto = ( value, field ) => {
      setFormContacto({
          ...formContacto,
          [field]: value
      });
  }
  const onChangeFormTrato = ( value, field ) => {
      setDatosTrato({
          ...datosTrato,
          [field]: value
      });
  }
  const onSubmit = async(data) => {
      const valid = await AuxSoftland.verificarContacto(formContacto)
      if(valid?.estado === 'ok'){
          iniciarContrato(datosTrato);
          iniciarContacto(formContacto);
          nextStep();
      }else if(Array.isArray(valid?.error) && valid?.error?.length > 0){
        valid?.error.forEach((e)=>{
          toast.error(`Verifique los datos ingresados en ${e}`, {
            position: "top-right",
            autoClose: 4500,
            pauseOnHover: false,
          });
        })
      }else{
        toast.error('Verifique los datos ingresados', {
            position: "top-right",
            autoClose: 4500,
            pauseOnHover: false,
        });
      }
  }
  const descargarConstanciaCuit = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathconstanciaAfip_icon}),'constancia-cuit')
  }
  const descargarContratoSocialEstatuto = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathContratoSocialEstatuto_icon}),'contrato-socio-estatuto')
  }
  /* const descargarDesigActaActualizada = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathDesigActaActualizada_icon}),'designacion-acta')
  } */
  const descargarPoderApoderado = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathPoderApoderado_icon}),'poder-apoderado')
  }
  const descargarDniFrenteApoderado = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathDniFrenteApoderado_icon}),'dni-frente-apoderado')
  }
  const descargarDniDorsoApoderado = async() =>{
    await AuxiliarFunction.downloadFile(DownloadFile.constanciaCuitNubi({id:imgContacto?.pathDniDorsoApoderado_icon}),'dni-dorso-apoderado')
  }
  useEffect(() => {
      if(contrato){
          setDatosTrato(contrato)
          if(contrato.contactoId_t){
              actualizarDataContacto()
          }
      }
  }, [contrato]);
  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
        color: 'gray',
        fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
        marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
        },
        '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
        },
        '&.Mui-focused': {
            '&:hover':{
            border:0,
            height: 40
            }
        },
        color: 'gray',
        height: 40
    }
  }), [])
  return (
    <Box>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={8}>
          <Card sx={{background: '#fff !important', p: 2}}>
            <Typography sx={{color: '#000', fontWeight: 'bold'}} variant='h3'>Alta en Softland</Typography>
            <Grid container mt={2} spacing={1}>
              <Grid item xs={12}>
                <Box display='flex'>
                  <ContactPhoneIcon sx={{color: '#000',mr:2}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',mb:-1}}>Datos del cliente</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Nombre Completo</Typography>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.nombreCompleto_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'nombreCompleto_c')}
                />
              </Grid>
              <Grid item xs={4} md={2.5}>
                <Typography sx={{color: '#000'}}>Tipo Doc</Typography>
                <SelectTipoDocSoftland
                  value={formContacto?.tipoDoc_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'tipoDoc_c')}
                />
              </Grid>
              <Grid item xs={8} md={3.5} mt={2}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.documento_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'documento_c')}
                  inputProps={{ min: 1000000, inputProps: { min: 1000000 } }}
                />
                <Typography sx={{ml:1.5,color: '#000',fontSize: 11}}>Sin puntos o guines</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{color: '#000', fontWeight: 'bold',mb:-1,fontSize: 15}}>Datos del Contacto</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Celular</Typography>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.celular_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'celular_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Email</Typography>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.email_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'email_c')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{color: '#000', fontWeight: 'bold',mb:-1,mt:1,fontSize: 15}}>Datos de Residencia</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>País</Typography>
                <SelectPaisesSoftland
                  value={formContacto?.pais_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'pais_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Provincia</Typography>
                <SelectProvinciasSoftland
                  value={formContacto?.provincia_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'provincia_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Localidad</Typography>
                <SelectLocalidadesSoftland
                  value={formContacto?.cpostal_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'cpostal_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Zonas</Typography>
                <SelectZonasSoftland
                  value={formContacto?.zona_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'zona_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Barrio</Typography>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.barrio_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'barrio_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Calle</Typography>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formContacto?.direccion_c}
                  onChange={({target})=>onChangeFormContacto(target.value,'direccion_c')}
                />
              </Grid>
              <Grid item xs={12} mt={1} mb={0.5}>
                <Divider sx={{background: 'gray',m:1}} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Vendedor</Typography>
                <SelectVendedoresSoftland
                    value={formContacto?.vendedor_c}
                    onChange={(opt)=>onChangeFormContacto(opt.value,'vendedor_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Cobrador</Typography>
                <SelectCobradorSoftland
                    value={formContacto?.cobrador_c}
                    onChange={(opt)=>onChangeFormContacto(opt.value,'cobrador_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Lista de Precio</Typography>
                <SelectListPrecioSoftland
                    value={formContacto?.listPrecio_c}
                    onChange={(opt)=>onChangeFormContacto(opt.value,'listPrecio_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>Categoria</Typography>
                <SelectZonasSoftland
                  value={formContacto?.zona_c}
                  onChange={(opt)=>onChangeFormContacto(opt.value,'zona_c')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{color: '#000'}}>IVA</Typography>
                <SelectIvaSoftland
                    value={formContacto?.iva_c}
                    onChange={(opt)=>onChangeFormContacto(opt.value,'iva_c')}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{background: '#fff !important', p: 2}}>
            <Box display='flex' alignItems='center'>
              <SensorOccupiedIcon sx={{color: '#000',mr:1}} />
              <Typography sx={{color: '#000', fontWeight: 'bold',fontSize:15}}>
                {formContacto?.tipoDoc_c === '96' ? 'Documento de identidad' : 'Contancia de AFIP'}
              </Typography>
            </Box>
            { formContacto?.tipoDoc_c === '96' && imgContacto?.pathFrente_icon && (
              <Box sx={{p: 2}}>
                <Typography sx={{color: '#000', fontWeight: 'bold'}}>Frente</Typography>
                <PhotoConsumer
                    key={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathFrente_icon}&token=${token}`}
                    src={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathFrente_icon}&token=${token}`}
                    intro={imgContacto?.pathFrente_icon}
                >
                    <img
                        src={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathFrente_icon}&token=${token}`}
                        alt='dniFrente'
                        style={{width: '100%', height: 220}}
                    />
                </PhotoConsumer>
              </Box>
            )}
            { formContacto?.tipoDoc_c === '96' && imgContacto?.pathDorso_icon && (
              <Box sx={{p: 2}}>
                <Typography sx={{color: '#000', fontWeight: 'bold'}}>Dorso</Typography>
                <PhotoConsumer
                    key={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathDorso_icon}&token=${token}`}
                    src={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathDorso_icon}&token=${token}`}
                    intro={imgContacto?.pathDorso_icon}
                >
                    <img
                        src={`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${imgContacto?.pathDorso_icon}&token=${token}`}
                        alt='dniDorso'
                        style={{width: '100%', height: 220}}
                    />
                </PhotoConsumer>
              </Box>
            )}
            {formContacto?.tipoDoc_c === '80' && imgContacto?.pathconstanciaAfip_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar Constancia'
                  onClick={()=>descargarConstanciaCuit(imgContacto?.pathconstanciaAfip_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )}
            {formContacto?.tipoDoc_c === '80' && imgContacto?.pathContratoSocialEstatuto_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar Contrato Socio-Estatuto'
                  onClick={()=>descargarContratoSocialEstatuto(imgContacto?.pathContratoSocialEstatuto_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )}
            {/* {formContacto?.tipoDoc_c === '80' && imgContacto?.pathDesigActaActualizada_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar Acta Actualizada'
                  onClick={()=>descargarDesigActaActualizada(imgContacto?.pathDesigActaActualizada_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )} */}
            {formContacto?.tipoDoc_c === '80' && imgContacto?.pathPoderApoderado_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar Poder-Apoderado'
                  onClick={()=>descargarPoderApoderado(imgContacto?.pathPoderApoderado_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )}
            {formContacto?.tipoDoc_c === '80' && imgContacto?.pathDniFrenteApoderado_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar DNI-Frente-Apoderado'
                  onClick={()=>descargarDniFrenteApoderado(imgContacto?.pathDniFrenteApoderado_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )}
            {formContacto?.tipoDoc_c === '80' && imgContacto?.pathDniDorsoApoderado_icon && (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ButtonBasic
                  textButton='Descargar DNI-Dorso-Apoderado'
                  onClick={()=>descargarDniDorsoApoderado(imgContacto?.pathDniDorsoApoderado_icon)}
                  variant='wk'
                  color='nextStep'
                  sx={{width: 200}}
                />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center',mb:12}}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          color='nextWhite'
          onClick={()=>navigate(`/contactos/${contrato.contactoId_t}/nubicom`)}
          sx={{border: 1, borderColor: '#0364ff'}}
        />
        <ButtonBasic
          textButton='Continuar'
          variant='wk'
          color='nextStep'
          iconEnd={<ArrowForwardIcon sx={{color: '#fff'}} />}
          sx={{ml:2}}
          onClick={()=>onSubmit()}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso1SinCuenta);