
import {toast} from 'react-toastify';
import types from '../types/types';


const initialState = {
    isLoggedIn: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    tokenNubi: localStorage.getItem('tokenNubi'),
    tokenStr: localStorage.getItem('tokenStr'),
    currentUser: {}
}

export const authReducer = (state = initialState, {type,payload}) => {

    switch (type) {
        case types.authLogin:
            return {
                ...state,
                isLoggedIn: true,
                token: payload.token,
                tokenNubi: payload?.tokenNubi,
                tokenStr: payload?.tokenStr,
                currentUser: payload.usuario
            }
        case types.authUserMe:
            return {
                ...state,
                isLoggedIn: true,
                currentUser: payload.currentUser
            }
        case types.authStartLogout:
            localStorage.clear();
            if(state.isLoggedIn){
                toast.warn('Se cerró la sesión...', {});
            }
            return {
                isLoggedIn: false,
                token: null,
                currentUser: {
                    email: 'mail@example.com'
                }
            };
        default:
            return state;
    }
}