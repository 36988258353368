import { useState } from 'react'
import { Box, Collapse } from '@mui/material';
import ButtonBasic from '../buttons/ButtonBasic';
import FechaFunction from '../../function/FechaFunction';
import TextInfoTicket from '../text/TextInfoTicket';

const ListTarea = ({
    tarea
})=>{
    const [open, setOpen] = useState(false)

    return (
        <Box pl={2} pr={2} mt={1} mb={1}>
            <ButtonBasic
                onClick={()=>setOpen(!open)}
                fullWidth
                textButton={` #${tarea?.id_t} - ${tarea?.tipo_tarea?.nombre_tt}`}
                sx={{
                    background: '#fff',
                    border: 1,
                    borderColor:'gray',
                    marginBottom: 1,
                    '&:hover':{
                        background: '#d1d1d1',
                        border: 1,
                        borderColor:'gray',
                    }
                }}
            />
            <Collapse in={open} sx={{border:1,borderColor:'#dfe2e6',borderRadius:3,boxShadow:3}}>
                <Box p={1}>
                    <TextInfoTicket
                        titulo='Estado'
                        descripcion={(tarea?.estado_tarea?.nombre_et === 'REPROGRAMADO' && tarea?.observaciones_t === 'CRM')
                            ? 'INSERTAR FECHA'
                            : tarea?.estado_tarea?.nombre_et
                        }
                    />
                    {tarea && tarea?.motivoReprogramadoObservaciones_t &&(
                        <TextInfoTicket titulo='Motivo Reprogramado' descripcion={tarea?.motivoReprogramadoObservaciones_t} />
                    )}
                    {tarea && tarea?.fecha_t &&(
                        <TextInfoTicket titulo='Fecha' descripcion={FechaFunction.format(tarea?.fecha_t, 'dd/MM/yyyy')} />
                    )}
                    <TextInfoTicket titulo='Horario' descripcion={`${tarea?.horaIni_t || ''} - ${tarea?.horaFin_t || ''}`} />
                    {tarea && tarea?.createdAt_t &&(
                        <TextInfoTicket titulo='Creado' descripcion={FechaFunction.format(new Date(tarea?.createdAt_t), 'dd/MM/yyyy HH:mm:ss')} />
                    )}
                    <p className='text-center' style={{marginBottom: -5}}>
                        <a className="text-center" href={`http://calendario.nubicom.com.ar/tareas/${tarea?.id_t}/editar`} target='_blank' rel="noreferrer">
                            Ir al Calendario
                        </a>
                    </p>
                </Box>
            </Collapse>
        </Box>
    )
}

export default ListTarea