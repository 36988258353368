import { Box, Grid, Typography } from '@mui/material';
import {useWindowWidth} from '@react-hook/window-size';
import styled,{css} from 'styled-components';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const StyleCircle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box-circle{
        width: 3em;
        height: 3em;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 2em;
        border-radius: 50%;
        background: ${props => props.background};
        display: inline-block;
        color: ${props => props.color};
        position: relative;
    }
    .box-circle::before{
        content: "";
        position: absolute;
        zindex: 1;
        left: 3.5em;
        top: 50%;
        transform: translateY(-50%);
        height: 3px;
        width: 100%;
        background: #000;
    }
    .title{
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        height: 2rem;
        text-align: center;
        font-weight: bold;
        font-size: ${props => props.selected?'normal':'x-small'};
    }
`

const StylesBox = styled.div`
    flex: 1;
    .box-step{
        cursor: ${props => props.validate?'pointer':'default'};
        color: ${props => props.color};
        display:block;
        background: ${props => props.background};
        text-decoration: none;
        position:relative;
        height: 80px;
        padding: 0 10px 0 5px;
        border: 30px;
        margin-right: 1.7rem;
        padding-left: 1.5rem;
        line-height: 20px;
        align-items: center;
        display: flex;
        border-radius: 10px;
        .box-header{
            text-align: left;
            .title{
                font-weight: bold;
            }
            .subtitle{
                font-size: small;
            }
        }
        ${props => props.validate && css`
            &:hover{
                background-color: #4AA8EA;
                color: white;
                &:before{
                    border-color: #4AA8EA;
                    border-left-color:transparent;
                }
                &:after{
                    border-left-color:#4AA8EA;
                }
            }
        `}
        &:active{
            background-color: #4AA8EA;
            &:before{
                border-color: #4AA8EA;
                border-left-color:transparent;
            }
            &:after{
                border-left-color: #4AA8EA;
            }
        }
    }
`;

function StepTitle({
    title,
    index,
    subtitle,
    selected,
    validate,
    className,
    onClick,
    icon
}){
    const onlyWidth = useWindowWidth();

    let background = 'white';
    let color = 'black';
    if(selected){
        background = '#fff';
        color = '#000';
    } else if(validate){
        background = '#e6f0ff';
        color = '#4582ec';
    } else {
        background = '#d1d1d1d1';
        color = 'grey';
    }
    const onClickStep = () => {
        if(validate){
            onClick()
        }
    }
    return(
        <>
            { onlyWidth < 992 ? (
                    <StyleCircle className={className} color={color} background={background} validate={validate} onClick={onClickStep} selected={selected} aria-hidden="true">
                        <div className="box-circle">
                            {icon}
                        </div>
                        <div className="title text-gray-900">{title}</div>
                    </StyleCircle>
                ) : (
                    <StylesBox className={className} color={color} background={background} validate={validate}>
                        <Box className="box-step" onClick={onClickStep} aria-hidden="true" sx={{boxShadow: 5}}>
                            <Grid container>
                                <Grid item xs={3} md={3} display='flex' justifyContent='center' alignItems='center'>
                                    <Box sx={{ boxShadow:4, width:45, height: 45,borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {icon}
                                    </Box>
                                </Grid>
                                <Grid item xs={8} md={8} display='flex' justifyContent='start' alignItems='center'>
                                    <div className="box-header">
                                        <Box className="title">{title}</Box>
                                        <div className="subtitle">{subtitle}</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </StylesBox>
            )}
        </>
    );
}

StepTitle.defaultProps = {
    title:'',
    subtitle:'',
    selected: false,
    validate: false,
    onClick: () => {},
};

export default StepTitle;