import { Box, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonBasic } from '../../components'
import AuthService from '../../services/auth.service'

const RecoverPasswordScreen = () => {
    const [textEmail, setTextEmail] = useState('');
    const [textEmailErr, setTextEmailErr] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    useEffect(() => {
        if(validateEmail(textEmail)){
            setTextEmailErr(false)
        }else{
            setTextEmailErr(true)
        }
    }, [textEmail])

    const onSubmit = async() =>{
        if(validateEmail(textEmail)){
            setIsLoading(true)
            await AuthService.recoverPassword(textEmail).then(()=>{
                navigate(`/recover/password/ok/${textEmail}`)
            }).catch(()=>{
                setIsLoading(false)
            })
        }
    }
    return (
        <Box sx={{height: {xs:'100%',md:'100vh'}, width: '100%', background: '#fff',padding:17}}>
            <img src='/img/Zeta-black.png' style={{height: 80,width:170}} />
            <Grid container>
                <Grid item xs={12} md={8} lg={6} xl={5} sx={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                    <img src='/img/recoverPassword.png' style={{width:400, height: 400}} />
                </Grid>
                <Grid item flex={1} xs={12} md={4} lg={6} xl={7} sx={{display: 'flex',justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h1'>¿Olvidaste tu contraseña?</Typography>
                    <Typography sx={{color: '#000',marginTop: 3}} variant='h5'>Ingresa el correo electrónico con el que te dieron de alta inicialmente.</Typography>
                    <Typography sx={{color: '#000'}} variant='h5'>Te enviaremos un link para recuperar tu clave.</Typography>
                    <TextField
                        variant='standard'
                        focused
                        type='email'
                        label='Email'
                        value={textEmail}
                        onChange={(e)=>setTextEmail(e.target.value)}
                        InputLabelProps={{style:{color: '#000', fontSize: 17}}}
                        InputProps={{ style:{ fontWeight: 'bold',fontSize:15,color: '#000' } }}
                        error={textEmailErr}
                        helperText={textEmailErr && 'Ingrese un correo valido'}
                        sx={{
                            width: 450,
                            marginTop:10,
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "#000"
                            },
                        }}
                    />
                    <ButtonBasic
                        textButton='Enviar link a este mail'
                        sx={{
                            width: 450,
                            marginTop: 7,
                            height:50,
                            borderRadius: 20,
                            fontSize:15,
                        }}
                        variant='wk'
                        color='nextStep'
                        isDisabled={textEmailErr}
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                    />
                    <Typography
                        sx={{color: 'gray',marginTop:2,cursor: 'pointer'}}
                        onClick={()=>navigate('/login')}
                    >
                        Volver al inicio de sesión
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    )
}

export default RecoverPasswordScreen