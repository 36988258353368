import { useState, useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { ButtonBasic, CardTabInfoTicket, CardViewTicket, SelectAbiertoCerradoTicket, SelectFilterStageTicket, SelectMotivoTicket } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalCrearTicketNubicom from '../../../modals/ticket/ModalCrearTicketNubicom';
import ModalCrearTicketNubicomServicio from '../../../modals/ticket/ModalCrearTicketNubicomServicio';

const TabCuentaTicket = ({tickets,cuenta,preservicio,nombreCompleto,onGuardar,servicioNewTk}) => {
    const [ticketsView, setTicketsView] = useState([]);
    const [contVencidos, setContVencidos] = useState(0);
    const [arrFiltroMotivoTk, setArrFiltroMotivoTk] = useState([]);
    const [filtroIdMotivo, setFiltroIdMotivo] = useState(null);
    const [filtroTK, setfiltroTK] = useState('todos');

    const onClickTicketNubi = () => {
        if(preservicio){
            showModal(ModalCrearTicketNubicom,{modalNewTk:{ contactoId: preservicio,type: 'nubicom',nombreCompleto,onGuardar:onGuardar}});
        }else if(cuenta){
            showModal(ModalCrearTicketNubicom,{modalNewTk:{ cuentaId: cuenta,type: 'nubicom',nombreCompleto,onGuardar:onGuardar}});
        }
    }
    const onClickTicketNubiServicio = () => {
        showModal(ModalCrearTicketNubicomServicio,{modalNewTk:servicioNewTk, onGuardar:onGuardar});
    }
    useEffect(() => {
        if(tickets?.length > 0){
            const tkOrdenados =  tickets?.sort((a, b) => b?.id_tk - a.id_tk);
            setContVencidos(tkOrdenados?.filter((filTk)=> filTk?.estado_tk === 'VENCIDO').length)
            const arrMotivoTk = []
            tkOrdenados?.forEach(filMt => {
                if(!arrMotivoTk.includes(filMt.motivo_tk)){
                    arrMotivoTk.push(filMt.motivo_tk)
                }
            });
            setArrFiltroMotivoTk(arrMotivoTk)
        }
    }, [tickets])
    useEffect(() => {
        if(tickets?.length > 0){
            let ticketViewFilter = tickets?.sort((a, b) => b?.id_tk - a.id_tk);;
            if(filtroIdMotivo){
                const filtroAct = ticketViewFilter.filter((tk)=> tk?.motivo_tk === filtroIdMotivo)
                ticketViewFilter = filtroAct;
            }else{
                ticketViewFilter = ticketViewFilter;
            }
            if(filtroTK !== 'todos'){
                if(filtroTK === 'ABIERTO'){
                    const filtroAct = ticketViewFilter.filter((tk)=> !tk?.cerrado_tk)
                    ticketViewFilter = filtroAct;
                }else{
                    const filtroAct = ticketViewFilter.filter((tk)=> tk?.cerrado_tk)
                    ticketViewFilter = filtroAct;
                }
            }else{
                ticketViewFilter = ticketViewFilter;
            }
            setTicketsView(ticketViewFilter)
        }else{
            setTicketsView([])
        }
    }, [tickets,filtroIdMotivo,filtroTK])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
                <CardTabInfoTicket
                    texto='Tickets creados'
                    cantidad={tickets?.length ||  0}
                    icon={<ConfirmationNumberOutlinedIcon sx={{color: '#0364ff', fontSize: 40}} />}
                    backgroundIcon='#e6f0ff !important'
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <CardTabInfoTicket
                    texto='Tickets vencidos'
                    cantidad={contVencidos || 0}
                    icon={<ConfirmationNumberOutlinedIcon sx={{color: '#e53939', fontSize: 40}} />}
                    backgroundIcon='#fce7e7 !important'
                />
            </Grid>
            <Grid item xs={12} sm={2} display='flex' justifyContent='center' alignItems='center'>
                {(cuenta || preservicio) && (
                    <ButtonBasic
                        variant='wk'
                        color='nextStep'
                        textButton='Nuevo Ticket'
                        iconStart={<AddIcon />}
                        fullWidth
                        onClick={()=>onClickTicketNubi()}
                    />
                )}
                {servicioNewTk && (
                    <ButtonBasic
                        variant='wk'
                        color='nextStep'
                        textButton='Nuevo Ticket'
                        iconStart={<AddIcon />}
                        fullWidth
                        onClick={()=>onClickTicketNubiServicio(servicioNewTk)}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={1} display='flex' justifyContent='center' alignItems='center'>
                <Typography sx={{color: '#15294b', fontWeight: 'bold'}}>Filtrar</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography sx={{color: '#15294b'}}>Tipo</Typography>
                <SelectMotivoTicket
                    isClearable
                    emp='nubicom'
                    value={filtroIdMotivo}
                    onChange={(e)=>setFiltroIdMotivo(e?.value)}
                    filterExist={arrFiltroMotivoTk}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography sx={{color: '#15294b'}}>Estado</Typography>
                <SelectAbiertoCerradoTicket
                    value={filtroTK}
                    onChange={(e)=>setfiltroTK(e.value)}
                />
            </Grid>
            <Grid item xs={12} sm={4} display='flex' justifyContent='end' alignItems='center'>
                <Typography sx={{color: '#15294b', fontWeight: 'bold'}} variant='h6'>{`${ticketsView?.length || '0'} resultados`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={{border:1, borderColor: '#ebedf0',borderRadius:1}}>
                    <Grid item xs={8} sm={10} p={1.5}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml:10}}>Ticket</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} display='flex' alignItems='center'>
                        <Divider orientation='vertical' sx={{background: '#ebedf0'}} />
                        <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',ml:4}}>Estado</Typography>
                    </Grid>
                </Grid>
                <Box mb={5}>
                    {ticketsView?.length > 0 && (
                        ticketsView.map((tkv)=> <CardViewTicket key={tkv?.id_tk} item={tkv} /> )
                    )}
                </Box>
            </Grid>
        </Grid>
    )
}

export default TabCuentaTicket