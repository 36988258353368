import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectEstadoPreServicio = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    defaultProps,
    isMulti,
    restringir,
    negativaFO,
    negativaRF
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [options, setOptions] = useState([
        {
            value: 'EN ESPERA DE DA',
            label: 'EN ESPERA DE DA'
        },
        {
            value: 'FECHA PENDIENTE DE ASIGNAR',
            label: 'FECHA PENDIENTE DE ASIGNAR'
        },
        {
            value: 'FECHA ASIGNADA',
            label: 'FECHA ASIGNADA'
        },
        {
            value: 'NO LE INTERESA',
            label: 'NO LE INTERESA'
        },
        {
            value: 'INSTALADO',
            label: 'INSTALADO'
        },
        {
            value: 'A REPROGRAMAR',
            label: 'A REPROGRAMAR'
        },
        {
            value: 'PENDIENTE DE ALTA EN SOFTLAND POR VALIDACION DE DOCUMENTACION',
            label: 'PENDIENTE DE ALTA EN SOFTLAND POR VALIDACION DE DOCUMENTACION'
        },
        {
            value: 'SIN LINEA DE VISTA',
            label: 'SIN LINEA DE VISTA'
        },
        {
            value: 'BAJA SEÑAL',
            label: 'BAJA SEÑAL'
        },
        {
            value: 'FALTA DE POSTE',
            label: 'FALTA DE POSTE'
        },
        {
            value: 'POSTE EN MAL ESTADO',
            label: 'POSTE EN MAL ESTADO'
        },
        {
            value: 'INSTALACION CANCELADA',
            label: 'INSTALACION CANCELADA'
        },
        {
            value: 'CANCELADO POR FALTA DE DOCUMENTACIÓN',
            label: 'CANCELADO POR FALTA DE DOCUMENTACIÓN'
        },
        {
            value: 'NUEVO PRE-SERVICIO CORPORATIVO',
            label: 'NUEVO PRE-SERVICIO CORPORATIVO'
        },
        {
            value: 'NUEVO PRE-SERVICIO GOBIERNO',
            label: 'NUEVO PRE-SERVICIO GOBIERNO'
        },
        {
            value: 'NO AUTORIZADO - VIP',
            label: 'NO AUTORIZADO - VIP'
        }
    ]);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        if(typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = options.find(it=>it.value === va);
                if(item){
                    return {
                        value:item.value,
                        label:item.label
                    };
                }
                return null
            }))
        }else{
            setSelectedValue(null);
        }
    }, [options,value]);
    useEffect(() => {
        if(restringir){
            setOptions([
                {
                    value: 'EN ESPERA DE DA',
                    label: 'EN ESPERA DE DA'
                },
                {
                    value: 'FECHA PENDIENTE DE ASIGNAR',
                    label: 'FECHA PENDIENTE DE ASIGNAR'
                },
                {
                    value: 'FECHA ASIGNADA',
                    label: 'FECHA ASIGNADA'
                },
                {
                    value: 'NO LE INTERESA',
                    label: 'NO LE INTERESA'
                },
                {
                    value: 'A REPROGRAMAR',
                    label: 'A REPROGRAMAR'
                },
                {
                    value: 'SIN LINEA DE VISTA',
                    label: 'SIN LINEA DE VISTA'
                },
                {
                    value: 'BAJA SEÑAL',
                    label: 'BAJA SEÑAL'
                },
                {
                    value: 'FALTA DE POSTE',
                    label: 'FALTA DE POSTE'
                },
                {
                    value: 'POSTE EN MAL ESTADO',
                    label: 'POSTE EN MAL ESTADO'
                }
            ])
        }
    }, [restringir])
    useEffect(() => {
        if(negativaRF){
            setOptions([
                {
                    value: 'SIN LINEA DE VISTA',
                    label: 'SIN LINEA DE VISTA'
                },
                {
                    value: 'BAJA SEÑAL',
                    label: 'BAJA SEÑAL'
                },
            ])
        }
    }, [negativaRF])
    useEffect(() => {
        if(negativaFO){
            setOptions([
                {
                    value: 'ACCION SUPEDITADA A ENTIDAD EXTERNA',
                    label: 'ACCION SUPEDITADA A ENTIDAD EXTERNA'
                },
                {
                    value: 'NAP NO DISPONIBLE',
                    label: 'NAP NO DISPONIBLE'
                },
            ])
        }
    }, [negativaFO])
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={disabled}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectEstadoPreServicio.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectEstadoPreServicio;