import { useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { Wizard } from '../../../components/index';
import ContactosAction from '../../../store/actions/contactos';
import { Box } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FormPaso1SinCuenta from './FormPaso1SinCuenta';
import FormPaso2SinCuenta from './FormPaso2SinCuenta';
import FormPaso3SinCuenta from './FormPaso3SinCuenta';
import TratoService from '../../../services/tratos.service';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
const SinCuentaNuevoScreen = ({
    iniciarContacto,
    iniciarContrato
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { item } = (location && location.state) || { };
    const options = [
      {
        title: 'Contacto',
        subtitle: 'Datos del cliente',
        icon: <AccountBoxIcon sx={{fontSize: 35}} />
      },{
        title: 'Contrato',
        subtitle: 'Datos del contrato',
        icon: <DescriptionOutlinedIcon sx={{fontSize: 35}} />
      },{
        title: 'Servicios',
        subtitle: 'Servicios seleccionados',
        icon: <TaskAltIcon sx={{fontSize: 35}} />
      }
    ];
    const actualizar = async() =>{
      await TratoService.getById(item.id_t,{type:'nubicom'}).then((resp)=>{
        iniciarContrato(resp)
        if(!resp?.cuentaId_t && resp?.contacto){
            iniciarContacto(resp?.contacto)
        }
      })
    }
    useEffect(() => {
        if(item?.id_t){
            actualizar()
        }
        document.title = 'Alta Softland';
    }, []);

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto',maxWidth:'1280px', margin: 'auto'}} className='side'>
            <Box p={2} marginBottom={0}>
                <Wizard options={options}>
                    <FormPaso1SinCuenta item={item} />
                    <FormPaso2SinCuenta />
                    <FormPaso3SinCuenta />
                </Wizard>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SinCuentaNuevoScreen);