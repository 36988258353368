import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { ButtonBasic} from '../../components';
import DownloadFile from '../../services/downloadFile.service';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalRepDownRangoFecha from './ModalRepDownRangoFecha';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FechaFunction from '../../function/FechaFunction';
import ConfirmationDialog from '../default/ConfirmationDialog';
import {toast} from 'react-toastify';

const ModalReporteDownload = ({show, hideModal, reporte,actualizarListReports,permisos}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [rangoFechasArray, setRangoFechasArray] = useState([]);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true);
        if(rangoFechasArray?.length > 0){
            const form = {
                reporteId_lr: reporte?.id_rts,
                rangoFechasArray: rangoFechasArray,
                tipo_lr: 'NORMAL'
            }
            if(permisos['reportes.isUrgent'] && isUrgent){
                form.isUrgent_lr = true;
            }
            await DownloadFile.reportExcelNubiV2(form)
            .then(()=>{
                toast.success('Tu solicitud de reporte ha sido recibida. Te notificaremos cuando esté listo para descargar.', {
                    position: "top-right",
                    autoClose: 5500,
                    pauseOnHover: false,
                });
                if(actualizarListReports){
                    actualizarListReports()
                }
                handleClose()
            })
            .catch(()=>{
                setIsLoading(false)
            })
            .finally(()=>{
                setIsLoading(false)
            })
        }
    };
    const onOpenModalRangoFecha = () => {
        showModal(ModalRepDownRangoFecha, { tipo: reporte?.tipo_rts,rangoFechasArray: rangoFechasArray, setRangoFechasArray: setRangoFechasArray })
    }
    const onEliminarValid = (idValid) =>{
        const validFilter = rangoFechasArray?.filter((ivd)=> ivd?.keyCampo !== idValid)
        setRangoFechasArray(validFilter)
    }
    const onOpenModalDeleteValid = (idValid) =>{
        showModal(ConfirmationDialog, {
            title: 'Eliminar el filtro',
            message: '¿Esta seguro que desea eliminar el filtro?',
            onConfirm: () => onEliminarValid(idValid)
        });
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Descarga del Reporte: ${reporte?.titulo_rts}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} display='flex' justifyContent='flex-start' alignItems='center'>
                            <Typography sx={{color: '#000', fontWeight: 'bold'}}>Filtro de Fechas</Typography>
                        </Grid>
                        <Grid item xs={6} display='flex' justifyContent='end'>
                            <ButtonBasic
                                iconStart={<AddIcon sx={{color: '#0364ff'}} />}
                                textButton='Nuevo filtro'
                                variant='wk'
                                color='nextWhite'
                                sx={{border: 1 , borderColor: '#0364ff'}}
                                onClick={()=>onOpenModalRangoFecha()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            { rangoFechasArray?.length > 0 ? (
                                <Grid container spacing={1}>
                                    {rangoFechasArray.map((rnfecha)=>(
                                        <Grid item xs={12} md={6} key={rnfecha?.keyCampo}>
                                            <Card sx={{background: '#f5f5f5 !important', boxShadow: 3,p:1, borderRadius: 3}}>
                                                <Grid container>
                                                    <Grid item xs={10} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                                        <Typography sx={{color: '#000',fontWeight: 'bold'}}>{rnfecha?.itemsCampo?.label}</Typography>
                                                        <Typography sx={{color: '#000'}}>{`Desde: ${FechaFunction.format(rnfecha?.fechaIni,'dd-MM-yyyy')}`}</Typography>
                                                        <Typography sx={{color: '#000'}}>{`Hasta: ${FechaFunction.format(rnfecha?.fechaFin,'dd-MM-yyyy')}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <IconButton sx={{boxShadow: 3}} onClick={()=>onOpenModalDeleteValid(rnfecha?.keyCampo)}>
                                                            <DeleteOutlineOutlinedIcon sx={{color: '#15294b'}} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ):(
                                <Box sx={{background: '#f6f8fc',minHeight: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography sx={{color: '#808b9e', fontWeight: 'bold', textAlign: 'center'}}>Aún no se realizaron filtro de fechas</Typography>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {permisos['reportes.isUrgent'] && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onClick={()=>setIsUrgent(!isUrgent)}
                                            checked={isUrgent}
                                            sx={{
                                                color: '#000',
                                                '&.Mui-checked': {
                                                    color: '#000',
                                                },
                                            }}
                                        />
                                    }
                                    label="¿Necesita el reporte con urgencia?"
                                    componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                                    sx={{marginTop:2}}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={!rangoFechasArray || rangoFechasArray?.length === 0}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalReporteDownload