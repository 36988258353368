import axios from 'axios';
const base = 'notify';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

const NotifyService = {
    getAll
};
export default NotifyService;
