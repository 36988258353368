
import {Route, Routes} from 'react-router-dom';
import { connect, useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box, Stack, Typography } from '@mui/material';
import { Feed, Navbar, Sidebar, SidebarResponsive } from '../components/index';
import HomeScreen from '../screens/HomeScreen';
import { useContext, useEffect, useState } from 'react';
import { logout, userme } from '../store/actions/authActions';
import TicketGetByIdNubiScreen from '../screens/tickets/TicketGetByIdNubiScreen';
import PlantillaEmailScreen from '../screens/plantillas/PlantillaEmailScreen';
import ContactoNuevoNUBIScreen from '../screens/contacto/nuevoNubicom/ContactoNuevoNUBIScreen';
import PotencialesClientesScreen from '../screens/contacto/PotencialesClientesScreen';
import RedirectTicket from '../screens/loading/RedirectTicket';
import usePermisos from '../hooks/usePermisos';
import { SocketContext } from '../context/SocketContext';
import Push from 'push.js'
import {toast} from 'react-toastify';
import { actualizarLugares,
        actualizarMentions,
        actualizarNotifiTicket,
        actualizarNotifyGral,
        actualizarMotivoBajaTK,
        actualizarMotivoTK,
        actualizarNivelAdmTK,
        actualizarSubMotivoTK,
        actualizarTratosCam,
        actualizarUsuariosSelect,
        actualizaSubTipoTK,
        actualizaTipoTK,
        actualizarNotifyTk911,
        actualizarNotifiCom911,
        actualizarHashtags } from '../store/slices/default.slice';
import { finishTicketReload,
        startTicketReload,
        startTicketIndividualReload,
        finishTicketIndividualReload,
        startTicketGroupReload,
        finishTicketGroupReload } from '../store/actions/ticket';
import TablaTicketScreen from '../screens/tickets/TablaTicketScreen';
import PlantillaMotivoTicketsScreen from '../screens/plantillas/PlantillaMotivoTicketsScreen';
import PlantillaCierreTicketScreen from '../screens/plantillas/PlantillaCierreTicketScreen';
import ContactoFindByIdScreen from '../screens/contacto/ContactoFindByIdScreen';
import CuentaFindByIdScreen from '../screens/contacto/CuentaFindByIdScreen';
import PlantillaBonificacionSAIScreen from '../screens/plantillas/PlantillaBonificacionSAIScreen';
import TipoTicketScreen from '../screens/admTicket/TipoTicketScreen';
import SubTipoTicketScreen from '../screens/admTicket/SubTipoTicketScreen';
import NivelAdministrativoScreen from '../screens/admTicket/NivelAdministrativoScreen';
import MotivoBajaScreen from '../screens/admTicket/MotivoBajaScreen';
import PermisosScreen from '../screens/administrador/PermisosScreen';
import GruposScreen from '../screens/administrador/GruposScreen';
import MapaFibraOpticaScreen from '../screens/fibraOptica/MapaFibraOpticaScreen';
import ServicioCuentaVerScreen from '../screens/servicio/ServicioCuentaVerScreen';
import PreServicioVerScreen from '../screens/servicio/PreServicioVerScreen';
import CuentaExistenteNuevoScreen from '../screens/altaSoftland/cuentaExistente/CuentaExistenteNuevoScreen';
import SinCuentaNuevoScreen from '../screens/altaSoftland/sinCuenta/SinCuentaNuevoScreen';
import ContactoCambioTitularScreen from '../screens/contacto/cambioTitular/ContactoCambioTitularScreen';
import ReportesScreen from '../screens/reportes/ReportesScreen';
import PreServiciosScreen from '../screens/preServicios/PreServiciosScreen';
import HashTagScreen from '../screens/admTicket/HashTagScreen';
import ArtFallaTkScreen from '../screens/admTicket/ArtFallaTkScreen';
import SolucionTecTkScreen from '../screens/admTicket/SolucionTecTkScreen';
import ProblematicaTkScreen from '../screens/admTicket/ProblematicaTkScreen';
import PreServiciosPendientesScreen from '../screens/preServicios/PreServiciosPendientesScreen';
import PotencialesCorporativosScreen from '../screens/corporativos/PotencialesCorporativosScreen';
import ContactoNuevoCorpoScreen from '../screens/corporativos/nuevoCorporativoNubi/ContactoNuevoCorpoScreen';
import TablaProcesosScreen from '../screens/procesos/TablaProcesosScreen';
import ProcesosGetByIdScreen from '../screens/procesos/ProcesosGetByIdScreen';
import ProcesosScreen from '../screens/procesos/ProcesosScreen';
import TablaRadioEnlaceScreen from '../screens/radioEnlace/TablaRadioEnlaceScreen';
import HistorialKMZScreen from '../screens/radioEnlace/HistorialKMZScreen';
import TablaFONapsScreen from '../screens/fibraOptica/TablaFONapsScreen';
import TablaFOZonasScreen from '../screens/fibraOptica/TablaFOZonasScreen';
import TablaFOHistorialScreen from '../screens/fibraOptica/TablaFOHistorialScreen';
import PreTicketsScreen from '../screens/preTickets/PreTicketsScreen';
import EmailInvalidoScreen from '../screens/admTicket/EmailInvalidoScreen';
import PotencialesGobiernoScreen from '../screens/gobierno/PotencialesGobiernoScreen';
import ContactoNuevoGobiernoScreen from '../screens/gobierno/nuevoGobiernoNubi/ContactoNuevoGobiernoScreen';
import GruposViewScreen from '../screens/grupos/GruposViewScreen';
import TablaFORangoCoberturaScreen from '../screens/fibraOptica/TablaFORangoCoberturaScreen';
import ConsultasClientesScreen from '../screens/consultas/ConsultasClientesScreen';
import ConsultasClientesCorpoScreen from '../screens/corporativos/ConsultasClientesCorpoScreen';
import ConsultasClientesGobiernoScreen from '../screens/gobierno/ConsultasClientesGobiernoScreen';
import MapaFibraOpticaAllScreen from '../screens/fibraOptica/MapaFibraOpticaAllScreen';
import AnaliticsUserIdScreen from '../screens/analitics/AnaliticsUserIdScreen';
import AnaliticsGralScreen from '../screens/analitics/AnaliticsGralScreen';
import UsuariosAsesoresScreen from '../screens/asesores/UsuariosAsesoresScreen';
import HomeAsesorScreen from '../screens/asesores/HomeAsesorScreen';
import ContactoNuevoNUBIAsesorScreen from '../screens/contacto/nuevoAsesor/ContactoNuevoNUBIAsesorScreen';
import PotencialesClientesAsesorScreen from '../screens/contacto/PotencialesClientesAsesorScreen';
import ConsultasClientesAsesorScreen from '../screens/consultas/ConsultasClientesAsesorScreen';
import RestoreIcon from '@mui/icons-material/Restore';
import ObjetivosScreen from '../screens/asesores/ObjetivosScreen';
import NovedadesScreen from '../screens/asesores/NovedadesScreen';
import SubCierreTkScreen from '../screens/admTicket/SubCierreTkScreen';
import RecordatorioScreen from '../screens/recordatorio/RecordatorioScreen';

function MainRoute({
    onLogout,
    onUserLoad,
    user
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const permisos = usePermisos();
    const { sockets } = useContext( SocketContext );

    const [openDrawer, setOpenDrawer] = useState(false);
    const onOpenDrawerChange = () => setOpenDrawer(!openDrawer);
    const [openDrawerResponsive, setOpenDrawerResponsive] = useState(false);
    const onOpenDrawerChangeResponsive = () => setOpenDrawerResponsive(!openDrawerResponsive);

    useEffect(() => {
        onUserLoad()
    }, [])

    useEffect(() => {
        if(user.dni_p){
            const canalUser = user?.dni_p.toString();
            const idUser = user?.id_p.toString();
            const canalUserReload = `${user?.dni_p.toString()}R`;
            sockets?.on( canalUser , () => {
                Push.create("Te Asignaron un Ticket!", {
                    body: "Se te asigno un ticket Individual.",
                    icon: '/img/logoZ-32x32.png',
                    timeout: 6000,
                    onClick: () => {
                        navigate('/tickets')
                    }
                });
                toast.info('Te Asignaron un Ticket!', {
                    position: "top-right",
                    autoClose: 4000,
                    pauseOnHover: false,
                });
            })
            dispatch(actualizarMentions())
            sockets?.on( `mentions${idUser}` , () => {
                dispatch(actualizarMentions())
            })
            dispatch(actualizarHashtags())
            sockets?.on( 'hashtags' , () => {
                dispatch(actualizarHashtags())
            })
            dispatch(actualizarNotifiTicket())
            sockets?.on( `notifications${idUser}` , () => {
                dispatch(actualizarNotifiTicket())
            })
            dispatch(actualizarNotifyGral())
            sockets?.on( `notifygral${idUser}` , () => {
                dispatch(actualizarNotifyGral())
            })
            sockets.on( canalUserReload , () => {
                dispatch(startTicketIndividualReload());
                setTimeout(() => {
                    dispatch(finishTicketIndividualReload());
                }, 100);
            })
        }
        if(user?.grupos?.length > 0){
            user?.grupos?.forEach(gr => {
                const canalGrupo = user?.gr?.id_g;
                const canalGrupoReload = `${user?.gr?.id_g}R`;

                sockets?.on( canalGrupo , () => {
                    Push.create("Te Asignaron un Ticket!", {
                        body: `Nuevo Ticket en el Grupo ${gr?.nombre_g}.`,
                        icon: '/img/logoZ-32x32.png',
                        timeout: 6000,
                        onClick: () => {
                            navigate('/tickets')
                    }
                    });
                    toast.info(`Nuevo Ticket en el Grupo ${gr?.nombre_g}.`, {
                        position: "top-right",
                        autoClose: 4000,
                        closeOnClick: true,
                        pauseOnHover: false,
                    });
                })
                sockets.on( canalGrupoReload , () => {
                    dispatch(startTicketGroupReload());
                    setTimeout(() => {
                        dispatch(finishTicketGroupReload());
                    }, 100);
                })
            });
        }
    }, [user]);

    useEffect(() => {
        if(permisos['lugar.getAll']){
            dispatch(actualizarLugares())
            dispatch(actualizarUsuariosSelect())
            sockets.on( 'newLugar' , () => {
                dispatch(actualizarLugares())
            })
        }

        if(permisos['tratos.cvvGetAll']){
            dispatch(actualizarTratosCam())
        }

        if(permisos['motivoticket.getAll']){
            dispatch(actualizarMotivoTK())
            dispatch(actualizaTipoTK())
            dispatch(actualizaSubTipoTK())
            dispatch(actualizarNivelAdmTK())
            dispatch(actualizarMotivoBajaTK())
            dispatch(actualizarSubMotivoTK())

            sockets.on( 'newMotivoTK' , () => {
                dispatch(actualizarMotivoTK())
            })
            sockets.on( 'newSubMotivoTK' , () => {
                dispatch(actualizarSubMotivoTK())
            })
            sockets.on( 'newTipoTK' , () => {
                dispatch(actualizaTipoTK())
            })
            sockets.on( 'newSubTipoTK' , () => {
                dispatch(actualizaSubTipoTK())
            })
            sockets.on( 'newNivelAdmTK' , () => {
                dispatch(actualizarNivelAdmTK())
            })
            sockets.on( 'newMotivoBajaTK' , () => {
                dispatch(actualizarMotivoBajaTK())
            })
        }

        if(permisos['ticketNubi.getById']){
            sockets.on( 'ticketNubiRefresh' , (idSocket) => {
                const ticketId = localStorage.getItem('ticketId');
                if(ticketId === idSocket){
                    dispatch(startTicketReload());
                    setTimeout(() => {
                        dispatch(finishTicketReload());
                    }, 100);
                }
            })
        }

        if(permisos['ticketStr.getById']){
            sockets.on( 'ticketStrRefresh' , (idSocketStr) => {
                const ticketId = localStorage.getItem('ticketId');
                if(ticketId === idSocketStr){
                    dispatch(startTicketReload());
                    setTimeout(() => {
                        dispatch(finishTicketReload());
                    }, 100);
                }
            })
        }

        if(permisos['ticketIds.getById']){
            sockets.on( 'ticketIdsRefresh' , (idSocketIds) => {
                const ticketId = localStorage.getItem('ticketId');
                if(ticketId === idSocketIds){
                    dispatch(startTicketReload());
                    setTimeout(() => {
                        dispatch(finishTicketReload());
                    }, 100);
                }
            })
        }
        if(permisos['ticketCvv.All']){
            sockets.on( 'ticketCvvRefresh' , (idSocket) => {
                const ticketId = localStorage.getItem('ticketId');
                if(ticketId === idSocket){
                    dispatch(startTicketReload());
                    setTimeout(() => {
                        dispatch(finishTicketReload());
                    }, 100);
                }
            })
        }
    }, [permisos])

    useEffect(() => {
        if(user?.id_p && permisos['tratos.cvvGetAll']){
            const idUser = user?.id_p.toString();
            dispatch(actualizarNotifyTk911())
            dispatch(actualizarNotifiCom911())
            sockets?.on( `tkNotify911${idUser}` , () => {
                dispatch(actualizarNotifyTk911())
            })
            sockets?.on( `comNotify911${idUser}` , () => {
                dispatch(actualizarNotifiCom911())
            })
        }
    }, [user,permisos])

    const mentions = useSelector(state => state.default.mentions);
    const notifications = useSelector(state => state.default.notifiTk);
    const notifyGral = useSelector(state => state.default.notifyGral);

    const notifitk911 = useSelector(state => state.default.notifitk911);
    const notificom911 = useSelector(state => state.default.notificom911);

    const [countNotifi, setCountNotifi] = useState(0);
    const [countNotifiTk, setCountNotifiTk] = useState(0);
    const [countNotifi911, setCountNotifi911] = useState(0);

    useEffect(() => {
        setCountNotifi((mentions?.count || 0) + (notifications?.count || 0) + (notifyGral?.count || 0) )
        setCountNotifiTk(notifications?.count || 0)
    }, [mentions,notifications,notifyGral])

    useEffect(() => {
        setCountNotifi911((notifitk911?.count || 0) + (notificom911?.count || 0) )
    }, [notifitk911,notificom911])
    const CustomIcon = () => {
        return (
            <Box sx={{ width: '100%', background: 'red',display: 'flex',justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '50%', background: 'blue', borderRadius: 50 }}>
                    <Typography>asdasd</Typography>
                </Box>
            </Box>
        );
    }
    return (
        <Box bgcolor='#f0f2f5' sx={{position: 'fixed',width: '100%'}}>
            <SidebarResponsive openDrawer={openDrawerResponsive} onOpenDrawerChange={onOpenDrawerChangeResponsive} countNotifiTk={countNotifiTk} />
            <Stack direction='row' spacing={{xs:0,sm:0,md:0,lg:0}} marginRight={{md:0,lg:0}} justifyContent='space-between' className='side'>
                <Sidebar user={user} openDrawer={openDrawer} onOpenDrawerChange={onOpenDrawerChange} countNotifiTk={countNotifiTk} />
                <Feed>
                    <Navbar
                        onOpenDrawerChange={onOpenDrawerChange}
                        onOpenDrawerChangeResponsive={onOpenDrawerChangeResponsive}
                        onLogout={onLogout}
                        countNotifi={countNotifi}
                        countNotifi911={countNotifi911}
                    />
                    <Routes>
                        {user?.razonSocial_p === 'Asesor' ? (
                            <>
                                <Route end path="/" element={<HomeAsesorScreen />} />
                                <Route
                                    end
                                    path="/contactos/nuevonubi"
                                    element={<ContactoNuevoNUBIAsesorScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos"
                                    element={<PotencialesClientesAsesorScreen />}
                                />
                                <Route
                                    end
                                    path="/tabla/consultas"
                                    element={<ConsultasClientesAsesorScreen />}
                                />
                            </>
                        ):(
                            <>
                                <Route end path="/" element={<HomeScreen />} />
                                <Route
                                    end
                                    path="/ticket/:id/nubicom"
                                    element={<TicketGetByIdNubiScreen />}
                                />
                                <Route
                                    end
                                    path="/plantilla/emails"
                                    element={<PlantillaEmailScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos"
                                    element={<PotencialesClientesScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/nuevonubi"
                                    element={<ContactoNuevoNUBIScreen />}
                                />
                                <Route
                                    end
                                    path="/tickets"
                                    element={<TablaTicketScreen />}
                                />
                                <Route
                                    end
                                    path="/plantilla/motivos"
                                    element={<PlantillaMotivoTicketsScreen />}
                                />
                                <Route
                                    end
                                    path="/plantilla/cierreticket"
                                    element={<PlantillaCierreTicketScreen />}
                                />
                                <Route
                                    end
                                    path="/plantilla/bonificaciones"
                                    element={<PlantillaBonificacionSAIScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/tipo-ticket"
                                    element={<TipoTicketScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/sub-tipo-ticket"
                                    element={<SubTipoTicketScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/nivel-administrativo"
                                    element={<NivelAdministrativoScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/motivo-baja"
                                    element={<MotivoBajaScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/tags"
                                    element={<HashTagScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/articulo-falla"
                                    element={<ArtFallaTkScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/problematica"
                                    element={<ProblematicaTkScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/solucion-tecnica"
                                    element={<SolucionTecTkScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/email-invalido"
                                    element={<EmailInvalidoScreen />}
                                />
                                <Route
                                    end
                                    path="/administrador/permisos"
                                    element={<PermisosScreen />}
                                />
                                <Route
                                    end
                                    path="/administrador/grupos"
                                    element={<GruposScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/mapa"
                                    element={<MapaFibraOpticaScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/mapa-total"
                                    element={<MapaFibraOpticaAllScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/:id/:type"
                                    element={<ContactoFindByIdScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/:id/cuenta"
                                    element={<CuentaFindByIdScreen />}
                                />
                                <Route
                                    end
                                    path="/servicio/:id/cuenta"
                                    element={<ServicioCuentaVerScreen />}
                                />
                                <Route
                                    end
                                    path="/pre-servicio/:id/:type"
                                    element={<PreServicioVerScreen />}
                                />
                                <Route
                                    end
                                    path="/softland/nuevo/cliente"
                                    element={<SinCuentaNuevoScreen />}
                                />
                                <Route
                                    end
                                    path="/softland/nuevo/cuenta"
                                    element={<CuentaExistenteNuevoScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/cambio-titular"
                                    element={<ContactoCambioTitularScreen />}
                                />
                                <Route
                                    end
                                    path="/reports"
                                    element={<ReportesScreen />}
                                />
                                <Route
                                    end
                                    path="/recordatorios"
                                    element={<RecordatorioScreen />}
                                />
                                <Route
                                    end
                                    path="/pre/servicios"
                                    element={<PreServiciosScreen />}
                                />
                                <Route
                                    end
                                    path="/pre/servicios/pendientes"
                                    element={<PreServiciosPendientesScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/corporativos"
                                    element={<PotencialesCorporativosScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/nuevocorporativo"
                                    element={<ContactoNuevoCorpoScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/gobierno"
                                    element={<PotencialesGobiernoScreen />}
                                />
                                <Route
                                    end
                                    path="/contactos/nuevogobierno"
                                    element={<ContactoNuevoGobiernoScreen />}
                                />
                                <Route
                                    end
                                    path="/tabla/procesos"
                                    element={<TablaProcesosScreen />}
                                />
                                <Route
                                    end
                                    path="/procesos/:id"
                                    element={<ProcesosGetByIdScreen />}
                                />
                                <Route
                                    end
                                    path="/procesos"
                                    element={<ProcesosScreen />}
                                />
                                <Route
                                    end
                                    path="/radio-enlace/tabla"
                                    element={<TablaRadioEnlaceScreen />}
                                />
                                <Route
                                    end
                                    path="/radio-enlace/historial/kmz"
                                    element={<HistorialKMZScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/naps"
                                    element={<TablaFONapsScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/zonas"
                                    element={<TablaFOZonasScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/historial/kmz"
                                    element={<TablaFOHistorialScreen />}
                                />
                                <Route
                                    end
                                    path="/fibra-optica/rango/cobertura"
                                    element={<TablaFORangoCoberturaScreen />}
                                />
                                <Route
                                    end
                                    path="/pretickets"
                                    element={<PreTicketsScreen />}
                                />
                                <Route
                                    end
                                    path="/vista/grupos"
                                    element={<GruposViewScreen />}
                                />
                                <Route
                                    end
                                    path="/tabla/consultas"
                                    element={<ConsultasClientesScreen />}
                                />
                                <Route
                                    end
                                    path="/tabla/consultas/gobierno"
                                    element={<ConsultasClientesGobiernoScreen />}
                                />
                                <Route
                                    end
                                    path="/tabla/consultas/corporativos"
                                    element={<ConsultasClientesCorpoScreen />}
                                />
                                <Route
                                    end
                                    path="/analitica/general"
                                    element={<AnaliticsGralScreen />}
                                />
                                <Route
                                    end
                                    path="/analitica/personal"
                                    element={<AnaliticsUserIdScreen />}
                                />
                                <Route
                                    end
                                    path="/asesores/personal"
                                    element={<UsuariosAsesoresScreen />}
                                />
                                <Route
                                    end
                                    path="/asesores/novedades"
                                    element={<NovedadesScreen />}
                                />
                                <Route
                                    end
                                    path="/asesores/objetivos"
                                    element={<ObjetivosScreen />}
                                />
                                <Route
                                    end
                                    path="/administrar/sub-cierre-ticket"
                                    element={<SubCierreTkScreen />}
                                />
                            </>
                        )}
                        <Route
                            end
                            path="/load"
                            element={<RedirectTicket />}
                        />
                    </Routes>
                </Feed>
            {user?.razonSocial_p === 'Asesor' && (
                <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={0}
                        onChange={(event, newValue) => {
                            console.log(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
                        <BottomNavigationAction label="Custom" value="/custom" icon={<CustomIcon />} />
                        <BottomNavigationAction label="Archive" icon={<RestoreIcon />} />
                    </BottomNavigation>
                </Box>
            )}
            </Stack>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(logout()),
    onUserLoad: () => dispatch(userme())
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute);