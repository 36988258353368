import { useEffect, useMemo, useState } from 'react'
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ButtonBasic, InputEditQuill, SelectCSCosto, SelectCSRouter, SelectMotivoTicket, SelectNivelVIP } from '../../../components'
import TratoService from '../../../services/tratos.service';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
const FormPaso4Screen = ({
  contacto,
  contrato,
  previousStep,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [preViewContrato, setPreViewContrato] = useState(false);
  const [pregDATercero, setPregDATercero] = useState(false);
  const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const [formAll, setFormAll] = useState({
    nombre_t: {
      texto: null,
      textoErr: 'El nombre es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    direccion_t: {
      texto: null,
      textoErr: 'El apellido es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    celular_t: {
      texto: null,
      textoErr: 'El celular es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    contactoEmail_t: {
      texto: null,
      textoErr: 'El email es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    cargoinstalacion_t: {
      texto: null,
      textoErr: 'El costo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    router_tk: {
      texto: null,
      textoErr: 'El router es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    promdesc_t: {
      texto: '-',
      textoErr: 'La promocion es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    motivoTareaId: {
      texto: null,
      textoErr: 'El motivo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    tituloTk: {
      texto: null,
      textoErr: 'El titulo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    observacionesTarea: {
      texto: '',
      textoErr: 'La observacion es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    crearTarea: {
      texto: 'ticketsfecha',
      textoErr: 'La creacion de la tarea es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    debitoTercero_t: {
      texto: null,
      textoErr: 'El DA es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nivelVip_t: {
      texto: null,
      textoErr: 'El nivel de vip es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    isDepartment_t: {
      texto: 'N/A',
      textoErr: 'El edificio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
  });
  const onChangeForm = (value,input) =>{
    let formOld = formAll;
    if(!formOld[input]){
      formOld[input] = {
        texto: value,
        textoErr: `El ${input} es requerido`,
        estadoErr: false,
        tipo: 'string'
      }
    }else{
      formOld[input].texto = value
    }
    if((!value || value === '') && input !== 'telefono_c'){
      formOld[input].estadoErr = true
    }else{
      if(input === 'contactoEmail_t'){
        if(validateEmail(value)){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else{
        formOld[input].estadoErr = false
      }
    }
    setFormAll({...formOld})
  }
  const onChangeFormSetError = (input) =>{
    let formOld = formAll;
    formOld[input].estadoErr = true
    setFormAll({...formOld})
  }
  const styleInput = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 14,
      marginTop: -0.4
    },
    '& fieldset': {
        borderRadius: 2,
        height: 50,
        border: 1,
        borderColor: 'gray',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          border:1,
          color: '#000',
          height: 50,
        },
        '&:hover':{
          border: 0.1,
          borderRadius: 2,
          borderColor: 'gray',
          height: 45,
        },
        '&.Mui-focused': {
          '&:hover':{
            border: 0,
            borderRadius: 2,
            height: 45,
          }
        },
        color: 'gray',
        height: 45,
    }
  }), [])
  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
      marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border:1,
        height: 45,
        color: '#000'
      },
      '&:hover':{
        border: 1,
        borderRadius: 2,
        borderColor: 'gray',
        height: 40
      },
      '&.Mui-focused': {
        '&:hover':{
          border:0,
          height: 40
        }
      },
      color: 'gray',
      height: 40
    }
  }), [])
  useEffect(() => {
    if(contrato){
      onChangeForm(contacto?.nombreCompleto_c || '', 'nombre_t')
      onChangeForm(`${contrato?.barrio_t || ''} - ${contrato?.calle_t || ''}`, 'direccion_t')
      onChangeForm(contacto?.celular_c || '', 'celular_t')
      onChangeForm(contacto?.email_c || '', 'contactoEmail_t')
      if(contrato?.requiereAutorizacion === 'SI'){
        onChangeForm('pendienteAprobacion', 'crearTarea')
      }
      setPreViewContrato(false);
    }
  }, [contrato,contacto])
  const solicitarContratoPreVista = async() => {
    if(contrato?.servicios?.length > 0){
      setIsLoading(true);
      let isOk = false;
      const inputRequired = ['nombre_t', 'direccion_t','celular_t','contactoEmail_t', 'motivoTareaId',
      'tituloTk', 'observacionesTarea', 'crearTarea', 'cargoinstalacion_t','router_tk'];
      if(pregDATercero){
        inputRequired.push('inputRequired')
      }
      const form = {
        nombreCompletoContacto1_t: contacto?.nombreCompletoContacto1_c,
        dniContacto1_t: contacto?.dniContacto1_c,
        cpostal_c: contacto?.cpostal_c,
        iva_c: contacto?.iva_c || 'C',
        codpostal_t: contrato?.codpostal_t || '4400',
        nombreCompleto_c: contacto?.nombreCompleto_c,
        documento_c: contacto?.documento_c,
        direccion_c: `${contacto?.barrio_c || 'S/B'}, ${contacto?.direccion_c || 'S/C'}`,
        telefono_c: contacto?.telefono_c,
        nombreServicio_ds: contrato?.servicios[0]?.nombre_s,
        anchoBajadaServicio_ds: contrato?.servicios[0]?.anchoBajada_s,
        anchoSubidaServicio_ds: contrato?.servicios[0]?.anchoSubida_s,
        moneda_ds: contrato?.servicios[0]?.moneda_s,
        importeServicio_ds: contrato?.servicios[0]?.importe_s,
        coordenadas_t: `${contacto?.latitud_c}, ${contacto?.longitud_c}`,
      }
      if(contacto?.cuenta){
        form.direccion_c = contacto?.direccion_c
      }
      const propertyNames = Object.keys(formAll);
      await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
          form[name] = formAll[name].texto
        }else{
          if(inputRequired.includes(name)){
            isOk = true;
            onChangeFormSetError(name)
          }
        }
      }))
      if(!isOk){
        await AuxiliarFunction.downloadFile(
          TratoService.preView(form),
          'pre-contrato.pdf'
        )
        setTimeout(() => {
          setIsLoading(false);
          setPreViewContrato(true)
        }, 7000);
      }else{
        setIsLoading(false);
        toast.error('Revise la informacion.', {
          position: "top-right",
          autoClose: 2500,
          pauseOnHover: false,
        });
      }
    }else{
      toast.error('Revise la informacion.', {
        position: "top-right",
        autoClose: 2500,
        pauseOnHover: false,
      });
    }
  }
  const onSubmit = async() => {
    setIsLoading(true);
    let isOk = false;
    const inputRequired = ['nombre_t', 'direccion_t','celular_t','contactoEmail_t', 'motivoTareaId',
    'tituloTk', 'observacionesTarea', 'crearTarea'];
    if(contrato?.prefactibilidad !== 'NEGATIVA'){
      inputRequired.push('cargoinstalacion_t','router_tk')
    }
    if(pregDATercero){
      inputRequired.push('inputRequired')
    }
    const form ={
        ...contacto,
        ...contrato,
        type:'nubicom',
        nombreCompletoContacto1_t: contacto?.nombreCompletoContacto1_c,
        dniContacto1_t: contacto?.dniContacto1_c,
        categoria_t: contacto?.categoria_cxe || 'MAS',
        latitud_t: contacto?.latitud_c,
        longitud_t: contacto?.longitud_c,
        coordenadas_t: `${contacto?.latitud_c},${contacto?.longitud_c}`,
        contratistaSoft_t: contacto?.canalcomu_c || '01',
        barrio_t: contrato?.barrio_t,
        calle_t: contrato?.calle_t,
        codpostal_t: contrato?.codpostal_t || '4400',
        codprovincia_t: contrato?.codprovincia_t || '917',
        servicios: contrato?.servicios
    }
    if(contrato?.prefactibilidad === 'NEGATIVA'){
      form.prefactibilidadRF_t = 'NEGATIVA';
      form.prefactibilidadFO_t = 'NEGATIVA';
    }
    const propertyNames = Object.keys(formAll);
    await Promise.resolve(
      propertyNames.forEach((name)=>{
      if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
        form[name] = formAll[name].texto
      }else{
        if(inputRequired.includes(name)){
          isOk = true;
          onChangeFormSetError(name)
        }
      }
    }))
    if(!isOk){
      if(contacto?.cuenta){
          form.cuentaId_t = contacto?.cuenta;
      }
      if(contacto?.id_c){
          form.contactoId_t = contacto?.id_c;
          form.contactoXempId_t  = contrato?.contactoXempId_t;
      }
      await TratoService.register(form).then(()=>{
        if(form.crearTarea === 'tareacalendar'){
          window.open('http://calendario.nubicom.com.ar/tareas');
        }
        navigate('/contactos');
      })
      .catch(()=>{
          setIsLoading(false);
      })
    }else{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(Array.isArray(contrato?.servicios) && contrato?.servicios?.length > 1){
      const buscar = contrato?.servicios.find((e) => e?.nombre_s?.toUpperCase() === 'Bonificacion Adhesión a débito automático VISA - MAS'?.toUpperCase())
      if(buscar && buscar?.servicioIdSoftland_ds === '1010'){
        setPregDATercero(true);
        onChangeForm('PROPIA','debitoTercero_t');
      }else{
        setPregDATercero(false);
        onChangeForm(null,'debitoTercero_t');
      }
    }else{
      setPregDATercero(false)
      onChangeForm(null,'debitoTercero_t');
    }
    if(contrato?.prefactibilidad === 'NEGATIVA'){
      onChangeForm(65,'motivoTareaId');
      onChangeForm('PREFACTIBILIDAD', 'tituloTk')
      onChangeForm('<p><strong>Solicitud de prefactibilidad.</strong></p>', 'observacionesTarea');
    }
  }, [contrato])


  return (
    <Box sx={{overflow: 'overlay',height: '76vh', marginTop:3}} className='side'>
      <Card sx={{background: '#fff !important'}}>
        <Grid container mt={2} p={2} spacing={1}>
          <Grid xs={12} md={12}>
            <Typography sx={{color: '#15294b', fontWeight: 'bold',marginBottom:3}} variant='h4'>Formulario Pre-Venta</Typography>
          </Grid>
          <Grid item xs={12} md={5.9}>
            <TextField
              label='Nombre Completo'
              autoComplete='off'
              fullWidth sx={styleInput}
              value={formAll.nombre_t.texto}
              onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
              InputLabelProps={{shrink: formAll.nombre_t.texto}}
              error={formAll.nombre_t.estadoErr}
              helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
            />
          </Grid>
          <Grid item xs={12} md={5.9} ml={1}>
            <TextField
              label='Dirección de instalacion'
              autoComplete='off'
              fullWidth
              sx={styleInput}
              value={formAll.direccion_t.texto}
              onChange={(e)=>onChangeForm(e.target.value,'direccion_t')}
              InputLabelProps={{shrink: formAll.direccion_t.texto}}
              error={formAll.direccion_t.estadoErr}
              helperText={formAll.direccion_t.estadoErr && formAll.direccion_t.textoErr}
            />
          </Grid>
          <Grid item xs={12} md={2.9} mt={4.5}>
            <TextField
              label='Celular'
              type='number'
              autoComplete='off'
              fullWidth
              sx={styleInput}
              value={formAll.celular_t.texto}
              onChange={(e)=>onChangeForm(e.target.value,'celular_t')}
              InputLabelProps={{shrink: formAll.celular_t.texto}}
              error={formAll.celular_t.estadoErr}
              helperText={formAll.celular_t.estadoErr && formAll.celular_t.textoErr}
              InputProps={{ inputProps: { min: 100000000 } }}
            />
          </Grid>
          <Grid item xs={12} md={3.9} mt={4.5} ml={1}>
            <TextField
              label='Email'
              fullWidth
              autoComplete='off'
              sx={styleInputHeight}
              value={formAll.contactoEmail_t.texto}
              onChange={(e)=>onChangeForm(e.target.value,'contactoEmail_t')}
              InputLabelProps={{shrink: formAll.contactoEmail_t.texto}}
              error={formAll.contactoEmail_t.estadoErr}
              helperText={formAll.contactoEmail_t.estadoErr && formAll.contactoEmail_t.textoErr}
            />
          </Grid>
          <Grid item xs={12} md={2} mt={2} ml={1}>
            <Typography sx={{color: '#000'}}>Costo</Typography>
            <SelectCSCosto
              value={formAll.cargoinstalacion_t.texto}
              onChange={(e)=> onChangeForm(e.value,'cargoinstalacion_t')}
            />
            {contrato?.prefactibilidad !== 'NEGATIVA' && (
              <>
                {formAll.cargoinstalacion_t.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cargoinstalacion_t.textoErr}</Typography>
                )}
                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>(*) Verifique el dato</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={2} mt={2} ml={1}>
            <Typography sx={{color: '#000'}}>Router</Typography>
            <SelectCSRouter
              value={formAll.router_tk.texto}
              onChange={(e)=> onChangeForm(e.value,'router_tk')}
            />
            {contrato?.prefactibilidad !== 'NEGATIVA' && (
              <>
                {formAll.router_tk.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.router_tk.textoErr}</Typography>
                )}
                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>(*) Verifique el dato</Typography>
              </>
            )}
          </Grid>
          {contrato?.prefactibilidad === 'NEGATIVA' ? (
            <Grid item xs={12} md={12} sx={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
              <Box
                sx={{
                  p: 1,
                  borderColor: '#e01010',
                  background: '#fce7e7',
                  borderRadius: 2,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: 750
                }}
              >
                <WarningRoundedIcon sx={{color: 'red', fontSize: 30}} />
                <Typography sx={{color: '#e01010',marginTop:0,fontSize: 14, textAlign: 'center'}} variant='h6'>
                  El pre-servicio quedará sujeto a la confirmación de la prefactibilidad técnica. Para tal efecto, se procederá a la creación de un ticket de prefactibilidad que será evaluado y confirmado por las áreas correspondientes.
                </Typography>
              </Box>
            </Grid>
          ):(
            <>
              <Grid item xs={12} md={4} mt={2}>
                <Typography sx={{color: '#000'}}>Motivo del ticket</Typography>
                <SelectMotivoTicket
                  id="motivoTareaId"
                  value={formAll.motivoTareaId.texto}
                  emp='nubicom'
                  instalacion
                  onChange={(opt)=>{
                    onChangeForm(opt.value,'motivoTareaId');
                    onChangeForm(opt.label, 'tituloTk')
                    onChangeForm(opt.descripcion, 'observacionesTarea');
                    /* if((opt.value === 4 || opt.value === 67)){
                        onChangeForm('SIN COSTO', 'cargoinstalacion_t')
                    }else if((opt.value === 10 || opt.value === 68 || opt.value === 7 || opt.value === 40)){
                        onChangeForm('CON COSTO', 'cargoinstalacion_t')
                    }else{
                      onChangeForm(formAll.cargoinstalacion_t.texto || 'CON COSTO', 'cargoinstalacion_t')
                    } */
                  }}
                />
                {formAll.motivoTareaId.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.motivoTareaId.textoErr}</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={4} ml={2}>
                {contrato?.requiereAutorizacion === 'NO' ?(
                  <>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formAll.crearTarea.texto}
                        onChange={(e) => onChangeForm(e.target.value,'crearTarea')}
                      >
                        <FormControlLabel
                          value="tareacalendar"
                          control={
                            <Radio
                              disabled
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                          />}
                          label="Crear la tarea en el calendario de Nubicom"
                          sx={{color: '#000','& .MuiTypography-root.MuiFormControlLabel-label':{color: 'gray'}}}
                        />
                        <FormControlLabel
                          value="ticketsfecha"
                          control={
                            <Radio
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                            />
                          }
                          label="Crear el ticket de instalación sin fecha definida"
                          sx={{color: '#000'}}
                        />
                      </RadioGroup>
                    </FormControl>
                    {formAll.crearTarea.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.crearTarea.textoErr}</Typography>
                    )}
                  </>
                ):(
                  <Box sx={{display: 'flex', justifyContent:'center', alignItems:'end', minHeight: 60,mb:2}}>
                    <Typography sx={{color: '#e01010',fontWeight: 'bold'}}>El pre-servicio se encontrara en estado pendiente de autorizacion.</Typography>
                  </Box>
                )}
              </Grid>
              {pregDATercero && (
                <Grid item xs={12} md={3} mt={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                  <Typography sx={{color: '#000'}}>¿La tarjeta DA es de terceros?</Typography>
                  <>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formAll.debitoTercero_t.texto}
                        onChange={(e) => onChangeForm(e.target.value,'debitoTercero_t')}
                        row
                      >
                        <FormControlLabel
                          value="TERCERO"
                          control={
                            <Radio
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                          />}
                          label="SI"
                          sx={{color: '#000','& .MuiTypography-root.MuiFormControlLabel-label':{color: '#000'}}}
                        />
                        <FormControlLabel
                          value="PROPIA"
                          control={
                            <Radio
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                            />
                          }
                          label="NO"
                          sx={{color: '#000'}}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>(*) Verifique el dato</Typography>
                    {formAll.debitoTercero_t.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.debitoTercero_t.textoErr}</Typography>
                    )}
                  </>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} md={6}>
            <Typography sx={{color: '#000'}}>Título del ticket</Typography>
            <TextField
              autoComplete='off'
              fullWidth
              sx={styleInputHeight}
              value={formAll.tituloTk.texto}
              onChange={(e)=>onChangeForm(e.target.value,'tituloTk')}
              InputLabelProps={{shrink: formAll.tituloTk.texto}}
              error={formAll.tituloTk.estadoErr}
              helperText={formAll.tituloTk.estadoErr && formAll.tituloTk.textoErr}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography sx={{color: '#000'}}>Nivel VIP</Typography>
            <SelectNivelVIP
              value={formAll?.nivelVip_t?.texto}
              onChange={(e)=> onChangeForm(e?.value,'nivelVip_t')}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={3} mt={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                  <Typography sx={{color: '#000'}}>¿Es un edificio?</Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formAll.isDepartment_t.texto}
                        onChange={(e) => onChangeForm(e.target.value,'isDepartment_t')}
                        row
                      >
                        <FormControlLabel
                          value="SI"
                          control={
                            <Radio
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                          />}
                          label="SI"
                          sx={{color: '#000','& .MuiTypography-root.MuiFormControlLabel-label':{color: '#000'}}}
                        />
                        <FormControlLabel
                          value="NO"
                          control={
                            <Radio
                              sx={{color: '#000'}}
                              icon={<CheckBoxOutlineBlankIcon sx={{color: 'gray',fontSize: 22}} />}
                              checkedIcon={<CheckBoxIcon sx={{color: '#02b879', fontSize: 22}} />}
                            />
                          }
                          label="NO"
                          sx={{color: '#000'}}
                        />
                      </RadioGroup>
                    </FormControl>
                </Grid>
          <Grid item xs={12} mt={2}>
            <InputEditQuill
                id="Comentario"
                template={formAll.observacionesTarea.texto}
                etiqueta
                onChangeText={(html)=>{
                    onChangeForm(html,'observacionesTarea')
                }}
            />
            {formAll.observacionesTarea.estadoErr && (
              <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesTarea.textoErr}</Typography>
            )}
          </Grid>
        </Grid>
      </Card>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15} marginTop={2}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          color='prevStep'
          isLoading={isLoading}
        />
        <ButtonBasic
          textButton={(preViewContrato || contrato?.prefactibilidad === 'NEGATIVA') ? 'Finalizar' : 'Ver Pre-Contrato'}
          onClick={()=>(preViewContrato || contrato?.prefactibilidad === 'NEGATIVA') ? onSubmit() : solicitarContratoPreVista()}
          variant='wk'
          color='nextStep'
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso4Screen);