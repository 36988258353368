import {useState} from 'react';
import {ButtonBasic } from '../../components/index';
import ConsultasService from '../../services/consultas.service';
import { Box, Divider, Grid, Typography, IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const ModalConsultaVinculacion = ({
    show,
    hideModal,
    id,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const navigate = useNavigate();

    const onSubmit = async() => {
        setIsLoading(true);
        await ConsultasService.realizarVinculacion({id_c: id}).then((respVCS)=>{
            if(respVCS?.cuentaId){
                setIsLoading(false);
                navigate(`/contactos/${respVCS?.cuentaId}/cuenta`);
                handleClose();
            }
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const onClosed = () =>handleClose();

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            Esta por vincular un potencial cliente
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 0}} />
                <Grid contariner p={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5' sx={{color: '#000', fontWeight: 'bold', textAlign: 'center'}}>
                            ¿Esta seguro que desea continuar?
                        </Typography>
                        <Typography variant='h6' sx={{color: '#000',mt: 1}}>
                            Al confirmar se llevara a cabo el proceso de vinculación a una cuenta de softland. Debe tener en cuenta que ambas deben pertenecer al mismo segmento.
                        </Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',mt: 1}}>Se migraran :</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Tickets</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Consultas</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Pre-Servicios</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Historico</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Documentación</Typography>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml: 3}}>* Tareas</Typography>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1,marginBottom:0.5}}>
                    <ButtonBasic
                        onClick={()=>onClosed()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='CONFIRMAR'
                        sx={{marginLeft: 1}}
                        variant='wk'
                        color='nextStep'
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalConsultaVinculacion;