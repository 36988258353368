import { Avatar, Badge, Box, Card, CircularProgress, Grid, IconButton, LinearProgress, Typography, circularProgressClasses, rgbToHex, Tooltip as MaterialToolTip } from '@mui/material'
import DashboardService from '../../services/dashboard.service';
import moment from 'moment'
import { useEffect, useRef, useState } from 'react';
import 'chart.js/auto';
import * as XLSX from 'xlsx';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import { ArcElement,Tooltip, Legend, Chart as ChartJS } from 'chart.js';
import { BasicTable, CardDashInfo, MapBase, MarkerClienteDash, PlantillaTableDash, TableBase } from '../../components';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupsIcon from '@mui/icons-material/Groups';
import FechaFunction from '../../function/FechaFunction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { showModal } from 'react-redux-modal-provider';
import ModalFilterDashTickets from '../../modals/dashboard/ModalFilterDashTickets';
ChartJS.register(ChartDataLabels,ArcElement, Tooltip, Legend);

const TabTicketsScreen = ({tickets}) => {
    const chartRef = useRef(null);
    const [itemsOriginal, setItemsOriginal] = useState([])
    const [items, setItems] = useState([])
    const [fechaSelect, setFechaSelect] = useState({
        startDate: FechaFunction.format(new Date(), 'yyyy-MM-dd'),
        endDate: FechaFunction.format(new Date(), 'yyyy-MM-dd')
    });
    const [itemsTipoTk, setItemsTipoTk] = useState([
        { value: 0, label: 'Administrativo',color: "#118dff" },
        { value: 0, label: 'Tecnico',color: "#12239e" },
        { value: 0, label: 'Comercial',color: "#e66c37" }
    ])
    const [filtroTotal, setFiltroTotal] = useState({
        tipoTicket: [],
        estado: [],
        estadoVencimiento: [],
        grupoId: [],
        plantilla: [],
        etapa: [],
        subTipo: [],
        nivelAdm: [],
        motivoBaja: [],
        cierresId: [],
        departamento: [],
        departamentoAutorTk: [],
        autorTk: [],
        tecnologia: [],
    })
    const [countFilter, setCountFilter] = useState(0)
    const [itemsGrupos, setItemsGrupos] = useState([])
    const [itemsPlantilla, setItemsPlantilla] = useState([])
    const [itemsEtapas, setItemsEtapas] = useState([])
    const [itemsSubTipo, setItemsSubTipo] = useState([])
    const [itemsNivelAdm, setItemsNivelAdm] = useState([])
    const [itemsMotivoBaja, setItemsMotivoBaja] = useState([])
    const [itemsCierreTk, setItemsCierreTk] = useState([])
    const [itemsTkCreadoDepartamento, setItemsTkCreadoDepartamento] = useState([])
    const [itemsTkCreadoUser, setItemsTkCreadoUser] = useState([])
    const [itemsComentariosCreadoUser, setItemsComentariosCreadoUser] = useState([])
    const [itemsFechasCreados, setItemsFechasCreados] = useState([])
    const [itemsNombrePlan, setItemsNombrePlan] = useState([])
    const [itemsNumber, setItemsNumber] = useState({
        total: 0,
        abiertosVencidos: 0,
        abiertosNoVencidos: 0,
        cerrados: 0
    })

    const agruparSegunElemento = (dataAll,elemt, setData) => {
        const agrupadoPorGrupo = dataAll.reduce((resultado, elemento) => {
            if(elemento[elemt]){
                const grupo = elemento[elemt];
                if (!resultado[grupo]) {
                    resultado[grupo] = [];
                }
                resultado[grupo].push(elemento);
            }
            return resultado;
        }, {});
        const newArray = Object.keys(agrupadoPorGrupo).map((grupo) => ({
            label: grupo,
            value: agrupadoPorGrupo[grupo].length,
        }));
        newArray?.sort((a, b) => b?.value - a?.value);
        setData(newArray)
    }
    const agruparSegunElementoFoto = (dataAll,elemt1,setData) => {
        const agrupadoPorGrupo = dataAll.reduce((resultado, elemento) => {
            if(elemento[elemt1]){
                const grupo = elemento[elemt1];
                if (!resultado[grupo]) {
                    resultado[grupo] = [];
                }
                resultado[grupo].push(elemento);
            }
            return resultado;
        }, {});
        const newArray = Object.keys(agrupadoPorGrupo).map((grupo) => ({
            label: grupo,
            value: agrupadoPorGrupo[grupo].length,
        }));
        newArray?.sort((a, b) => b?.value - a?.value);
        const total = newArray.reduce((sum, item) => sum + item.value, 0);
        const dataWithPercentage = newArray.map(item => ({
            label: item.label,
            value: item.value,
            percentage: Number(((item.value / total) * 100).toFixed(2)),
            img: dataAll?.find((e)=>e?.foto_p && e?.nombre_p === item.label )?.foto_p
        }));
        setData(dataWithPercentage)
    }
    const agruparSegunElementoNivel2 = (dataAll,elemt1,elemt2, setData) => {
        const agrupadoPorGrupo = dataAll.reduce((resultado, elemento) => {
            if(elemento[elemt1][elemt2]){
                const grupo = elemento[elemt1][elemt2];
                if (!resultado[grupo]) {
                    resultado[grupo] = [];
                }
                resultado[grupo].push(elemento);
            }
            return resultado;
        }, {});
        const newArray = Object.keys(agrupadoPorGrupo).map((grupo) => ({
            label: grupo,
            value: agrupadoPorGrupo[grupo].length,
        }));
        newArray?.sort((a, b) => b?.value - a?.value);
        const total = newArray.reduce((sum, item) => sum + item.value, 0);
        const dataWithPercentage = newArray.map(item => ({
            label: item.label,
            value: item.value,
            percentage: Number(((item.value / total) * 100).toFixed(2)),
            img: dataAll?.find((e)=>e[elemt1]?.foto_p && e[elemt1]?.nombre_p === item.label )?.autor?.foto_p
        }));
        setData(dataWithPercentage)
    }
    function tiempoASegundos(tiempo) {
        const partes = tiempo.split(':');
        const horas = parseInt(partes[0], 10) * 3600;
        const minutos = parseInt(partes[1], 10) * 60;
        const segundos = parseInt(partes[2], 10);
        return horas + minutos + segundos;
    }
    function segundosATiempo(segundos) {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const segundosRestantes = segundos % 60;
        const segundosRedondeados = parseFloat(segundosRestantes.toFixed(0));
        const formatoTiempo = `${agregarCeroDelante(horas)}:${agregarCeroDelante(minutos)}:${agregarCeroDelante(segundosRedondeados)}`;
        return formatoTiempo;
    }
    function agregarCeroDelante(numero) {
        return numero < 10 ? `0${numero}` : numero;
    }
    const agruparSegunElementoAndTime = (dataAll,elemt, setData) => {
        const agrupadoPorGrupo = dataAll.reduce((resultado, elemento) => {
            if(elemento[elemt]){
                const grupo = elemento[elemt];
                if (!resultado[grupo]) {
                    resultado[grupo] = {
                        elementos: [],
                        totalTiempo: 0,
                        totalComentariosGrupo: 0,
                        tkcerrados: 0
                    };
                }
                resultado[grupo].elementos.push(elemento);
                if(elemento?.transcurrido){
                    const tiempoEnSegundos = tiempoASegundos(elemento.transcurrido);
                    resultado[grupo].totalTiempo += tiempoEnSegundos;
                    resultado[grupo].tkcerrados = resultado[grupo].tkcerrados + 1
                }
                resultado[grupo].totalComentariosGrupo += elemento?.comentarios?.length || 0
            }
            return resultado;
        }, {});
        const newArray = Object.keys(agrupadoPorGrupo).map((grupo) => {
            const numElementos = agrupadoPorGrupo[grupo].elementos.length;
            const promedioTiempo = agrupadoPorGrupo[grupo].tkcerrados > 0 ? agrupadoPorGrupo[grupo].totalTiempo / agrupadoPorGrupo[grupo].tkcerrados : 0;
            return {
                label: grupo,
                value: numElementos,
                totalComentarios: agrupadoPorGrupo[grupo].totalComentariosGrupo,
                tkcerrados: agrupadoPorGrupo[grupo].tkcerrados,
                tiempoTranscurrido: segundosATiempo(promedioTiempo),
            }
        });
        newArray?.sort((a, b) => b?.value - a?.value);
        setData(newArray)
    }
    const obtenerFechasEnRango = (fechaInicio, fechaFin, dataAll) => {
        const fechas = [];
        let fechaActual = moment(fechaInicio);
        while (fechaActual.isSameOrBefore(fechaFin)) {
            const fechaFormateada = fechaActual.format('DD-MM-YYYY')?.toString();
            fechas.push({
                label: fechaFormateada,
                value: dataAll?.filter((e)=>e?.fechaCreacion === fechaFormateada )?.length || 0
            })
            fechaActual.add(1, 'days');
        }
        setItemsFechasCreados(fechas)
    };
    const primeraCarga = (dataAll) => {
        const cerrados = dataAll?.filter((e)=>e?.estado_tk === 'CERRADO')?.length
        const abiertosNoVencidos = dataAll?.filter((e)=>e?.estado_tk === 'ABIERTO' && e?.estadoVencimiento_tk === 'NO VENCIDO')?.length
        const abiertosVencidos = dataAll?.filter((e)=>e?.estado_tk === 'ABIERTO' && e?.estadoVencimiento_tk === 'VENCIDO')?.length
        if(dataAll?.length >= 1000){
            setItemsNumber({
                total: dataAll?.length / 1000,
                abiertosNoVencidos: abiertosNoVencidos >= 1000 ? abiertosNoVencidos / 1000 : abiertosNoVencidos,
                abiertosVencidos: abiertosVencidos >= 1000 ? abiertosVencidos / 1000 : abiertosVencidos,
                cerrados: cerrados >= 1000 ? cerrados / 1000 : cerrados
            });
        }else{
            setItemsNumber({
                total:dataAll?.length,
                abiertosNoVencidos: abiertosNoVencidos,
                abiertosVencidos: abiertosVencidos,
                cerrados: cerrados
            });
        }
        const adm = dataAll?.filter((tk)=> tk?.tipoTicketNombre === 'ADMINISTRATIVO');
        const tec = dataAll?.filter((tk)=> tk?.tipoTicketNombre === 'TECNICO');
        const com = dataAll?.filter((tk)=> tk?.tipoTicketNombre === 'COMERCIAL');
        setItemsTipoTk([
            { value: adm?.length || 0, label: 'Administrativo',color: "#118dff" },
            { value: tec?.length || 0, label: 'Tecnico',color: "#12239e" },
            { value: com?.length || 0, label: 'Comercial',color: "#e66c37" },
        ])
        let totalComentarios = 0;
        let comentariosArr = []
        const agrupadoPorGrupo = dataAll.reduce((resultado, elemento) => {
            if(elemento?.grupoNombre){
                const grupo = elemento.grupoNombre;
                if (!resultado[grupo]) {
                    resultado[grupo] = [];
                }
                resultado[grupo].push(elemento);
            }
            if(elemento?.comentarios?.length > 0){
                totalComentarios = totalComentarios + elemento?.comentarios?.length;
                comentariosArr = comentariosArr.concat(elemento?.comentarios)
            }
            return resultado;
        }, {});
        agruparSegunElementoFoto(comentariosArr,'nombre_p',setItemsComentariosCreadoUser)
        const newArray = Object.keys(agrupadoPorGrupo).map((grupo) => ({
            label: grupo,
            value: agrupadoPorGrupo[grupo].length,
        }));
        newArray?.sort((a, b) => b?.value - a?.value);
        const total = newArray.reduce((sum, item) => sum + item.value, 0);
        const dataWithPercentage = newArray.map(item => ({
            label: item.label,
            value: item.value,
            percentage: Number(((item.value / total) * 100).toFixed(2)),
        }));
        setItemsGrupos(dataWithPercentage)
        agruparSegunElementoAndTime(dataAll,'motivoNombre',setItemsPlantilla)
        agruparSegunElemento(dataAll,'stageTicket_tk',setItemsEtapas)
        agruparSegunElemento(dataAll,'subTipoTicketNombre',setItemsSubTipo)
        agruparSegunElemento(dataAll,'nivelAdmNombre',setItemsNivelAdm)
        agruparSegunElemento(dataAll,'motivoBajaNombre',setItemsMotivoBaja)
        agruparSegunElemento(dataAll,'motivoBajaNombre',setItemsMotivoBaja)
        agruparSegunElemento(dataAll,'cierreTicketNombre',setItemsCierreTk)
        agruparSegunElementoNivel2(dataAll,'autor','departamento_p',setItemsTkCreadoDepartamento)
        agruparSegunElementoNivel2(dataAll,'autor','nombre_p',setItemsTkCreadoUser)
        agruparSegunElemento(dataAll,'nombrePlan',setItemsNombrePlan)
        obtenerFechasEnRango(moment(fechaSelect.startDate).add(3, 'hours').toDate(),moment(fechaSelect.endDate).add(3, 'hours').toDate(),dataAll)
    }
    const actualizar = async()=>{
        await DashboardService.gralTicket({
            fecIni: fechaSelect.startDate,
            fecFin: fechaSelect.endDate
        }).then((resp)=>{
            setItemsOriginal(resp)
            setItems(resp)
            primeraCarga(resp)
        })
    }

    useEffect(() => {
        actualizar()
    }, [])

    const data = {
        labels: ['Comercial', 'Tecnico','Administrativo'],
        datasets: [
            {
                label: 'Total',
                data: [itemsTipoTk[0]?.value, itemsTipoTk[1]?.value, itemsTipoTk[2]?.value],
                backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: '#000',
                anchor: 'center',
                font: {
                    weight: 'bold',
                    anchor: 'center'
                },
                formatter: (value, context) => {
                    const dataset = context.dataset;
                    const total = dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2) + '%';
                    return percentage;
                },
            },
        }
    };
    const cssCard = {
        background: '#fff !important',
        minHeight: 75,
        width: '100%',
        boxShadow: 4,
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                    color: '#dfe2e6',
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    disableShrink
                    sx={{
                    color: '#1a90ff',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" sx={{color: '#000',fontWeight: 'bold'}}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    const columns = [
        {
            name: "id_tk",
            label: "Nro",
        },{
            name: "createdAt",
            label: "Creado",
        },{
            label: "Titulo",
            name: "titulo_tk",
        },{
            label: 'Estado',
            name: 'estado_tk',
        },{
            label: 'Vencimiento',
            name: 'estadoVencimiento_tk',
        },{
            label: 'Etapa',
            name: 'stageTicket_tk',
        },{
            label: 'Plantilla',
            name: 'motivoNombre',
        },{
            label: 'Tipo',
            name: 'tipoTicketNombre',
        },{
            label: 'Sub-Tipo',
            name: 'subTipoTicketNombre',
        },{
            label: 'Nivel Adm.',
            name: 'nivelAdmNombre',
        },{
            label: 'Motivo Baja',
            name: 'motivoBajaNombre',
        },{
            label: 'Cierre',
            name: 'cierreTicketNombre',
        },{
            label: 'Asignado a',
            name: 'grupoNombre',
        },{
            label: 'Tiempo',
            name: 'transcurrido',
        },{
            label: 'Cliente',
            name: 'nombreCliente',
        },{
            label: 'Plan',
            name: 'nombrePlan',
        },{
            label: 'Direccion',
            name: 'direccionServicio',
        },{
            label: 'Tecnologia',
            name: 'tipoTecnologia',
        }
    ];
    const descargarExcel = () => {
        const dataForExcel = items.map(item => ({
            Nro: item.id_tk,
            Creado: item?.createdAt || '-',
            Titulo: item.titulo_tk,
            Plantilla: item?.motivoNombre || '-',
            Tipo: item?.tipoTicketNombre || '-',
            'Sub-Tipo': item?.subTipoTicketNombre || '-',
            'Nivel Administrativo': item?.nivelAdmNombre || '-',
            'Motivo Baja': item?.motivoBajaNombre || '-',
            Cierre: item?.cierreTicketNombre || '-',
            Etapa: item?.stageTicket_tk || '-',
            Estado: item?.estado_tk || '-',
            Vencimiento: item?.estadoVencimiento_tk || '-',
            'Asignado a': item?.grupoNombre || '-',
            Cliente: item?.nombreCliente || '-',
            Plan: item?.nombrePlan || '-',
            Cuenta: item?.cuenta || '-',
            Servicio: item?.servicio || '-',
            'Tipo Cliente': item?.softland ? 'CLIENTE' : 'POTENCIAL CLIENTE',
            Latitud: item?.latitud || '-',
            Longitud: item?.longitud || '-',
            Tecnologia: item?.tipoTecnologia || '-',
            Autor: `${item?.autor?.nombre_p} - ${item?.autor?.departamento_p}`
        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        XLSX.utils.book_append_sheet(wb, ws, "Tickets");
        const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', mimeType: 'application/octet-stream' });
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const fileName = 'dashboardTickets.xlsx';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };
    const onShowModalFilter = () => {
        showModal(ModalFilterDashTickets,{
            filtroTotal,
            setFiltroTotal,
            setCountFilter,
            itemsOriginal,
            primeraCarga,
            setItems,
            fechaSelect,
            setFechaSelect,
            setItemsOriginal
        })
    }
    return (
        <Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: {xs: 100, sm: 50},
                    right: {xs: 20, sm: 36},
                    zIndex: 99999,
                    background: '#0364ff',
                    borderRadius: 50,
                    '&:hover':{
                        background: '#000'
                    }
                }}
            >
                <IconButton aria-label="cart" onClick={()=>onShowModalFilter()}>
                    <Badge
                        badgeContent={countFilter}
                        sx={{
                            '& span':{
                                '&.MuiBadge-badge':{
                                    background: '#000',
                                    color:'white'
                                }
                            }
                        }}
                    >
                        <FilterListIcon sx={{fontSize: 30,color: '#fff'}} />
                    </Badge>
                </IconButton>
            </Box>
            <Grid container spacing={2} mb={10}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important', p: 1,boxShadow: 4,borderRadius: 2}}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography sx={{color: '#000',textAlign: 'center',fontWeight: 'bold', fontSize: 14}}>
                                            Rango de fecha
                                        </Typography>
                                        <Typography sx={{color: '#000',textAlign: 'center',fontWeight: 'bold', fontSize: 18}}>
                                            {`${FechaFunction.format(fechaSelect.startDate, 'dd-MM-yyyy')} ${' '} al ${FechaFunction.format(fechaSelect.endDate, 'dd-MM-yyyy')}` }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <MaterialToolTip title="Descargar excel">
                                            <IconButton sx={{boxShadow: 4}} onClick={()=>descargarExcel()}>
                                                <FileDownloadIcon sx={{color: 'green'}} />
                                            </IconButton>
                                        </MaterialToolTip>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{...cssCard, background: '#0364ff !important'}}>
                                <Typography
                                    sx={{
                                        color: '#fff',
                                        textAlign: 'center',
                                        fontSize: 38,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {itemsNumber?.total || 0}
                                </Typography>
                                <Typography sx={{color: '#fff',textAlign: 'center',fontSize: 17}}>Cant. tickets</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <CardDashInfo
                                label='Abiertos'
                                value={itemsNumber?.abiertosNoVencidos + itemsNumber?.abiertosVencidos}
                                key='DashAbiertos01'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardDashInfo
                                label='Cerrados'
                                value={itemsNumber?.cerrados}
                                key='DashCerrados01'
                                color= 'gray'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardDashInfo
                                label='No Vencidos'
                                value={itemsNumber?.abiertosNoVencidos}
                                key='DashNoVencidos01'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardDashInfo
                                label='Vencidos'
                                value={itemsNumber?.abiertosVencidos}
                                key='DashabiertosVencidos01'
                                color='red'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important',boxShadow: 4,height: 310,borderRadius:4,p:2, display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>Tipo Tickets</Typography>
                        <Chart
                            data={data}
                            type='pie'
                            height={150}
                            width={150}
                            ref={chartRef}
                            options={options}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            Cant. de ticket abiertos por área
                        </Typography>
                        <Box className='side' sx={{maxHeight: 270, overflow: 'auto'}}>
                            {itemsGrupos.map((eTkCDep) =>(
                                <Box sx={{p: 1,mt: 1,'&:hover':{background: '#d1d1d1', borderRadius: 2, cursor: 'pointer'}}}>
                                    <Grid container>
                                        <Grid item xs={3} sm={1.5} md={1.5} lg={1.9} xl={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <CircularProgressWithLabel value={eTkCDep?.percentage || 0} />
                                        </Grid>
                                        <Grid item xs={9} lg={10} xl={10.5}>
                                            <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                {eTkCDep?.label || '-'}
                                            </Typography>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Box sx={{width: '80%',mt: -0.5}}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={eTkCDep?.percentage || 0}
                                                        sx={{
                                                            height: 9,
                                                            borderRadius: 1,
                                                            background: 'transparent',
                                                            '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary':{
                                                                background: '#0364ff'
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{width: '20%', mt: -1}}>
                                                    <Typography sx={{textAlign: 'center', ml: 1, color: '#000',fontWeight: 'bold',fontSize: 19,mt: -0.5}}>
                                                        {eTkCDep?.value || 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            PLANTILLAS DE TICKETS
                        </Typography>
                        <PlantillaTableDash dataAll={itemsPlantilla} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            ETAPAS
                        </Typography>
                        <BasicTable dataAll={itemsEtapas} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            SUB-TIPO
                        </Typography>
                        <BasicTable dataAll={itemsSubTipo} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            NIVEL ADMINISTRATIVO
                        </Typography>
                        <BasicTable dataAll={itemsNivelAdm} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            MOTIVO DE BAJA
                        </Typography>
                        <BasicTable dataAll={itemsMotivoBaja} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            FECHAS
                        </Typography>
                        <BasicTable dataAll={itemsFechasCreados} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            CIERRES
                        </Typography>
                        <BasicTable dataAll={itemsCierreTk} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4, minHeight: 210}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            PLAN
                        </Typography>
                        <BasicTable dataAll={itemsNombrePlan} />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            Tickets Creados por Departamento
                        </Typography>
                        <Box className='side' sx={{maxHeight: 270, overflow: 'auto'}}>
                            {itemsTkCreadoDepartamento.map((eTkCDep) =>(
                                <Box sx={{p: 1,mt: 1,'&:hover':{background: '#d1d1d1', borderRadius: 2, cursor: 'pointer'}}}>
                                    <Grid container>
                                        <Grid item xs={3} sm={1.5} md={1.5} lg={1.9} xl={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Avatar sx={{background: '#ebedf0',border: 1, borderColor: '#98a1b0'}}>
                                                <GroupsIcon sx={{fontSize: 32,color: '#243757'}} />
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs={9} lg={10} xl={10.5}>
                                            <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                {eTkCDep?.label || '-'}
                                            </Typography>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Box sx={{width: '80%',mt: -0.5}}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={eTkCDep?.percentage || 0}
                                                        sx={{
                                                            height: 8,
                                                            borderRadius: 4,
                                                            background: 'gray',
                                                            '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary':{
                                                                background: 'red'
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{width: '20%', mt: -1}}>
                                                    <Typography sx={{textAlign: 'center', ml: 1, color: '#000',fontWeight: 'bold',fontSize: 19,mt: -0.5}}>
                                                        {eTkCDep?.value || 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            Tickets Creados por Usuario
                        </Typography>
                        <Box className='side' sx={{height: 270, overflow: 'auto'}}>
                            {itemsTkCreadoUser.map((eTkCDep) =>(
                                <Box sx={{p: 1,mt: 1,'&:hover':{background: '#d1d1d1', borderRadius: 2, cursor: 'pointer'}}}>
                                    <Grid container>
                                        <Grid item xs={3} sm={1.5} md={1.5} lg={1.9} xl={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Avatar src={eTkCDep?.img} />
                                        </Grid>
                                        <Grid item xs={9} lg={10} xl={10.5}>
                                            <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                {eTkCDep?.label || '-'}
                                            </Typography>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Box sx={{width: '80%',mt: -0.5}}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={eTkCDep?.percentage || 0}
                                                        sx={{
                                                            height: 8,
                                                            borderRadius: 4,
                                                            background: 'gray',
                                                            '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary':{
                                                                background: 'red'
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{width: '20%', mt: -1}}>
                                                    <Typography sx={{textAlign: 'center', ml: 1, color: '#000',fontWeight: 'bold',fontSize: 19,mt: -0.5}}>
                                                        {eTkCDep?.value || 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{background: '#fff !important', p: 1, boxShadow: 4, borderRadius: 4}}>
                        <Typography sx={{fontWeight: 'bold', color: '#000',textAlign: 'center'}}>
                            Comentarios Creados por Usuario
                        </Typography>
                        <Box className='side' sx={{height: 270, overflow: 'auto'}}>
                            {itemsComentariosCreadoUser.map((eTkCDep) =>(
                                <Box sx={{p: 1,mt: 1,'&:hover':{background: '#d1d1d1', borderRadius: 2, cursor: 'pointer'}}}>
                                    <Grid container>
                                        <Grid item xs={3} sm={1.5} md={1.5} lg={1.9} xl={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Avatar src={eTkCDep?.img} />
                                        </Grid>
                                        <Grid item xs={9} lg={10} xl={10.5}>
                                            <Typography sx={{textAlign: 'left', color: '#000'}}>
                                                {eTkCDep?.label || '-'}
                                            </Typography>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Box sx={{width: '80%',mt: -0.5}}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={eTkCDep?.percentage || 0}
                                                        sx={{
                                                            height: 8,
                                                            borderRadius: 4,
                                                            background: 'gray',
                                                            '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary':{
                                                                background: 'red'
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{width: '20%', mt: -1}}>
                                                    <Typography sx={{textAlign: 'center', ml: 1, color: '#000',fontWeight: 'bold',fontSize: 19,mt: -0.5}}>
                                                        {eTkCDep?.value || 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{background: '#fff !important', boxShadow: 4, borderRadius: 2, p:1}}>
                        <TableBase
                            title=''
                            columns={columns}
                            data={items}
                            options={{
                                rowsPerPageOptions: [5],
                                rowsPerPage: 5,
                                print: false,
                                download: false,
                                search: false,
                                filter: false,
                                toolbar: null,
                                customToolbar: null,
                                display: false,
                                viewColumns: false,
                            }}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{background: '#fff !important', boxShadow: 4, borderRadius: 2, p:1,position: 'relative'}}>
                        <Typography sx={{color: '#000', textAlign: 'center', fontWeight: 'bold',fontSize: 18}}>
                            MAPA
                        </Typography>
                        <MapBase
                            id="mapaDashTk"
                            key='mapaDashTk'
                            options={{
                                maxZoom: 19,
                                minZoom: 7,
                            }}
                            zoom={15}
                            mapContainerStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                                width: '100%',
                                height: 400
                            }}
                        >
                            { items?.length > 0 &&(
                                items?.map((eitm)=>{
                                    if(eitm?.latitud && eitm?.longitud){
                                        return(
                                            <MarkerClienteDash
                                                coordenadas={{
                                                    lat: Number(eitm?.latitud),
                                                    lng: Number(eitm?.longitud)
                                                }}
                                                item={eitm}
                                            />
                                        )
                                    }else{
                                        return <></>
                                    }
                                })
                            )}
                        </MapBase>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TabTicketsScreen
