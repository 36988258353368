import { useState, useEffect } from "react"
import { Box, Grid, Typography } from "@mui/material"
import TablaTicketService from "../../services/tablaTicket.service";
import FechaFunction from "../../function/FechaFunction";
import { useNavigate } from "react-router-dom";

const HomeAsesorScreen = () => {
    const [tickets, setTickets] = useState();
    const navigate = useNavigate();

    const actualizar = (data) => {
        TablaTicketService.countTicketTic(data).then((c)=>{
            setTickets(c)
        })
    }
    useEffect(() => {
        document.title = `Zeta-CRM`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={20}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography className="text-gray-900 font-bold" variant="h1">Dashboard Asesor</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomeAsesorScreen;