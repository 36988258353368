import { useEffect, useState } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import BarChartIcon from '@mui/icons-material/BarChart';
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import ObjetivosService from "../../services/objetivo.service";
import ModalAddObjetivo from "../../modals/asesores/ModalAddObjetivo";
import FechaFunction from "../../function/FechaFunction";
import ModalVerObjetivo from "../../modals/asesores/ModalVerObjetivo";

const ObjetivosScreen = () => {
    const [items, setItems] = useState([]);
    const actualizar = async() =>{
        await ObjetivosService.getAll({all : true}).then((response) =>{
            setItems(response)
        })
    }

    const columns = [
        {
            name: "id_obj",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "createdAt",
            label: "Creado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const nuevaFecha = new Date(value);
                    nuevaFecha.setHours(nuevaFecha.getHours() + 3)
                    return FechaFunction.format(nuevaFecha,'dd/MM/yyyy HH:mm:ss');
                }
            },
        },
        {
            name: "titulo_obj",
            label: "Titulo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "tipoObj_obj",
            label: "Categoria",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cantObj_obj",
            label: "Cantidad",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "fechaIni_obj",
            label: "Inicio",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const nuevaFecha = new Date(value);
                    nuevaFecha.setHours(nuevaFecha.getHours() + 3)
                    return FechaFunction.format(nuevaFecha,'dd/MM/yyyy HH:mm:ss');
                }
            },
        },
        {
            name: "fechaFin_obj",
            label: "Fin",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const nuevaFecha = new Date(value);
                    nuevaFecha.setHours(nuevaFecha.getHours() + 3)
                    return FechaFunction.format(nuevaFecha,'dd/MM/yyyy HH:mm:ss');
                }
            },
        },
        {
            name: 'isDeleted_obj',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value) return <Chip label="Activo" color="success" />
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Box display='flex'>
                        <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                            <BarChartIcon fontSize="medium" />
                        </IconButton>
                        <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                            { !tableMeta.rowData[7]  && (
                                <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                            )}
                        </IconButton>
                    </Box>
                )
            }
        }
    ];
    const onGuardar = () => actualizar();

    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_obj === item)
            const fechaIni = new Date(searchItem.fechaIni_obj);
            fechaIni.setHours(fechaIni.getHours() + 3)
            searchItem.fechaIni = FechaFunction.format(fechaIni,'dd/MM/yyyy HH:mm:ss');
            const fechaFin = new Date(searchItem.fechaFin_obj);
            fechaFin.setHours(fechaFin.getHours() + 3)
            searchItem.fechaFin = FechaFunction.format(fechaFin,'dd/MM/yyyy HH:mm:ss');
            showModal(ModalVerObjetivo, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalAddObjetivo, {onGuardar: onGuardar});
        }
    };
    const onEliminar = (item) => {
        const searchItem = items.find((sItem)=> sItem.id_obj === item)
        showModal(ConfirmationDialog, {
            title: 'Desactivar objetivo',
            message: `¿Esta seguro que desea desactivar el objetivo?`,
            onConfirm: () => {
                ObjetivosService.destroy(searchItem.keyObj_obj).then(() => {
                    onGuardar();
                });
            }
        });
    };
    useEffect(() => {
        actualizar();
        document.title = 'Asesores-Objetivos';
    }, [])


    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Objetivos</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Nuevo Objetivo"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ObjetivosScreen