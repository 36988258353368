import { Marker } from '@react-google-maps/api';
import React from 'react'
function MarkerServicio({
    coordenadas,
    ...defaultProps
}) {

    return (
        <Marker
            {...defaultProps}
            position={coordenadas}
            icon={{
                url:'/img/location_home.svg',
                scaledSize: {
                    width:50,
                    height:50,
                }
            }}
        >
        </Marker>
    );
}

export default React.memo(MarkerServicio);
