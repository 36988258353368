import { useEffect, useState } from "react"
import { Box, Card, Grid, IconButton, Typography } from "@mui/material"
import { showModal } from "react-redux-modal-provider";
import MessageIcon from '@mui/icons-material/Message';
import { ButtonBasic, SelectEstadoRecordatorio, SelectFiltroRecordatorio, TableBase } from "../../components"
import RecordatorioService from "../../services/recordatorio.service";
import FechaFunction from "../../function/FechaFunction";
import ModalRecordatorioById from "../../modals/recordatorios/ModalRecordatorioById";
import ModalCrearRecordatorioSelect from "../../modals/recordatorios/ModalCrearRecordatorioSelect";

const RecordatorioScreen = () => {
    const [items, setItems] = useState([]);
    const [itemsView, setItemsView] = useState([]);
    const [filtro, setFiltro] = useState('Asignados');
    const [filtroEstado, setFiltroEstado] = useState('Todos');
    const actualizar = async(itemFIltro) =>{
        let form = {}
        switch (itemFIltro) {
            case 'Asignados':
                form = {}
                break;
            case 'Creados':
                form = {creadas: true}
                break;
            case 'Todos':
                form = {all: true}
                break;
            default:
                break;
        }
        await RecordatorioService.getAll(form).then((resp)=>{
            setItems(resp)
        })
    }

    const columns = [
        {
            name: "id_rec",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "titulo_rec",
            label: "Titulo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "fechaProgramado_rec",
            label: "Programado",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) =>(
                    FechaFunction.format(new Date(tableMeta.rowData[2]),'dd/MM/yyyy HH:mm:ss')
                )
            }
        },
        {
            name: "estado_rec",
            label: "Estado",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Box display='flex'>
                        <IconButton color='info' aria-label="edit" onClick={()=>onOpenModalId(tableMeta.rowData[0])}>
                            <MessageIcon fontSize="medium" />
                        </IconButton>
                    </Box>
                )
            }
        }
    ];
    const onOpenModalId = (itemId) => {
        showModal(ModalRecordatorioById, {id: itemId, onGuardar: actualizar});
    };
    const onOpenModalCreated = () => {
        showModal(ModalCrearRecordatorioSelect, {onGuardar: actualizar});
    };
    useEffect(() => {
        actualizar('Asignados');
        document.title = 'Recordatorios';
    }, [])
    useEffect(() => {
        actualizar(filtro);
    }, [filtro])

    useEffect(() => {
        let filtroEst = items;
        switch (filtroEstado) {
            case 'Todos':
                filtroEst = items
                break;
            default:
                filtroEst = items.filter((e)=>e?.estado_rec === filtroEstado)
                break;
        }
        setItemsView(filtroEst);
    }, [items,filtroEstado])
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} spacing={1}>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Recordatorios</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenModalCreated()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{background: '#fff !important', maxWidth: 320,p: 1.5, borderRadius: 3}}>
                        <Typography className="text-gray-900" variant="h5">
                            Filtro:
                        </Typography>
                        <SelectFiltroRecordatorio
                            value={filtro}
                            onChange={(e)=> setFiltro(e.value)}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{background: '#fff !important', maxWidth: 320,p: 1.5, borderRadius: 3}}>
                        <Typography className="text-gray-900" variant="h5">
                            Estado:
                        </Typography>
                        <SelectEstadoRecordatorio
                            value={filtroEstado}
                            onChange={(e)=> setFiltroEstado(e?.value || 'Todos')}
                            isClearable
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} mt={1} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={itemsView} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default RecordatorioScreen