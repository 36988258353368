import { useEffect, useState } from 'react';
import { Box, Card, Grid, IconButton, Typography } from '@mui/material'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player'
import { saveAs } from 'file-saver';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
import DownloadFile from '../../../services/downloadFile.service';
import { ButtonBasic } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalDocumentacionAdj from '../../../modals/contacto/ModalDocumentacionAdj';

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: '100%',
    height: 120,
    padding: 1,
    justifyContent: 'center',
    background: '#fff !important',
    borderRadius: 4,
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    borderRadius: 10
};

const TabCuentaDocumentacion = ({infoIni, filesAdj,token,onGuardar,consulta}) => {
    const [imgArrSelect, setImgArrSelect] = useState([]);
    const [archiveSelect, setArchiveSelect] = useState(null);
    const [mimeArchive, setMimeArchive] = useState(null);
    const permitidosAdd = [
        'DNI Frente',
        'DNI Dorso',
        'Constancia AFIP',
        'Contrato Social-Estatuto',
        'Acta Actualizada',
        'Poder-Apoderado',
        'Dni-Frente-Apoderado',
        'Dni-Dorso-Apoderado'
    ];
    const urlNubi = 'https://zeta.strongsystems.com.ar:8000/api/img?url='
    useEffect(() => {
        if(archiveSelect?.pathFile_facon){
            const tipe = archiveSelect?.pathFile_facon?.split('.');
            if(tipe?.length > 0){
                setMimeArchive(tipe[tipe?.length - 1])
            }else{
                setMimeArchive('NoExiste')
            }
        }else{
            setMimeArchive('NoExiste')
        }
    }, [archiveSelect])

    useEffect(() => {
        setImgArrSelect([])
        const form = {
            lugarAdjuntado_facon: 'DNI Frente',
            nombreFile_facon: infoIni?.pathFrente_icon,
        }
        if(infoIni?.pathFrente_icon){
            form.pathFile_facon = `contacto/${infoIni?.pathFrente_icon}`
        }else{
            form.pathFile_facon = null
        }
        setArchiveSelect(form)
    }, [])

    const onSelectViewImg = (nombre) =>{
        switch (nombre) {
            case 'DNIFRENTE':
                setImgArrSelect([])
                const form = {
                    lugarAdjuntado_facon: 'DNI Frente',
                    nombreFile_facon: infoIni?.pathFrente_icon,
                }
                if(infoIni?.pathFrente_icon){
                    form.pathFile_facon = `contacto/${infoIni?.pathFrente_icon}`
                }else{
                    form.pathFile_facon = null
                }
                setArchiveSelect(form)
                break;
            case 'DNIDORSO':
                setImgArrSelect([])
                const form2 = {
                    lugarAdjuntado_facon: 'DNI Dorso',
                    nombreFile_facon: infoIni?.pathDorso_icon,
                }
                if(infoIni?.pathDorso_icon){
                    form2.pathFile_facon = `contacto/${infoIni?.pathDorso_icon}`
                }else{
                    form2.pathFile_facon = null
                }
                setArchiveSelect(form2)
                break;
            case 'CONSTANCIA':
                setImgArrSelect([])
                const form3 = {
                    lugarAdjuntado_facon: 'Constancia AFIP',
                    nombreFile_facon: infoIni?.pathconstanciaAfip_icon,
                }
                if(infoIni?.pathconstanciaAfip_icon){
                    form3.pathFile_facon = `contacto/${infoIni?.pathconstanciaAfip_icon}`
                }else{
                    form3.pathFile_facon = null
                }
                setArchiveSelect(form3)
                break;
            case 'SOCIAL-ESTATUTO':
                setImgArrSelect([])
                const form4 = {
                    lugarAdjuntado_facon: 'Contrato Social-Estatuto',
                    nombreFile_facon: infoIni?.pathContratoSocialEstatuto_icon,
                }
                if(infoIni?.pathContratoSocialEstatuto_icon){
                    form4.pathFile_facon = `contacto/${infoIni?.pathContratoSocialEstatuto_icon}`
                }else{
                    form4.pathFile_facon = null
                }
                setArchiveSelect(form4)
                break;
            /* case 'ACTA ACTUALIZADA':
                setImgArrSelect([])
                const form5 = {
                    lugarAdjuntado_facon: 'Acta Actualizada',
                    nombreFile_facon: infoIni?.pathDesigActaActualizada_icon,
                }
                if(infoIni?.pathDesigActaActualizada_icon){
                    form5.pathFile_facon = `contacto/${infoIni?.pathDesigActaActualizada_icon}`
                }else{
                    form5.pathFile_facon = null
                }
                setArchiveSelect(form5)
                break; */
            case 'PODER-APODERADO':
                setImgArrSelect([])
                const form6 = {
                    lugarAdjuntado_facon: 'Poder-Apoderado',
                    nombreFile_facon: infoIni?.pathPoderApoderado_icon,
                }
                if(infoIni?.pathPoderApoderado_icon){
                    form6.pathFile_facon = `contacto/${infoIni?.pathPoderApoderado_icon}`
                }else{
                    form6.pathFile_facon = null
                }
                setArchiveSelect(form6)
                break;
            case 'DNI-FRENTE-APODERADO':
                setImgArrSelect([])
                const form7 = {
                    lugarAdjuntado_facon: 'Dni-Frente-Apoderado',
                    nombreFile_facon: infoIni?.pathDniFrenteApoderado_icon,
                }
                if(infoIni?.pathDniFrenteApoderado_icon){
                    form7.pathFile_facon = `contacto/${infoIni?.pathDniFrenteApoderado_icon}`
                }else{
                    form7.pathFile_facon = null
                }
                setArchiveSelect(form7)
                break;
            case 'DNI-DORSO-APODERADO':
                setImgArrSelect([])
                const form8 = {
                    lugarAdjuntado_facon: 'Dni-Dorso-Apoderado',
                    nombreFile_facon: infoIni?.pathDniDorsoApoderado_icon,
                }
                if(infoIni?.pathDniDorsoApoderado_icon){
                    form8.pathFile_facon = `contacto/${infoIni?.pathDniDorsoApoderado_icon}`
                }else{
                    form8.pathFile_facon = null
                }
                setArchiveSelect(form8)
                break;
            case 'VACIAR':
                setImgArrSelect([])
                setArchiveSelect(null)
                break;
            default:
                if(filesAdj[nombre] && filesAdj[nombre]?.length > 1){
                    setImgArrSelect(filesAdj[nombre])
                    setArchiveSelect(filesAdj[nombre][0])
                }
                if(filesAdj[nombre] && filesAdj[nombre]?.length === 1){
                    setArchiveSelect(filesAdj[nombre][0])
                    setImgArrSelect(filesAdj[nombre])
                }
                break;
        }
    }

    const descargarFile = async() =>{
        if(archiveSelect?.pathFile_facon){
            await AuxiliarFunction.downloadFile(DownloadFile.fileNubi({id:archiveSelect?.pathFile_facon}),archiveSelect?.nombreFile_facon)
        }
    }

    const onOpenModalAddDocumentacion = () =>{
        showModal(ModalDocumentacionAdj,{archiveSelect: archiveSelect, onGuardar: onGuardar,consulta: consulta, onSelectViewImg:onSelectViewImg })
    }
    return (
        <Card sx={{background: '#fff !important',p:1,boxShadow:4}}>
            <Grid container>
                <Grid item xs={4} md={2}>
                    <Box sx={{width: '100%',height: 50,border: 1,borderColor: 'gray',display: 'flex',alignItems:'center',justifyContent: 'center'}}>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold'}}>Adjuntos</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('DNI Frente' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'DNI Frente' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('DNIFRENTE')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>DNI Frente</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('DNI Dorso' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'DNI Dorso' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('DNIDORSO')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>DNI Dorso</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Constancia AFIP' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Constancia AFIP' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('CONSTANCIA')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Constancia AFIP</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Contrato Social-Estatuto' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Contrato Social-Estatuto' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('SOCIAL-ESTATUTO')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Contrato Social-Estatuto</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Acta Actualizada' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Acta Actualizada' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('ACTA ACTUALIZADA')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Acta Actualizada</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Poder-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Poder-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('PODER-APODERADO')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Poder-Apoderado</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Dni-Frente-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Dni-Frente-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('DNI-FRENTE-APODERADO')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Dni-Frente-Apoderado</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 50,
                            border: 1,
                            borderColor: 'gray',
                            display: 'flex',
                            alignItems:'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            background: ('Dni-Dorso-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                            color: ( 'Dni-Dorso-Apoderado' === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                        }}
                        onClick={()=>onSelectViewImg('DNI-DORSO-APODERADO')}
                    >
                        <Typography sx={{color: '#6b788e',fontWeight: 'bold'}}>Dni-Dorso-Apoderado</Typography>
                    </Box>
                    {filesAdj && Object.keys(filesAdj).length > 0 && (
                        Object.keys(filesAdj).map((fileKey)=>(
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 50,
                                    border: 1,
                                    borderColor: 'gray',
                                    display: 'flex',
                                    alignItems:'center',
                                    justifyContent: 'center',
                                    background: (fileKey === imgArrSelect[0]?.lugarAdjuntado_facon || fileKey === archiveSelect?.lugarAdjuntado_facon ) ? '#e6f0ff !important' : '#fff !important',
                                    color: (fileKey === imgArrSelect[0]?.lugarAdjuntado_facon || fileKey === archiveSelect?.lugarAdjuntado_facon ) ? '#15294b !important' : '#6b788e !important',
                                    cursor: 'pointer'
                                }}
                                onClick={()=>onSelectViewImg(fileKey)}
                            >
                                <Typography sx={{fontWeight: 'bold'}}>{fileKey}</Typography>
                            </Box>
                        ))
                    )}
                </Grid>
                <Grid item xs={8} md={10}>
                    <Box sx={{width: '100%',height: 50,border: 1,borderColor: 'gray',display: 'flex', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex',alignItems:'center'}}>
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',ml: 1}}>{archiveSelect?.nombreFile_facon || '-'}</Typography>
                        </Box>
                        <Box sx={{display: 'flex',alignItems:'center',justifyContent: 'center'}}>
                            {(permitidosAdd.includes(archiveSelect?.lugarAdjuntado_facon)) && (
                                <ButtonBasic
                                    textButton='Agregar'
                                    iconStart={<AddIcon />}
                                    variant='wk'
                                    color='nextWhite'
                                    sx={{mr:0.5, color: '#15294b', borderColor: '#15294b', border:1}}
                                    onClick={()=>onOpenModalAddDocumentacion(archiveSelect)}
                                />
                            )}
                            <IconButton sx={{boxShadow: 4, mr:1}} onClick={()=>descargarFile()}>
                                <SaveAltIcon sx={{color: '#15294b'}} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{height: 290,p:1}}>
                        {archiveSelect && mimeArchive && (
                            <>
                                {(mimeArchive === 'jpg' || mimeArchive === 'png' || mimeArchive === 'jpeg' || mimeArchive === 'svg' || mimeArchive === 'x-png')  && (
                                    <PhotoConsumer
                                        key={`IMG${archiveSelect.pathFile_facon}`}
                                        src={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                        intro={archiveSelect.pathFile_facon}
                                    >
                                        <Box sx={{width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <img
                                                src={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                                alt={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                                style={{width:'80%',height:280}}
                                            />
                                        </Box>
                                    </PhotoConsumer>
                                )}
                                {(mimeArchive === 'wmv' || mimeArchive === 'mp4' || mimeArchive === 'avi' || mimeArchive === 'mov') &&(
                                    <ReactPlayer
                                        key={`Video${archiveSelect?.pathFile_facon}`}
                                        url={`${urlNubi}${archiveSelect?.pathFile_facon}&token=${token}`}
                                        width='100%'
                                        height='280px'
                                        controls
                                    />
                                )}
                                {mimeArchive === 'pdf' && (
                                    <Box sx={{width:'100%',height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img
                                            src='/img/pdf.png'
                                            alt={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                            style={{width:150,height:150}}
                                        />
                                    </Box>
                                )}
                                {(mimeArchive === 'doc' || mimeArchive === 'docx') && (
                                    <Box sx={{width:'100%',height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img
                                            src='/img/doc.png'
                                            alt={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                            style={{width:150,height:150}}
                                        />
                                    </Box>
                                )}
                                {(mimeArchive === 'xls' || mimeArchive === 'xlsx') && (
                                    <Box sx={{width:'100%',height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img
                                            src='/img/xls.png'
                                            alt={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                            style={{width:150,height:150}}
                                        />
                                    </Box>
                                )}
                                {mimeArchive === 'txt' && (
                                    <Box sx={{width:'100%',height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img
                                            src='/img/txt.png'
                                            alt={`${urlNubi}${archiveSelect.pathFile_facon}&token=${token}`}
                                            style={{width:150,height:150}}
                                        />
                                    </Box>
                                )}
                                {mimeArchive === 'NoExiste' && (
                                    <Box sx={{width:'100%',height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img
                                            src='/img/sin_datos.jpg'
                                            alt='Sin Imagen'
                                            style={{width:'80%',height:280}}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{width: {xs:100, md:310,lg:360}}}>
                            {imgArrSelect?.length > 1 && (
                                <Card sx={{background: '#6b7280 !important', p:1,borderRadius:3}}>
                                    <Carousel
                                        additionalTransfrom={0}
                                        arrows
                                        autoPlaySpeed={3000}
                                        centerMode
                                        className="bg-gray-500 p-2"
                                        containerClass="container"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite={false}
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 1,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 464,
                                                    min: 0
                                                },
                                                items: 1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 464
                                                },
                                                items: 1,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {imgArrSelect?.map((fot)=>{
                                            let urlNew = `https://zeta.strongsystems.com.ar:8000/api/img?url=${fot?.pathFile_facon}&token=${token}`;
                                            const tipe = fot?.pathFile_facon?.split('.');
                                            if(tipe?.length > 0){
                                                if(tipe[tipe?.length - 1] === 'jpg' || tipe[tipe?.length - 1] === 'png' || tipe[tipe?.length - 1] === 'jpeg' || tipe[tipe?.length - 1] === 'svg' || tipe[tipe?.length - 1] === 'x-png'){
                                                    urlNew = `https://zeta.strongsystems.com.ar:8000/api/img?url=${fot?.pathFile_facon}&token=${token}`
                                                }
                                                if(tipe[tipe?.length - 1] === 'wmv' || tipe[tipe?.length - 1] === 'mp4' || tipe[tipe?.length - 1] === 'avi' || tipe[tipe?.length - 1] === 'mov'){
                                                    urlNew = '/img/tarjeta-tarea.png'
                                                }
                                                if(tipe[tipe?.length - 1] === 'pdf'){
                                                    urlNew = '/img/pdf.png'
                                                }
                                                if(tipe[tipe?.length - 1] === 'doc' || tipe[tipe?.length - 1] === 'docx'){
                                                    urlNew = '/img/doc.png'
                                                }
                                                if(tipe[tipe?.length - 1] === 'xls' || tipe[tipe?.length - 1] === 'xlsx'){
                                                    urlNew = '/img/xls.png'
                                                }
                                                if(tipe[tipe?.length - 1] === 'txt'){
                                                    urlNew = '/img/txt.png'
                                                }
                                                return(
                                                    <Card sx={thumb} key={fot?.id_facon} onClick={()=>setArchiveSelect(fot)}>
                                                        <div style={thumbInner}>
                                                            <img
                                                                src={urlNew}
                                                                style={{width: '95%',height: '95%'}}
                                                                alt='preview'
                                                            />
                                                        </div>
                                                    </Card>
                                                )
                                            }
                                            return ''
                                        })}
                                    </Carousel>
                                </Card>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default TabCuentaDocumentacion