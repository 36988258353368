import { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Card, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import { toast } from 'react-toastify';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, InputEditQuill, CardUploadFile, InputDropZoneComment, SelectCSRouter, SelectCSCosto, SelectMotivoTicket, CardItemServicioAdicional } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import TratoService from '../../services/tratos.service';
import ConfirmationDialog from '../default/ConfirmationDialog';
import { showModal } from 'react-redux-modal-provider';
import CardItemSAIPrefa from '../../components/card/CardItemSAIPrefa';
import ModalSelectPlanPrefa from './ModalSelectPlanPrefa';
import ModalSAIPrefa from './ModalSAIPrefa';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import ModalReConfirmarPrefa from './ModalReConfirmarPrefa';

function ModalConfirmarItemsPrefa({show, hideModal,onGuardar,ticketId,tratoId}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const { sockets } = useContext( SocketContext );
    const [preServicio, setPreServicio] = useState(null);
    const [files, setFiles] = useState([]);
    const [arrSAI, setArrSAI] = useState([]);
    const [selectPlan, setSelectPlan] = useState({});
    const [motivoId, setMotivoId] = useState(null);
    const [costoInst, setCostoInst] = useState(null);
    const [routerInt, setRouterInt] = useState(null);
    const [textTitulo, setTextTitulo] = useState('');
    const [textComentario, setTextComentario] = useState(null);
    const [preViewContrato, setPreViewContrato] = useState(false);

    const [filesCompress, setFilesCompress] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = '';
    const onCompressImg = async(imgx) =>{
        const nombreCortado = imgx?.name?.split('.');
        let extension = nombreCortado[ nombreCortado?.length - 1 ];
        extension = extension?.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx?.id
                    result.preview=imgx?.preview
                    setIsLoading(false);
                    setFilesCompress(old => [...old,result]);
                }
            })
        }else{
            setFilesCompress(old => [...old,imgx]);
        }
    }
    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    const obtenerPreServicio = async() => {
        await TratoService.getByIdPrefa(tratoId).then((resp)=>{
            if(resp?.plan?.id_ds){
                setSelectPlan(resp?.plan)
            }
            if(resp?.sai?.length > 0){
                setArrSAI(resp?.sai)
            }
            if(resp?.info?.conCosto_si){
                setCostoInst(resp?.info?.conCosto_si)
            }
            if(resp?.info?.router_si){
                setRouterInt(resp?.info?.router_si)
            }
            setPreServicio(resp?.trato)
        })
    }
    const onSubmit = async() =>{
        if(!preViewContrato){
            setIsLoading(true);
            const formPreVista = {
                cpostal_c: preServicio?.contacto?.cpostal_c || preServicio?.contacto?.codpostal,
                iva_c: preServicio?.contacto?.iva_c || preServicio?.contacto?.cod_iva,
                nombreCompleto_c: preServicio?.contacto?.nombreCompleto_c || preServicio?.contacto?.nombre,
                documento_c: preServicio?.contacto?.documento_c || preServicio?.contacto?.dni,
                direccion_c: preServicio?.contacto?.direccion_c || preServicio?.contacto?.direccion_cliente,
                telefono_c: preServicio?.contacto?.telefono_c || preServicio?.contacto?.telefono,

                nombreServicio_ds: selectPlan?.nombreServicio_ds,
                anchoBajadaServicio_ds: selectPlan?.anchoBajadaServicio_ds,
                anchoSubidaServicio_ds: selectPlan?.anchoSubidaServicio_ds,
                moneda_ds: selectPlan?.moneda_ds,
                importeServicio_ds: selectPlan?.importeServicio_ds,

                codpostal_t: preServicio?.codpostal_t,
                nombreCompletoContacto1_t: preServicio?.nombreCompletoContacto1_t,
                dniContacto1_t: preServicio?.dniContacto1_t,
                dniContacto1_t: preServicio?.dniContacto1_t,
                celular_t: preServicio?.celular_t,
                contactoEmail_t: preServicio?.contactoEmail_t,
                cargoinstalacion_t: costoInst,
                direccion_t: preServicio?.direccion_t,
                coordenadas_t: preServicio?.coordenadas_t,
            }
            await AuxiliarFunction.downloadFile(
                TratoService.preView(formPreVista),
                'pre-contrato.pdf'
            )
            setTimeout(() => {
                setIsLoading(false);
                setPreViewContrato(true)
            }, 3000);
        }else{
            if(textComentario && textComentario.length > 16){
                setIsLoading(true);
                const items = [selectPlan].concat(arrSAI)
                const form = {
                    estado: 'SI',
                    items: items,
                    tratoId: tratoId,
                    ticketId: ticketId,
                    motivoId: motivoId,
                    cuerpo_tk: textComentario,
                    textTitulo: textTitulo,
                    costoInst,
                    routerInt,
                };
                if(filesCompress?.length > 0){
                    form.filesCompress = filesCompress;
                }
                setIsLoading(true);
                await TratoService.confirmClientePrefa(form).then((resp)=>{
                    if(resp?.id_tk){
                        if(onGuardar){
                            onGuardar()
                        }
                        hideModal();
                    }else{
                        setIsLoading(false);
                    }
                })
                .catch(()=>{
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }else{
                toast.error('El comentario debe tener al menos 10 caractares.', {
                    position: "top-right",
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            }
        }
    }
    useEffect(() => {
        obtenerPreServicio()
    }, [])

    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    const obtenerParteEntera = (numero) => {
        let valor = Number(numero);
        if (isNaN(valor) || valor === 0) {
            return 1;
        }
        return Math.floor(valor);
    }
    const onEliminar = (itemSer) => {
        showModal(ConfirmationDialog, {
            title: 'Eliminar el Servicio Seleccionado',
            message: '¿Esta seguro que desea eliminar el Servicio Seleccionado?',
            onConfirm: () => {
                const filtro = arrSAI.filter((serv)=> (serv.id_ds !== itemSer))
                const filtro2 = filtro.filter((serv)=> (serv?.id !== itemSer))
                setArrSAI(filtro2)
            }
        });
    };
    const onEliminarPlan = () => {
        showModal(ConfirmationDialog, {
            title: 'Eliminar el Plan Seleccionado',
            message: '¿Esta seguro que desea eliminar el Plan junto con sus SAI?',
            onConfirm: () => {
                setSelectPlan(null);
                setArrSAI([]);
                setPreViewContrato(false);
            }
        });
    };
    const onOpenModalPlan = () => {
        showModal(ModalSelectPlanPrefa,{
            onGuardar: setSelectPlan,
            coberturaMaxRF: preServicio?.mbPrefaRF_t || 0,
            coberturaMaxFO: preServicio?.mbPrefaFO_t || 0,
            preServicio: preServicio
        })
    }
    const onOpenModalSai = () => {
        showModal(ModalSAIPrefa,{
            setAdd: setArrSAI,
            serviciosAdd: arrSAI,
            precioPlan: selectPlan?.importeServicio_ds,
            tipoServicio: 'PYM'
        })
    }
    const onOpenModalReConfirmarPrefa = (typeTec) => {
        showModal(ModalReConfirmarPrefa,{
            onGuardarPreServicio: obtenerPreServicio,
            ticketId: ticketId,
            tratoId: preServicio?.id_t,
            typeTec: typeTec
        })
    }
    const stylesCard = {background: '#fff !important', boxShadow: 4,p: 1, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Confirmar Items Pre-Servicio
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={stylesCard}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                    Resultado de Prefactibilidad
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: {xs:'column',md: 'row'}}}>
                                    <Card sx={{...stylesCard, minWidth: 250}}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000'}} component='div'>Tecnología: <Box fontWeight='bold' display='inline'>Radio Frecuencia</Box></Typography>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000'}} component='div'>Prefactibilidad: <Box fontWeight='bold' display='inline'>{preServicio?.prefactibilidadRF_t}</Box></Typography>
                                        </Box>
                                        {preServicio?.prefactibilidadRF_t === 'NEGATIVA' ? (
                                            <ButtonBasic
                                                textButton='Ingresar prefactibilidad'
                                                variant="wk"
                                                color='nextStep'
                                                sx={{minWidth: 150}}
                                                onClick={()=>onOpenModalReConfirmarPrefa('RF')}
                                            />
                                        ):(
                                            <Box sx={{display: 'flex', flexDirection: 'row',ml: 2}}>
                                                <Typography sx={{color: '#000'}} component='div'>Cobertura máxima Mb: <Box fontWeight='bold' display='inline'>{preServicio?.mbPrefaRF_t}</Box></Typography>
                                            </Box>
                                        )}
                                    </Card>
                                    <Card sx={{...stylesCard, minWidth: 250,ml: {xs: 0,md: 2}, mt: {xs:1, md: 0}}}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000'}} component='div'>Tecnología: <Box fontWeight='bold' display='inline'>Fibra Optica</Box></Typography>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000'}} component='div'>Prefactibilidad: <Box fontWeight='bold' display='inline'>{preServicio?.prefactibilidadFO_t}</Box></Typography>
                                        </Box>
                                        {preServicio?.prefactibilidadFO_t === 'NEGATIVA' ? (
                                            <ButtonBasic
                                                textButton='Ingresar prefactibilidad'
                                                variant="wk"
                                                color='nextStep'
                                                sx={{minWidth: 150}}
                                                onClick={()=>onOpenModalReConfirmarPrefa('FO')}
                                            />
                                        ):(
                                            <Box sx={{display: 'flex', flexDirection: 'row',ml: 2}}>
                                                <Typography sx={{color: '#000'}} component='div'>Cobertura máxima Mb: <Box fontWeight='bold' display='inline'>{preServicio?.mbPrefaFO_t || '-'}</Box></Typography>
                                            </Box>
                                        )}
                                    </Card>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Card sx={stylesCard}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                    Plan
                                </Typography>
                                {selectPlan?.servicioIdSoftland_ds ? (
                                    <Card sx={{background: '#0364ff !important',p:2,borderRadius:2}}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <Typography variant="h6" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                                                    {selectPlan?.nombreServicio_ds}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} mt={-1}>
                                                <IconButton
                                                    sx={{
                                                        background: '#f1f1f1',
                                                        '&:hover':{
                                                            background: '#fff'
                                                        }
                                                    }}
                                                    onClick={()=>onEliminarPlan()}
                                                >
                                                    <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',alignItems: 'center', flexDirection: 'row'}}>
                                                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'center'}}>
                                                    {`${obtenerParteEntera(selectPlan?.anchoBajadaServicio_ds)} Mb`}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        border: 1,
                                                        borderColor: '#fff',
                                                        borderRadius:50,
                                                        minWidth:95,
                                                        color: '#0364ff',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        minHeight:30,
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        fontSize: 12,
                                                        background: '#fff',
                                                        ml: 2,
                                                        p: 1
                                                    }}
                                                >
                                                    {`$ ${selectPlan?.importeServicio_ds} /mes`}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ):(
                                    <ButtonBasic
                                        textButton='Seleccionar Plan'
                                        variant="wk"
                                        color='nextStep'
                                        sx={{minWidth: 150}}
                                        onClick={()=>onOpenModalPlan()}
                                    />
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Card sx={stylesCard}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                    SAI
                                </Typography>
                                <ButtonBasic
                                        textButton='Seleccionar Items'
                                        variant="wk"
                                        color='nextStep'
                                        sx={{minWidth: 150}}
                                        onClick={()=>onOpenModalSai()}
                                    />
                                {arrSAI?.length > 0 && (
                                    arrSAI.map((sai)=>(
                                        <CardItemSAIPrefa
                                            key={sai?.id_ds}
                                            item={sai}
                                            precioPlan={selectPlan?.importeServicio_ds}
                                            onEliminar={onEliminar}
                                        />
                                    ))
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Motivo del ticket</Typography>
                            <SelectMotivoTicket
                                id="motivoTareaId"
                                emp='nubicom'
                                instalacion
                                value={motivoId}
                                onChange={(opt)=>{
                                    setMotivoId(opt.value);
                                    setTextTitulo(opt.label)
                                    setTextComentario(opt.descripcion);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Costo</Typography>
                            <SelectCSCosto
                                value={costoInst}
                                onChange={(e)=> {setPreViewContrato(false); setCostoInst(e?.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Router</Typography>
                            <SelectCSRouter
                                value={routerInt}
                                onChange={(e)=> setRouterInt(e?.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Título del ticket</Typography>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                value={textTitulo}
                                sx={styleInput}
                                onChange={(e)=>setTextTitulo(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold',mt: 0.5}}>
                                Comentario
                            </Typography>
                            <InputEditQuill
                                template={textComentario}
                                onChangeText={(text)=>setTextComentario(text)}
                            />
                        </Grid>
                        { filesCompress?.length > 0 &&(
                            filesCompress.map((fil)=>(
                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                            </Grid>
                            ))
                        )}
                    </Grid>
                    { filesCompress?.length < 5 &&(
                        <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                            <InputDropZoneComment
                                id="otImg"
                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                setFoto={setFiles}
                                accept={acceptInputFile}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton={!preViewContrato ? 'Ver Pre-Contrato' : 'Confirmar'}
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={
                            !ticketId
                            || !tratoId
                            || !textComentario
                            || !textTitulo
                            || !routerInt
                            || !costoInst
                            || !motivoId
                        }
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalConfirmarItemsPrefa;