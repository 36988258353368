import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import "quill-mention";
import 'react-quill/dist/quill.snow.css'
const Styles = styled.div`
    .ql-toolbar{
        padding-bottom: 15px;
    }
    .ql-container{
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        color: #000;
    }
    .ql-editor{
        height: 180px;
    }
    .ql-container.ql-snow{
        background: #fff;
    }
    .ql-snow.ql-toolbar {
        display: block;
        background: #e6f0ff;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        position: sticky!important;
        top: 0;
        z-index: 1;
    }
    span.mention {
        color: blue;
        background: lightskyblue;
    }
`;

const InputEditQuillSinEtiqueta =({onChangeText,template,id,etiqueta}) => {
    const [value, setValue] = useState(template || '')

    const modules = useMemo(() => ({
        toolbar: [
            ['bold', 'italic', 'underline','strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'header': [2, 3, false] }],
            [{ 'align': [] }],
            [{ 'color': ['#ff0000', '#00ff00', '#0000ff', '#000000', '#ffffff'], 'default': '#ff0000' }, { 'background': [] }],
            ['clean']
        ],
    }), [])
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent','background','color','align','mention'
    ]
    const onChangeTemplete = (newEditor) =>{
        onChangeText(newEditor)
        setValue(newEditor)
    }

    useEffect(() => {
        onChangeText(template)
        setValue(template)
    }, [template])
    return (
        <Styles>
            <ReactQuill
                id={id}
                theme="snow"
                value={value}
                onChange={onChangeTemplete}
                modules={modules}
                formats={formats}
            />
        </Styles>
    )
}

export default InputEditQuillSinEtiqueta