import { useState, useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material'
import { ButtonBasic, CardRecordatorio, CardViewTicket } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalCrearRecordatorio from '../../../modals/recordatorios/ModalCrearRecordatorio';

const TabCuentaRecodatorios = ({
    recordatoriosAll,
    cuenta,
    contactoId,
    preServicioId,
    servicioId,
    consultaId,
    ticketId,
    nombreCompleto,
    onGuardar,
}) => {

    const onClickNewRecordatorio = () => {
        showModal(ModalCrearRecordatorio,{
            onGuardar:onGuardar,
            nombreCompleto: nombreCompleto,
            contactoId: contactoId,
            cuentaId: cuenta,
            servicioId: servicioId,
            preServicioId: preServicioId,
            consultaId: consultaId,
            ticketId: ticketId,
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} display='flex' justifyContent='start' alignItems='center'>
                <ButtonBasic
                    variant='wk'
                    color='nextStep'
                    textButton='Nuevo Recordatorio'
                    iconStart={<AddIcon />}
                    onClick={()=>onClickNewRecordatorio()}
                />
            </Grid>
            <Grid item xs={12} sm={6} display='flex' justifyContent='end' alignItems='end'>
                <Typography sx={{color: '#15294b', fontWeight: 'bold'}} variant='h6'>
                    {`${recordatoriosAll?.length || '0'} resultados`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={{border:1, borderColor: '#ebedf0',borderRadius:1}}>
                    <Grid item xs={8} sm={8} p={1.5}>
                        <Typography sx={{color: '#000',fontWeight: 'bold',ml:10}}>Recordatorios</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} display='flex' alignItems='center'>
                        <Divider orientation='vertical' sx={{background: '#ebedf0'}} />
                        <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',ml:4}}>Estado</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} display='flex' alignItems='center'>
                        <Divider orientation='vertical' sx={{background: '#ebedf0'}} />
                        <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',ml:4}}>Acciones</Typography>
                    </Grid>
                </Grid>
                <Box mb={5}>
                    {recordatoriosAll?.length > 0 && (
                        recordatoriosAll.map((tkv)=> <CardRecordatorio key={tkv?.id_rec} item={tkv} onGuardar={onGuardar} /> )
                    )}
                </Box>
            </Grid>
        </Grid>
    )
}

export default TabCuentaRecodatorios