import { Box, Card, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { showModal } from 'react-redux-modal-provider';
import FechaFunction from '../../function/FechaFunction';
import ModalEstadoSoftland from '../../modals/servicio/ModalEstadoSoftland';
import TratoService from '../../services/tratos.service';
import ButtonBasic from '../buttons/ButtonBasic';
import TextEstadoServicio from '../text/TextEstadoServicio';
import usePermisos from '../../hooks/usePermisos';
import ConfirmationDialog from '../../modals/default/ConfirmationDialog';

const CardCuentaProducto = ({
    item,
    numero,
    onGuardar
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const permisos = usePermisos();
    const onOpenModalEstadoSoft = () =>{
        showModal(ModalEstadoSoftland,{extension:numero, servicio: item[0],onGuardar: onGuardar })
    }
    const onActivarEstadoSoft = async() =>{
        setIsLoading(true)
        const form = {
            tipoContrato: item[0].tipo,
            contratoId: item[0].numero,
            nroExt: numero,
            estado: '01',
        }
        await TratoService.changeEstadoSoftland(form).then(()=>{
            setIsLoading(false)
            onGuardar()
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    const onOpenModalActivar = () =>{
        showModal(ConfirmationDialog,{
            title: 'Esta por activar el servicio',
            message: '¿Esta seguro que desea continuar? Al confirmar se llevara a cabo el proceso de activacion en softland.',
            onConfirm: onActivarEstadoSoft
        })
    }
    return (
        <Card sx={{background: '#f5f6f7 !important',p: 2,borderRadius: 3}}>
            <Grid container>
                <Grid item xs={6} sx={{display:'flex', justifyContent: 'start', alignItems: 'center'}}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}} variant='h3'>{`Extensión ${numero}`}</Typography>
                </Grid>
                <Grid item xs={6} sx={{display:'flex', justifyContent: 'end', alignItems: 'center'}}>
                    <>
                        {item?.length > 0 && item[0]?.estado?.codigo === '01' && permisos['softland.bajaSuspension'] && (
                            <ButtonBasic
                                textButton='Baja/Suspension'
                                onClick={()=>onOpenModalEstadoSoft()}
                                variant='wk'
                                color='error'
                            />
                        )}
                        {item?.length > 0 && (item[0]?.estado?.codigo === '02' || item[0]?.estado?.codigo === '03' || item[0]?.estado?.codigo === '04') && permisos['softland.activarServicio'] && (
                            <ButtonBasic
                                textButton='Activar'
                                onClick={()=>onOpenModalActivar()}
                                variant='wk'
                                color='nextWhite'
                            />
                        )}
                    </>
                </Grid>
                <Grid xs={12} mt={2}>
                {item && item?.length > 0 &&(
                    item?.map((it)=>(
                        <Card sx={{background: '#fff !important',mb: 3,border:1, borderColor: '#000',borderRadius: 4}}>
                            <Box
                                sx={{ background: it?.estado?.descripcion === 'Servicio Habilitado' ? '#e6f8f2' : '#fce7e7', p: 2, display: 'flex',alignItems: 'center'}}
                            >
                                <Typography sx={{color: '#15294b',fontWeight: 'bold',mr:2}} variant='h5'>{it?.articulo?.nombre}</Typography>
                                <TextEstadoServicio descripcion={it?.estado?.descripcion} />
                            </Box>
                            <Grid container>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Tipo:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.tipo}</Box></Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Precio:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{`${it?.articulo?.precio} ${it?.articulo?.moneda}`}</Box></Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Cantidad:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.articulo?.cantidad}</Box></Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Vigencia:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.articulo?.vigencia === 'S' ? 'SI' : 'NO'}</Box></Typography>
                                    </Box>
                                </Grid>
                                {it?.articulo?.vigencia_desde && (
                                    <Grid xs={12} sm={4} pl={2}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Desde:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(it?.articulo?.vigencia_desde,'dd/MM/yyyy')}</Box></Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {it?.articulo?.vigencia_hasta && (
                                    <Grid xs={12} sm={4} pl={2}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Hasta:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(it?.articulo?.vigencia_hasta,'dd/MM/yyyy')}</Box></Typography>
                                        </Box>
                                    </Grid>
                                )}
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Condición pago:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.condicion_pago?.descripcion}</Box></Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Contratista:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.contratista?.descripcion}</Box></Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={4} pl={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{it?.ubicacion?.domicilio}</Box></Typography>
                                    </Box>
                                </Grid>
                                {it?.ubicacion?.latitud && it?.ubicacion?.longitud && (
                                    <Grid xs={12} sm={4} pl={2} mb={2}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>
                                                {`${it?.ubicacion?.latitud}, ${it?.ubicacion?.longitud}`}
                                            </Box></Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {it?.estado?.alta && (
                                    <Grid xs={12} sm={4} pl={2}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Fecha de Alta:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(it?.estado?.alta,'dd/MM/yyyy')}</Box></Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {it?.estado?.modificacion && (
                                    <Grid xs={12} sm={4} pl={2} mb={2}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Ultima Modificación:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(it?.estado?.modificacion,'dd/MM/yyyy')}</Box></Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Card>
                    ))
                )}
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardCuentaProducto