import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import MotivoCorreoInvalidoService from '../../services/correo.invalido.services';

const ModalMotivoEmailInvalido = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        nombre_mcptke: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        }
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {createdEmp_mt: 'NUBICOM'};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_mcptke']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(item?.id_mcptke > 0) {
                form.id_mcptke = item.id_mcptke
                MotivoCorreoInvalidoService.update(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }else{
                MotivoCorreoInvalidoService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.nombre_mcptke,'nombre_mcptke')
            }, 100);
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_mcptke > 0 ? 'Editar Motivo Correo Invalido' : 'Registrar Motivo Correo Invalido'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.nombre_mcptke.texto}
                                error={formAll.nombre_mcptke.estadoErr}
                                helperText={formAll.nombre_mcptke.estadoErr && formAll.nombre_mcptke.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'nombre_mcptke')}
                                sx={styleInput}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalMotivoEmailInvalido;