import axios from 'axios';

function prefa(query = null) {
    return axios.get('calv2/prefa', {
        params: query
    });
}

function tareasTicketCalendar(id) {
    return axios.get(['calv2/tareastk', id].join('/'));
}

function lugaresCalendar() {
    return axios.get('calv2/lugares');
}

function registerTareaCalendar(data) {
    return axios.post('calv2/tarea', data);
}

function tareasCalendar(query = null) {
    return axios.get('calv2/tareas',{
        params: query
    });
}

function canalesCalendar() {
    return axios.get('calv2/canales');
}

const CalendarioV2Service = {
    prefa,
    tareasTicketCalendar,
    lugaresCalendar,
    registerTareaCalendar,
    tareasCalendar,
    canalesCalendar
};
export default CalendarioV2Service;
