import { useEffect, useMemo, useState } from 'react';
import { Box, Card, Checkbox, FormControlLabel, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonBasic, InputEditQuill, InputSearchContactNubicom, SelectCanalesSoftland, SelectEstadoConsulta, SelectGrupos, SelectIvaSoftland, SelectLocalidadesSoftland, SelectLugaresCalendar, SelectTipoDocSoftland } from '../../../components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
import usePermisos from '../../../hooks/usePermisos';
import CallIcon from '@mui/icons-material/Call';
import CorporativoService from '../../../services/corporativo.service';
import StoreIcon from '@mui/icons-material/Store';
import RouterIcon from '@mui/icons-material/Router';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const FormPaso1Screen = ({item}) => {
  const permisos = usePermisos();
  const [isLoading, setIsLoading] = useState(false);
  const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false})
  const [tipoRegistro, setTipoRegistro] = useState('Consulta');
  const [isCuenta, setIsCuenta] = useState(null);
  const [isPotCliente, setIsPotCliente] = useState(null);
  const [createPreServ, setCreatePreServ] = useState(false);
  const [createTkPrefa, setCreateTkPrefa] = useState(false);
  const navigate = useNavigate()
  const [formAll, setFormAll] = useState({
    nombreCompleto_c: {
      texto: null,
      textoErr: 'La razon socual es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    canalcomu_c: {
        texto: null,
        textoErr: 'El canal es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    codCanal_tk: {
      texto: null,
      textoErr: 'El canal es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompletoContacto1_t: {
        texto: null,
        textoErr: 'El nombre es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    emailContacto1_t: {
        texto: null,
        textoErr: 'El correo es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    celularContacto1_t: {
        texto: null,
        textoErr: 'El celular es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    nombre_t: {
        texto: null,
        textoErr: 'El nombre es requerido',
        estadoErr: false,
        tipo: 'string'
    },
    documento_c: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    iva_c: {
      texto: null,
      textoErr: 'El iva es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    celular_c: {
      texto: null,
      textoErr: 'El celular es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    email_c: {
      texto: null,
      textoErr: 'El correo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    cpostal_c: {
      texto: null,
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    lugarId_t: {
      texto: null,
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    lugarId_c: {
      texto: null,
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    barrio_t: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    calle_t: {
      texto: null,
      textoErr: 'La calle es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    latitud_c: {
      texto: null,
      textoErr: 'La latitud es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    observacionesTkPrefa: {
      texto: '<p><strong>Solicitud de prefactibilidad.</strong></p>',
      textoErr: 'La observaciones son requeridas',
      estadoErr: false,
      tipo: 'string'
    },
    observacionesConsulta: {
      texto: '',
      textoErr: 'La observaciones son requeridas',
      estadoErr: false,
      tipo: 'string'
    },
    estadoConsulta: {
      texto: null,
      textoErr: 'La observaciones son requeridas',
      estadoErr: false,
      tipo: 'string'
    },
  });

  const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onChangeForm = (value,input) =>{
    let formOld = formAll;
    if(!formOld[input]){
      formOld[input] = {
        texto: value,
        textoErr: `El ${input} es requerido`,
        estadoErr: false,
        tipo: 'string'
      }
    }else{
      formOld[input].texto = value
    }
    if((!value || value === '') && (input !== 'telefono_c' && input !== 'documento_c' && input !== 'latitud_c' && input !== 'longitud_c')){
      formOld[input].estadoErr = true;
    }else{
      if(input === 'email_c' || input === 'emailContacto1_t'){
        if(validateEmail(value)){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else if(input === 'documento_c'){
        if(value){
          if(value > 1000000){
            formOld[input].estadoErr = false
          }else{
            formOld[input].estadoErr = true
          }
        }
      }else{
        formOld[input].estadoErr = false
      }
    }
    setFormAll({...formOld})
  }

  const onChangeFormSetError = (input,clear) =>{
    let formOld = formAll;
    formOld[input].estadoErr = !clear ? true : false
    setFormAll({...formOld})
  }

  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
      marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border:1,
        height: 45,
        color: '#000'
      },
      '&:hover':{
        border: 1,
        borderRadius: 2,
        borderColor: 'gray',
        height: 40
      },
      '&.Mui-focused': {
        '&:hover':{
          border:0,
          height: 40
        }
      },
      color: 'gray',
      height: 40
    }
  }), [])

  const onSubmitContacto = async() => {
    const form = {segmento_con: 'COR',tipoRegistro};
    let isOk = false;
    setIsLoading(true);
    const inputRequired = [
      'nombreCompleto_c',
      'canalcomu_c',
      'codCanal_tk',
      'nombreCompletoContacto1_t',
    ];
    if(tipoRegistro === 'Consulta'){
      inputRequired.push('observacionesConsulta','estadoConsulta')
    }else{
      form.createPreServ = createPreServ;
      form.createTkPrefa = createTkPrefa;
      if(createPreServ){
        inputRequired.push('nombre_t')
        if(createTkPrefa){
          inputRequired.push('observacionesTkPrefa')
        }
      }
    }
    const propertyNames = Object.keys(formAll);
    await Promise.resolve(
      propertyNames.forEach((name)=>{
      if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
        form[name] = formAll[name].texto
      }else{
        if(inputRequired.includes(name)){
          isOk = true;
          onChangeFormSetError(name)
        }
      }
    }))
    if(!isOk){
      if(isPotCliente){
        form.idPotCliente = isPotCliente?.id_c;
      }
      if(isCuenta){
        form.idCuenta = isCuenta?.cuenta;
      }
      form.canal_con = formAll.canalcomu_c.texto;
      form.nombre_t = `${formAll.nombreCompleto_c.texto?.toUpperCase() || ''} - ${ formAll.nombre_t.texto?.toUpperCase() || ''}`
      await CorporativoService.registerContactoConsulta(form).then((response)=>{
        setIsLoading(false)
        if(response?.potCliente){
          navigate(`/contactos/${response?.potCliente}/nubicom`)
        }else if(response?.cuenta){
          navigate(`/contactos/${response?.cuenta}/cuenta`)
        }else{
          navigate('/contactos/corporativos')
        }
      })
      .finally(()=>setIsLoading(false));
    }else{
      setIsLoading(false);
    }
  }

  const onSubmitCoordenadas = () =>{
    setIsLoading(true)
    const quitarEspacios = coordenadas.texto.replace(/ /g, "")
    const separador = quitarEspacios.split(',')
    if(separador.length === 2){
        const validCoord = `${separador[0]}, ${separador[1]}`
        const valid = AuxiliarFunction.isValidCoordinates(validCoord)
        if(valid){
          setIsLoading(false)
          onChangeForm(separador[0],'latitud_c')
          onChangeForm(separador[1],'longitud_c')
          setCoordenadas({texto: coordenadas.texto, estadoErr: false})
        }else{
            setIsLoading(false)
            setCoordenadas({texto: coordenadas.texto, estadoErr: true})
        }
    }else{
        setIsLoading(false)
        setCoordenadas({texto: coordenadas.texto, estadoErr: true})
    }
  }

  const onSelectContacto = (e) => {
    if(e?.cuenta){
      setIsCuenta(e);
      setIsPotCliente(null);
      onChangeForm(e?.nombre_completo,'nombreCompleto_c')
      onChangeForm(e?.dni_cuit?.numero,'documento_c')
      onChangeForm(e?.iva?.codigo,'iva_c')
      onChangeForm(e?.contacto?.telefono,'celular_c')
      onChangeForm(e?.contacto?.email?.toLowerCase(),'email_c')
      onChangeForm(e?.ubicacion?.codigo_postal,'cpostal_c')
      onChangeForm('','nombreCompletoContacto1_t')
    }else{
      setIsCuenta(null);
      setIsPotCliente(e);
      onChangeForm(e?.nombreCompleto_c,'nombreCompleto_c')
      onChangeForm(e?.documento_c || '','documento_c')
      onChangeForm(e?.iva_c,'iva_c')
      onChangeForm(e?.celular_c,'celular_c')
      onChangeForm(e?.email_c,'email_c')
      onChangeForm(e?.cpostal_c,'cpostal_c')
      onChangeForm(e?.nombreCompletoContacto1_c,'nombreCompletoContacto1_t')
    }
  }

  useEffect(() => {
    if(coordenadas?.texto?.length > 12){
      onSubmitCoordenadas()
    }else{
      onChangeForm(null,'latitud_c')
      onChangeForm(null,'longitud_c')
      setCoordenadas({texto: null, estadoErr: true})
    }
  }, [coordenadas])

  const styleInput = useMemo(() => ({
    '& .MuiInputLabel-root':{
        color: 'gray',
        fontSize: 14,
        marginTop: -0.4
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            border:1,
            color: '#000',
        },
        '&:hover':{
            border: 0.1,
            borderRadius: 2,
            borderColor: 'gray',
        },
        '&.Mui-focused': {
            '&:hover':{
                border: 0,
                borderRadius: 2,
            }
        },
        color: 'gray',
    }
  }), [])

  useEffect(() => {
    if(item?.id_c){
      setIsCuenta(null);
      setIsPotCliente(item);
      onChangeForm(item?.nombreCompleto_c,'nombreCompleto_c')
      onChangeForm(item?.documento_c || '','documento_c')
      onChangeForm(item?.iva_c,'iva_c')
      onChangeForm(item?.celular_c,'celular_c')
      onChangeForm(item?.email_c,'email_c')
      onChangeForm(item?.cpostal_c,'cpostal_c')
      onChangeForm(item?.nombreCompletoContacto1_c,'nombreCompletoContacto1_t')
    }
  }, [])

  return (
    <Box sx={{overflow: 'overlay',height: '85vh'}} className='side'>
      <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center'}} variant='h3'>
        CONTACTO CORPORATIVO/EVENTUAL
      </Typography>
      <Grid container mt={2} spacing={1}>
        <Grid item xs={12} md={11.9} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{color: '#000', fontWeight: 'bold',mt: 1, ml: 3}} variant='h5'>
                Tipo de registro
              </Typography>
              <Card sx={{background: '#fff !important',ml:0.3, boxShadow: 4,borderRadius: 4}}>
                  <Grid container p={2}>
                      <Grid item xs={12} sm={5}>
                          <Typography sx={{color: '#000',marginLeft:1,fontSize:14, opacity: 0.6,marginTop:1}}>Datos obligatorios según el tipo de registro</Typography>
                      </Grid>
                      <Grid item xs={12} sm={5.9} ml={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <ToggleButtonGroup
                          color="primary"
                          value={tipoRegistro}
                          exclusive
                          onChange={(e)=>setTipoRegistro(e.target.value)}
                          aria-label="Platform"
                          >
                              <ToggleButton
                                  value="Consulta"
                                  sx={{
                                  background: '#e7e7e9',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                                  '&.MuiToggleButton-root':{
                                      border:2,
                                  },
                                  '&.Mui-selected':{
                                      background: '#e6f0ff',
                                      color: '#0364FF',
                                      borderColor: '#0364FF',
                                      border:2,
                                      '&:hover':{
                                      background: '#e6f0ff',
                                      color: '#0364FF',
                                      borderColor: '#0364FF',
                                      border:2,
                                      }
                                  },
                                  '&:hover':{
                                      background: '#e6f0ff',
                                      color: '#0364FF',
                                      borderColor: '#0364FF',
                                      border:2,
                                  }
                                  }}
                              >
                                  Consulta
                              </ToggleButton>
                              <ToggleButton
                                  value="Contacto"
                                  sx={{
                                  background: '#e7e7e9',borderWidth:2,borderWidth:1,fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                                  '&.MuiToggleButton-root':{
                                      border:2,
                                  },
                                  '&.Mui-selected':{
                                      background: '#e6f8f2',
                                      color: '#018155',
                                      borderColor: '#018155',
                                      border:2,
                                      '&:hover':{
                                      background: '#e6f8f2',
                                      color: '#018155',
                                      borderColor: '#018155',
                                      border:2,
                                      }
                                  },
                                  '&:hover':{
                                      background: '#e6f8f2',
                                      color: '#018155',
                                      borderColor: '#018155',
                                      border:2,
                                  }
                                  }}
                              >
                                  Contacto
                              </ToggleButton>
                          </ToggleButtonGroup>
                      </Grid>
                  </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{background: '#fff !important',borderRadius: 3,boxShadow: 2}}>
                <Box display='flex' justifyContent='space-between' p={1}>
                  <Box display='flex' pl={2} pt={1}>
                      <StoreIcon sx={{color: '#000',fontSize: 30}} />
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Datos de la Compañia</Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                  <ButtonBasic
                        textButton='Limpiar registro'
                        variant="wk"
                        color="downloadHome"
                        sx={{border: 1,marginTop:0.5}}
                        onClick={()=> navigate('/load',{state:'/contactos/nuevocorporativo'})}
                      />
                  </Box>
                </Box>
                <Grid container p={2} spacing={1}>
                  <Grid item xs={12}>
                    <Typography sx={{color: '#000',marginLeft: 1}}>Buscar contacto</Typography>
                    <InputSearchContactNubicom
                      corporativo
                      onClick={(e)=>onSelectContacto(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Razón Social</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.nombreCompleto_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'nombreCompleto_c')}
                      InputLabelProps={{shrink: formAll.nombreCompleto_c.texto}}
                      inputProps={{ readOnly: isCuenta }}
                      error={formAll.nombreCompleto_c.estadoErr}
                      helperText={formAll.nombreCompleto_c.estadoErr && formAll.nombreCompleto_c.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>CUIT</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      type='number'
                      value={formAll.documento_c.texto}
                      onChange={(e)=>onChangeForm(Number(e.target.value),'documento_c')}
                      InputLabelProps={{shrink: formAll.documento_c.texto}}
                      inputProps={{ readOnly: isCuenta, min: 1000000 ,inputProps: { min: 1000000 } }}
                      error={formAll.documento_c.estadoErr}
                      helperText={formAll.documento_c.estadoErr && formAll.documento_c.textoErr}
                      FormHelperTextProps={{ className: '-mt-1' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>IVA</Box>
                      </Typography>
                    </Box>
                    <SelectIvaSoftland
                      filter
                      isInvalid={formAll.iva_c.estadoErr}
                      value={formAll.iva_c.texto}
                      onChange={(e)=>onChangeForm(e.value,'iva_c')}
                      tipoDoc='80'
                      finalCorpo
                      disabled={isCuenta}
                    />
                    {formAll.iva_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.iva_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Canal</Box>
                      </Typography>
                    </Box>
                    <SelectCanalesSoftland
                      isInvalid={formAll.canalcomu_c.estadoErr}
                      value={formAll.canalcomu_c.texto}
                      onChange={(e)=>{
                        onChangeForm(e.value,'canalcomu_c')
                        onChangeForm(e.value,'codCanal_tk')
                      }}
                    />
                    {formAll.canalcomu_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Celular</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      type='number'
                      value={formAll.celular_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'celular_c')}
                      InputLabelProps={{shrink: formAll.celular_c.texto}}
                      inputProps={{ readOnly: isCuenta }}
                      error={formAll.celular_c.estadoErr}
                      helperText={formAll.celular_c.estadoErr && formAll.celular_c.textoErr}
                      InputProps={{ inputProps: { readOnly: isCuenta, min: 100000000 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.email_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'email_c')}
                      InputLabelProps={{shrink: formAll.email_c.texto}}
                      inputProps={{readOnly: isCuenta}}
                      error={formAll.email_c.estadoErr}
                      helperText={formAll.email_c.estadoErr && formAll.email_c.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad</Box>
                        </Typography>
                    </Box>
                    <SelectLocalidadesSoftland
                      value={formAll.cpostal_c.texto}
                      isInvalid={formAll.cpostal_c.estadoErr}
                      onChange={(e)=>{
                        onChangeForm(e.value,'cpostal_c')
                        onChangeForm(e.provinciaId,'provincia_c')
                      }}
                    />
                    {formAll.cpostal_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cpostal_c.textoErr}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Box display='flex' alignItems='center'>
                        <CallIcon sx={{color: '#000',fontSize: 20}} />
                        <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 0.5}} variant='h5'>Datos del Contacto</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.nombreCompletoContacto1_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'nombreCompletoContacto1_t')}
                      InputLabelProps={{shrink: formAll.nombreCompletoContacto1_t.texto}}
                      error={formAll.nombreCompletoContacto1_t.estadoErr}
                      helperText={formAll.nombreCompletoContacto1_t.estadoErr && formAll.nombreCompletoContacto1_t.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Celular</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      type='number'
                      value={formAll.celularContacto1_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'celularContacto1_t')}
                      InputLabelProps={{shrink: formAll.celularContacto1_t.texto}}
                      error={formAll.celularContacto1_t.estadoErr}
                      helperText={formAll.celularContacto1_t.estadoErr && formAll.celularContacto1_t.textoErr}
                      InputProps={{ inputProps: { min: 100000000 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.emailContacto1_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'emailContacto1_t')}
                      InputLabelProps={{shrink: formAll.emailContacto1_t.texto}}
                      error={formAll.emailContacto1_t.estadoErr}
                      helperText={formAll.emailContacto1_t.estadoErr && formAll.emailContacto1_t.textoErr}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        { tipoRegistro === 'Contacto' ? (
          <Grid xs={12} md={12} lg={5.9} ml={0.5} mr={0.5} mb={1} mt={{xs:1, lg: 4}}>
            <Card sx={{background: '#fff !important', borderRadius: 3,boxShadow: 2}}>
              <Box p={2}>
                <Box display='flex' pt={1}>
                    <LocationOnIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>
                      Dirección de instalación
                    </Typography>
                </Box>
                <Grid container mb={1} mt={1} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad Calendario</Box>
                          </Typography>
                      </Box>
                      <SelectLugaresCalendar
                        value={formAll.lugarId_t.texto}
                        isInvalid={formAll.lugarId_t.estadoErr}
                        onChange={(e)=>{
                          onChangeForm(e?.value,'lugarId_t')
                          onChangeForm(e?.codLocalidad,'codpostal_t')
                          onChangeForm(e?.codProvincia,'codprovincia_t')
                        }}
                      />
                      {formAll.lugarId_t.estadoErr && (
                        <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        InputLabelProps={{shrink: true}}
                        value={coordenadas?.texto}
                        onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={()=>onSubmitCoordenadas()}>
                              <GpsFixedIcon sx={{color: '#000'}} size={35} />
                            </IconButton>
                          ),
                        }}
                      />
                      {coordenadas?.estadoErr && (
                        <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={5.9}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                        </Typography>
                      </Box>
                      <Typography sx={{color: '#000'}}> </Typography>
                      <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll.barrio_t.texto}
                        onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                        InputLabelProps={{shrink: formAll.barrio_t.texto}}
                        error={formAll.barrio_t.estadoErr}
                        helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                      />
                    </Grid>
                    <Grid xs={12} md={5.9} ml={0.6} mt={1}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll.calle_t.texto}
                        onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                        InputLabelProps={{shrink: formAll.calle_t.texto}}
                        error={formAll.calle_t.estadoErr}
                        helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                      />
                    </Grid>
                </Grid>
              </Box>
            </Card>
            <Card sx={{background: '#fff !important', mt: 2,borderRadius: 3,boxShadow: 2}}>
              <Grid container>
                <Grid item xs={12}>
                  <Box display='flex' p={2}>
                    <RouterIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: 'bold',
                        marginLeft: 1,
                        marginTop:0.5,
                        fontSize: 16
                      }}
                    >
                      Pre-Servicio y Prefactibilidad
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormControlLabel
                        labelPlacement='start'
                        control={
                            <Checkbox
                                onClick={()=>setCreatePreServ(!createPreServ)}
                                checked={createPreServ}
                                sx={{
                                    color: '#0247B5',
                                    ml: 1,
                                    '&.Mui-checked': {
                                        color: '#0247B5',
                                    },
                                }}
                            />
                        }
                        label="¿Desea crear el pre-servicio?"
                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                    />
                </Grid>
                <Grid item xs={12} md={7} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {createPreServ && (
                    <FormControlLabel
                      labelPlacement='start'
                      control={
                          <Checkbox
                              onClick={()=>setCreateTkPrefa(!createTkPrefa)}
                              checked={createTkPrefa}
                              sx={{
                                  color: '#0247B5',
                                  ml: 1,
                                  '&.Mui-checked': {
                                      color: '#0247B5',
                                  },
                              }}
                          />
                      }
                      label="¿Desea crear el ticket de prefactibilidad?"
                      componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                    />
                  )}
                </Grid>
              </Grid>
              <Box p={2}>
                  <Grid container mb={1} mt={1} spacing={1}>
                    <Grid item xs={12}>
                      {createPreServ && (
                        <>
                          <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                              * <Box sx={{fontWeight: 'normal',display: 'inline' }}>
                                {`Nombre: ${ formAll.nombreCompleto_c.texto || ''} - ${ formAll.nombre_t.texto || ''}`}
                              </Box>
                            </Typography>
                          </Box>
                          <TextField
                            fullWidth
                            autoComplete='off'
                            sx={styleInputHeight}
                            value={formAll.nombre_t.texto}
                            onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
                            InputLabelProps={{shrink: formAll.nombre_t.texto}}
                            error={formAll.nombre_t.estadoErr}
                            helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      { createPreServ && createTkPrefa && (
                        <>
                          <Typography sx={{color: '#000',fontWeight: 'bold'}}>
                            Descripción: Ticket de prefactibilidad
                          </Typography>
                          <InputEditQuill
                            id="Comentario"
                            template={formAll.observacionesTkPrefa.texto}
                            etiqueta
                            onChangeText={(html)=>{
                              onChangeForm(html,'observacionesTkPrefa')
                            }}
                          />
                          {formAll.observacionesTkPrefa.estadoErr && (
                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesTkPrefa.textoErr}</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} lg={5.9}>
            <Card sx={{background: '#fff !important', borderRadius: 3,boxShadow: 2}}>
              <Box p={2}>
                <Box display='flex' pt={1}>
                    <LibraryBooksIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>
                      Nueva Consulta
                    </Typography>
                </Box>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} md={6}>
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Estado</Box>
                      </Typography>
                    </Box>
                    <SelectEstadoConsulta
                      name='estadoId'
                      id='estadoId'
                      value={formAll.estadoConsulta.texto}
                      todos
                      isInvalid={formAll?.estadoConsulta?.estadoErr}
                      onChange={(pl)=>onChangeForm(pl.value,'estadoConsulta')}
                    />
                    {formAll?.estadoConsulta?.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>
                        {formAll.estadoConsulta.textoErr}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad Calendario</Box>
                          </Typography>
                      </Box>
                      <SelectLugaresCalendar
                        value={formAll.lugarId_t.texto}
                        isInvalid={formAll.lugarId_t.estadoErr}
                        onChange={(e)=>{
                          onChangeForm(e?.value,'lugarId_t')
                          onChangeForm(e?.codLocalidad,'codpostal_t')
                          onChangeForm(e?.codProvincia,'codprovincia_t')
                        }}
                      />
                      {formAll.lugarId_t.estadoErr && (
                        <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5.9}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                      </Typography>
                    </Box>
                    <Typography sx={{color: '#000'}}> </Typography>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.barrio_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                      InputLabelProps={{shrink: formAll.barrio_t.texto}}
                      error={formAll.barrio_t.estadoErr}
                      helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                    />
                  </Grid>
                  <Grid xs={12} md={5.9} ml={0.6} mt={1}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.calle_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                      InputLabelProps={{shrink: formAll.calle_t.texto}}
                      error={formAll.calle_t.estadoErr}
                      helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      InputLabelProps={{shrink: true}}
                      value={coordenadas?.texto}
                      onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={()=>onSubmitCoordenadas()}>
                            <GpsFixedIcon sx={{color: '#000'}} size={35} />
                          </IconButton>
                        ),
                      }}
                    />
                    {coordenadas?.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Motivo de consulta</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInput}
                      multiline
                      value={formAll.observacionesConsulta.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'observacionesConsulta')}
                      InputLabelProps={{shrink: formAll.observacionesConsulta.texto}}
                      error={formAll.observacionesConsulta.estadoErr}
                      helperText={formAll.observacionesConsulta.estadoErr && formAll.observacionesConsulta.textoErr}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          color='prevStep'
          onClick={()=>navigate(-2)}
          sx={{border:1, borderColor: '#000'}}
        />
        <ButtonBasic
          textButton={tipoRegistro === 'Contacto' ? 'Crear Contacto' : 'Crear Consulta'}
          iconStart={<PersonAddIcon />}
          isLoading={isLoading}
          onClick={()=>onSubmitContacto()}
          variant='wk'
          color='nextStep'
          sx={{border:1, borderColor: '#fff',marginLeft: 1}}
        />
      </Box>
    </Box>
  )
}

export default FormPaso1Screen;