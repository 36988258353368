import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function usePermisos() {
    const user = useSelector(state => state.auth.currentUser);

    const [resultado, setResultado] = useState({})

    useEffect(() => {
        if(user){
            const {autorizado} = user;
            const json = {};
            if(autorizado){
                autorizado.forEach((accion)=>{
                    json[accion] = '*'
                })
            }
            setResultado(json);
        }
        return () => {
        }
    }, [user])

    return resultado
}

export default usePermisos;
