import { Box, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import {
    CardDashboardTotal,
    CardEstadoTicket,
    CardTotalEstadoTk,
    DateRange } from "../../components";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TablaTicketService from "../../services/tablaTicket.service";
import FechaFunction from "../../function/FechaFunction";
import { useNavigate } from "react-router-dom";
import { showModal } from "react-redux-modal-provider";

const AnaliticsUserIdScreen = () => {
    const [tickets, setTickets] = useState();
    const navigate = useNavigate();
    const [fechaSelect, setFechaSelect] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    const actualizar = (data) => {
        TablaTicketService.countTicketTic(data).then((c)=>{
            setTickets(c)
        })
    }

    useEffect(() => {
        const fechas={
            fecIni:FechaFunction.format(fechaSelect.startDate, 'yyyy-MM-dd'),
            fecFin:FechaFunction.format(fechaSelect.endDate, 'yyyy-MM-dd'),
        }
        actualizar(fechas)
    }, [fechaSelect])
    useEffect(() => {
        document.title = `Zeta-CRM`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} mb={20}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography className="text-gray-900 font-bold" variant="h1">Dashboard</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AnaliticsUserIdScreen