import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { showModal } from 'react-redux-modal-provider';
import PaymentIcon from '@mui/icons-material/Payment';
import ModalDirEntregaUpdate from '../notificaciones/ModalDirEntregaUpdate';
import ModalNoVeoServicio from '../servicio/ModalNoVeoServicio';
import ModalCombinarTicket from '../ticket/ModalCombinarTicket';
import ModalValidarTarjeta from '../softland/ModalValidarTarjeta';

const ModalAccionesDirEntrega = ({show, hideModal}) => {
    const handleClose = () => hideModal();
    const onOpenEditContacto = () =>{
        handleClose()
        showModal(ModalDirEntregaUpdate)
    }

    const onOpenNoVeoServicio = () =>{
        handleClose()
        showModal(ModalNoVeoServicio)
    }

    const onOpenCombinarTicket = () =>{
        handleClose()
        showModal(ModalCombinarTicket)
    }

    const onOpenValidarTarjeta = () =>{
        handleClose()
        showModal(ModalValidarTarjeta)
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        ¿Que desea hacer?
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Grid item xs={12} md={12}>
                            <ButtonBasic
                                iconStart={<PersonAddAlt1Icon sx={{color: '#fff'}} />}
                                variant='wk'
                                color='nextStep'
                                textButton='EDITAR UN CONTACTO'
                                sx={{width: 250, borderRadius: 2}}
                                onClick={()=>onOpenEditContacto()}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ButtonBasic
                                iconStart={<PersonSearchIcon sx={{color: '#fff'}} />}
                                variant='wk'
                                color='nextStep'
                                textButton='NO ENCUENTRO UN SERVICIO'
                                sx={{width: 250, borderRadius: 2}}
                                onClick={()=>onOpenNoVeoServicio()}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ButtonBasic
                                iconStart={<PaymentIcon sx={{color: '#fff'}} />}
                                variant='wk'
                                color='nextStep'
                                textButton='VALIDAR TARJETA'
                                sx={{width: 250, borderRadius: 2}}
                                onClick={()=>onOpenValidarTarjeta()}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ButtonBasic
                                iconStart={<PublishedWithChangesIcon sx={{color: '#fff'}} />}
                                variant='wk'
                                color='nextStep'
                                textButton='COMBINAR TICKETS'
                                sx={{width: 250, borderRadius: 2}}
                                onClick={()=>onOpenCombinarTicket()}
                                isDisabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default ModalAccionesDirEntrega