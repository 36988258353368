import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill, SelectEmpresas, SelectGrupos, SelectMotivoBajaTk, SelectNivelAdmTicket, SelectSubTipoTicket, SelectTipoTarea, SelectTipoTicket } from '../../components';
import MotivoTicketService from '../../services/motivoTicket.service';

const ModalPlantillaMotivoTicket = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        grupoId_mt: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        empresaId_mt: {
            texto: null,
            textoErr: 'El empresa es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoTareaId_mt: {
            texto: null,
            textoErr: 'El tipo de tarea es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoTicketId_mt: {
            texto: null,
            textoErr: 'El tipo de ticket es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        subTipoTicketId_mt: {
            texto: null,
            textoErr: 'El sub-tipo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nivelAdmTkId_mt: {
            texto: null,
            textoErr: 'El nivel administrativo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        motivoBajaTk_mt: {
            texto: null,
            textoErr: 'El motivo de baja es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        dayResolucion_mt: {
            texto: null,
            textoErr: 'La cantidad de dias es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        motivo_mt: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuerpo_mt: {
            texto: '',
            textoErr: 'La descripcion es requerida.',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }

    const onSubmit = async() => {
        const form = {createdEmp_mt: 'NUBICOM'};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['motivo_mt','cuerpo_mt','empresaId_mt','grupoId_mt','tipoTareaId_mt','tipoTicketId_mt','subTipoTicketId_mt']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if (item?.id_mt > 0) {
                form.id_mt = item.id_mt
                MotivoTicketService.update(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(true);
                    });
            } else {
                MotivoTicketService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .finally(() => {
                        setIsLoading(true);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }

    const onChangeSelects = (value, input) =>{
        if(input === 'tipoTicketId_mt'){
            onChangeForm( value,'tipoTicketId_mt')
            onChangeForm( null,'subTipoTicketId_mt')
            onChangeForm( null,'nivelAdmTkId_mt')
            onChangeForm( null,'motivoBajaTk_mt')
        }else if(input === 'subTipoTicketId_mt'){
            onChangeForm( value,'subTipoTicketId_mt')
            onChangeForm( null,'nivelAdmTkId_mt')
            onChangeForm( null,'motivoBajaTk_mt')
        }else if(input === 'nivelAdmTkId_mt'){
            onChangeForm( value,'nivelAdmTkId_mt')
            onChangeForm( null,'motivoBajaTk_mt')
        }
    }
    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.cuerpo_mt || '','cuerpo_mt')
                onChangeForm(item?.dayResolucion_mt || 0,'dayResolucion_mt')
                onChangeForm(item?.grupoId_mt,'grupoId_mt')
                onChangeForm(item?.empresaId_mt,'empresaId_mt')
                onChangeForm(item?.motivoBajaTk_mt,'motivoBajaTk_mt')
                onChangeForm(item?.motivo_mt,'motivo_mt')
                onChangeForm(item?.nivelAdmTkId_mt,'nivelAdmTkId_mt')
                onChangeForm(item?.subTipoTicketId_mt,'subTipoTicketId_mt')
                onChangeForm(item?.tipoTareaId_mt,'tipoTareaId_mt')
                onChangeForm(item?.tipoTicketId_mt,'tipoTicketId_mt')
            }, 100);
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                    {item?.id_mt > 0 ? 'Editar plantilla de ticket' : 'Registrar plantilla de ticket'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Empresa:</Typography>
                            <SelectEmpresas
                                value={formAll.empresaId_mt.texto}
                                onChange={(e)=>onChangeForm(e.value, 'empresaId_mt')}
                            />
                            {formAll.empresaId_mt.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.empresaId_mt.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Destino:</Typography>
                            <SelectGrupos
                                value={formAll.grupoId_mt.texto}
                                onChange={(e)=>onChangeForm(e.value, 'grupoId_mt')}
                                empresaId={formAll.empresaId_mt.texto}
                            />
                            {formAll.grupoId_mt.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.grupoId_mt.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Tipo de Ticket:</Typography>
                            <SelectTipoTicket
                                value={formAll.tipoTicketId_mt.texto}
                                onChange={(e)=>onChangeSelects(e.value, 'tipoTicketId_mt')}
                            />
                            {formAll.tipoTicketId_mt.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoTicketId_mt.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Sub-Tipo:</Typography>
                            <SelectSubTipoTicket
                                tipoTicket={formAll.tipoTicketId_mt.texto}
                                isDisabled={!formAll.tipoTicketId_mt.texto}
                                value={formAll.subTipoTicketId_mt.texto}
                                onChange={(e)=>{
                                    onChangeSelects(e.value, 'subTipoTicketId_mt')
                                    onChangeForm(e.resolucion || 0, 'dayResolucion_mt')
                                }}
                            />
                            {formAll.subTipoTicketId_mt.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.subTipoTicketId_mt.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Nivel Administrativo:</Typography>
                            <SelectNivelAdmTicket
                                value={formAll.nivelAdmTkId_mt.texto}
                                subTipoTicket={formAll.subTipoTicketId_mt.texto}
                                isDisabled={!formAll.subTipoTicketId_mt.texto}
                                onChange={(e)=>onChangeSelects(e.value, 'nivelAdmTkId_mt')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Motivo Baja:</Typography>
                            <SelectMotivoBajaTk
                                value={formAll.motivoBajaTk_mt.texto}
                                isDisabled={formAll.nivelAdmTkId_mt.texto !== 10}
                                nivelAdmTk={formAll.nivelAdmTkId_mt.texto}
                                onChange={(e)=>onChangeForm(e.value, 'motivoBajaTk_mt')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000'}}>Tipo Tarea:</Typography>
                            <SelectTipoTarea
                                value={formAll.tipoTareaId_mt.texto}
                                onChange={(e)=>onChangeForm(e.value, 'tipoTareaId_mt')}
                            />
                            {formAll.tipoTareaId_mt.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoTareaId_mt.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <Typography sx={{color: '#000'}}>Titulo:</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={formAll.motivo_mt.texto}
                                error={formAll.motivo_mt.estadoErr}
                                helperText={formAll.motivo_mt.estadoErr && formAll.motivo_mt.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'motivo_mt')}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Descripcion</Typography>
                            <InputEditQuill
                                template={formAll.cuerpo_mt.texto}
                                onChangeText={(e)=>onChangeForm(e,'cuerpo_mt')}
                            />
                            {(formAll.cuerpo_mt.estadoErr || formAll.cuerpo_mt.texto?.length < 20) && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cuerpo_mt.textoErr}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalPlantillaMotivoTicket