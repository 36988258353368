import { useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import FormPaso1Screen from './FormPaso1Screen';
import FormPaso2Screen from './FormPaso2Screen';
import { Wizard } from '../../../components/index';
import ContactosAction from '../../../store/actions/contactos';
import { Box } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const ContactoCambioTitularScreen = ({
    iniciarContacto,
    iniciarContrato
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const { trato, ticket } = (location && location.state) || { };
    const options = [
        {
            title: 'Contacto',
            subtitle: 'Datos del nuevo cliente',
            icon: <AccountBoxIcon sx={{fontSize: 35}} />
        },
        {
            title: 'Documentación y Servicio',
            subtitle: 'Imagenes y Confirmación',
            icon: <DescriptionOutlinedIcon sx={{fontSize: 35}} />
        }
    ];

    useEffect(() => {
        if(trato){
            iniciarContacto(null);
            iniciarContrato(trato);
        }
        navigate('/contactos/cambio-titular#step1');
        document.title = 'Cambio Titular';
    }, []);

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto',maxWidth:'1280px', margin: 'auto'}} className='side'>
            <Box p={2} marginBottom={0}>
                <Wizard options={options}>
                    <FormPaso1Screen ticket={ticket} />
                    <FormPaso2Screen />
                </Wizard>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoCambioTitularScreen);
