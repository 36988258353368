import { useEffect, useState, useContext } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { SocketContext } from "../../context/SocketContext";
import ModalTipoTicket from "../../modals/admTicket/ModalTipoTicket";
import TipoTicketService from "../../services/tipoTicket.service";
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const TipoTicketScreen = () => {
    const [items, setItems] = useState([]);
    const actualizar = async() =>{
        await TipoTicketService.getAll({all: true}).then((resp)=>{
            setItems(resp)
        })
    }
    const { sockets } = useContext( SocketContext );


    const columns = [
        {
            name: "id_ttk",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_ttk",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_ttk',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                    return <Chip label="Activo" color="success" />
                    }
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { !tableMeta.rowData[2] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () => {
        actualizar()
        sockets?.emit('newTipoTK');
    };
    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_ttk === item)
            showModal(ModalTipoTicket, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalTipoTicket, {onGuardar: onGuardar});
        }
    };
    const onEliminar = (item) => {
        const searchItem = items.find((sItem)=> sItem.id_ttk === item)
        showModal(ConfirmationDialog, {
            title: `${searchItem?.isDeleted_ttk ? 'Activar' : 'Eliminar'} el Tipo de Ticket`,
            message: `¿Esta seguro que desea ${searchItem?.isDeleted_ttk ? 'activar' : 'eliminar'} el Tipo de Ticket?`,
            onConfirm: () => {
                TipoTicketService.destroy(searchItem.id_ttk).then(() => {
                    onGuardar();
                });
            }
        });
    };
    useEffect(() => {
        actualizar();
        document.title = 'Tipo Ticket';
    }, [])
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Tipo de Ticket</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TipoTicketScreen