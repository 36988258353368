import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { useState, useEffect } from 'react';
import { CardViewTicket } from '../../components';

function ModalTicketsPreServicio({id, show, hideModal,item, history}) {
    const handleClose = () => hideModal();
    const [tickets, setTickets] = useState([])
    useEffect(() => {
        const arrTk = [];
        if(item?.tratoxticket?.length > 0){
            item?.tratoxticket.forEach(txt => {
                if(txt?.ticket){
                    arrTk.push(txt?.ticket)
                }
            });
            setTickets(arrTk)
        }
    }, [item])
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Box sx={{display: 'flex', flexDirection: 'row',justifyContent:'start',alignItems:'center'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h5' component='div'>{`Pre-Servicio #${item?.id_t} - `}<Box fontWeight='normal' display='inline'>{item?.nombre_t}</Box></Typography>
                    </Box>
                </DialogTitleDefault>
                <DialogContent className='side'>
                    {tickets && tickets?.length > 0 && (
                        tickets?.map((tkI)=>(
                            <CardViewTicket item={tkI} key={tkI?.id_t} onCloseModal={handleClose} />
                        ))
                    )}
                </DialogContent>
            </Box>
        </Dialog>
    );
}

ModalTicketsPreServicio.propTypes = {
    show: PropTypes.bool
};

ModalTicketsPreServicio.defaultProps = {
    show: false
};

export default ModalTicketsPreServicio