import { InfoWindow, Marker } from '@react-google-maps/api';
import {memo,useState,useCallback, useEffect} from 'react'
function MarkerFibraNaps({
    coordenadas,
    item,
    ...defaultProps
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [colorIcon, setcolorIcon] = useState('/img/nap-fibra-optica.png')
    useEffect(() => {
        switch (item?.estadoColor || item?.estado) {
            case 'VERDE':
            case 'DISPONIBLE':
                setcolorIcon('/img/nap-verde.png')
                break;
            case 'AMARILLO':
                setcolorIcon('/img/nap-amarillo.png')
                break;
            case 'NARANJA':
                setcolorIcon('/img/nap-naranja.png')
                break;
            case 'NO DISPONIBLE':
            case 'ROJO':
                setcolorIcon('/img/nap-rojo.png')
                break;
            default:
                setcolorIcon('/img/nap-fibra-optica.png')
                break;
        }
    }, [item])
    return (
        <Marker
            {...defaultProps}
            position={coordenadas}
            onClick={()=>setIsVisible(!isVisible)}
            icon={{
                url: colorIcon,
                scaledSize: {
                    width:50,
                    height:50,
                }
            }}
        >
            { isVisible && (
                <InfoWindow
                    className="card"
                    position={coordenadas}
                    onCloseClick={()=>{setIsVisible(!isVisible)}}
                >
                    <>
                        <h3 style={{color: '#000',textAlign: 'center'}} >{item?.nombre_fnap || item?.nombre}</h3>
                        <div style={{color: '#000',textAlign: 'center'}}>{`Distancia: ${item?.distanciaReal || item?.distanciaMts || '-'}Mts`}</div>
                        <div style={{color: '#000',textAlign: 'center'}}>{`Ocupados: ${item?.usados}/${item?.puertos}`}</div>
                    </>
                </InfoWindow>
            )}
        </Marker>
    );
}

export default memo(MarkerFibraNaps);
