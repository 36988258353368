import { createContext } from 'react';
import useSocket  from '../hooks/useSocket';

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
const { sockets, online } = useSocket('https://zeta.strongsystems.com.ar:8000/');
    return (
        <SocketContext.Provider value={{ sockets, online }}>
            { children }
        </SocketContext.Provider>
    )
}
export default SocketProvider;
