import { InfoWindow, Marker } from '@react-google-maps/api';
import { memo, useState, useEffect } from 'react'
function MarkerClienteDash({
    coordenadas,
    item,
    ...defaultProps
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [colorIcon, setcolorIcon] = useState('/img/antena2.png')
    useEffect(() => {
        switch (item?.tipoTecnologia) {
            case 'FO':
                setcolorIcon('/img/fibra-optica.png')
                break;
            default:
                setcolorIcon('/img/antena2.png')
                break;
        }
    }, [item])
    return (
        <Marker
            {...defaultProps}
            position={coordenadas}
            onClick={()=>setIsVisible(!isVisible)}
            icon={{
                url: colorIcon,
                scaledSize: {
                    width:50,
                    height:50,
                }
            }}
        >
            { isVisible && (
                <InfoWindow
                    className="card"
                    position={coordenadas}
                    onCloseClick={()=>{setIsVisible(!isVisible)}}
                >
                    <>
                        <h3 style={{color: '#000',textAlign: 'center'}} >{`${item?.cuenta ? '' : 'PRE-'}SERVICIO #${item?.servicio || ''}`}</h3>
                        <div style={{color: '#000',textAlign: 'center',marginTop: -1}}>{`Cliente: ${item?.nombreCliente || ''}`}</div>
                        <div style={{color: '#000',textAlign: 'center'}}>{`Plan: ${item?.nombrePlan || ''}`}</div>
                        <div style={{color: '#000',textAlign: 'center'}}>{`${item?.latitud || ''}, ${item?.longitud || ''}`}</div>
                    </>
                </InfoWindow>
            )}
        </Marker>
    );
}

export default memo(MarkerClienteDash);
