import { useEffect, useState } from 'react'
import { Box, Collapse, Grid } from '@mui/material';
import ButtonBasic from '../buttons/ButtonBasic';
import ListArticuloSoft from './ListArticuloSoft';
import TextInfoTicket from '../text/TextInfoTicket';
import MenuOptServicio from '../menu/MenuOptServicio';

const ListContratoSoft = ({
    tr,
    ticket,
    estadoTicket,
    onGuardar
})=>{
    const [open, setOpen] = useState(false);
    const [nro, setNro] = useState(null);
    const [datos, setDatos] = useState(null);
    const [datosGral, setDatosGral] = useState(null);
    useEffect(() => {
        Object.entries(tr).map((([keys,value])=>{
            setNro(keys)
            setDatos(value ||value[0])
            setDatosGral(value)
            return ''
        }))
    }, [tr])

    return (
        <Box pl={2} pr={2} mt={1} key={`servicio${nro}`}>
            <ButtonBasic
                fullWidth
                onClick={()=>setOpen(!open)}
                textButton={`Servicio: #${nro}`}
                sx={{
                    background: '#fff',
                    border: 1,
                    borderColor:'gray',
                    '&:hover':{
                        background: '#d1d1d1',
                        border: 1,
                        borderColor:'gray',
                    }
                }}
            />
            <Collapse in={open} sx={{marginTop:1,border:1,borderColor:'#dfe2e6',borderRadius:3,boxShadow:3}} key={`collapse${nro}`}>
                <Box p={1} key={`box${nro}`}>
                    <Grid container>
                        <Grid item xs={9}>
                            <TextInfoTicket titulo='Cuenta' descripcion={datos?.cuenta || ((Array.isArray(datos) && datos?.length > 0) && datos?.[0]?.cuenta)  || ''} />
                        </Grid>
                        <Grid item xs={3} sx={{display: 'flex', justifyContent: 'end'}}>
                            <MenuOptServicio
                                servicio={datos}
                                ticket={ticket}
                                estadoTicket={estadoTicket}
                                onGuardar={onGuardar}
                            />
                        </Grid>
                    </Grid>
                    <TextInfoTicket titulo='Cliente' descripcion={datos?.cliente || datos?.descripcion || ((Array.isArray(datos) && datos?.length > 0) && datos?.[0]?.descripcion) || ''} />
                    <TextInfoTicket titulo='Nombre' descripcion={datos?.nombre || ((Array.isArray(datos) && datos?.length > 0) && datos?.[0]?.nombre) || ((Array.isArray(datos) && datos?.length > 0) && datos?.[0]?.nombreServicio) || ''} />
                    <TextInfoTicket
                        titulo='Fec. Instalación'
                        descripcion={
                            datos?.instalacion
                            || ((Array.isArray(datos) && datos?.length > 0) && datos?.[0]?.instalacion)
                            || '-'
                        }
                    />
                    {datosGral &&(
                        <Box sx={{pl:2,pr:2}}>
                            <ListArticuloSoft articulos={datosGral} />
                        </Box>
                    )}
                </Box>
            </Collapse>
        </Box>
    )
}

export default ListContratoSoft