import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import AccionesService from '../../services/acciones.service';

const SelectAcciones = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    stack,
    ...defaultProps
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const actualizar = async() => {
        await AccionesService.getAll().then((response) => {
            setItems(response);
        });
    }
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_ac === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_ac === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    useEffect(() => {
        actualizar();
    }, []);
    useEffect(() => {
        if(items?.length > 0){
            const newOptions = items.map((item) => {
                return {
                    value: item.id_ac,
                    label: item.nombre_ac,
                };
            });
            setOptions(newOptions);
            if(Array.isArray(value)){
                setSelectedValue(value?.map(va=>{
                    const item = items.find(it=>it.id_ac === va);
                    if(item){
                        return {
                            label:item.nombre_ac,
                            value:item.id_ac,
                        };
                    }
                    return null
                }))
            }
        }
    }, [items]);

    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
            {...defaultProps}
        />
    );
};

SelectAcciones.defaultProps = {
    isClearable: false,
    onChange: () => {},
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(SelectAcciones)

