import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { Box, Card, Grid, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import TabTickets from '../../components/buscadorV2/TabTickets';
import TabContacto from '../../components/buscadorV2/TabContacto';
import TabServicios from '../../components/buscadorV2/TabServicios';
import TabPreServicios from '../../components/buscadorV2/TabPreServicios';
import TabPotClientes from '../../components/buscadorV2/TabPotClientes';
import TabTags from '../../components/buscadorV2/TabTags';
import TabAllSearch from '../../components/buscadorV2/TabAllSearch';
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel2"
            hidden={value !== index}
            id={`tabpanels-${index}`}
            aria-labelledby={`tabs-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3,maxHeight:500, overflow: 'auto' }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `tabs-${index}`,
        'aria-controls': `tabpanels-${index}`,
    };
}

const ModalBuscadorV2 = ({show, hideModal,textSearch}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [textSearch2, setTextSearch2] = useState(textSearch || '');
    const [value, setValue] = useState(0);
    const user = useSelector((state) => state.auth.currentUser);

    const handleClose = () => {
        hideModal()
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const onSubmit = async() => {
        handleClose('')
    };

    return(
        <Dialog
            open={show}
            fullScreen
            sx={{backgroundColor:'transparent !important','& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded':{background: 'transparent'}}}
            onClose={handleClose}
        >
            <Grid container spacing={1}>
                <Grid item xs={11} sx={{display:'flex', justifyContent:'center',alignItems: 'center',background: '#fff',minHeight: 70}}>
                    <TextField
                        focused
                        onChange={(e)=>setTextSearch2(e.target.value)}
                        value={textSearch2}
                        placeholder='Buscar...'
                        autoComplete='off'
                        autoFocus
                        InputProps={{
                            style:{ fontWeight: 'normal',fontSize:15,color: '#15294b' },
                            type: 'search',
                            startAdornment:(
                                <SearchIcon sx={{color: '#15294b',opacity: 0.9,marginTop:-0.7}} size={20} />
                            )
                        }}
                        sx={{
                            '& fieldset': {
                                borderRadius: 3
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#b3b9c4',
                                    height: 50,
                                },
                            },
                            '& input':{
                                marginTop: -0.5,
                                marginLeft:1
                            },
                            width: 350,
                            marginLeft: 10
                        }}
                    />
                </Grid>
                <Grid item xs={1} sx={{display:'flex', justifyContent:'center',alignItems: 'center',background: '#fff',minHeight: 70}}>
                    <IconButton onClick={()=>handleClose()}>
                        <CloseIcon sx={{color: '#000'}} />
                    </IconButton>
                </Grid>
                <Grid item xs={12} sx={{display:'flex', justifyContent:'center',alignItems: 'center'}}>
                    {(user?.razonSocial_p && user?.razonSocial_p !== 'Asesor') ? (
                        <Card sx={{background: '#fff !important',marginTop:-0.8,borderRadius: 0, width: '80%'}}>
                            <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                    sx={{
                                        '& .MuiTabs-indicator':{
                                            background: '#0364ff'
                                        },
                                        '& .MuiButtonBase-root':{
                                        color: '#6b788e',
                                        fontWeight: 'bold'
                                        },
                                        '& .MuiButtonBase-root.Mui-selected':{
                                            color: '#0364ff'
                                        }
                                    }}
                                >
                                    <Tab label="Todos" {...a11yProps(0)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Tickets" {...a11yProps(1)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Servicios" {...a11yProps(2)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Pre Servicios" {...a11yProps(3)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Clientes" {...a11yProps(4)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Pots. Clientes" {...a11yProps(5)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Tags" {...a11yProps(6)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <TabAllSearch textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TabTickets textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <TabServicios textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <TabPreServicios textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <TabContacto textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <TabPotClientes textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <TabTags textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                        </Card>
                    ):(
                        <Card sx={{background: '#fff !important',marginTop:-0.8,borderRadius: 0, width: '80%'}}>
                            <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                    sx={{
                                        '& .MuiTabs-indicator':{
                                            background: '#0364ff'
                                        },
                                        '& .MuiButtonBase-root':{
                                        color: '#6b788e',
                                        fontWeight: 'bold'
                                        },
                                        '& .MuiButtonBase-root.Mui-selected':{
                                            color: '#0364ff'
                                        }
                                    }}
                                >
                                    <Tab label="Todos" {...a11yProps(0)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Servicios" {...a11yProps(1)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Pre Servicios" {...a11yProps(2)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Clientes" {...a11yProps(3)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                    <Tab label="Pots. Clientes" {...a11yProps(4)} sx={{ minWidth: {xs:"50px", md: "150px"} }}/>
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <TabAllSearch textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TabServicios textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <TabPreServicios textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <TabContacto textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <TabPotClientes textSearch={textSearch2} handleClose={handleClose} />
                            </TabPanel>
                        </Card>
                    )}
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default ModalBuscadorV2