import {useState,useContext,useMemo, useEffect} from 'react';
import { SocketContext } from '../../context/SocketContext';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import ActionsCvvService from '../../services/actionsCvv.service';
import TicketService from '../../services/tickets.service';
import { ButtonBasic, SelectEmpresas, SelectGrupos, SelectStageTicket, SelectUser } from '../../components';

function ModalAsignarTicket({
    show,
    hideModal,
    ticketId,
    onGuardar,
    type,
    socketOld,
    socketReturn,
    stageTicket
}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [asignar, setAsignar] = useState('GRUPO');
    const [asignadoTK, setAsignadoTK] = useState(null);
    const [asignadoPG, setAsignadoPG] = useState(null);
    const [stageTicketId, setStageTicketId] = useState(stageTicket || null);
    const [empresaId, setEmpresaId] = useState(1);
    const { sockets } = useContext( SocketContext );
    const onSubmit = async() =>{
        const form ={
            ticketId_uxt:Number(ticketId),
            tipo_uxt:asignar,
            tipoId_uxt: asignadoTK,
            type: type,
            stageTicket_tk: stageTicketId
        };
        if(type === 'cvv'){
            ActionsCvvService.asignarTicket(form).then(()=>{
                onGuardar();
                sockets?.emit('newTicket',asignadoPG.toString() )
                sockets?.emit( socketReturn, `${type}${ticketId}` )
                if(socketOld){
                    sockets?.emit( 'refreshTicket', `${socketOld.toString()}R` )
                }
                hideModal();
            })
        }else{
            setIsLoading(true)
            await TicketService.asignar(form).then(()=>{
                onGuardar();
                sockets?.emit('newTicket',asignadoPG.toString() )
                sockets?.emit( socketReturn, `${type}${ticketId}` )
                if(socketOld){
                    sockets?.emit( 'refreshTicket', `${socketOld.toString()}R` )
                }
                setIsLoading(false)
                hideModal();
            }).catch(()=>{
                setIsLoading(false)
            })
        }
    }

    const styleBtnSelect = useMemo(() => ({
        border:1,borderColor: '#15294b', color: '#15294b',background:'white',
        '&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);
    const styleBtnNone = useMemo(() => ({
        background:'#dfe2e6',boxShadow:0,'&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);
    useEffect(() => {
        setAsignadoTK(null)
        setAsignadoPG(null)
    }, [empresaId])

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {`Asignacion del ticket # ${ticketId} `}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom:2}} />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    {/* <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center', width:250,background:'#dfe2e6',p:1,borderRadius:3,mb:2}}>
                        <ButtonBasic
                            textButton='Personal'
                            sx={asignar === 'DNI' ? styleBtnSelect : styleBtnNone}
                            onClick={()=>setAsignar('DNI')}
                        />
                        <ButtonBasic
                            textButton='Grupo'
                            sx={[{marginLeft:0},asignar === 'GRUPO' ? styleBtnSelect : styleBtnNone]}
                            onClick={()=>setAsignar('GRUPO')}
                        />
                    </Box> */}
                    { asignar === 'DNI' ? (
                        <Box sx={{width: 310}}>
                            <SelectUser
                                id="selectUser"
                                filter="select"
                                onChange={(opt) => {
                                    setAsignadoTK(opt.value);
                                    setAsignadoPG(opt.dni)
                                }}
                            />
                        </Box>
                    ):(
                        <>
                            <Box sx={{width: 310}}>
                                <Typography sx={{color: '#000',mt: 1}}>(*) Empresa</Typography>
                                <SelectEmpresas
                                    value={empresaId}
                                    onChange={(opt) => setEmpresaId(opt?.value)}
                                    disabledIds
                                />
                            </Box>
                            <Box sx={{width: 310}}>
                                <Typography sx={{color: '#000',mt: 1}}>(*) Grupos</Typography>
                                <SelectGrupos
                                    id="selectGrupo"
                                    empresaId={empresaId}
                                    onChange={(opt) => {
                                        setAsignadoTK(opt.value);
                                        setAsignadoPG(opt.value);
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    <Box sx={{width: 310}}>
                        <Typography sx={{color: '#000',mt:1}}>(*) Etapa del Ticket</Typography>
                        <SelectStageTicket
                            id="stageTicket"
                            name="stageTicket"
                            notClose
                            value={stageTicketId}
                            onChange={(opt)=>setStageTicketId(opt?.value)}
                        />
                        {!stageTicketId && (
                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>
                                La etapa del ticket es requerida.
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Divider color='gray' sx={{marginTop:2}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        disabled={!asignadoTK || !stageTicketId}
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                    />
                </Box>
            </Box>
        </Modal>
    );
}
export default ModalAsignarTicket;
