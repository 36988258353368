import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import { Typography } from '@mui/material';
import PlantillaEmailService from '../../services/plantillaEmails.service';


const SelectPlantillaEmail = ({value,disabled,onChange,isClearable,errorText, ...props}) => {

    const [selectedValue, setSelectedValue] = useState();
    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const actualizar = async() =>{
        await PlantillaEmailService.getAll().then((resp)=>{
            if(resp?.length > 0){
                setItems(resp)
            }
        })
    }
    const onSelectValue =
    useMemo(() =>
        (ev) => {
            setSelectedValue(ev)
            if(Array.isArray(ev)){
                onChange(ev.map(e=>{
                    const item = items.find(it=>it.id_pmail === e.value);
                    return {
                        ...e,
                        item: item,
                    }
                }));
            } else if(ev){
                ev.item = items.find(it=>it.id_pmail === ev.value);
                onChange(ev);
            } else {
                onChange(null);
            }
        }
    , [])

    useEffect(() => {
        if(items?.length > 0){
            const newOptions = items.map((item) => {
                return {
                    value: item.id_pmail,
                    label: `${item?.asunto_pmail} - ${item?.correo_pmail}`,
                    ...item
                };
            });
            setOptions(newOptions);
        }
    }, [items,]);

    useEffect(() => {
        if( options.length > 0 ){
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(options.find((opt) => opt.value === value));
            } else if(Array.isArray(value)){
                setSelectedValue(value.map((va)=>{
                const busq = options?.find((it)=> it.value === va)
                    return({
                        label:busq?.label,
                        value:busq?.value,
                        ...busq
                    })
                }))
            }
        }
    }, [options,value])

    useEffect(() => {
        actualizar();
    }, [])

    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])

    return (
        <Select
            {...props}
            className='react-select-container'
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder='Seleccione...'
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={disabled}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectPlantillaEmail.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectPlantillaEmail;