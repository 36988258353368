import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import TratosService from '../../services/tratos.service';
import PreTicketsService from '../../services/preTickets.service';

const SelectTicketXServicio = ({id, value, onChange, isInvalid, isClearable, disabled, filtro}) => {

    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        if(filtro){
            PreTicketsService.searchInfoServicio(filtro).then((response) => {
                const newOptions = response.map((item) => {
                    return {
                        value: item.id_tk,
                        label: `#${item.id_tk} - ${item.titulo_tk}`
                    };
                });
                setOptions(newOptions);
                if (typeof value === 'string' || typeof value === 'number') {
                    setSelectedValue(
                        newOptions.find((opt) => opt.value === Number(value))
                    );
                } else if(Array.isArray(value)){
                    setSelectedValue(value.map(va=>({label:`#${va.id_tk} - ${va.titulo_tk}`,value:va.id_tk})));
                }else{
                    setSelectedValue(null)
                }
            });
        }
    }, [filtro]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            isDisabled={disabled}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectTicketXServicio.defaultProps = {
    isClearable: false
};

export default SelectTicketXServicio;
