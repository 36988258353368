import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill, SelectLugaresCalendar } from '../../components';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import {toast} from 'react-toastify';
import TratoService from '../../services/tratos.service';
import { useNavigate } from 'react-router-dom';

const ModalNewServicioSC = ({show, hideModal}) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => hideModal();
    const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false});
    const [tkPrefa, setTkPrefa] = useState(true);
    const [tkInst, setTkInst] = useState(true);
    const [formAll, setFormAll] = useState({
        nombre_t: {
            texto: null,
            textoErr: 'El nombre es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        observacionesPrefa: {
            texto: '<p><strong>Solicitud de prefactibilidad.</strong></p>',
            textoErr: 'La observacion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        observacionesInst: {
            texto: '<p>&nbsp;Descripcion Instalacion Salta Conectada</p>',
            textoErr: 'La observacion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        direccion_t: {
            texto: null,
            textoErr: 'La direccion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        latitud_t: {
            texto: null,
            textoErr: 'La latitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        longitud_t: {
            texto: null,
            textoErr: 'La longitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        codpostal_t: {
            texto: null,
            textoErr: 'El CCPP es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        codprovincia_t: {
            texto: null,
            textoErr: 'La provincia es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        lugarId_t: {
            texto: null,
            textoErr: 'La provincia es requerida',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = { codCanal_tk: '06' };
        if(coordenadas.texto && coordenadas.texto?.length > 10){
            setIsLoading(true)
            const quitarEspacios = coordenadas?.texto?.replace(/ /g, "")
            const separador = quitarEspacios?.split(',')
            if(separador?.length === 2){
                const validCoord = `${separador[0]}, ${separador[1]}`
                const valid = AuxiliarFunction.isValidCoordinates(validCoord)
                if(valid){
                    let isOk = false;
                    setIsLoading(true);
                    const inputRequired = [
                        'nombre_t',
                        'lugarId_t',
                        'direccion_t',
                        'codprovincia_t',
                        'codpostal_t'
                    ];
                    if(tkInst){
                        inputRequired.push('observacionesInst')
                    }
                    if(tkPrefa){
                        inputRequired.push('observacionesPrefa')
                    }
                    const propertyNames = Object.keys(formAll);
                    await Promise.resolve(
                    propertyNames.forEach((name)=>{
                    if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
                        form[name] = formAll[name].texto
                    }else{
                        if(inputRequired.includes(name)){
                            isOk = true;
                            onChangeFormSetError(name)
                        }
                    }
                    }))
                    if(!isOk){
                        form.nombre_t = formAll.nombre_t.texto?.toUpperCase();
                        form.direccion_t = formAll.direccion_t.texto.toUpperCase();
                        form.coordenadas_t = `${separador[0]}, ${separador[1]}`;
                        form.tkInst = tkInst;
                        form.tkPrefa = tkPrefa;
                        await TratoService.registerSC(form).then((resp)=>{
                            if(resp?.id){
                                setTimeout(() => {
                                    navigate(`/servicio/${resp?.id}/cuenta`);
                                    handleClose();
                                    setIsLoading(false);
                                }, 1000);
                            }else{
                                setIsLoading(false);
                            }
                        })
                        .catch(()=>{
                            setIsLoading(false);
                        })
                    }else{
                        toast.error('Verifique los datos.', {
                            position: "top-right",
                            autoClose: 2500,
                            pauseOnHover: false,
                        });
                        setIsLoading(false)
                    }
                }else{
                    toast.error('Las coordenadas no son validas.', {
                        position: "top-right",
                        autoClose: 2500,
                        pauseOnHover: false,
                    });
                    setIsLoading(false)
                    setCoordenadas({texto: coordenadas.texto, estadoErr: true})
                }
            }else{
                toast.error('Las coordenadas no son validas.', {
                    position: "top-right",
                    autoClose: 2500,
                    pauseOnHover: false,
                });
                setIsLoading(false)
                setCoordenadas({texto: coordenadas.texto, estadoErr: true})
            }
        }else{
            toast.error('Las coordenadas son requeridas.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    }

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])
    const onSubmitCoordenadas = () =>{
        if(coordenadas.texto && coordenadas.texto?.length > 10){
            setIsLoading(true)
            const quitarEspacios = coordenadas?.texto?.replace(/ /g, "")
            const separador = quitarEspacios?.split(',')
            if(separador?.length === 2){
                const validCoord = `${separador[0]}, ${separador[1]}`
                const valid = AuxiliarFunction.isValidCoordinates(validCoord)
                if(valid){
                    setIsLoading(false)
                    onChangeForm(separador[0],'latitud_t')
                    onChangeForm(separador[1],'longitud_t')
                    onChangeForm(`${separador[0]},${separador[1]}`,'coordenadas_t')
                    setCoordenadas({texto: coordenadas.texto, estadoErr: false})
                }else{
                    setIsLoading(false)
                    setCoordenadas({texto: coordenadas.texto, estadoErr: true})
                }
            }else{
                setIsLoading(false)
                setCoordenadas({texto: coordenadas.texto, estadoErr: true})
            }
        }
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Servicio - Salta Conectada
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt: 1}} variant='h4'>DATOS DEL SERVICIO</Typography>
                            <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                <Grid container mb={1} mt={1} spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                            (*) <Box sx={{fontWeight: 'normal',display: 'inline' }}>
                                                {`Nombre: ${formAll.nombre_t.texto || ''}`}
                                            </Box>
                                        </Typography>
                                        </Box>
                                        <Typography sx={{color: '#000'}}> </Typography>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.nombre_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
                                            InputLabelProps={{shrink: formAll.nombre_t.texto}}
                                            error={formAll.nombre_t.estadoErr}
                                            helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                                            </Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.direccion_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'direccion_t')}
                                            InputLabelProps={{shrink: formAll.direccion_t.texto}}
                                            error={formAll.direccion_t.estadoErr}
                                            helperText={formAll.direccion_t.estadoErr && formAll.direccion_t.textoErr}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                            (*) <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                                            </Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            InputLabelProps={{shrink: true}}
                                            value={coordenadas?.texto}
                                            error={formAll.latitud_t.estadoErr || coordenadas?.estadoErr}
                                            helperText={formAll.latitud_t.estadoErr && formAll.latitud_t.textoErr}
                                            onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                                            InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={()=>onSubmitCoordenadas()}>
                                                    <GpsFixedIcon sx={{color: '#000'}} size={35} />
                                                </IconButton>
                                            ),
                                            }}
                                        />
                                        {coordenadas?.estadoErr && (
                                            <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                            (*) <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad calendario</Box>
                                        </Typography>
                                        </Box>
                                        <SelectLugaresCalendar
                                        value={formAll.lugarId_t.texto}
                                        isInvalid={formAll.lugarId_t.estadoErr}
                                        onChange={(e)=>{
                                            onChangeForm(e.codProvincia,'codprovincia_t')
                                            onChangeForm(e.codLocalidad,'codpostal_t')
                                            onChangeForm(e.value,'lugarId_t')
                                        }}
                                        />
                                        {formAll.lugarId_t.estadoErr && (
                                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt: 1}} variant='h4'>TICKETS</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                <Grid container>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FormControlLabel
                                            labelPlacement='start'
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTkInst(!tkInst)}
                                                    checked={tkInst}
                                                    sx={{
                                                        color: '#0247B5',
                                                        ml: 2,
                                                        '&.Mui-checked': {
                                                            color: '#0247B5',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="¿Desea crear el ticket de instalacion?"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FormControlLabel
                                            labelPlacement='start'
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTkPrefa(!tkPrefa)}
                                                    checked={tkPrefa}
                                                    sx={{
                                                        color: '#0247B5',
                                                        ml: 2,
                                                        '&.Mui-checked': {
                                                            color: '#0247B5',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="¿Desea crear el ticket de prefactibilidad?"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000',fontWeight: 'bold'} } }}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        { tkInst && (
                            <Grid item xs={12} mt={2}>
                                <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                    <Typography sx={{color: '#000',textAlign: 'center',textDecoration:'underline'}} variant='h4'>Ticket de Instalacion</Typography>
                                    <Typography sx={{color: '#000'}}>Descripción</Typography>
                                    <InputEditQuill
                                        id="ComentarioPrefa"
                                        template={formAll.observacionesInst.texto}
                                        etiqueta
                                        onChangeText={(html)=>{
                                            onChangeForm(html,'observacionesInst')
                                        }}
                                    />
                                    {formAll.observacionesInst.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesInst.textoErr}</Typography>
                                    )}
                                </Card>
                            </Grid>
                        )}
                        { tkPrefa && (
                            <Grid item xs={12} mt={2}>
                                <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                    <Typography sx={{color: '#000',textAlign: 'center',textDecoration:'underline'}} variant='h4'>Ticket de Prefactibilidad</Typography>
                                    <Typography sx={{color: '#000'}}>Descripción</Typography>
                                    <InputEditQuill
                                        id="ComentarioPrefa"
                                        template={formAll.observacionesPrefa.texto}
                                        etiqueta
                                        onChangeText={(html)=>{
                                            onChangeForm(html,'observacionesPrefa')
                                        }}
                                    />
                                    {formAll.observacionesPrefa.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesPrefa.textoErr}</Typography>
                                    )}
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNewServicioSC;