import { Grid } from '@mui/material'
import { CardPreServicioItem } from '../../../components'
import usePermisos from '../../../hooks/usePermisos';

const TabContactoPreServicio = ({preServicios, cuenta,datosOK,datosFaltantes}) => {
    const permisos = usePermisos();
    return (
        <Grid container spacing={1}>
            {preServicios && preServicios?.length > 0 && (
                preServicios.map((preS)=>(
                    <Grid item xs={12} key={`${preS?.id_ds}grid`}>
                        <CardPreServicioItem item={preS} cuenta={cuenta} permisos={permisos} datosOK={datosOK} datosFaltantes={datosFaltantes} />
                    </Grid>
                ))
            )}
        </Grid>
    )
}

export default TabContactoPreServicio