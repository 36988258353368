import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill, SelectGrupos, SelectLocalidadesSoftland } from '../../components';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import {toast} from 'react-toastify';
import AuxSoftland from '../../function/AuxSoftland';
import ApiSoftland from '../../services/apis.softland.service';

const ModalNewDireccionEntregaEventual = ({show, hideModal, contacto, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ticketPrefa, setTicketPrefa] = useState(false);
    const handleClose = () => hideModal();
    const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false})
    const [formAll, setFormAll] = useState({
        nombreCompleto_c: {
            texto: null,
            textoErr: 'La razon socual es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        canalcomu_c: {
            texto: null,
            textoErr: 'El canal es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nombre_t: {
            texto: null,
            textoErr: 'El nombre es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        grupoId: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        observacionesTarea: {
            texto: '<p><strong>Solicitud de prefactibilidad.</strong></p>',
            textoErr: 'La observacion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        barrio_t: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        calle_t: {
            texto: null,
            textoErr: 'El grupo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        direccion_t: {
            texto: null,
            textoErr: 'La direccion es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        codpostal_t: {
            texto: null,
            textoErr: 'La localidad es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        latitud: {
            texto: null,
            textoErr: 'La latitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        cod_iva: {
            texto: 'I',
            textoErr: 'La latitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        nro_cuenta: {
            texto: null,
            textoErr: 'La latitud es requerida',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const coordenadasValid = onSubmitCoordenadas();
        if(coordenadasValid === 'invalido'){
            toast.error('Verifique las coordenadas.', {
                position: "top-right",
                autoClose: 4500,
                pauseOnHover: false,
            });
            return null;
        }
        const form = {
            type: 'nubicom',
            contratistaSoft_t: '01',
            categoria_t: contacto?.cod_segmento || 'PYM',
        };
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_t', 'cod_iva','nro_cuenta','cod_vendedor','cod_zona','cod_pais','barrio_t','calle_t','codpostal_t','codprovincia_t'];
        if(ticketPrefa){
            inputRequired.push('observacionesTarea','grupoId')
        }
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(!ticketPrefa){
                form.observacionesTarea = null;
                form.grupoId = null;
            }
            const aux = AuxSoftland.armadoCategoria(contacto?.cod_segmento || 'PYM');
            form.nombre_t = `${contacto?.nombreCompleto_c || contacto?.nombre_completo} - ${formAll.nombre_t.texto?.toUpperCase()}`
            form.descripcion = `${contacto?.nombreCompleto_c || contacto?.nombre_completo} - ${formAll.nombre_t.texto?.toUpperCase()}`
            form.direccion_t = `${formAll.barrio_t.texto || ''} - ${formAll.calle_t.texto || ''}`?.toUpperCase();
            form.direccion = `${formAll.barrio_t.texto || ''} - ${formAll.calle_t.texto || ''}`?.toUpperCase();
            form.cod_provincia = formAll.codprovincia_t.texto;
            form.cod_postal = formAll.codpostal_t.texto;
            form.cod_vendedor = '01';
            form.cod_zona = '01';
            form.cod_pais = '054';
            form.listaPrecioSoft_t = aux?.listaPrecio;
            form.tipoConSoft_t = aux?.tipoContrato;
            await ApiSoftland.registerDireccionEntrega(form).then(()=>{
                if(onGuardar){
                    onGuardar();
                }
                handleClose();
            })
            .catch(()=>{
                setIsLoading(false);
            })
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(contacto){
            setTimeout(() => {
                onChangeForm('', 'nombre_t')
                if((contacto?.barrio_c || contacto?.direccion_c)){
                    onChangeForm(`${contacto?.barrio_c || ''} - ${contacto?.direccion_c || ''}`, 'direccion_t')
                }else{
                    onChangeForm(contacto?.ubicacion?.direccion || contacto?.direcFac || '-', 'direccion_t')
                }
                onChangeForm(contacto?.celular_c || contacto?.contacto?.telefono || contacto?.telefono || '-', 'celular_t')
                onChangeForm(contacto?.email_c || contacto?.contacto?.email || contacto?.email || '', 'contactoEmail_t')


                onChangeForm(contacto?.cod_iva || 'E', 'cod_iva')
                onChangeForm(contacto?.cuenta, 'nro_cuenta')
            }, 100);
        }
    }, [contacto])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
                borderRadius: 2,
                border: 1,
                borderColor: 'gray',
                height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    const onSubmitCoordenadas = () =>{
        if(coordenadas.texto && coordenadas.texto?.length > 4){
            setIsLoading(true)
            const quitarEspacios = coordenadas.texto.replace(/ /g, "")
            const separador = quitarEspacios.split(',')
            if(separador.length === 2){
                const validCoord = `${separador[0]}, ${separador[1]}`
                const valid = AuxiliarFunction.isValidCoordinates(validCoord)
                if(valid){
                    setIsLoading(false)
                    onChangeForm(separador[0],'latitud')
                    onChangeForm(separador[1],'longitud')
                    onChangeForm(`${separador[0]},${separador[1]}`,'coordenadas_t')
                    setCoordenadas({texto: coordenadas.texto, estadoErr: false})
                    return 'ok';
                }else{
                    setIsLoading(false)
                    setCoordenadas({texto: coordenadas.texto, estadoErr: true})
                    return 'invalido';
                }
            }else{
                setIsLoading(false)
                setCoordenadas({texto: coordenadas.texto, estadoErr: true})
                return 'invalido';
            }
        }else{
            return 'vacio';
        }
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Pre-Servicio Eventual
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h5'>Cliente:</Typography>
                            <Card sx={{background: '#0364ff !important', p:1,boxShadow:2,borderRadius:3,minHeight:110}}>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Nombre Completo: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.nombreCompleto_c || contacto?.nombre_completo || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Correo: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.email_c || contacto?.contacto?.email || contacto?.email || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Celular: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{contacto?.celular_c || contacto?.contacto?.telefono || contacto?.telefono || '-'}</Box></Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Direccion: <Box sx={{fontWeight: 'normal',display: 'inline'}}>{formAll.direccion_t.texto || '-'}</Box></Typography>
                                </Box>
                            </Card>
                            <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt: 2, mb: 1}} variant='h4'>Datos del Pre-Servicio</Typography>
                            <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                <Grid container mb={1} mt={1} spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                            * <Box sx={{fontWeight: 'normal',display: 'inline' }}>
                                                {`Nombre: ${contacto?.nombreCompleto_c || contacto?.nombre_completo} - ${formAll.nombre_t.texto || ''}`}
                                            </Box>
                                        </Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.nombre_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'nombre_t')}
                                            InputLabelProps={{shrink: formAll.nombre_t.texto}}
                                            error={formAll.nombre_t.estadoErr}
                                            helperText={formAll.nombre_t.estadoErr && formAll.nombre_t.textoErr}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>*
                                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad</Box>
                                            </Typography>
                                        </Box>
                                        <SelectLocalidadesSoftland
                                            value={formAll.codpostal_t.texto}
                                            isInvalid={formAll.codpostal_t.estadoErr}
                                            onChange={(e)=>{
                                                onChangeForm(e.value,'codpostal_t')
                                                onChangeForm(e.provinciaId,'codprovincia_t')
                                            }}
                                        />
                                        {formAll.codpostal_t.estadoErr && (
                                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.codpostal_t.textoErr}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>*
                                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                                        </Typography>
                                        </Box>
                                        <Typography sx={{color: '#000'}}> </Typography>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            sx={styleInputHeight}
                                            value={formAll.barrio_t.texto}
                                            onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                                            InputLabelProps={{shrink: formAll.barrio_t.texto}}
                                            error={formAll.barrio_t.estadoErr}
                                            helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={5.9} ml={0.6} mt={1}>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>*
                                            <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                                        </Typography>
                                        </Box>
                                        <TextField
                                        fullWidth
                                        autoComplete='off'
                                        sx={styleInputHeight}
                                        value={formAll.calle_t.texto}
                                        onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                                        InputLabelProps={{shrink: formAll.calle_t.texto}}
                                        error={formAll.calle_t.estadoErr}
                                        helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                                    </Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    sx={styleInputHeight}
                                    InputLabelProps={{shrink: true}}
                                    value={coordenadas?.texto}
                                    error={formAll.latitud.estadoErr || coordenadas?.estadoErr}
                                    helperText={formAll.latitud.estadoErr && formAll.latitud.textoErr}
                                    onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                                    InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={()=>onSubmitCoordenadas()}>
                                            <GpsFixedIcon sx={{color: '#000'}} size={35} />
                                        </IconButton>
                                    ),
                                    }}
                                />
                                {coordenadas?.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Divider sx={{background: 'gray'}} />
                        </Grid>
                        <Grid item xs={12} mt={1} display='flex' justifyContent='left' alignItems='center'>
                            <Typography sx={{color: '#000'}}>¿Desea crear el ticket de prefactibilidad?</Typography>
                            <FormControlLabel
                                labelPlacement='start'
                                control={
                                    <Checkbox
                                        onClick={()=>setTicketPrefa(!ticketPrefa)}
                                        checked={ticketPrefa}
                                        sx={{
                                            color: '#0247B5',
                                            ml: 2,
                                            '&.Mui-checked': {
                                                color: '#0247B5',
                                            },
                                        }}
                                    />
                                }
                                componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                            />
                        </Grid>
                        {ticketPrefa && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',mt:2}} variant='h4'>Ticket de prefactibilidad</Typography>
                                    <Card sx={{background: '#fff !important',p: 1,boxShadow: 4,borderRadius:3}}>
                                        <Grid container mb={1} mt={1} spacing={1}>
                                            <Grid item xs={12}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Seleccione el destinatario</Box>
                                                </Typography>
                                                </Box>
                                                <SelectGrupos
                                                    empresaId={1}
                                                    isInvalid={formAll.grupoId.estadoErr}
                                                    value={formAll.grupoId.texto}
                                                    onChange={(e)=>onChangeForm(e.value,'grupoId')}
                                                />
                                                {formAll.grupoId.estadoErr && (
                                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.grupoId.textoErr}</Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Typography sx={{color: '#000'}}>Descripción: Ticket de prefactibilidad</Typography>
                                    <InputEditQuill
                                        id="Comentario"
                                        template={formAll.observacionesTarea.texto}
                                        etiqueta
                                        onChangeText={(html)=>{
                                            onChangeForm(html,'observacionesTarea')
                                        }}
                                    />
                                    {formAll.observacionesTarea.estadoErr && (
                                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.observacionesTarea.textoErr}</Typography>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNewDireccionEntregaEventual