import { Box, Grid, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"

const TextEstadoTicket = ({titulo, descripcion,abiertoCerrado,estado}) => {
    const [info, setInfo] = useState({
        color: '#74767f',
        background: '#e7e7e9',
        texto: 'En Proceso'
    })
    useEffect(() => {
        if(abiertoCerrado){
            if(!estado){
                setInfo({
                    color: '#018155',
                    background: '#E6F8F2',
                    texto: 'Abierto'
                })
            }else{
                setInfo({
                    color: '#74767f',
                    background: '#e7e7e9',
                    texto: 'Cerrado'
                })
            }
        }else{
            switch (descripcion) {
                case 'ABIERTO':
                    setInfo({
                        color: '#018155',
                        background: '#E6F8F2',
                        texto: 'Abierto'
                    })
                    break;
                case 'CERRADO':
                    setInfo({
                        color: '#74767f',
                        background: '#e7e7e9',
                        texto: 'Cerrado'
                    })
                    break;
                case 'PORVENCER':
                    setInfo({
                        color: '#be7200',
                        background: '#fff2d1',
                        texto: 'Por Vencer'
                    })
                    break;
                case 'VENCEHOY':
                    setInfo({
                        color: '#be7200',
                        background: '#fff2d1',
                        texto: 'Vence Hoy'
                    })
                    break;
                case 'VENCIDO':
                    setInfo({
                        color: '#9d0b0b',
                        background: '#fce7e7',
                        texto: 'Vencido'
                    })
                    break;
                default:
                    break;
            }
        }
    }, [descripcion])

    return (
        <Grid container sx={{marginLeft:2,marginBottom:1}}>
            <Grid item xs={5.1} sm={5.1}>
                <Typography sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12}}>{titulo}</Typography>
            </Grid>
            <Grid item xs={5.9} sm={5.9} display='flex' alignItems='center' justifyContent='end'>
                <Typography
                    sx={{fontWeight: 'bold',color: info?.color,height:23,fontSize:12, width: 100,background: info?.background,display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                    component='div'
                >
                    <Box fontWeight='bold' display='inline' sx={{fontSize: 15,color: info?.color,mr:0.2}}>•</Box>{info?.texto}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default TextEstadoTicket