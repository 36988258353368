import axios from 'axios';

function getAllNodos() {
    return axios.get('mikrotikv2/nodos');
}

function getAllPaneles() {
    return axios.get('mikrotikv2/paneles');
}

function cargarNodoPanelTicket(data) {
    return axios.put(['mikrotikv2','ticket', data.id_tk].join('/'), data);
}

function obtenerServicioId(query = null) {
    return axios.get('mikrotikv2/servicio', {
        params: query
    });
}

function actualizarTicketEquipoServicioId(query = null) {
    return axios.get('mikrotikv2/ticket', {
        params: query
    });
}

const MikrotikV2Service = {
    getAllNodos,
    getAllPaneles,
    cargarNodoPanelTicket,
    obtenerServicioId,
    actualizarTicketEquipoServicioId
};

export default MikrotikV2Service;