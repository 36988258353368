import {useState, useEffect, useCallback} from 'react';
import { Marker} from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
function BoxPrefactibilidad({coordinates, onChange, prefactibilidad, isLoading}) {
    const [marker, setMarker] = useState(null);
    const [colorsSet, setColorsSet] = useState(null)
    const onLoadMarker = useCallback(function callback(markerLoaded) {
        setMarker(markerLoaded);
    }, []);


    const onDragEnd = () => {
        const newCoordinates = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
        };
        onChange(newCoordinates);
    };

    useEffect(() => {
        if(marker){
            marker.setPosition(coordinates);
        }
    }, [coordinates]);
    useEffect(() => {
        if(prefactibilidad?.color){
            switch (prefactibilidad?.color) {
                case '#FBBC05':
                    setColorsSet({
                        backgroundColor: '#fffbe6',
                        borderColor: '#b39500',
                        textColor: '#9c8200'
                    })
                    break;
                case '#2ECC71':
                    setColorsSet({
                        backgroundColor: '#e6f8f2',
                        borderColor: '#018155',
                        textColor: '#01704a'
                    })
                    break;
                case '#EA4335':
                    setColorsSet({
                        backgroundColor: '#fce7e7',
                        borderColor: '#9d0b0b',
                        textColor: '#890a0a'
                    })
                    break;
                case '#ebedf0':
                    setColorsSet({
                        backgroundColor: '#ebedf0',
                        borderColor: '#15294b',
                        textColor: '#091e42'
                    })
                    break;
                default:
                    setColorsSet({
                        backgroundColor: '#ebedf0',
                        borderColor: '#15294b',
                        textColor: '#091e42'
                    })
                    break;
            }
        }
    }, [prefactibilidad])

    return (
        <>
            <Marker
                onLoad={onLoadMarker}
                position={coordinates}
                draggable
                onDragEnd={onDragEnd}
                icon={{
                    url:'/img/location_home.svg',
                    scaledSize: {
                        width:50,
                        height:50,
                    }
                }}
            />
            <Box
                id="divcard"
                sx={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    maxWidth: {xs:'180px', md: '225px'},
                    minWidth: '130px',
                    height: `50px`,
                    padding: `0 12px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "5%",
                    bottom: 30,
                    /* backgroundColor: prefactibilidad ? prefactibilidad.color : 'white', */
                    backgroundColor: colorsSet?.backgroundColor,
                    borderColor: colorsSet?.borderColor,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    borderTopLeftRadius: 12,
                    borderBottomRightRadius: 12,
                    borderBottomLeftRadius: 12,
                }}
            >
                {
                    !isLoading ? (
                        <>
                            {prefactibilidad?.color === '#FBBC05' &&(
                                <WarningAmberIcon sx={{color: colorsSet?.borderColor,marginRight: 0.6, fontSize: 25}} />
                            )}
                            {prefactibilidad?.color === '#2ECC71' &&(
                                <WifiIcon sx={{color: colorsSet?.borderColor,marginRight: 0.6, fontSize: 25}} />
                            )}
                            {prefactibilidad?.color === '#ebedf0' &&(
                                <PodcastsIcon sx={{color: colorsSet?.borderColor,marginRight: 0.6, fontSize: 25}} />
                            )}
                            {prefactibilidad?.color === '#EA4335' &&(
                                <WifiOffIcon sx={{color: colorsSet?.borderColor,marginRight: 0.6, fontSize: 25}} />
                            )}
                            <Typography sx={{color:colorsSet?.textColor,fontWeight: 'bold',fontSize: 15}} id="labelcoberturaMb">{prefactibilidad ? prefactibilidad.descripcion : 'Estado'}</Typography>
                        </>
                    ) : (
                        <CircularProgress size={30} sx={{color: '#98a1b0',marginLeft: 5}} />
                    )
                }
            </Box>
        </>
    );
}


BoxPrefactibilidad.defaultProps = {
    onChange: () => {},
    onClick: () => {},
};

export default BoxPrefactibilidad;
