import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectValidTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const options = [
        {
            label: 'Titulo',
            value: 'titulo_tk',
            tipo: 'texto'
        },
        {
            label: 'Plantilla',
            value: 'motivo_tk',
            tipo: 'select'
        },
        {
            label: 'Etapa del Ticket',
            value: 'stageTicket_tk',
            tipo: 'select'
        },
        /* {
            label: 'Cuerpo',
            value: 'cuerpo_tk',
            tipo: 'texto'
        }, */
        {
            label: 'Estado del Ticket',
            value: 'estado_tk',
            tipo: 'select'
        },
        {
            label: 'Estado Vencimiento del Ticket',
            value: 'estadoVencimiento_tk',
            tipo: 'select'
        },
        {
            label: 'Prioridad',
            value: 'prioridad_tk',
            tipo: 'select'
        },
        {
            label: 'Continuidad del Ticket',
            value: 'ticketIdOld_tk',
            tipo: 'texto'
        },
        {
            label: 'Canal del Ticket',
            value: 'codCanal_tk',
            tipo: 'select'
        },
        {
            label: 'Tipo Ticket',
            value: 'tipoTicketId_tk',
            tipo: 'select'
        },
        {
            label: 'Sub-Tipo Ticket',
            value: 'subTipoTicketId_tk',
            tipo: 'select'
        },
        {
            label: 'Nivel Administrativo',
            value: 'nivelAdmTkId_tk',
            tipo: 'select'
        },
        {
            label: 'Motivo de Baja',
            value: 'motivoBajaTk_tk',
            tipo: 'select'
        },
        {
            label: 'Nodo',
            value: 'nodoName_tk',
            tipo: 'select'
        },
        {
            label: 'Panel',
            value: 'panelName_tk',
            tipo: 'select'
        },
        {
            label: 'Tipo de Cierre',
            value: 'cierreTicketId_tk',
            tipo: 'select'
        },
        {
            label: 'Sub-Tipo de Cierre',
            value: 'subCierreTicketId_tk',
            tipo: 'select'
        },
        {
            label: 'Segmento Detalle Adicional',
            value: 'articuloFallaId_tk',
            tipo: 'select'
        },
        {
            label: 'Problematica',
            value: 'problematicaTkId_tk',
            tipo: 'select'
        },
        {
            label: 'Solucion Tecnica',
            value: 'solucionTecTkId_tk',
            tipo: 'select'
        },
        {
            label: 'Derivo en Asistencia',
            value: 'derivoAsis_tk',
            tipo: 'select'
        },
        {
            label: 'Linea de Vista',
            value: 'lineaVista_tk',
            tipo: 'select'
        },
        {
            label: 'Creado Por',
            value: 'createdPersonaId_tk',
            tipo: 'select'
        },
        {
            label: 'Cerrado Por',
            value: 'closedPersonaId_tk',
            tipo: 'select'
        },
    ]

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={disabled}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectValidTicket.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectValidTicket;