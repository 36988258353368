import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, SelectUser } from '../../components';
import ReportesService from '../../services/reportes.service';

const ModalCompartirReporte = ({show, hideModal, reporte,onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrPersonal, setArrPersonal] = useState([]);

    useEffect(() => {
        if(reporte?.arrUsuarios?.length > 0){
            setArrPersonal(reporte.arrUsuarios)
        }
    }, [])

    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true);
        const form = {
            id_rts : reporte?.id_rts,
            arrPersonal
        }
        await ReportesService.compartir(form).then(()=>{
            setIsLoading(false);
            if(onGuardar){
                onGuardar()
            }
            handleClose();
        }).catch(()=>{
            setIsLoading(false);
        })
    };

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Compartir el Reporte: ${reporte?.titulo_rts}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Seleccione el personal</Typography>
                            <SelectUser
                                value={arrPersonal}
                                filter='allActive'
                                onChange={(opt) => setArrPersonal(opt.map(op=>(op.value)))}
                                isMulti
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCompartirReporte