import { Box, Grid, IconButton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";
import FechaFunction from "../../function/FechaFunction"
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { showModal } from "react-redux-modal-provider";
import ModalRecordatorioById from "../../modals/recordatorios/ModalRecordatorioById";
const CardRecordatorio = ({item, onCloseModal, onGuardar}) => {
    const navigate = useNavigate();
    const onClickNameCliente = (it,tipo) => {
        switch (tipo) {
            case 'contacto':
                setTimeout(() => {
                    navigate(`/contactos/${it}/nubicom`);
                }, 400);
                break;
            case 'cuenta':
                setTimeout(() => {
                navigate(`/contactos/${it}/cuenta`);
                }, 400);
                break;
            case 'servicio':
                setTimeout(() => {
                    navigate(`/servicio/${it}/cuenta`);
                }, 400);
                break;
            case 'preServicio':
                setTimeout(() => {
                    navigate(`/pre-servicio/${it}/nubicom`);
                }, 400);
                break;
            case 'ticket':
                setTimeout(() => {
                    navigate(`/ticket/${it}/nubicom`);
                }, 400);
                break;
            default:
                break;
        }
        if(onCloseModal){
            onCloseModal();
        }
    }
    const onOpenModal = () => {
        showModal(ModalRecordatorioById,{id: item?.id_rec, onGuardar: onGuardar})
    }
    const RedirectItem = ({titulo,tipoId,tipo}) => (
        <Box display='flex' flexDirection='row' alignItems='center' sx={{ml: 1}}>
            <Typography sx={{color: '#000'}}>{titulo}</Typography>
            <Typography
                sx={{ml:0.5, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                variant='h5'
                onClick={()=>onClickNameCliente(tipoId,tipo)}
            >
                {`#${tipoId}`}
            </Typography>
        </Box>
    )
    return (
        <Box
            sx={{
                background: '#fff !important',
                border: 1,
                borderColor: '#ebedf0',
                borderBottomColor: '#c2c7d0',
                p:1,
                '&:hover':{
                    background: '#dfe2e6 !important',
                    cursor: 'pointer'
                }
            }}
        >
            <Grid container>
                <Grid item xs={2} sm={1} display='flex' justifyContent='center' alignItems='center'>
                    <Box
                        sx={{
                            width: 50,
                            height: 50,
                            border:2,
                            borderColor: '#D2D5D9',
                            borderRadius: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src="/img/nubi-cirucular.png" width={45} height={45} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Box display='flex' flexDirection='column'>
                        <Typography sx={{color: '#15294b', fontWeight: 'bold'}}>
                            {`# ${item?.id_rec} - ${item?.titulo_rec}`}
                        </Typography>
                        {!item?.cuentaId_rec && item?.contactoId_rec && (
                            <RedirectItem
                                titulo='Contacto: '
                                tipoId={item?.contactoId_rec}
                                tipo='contacto'
                            />
                        )}
                        {item?.cuentaId_rec && (
                            <RedirectItem
                                titulo='Cuenta: '
                                tipoId={item?.cuentaId_rec}
                                tipo='cuenta'
                            />
                        )}
                        {item?.preServicioId_rec && (
                            <RedirectItem
                                titulo='Pre-Servicio: '
                                tipoId={item?.preServicioId_rec}
                                tipo='preServicio'
                            />
                        )}
                        {item?.servicioId_rec && (
                            <RedirectItem
                                titulo='Servicio: '
                                tipoId={item?.servicioId_rec}
                                tipo='servicio'
                            />
                        )}
                        {item?.ticketId_rec && (
                            <RedirectItem
                                titulo='Ticket: '
                                tipoId={item?.ticketId_rec}
                                tipo='ticket'
                            />
                        )}
                        {item?.consultaId_rec && (
                            <RedirectItem
                                titulo='Consulta: '
                                tipoId={item?.consultaId_rec}
                                tipo='consulta'
                            />
                        )}
                        {item?.createdAt && (
                            <Typography sx={{color: '#15294b',ml:2}}>
                                {FechaFunction.format(new Date(item?.createdAt),'dd/MM/yyyy HH:mm:ss')}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
                    <Typography sx={{color: '#15294b', fontWeight: 'bold'}}>
                        {item?.estado_rec}
                    </Typography>
                </Grid>
                <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
                    <IconButton
                        sx={{
                            width: 35,
                            height: 35,
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover':{
                                background: '#fff'
                            }
                        }}
                        onClick={()=>onOpenModal()}
                    >
                        <QuestionAnswerRoundedIcon
                            color="primary"
                            sx={{
                                fontSize: 20,
                                color: '#0364ff',
                            }}
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CardRecordatorio