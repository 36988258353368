import { useEffect, useState, useContext } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { SocketContext } from "../../context/SocketContext";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";
import UserService from "../../services/personas.service";
import ModalAddAsesores from "../../modals/asesores/ModalAddAsesores";

const UsuariosAsesoresScreen = () => {
    const [items, setItems] = useState([]);
    const { sockets } = useContext( SocketContext );
    const actualizar = async() =>{
        await UserService.getAll({type : 'asesores'}).then((response) =>{
            setItems(response)
        })
    }

    const columns = [
        {
            name: "id_p",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_p",
            label: "Nombre Completo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "dni_p",
            label: "DNI",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email_p",
            label: "Correo Electronico",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "telPersonal_p",
            label: "Telefono",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'activo_p',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value) return <Chip label="Activo" color="success" />
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { tableMeta.rowData[5] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () => {
        actualizar()
    };
    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_p === item)
            showModal(ModalAddAsesores, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalAddAsesores, {onGuardar: onGuardar});
        }
    };
    const onEliminar = (item) => {
        const searchItem = items.find((sItem)=> sItem.id_p === item)
        showModal(ConfirmationDialog, {
            title: `${!searchItem?.activo_p ? 'Activar' : 'Desactivar'} Asesor`,
            message: `¿Esta seguro que desea ${!searchItem?.activo_p ? 'activar' : 'desactivar'} el Asesor?`,
            onConfirm: () => {
                UserService.deleteAsesor(searchItem.id_p).then(() => {
                    onGuardar();
                });
            }
        });
    };
    useEffect(() => {
        actualizar();
        document.title = 'Asesores-Personal';
    }, [])


    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Asesores - Usuarios</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Nuevo Asesor"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default UsuariosAsesoresScreen