import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import usePermisos from '../../hooks/usePermisos';
import SaiSoftlandService from '../../services/saiSoftland.service';

const SelectSaiSoftlandZeta = ({id, value, onChange, isInvalid, isClearable,type,isMulti,excluirID,getAll,addSAI,serviciosAdd,primerBonificacion}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const permisos = usePermisos();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    const actualizar = async() =>{
        const form = {}
        if(getAll){
            form.all = true;
        }else{
            if(!type){
                form.type = 'MAS'
            }else{
                form.type = type
            }
        }
        await SaiSoftlandService.getAll(form).then((response) => {
            if(excluirID){
                setItems(response?.filter((resp)=>resp.idSoftServicio_ss !== excluirID))
            }else{
                setItems(response);
            }
        });
    }
    useEffect(() => {
        actualizar();
    }, [type,excluirID]);

    useEffect(() => {
        if(items?.length > 0){
            let newArr = [];
            const newOptions = items?.map((item) => {
                return {
                    value: item.id_ss,
                    label: `${item.nombre_ss} - ${item?.segmento_ss}`,
                    ...item
                };
            });
            if(addSAI){
                if(permisos['bonificacion_nivel1']){
                    let newFilter = items.filter((se)=> se?.nivelBonificacion_ss === 'bonificacion_nivel1')
                    if(newFilter?.length > 0){
                        newFilter.forEach((nfr)=>{
                            nfr.value = nfr.id_ss;
                            nfr.label = `${nfr.nombre_ss} - ${nfr?.segmento_ss}`;
                        })
                        newArr = newArr.concat(newFilter)
                    }
                }
                if(permisos['bonificacion_nivel2']){
                    let newFilter = items.filter((se)=> se?.nivelBonificacion_ss === 'bonificacion_nivel2')
                    if(newFilter?.length > 0){
                        newFilter.forEach((nfr)=>{
                            nfr.value = nfr.id_ss;
                            nfr.label = `${nfr.nombre_ss} - ${nfr?.segmento_ss}`;
                        })
                        newArr = newArr.concat(newFilter)
                    }
                }
                if(permisos['bonificacion_nivel3']){
                    let newFilter = items.filter((se)=> se?.nivelBonificacion_ss === 'bonificacion_nivel3')
                    if(newFilter?.length > 0){
                        newFilter.forEach((nfr)=>{
                            nfr.value = nfr.id_ss;
                            nfr.label = `${nfr.nombre_ss} - ${nfr?.segmento_ss}`;
                        })
                        newArr = newArr.concat(newFilter)
                    }
                }
                if(primerBonificacion?.id_s || primerBonificacion?.id_ds){
                    const arrPermitidos = newArr.find((nar)=> (nar?.id_ss === primerBonificacion?.id_s || nar?.id_ss === primerBonificacion?.id_ds));
                    if(arrPermitidos?.arrAcum?.length > 0){
                        newArr = newArr.filter((erd)=> arrPermitidos?.arrAcum?.includes(erd?.id_ss))
                    }else{
                        newArr = []
                    }
                    if(serviciosAdd?.length > 0 && newArr?.length > 0){
                        const arrIdOld = []
                        serviciosAdd?.forEach((sad)=>{
                            if(!arrIdOld.includes(sad?.id_s)){
                                arrIdOld.push(sad?.id_s)
                            }
                        })
                        if(arrIdOld?.length > 0 && newArr?.length > 0){
                            newArr = newArr.filter((erd)=> !arrIdOld.includes(erd?.value) )
                        }
                    }
                    setOptions(newArr)
                }
                setOptions(newArr)
            }else{
                setOptions(newOptions);
            }
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    newOptions.find((opt) => opt.value === value)
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>{
                    const item = items.find(it=>it.id_ss === va);
                    if(item){
                        return {
                            value:item.id_ss,
                            label:`${item.nombre_ss} - ${item?.segmento_ss}`,
                            ...item
                        };
                    }
                    return null
                }))
            }
        }
    }, [items,value,permisos]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
            isMulti={isMulti}
        />
    );
};

SelectSaiSoftlandZeta.defaultProps = {
    isClearable: false
};

export default SelectSaiSoftlandZeta;
