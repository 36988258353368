import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Tooltip, tooltipClasses, Typography } from "@mui/material"
import { ButtonBasic, TableBase } from "../../components"
import FechaFunction from "../../function/FechaFunction";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { styled } from '@mui/material/styles';
import ContactosAction from '../../store/actions/contactos';
import { connect } from "react-redux";
import ConsultasService from "../../services/consultas.service";
import ModalNewPreServicioCorporativo from "../../modals/corporativo/ModalNewPreServicioCorporativo";
import { showModal } from "react-redux-modal-provider";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));
const ConsultasClientesCorpoScreen = ({
    iniciarContacto,
    iniciarContrato,
    user
}) => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const actualizar = async () => {
        await ConsultasService.getAll({ corpo: 'ok' }).then((r)=>{
            setItems(r)
        })
    };
    const onClickNuevo = (empresa) => {
        iniciarContacto(null)
        iniciarContrato(null)
        navigate(`/contactos/nuevo${empresa}`);
    };
    const onClickVender = async(idCliente) => {
        const item = items.find((it)=> it.id_con === idCliente);
        if(item?.contactoCuenta_con){
            await ConsultasService.infoClienteConsulta({
                type: 'soft',
                idCliente: item?.contactoCuenta_con
            }).then((resp)=>{
                showModal(ModalNewPreServicioCorporativo,{contacto: resp, onGuardar: actualizar})
            })
        }else if(item?.contactoId_con){
            await ConsultasService.infoClienteConsulta({
                type: 'pot',
                idCliente: item?.contactoId_con
            }).then((potencial)=>{
                showModal(ModalNewPreServicioCorporativo,{contacto: potencial, onGuardar: actualizar})
            })
        }else{
            console.log('No existe')
        }
    }
    const onOpenVer = (idCliente) => {
        const item = items.find((it)=> it.id_con === idCliente)
        if(item?.contactoCuenta_con){
            return navigate(`/contactos/${item?.contactoCuenta_con}/cuenta`,{state:{item:item}});
        }else if(item?.contactoId_con){
            return navigate(`/contactos/${item?.contactoId_con}/nubicom`,{state:{item:item}});
        }
        return ''
    }

    const columns = [
        {
            name: "id_con",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "createdAt",
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return FechaFunction.format(new Date(value),'dd/MM/yyyy HH:mm');
                }
            }
        },
        {
            label: "Nombre",
            name: "nombreContacto_con",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Observaciones",
            name: 'observaciones_con',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            label: 'Estado',
            name: 'estadoConsulta_con',
        },
        {
            label: 'Canal',
            name: 'canal_con',
        },
        {
            label: 'Segmento',
            name: 'segmento_con',
        },
        {
            name: 'contactoCuenta_con',
            label: "Cliente",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value) return <Typography sx={{color: '#000'}}>Potencial</Typography>
                    return <Typography sx={{color: '#000'}}>Cliente</Typography>
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <LightTooltip  title="Perfil del Contacto" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVer(tableMeta.rowData[0])}>
                                    <AssignmentIndIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip  title="Nueva Venta" placement="top">
                                <IconButton color='info' aria-label="edit" onClick={()=>onClickVender(tableMeta.rowData[0])}>
                                    <MedicalServicesIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </>
                )
            }
        }
    ];
    useEffect(() => {
        actualizar()
        document.title = `Consultas Corporativas`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={4}>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Consultas Clientes Corporativos</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    {/* <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30, color: '#fff'}}
                        onClick={()=>onClickNuevo('nubi')}
                        variant='wk'
                        color='nextStep'
                    /> */}
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultasClientesCorpoScreen);