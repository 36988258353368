import { Card, Grid, Typography } from '@mui/material'
import MenuItemsABM from '../menu/MenuItemsABM'

const CardItemServicioAdicional = ({item,onEliminar,openModalEdit}) => {
    return (
        <Card sx={{background: '#fff !important', boxShadow: 3,marginTop: 2, width: {xs: '100%',md:'70%'}}}>
            <Grid container p={3}>
                <Grid item xs={11} md={11} mb={3}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}} variant='h5'>{item?.nombre_s}</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                    <MenuItemsABM dataId={item} onEditar={openModalEdit} onEliminar={onEliminar}  />
                </Grid>
                {/* <Grid item xs={6}>
                    <Typography sx={{color: '#15294b'}}>{`Cantidad: ${item?.cantidad_s || '1.0000'}`}</Typography>
                </Grid> */}
                {/* <Grid item xs={6}>
                    <Typography sx={{color: '#15294b'}}>{`Precio: $${item?.importe_s || '0'} ${item?.moneda_s || 'USD'}`}</Typography>
                </Grid> */}
                <Grid item xs={6}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}}>{`Cantidad: ${(item?.cantidad_s / 1.0000) * 100}%`}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}}>{`Sub-Total: $${((item?.importe_s || 0) * item?.cantidad_s || 1)?.toFixed(6)} ${item?.moneda_s || 'USD'}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{color: '#15294b'}}>{`Observaciones: ${item?.obs_s || '-'}`}</Typography>
                </Grid>
                {(item?.nivelBonificacion === 'bonificacion_nivel2' || item?.nivelBonificacion === 'bonificacion_nivel3') && (
                    <Grid item xs={12}>
                        <Typography sx={{color: '#e01010',fontWeight: 'bold',textAlign:'center'}}>Se requiere autorización.</Typography>
                    </Grid>
                )}
            </Grid>
        </Card>
    )
}

export default CardItemServicioAdicional