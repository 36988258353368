import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Divider, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';
import DialogTitleDefault from './DialogTitleDefault';

function ConfirmationDialog({id, show, hideModal, title, message, onConfirm}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false)
    const onClickConfirm = async() => {
        setIsLoading(true)
        await onConfirm();
        setIsLoading(false)
        handleClose();
    };

    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])

    return (
        <Dialog open={show} disableEscapeKeyDown fullWidth>
            <Box sx={{background: '#fff !important'}}>
                <DialogTitleDefault id="customized-dialog-title" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center',color:'#15294b',fontWeight: 'bold'}} variant='h5'>
                        {title}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Typography sx={{display:'flex', justifyContent:'center',color:'#15294b'}} variant='h6'>{message}</Typography>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Confirmar'
                        variant='wk'
                        color='nextWhite'
                        sx={{border:1, borderColor: '#0364ff',ml:1}}
                        onClick={()=>onClickConfirm()}
                        isLoading={isLoading}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
};

ConfirmationDialog.defaultProps = {
    show: false,
    title: '',
    message: ''
};

export default ConfirmationDialog;
