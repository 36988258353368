import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ButtonBasic, InputDropZone } from '../../components';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ContactosService from '../../services/contactos.service';

function ModalDocumentacionAdj({show, hideModal, archiveSelect,onGuardar,consulta,onSelectViewImg}) {
    const [isLoading, setIsLoading] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const acceptInputFileImg = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png'
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png,application/pdf'
    const handleClose = () => hideModal();
    const [tipoFile, setTipoFile] = useState(acceptInputFileImg)
    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            ...consulta,
            type: 'nubicom'
        }
        switch (archiveSelect?.lugarAdjuntado_facon) {
            case 'DNI Dorso':
                form.dniDorso = archivo;
                break;
                case 'DNI Frente':
                form.dniFrente = archivo;
                break;
            case 'Constancia AFIP':
                form.constanciaAfip = archivo;
                break;
            case 'Contrato Social-Estatuto':
                form.contratoSocualEstatuto = archivo;
                break;
            case 'Acta Actualizada':
                form.desigActaActualizada = archivo;
                break;
            case 'Poder-Apoderado':
                form.poderApoderado = archivo;
                break;
            case 'Dni-Frente-Apoderado':
                form.dniFrenteApoderado = archivo;
                break;
            case 'Dni-Dorso-Apoderado':
                form.dniDorsoApoderado = archivo;
                break;
            default:
                break;
        }
        await ContactosService.registerInfo(form).then(()=>{
            if(onGuardar){
                onGuardar()
            }
            if(onSelectViewImg){
                onSelectViewImg('VACIAR')
            }
            setIsLoading(false)
            handleClose()
        }).catch(()=>{
            setIsLoading(false)
        })
    };
    useEffect(() => {
        switch (archiveSelect?.lugarAdjuntado_facon) {
            case 'DNI Dorso':
            case 'DNI Frente':
                setTipoFile(acceptInputFileImg)
                break;
            case 'Constancia AFIP':
            case 'Contrato Social-Estatuto':
            case 'Acta Actualizada':
            case 'Poder-Apoderado':
            case 'Dni-Frente-Apoderado':
            case 'Dni-Dorso-Apoderado':
                setTipoFile(acceptInputFile)
                break;
            default:
                setTipoFile(acceptInputFileImg)
                break;
        }
    }, [])

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {archiveSelect?. nombreFile_facon ?
                                `Cambiar archivo ${archiveSelect?.lugarAdjuntado_facon || ''}`
                                :`Agregar archivo ${archiveSelect?.lugarAdjuntado_facon || ''}`
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom:2}} />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Typography sx={{color: '#000'}}>Seleccione el archivo</Typography>
                    <Box sx={{width: 172}}>
                        <InputDropZone
                            id="html1"
                            accept={tipoFile}
                            defaultImage={archivo}
                            onChange={setArchivo}
                            setFoto={setArchivo}
                            isDeletedNow={!archivo}
                            backgroundImage="fileUpload"
                        />
                    </Box>
                </Box>
                <Divider color='gray' sx={{marginTop:2}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalDocumentacionAdj.propTypes = {
    show: PropTypes.bool
};

ModalDocumentacionAdj.defaultProps = {
    show: false
};

export default ModalDocumentacionAdj;