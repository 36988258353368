import {combineReducers} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {reducer as modalProvider} from 'react-redux-modal-provider';
import { authReducer } from './reducers/authReducer';
import modalSlice from './slices/modal.slice';
import defaultSlice from './slices/default.slice';
import contactosReducer from './reducers/contactos';
import contactosCorporativoReducer from './reducers/corportativo';
import contactosGobiernoReducer from './reducers/gobierno';
import ticketReducer from './reducers/ticket';


const rootReducer = combineReducers({
    auth: authReducer,
    contactos: contactosReducer,
    corporativo: contactosCorporativoReducer,
    gobierno: contactosGobiernoReducer,
    default: defaultSlice,
    modal: modalSlice,
    ticketrefresh: ticketReducer,
    modalProvider
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}),
});

export default store;
