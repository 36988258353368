import { Box, Card, Grid, Typography } from '@mui/material'
import { ButtonBasic } from '../../../components';
import FechaFunction from '../../../function/FechaFunction';

const TabCuentaTareas = ({tareas}) => {
    return (
        <Grid container spacing={1}>
            { Array.isArray(tareas) && tareas?.length > 0 && (
                tareas.map((item)=>(
                    <Grid item xs={12} md={6} key={`${item?.id}tarea`}>
                        <Card
                            sx={{background:'#fff !important', boxShadow: 3,p:2,marginTop: 2,borderRadius:4 }}
                        >
                            <Box sx={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                                <Typography sx={{fontWeight: 'bold', color: '#000',fontSize: 15}}>{`Tarea #${item?.id || '0'} - ${item?.tipoTarea || ''}`}</Typography>
                            </Box>
                            {item?.cuentaContratoId && (
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 12}} component='div'>{`Servicio #${item?.cuentaContratoId}`}</Typography>
                                </Box>
                            )}
                            {!item?.cuentaContratoId && item?.contratoId && (
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 12}} component='div'>{`Pre-Servicio #${item?.contratoId}`}</Typography>
                                </Box>
                            )}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box fontWeight='normal' display='inline'>{item?.clienteDireccion}</Box></Typography>
                            </Box>
                            {item?.clienteLatitud && item?.clienteLongitud && (
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas:<Box fontWeight='normal' display='inline'>{`${item?.clienteLatitud}, ${item?.clienteLongitud}`}</Box></Typography>
                                </Box>
                            )}
                            {item?.fecha && (
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Fecha:<Box fontWeight='normal' display='inline'>{`${FechaFunction.format(item?.fecha,'dd-MM-yyyy')} ${item?.horaIni || ''}`}</Box></Typography>
                                </Box>
                            )}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Estado:<Box fontWeight='normal' display='inline'>{(item?.estadoTarea ==='REPROGRAMADO' && item?.observaciones === 'CRM') ? 'INSERTAR FECHA' : item?.estadoTarea }</Box></Typography>
                            </Box>
                            <Grid container spacing={2} mt={0.5} sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                                <Grid item xs={12} sm={6} >
                                    <ButtonBasic
                                        textButton="Ir al calendario"
                                        variant="wk"
                                        color='nextWhite'
                                        sx={{width: 120,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                                        onClick={()=>window?.open(`http://calendario.nubicom.com.ar/tareas/${item.id}/editar`)}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                ))
            )}
        </Grid>
    )
}

export default TabCuentaTareas