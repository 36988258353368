import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, InputSearchDireccionEntrega, SelectInsertData } from '../../components';
import PreTicketsService from '../../services/preTickets.service';

const ModalCombinarTicket = ({show, hideModal}) => {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [servicioId, setServicioId] = useState(null);
    const [allTicket, setAllTicket] = useState([]);
    const [allTicketFilter, setAllTicketFilter] = useState([]);
    const [dataTicket1, setDataTicket1] = useState(null);
    const [dataTicket2, setDataTicket2] = useState(null);
    const onSubmit = async() => {
        console.log('')
    }
    console.log(allTicket)
    const onSelectServiceTk = async(formInfo) => {
        setAllTicket([])
        setAllTicketFilter([])
        setDataTicket1(null)
        setDataTicket2(null)
        await PreTicketsService.searchInfoServicio(formInfo).then((response) => {
            const data = response.map((e)=>({label: `#${e?.id_tk} - ${e?.titulo_tk}`, value: e?.id_tk}))
            setAllTicket(data)
        })
    }
    const dataTicket1Select = (idSelect) =>{
        const filter2 = allTicket.filter((dat)=> dat?.value !== idSelect )
        setAllTicketFilter(filter2)
        setDataTicket2(null)
    }
    const cargarDatosTicket = async(idTk, nivel) => {
        console.log({idTk,nivel})
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='lg' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        CAMBINACION DE TICKET
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Servicio</Typography>
                            <InputSearchDireccionEntrega
                                onClick={(e)=>{
                                    console.log(e)
                                    if(e?.contrato){
                                        setServicioId(e?.contrato)
                                        onSelectServiceTk({
                                            numero: e?.contrato,
                                            tipo: "servicio"
                                        })
                                    }else{
                                        setServicioId(e?.id_t)
                                        onSelectServiceTk({
                                            numero: e?.id_t,
                                            tipo: "pre-servicio"
                                        })
                                    }
                                }}
                            />
                        </Grid>
                        {allTicket?.length < 2 && servicioId ? (
                            <Grid item xs={12}>
                                <Typography sx={{color: 'red', textAlign: 'center',fontWeight: 'bold', fontSize: 16}}>
                                    El servicio seleccionado no puede realizar una combinacion de tickets.
                                </Typography>
                            </Grid>
                        ):(
                            <>
                                <Grid item xs={12} md={6}>
                                    <Card sx={{background: '#fff !important', borderRadius: 2, boxShadow: 4, p: 1}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold', textAlign: 'center'}}>TICKET #1</Typography>
                                        <Typography sx={{color: '#000'}}>Seleccione el ticket</Typography>
                                        <SelectInsertData
                                            dataAll={allTicket}
                                            onChange={(e)=>{
                                                dataTicket1Select(e?.value)
                                                cargarDatosTicket(e?.value, 'nivel1')
                                            }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Card sx={{background: '#fff !important', borderRadius: 2, boxShadow: 4, p: 1}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold', textAlign: 'center'}}>TICKET #2</Typography>
                                        <Typography sx={{color: '#000'}}>Seleccione el ticket</Typography>
                                        <SelectInsertData
                                            dataAll={allTicketFilter}
                                            onChange={(e)=>{
                                                cargarDatosTicket(e?.value, 'nivel2')
                                            }}
                                        />
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCombinarTicket