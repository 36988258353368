import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import TratoService from "../../services/tratos.service";
import CardSearchNav from "../card/CardSearchNav";

const TabServicios = ({textSearch,handleClose}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleSearchServicios = async() => {
        if(textSearch?.length >= 2){
            setIsLoading(true);
            await TratoService.getAll({searchNubi: textSearch,typoSearch:'soft'}).then((data)=>{
                setOptions(
                    data.map((item) =>{
                        return {
                            ...item,
                            tituloCard: `${item?.id_t} - ${item?.nombre_t}`,
                            descripCard: item?.softland ? `Servicio en Softland` : '',
                            empresaCard: 'NUBICOM',
                            keyCard: `${item?.id_t}Ticket`
                        }
                    })
                )
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        const delayWrite = setTimeout(() => {
            handleSearchServicios()
        }, 500)
        return () => clearTimeout(delayWrite)
    }, [textSearch])
    return (
        <Grid container>
            { !isLoading ? (
                <>
                    {options && options?.length > 0 ? (
                        options?.map((option)=>(
                            <Grid item xs={12} key={`${option?.keyCard}c`}>
                                <CardSearchNav
                                    item={option}
                                    descripcion={option?.descripCard}
                                    titulo={option?.tituloCard}
                                    key={option?.keyCard}
                                    onCLoseModal={handleClose}
                                    textSearch={textSearch}
                                />
                            </Grid>
                        ))
                    ):(
                        <Grid item xs={12} key='sinServ'>
                            <Box sx={{background: '#ebedf0',p:2,borderRadius:3}}>
                                <Typography sx={{color: '#15294b',textAlign: 'center',marginTop:2,marginBottom:2}} variant='h5'>No se encontraron resultados</Typography>
                            </Box>
                        </Grid>
                    )}
                </>
            ):(
                <Grid item xs={12} key='circletabserv' sx={{display:'flex', justifyContent:'center', alignItems: 'center', minHeight:50}}>
                    <CircularProgress size={40} sx={{color: '#0364ff'}} />
                </Grid>
            )}
        </Grid>
    )
}

export default TabServicios