import { CONTACTOS_CONTRATO_GOBIERNO,
    CONTACTOS_CREAR_CONTRATO_GOBIERNO,
    CONTACTOS_CURRENT_GOBIERNO
} from '../actions/gobierno';

let initialState = {
    esHabilitadoCrearContrato: true,
    current: null,
    contrato: null,
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case CONTACTOS_CURRENT_GOBIERNO:
            return {
                ...state,
                current: payload,
            };
        case CONTACTOS_CONTRATO_GOBIERNO:
            return {
                ...state,
                contrato: payload,
            };
        case CONTACTOS_CREAR_CONTRATO_GOBIERNO:
            return {
                ...state,
                esHabilitadoCrearContrato: payload,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;
