import { Card, IconButton, Grid, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const CardListAlerta = ({item,onDeletedAlerta}) => {

    return (
        <Card sx={{background: '#fff !important',padding: 2,borderRadius:2,boxShadow: 4}} key={item?.id}>
            <Grid container spacing={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Grid item xs={8.5}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',textAlign: 'center', fontSize: 12}}>
                        {item?.tipo_alerec}
                    </Typography>
                    <Typography sx={{color: '#000', fontWeight: 'bold',textAlign: 'center'}}>
                        {item?.tipoIdNombre}
                    </Typography>
                    <Typography sx={{color: '#000'}}>
                        {item?.textAviso_alerec}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <IconButton sx={{boxShadow:1}} onClick={()=>onDeletedAlerta(item?.id)}>
                        <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardListAlerta;