import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { useState, useEffect } from 'react';
import { CardViewTicket } from '../../components';

function ModalTicketsServicio({id, show, hideModal,items,servicioId}) {
    const handleClose = () => hideModal();

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Box sx={{display: 'flex', flexDirection: 'row',justifyContent:'start',alignItems:'center'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h5' component='div'>{`Servicio #${items?.numero || servicioId} - `}<Box fontWeight='normal' display='inline'>{items?.descripcion || items?.nombre}</Box></Typography>
                    </Box>
                </DialogTitleDefault>
                <DialogContent className='side'>
                    {items && items?.length > 0 && (
                        items?.map((tkI)=>(
                            <CardViewTicket item={tkI} key={tkI?.id_t} onCloseModal={handleClose} />
                        ))
                    )}
                </DialogContent>
            </Box>
        </Dialog>
    );
}

ModalTicketsServicio.propTypes = {
    show: PropTypes.bool
};

ModalTicketsServicio.defaultProps = {
    show: false
};

export default ModalTicketsServicio