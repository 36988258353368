import { useState, useEffect } from "react";
import { Box, Card, Grid, Typography } from "@mui/material"
import {ButtonBasic, CardItemServicioAdicional} from "../../../components";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { connect } from "react-redux";
import ContactosAction from '../../../store/actions/contactos';
import { showModal } from "react-redux-modal-provider";
import ModalServiciosSoftland from "../../../modals/softland/ModalServiciosSoftland";
import ConfirmationDialog from "../../../modals/default/ConfirmationDialog";
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

const FormPaso3Screen = ({
  contacto,
  contrato,
  nextStep,
  previousStep,
  iniciarContrato,
}) => {

  const [mbBajada, setmbBajada] = useState(0)
  const [tipoServicio, setTipoServicio] = useState('MAS');

  const [arrSAI, setArrSAI] = useState([{
    "id": uuidv4(),
    "tipo_descripcion": "SERVICOS ASOCIADOS A  LA INSTALACION",
    "nombre": "Bonificacion Adhesión a débito automático VISA - MAS",
    "nombre_s": "Bonificacion Adhesión a débito automático VISA - MAS",
    "importe_s": "-24.800000",
    "moneda_s": "USD",
    "cantidad_s": "0.1000",
    "tipoServicio_s": "SAI   ",
    "id_s": 10,
    "servicioIdSoftland_ds": "1010",
    "anchoBajada_s": "",
    "anchoSubida_s": "",
    "tipoAnchoBajada_s": "",
    "tipoAnchoSubida_s": "",
    "obs_s": "asd",
    "cantMeses_ds": 12,
    "vigencia_ds": "S",
    "nivelBonificacion": "bonificacion_nivel1"
  }])
  const openModalCreated = () =>{
    showModal(ModalServiciosSoftland,{serviciosAdd:arrSAI,setAdd: setArrSAI,precioPlan: contrato?.precio,tipoServicio: tipoServicio});
  }
  const openModalEdit = (item) =>{
      showModal(ModalServiciosSoftland,{add:item, serviciosAdd:arrSAI, setAdd: setArrSAI, precioPlan: contrato?.precio, tipoServicio: tipoServicio});
  }
  const onEliminar = (itemSer) => {
    showModal(ConfirmationDialog, {
        title: 'Eliminar el Servicio Seleccionado',
        message: '¿Esta seguro que desea eliminar el Servicio Seleccionado?',
        onConfirm: () => {
            const filtro = arrSAI.filter((serv)=> serv.id !== itemSer.id)
            setArrSAI(filtro)
        }
    });
  };

  useEffect(() => {
    if(contrato?.servicios?.length > 0){
      const splite = contrato?.servicios[0]?.anchoBajada_s?.split('.')
      if(splite?.length > 0){
          setmbBajada(splite[0])
      }
    }
    if(contrato?.precio && arrSAI?.length > 0){
      const copiaArr = arrSAI;
      copiaArr.forEach((e)=>{
        e.importe_s = ((contrato?.precio || 0) * -1).toFixed(6)
      })
      setArrSAI(copiaArr)
    }
}, [contrato])
  const onSubmit = () => {
    let form = contrato?.servicios;
    let requiereAutorizacion = 'NO';
    if(arrSAI?.length > 0){
      const arrPrincipal = [contrato?.servicios[0]]
      form = arrPrincipal?.concat(arrSAI);
      arrSAI?.forEach((dat)=>{
        if(dat?.nivelBonificacion === 'bonificacion_nivel2' || dat?.nivelBonificacion === 'bonificacion_nivel3'){
          requiereAutorizacion = 'SI'
        }
      })
    }else{
      form = contrato?.servicios?.filter((e)=> !e?.id) || [];
    }

    iniciarContrato({
        ...contrato,
        servicios: form,
        requiereAutorizacion: requiereAutorizacion
    })
    nextStep();
  };

  useEffect(() => {
    if(contacto?.categoria_cxe){
        let categoria = '';
        switch (contacto?.categoria_cxe) {
            case 'PYM':
            case 'PYMIN':
                categoria= 'PYM'
                break;
            case 'MAS':
            case 'MASIN':
                categoria= 'MAS'
                break;
            default:
                break;
        }
        setTipoServicio(categoria)
    }
  }, [contacto])

  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2}>
        <Grid xs={12} lg={4.9}>
          <Card sx={{background: '#fff !important', cursor: 'pointer'}}>
            <Grid container>
              <Grid item xs={12} p={1}>
                <Typography sx={{color: '#000', fontWeight: 'bold'}}>Plan seleccionado</Typography>
              </Grid>
              {contrato?.servicios?.length > 0 && (
                <Grid item xs={12} p={2}>
                  <Card sx={{background: '#0364ff !important',p:2,borderRadius:2}}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography variant="h6" mb={1}>{contrato?.tipoConSoft_t}</Typography>
                        <Typography variant="h4" fontWeight='bold'>{contrato?.servicios[0]?.nombre_s}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" fontWeight='bold'>{`${mbBajada} Mb`}</Typography>
                        <Box
                              sx={{
                                  border: 1,
                                  borderColor: '#fff',
                                  borderRadius:50,
                                  minWidth:95,
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  minHeight:30,
                                  display:'flex',
                                  justifyContent:'center',
                                  alignItems:'center',
                                  mt:1,
                                  fontSize: 12
                              }}
                          >
                              {`$ ${contrato?.servicios[0]?.importe_s} /mes`}
                          </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} lg={6.9} ml={1}>
          <Card sx={{background: '#fff !important'}}>
            <Box p={2}>
              <Box sx={{display: 'flex', justifyContent:'space-between'}}>
                <Typography sx={{color: '#15294b', fontWeight: 'bold'}} variant='h5'>Listado de servicios agregados al plan</Typography>
                <ButtonBasic
                  textButton="Agregar items"
                  iconStart={<AddIcon sx={{color: '#0364ff'}} />}
                  variant="wk"
                  color='nextWhite'
                  sx={{fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                  onClick={()=>openModalCreated()}
                />
              </Box>
              {arrSAI?.length > 0 ?(
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  {arrSAI.map((sai)=>(
                    <CardItemServicioAdicional
                      key={sai?.id}
                      item={sai}
                      precioPlan={contrato?.precio}
                      onEliminar={onEliminar}
                      openModalEdit={openModalEdit}
                    />
                  ))}
                </Box>
              ):(
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mb={15}>
                  <img src="/img/carritoCompra.png" style={{width: 220, height: 220}} />
                  <Typography sx={{color: '#6b788e', fontWeight: 'bold',marginTop: 2}} variant='h5'>Aún no se agrego ningun servicio adicional</Typography>
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15} marginTop={2}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          color='prevStep'
        />
        <ButtonBasic
          textButton='Continuar'
          iconEnd={<ArrowForwardIcon />}
          onClick={()=>onSubmit()}
          variant='wk'
          color='nextStep'
          isDisabled={contrato?.servicios?.length <= 0}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso3Screen);