import { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, ComentarioRecordatorio, InputEditQuillSinEtiqueta, InputReadQuill, SelectEstadoRecordatorio } from '../../components';
import RecordatorioService from '../../services/recordatorio.service';
import FechaFunction from '../../function/FechaFunction';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from '../../store';

function ModalRecordatorioById({
    show,
    hideModal,
    id,
    onGuardar
}) {
    const handleClose = () => hideModal();
    const [recordatorio, setRecordatorio] = useState(null);
    const [cuerpo, setCuerpo] = useState('');
    const user = useSelector((state) => state.auth.currentUser);
    const navigate = useNavigate();

    const onClickNameCliente = (it,tipo) => {
        switch (tipo) {
            case 'contacto':
                setTimeout(() => {
                    navigate(`/contactos/${it}/nubicom`);
                }, 400);
                break;
            case 'cuenta':
                setTimeout(() => {
                navigate(`/contactos/${it}/cuenta`);
                }, 400);
                break;
            case 'servicio':
                setTimeout(() => {
                    navigate(`/servicio/${it}/cuenta`);
                }, 400);
                break;
            case 'preServicio':
                setTimeout(() => {
                    navigate(`/pre-servicio/${it}/nubicom`);
                }, 400);
                break;
            case 'ticket':
                setTimeout(() => {
                    navigate(`/ticket/${it}/nubicom`);
                }, 400);
                break;
            default:
                break;
        }
        const {stack} = store.getState().modalProvider;
        if(stack){
            stack.forEach(({id})=>{
                store.dispatch({
                    type: '@react-redux-modal-provider.hide',
                    id,
                })
            })
        }
    }
    const actualizar = async() => {
        await RecordatorioService.getById(id).then((resp)=>{
            setRecordatorio(resp)
        })
    }
    const onSubmit = async() =>{
        const form = {
            recordatorioId_comrec: id,
            cuerpo_comrec: cuerpo,
            isDisabled_rec: false
        }
        await RecordatorioService.registerComentario(form)
        .then(()=>{
            actualizar();
            if(onGuardar){
                onGuardar()
            }
        })
        .catch(()=>{
            actualizar();
        })
    }
    const actualizarEstado = async(estadoNew) => {
        await RecordatorioService.updateEstado({
            id: id,
            estado_rec: estadoNew
        }).then(()=>{
            actualizar();
            if(onGuardar){
                onGuardar()
            }
        })
        .catch(()=>{
            actualizar();
        })
    }
    useEffect(() => {
        actualizar()
    }, [])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='lg' fullWidth className='side'>
            <Box sx={{background: '#fff',minHeight: '40vh'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Recordatorio #${id}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side' sx={{background: '#fff'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <Card
                                sx={{
                                    background: '#d1d1d1 !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1.5,
                                    borderRadius: 1,
                                    boxShadow: 3,
                                    flexDirection: 'column'
                                }}
                            >
                                <Card
                                    sx={{
                                        background: '#fff !important',
                                        p: 1.5,
                                        borderRadius: 1,
                                        boxShadow: 3,
                                        width: '100%',
                                        mb: 1
                                    }}
                                >
                                    <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                        Recordatorio
                                    </Typography>
                                    <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                        Estado:
                                    </Typography>
                                    <SelectEstadoRecordatorio
                                        value={recordatorio?.estado_rec}
                                        onChange={(e)=>{
                                            if(e.value){
                                                actualizarEstado(e.value)
                                            }
                                        }}
                                    />
                                    <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                        Creado Por:
                                    </Typography>
                                    <Typography sx={{color: '#000', fontSize: 14}}>
                                        {recordatorio?.autor?.nombre_p || '-'}
                                    </Typography>
                                    {recordatorio?.fechaProgramado_rec && (
                                        <>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                Fecha Programada:
                                            </Typography>
                                            <Typography sx={{color: '#000'}}>
                                                {FechaFunction.format(new Date(recordatorio?.fechaProgramado_rec),'dd/MM/yyyy HH:mm:ss')}
                                            </Typography>
                                        </>
                                    )}
                                </Card>
                                {(!recordatorio?.cuenta?.cuenta && recordatorio?.contacto?.id_c) && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                                Contacto
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography
                                                sx={{mt: {xs: 0, md: -1}, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                                variant='h5'
                                                onClick={()=>onClickNameCliente(recordatorio?.contacto?.id_c,'contacto')}
                                            >
                                                #{recordatorio?.contacto?.id_c}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                {recordatorio?.contacto?.nombreCompleto_c || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.contacto?.documento_c || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.contacto?.email_c || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.contacto?.celular_c || '-'}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.cuenta?.cuenta && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                                Cuenta
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography
                                                sx={{mt: {xs: 0, md: -1}, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                                variant='h5'
                                                onClick={()=>onClickNameCliente(recordatorio?.cuenta?.cuenta,'cuenta')}
                                            >
                                                #{recordatorio?.cuenta?.cuenta}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                {recordatorio?.cuenta?.cliente || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.cuenta?.dni || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.cuenta?.email || '-'}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.cuenta?.telefono || '-'}
                                            </Typography>
                                            {recordatorio?.cuenta?.telefono2 && (
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {recordatorio?.cuenta?.telefono2 || '-'}
                                                </Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.preServicio?.id_t && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 15}}>
                                                Pre-Servicio
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography
                                                sx={{mt: {xs: 0, md: -1}, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                                variant='h5'
                                                onClick={()=>onClickNameCliente(recordatorio?.preServicio?.id_t,'preServicio')}
                                            >
                                                #{recordatorio?.preServicio?.id_t}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                {recordatorio?.preServicio?.nombre_t}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.preServicio?.estadoServicio_t}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.servicioId?.contrato && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 15}}>
                                                Servicio
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography
                                                sx={{mt: {xs: 0, md: -1}, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                                variant='h5'
                                                onClick={()=>onClickNameCliente(recordatorio?.servicioId?.contrato,'servicio')}
                                            >
                                                #{recordatorio?.servicioId?.contrato}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                {recordatorio?.servicioId?.plan}
                                            </Typography>
                                            {recordatorio?.servicioId?.tipo_contrato && (
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {recordatorio?.servicioId?.tipo_contrato}
                                                </Typography>
                                            )}
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.servicioId?.direccion_servicio}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.servicioId?.localidad}
                                            </Typography>
                                            {recordatorio?.servicioId?.vip && (
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {recordatorio?.servicioId?.vip}
                                                </Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.ticket?.id_tk && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                                Ticket
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography
                                                sx={{mt: {xs: 0, md: -1}, color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                                                variant='h5'
                                                onClick={()=>onClickNameCliente(recordatorio?.ticket?.id_tk,'ticket')}
                                            >
                                                #{recordatorio?.ticket?.id_tk}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12, fontWeight: 'bold'}}>
                                                {recordatorio?.ticket?.titulo_tk}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {`${recordatorio?.ticket?.estado_tk} - ${recordatorio?.ticket?.estadoVencimiento_tk}`}
                                            </Typography>
                                            {recordatorio?.ticket?.cerrado_tk && (
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {FechaFunction.format(new Date(recordatorio.ticket?.cerrado_tk),'dd/MM/yyyy HH:mm:ss')}
                                                </Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.consulta?.id_con && (
                                    <Accordion sx={{width: '100%'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                            sx={{background: '#fff'}}
                                        >
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                                Consulta
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{background: '#fff'}}>
                                            <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                {recordatorio?.consulta?.observaciones_con}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.consulta?.planConsulta_con}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.consulta?.direccionConsulta_con}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.consulta?.coordenadasConsulta_con}
                                            </Typography>
                                            <Typography sx={{color: '#000', fontSize: 12}}>
                                                {recordatorio?.consulta?.estadoCOnsulta_con}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {recordatorio?.alertas?.length > 0 && (
                                    <Accordion sx={{width: '100%'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{color: '#000'}} />}
                                        sx={{background: '#fff'}}
                                    >
                                        <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 16}}>
                                            Alertas
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{background: '#fff', mt: -1.5}}>
                                        { recordatorio?.alertas.map((alertRec)=>(
                                            <Card sx={{background: '#e6f0ff !important', p: 1, mb: 1, borderRadius: 2, boxShadow: 3}}>
                                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                    {`${alertRec?.tipo_alerec}:`}
                                                </Typography>
                                                <Typography sx={{color: '#000', fontSize: 14}}>
                                                    {alertRec?.asignado?.nombre_p || alertRec?.asignado?.nombre_g}
                                                </Typography>
                                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                    Tiempo de aviso:
                                                </Typography>
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {alertRec?.textAviso_alerec}
                                                </Typography>
                                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14}}>
                                                    Hora de aviso:
                                                </Typography>
                                                <Typography sx={{color: '#000', fontSize: 12}}>
                                                    {moment(alertRec?.fechaAlerta_alerec).add(3, 'h').format('DD/MM/YYYY HH:mm:ss')}
                                                </Typography>
                                            </Card>
                                        ))}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Card sx={{ background: '#fff !important',p:1,borderRadius:1,boxShadow:3}}>
                                <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold', fontSize: 16}}>
                                    {recordatorio?.titulo_rec || ''}
                                </Typography>
                                {recordatorio?.observaciones_rec && (
                                    <>
                                        <Divider sx={{background: 'gray !important',marginTop:0}} />
                                        <Box sx={{background: '#fff',p:2}}>
                                            <Typography sx={{color: '#15294b',fontWeight: 'bold'}} variant='h5'>
                                                Descripción
                                            </Typography>
                                            <InputReadQuill
                                                id="cuerpo_rec"
                                                template={recordatorio?.observaciones_rec}
                                            />
                                        </Box>
                                    </>
                                )}
                                <Typography sx={{color:'#15294b',opacity: 0.9, textAlign: 'end'}}>
                                    {recordatorio?.createdAt && (
                                        `${FechaFunction.format(new Date(recordatorio?.createdAt),'dd/MM/yyyy HH:mm:ss')}`
                                    )}
                                </Typography>
                                <Divider sx={{background: 'gray !important',marginTop: 1, marginBottom: 1}} />
                                <Box sx={{background: '#fafbfb',p:2}}>
                                    <Typography sx={{color: '#15294b',fontWeight: 'bold',marginTop:0}} variant='h5'>
                                        Comentarios
                                    </Typography>
                                    {recordatorio?.comentarios?.length > 0 ? (
                                        recordatorio?.comentarios.map((recCom)=>(
                                            <ComentarioRecordatorio
                                                user={user}
                                                item={recCom}
                                            />
                                        ))
                                    ):(
                                        <Typography sx={{color: '#15294b',textAlign: 'center',marginTop:2,marginBottom:2}} variant='h5'>
                                            Aún no hay comentarios
                                        </Typography>
                                    )}
                                </Box>
                                {['A Realizar','En Progreso','Cancelada'].includes(recordatorio?.estado_rec) && (
                                    <>
                                        <Divider sx={{background: 'gray !important',marginTop:1}} />
                                        <Typography sx={{color: '#15294b',fontWeight: 'bold',marginBottom:2}} variant='h5'>
                                            Dejar un comentario
                                        </Typography>
                                        <InputEditQuillSinEtiqueta
                                            id="comentariorec1"
                                            template={cuerpo}
                                            etiqueta
                                            onChangeText={(html)=>{
                                                setCuerpo(html)
                                            }}
                                        />
                                    </>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center',alignItems: 'end',background: '#fff'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2, minWidth: 120}}
                    />
                    {['A Realizar','En Progreso','Cancelada'].includes(recordatorio?.estado_rec) && (
                        <ButtonBasic
                            textButton='Comentar'
                            variant='wk'
                            color='nextStep'
                            onClick={()=>onSubmit()}
                            sx={{mb:2, minWidth: 120}}
                            isDisabled={cuerpo?.length < 10}
                        />
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalRecordatorioById;