import {useMemo, useState} from 'react';
import {ButtonBasic, SelectLugaresCalendar } from '../../components/index';
import ConsultasService from '../../services/consultas.service';
import { Box, Divider, Grid, Typography, IconButton, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import AuxiliarFunction from '../../function/AuxiliarFunction';

const ModalConsultaCamDomicilio = ({
    show,
    hideModal,
    item,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [localidadId, setLocalidadId] = useState(null);
    const [domicilioNuevo, setDomicilioNuevo] = useState('');
    const handleClose = () => hideModal();
    const [errCoord, setErrCoord] = useState(false);
    const [coordenadas, setCoordenadas] = useState('')

    const onSubmit = async() => {
        setIsLoading(true);
        const form = {
            ...item,
            direccionConsulta_con: domicilioNuevo,
            localidadId_con: localidadId
        }
        const quitarEspacios = coordenadas.replace(/ /g, "")
        const separador = quitarEspacios.split(',')
        if(separador.length === 2){
            const validCoord = `${separador[0]}, ${separador[1]}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                form.coordenadasConsulta_con = validCoord;
                await ConsultasService.registerConsultaCoberturaCamDomicilio(form).then(()=>{
                    setErrCoord(false);
                    setIsLoading(false);
                    handleClose();
                }).catch(()=>{
                    setIsLoading(false)
                })
            }else{
                setIsLoading(false)
                setErrCoord(true)
            }
        }else{
            setIsLoading(false)
            setErrCoord(true)
        }
    };

    const onClosed = () =>handleClose();
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                }
            },
            color: 'gray',
        }
    }), [])
    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {`Cambio de Domicilio Servicio #${item?.nroServicio}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 1}} />
                    <Grid contariner p={2}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Nuevo Domicilio</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInput}
                                value={domicilioNuevo}
                                onChange={(e)=>setDomicilioNuevo(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Localidad</Typography>
                            <SelectLugaresCalendar
                                value={localidadId}
                                isInvalid={!localidadId}
                                onChange={(e)=>setLocalidadId(e?.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Coordenadas</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={coordenadas}
                                onChange={(e)=>setCoordenadas(e.target.value)}
                                sx={styleInput}
                            />
                            {errCoord && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>No es una coordenada valida</Typography>
                            )}
                        </Grid>
                    </Grid>
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1,marginBottom:0.5}}>
                    <ButtonBasic
                        onClick={()=>onClosed()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        sx={{marginLeft: 1}}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!localidadId}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalConsultaCamDomicilio;