import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ButtonBasic, SelectEstadoBaja, SelectLugaresCalendar } from '../../components';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TratoService from '../../services/tratos.service';

function ModalEstadoSoftland({show, hideModal, extension,servicio,onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [estadoSelect, setEstadoSelect] = useState(null);
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            tipoContrato: servicio.tipo,
            contratoId: servicio.numero,
            nroExt: extension,
            estado: estadoSelect,
        }
        await TratoService.changeEstadoSoftland(form).then(()=>{
            setIsLoading(false)
            handleClose()
            onGuardar()
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            Cambiar estado del servicio
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom:2}} />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Box sx={{width: 300}}>
                        <Typography sx={{color: '#000'}}>Estado:</Typography>
                        <SelectEstadoBaja
                            value={estadoSelect}
                            onChange={(e)=>setEstadoSelect(e.value)}
                        />
                    </Box>
                </Box>
                <Divider color='gray' sx={{marginTop:2}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        isDisabled={!estadoSelect}
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalEstadoSoftland.propTypes = {
    show: PropTypes.bool
};

ModalEstadoSoftland.defaultProps = {
    show: false
};

export default ModalEstadoSoftland;