import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Tooltip, tooltipClasses, Typography } from "@mui/material"
import { ButtonBasic, TableBase } from "../../components"
import ContactosService from "../../services/contactos.service";
import FechaFunction from "../../function/FechaFunction";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { styled } from '@mui/material/styles';
import { showModal } from "react-redux-modal-provider";
import ModalNewServicioSC from "../../modals/gobierno/ModalNewServicioSC";
import usePermisos from "../../hooks/usePermisos";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));
const PotencialesGobiernoScreen = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const permisos = usePermisos()

    const actualizar = async () => {
        await ContactosService.getAll({ type: 'gobierno' }).then((r)=>{
            setItems(r)
        })
    };
    const onClickNuevo = () => {
        navigate('/contactos/nuevogobierno');
    };
    const onClickSCNew = () => {
        showModal(ModalNewServicioSC)
    }
    const onOpenVer = (idCliente) => {
        const item = items.find((it)=> it.id_c === idCliente)
        if(item?.cuentaNubi_c){
            return navigate(`/contactos/${item?.cuentaNubi_c}/cuenta`,{state:{item:item}});
        }
        if(item?.nubicom){
            navigate(`/contactos/${item.id_c}/nubicom`,{state:{item:item}});
        }else if(item?.strong){
            navigate(`/contactos/${item.id_c}/strong`,{state:{item:item}});
        }else if(item?.ids){
            navigate(`/contactos/${item.id_c}/ids`,{state:{item:item}});
        }
        return ''
    }
    const columns = [
        {
            name: "id_c",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "createdAt",
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return FechaFunction.format(value,'dd/MM/yyyy');
                }
            }
        },
        {
            label: "Nombre",
            name: "nombreCompleto_c",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'tipoDoc_c',
            label: "Tipo Documento",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value === '96'){
                        return 'DNI';
                    }
                    if(value === '80'){
                        return 'CUIT';
                    }
                    return 'Sin Dato'
                }
            }
        },
        {
            label: 'DNI',
            name: 'documento_c',
        }
        ,{
            label: 'Correo',
            name: 'email_c',
        }
        ,{
            label: 'Categoria',
            name: 'categoria_cxe',
        }
        ,{
            label: 'Estado',
            name: 'estadoCliente_c',
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <LightTooltip  title="Perfil del Contacto" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVer(tableMeta.rowData[0])}>
                                    <AssignmentIndIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </>
                )
            }
        }
    ];
    useEffect(() => {
        actualizar();
        document.title = `Potenciales Gobierno`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={4}>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography className="text-gray-900 font-bold" variant="h3" mb={2}>Potenciales Clientes Gobierno/Eventuales</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} sx={{display: 'flex', justifyContent: 'end'}}>
                    {permisos['trato.create.sc'] && (
                        <ButtonBasic
                            textButton="NUEVO SC"
                            sx={{width: 200, height: 30, color: '#fff',backgroundColor: '#01704a', '&:hover':{backgroundColor: '#02b879'}}}
                            onClick={()=>onClickSCNew()}
                            variant='wk'
                            color='nextStep'
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={3} lg={3} sx={{display: 'flex', justifyContent: 'center'}}>
                    {permisos['contactos.gobierno.created'] && (
                        <ButtonBasic
                            textButton="NUEVO"
                            sx={{width: 200, height: 30, color: '#fff'}}
                            onClick={()=>onClickNuevo('nubi')}
                            variant='wk'
                            color='nextStep'
                        />
                    )}
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PotencialesGobiernoScreen