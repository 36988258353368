import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputEditQuill, SelectInsertEmail, SelectPlantillaEmail } from '../../components';
import ComentarioTicketService from '../../services/comentarioTicket.service';
import { useSelector } from 'react-redux';

const ModalSendEmailComentario = ({show, hideModal, onGuardar,ticketId, email,nombreCliente}) => {
    const user = useSelector((state) => state.auth.currentUser);
    const [isLoading, setIsLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const [asunto, setAsunto] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailDestino, setEmailDestino] = useState([]);
    const [cuerpo, setCuerpo] = useState('');
    const handleClose = () => hideModal();
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            asunto: asunto,
            nombreCliente: nombreCliente,
            cuerpo_com: cuerpo,
            ticketId_com: ticketId
        }
        if(emailDestino?.length > 0){
            let stringDestino = ''
            emailDestino?.forEach((emailDes,index)=>{
                if(validateEmail(emailDes?.label)){
                    if(index === 0){
                        stringDestino = `${emailDes?.label}`
                    }else{
                        stringDestino = `${stringDestino}, ${emailDes?.label}`
                    }
                }
            })
            form.destino = stringDestino
        }
        if(emails && emails?.length > 0){
            form.ccArr = emails;
        }
        await ComentarioTicketService.registerEmail(form).then(()=>{
            onGuardar();
            handleClose();
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    /* HABILITAR ESTA PARTE EN PRODUCCION */
    useEffect(() => {
        if(email && validateEmail(email?.replace(/[\r\n]+/g, '')?.trim())){
            setEmailDestino([{
                value: email?.replace(/[\r\n]+/g, '')?.trim(),
                label: email?.replace(/[\r\n]+/g, '')?.trim()
            }])
        }
    }, [])
    /* SACAR ESTA PARTE */
    /* useEffect(() => {
        if(user?.email_p){
            setEmailDestino([{
                value: user?.email_p,
                label: user?.email_p
            }])
        }
    }, [user]) */
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Enviar correo electronico al cliente
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center',flexDirection: 'column'}}>
                        <Typography sx={{color: '#000'}} variant='h6'>Template</Typography>
                        <SelectPlantillaEmail
                            value={template}
                            onChange={(e)=>{
                                setEmails(e.ccArr_pmail || [])
                                setAsunto(e.asunto_pmail)
                                setCuerpo(e.cuerpo_pmail)
                                setTemplate(e)
                            }}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>Destino</Typography>
                        <SelectInsertEmail
                            id="selectEmails"
                            value={emailDestino}
                            onChange={setEmailDestino}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>CC</Typography>
                        <SelectInsertEmail
                            id="selectEmails"
                            value={emails}
                            onChange={setEmails}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>Asunto</Typography>
                        <TextField
                            id='cuerpoEmail'
                            key='cuerpoEmail'
                            autoComplete='off'
                            focused
                            InputProps={{ style:{fontSize:14,color: '#000'} }}
                            value={asunto}
                            onChange={(e)=>setAsunto(e.target.value)}
                            sx={{
                                '& fieldset': {
                                    borderRadius: 1
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'gray',
                                    },
                                }
                            }}
                        />
                        <Typography sx={{color: '#000'}} variant='h6'>Cuerpo</Typography>
                        <InputEditQuill
                            id="Comentario"
                            template={ cuerpo ||''}
                            onChangeText={(html)=>{
                                setCuerpo(html)
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={!template || !emailDestino || isLoading}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalSendEmailComentario