import {useState, useEffect} from 'react';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { showModal } from 'react-redux-modal-provider';
import ModalBuscadorV2 from '../../modals/default/ModalBuscadorV2';

const InputSearchNavV2 = ({value,disabled,onChange,isClearable,errorText, ...props}) => {

    const [textSearch, setTextSearch] = useState('');

    useEffect(() => {
        if(textSearch?.length > 2){
            showModal(ModalBuscadorV2,{textSearch: textSearch})
        }
    }, [textSearch])

    return (
        <Box sx={{width:350,display: 'flex'}}>
            <TextField
                focused
                onChange={(e)=>setTextSearch(e.target.value)}
                value={textSearch}
                placeholder='Buscar...'
                fullWidth
                autoComplete='off'
                InputProps={{
                    style:{ fontWeight: 'normal',fontSize:15,color: '#15294b' },
                    type: 'search',
                    startAdornment:(
                        <SearchIcon sx={{color: '#15294b',opacity: 0.9,marginTop:-0.7}} size={20} />
                    )
                }}
                sx={{
                    '& fieldset': {
                        borderRadius: 3
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: '#b3b9c4',
                            height: 50,
                        },
                    },
                    '& input':{
                        marginTop: -0.5,
                        marginLeft:1
                    }
                }}
            />
        </Box>
    );
};

InputSearchNavV2.defaultProps = {
    isClearable: false,
    value: null
};

export default InputSearchNavV2;