import { Avatar, Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useEffect, useContext, useState } from 'react';
import {connect} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonBasic, CardUploadFile, ComentarioTicketCard, InputDropZoneComment, InputEditQuill, InputReadQuill, ListContratoSoft, ListInfoCam, ListTarea, TextEstadoTicket, TextEstadogTicketID, TextInfoTicket } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import FechaFunction from '../../function/FechaFunction';
import usePermisos from '../../hooks/usePermisos';
import ApiSoftland from '../../services/apis.softland.service'
import CalendarioV2Service from '../../services/calendarioV2.service';
import ComentarioTicketService from '../../services/comentarioTicket.service';
import TicketService from '../../services/tickets.service'
import { showModal } from 'react-redux-modal-provider';
import ModalSendEmailComentario from '../../modals/plantillas/ModalSendEmailComentario';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import Compressor from 'compressorjs';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ModalCierreTicketNubicom from '../../modals/cierreTicket/ModalCierreTicketNubicom';
import ModalTicketIdLogs from '../../modals/ticket/ModalTicketIdLogs';
import ModalAsignarTicket from '../../modals/ticket/ModalAsignarTicket';
import ModalCrearTarea from '../../modals/tarea/ModalCrearTarea';
import ModalConfirmInstalacion from '../../modals/ticket/ModalConfirmInstalacion';
import ModalEditTicketNubicom from '../../modals/ticket/ModalEditTicketNubicom';
import ModalReaperturaTicket from '../../modals/ticket/ModalReaperturaTicket';
import TratoService from '../../services/tratos.service';
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player'
import { saveAs } from 'file-saver';
import ModalTicketNodoPanel from '../../modals/mikrotikV2/ModalTicketNodoPanel';
import MikrotikV2Service from '../../services/mikrotikV2.service';
import ModalTicketDetalleAdicional from '../../modals/ticket/ModalTicketDetalleAdicional';
import ModalCancelarCambioTitular from '../../modals/contacto/ModalCancelarCambioTitular';
import ModalAdjContratoConstancia from '../../modals/ticket/ModalAdjContratoConstancia';
import ModalConfirmarPrefactibilidad from '../../modals/tratos/ModalConfirmarPrefactibilidad';
import ModalConfirmarItemsPrefa from '../../modals/tratos/ModalConfirmarItemsPrefa';

const TicketGetByIdNubiScreen = ({
  user,
  ticketrefresh
}) => {
  const {id} = useParams();
  const [trato, setTrato] = useState();
  const [tareas, setTareas] = useState(null);
  const [contratoSoft, setContratoSoft] = useState(null);
  const [linkContratoSoft, setLinkContratoSoft] = useState(null);
  const [ticket, setTicket] = useState();
  const [comentario, setComentario] = useState('');
  const permisos = usePermisos();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [tareasCerradas, setTareasCerradas] = useState(false);
  const [nuevoTitular, setNuevoTitular] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesCompress, setFilesCompress] = useState([]);
  const navigate = useNavigate();
  const { sockets } = useContext( SocketContext );
  const token = localStorage.getItem('token');
  const extensionPermitida = ['png','jpg','jpeg'];
  const acceptInputFile = ''
  const urlNubi = 'https://zeta.strongsystems.com.ar:8000/api/img?url=tickets/'
  const actualizar = async() =>{
    setIsLoadingPage(true)
    await TicketService.getById(id,{type: 'nubicom'}).then(async(resp)=>{
        setTicket(resp);
        setIsLoadingPage(false)
        if(resp.tratos.length > 0){
            setTrato(resp.tratos[0])
        }
        if(resp?.tratrosSoft?.length > 0){
            await ApiSoftland.getContratos(resp?.tratrosSoft).then((re)=>{
              setContratoSoft(re)
            })
        }
    })
    .catch((er)=>{
        setIsLoadingPage(false);
        navigate('/tickets');
    })
  }
  const traerTareas = async() =>{
    await CalendarioV2Service.tareasTicketCalendar(id).then((tar)=>{
      const permitirTarea = [4,5,6]
      if(tar?.length > 0){
          if(permitirTarea.includes(tar[0].estadoTareaId_t)){
            setTareasCerradas(true)
          }else{
            setTareasCerradas(false)
          }
      }else{
        setTareasCerradas(true)
      }
      setTareas(tar)
    })
  }
  const onSubmit = () =>{
    const form={
        ticketId_com : id,
        cuerpo_com: comentario,
        propertys: 'nubicom'
    }
    if(comentario && comentario.length > 16){
        setIsLoading(true);
        if(filesCompress?.length > 0){
          form.filesCompress = filesCompress;
        }
        if(permisos['comentarioNubi.register']){
            form.isVisible_com= false;
            ComentarioTicketService.register(form).then(()=>{
                sockets?.emit( 'ticketNubiRefresh', `nubicom${id}` );
                setFiles([])
                setFilesCompress([])
                setComentario('')
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    }else{
        toast.error('El comentario debe tener al menos 10 caractares.', {
            position: "top-right",
            autoClose: 2500,
            closeOnClick: true,
            pauseOnHover: false,
        });
    }
  }
  const onOpenSendEmail = () =>{
    showModal(ModalSendEmailComentario,{onGuardar: actualizar,ticketId: id,email: linkContratoSoft?.email || trato?.contactoEmail_t, nombreCliente: linkContratoSoft?.nombre || trato?.nombre_t })
  }
  const cierreTicketOK = () =>{
    sockets?.emit( 'ticketNubiRefresh', `nubicom${id}` );
    setIsLoadingPage(true);
  }
  const cerrarTicket = () =>{
    showModal(ModalCierreTicketNubicom, { ticket: ticket, confirm: cierreTicketOK, setIsLoadingPage: setIsLoadingPage, trato: trato });
  }

  useEffect(() => {
    localStorage.setItem('ticketId', `nubicom${id}`);
    setContratoSoft(null)
    setLinkContratoSoft(null)
    setTrato(null)
    actualizar();
    document.title = `Ticket #${id}`;
    /* setTimeout(() => { REDIRIGIR A UN COMENTARIO EJEMPLO EL id_com: 17
        window.location.href = "#17";
    }, 2000); */
  }, [id])

  useEffect(() => {
    if(permisos['calendar.getTareas']){
      setTimeout(() => {
        traerTareas();
      }, 300);
    }
  }, [permisos,id,ticket]);

  useEffect(() => {
    if(ticketrefresh?.reloading){
        setContratoSoft(null)
        setTrato(null)
        setLinkContratoSoft(null)
        actualizar();
        if(permisos['calendar.getTareas']){
          setTimeout(() => {
            traerTareas();
          }, 300);
        }
    }
  }, [ticketrefresh]);
  const actualizarNodoPanelTicket = async(servicioId) =>{
    await MikrotikV2Service.actualizarTicketEquipoServicioId({ticketId: id,servicioId: servicioId}).then((resp)=>{
      if(resp?.id_tk){
        actualizar();
      }
    })
  }
  useEffect(() => {
      if(Array.isArray(contratoSoft) && contratoSoft?.length > 0){
          const dataAux = AuxiliarFunction.extraerDataContratoSonft(contratoSoft)
          setTimeout(() => {
            if( (!ticket?.nodoName_tk && !ticket?.tecServicio_tk) && dataAux?.servicioId){
              actualizarNodoPanelTicket(dataAux?.servicioId)
            }
          }, 500);
          setLinkContratoSoft(dataAux)
      }else{
        setLinkContratoSoft(null)
      }
  }, [contratoSoft])
  const onDeletedFile = (fileDele) =>{
    const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
    const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
    setFiles(filesRest)
    setFilesCompress(filesCompRest)
  }
  const onCompressImg = async(imgx,ot) =>{
    const nombreCortado = imgx?.name?.split('.');
    let extension = nombreCortado[ nombreCortado?.length - 1 ];
    extension = extension?.toLowerCase();
    if(extensionPermitida.includes( extension ) ){
        setIsLoading(true);
        const img = new Compressor(imgx, {
            quality: 0.3,
            convertSize:2000000,
            success(result) {
                result.id=imgx?.id
                result.preview=imgx?.preview
                setIsLoading(false);
                setFilesCompress(old => [...old,result]);
            }
        })
    }else{
      setFilesCompress(old => [...old,imgx]);
    }
  }
  useEffect(() => {
    if(files.length > 0){
        files.forEach((filImg)=>{
            const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
            if(!existe){
                onCompressImg(filImg)
            }
        })
    }
  }, [files])

  const onOpenLogs = () =>{
    showModal(ModalTicketIdLogs, {
      ticketId: id,
      ticketIdOld: ticket.ticketIdOld_tk,
      type: 'nubicom',
      nombreCompleto: trato?.nombre_t || linkContratoSoft?.nombre,
      preServicioId: trato?.id_t,
      contactoId: trato?.contactoId_t,
      cuentaId: trato?.cuentaId_t || linkContratoSoft?.cuenta,
      servicioId: trato?.cuentaContratoId_t || linkContratoSoft?.servicioId,
    });
  }
  const onOpenAsignacion = () => {
    let socketOld = 2;
    if( ticket?.habilitadoTK?.tipo_uxt === 'DNI' && ticket?.habilitadoTK?.nombre_p){
        socketOld = ticket?.habilitadoTK?.dni_p
    }else if( ticket?.habilitadoTK?.tipo_uxt === 'GRUPO' && ticket?.habilitadoTK?.nombre_g){
        socketOld = ticket?.habilitadoTK?.id_g
    }
    showModal(ModalAsignarTicket, {
      ticketId: id,
      onGuardar:actualizar,
      type: 'nubicom',
      socketOld: socketOld,
      socketReturn:'ticketNubiRefresh',
      stageTicket: ticket?.stageTicket_tk
    });
  }
  const onGuardarActualizar = () =>{
    actualizar();
    traerTareas();
  }
  const onOpenModalCrearTarea = () =>{
    showModal(ModalCrearTarea,{ticket: ticket, contratoSoft: contratoSoft, tareas:tareas, onGuardar:onGuardarActualizar })
  }
  const onOpenModalConfirmInstalacion = (tratoId) =>{
    showModal(ModalConfirmInstalacion, {ticketId: id, tratoId: tratoId, trato: trato, onGuardar: actualizar, nombreCliente: linkContratoSoft?.nombre || trato?.nombre_t});
  }
  const onOpenModalEditNubicom = ()=>{
    showModal(ModalEditTicketNubicom, {item: ticket, onGuardar: actualizar, trato: trato});
  }
  const onOpenReapertura = () =>{
    showModal(ModalReaperturaTicket,{onGuardar:actualizar,ticket: ticket, type: 'nubicom'})
  }
  const onOpenModalConfirmPrefactibilidad = (tratoId) =>{
    showModal(ModalConfirmarPrefactibilidad, {ticketId: id, tratoId: tratoId, onGuardar: actualizar});
  }
  const onOpenModalConfirmItemsPrefa = (tratoId) =>{
    showModal(ModalConfirmarItemsPrefa, {ticketId: id, tratoId: tratoId, onGuardar: actualizar});
  }
  const onClickNameCliente = () =>{
    if(linkContratoSoft?.cuenta){
      navigate(`/contactos/${linkContratoSoft?.cuenta}/cuenta`)
    }else if(trato?.cuentaId_t){
      navigate(`/contactos/${trato?.cuentaId_t}/cuenta`)
    }else{
      navigate(`/contactos/${trato?.contactoId_t}/nubicom`)
    }
  }
  const onClickNroServicio = () =>{
    if(linkContratoSoft?.servicioId){
      navigate(`/servicio/${linkContratoSoft?.servicioId}/cuenta`)
    }
  }
  const onClickCambioTitular = () =>{
    if(ticket?.ticketxtrato?.length > 0){
      const idTrato = ticket?.ticketxtrato[0]?.cuentaContratoId_txt
      if(contratoSoft?.length > 0 && contratoSoft[0][idTrato]?.length > 0){
        const tratoCuenta = contratoSoft[0][idTrato][0]
        navigate(`/contactos/cambio-titular`,{state:{trato: tratoCuenta,ticket: id}})
      }
    }
  }
  const consultarCambioTitularServicio = async(servicioId)=>{
    await TratoService.consultarCambioTitularServicio({servicioId:servicioId}).then((resp)=>{
      setNuevoTitular(resp)
    })
  }
  useEffect(() => {
    if(ticket && ticket?.motivo_tk === 26){
      if(ticket?.ticketxtrato?.length > 0){
        const idTrato = ticket?.ticketxtrato[0]?.cuentaContratoId_txt;
        if(contratoSoft?.length > 0 && contratoSoft[0][idTrato]?.length > 0){
          const tratoCuenta = contratoSoft[0][idTrato][0];
          consultarCambioTitularServicio(tratoCuenta?.numero);
        }
      }
    }
  }, [contratoSoft,ticket])
  const onCancelarCambioTitular = (idT) =>{
    showModal(ModalCancelarCambioTitular,{ ticketId: id, idPreServicio: idT, onGuardar: actualizar });
  }
  const onOpenModalNodoPanel = () =>{
    showModal(ModalTicketNodoPanel,{ticket: ticket, onGuardar: actualizar})
  }
  const onOpenModalDetAdd = () => {
    showModal(ModalTicketDetalleAdicional,{ticket: ticket, onGuardar: actualizar})
  }
  const onOpenModalAdjContConst = (idT) => {
    showModal(ModalAdjContratoConstancia,{ticket: ticket, idPreServicio: idT, onGuardar: actualizar})
  }
  return (
    <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
      <Grid container sx={{marginBottom: 10,p:2,pl:4}}>
        <Grid item xs={12} mb={3}>
          <Card
            sx={{
              background: '#fff !important',
              p:1.5,
              display: 'flex',
              alignItems: 'center',
              borderBottomLeftRadius:10,
              borderBottomRightRadius:10,
              boxShadow: "0px 5px 0px -5px rgba(1,154,221,0.9),0px 2px 0px 0px rgba(1,154,221,0.5),0px 3px 0px 0px rgba(1,154,221,0.6)"
            }}
          >
            <Grid container>
              <Grid item xs={1} sm={1} md={1} display='flex' justifyContent='space-between' alignItems='center'>
                <IconButton sx={{boxShadow: 3,marginLeft: 2,width: 35, height:35}} onClick={()=>navigate(-1)}>
                  <ArrowBackIcon sx={{color: '#15294b',fontSize: 17}} />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={7} md={9} display='flex' justifyContent='start' alignItems='center'>
                  <Box sx={{boxShadow: 4, display: 'flex', justifyContent: 'center', alignItems: 'center',borderRadius: 50,width: 35, height: 35}}>
                    <Avatar alt="Logo Nubicom" src='/img/nubi-cirucular.png' sx={{ width: 34, height: 34, marginTop:0.1 }} />
                  </Box>
                  <Typography
                    sx={{fontWeight: 'normal',color: '#15294b',display: 'flex', alignItems: 'center',justifyContent: 'center',ml:1}}
                    component='div'
                >
                    <Box fontWeight='bold' display='inline' sx={{fontSize: 17,mr:0.2}}>{`TICKET #${ticket?.id_tk} - `}</Box>{ticket?.titulo_tk}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display='flex' justifyContent='center' alignItems='center'>
                <Typography
                    sx={{color: '#0364ff',cursor: 'pointer'}} variant='h6'
                    onClick={()=>onOpenLogs()}
                >
                    Ver historial
                </Typography>
                { ticket && (ticket.habilitadoTK && ticket.habilitadoTK.ticketId_uxt || permisos && permisos['asignacionGral.nubicom']) && !ticket.cerrado_tk && ticket?.fechavencimiento_tk &&(
                  <ButtonBasic
                    textButton='Asignar ticket'
                    variant='wk'
                    color='nextStep'
                    sx={{ml: 0.5,height: '75%'}}
                    onClick={()=>onOpenAsignacion()}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={2.9}>
          <Card sx={{background: '#fff !important',borderRadius: 3,marginBottom: 5}}>
            <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:2,marginTop:1,fontSize: 16}} variant='h5'>Cliente</Typography>
            <Box sx={{ml:2,mt:1,display: 'flex', alignItems: 'center'}}>
              <AccountCircleIcon sx={{color: '#15294b',fontSize: 34,marginLeft: 1}} />
              <Typography
                sx={{color: '#15294b',fontWeight: 'bold', fontSize: 14,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                variant='h5'
                onClick={()=>onClickNameCliente()}
              >
                {linkContratoSoft?.nombre || trato?.nombre_t}
              </Typography>
            </Box>
            {linkContratoSoft?.nombreServicio && (
              <Typography
                sx={{textAlign: 'center',mt:1,color: '#0364ff',fontWeight: 'bold', fontSize: 13,cursor: 'pointer', '&:hover':{color: '#0f98c5'}}}
                variant='h5'
                onClick={()=>onClickNroServicio()}
              >
                {`${linkContratoSoft?.servicioId || ''} ${linkContratoSoft?.servicioId ? '-' : ''} ${linkContratoSoft?.nombreServicio}`}
              </Typography>
            )}
            {(linkContratoSoft?.vip || trato?.nivelVip_t) && (
                <Typography
                  sx={{color: '#15294b',fontWeight: 'bold', fontSize: 13,textAlign: 'center',mt: 0.3}}
                  component='div'
                >
                  Nivel VIP:
                  <Box fontWeight='normal' display='inline' color='#15294b'>
                    {linkContratoSoft?.vip || trato?.nivelVip_t}
                  </Box>
                </Typography>
            )}
            {(linkContratoSoft?.domicilio || trato?.direccion_t) && (
                <Typography
                  sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12,textAlign: 'center',mt: 0.3}}
                  component='div'
                >
                  Dirección:
                  <Box fontWeight='normal' display='inline'>
                    {linkContratoSoft?.domicilio || trato?.direccion_t}
                  </Box>
                </Typography>
            )}
            {(linkContratoSoft?.localidad || ticket?.localidadTk?.codLocalidad) && (
                <Typography
                  sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12,textAlign: 'center',mt: 0.3}}
                  component='div'
                >
                  Localidad:
                  <Box fontWeight='normal' display='inline'>
                    {linkContratoSoft?.localidad
                      ? linkContratoSoft?.localidad
                      : `${ticket?.localidadTk?.codLocalidad} - ${ticket?.localidadTk?.nombre || ''}`
                    }
                  </Box>
                </Typography>
            )}
            {(linkContratoSoft?.coordenadas || trato?.coordenadas_t) && (
                <Typography
                  sx={{display: 'grid',color: '#15294b',fontWeight: 'bold', fontSize: 12,textAlign: 'center',mt: 0.3}}
                  component='div'
                >
                  Coordenadas:
                  <Box fontWeight='normal' display='inline' sx={{textAlign: 'center'}}>
                    {linkContratoSoft?.coordenadas
                      ? linkContratoSoft?.coordenadas
                      : trato?.coordenadas_t
                    }
                  </Box>
                </Typography>
            )}
            {(linkContratoSoft?.telefono || trato?.celular_t) && (
              <Typography
                sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12,textAlign: 'center',mt: 0.3}}
                component='div'
              >
                Telefono:
                <Box fontWeight='normal' display='inline'>
                  {linkContratoSoft?.telefono || trato?.celular_t}
                </Box>
              </Typography>
            )}
            {(linkContratoSoft?.telefono2) && (
              <Typography
                sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12,textAlign: 'center',mt: 0.3}}
                component='div'
              >
                Telefono2:
                <Box fontWeight='normal' display='inline'>
                  {linkContratoSoft?.telefono2}
                </Box>
              </Typography>
            )}
            <Box sx={{display: 'flex', justifyContent:'end',mr: 1,mt: 1}}>
              {ticket && !ticket?.cerrado_tk && (
                <ButtonBasic
                  textButton='Email'
                  variant='wk'
                  color='downloadHome'
                  sx={{background: 'white',boxShadow: 3, color: '#15294b',fontWeight: 'bold',fontSize:11}}
                  iconStart={<EmailOutlinedIcon sx={{color: '#15294b',fontSize: '16px !important'}} />}
                  onClick={()=>onOpenSendEmail()}
                />
              )}
              {((ticket && ticket?.cerrado_tk && permisos['ticket.editNubiCerrado']) || (ticket && !ticket?.cerrado_tk)) && (
                <IconButton
                  sx={{boxShadow: 3, display: 'flex', justifyContent: 'center', alignItems: 'center',width:30,height: 30,marginLeft:2}}
                  onClick={()=>onOpenModalEditNubicom()}
                >
                  <EditIcon sx={{color: '#15294b',fontSize: 18}} />
                </IconButton>
              )}
            </Box>
            <Divider sx={{background: 'gray',marginTop:1}} />
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Datos del ticket</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              {ticket?.createdAt && (
                <TextInfoTicket
                  titulo='Fecha de creación'
                  descripcion={FechaFunction.format(new Date(ticket?.createdAt),'dd/MM/yyyy')}
                />
              )}
              {ticket && (ticket.estado_tk !== "CERRADO") ? (
                <TextInfoTicket
                  titulo='Asignado a:'
                  descripcion={ticket && ticket.habilitadoTK && (ticket.estado_tk !== "CERRADO") && ticket?.habilitadoTK.tipo_uxt === 'DNI'
                  ? ticket?.habilitadoTK?.nombre_p : ticket?.habilitadoTK?.nombre_g}
                />
              ):(
                <TextInfoTicket
                  titulo='Ultima Asignacion:'
                  descripcion={ticket && ticket.ultimaAsignacion && ticket?.ultimaAsignacion?.id_p
                  ? `${ticket?.ultimaAsignacion?.nombre_p} - ${ticket?.ultimaAsignacion?.departamento_p}`
                  : ticket?.ultimaAsignacion?.nombre_g }
                />
              )}
              {ticket && ticket?.estado_tk === 'CERRADO'&&(
                <TextInfoTicket
                  titulo='Fecha Cierre'
                  descripcion={ticket && ticket.fechavencimiento_tk && ticket.estado_tk === 'CERRADO' ? ( FechaFunction.format(new Date(ticket?.cerrado_tk),'dd/MM/yyyy HH:mm:ss') ):( 'Sin Definir')}
                />
              )}
              { ticket && ticket?.finalAsig_tk?.nombre_g &&(
                <TextInfoTicket
                  titulo='Revision final:'
                  descripcion={ticket?.finalAsig_tk?.nombre_g}
                />
              )}
              { ticket && ticket?.motivotk &&(
                <TextInfoTicket
                  titulo='Plantilla'
                  descripcion={ticket?.motivotk?.motivo_mt}
                />
              )}
            </Box>
            <Divider sx={{background: 'gray'}} />
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Información del estado</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <TextInfoTicket
                titulo='Prioridad'
                descripcion={ticket?.prioridad_tk}
              />
              <TextEstadoTicket
                titulo='Estado'
                descripcion={ticket?.estado_tk}
                abiertoCerrado
                estado={ticket?.cerrado_tk}
              />
              <TextInfoTicket
                titulo='Etapa del ticket'
                descripcion={ticket?.stageTicket_tk}
              />
            </Box>
            <Divider sx={{background: 'gray'}} />
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Gestión de tiempo</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              <TextInfoTicket
                titulo='Fecha de vencimiento'
                descripcion={ticket && ticket.fechavencimiento_tk ? ( FechaFunction.format(ticket?.fechavencimiento_tk,'dd/MM/yyyy') ):( 'Sin Definir')}
              />
              <TextEstadogTicketID
                titulo='Estado de vencimiento'
                descripcion={ticket?.estadoVencimiento_tk}
              />
              {ticket && ticket?.trancurrido && !ticket?.cerrado_tk && (
                <TextInfoTicket
                  titulo='Tiempo transcurrido'
                  descripcion={ticket?.trancurrido}
                />
              )}
              {ticket && !ticket?.trancurrido && ticket?.cerrado_tk && (
                <TextInfoTicket
                  titulo='Tiempo transcurrido'
                  descripcion={ticket?.timeElapsed_tk?.time}
                />
              )}
            </Box>
            <Divider sx={{background: 'gray'}} />
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Detalle Adicional</Typography>
                <IconButton
                  sx={{boxShadow: 3, display: 'flex', justifyContent: 'center', alignItems: 'center',width:25,height: 25,marginRight:1,mt:0.5}}
                  onClick={()=>onOpenModalDetAdd()}
                >
                  <EditIcon sx={{color: '#15294b',fontSize: 14}} />
                </IconButton>
            </Box>
            <Box display='flex' flexDirection='column'>
              { ticket && ticket?.codCanalNombre_tk &&(
                <TextInfoTicket
                  titulo='Canal'
                  descripcion={ticket?.codCanalNombre_tk}
                />
              )}
              { ticket && ticket?.tipoTicketId_tk &&(
                <TextInfoTicket
                  titulo='Tipo de ticket'
                  descripcion={ticket?.tipoTicket?.nombre_ttk}
                />
              )}
              { ticket && ticket?.subTipoTicketId_tk &&(
                <TextInfoTicket
                  titulo='Sub-Tipo de ticket'
                  descripcion={ticket?.subTipoTicket?.nombre_sttk}
                />
              )}
              { ticket && ticket?.submotivotk &&(
                <TextInfoTicket
                  titulo='Sub-Motivo'
                  descripcion={ticket?.submotivotk?.titulo_smt}
                />
              )}
              { ticket && ticket?.nivelAdmTkId_tk &&(
                <TextInfoTicket
                  titulo='Nivel Administrativo'
                  descripcion={ticket?.nivelAdmTicket?.nombre_natk}
                />
              )}
              { ticket && ticket?.motivoBajaTk_tk &&(
                <TextInfoTicket
                  titulo='Motivo Baja'
                  descripcion={ticket?.motivoBajaTk?.nombre_mbtk}
                />
              )}
              { ticket && ticket?.cierreTicket &&(
                <TextInfoTicket
                  titulo='Motivo de Cierre'
                  descripcion={ticket?.cierreTicket?.nombre_ct}
                />
              )}
              { ticket && ticket?.subCierreTicket &&(
                <TextInfoTicket
                  titulo='Sub-Motivo de Cierre'
                  descripcion={ticket?.subCierreTicket?.nombre_sct}
                />
              )}
                <TextInfoTicket
                  titulo='Seg. Detalle Adicional'
                  descripcion={ticket?.articuloFallaTk?.nombre_atk || '-'}
                />
                <TextInfoTicket
                  titulo='Problematica'
                  descripcion={ticket?.problematicaStr || '-'}
                />
                <TextInfoTicket
                  titulo='Solucion tecnica'
                  descripcion={ticket?.solucionTecStr || '-'}
                />
                <TextInfoTicket
                  titulo='Derivo en Asistencia'
                  descripcion={ticket?.derivoAsis_tk || '-'}
                />
                {(ticket?.motivo_tk === 40 || ticket?.motivo_tk === 36 || ticket?.motivo_tk === 7) &&(
                  <TextInfoTicket
                    titulo='Linea de Vista'
                    descripcion={ticket?.lineaVista_tk || '-'}
                  />
                )}
            </Box>
            {ticket?.tipoTicket?.nombre_ttk === 'TECNICO' && (
              <>
                <Divider sx={{background: 'gray'}} />
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:1,marginTop:1,fontSize: 15}} variant='h5'>Nodo - Panel</Typography>
                  {!ticket?.cerrado_tk && (contratoSoft?.length >=2 || contratoSoft?.length === 0 || linkContratoSoft?.nombre === 'TICKET MAESTRO') && (
                    <IconButton
                      sx={{boxShadow: 3, display: 'flex', justifyContent: 'center', alignItems: 'center',width:25,height: 25,marginRight:1,mt:0.5}}
                      onClick={()=>onOpenModalNodoPanel()}
                    >
                      <EditIcon sx={{color: '#15294b',fontSize: 14}} />
                    </IconButton>
                  )}
                </Box>
                <Box display='flex' flexDirection='column'>
                  <TextInfoTicket
                    titulo='Tecnologia'
                    descripcion={ticket?.tecServicio_tk?.toUpperCase() || '-'}
                  />
                  <TextInfoTicket
                    titulo='Nodo'
                    descripcion={ticket?.nodoName_tk || '-'}
                  />
                  <TextInfoTicket
                    titulo={ticket?.tecServicio_tk === 'fibra' ? 'Nap' :'Panel'}
                    descripcion={ticket?.panelName_tk || '-'}
                  />
                  {ticket?.tecServicio_tk === 'fibra' && (
                    <TextInfoTicket
                      titulo='Puerto'
                      descripcion={ticket?.puertoNap_tk || '-'}
                    />
                  )}
                  {ticket?.tecServicio_tk === 'radio' && (
                    <TextInfoTicket
                      titulo='Tecnologia RF'
                      descripcion={ticket?.tipoEquipoRF_tk || '-'}
                    />
                  )}
                  <TextInfoTicket
                    titulo='Equipo'
                    descripcion={ticket?.equipoTec_tk || '-'}
                  />
                </Box>
              </>
            )}
            <Divider sx={{background: 'gray',marginTop:1}} />
            { contratoSoft && contratoSoft?.length > 0 && (
              <>
                <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:2,marginTop:1,fontSize: 16}} variant='h5'>Servicios</Typography>
                {contratoSoft?.map((tr)=>{
                    if(tr && Object?.keys(tr)?.length > 0 ){
                      return (
                        <ListContratoSoft
                          tr={tr}
                          ticket={id}
                          estadoTicket={ticket?.estado_tk}
                          onGuardar={actualizar}
                        />
                      )
                    }
                    return '';
                })}
              </>
            )}
            { ticket && !ticket?.cerrado_tk && permisos['contactos.verCambioTitular'] &&(
              <>
                { ticket && ticket?.motivo_tk === 26 && ticket?.cierreTicketId_tk !== 9 && (
                  <Box sx={{width: '100%'}}>
                    <Divider sx={{background: 'gray',marginTop:1}} />
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:2,marginTop:1,fontSize: 16}} variant='h5'>Nuevo Contacto</Typography>
                    <Box sx={{flex:1, p: 2}}>
                      {nuevoTitular?.disponible && !ticket?.cerrado_tk ? (
                        <>
                          <Box sx={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                            <ButtonBasic
                              textButton='Crear nuevo contacto'
                              variant='wk'
                              color='nextStep'
                              onClick={()=>onClickCambioTitular()}
                            />
                          </Box>
                        </>
                      ):(
                        <Card sx={{background: '#fff !important',p: 2,boxShadow:4,borderRadius: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                          <TextInfoTicket
                            titulo={!nuevoTitular?.cuentaContratoId_t ? 'Nro Pre-Servicio' : 'Nro Servicio'}
                            descripcion={`#${nuevoTitular?.id_t}`}
                            onClick={()=>navigate(`/pre-servicio/${nuevoTitular?.id_t}/nubicom`)}
                          />
                          <TextInfoTicket
                            titulo='Contacto'
                            descripcion={nuevoTitular?.nombre_t}
                            onClick={()=>{
                              if(nuevoTitular?.cuentaId_t){
                                navigate(`/contactos/${nuevoTitular?.cuentaId_t}/cuenta`)
                              }else{
                                navigate(`/contactos/${nuevoTitular?.contactoId_t}/nubicom`)
                              }
                            }}
                          />
                          { nuevoTitular?.id_t && !nuevoTitular?.confirmaAltaCambioTitular_t && (
                            <>
                              {(!nuevoTitular?.camTitularContrato_t || !nuevoTitular?.camTitularConstancia_t) && (
                                <ButtonBasic
                                  textButton='Adjuntar Contrato y Constancias'
                                  variant='wk'
                                  color='nextStep'
                                  sx={{mb: 1,borderRadius: 2}}
                                  onClick={()=>onOpenModalAdjContConst(nuevoTitular?.id_t)}
                                />
                              )}
                              <ButtonBasic
                                textButton='Cancelar cambio de titular'
                                variant='wk'
                                color='error'
                                onClick={()=>onCancelarCambioTitular(nuevoTitular?.id_t)}
                              />
                            </>
                          )}
                        </Card>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
            { ticket && ticket?.tratos?.length > 0 && (
              <>
                <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:2,marginTop:1,fontSize: 16}} variant='h5'>Pre-Servicios</Typography>
                {ticket.tratos.map((tr)=>(
                    <ListInfoCam
                      tr={tr}
                      onModal={onOpenModalConfirmInstalacion}
                      onModalPrefa={onOpenModalConfirmPrefactibilidad}
                      onModalItems={onOpenModalConfirmItemsPrefa}
                      ticket={ticket}
                    />
                  ))}
                <Divider sx={{background: 'gray',marginTop:1}} />
              </>
            )}
            <Typography sx={{color: '#15294b',fontWeight: 'bold',marginLeft:2,marginTop:1,fontSize: 15}} variant='h5'>Tareas del Calendario</Typography>
              {tareasCerradas && !ticket?.cerrado_tk && ([1,2,3,6]?.includes(ticket?.motivotk?.tipoTareaId_mt)) && permisos['calendar.createdTarea'] && (linkContratoSoft?.tipo !== 'NCORP' && linkContratoSoft?.tipo !== 'Corporativo' && trato?.categoria_t !== 'COR' ) && (
                <Box sx={{display:'flex', justifyContent:'center'}}>
                  <ButtonBasic
                    variant="text"
                    textButton='Crear tarea'
                    sx={{color: '#0364ff',fontSize:14}}
                    onClick={()=>onOpenModalCrearTarea()}
                  />
                </Box>
              )}
              {tareas && Array.isArray(tareas) && tareas?.length > 0 ?(
                  tareas?.map((tar)=>( <ListTarea tarea={tar} /> ))
              ):(
                <Typography sx={{color: 'gray',textAlign: 'center',mt:1,mb:2}}>No se registraron tareas</Typography>
              )}
          </Card>
        </Grid>
        <Grid item xs={12} md={8.9} ml={1.5}>
          <Card sx={{background: '#fff !important',borderRadius: 3}}>
            <Grid container p={2}>
              <Grid item xs={10} md={9} display='flex' alignItems='center'>
                <Avatar src={ticket?.autor?.foto_p} sx={{width: 33, height:33}} />
                <Typography sx={{color: '#8993a4',marginLeft:1.5}} variant='h5'>{ticket?.autor?.nombre_p}</Typography>
                <Typography sx={{color: '#8993a4',marginLeft:1}} variant='body2'>- autor</Typography>
              </Grid>
              <Grid item xs={2} md={3} display='flex' alignItems='center' justifyContent='center'>
                <Typography sx={{color:'#15294b',opacity: 0.9}}>
                  {ticket && (
                    `${FechaFunction.format(new Date(ticket?.createdAt),'dd/MM/yyyy HH:mm:ss')}`
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{background: '#fff',p:2}}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold'}} variant='h5'>Descripción</Typography>
              { ticket && (
                <InputReadQuill
                    id="cuerpo_tk"
                    template={ticket?.cuerpo_tk}
                />
              )}
            </Box>
            {ticket && ticket?.imgtk?.length > 0 &&(
              <Grid container>
                {
                  ticket?.imgtk?.map((img)=>{
                    if(img?.path_it){
                      const tipe = img?.path_it?.split('.');
                      if(tipe?.length > 1){
                        const tipoAdj = tipe[tipe?.length - 1];
                        if(tipoAdj === 'jpg' || tipoAdj === 'png' || tipoAdj === 'jpeg' || tipoAdj === 'svg' || tipoAdj === 'x-png'){
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                    <PhotoConsumer
                                        key={`IMG${img.path_it}`}
                                        src={`${urlNubi}${img.path_it}&token=${token}`}
                                        intro={img.path_it}
                                    >
                                        <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                            <img
                                                src={`${urlNubi}${img.path_it}&token=${token}`}
                                                alt={`${urlNubi}${img.path_it}&token=${token}`}
                                                style={{width:50,height:50}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_it}
                                            </Typography>
                                        </Card>
                                    </PhotoConsumer>
                                </Grid>
                            )
                        }
                        if(tipoAdj === 'wmv' || tipoAdj === 'mp4' || tipoAdj === 'avi' || tipoAdj === 'mov'){
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={4} className='ml-2 mt-1 cursor-pointer'>
                                    <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                        <Box>
                                            <ReactPlayer
                                                key={`Video${img.path_it}`}
                                                url={`${urlNubi}${img.path_it}&token=${token}`}
                                                width='155px'
                                                height='60px'
                                                controls
                                            />
                                        </Box>
                                        <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                            {img?.nombre_it}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                        if(tipoAdj === 'pdf'){
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                    <Card
                                        sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                        aria-hidden="true"
                                        onClick={()=>saveAs(`${urlNubi}${img.path_it}&token=${token}`,img.path_it)}
                                    >
                                        <img
                                            src='/img/pdf.png'
                                            alt='pdf.png'
                                            style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                        />
                                        <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                            {img?.nombre_it}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                        if(tipoAdj === 'doc' || tipoAdj === 'docx'){
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                    <Card
                                        sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                        aria-hidden="true"
                                        onClick={()=>saveAs(`${urlNubi}${img.path_it}&token=${token}`,img.path_it)}
                                    >
                                        <img
                                            src='/img/doc.png'
                                            alt='doc.png'
                                            style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                        />
                                        <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                            {img?.nombre_it}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                        if(tipoAdj === 'xls' || tipoAdj === 'xlsx'){
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                    <Card
                                        sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                        aria-hidden="true"
                                        onClick={()=>saveAs(`${urlNubi}${img.path_it}&token=${token}`,img.path_it)}
                                    >
                                        <img
                                            src='/img/xls.png'
                                            alt='xls.png'
                                            style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                        />
                                        <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                            {img?.nombre_it}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                        if(tipoAdj === 'txt'){
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                    <Card
                                        sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                        aria-hidden="true"
                                        onClick={()=>saveAs(`${urlNubi}${img.path_it}&token=${token}`,img.path_it)}
                                    >
                                        <img
                                            src='/img/txt.png'
                                            alt='txt.png'
                                            style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                        />
                                        <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                            {img?.nombre_it}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                      }
                    }
                      return ''
                  })
              }
              </Grid>
            )}
            {ticket && permisos['ticketNubi.cerrar'] && (!ticket?.cerrado_tk) && (ticket?.prioridad_tk !== 'A DEFINIR') &&(
              <Box sx={{display: 'flex', justifyContent: 'end',pr:2}}>
                  <ButtonBasic
                    textButton='Cerrar ticket'
                    variant='wk'
                    color='downloadHome'
                    sx={{height: 25}}
                    onClick={()=>cerrarTicket()}
                  />
              </Box>
            )}
            {ticket && !ticket?.reopeningDate_tk && permisos['ticketNubi.reapertura']  && !ticket.isOpenAgain_tk && (ticket.estado_tk === "CERRADO") && (
              <Box sx={{display: 'flex', justifyContent: 'end',pr:2}}>
                  <ButtonBasic
                    textButton='Reapertura del ticket'
                    variant='wk'
                    color='nextStep'
                    onClick={()=>onOpenReapertura()}
                  />
              </Box>
            )}
            <Divider sx={{background: 'gray !important',pl:-2,marginTop:4}} />
            <Box sx={{background: '#fafbfb',p:2}}>
              <Typography sx={{color: '#15294b',fontWeight: 'bold',marginTop:2}} variant='h5'>Comentarios</Typography>
              {ticket && ticket.comentarios.length > 0 ? (
                ticket.comentarios?.map((tk)=>(
                  <ComentarioTicketCard item={tk} key={tk.id_com} user={user} onGuardar={actualizar} />
                ))
              ):(
                <Typography sx={{color: '#15294b',textAlign: 'center',marginTop:2,marginBottom:2}} variant='h5'>Aún no hay comentarios</Typography>
              )}
            </Box>
            { ticket && !ticket?.cerrado_tk &&(
              <Divider sx={{background: 'gray !important',pl:-2,marginTop:1}} />
            )}
            <Box sx={{background: '#fff',p:2}}>
                { ticket && !ticket?.cerrado_tk &&(
                  <Box mb={2}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',marginBottom:2}} variant='h5'>Dejar un comentario</Typography>
                      <InputEditQuill
                          id="comentario12dsas"
                          template={comentario}
                          etiqueta
                          onChangeText={(html)=>{
                              setComentario(html)
                          }}
                      />
                      <Grid container mt={1}>
                          { filesCompress?.length > 0 &&(
                            filesCompress.map((fil)=>(
                              <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                              </Grid>
                            ))
                          )}
                      </Grid>
                      <Box sx={{border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                        <InputDropZoneComment
                          id="otImg"
                          backgroundImage="/img/svideo.png"
                          onChange={(e)=>setFiles((old)=>[...old,...e])}
                          setFoto={setFiles}
                          accept={acceptInputFile}
                        />
                      </Box>
                      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ButtonBasic
                            onClick={onSubmit}
                            isLoading={isLoading}
                            sx={{width: 300,marginTop:1}}
                            variant='wk'
                            color='nextStep'
                            textButton='Realizar Comentario'
                        />
                      </Box>
                  </Box>
                )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  ticketrefresh: state.ticketrefresh
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketGetByIdNubiScreen);