import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, SelectUser } from '../../components';
import UserService from '../../services/personas.service';

const ModalCanalPrivado = ({show, hideModal, item, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        id_p: {
            texto: null,
            textoErr: 'El personal es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if(!value || value === ''){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['id_p']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            UserService.cpAlta(form)
            .then(() => {
                onGuardar();
                hideModal();
            })
            .finally(() => {
                setIsLoading(true);
            });
        }else{
            setIsLoading(false)
        }
    }

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Habilitar Canal Privado
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Usuario</Typography>
                            <SelectUser
                                value={formAll.id_p.texto}
                                filter='FalseCanalPriv'
                                onChange={(opt) => onChangeForm(opt.value,'id_p')}
                            />
                            {formAll.id_p.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.id_p.textoErr}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={formAll?.acciones?.texto?.length === 0 || formAll?.personas?.texto?.length === 0}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalCanalPrivado