import { Box, Card, Grid, Typography } from '@mui/material'
import ButtonBasic from '../buttons/ButtonBasic'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { showModal } from 'react-redux-modal-provider';
import ModalDashTickets from '../../modals/dashboard/ModalDashTickets';

const CardDashboardTotal = ({texto,cantidad,icon,items}) => {

    const onOpenModal = () =>{
        showModal(ModalDashTickets,{texto:texto, items:items})
    }

    return (
        <Card sx={{background: '#fff !important', width: 280,borderRadius:5}} className='cardDashboardTotal'>
            <Grid container mt={2} mb={1}>
                <Grid item xs={4} display='flex' justifyContent='center' mt={1}>
                    <Card sx={{height: 50, width:50,background: '#e6f0ff !important',display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
                        {icon}
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{color: '#000'}} variant='h4'>{texto}</Typography>
                    <Box display='flex' mt={1}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h1'>{cantidad}</Typography>
                        <Typography sx={{color: '#000',marginTop: 3,marginLeft: 1}} variant='h5'>Total</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box p={1} mb={2}>
                <ButtonBasic
                    textButton='Más info'
                    className='buttonCardDashboardTotal'
                    fullWidth
                    disableElevation
                    sx={{borderRadius: 20, background: '#fff',color:'#0364ff',fontWeight: 'bold', fontSize: 14,
                        '&:hover':{
                            background: '#e6f0ff'
                        }
                    }}
                    iconEnd={<ArrowForwardIcon sx={{display: 'none'}} className='iconCardDashboardTotal' />}
                    onClick={()=>onOpenModal()}
                />
            </Box>
        </Card>
    )
}

export default CardDashboardTotal;