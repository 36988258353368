import { useState, useEffect } from "react";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material"
import ProcesosService from "../../services/procesos.service";
import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputReadQuill } from "../../components";

const ProcesosGetByIdScreen = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [item, setItem] = useState(null)
    const actualizar = async() =>{
        await ProcesosService.getById(id).then((resp)=>{
            setItem(resp)
        })
    }
    useEffect(() => {
        actualizar();
        document.title = `Proceso`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center' mb={20}>
                <Grid item xs={1}>
                    <IconButton sx={{boxShadow: 3,marginLeft: 2,width: 35, height:35}} onClick={()=>navigate(-1)}>
                        <ArrowBackIcon sx={{color: '#15294b',fontSize: 17}} />
                    </IconButton>
                </Grid>
                <Grid item xs={11} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography className="text-gray-900 font-bold" variant="h2" sx={{ml: {xs:1,md:-7,lg:-10}}}>{item?.nombre_pro}</Typography>
                </Grid>
                <Grid item xs={11} mb={2} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Card sx={{background: '#fff !important'}}>
                        <InputReadQuill template={item?.descripcion_pro || ''} />
                    </Card>
                </Grid>
                {item && item?.html1_pro && (
                    <Grid item xs={12} md={11} lg={9} sx={{display: 'flex', justifyContent: 'center'}}>
                        <Card sx={{background: '#fff !important', width: '95%', p:1,display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:3}}>
                            <iframe srcDoc={item?.html1_pro} width='100%' height={500} style={{display: 'block', justifyContent: 'center'}} />
                        </Card>
                    </Grid>
                )}
                {item && item?.html2_pro && (
                    <Grid item xs={12} md={11} lg={9} sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                        <Card sx={{background: '#fff !important', width: '95%', p:1,display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:3}}>
                            <iframe srcDoc={item?.html2_pro} width='100%' height={500} style={{display: 'block', justifyContent: 'center'}} />
                        </Card>
                    </Grid>
                )}
                {item && item?.video1_pro && (
                    <Grid item xs={12} md={10} lg={6} sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                        <Card sx={{background: '#fff !important', width: '95%', p:1,display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:3}}>
                            <ReactPlayer
                                key={`Video${item.video1_pro}`}
                                url={`https://zeta.strongsystems.com.ar:8000/api/img?url=procesos/${item?.video1_pro}&token=${token}`}
                                width='100%'
                                height='200px'
                                controls
                            />
                        </Card>
                    </Grid>
                )}
                {item && item?.video2_pro && (
                    <Grid item xs={12} md={10} lg={6} sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                        <Card sx={{background: '#fff !important', width: '95%', p:1,display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:3}}>
                            <ReactPlayer
                                key={`Video${item.video2_pro}`}
                                url={`https://zeta.strongsystems.com.ar:8000/api/img?url=procesos/${item?.video2_pro}&token=${token}`}
                                width='95%'
                                height='200px'
                                controls
                            />
                        </Card>
                    </Grid>
                )}
                {item && item?.video3_pro && (
                    <Grid item xs={12} md={10} lg={6} sx={{display: 'flex', justifyContent: 'center', mt:2}}>
                        <Card sx={{background: '#fff !important', width: '95%', p:1,display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:3}}>
                            <ReactPlayer
                                key={`Video${item.video3_pro}`}
                                url={`https://zeta.strongsystems.com.ar:8000/api/img?url=procesos/${item?.video3_pro}&token=${token}`}
                                width='95%'
                                height='200px'
                                controls
                            />
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default ProcesosGetByIdScreen