import { useEffect } from 'react';
import Typist from 'react-typist';
import './loading.css';

const LoadingScreen = () => {

    useEffect(() => {
        setTimeout(() => {
            window.location.replace('/')
        }, 4000);
    }, [])
    return (
        <div className="divLoad">
            <div>
                <div className="col-12 centerDiv">
                    <div className="hm-spinner"></div>
                </div>
                <div className="col-12 pt-5 pr-4 mr-4 mb-5">
                    <Typist>
                        <span className="pl-5 spanLoad"> Cargando...</span>
                    </Typist>
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen;
