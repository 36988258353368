import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import LogService from '../../services/log.service';
import ButtonBasic from '../buttons/ButtonBasic';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
const MenuOptionsNotificaciones = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpenViewTickets = async() => {
    await LogService.ticketNotifyViewAllZeta().then(()=>{
      setIsOpen(false)
    })
  }
  const onOpenViewEtiqueta = async() => {
    await LogService.etiquetaNotifyViewAllZeta().then(()=>{
      setIsOpen(false)
    })
  }
  const onOpenViewNotidyGral = async() => {
    await LogService.ticketNotifyGralViewAllZeta().then(()=>{
      setIsOpen(false)
    })
  }
  const onOpenViewAll = async() =>{
    await LogService.notifyViewAllZeta().then(()=>{
      setIsOpen(false)
    })
  }
  
  return (
    <>
      {/* <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{boxShadow:2,border:1,borderColor:'gray'}}>
        <MoreVertOutlinedIcon sx={{color: 'gray'}} />
      </IconButton> */}
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <ButtonBasic
          textButton='Acciones'
          variant='wk'
          color='nextWhite'
          iconStart={<PlaylistAddCheckIcon />}
          sx={{border: 1, borderColor: '#0364ff',width: 150}}
        />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 225, maxWidth: '100%',background: '#fff !important' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
          <MenuItem sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }} onClick={()=>onOpenViewNotidyGral()}>
            <ListItemIcon>
              <InventoryOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Marcar generales como leídos" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }} onClick={()=>onOpenViewTickets()}>
            <ListItemIcon>
              <AssignmentTurnedInOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Marcar tickets como leídos" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }} onClick={()=>onOpenViewEtiqueta()}>
            <ListItemIcon>
              <HowToRegOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Marcar etiquetas como leídas" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem sx={{ color: '#15294b','&:hover':{background: '#c2c7d0'} }} onClick={()=>onOpenViewAll()}>
            <ListItemIcon>
              <FactCheckOutlinedIcon sx={{ color: '#15294b' }} />
            </ListItemIcon>
            <ListItemText primary="Marcar todo como leídos" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
      </Menu>
    </>
  );
}

export default MenuOptionsNotificaciones