import { useContext, useState, useMemo, useEffect } from 'react';
import { Avatar, Box, Card, Chip, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, SelectGrupos, SelectUser, SelectPrioridadTkNubicom, SelectMotivoTicket, DateSingle, InputEditQuill } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import FechaFunction from '../../function/FechaFunction';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PreTicketsService from '../../services/preTickets.service';
import ModalDirEntregaMulti from '../ticket/ModalDirEntregaMulti';
import { useNavigate } from 'react-router-dom';

function ModalCreatedTkToPtk({show, hideModal,onGuardar,item,hideModalPtk}) {
    const handleClose = () => hideModal();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { sockets } = useContext( SocketContext );
    const [formAll, setFormAll] = useState({
        titulo_tk: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        cuerpo_tk: {
            texto: null,
            textoErr: 'El cuerpo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        motivo_tk: {
            texto: null,
            textoErr: 'El motivo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoId_uxt: {
            texto: 27,
            textoErr: 'El destino es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipo_uxt: {
            texto: 'GRUPO',
            textoErr: 'El tipo de destino es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        prioridad_tk: {
            texto: 'NORMAL',
            textoErr: 'La prioridad es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        subTipoTicketId_tk: {
            texto: null,
            textoErr: 'El sub tipo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        fechavencimiento_tk: {
            texto: null,
            textoErr: 'La fecha de vencimiento es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        nivelAdmTkId_tk: {
            texto: null,
            textoErr: 'El nivel administrativo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoTicketId_tk: {
            texto: null,
            textoErr: 'El tipo de ticket es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        arrContactos: {
            texto: [],
            textoErr: 'El listado de contacto es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        destinoDNI: {
            texto: null,
            textoErr: 'El destino es requerido',
            estadoErr: false,
            tipo: 'string'
        },
    })
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const cambiarFecha = (date) =>{
        if(!date){
            date = 0;
        }
        const newDate = FechaFunction.addDaysDate(new Date(),date)
        onChangeForm(newDate, 'fechavencimiento_tk')
    }
    const onSubmit = async() =>{
        setIsLoading(true);
        const form = {
            propertys: 'nubicom',
            createdEmp_tk: 'NUBICOM',
            ticketIndv: true,
            id_ptke: item?.id_ptke,
            codCanal_tk: '06'
        };
        let isOk = false;
        setIsLoading(true);
        const inputRequired = [
            'titulo_tk',
            'cuerpo_tk',
            'motivo_tk',
            'prioridad_tk',
            'subTipoTicketId_tk',
            'tipoTicketId_tk',
            'arrContactos',
            'fechavencimiento_tk',
            'tipoId_uxt',
            'tipo_uxt'
        ];
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            await PreTicketsService.convertPtkToTk(form).then((resp)=>{
                sockets?.emit( 'newTicket',formAll?.destinoDNI?.texto);
                if(hideModalPtk){
                    hideModalPtk()
                }
                if(resp?.length > 0){
                    if(resp[0]?.cuentaId){
                        navigate(`/contactos/${resp[0]?.cuentaId}/cuenta`);
                    }else if(resp[0]?.contactoId){
                        navigate(`/contactos/${resp[0]?.contactoId}/nubicom`);
                    }else{
                        if(onGuardar){
                            onGuardar(true);
                        }
                    }
                }else{
                    if(onGuardar){
                        onGuardar(true);
                    }
                }
                hideModal();
            })
            .catch(()=>{
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
        }else{
            setIsLoading(false)
        }
    }

    const styleBtnSelect = useMemo(() => ({
        border:1,borderColor: '#15294b', color: '#15294b',background:'white',
        '&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleBtnNone = useMemo(() => ({
        background:'#dfe2e6',boxShadow:0,'&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])

    const addArrContacto = (objArrContacto) =>{
        if(formAll.arrContactos.texto?.length > 0){
            const searchContacto = formAll.arrContactos.texto.find((arC)=> arC?.id_t && arC?.id_t === objArrContacto?.id_t)
            const searchContactoCuenta = formAll.arrContactos.texto.find((arC)=> arC?.cuentaContratoId_txt && arC?.cuentaContratoId_txt === objArrContacto?.cuentaContratoId_txt)
            if(!searchContacto && !searchContactoCuenta){
                const arrNew = formAll.arrContactos.texto || [];
                arrNew.push(objArrContacto)
                onChangeForm(arrNew,'arrContactos')
            }
        }else{
            const arrNew = formAll.arrContactos.texto || [];
            arrNew.push(objArrContacto)
            onChangeForm(arrNew,'arrContactos')
        }
    }

    const onDeleteContacto = (contactoSelect) =>{
        if(contactoSelect?.cuentaContratoId_txt){
            const filtroPreServicio = formAll.arrContactos.texto.filter((arC)=>arC?.id_t)
            const filtroArr = formAll.arrContactos.texto.filter((arC)=>arC?.cuentaContratoId_txt && arC?.cuentaContratoId_txt !== contactoSelect?.cuentaContratoId_txt)
            onChangeForm(filtroArr.concat(filtroPreServicio),'arrContactos')
        }
        if(contactoSelect?.id_t){
            const filtroServ = formAll.arrContactos.texto.filter((arC)=>arC?.cuentaContratoId_txt)
            const filtroArr = formAll.arrContactos.texto.filter((arC)=>arC?.id_t && arC?.id_t !== contactoSelect?.id_t)
            onChangeForm(filtroArr.concat(filtroServ),'arrContactos')
        }
    }

    const onOpenModalContacto = () =>{
        showModal(ModalDirEntregaMulti,{onGuardar: addArrContacto})
    }

    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.subject_ptke,'titulo_tk')
            }, 100);
        }
    }, [item])

    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Convertir Pre-Ticket en Ticket
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Seleccione el destinatario</Typography>
                            <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center', width:250,background:'#dfe2e6',p:1,borderRadius:3,mb:2}}>
                                {/* <ButtonBasic
                                    textButton='Personal'
                                    sx={formAll?.tipo_uxt?.texto === 'DNI' ? styleBtnSelect : styleBtnNone}
                                    onClick={()=>onChangeForm('DNI', 'tipo_uxt')}
                                /> */}
                                <ButtonBasic
                                    textButton='Grupo'
                                    sx={[{marginLeft:0},formAll?.tipo_uxt?.texto === 'GRUPO' ? styleBtnSelect : styleBtnNone]}
                                    onClick={()=>onChangeForm('GRUPO', 'tipo_uxt')}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                            { formAll.tipo_uxt.texto === 'DNI' ? (
                                <Box sx={{width: '100%',mt:3}}>
                                    <SelectUser
                                        id="selectUser"
                                        filter="select"
                                        value={formAll.tipoId_uxt.texto}
                                        isInvalid={formAll.tipoId_uxt.estadoErr}
                                        onChange={(opt) => {
                                            onChangeForm(opt.value, 'tipoId_uxt')
                                            onChangeForm(opt.dni, 'destinoDNI')
                                        }}
                                    />
                                </Box>
                            ):(
                                <Box sx={{width: '100%',mt:3}}>
                                    <SelectGrupos
                                        id="selectGrupo"
                                        allGroup
                                        value={formAll.tipoId_uxt.texto}
                                        isInvalid={formAll.tipoId_uxt.estadoErr}
                                        onChange={(opt) => {
                                            onChangeForm(opt.value, 'tipoId_uxt')
                                            onChangeForm(opt.value, 'destinoDNI')
                                        }}
                                    />
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{background: '#fff !important',p:2,boxShadow:2}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center'}}>
                                    <Box sx={{display: 'flex'}}>
                                        <Typography sx={{color: '#000', fontSize:15}}>Contactos</Typography>
                                        {formAll.arrContactos.texto?.length > 0 && (
                                            <Chip label={formAll.arrContactos.texto?.length} size="small" sx={{background: '#9d9ea4',width:23,height:23,ml:0.5}} />
                                        )}
                                    </Box>
                                    {formAll.arrContactos.texto?.length < 1 && (
                                        <ButtonBasic
                                            textButton='Agregar contacto'
                                            variant="wk"
                                            color='nextWhite'
                                            sx={{width: 170,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                                            iconStart={<AddIcon sx={{color: '#0364ff'}} />}
                                            onClick={()=>onOpenModalContacto()}
                                        />
                                    )}
                                </Box>
                                <Box sx={{marginTop: 2}}>
                                    {formAll?.arrContactos?.texto?.length > 0 ?(
                                        <Grid container spacing={1}>
                                            {formAll?.arrContactos?.texto.map((arCont)=>(
                                                <Grid item xs={12} md={4} key={arCont?.cuentaContratoId_txt || arCont?.id_t}>
                                                    <Card sx={{background: '#fff !important',boxShadow: 3,p:1}}>
                                                        <Box sx={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
                                                            <Avatar sx={{background: '#9d9ea4'}} />
                                                            <Box>
                                                                <Typography sx={{textAlign: 'start',color: '#000',fontSize: 10}}>{arCont?.nombre || '-'}</Typography>
                                                                <Typography sx={{textAlign: 'start',color: '#000'}}>{`${arCont?.cuentaContratoId_txt ? 'Servicio' : 'Pre-Servicio'}: ${arCont?.cuentaContratoId_txt || arCont?.id_t}`}</Typography>
                                                            </Box>
                                                            <IconButton
                                                                sx={{boxShadow: 3,color: '#000', '&:hover':{background: '#fce7e7',color: '#e01010'}}}
                                                                onClick={()=>onDeleteContacto(arCont)}
                                                            >
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ):(
                                        <Box sx={{background: '#f6f8fc',minHeight: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Typography sx={{color: '#808b9e', fontWeight: 'bold', textAlign: 'center'}}>Aun no se agregaron contactos</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={formAll.titulo_tk.texto}
                                onChange={(e)=>onChangeForm(e.target.value, 'titulo_tk')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Motivo del Ticket</Typography>
                            <SelectMotivoTicket
                                id="motivo_tk"
                                value={formAll.motivo_tk.texto}
                                emp='nubicom'
                                isInvalid={formAll.motivo_tk.estadoErr}
                                onChange={(opt) => {
                                    onChangeForm(opt.value, 'motivo_tk');
                                    onChangeForm('GRUPO', 'tipo_uxt');
                                    onChangeForm(opt.descripcion || '', 'cuerpo_tk');
                                    onChangeForm(opt.destino, 'tipoId_uxt');
                                    onChangeForm(opt.tipoTicket, 'tipoTicketId_tk');
                                    onChangeForm(opt.subTipoTicket, 'subTipoTicketId_tk');
                                    onChangeForm(opt.nivelAdm, 'nivelAdmTkId_tk');
                                    onChangeForm(opt.motivoBaja, 'motivoBajaTk_tk');
                                    cambiarFecha(opt.dayResolucion)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={0.5}>
                            <Typography sx={{color: '#000',fontSize:12}}>Prioridad</Typography>
                            <SelectPrioridadTkNubicom
                                value={formAll.prioridad_tk.texto}
                                isInvalid={formAll.prioridad_tk.estadoErr}
                                onChange={(opt) => onChangeForm(opt.value,'prioridad_tk')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography sx={{color: '#000',fontSize:12}}>Fecha de vencimiento</Typography>
                            <DateSingle
                                value={formAll.fechavencimiento_tk.texto}
                                onChange={(fecha)=> onChangeForm(fecha, 'fechavencimiento_tk')}
                                minDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputEditQuill
                                template={formAll.cuerpo_tk.texto}
                                onChangeText={(text)=>onChangeForm(text, 'cuerpo_tk')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={formAll.arrContactos.texto?.length === 0 || !formAll.tipoTicketId_tk.texto || !formAll.subTipoTicketId_tk.texto || !formAll.tipoId_uxt.texto}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalCreatedTkToPtk;