import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic } from '../../components';

const ModalVerReporte = ({show, hideModal, item}) => {
    const handleClose = () => hideModal();
    const [rangoFecha, setrangoFecha] = useState([])
    useEffect(() => {
        if(item?.rangoFecha_lr){
            setrangoFecha(JSON.parse(item?.rangoFecha_lr))
        }
    }, [])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Rango de Fechas
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000',textAlign: 'center', fontWeight: 'bold'}}>
                                {item?.['report.titulo_rts']}
                            </Typography>
                        </Grid>
                        {rangoFecha?.length > 0 && (
                            rangoFecha.map((itRF)=>{
                                const fechaIniSplit = itRF.fechaIni.split("-");
                                const fechaFinSplit = itRF.fechaFin.split("-");
                                const fechaIni = `${fechaIniSplit[2]}-${fechaIniSplit[1]}-${fechaIniSplit[0]}`
                                const fechaFin = `${fechaFinSplit[2]}-${fechaFinSplit[1]}-${fechaFinSplit[0]}`
                                return(
                                    <Grid item xs={12} md={3}>
                                        <Card sx={{ background: '#fff !important' ,p:1,borderRadius:1,boxShadow:3,mt:1,display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection: 'column'}}>
                                            <Typography sx={{color: '#000',textAlign: 'center',fontWeight: 'bold'}}>
                                                {itRF?.itemsCampo?.label}
                                            </Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    Inicio: <Box sx={{fontWeight: 'normal', display: 'inline',marginLeft: 1}}>{fechaIni}</Box>
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:0.5}}>
                                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                                                    Fin: <Box sx={{fontWeight: 'normal', display: 'inline',marginLeft: 2.5}}>{fechaFin}</Box>
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </Grid>
                                )
                            })
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{minWidth: 250}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalVerReporte