import { useEffect, useState } from "react"
import { Box, Grid, IconButton, Tooltip, Typography, tooltipClasses } from "@mui/material"
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { useNavigate } from "react-router-dom";
import KmzService from "../../services/kmz.service";
import ModalCargarKmz from "../../modals/kmz/ModalCargarKmz";
import FechaFunction from "../../function/FechaFunction";
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import AuxiliarFunction from "../../function/AuxiliarFunction";
import DownloadFile from "../../services/downloadFile.service";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const HistorialKMZScreen = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate()
    const actualizar = async() =>{
        await KmzService.obtenerHistorialKMZRF().then((resp)=>{
            setItems(resp);
        }).catch(()=>{
            navigate('/')
        })
    }
    const descargarKMZ = async(it) =>{
        const buscador = items.find((bc)=>bc.id_ad === it);
        if(buscador && buscador?.pathFile_ad){
            await AuxiliarFunction.downloadFile(DownloadFile.fileNubi({id:`kmz/${buscador?.pathFile_ad}`}),`${FechaFunction.format(buscador?.createdAt,'dd-MM-yyyy')}${buscador?.name_ad}.kmz`);
        }
    }
    const columns = [
        {
            name: "id_ad",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: 'createdAt',
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value) return FechaFunction.format(value,'dd-MM-yyyy');
                    return ''
                }
            }
        },
        {
            name: "name_ad",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "autor",
            label: "Responsable",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <LightTooltip  title="Descargar KMZ" placement="top">
                                <IconButton color='info' aria-label="edit" onClick={()=>descargarKMZ(tableMeta.rowData[0])}>
                                    <DownloadIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () => actualizar();

    const onOpenEditar = () => {
        showModal(ModalCargarKmz, { onGuardar: onGuardar});
    };

    useEffect(() => {
        actualizar();
        document.title = `Historial KMZ`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Historial KMZ</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="Subir KMZ"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default HistorialKMZScreen