import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const MenuItemsABM = ({
  dataId,
  onEditar,
  onEliminar,
  onVer,
  titleEliminar
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpenVer = () => {
    onVer(dataId)
    setIsOpen(false)
  }
  const onOpenEdit = () => {
    onEditar(dataId)
    setIsOpen(false)
  }
  const onOpenDeleted = () => {
    onEliminar(dataId)
    setIsOpen(false)
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{boxShadow: 3}}>
        <MoreVertOutlinedIcon sx={{color: '#000'}} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%', background: '#fff !important' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {onVer && (
          <MenuItem sx={{ color: '#0364ff' }} onClick={()=>onOpenVer()}>
            <ListItemIcon>
              <RemoveRedEyeIcon sx={{color: '#0364ff'}} />
            </ListItemIcon>
            <ListItemText primary="Ver" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {onEditar && (
          <MenuItem sx={{ color: '#0364ff' }} onClick={()=>onOpenEdit()}>
            <ListItemIcon>
              <ModeEditOutlineOutlinedIcon sx={{color: '#0364ff'}} />
            </ListItemIcon>
            <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {onEliminar && (
          <MenuItem sx={{ color: 'red' }} onClick={()=>onOpenDeleted()}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon sx={{color:'red'}} />
            </ListItemIcon>
            <ListItemText primary="Eliminar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default MenuItemsABM