import { useState, useEffect } from 'react';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardUploadFile, InputDropZone, InputDropZoneComment } from '../../components';
import TratoService from '../../services/tratos.service';

const ModalAdjContratoConstancia = ({show, hideModal, ticket,idPreServicio, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [contratoImg, setContratoImg] = useState(null);
    const [constanciaImg, setConstanciaImg] = useState(null);
    const [files, setFiles] = useState([]);
    const [filesCompress, setFilesCompress] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png'
    const onCompressImg = async(imgx,contrato = null,constancia = null) =>{
        const nombreCortado = imgx.name.split('.');
        let extension = nombreCortado[ nombreCortado.length - 1 ];
        extension = extension.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx.id
                    result.preview=imgx.preview
                    setIsLoading(false);
                    if(contrato){
                        setContratoImg(result)
                    }else if(constancia){
                        setConstanciaImg(result)
                    }else{
                        setFilesCompress(old => [...old,result]);
                    }
                }
            })
        }
    }

    const handleClose = () => hideModal();

    const onSubmit = async() => {
        setIsLoading(true)
        const form = {
            ticketId: ticket?.id_tk,
            idPreServicio,
            constanciaImg,
            contratoImg,
            filesCompress
        };
        await TratoService.confirmarDocumentacionCambTitular(form).then(()=>{
            if(onGuardar){
                onGuardar();
            }
            setIsLoading(false)
            handleClose();
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    useEffect(() => {
        if(constanciaImg && constanciaImg?.length > 0){
            onCompressImg(constanciaImg[0],false,true)
        }
    }, [constanciaImg])
    useEffect(() => {
        if(contratoImg && contratoImg?.length > 0){
            onCompressImg(contratoImg[0],true)
        }
    }, [contratoImg])
    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])
    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Documentacion Cambio de Titular
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent:'center',alignItems:'center',flexDirection: 'column'}}>
                            <Typography sx={{color: '#000'}}>Contrato(1ra Hoja)</Typography>
                            <Box sx={{width: 172}}>
                                <InputDropZone
                                    id="contratoImg"
                                    defaultImage={contratoImg}
                                    onChange={setContratoImg}
                                    setFoto={setContratoImg}
                                    isDeletedNow={!contratoImg}
                                    backgroundImage="fileUpload"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent:'center',alignItems:'center',flexDirection: 'column'}}>
                        <Typography sx={{color: '#000'}}>Constancia de recepción de equipos</Typography>
                            <Box sx={{width: 172}}>
                                <InputDropZone
                                    id="constanciaImg"
                                    defaultImage={constanciaImg}
                                    onChange={setConstanciaImg}
                                    setFoto={setConstanciaImg}
                                    isDeletedNow={!constanciaImg}
                                    backgroundImage="fileUpload"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container mt={1}>
                                { filesCompress?.length > 0 &&(
                                    <>
                                        <Grid item xs={12} mt={1}>
                                            <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center'}}>Imagenes Adjuntas</Typography>
                                        </Grid>
                                        {filesCompress.map((fil)=>(
                                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            { filesCompress?.length < 5 &&(
                                <InputDropZoneComment
                                    id="otImg"
                                    onChange={(e)=>setFiles((old)=>[...old,...e])}
                                    setFoto={setFiles}
                                    accept={acceptInputFile}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!idPreServicio || !constanciaImg || !contratoImg}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalAdjContratoConstancia