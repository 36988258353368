import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Button,
    Typography,
    Box
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { startLogin } from '../../store/actions/authActions';
import Cookies from 'js-cookie'
import { useEffect } from 'react';


const FormLogin = () => {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate()
    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('El coreo no es valido.').required('El Correo es requerido.'),
        password: Yup.string().required('La contraseña es requerida')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
        dispatch(startLogin(values?.email, values?.password,rememberMe))
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    useEffect(() => {
        const cookie = Cookies.get('auth');
        if(cookie){
            const usuarioRemember = JSON.parse(cookie)
            if(usuarioRemember){
                setFieldValue('email',usuarioRemember?.email)
                setFieldValue('password',usuarioRemember?.pass)
                if(usuarioRemember?.rememberMe){
                    setRememberMe(true)
                }
            }
        }
    }, [])

    return (
        <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <Stack spacing={0}>
                    <Typography sx={{color: '#000',textAlign: 'left',fontWeight: 'bold'}} variant="h2" >Bienvenido</Typography>
                    <Typography sx={{color: 'gray',textAlign: 'left',marginBottom:7}} variant="h5" >Ingresa tus credenciales</Typography>
                    <Typography
                        variant='h6'
                        color='#000'
                        sx={{ fontSize:18 }}
                    >
                        Email
                    </Typography>
                    <TextField
                        fullWidth
                        variant='standard'
                        autoComplete="username"
                        type="email"
                        focused
                        InputProps={{ style:{ fontWeight: 'bold',fontSize:15,color: '#000' } }}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        sx={{
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "#000"
                            },
                        }}
                        helperText={touched.email && errors.email}
                    />
                    <Typography
                        variant='h6'
                        color='#000'
                        sx={{ mt: 2,fontSize:18 }}
                    >
                        Contraseña
                    </Typography>
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        variant='standard'
                        focused
                        type={showPassword ? 'text' : 'password'}
                        {...getFieldProps('password')}
                        InputProps={{
                            style:{fontWeight: 'bold',fontSize:15,color: '#000',borderBottomColor: 'red'},
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end" sx={{color: '#000'}}>
                                        {!showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "#000"
                            },
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onClick={()=>setRememberMe(!rememberMe)}
                                checked={rememberMe}
                                sx={{
                                    color: '#000',
                                    '&.Mui-checked': {
                                        color: '#000',
                                    },
                                }}
                            />
                        }
                        label="Recordar usuario"
                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                        sx={{marginTop:2}}
                    />
                    <Typography
                        sx={{color: '#0247B5',marginTop:2,fontWeight: 'bold',cursor: 'pointer'}}
                        onClick={()=>navigate('/recover/password')}
                    >
                        Olvide mi contraseña
                    </Typography>
                </Box>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                    <Button
                        fullWidth
                        type="submit"
                        variant='contained'
                        sx={{
                            background: '#0364FF',
                            borderRadius: 20,
                            color: '#fff',
                            '&:hover':{
                                background: '#0247B5'
                            }
                        }}
                    >
                        INICIAR SESION
                    </Button>
                </Stack>
                <Typography
                    sx={{fontSize: 13, color: '#000', display: 'flex', justifyContent: 'center', marginTop:7}}
                >
                    Al utilizar Zeta-Crm usted acepta nuestros
                </Typography>
                <Link
                    href="#"
                    variant="body2"
                    color='inherit'
                    sx={{fontSize: 13, color: '#000', display: 'flex', justifyContent: 'center',width: {xs: '100%',md: 360}}}
                >
                    Términos y condiciones de uso
                </Link>
            </Form>
        </FormikProvider>
    );
}

export default FormLogin