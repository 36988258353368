import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material"
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { ButtonBasic, InputImgDocument, TextEstadoServicio } from "../../../components";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { connect } from "react-redux";
import ContactosAction from '../../../store/actions/contactos';
import { useEffect,useState } from "react";
import InfContactoService from "../../../services/infContacto.service";
import ContactosService from "../../../services/contactos.service";
import FechaFunction from "../../../function/FechaFunction";
import { useNavigate } from "react-router-dom";

const FormPaso2Screen = ({
  contacto,
  contrato,
  previousStep,
}) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tipoPersona, setTipoPersona] = useState('FISICA');

  const [imgPreviewDF, setImgPreviewDF] = useState(null);
  const [defaultImageDF, setDefaultImageDF] = useState(null);

  const [imgPreviewDD, setImgPreviewDD] = useState(null);
  const [defaultImageDD, setDefaultImageDD] = useState(null);

  const [imgPreviewConstAfip, setImgPreviewConstAfip] = useState(null);
  const [defaultImageConstAfip, setDefaultImageConstAfip] = useState(null);

  const [imgPreviewContratoSocialEstatuto, setImgPreviewContratoSocialEstatuto] = useState(null);
  const [defaultImageContratoSocialEstatuto, setDefaultImageContratoSocialEstatuto] = useState(null);

  /* const [imgPreviewDesigActaActualizada, setImgPreviewDesigActaActualizada] = useState(null);
  const [defaultImageDesigActaActualizada, setDefaultImageDesigActaActualizada] = useState(null); */

  const [imgPreviewPoderApoderado, setImgPreviewPoderApoderado] = useState(null);
  const [defaultImagePoderApoderado, setDefaultImagePoderApoderado] = useState(null);

  const [imgPreviewDniFrenteApoderado, setImgPreviewDniFrenteApoderado] = useState(null);
  const [defaultImageDniFrenteApoderado, setDefaultImageDniFrenteApoderado] = useState(null);

  const [imgPreviewDniDorsoApoderado, setImgPreviewDniDorsoApoderado] = useState(null);
  const [defaultImageDniDorsoApoderado, setDefaultImageDniDorsoApoderado] = useState(null);

  const [nextPageImg, setNextPageImg] = useState(false);
  const [contactoImg, setContactoImg] = useState(null);
  const acceptInputFile = 'image/jpeg, image/png, image/jpg, application/pdf';

  const onSubmit = async() => {
    setIsLoading(true);
    const form ={
      id_c: contacto?.idNuevoContacto,
      cuenta: contacto?.cuentaNueva,
      dniFrente: imgPreviewDF,
      dniDorso: imgPreviewDD,
      constanciaAfip: imgPreviewConstAfip,
      contratoSocualEstatuto: imgPreviewContratoSocialEstatuto,
      /* desigActaActualizada: imgPreviewDesigActaActualizada, */
      poderApoderado: imgPreviewPoderApoderado,
      dniFrenteApoderado: imgPreviewDniFrenteApoderado,
      dniDorsoApoderado: imgPreviewDniDorsoApoderado,
      type: 'nubicom',
      tipoCuit_icon: tipoPersona
    }
    if(
      (imgPreviewDF && imgPreviewDD) ||
      ( imgPreviewConstAfip
        || imgPreviewContratoSocialEstatuto
        /* || imgPreviewDesigActaActualizada */
        || imgPreviewPoderApoderado
        || imgPreviewDniFrenteApoderado
        || imgPreviewDniDorsoApoderado
      )
    ){
      await ContactosService.registerInfo(form).then(()=>{
          setIsLoading(false);
          if(contacto?.nroTicket){
            navigate(`/ticket/${contacto?.nroTicket}/nubicom`);
          }else{
            navigate('/tickets');
          }
      })
      .catch(()=>{
          setIsLoading(false);
      })
    }else{
      setIsLoading(false);
      if(contacto?.nroTicket){
        navigate(`/ticket/${contacto?.nroTicket}/nubicom`);
      }else{
        navigate('/tickets');
      }
    }
  };
  useEffect(() => {
    setDefaultImageDF(null);
    setDefaultImageDD(null);
    setDefaultImageConstAfip(null);
    setDefaultImageContratoSocialEstatuto(null);
    /* setDefaultImageDesigActaActualizada(null); */
    setDefaultImagePoderApoderado(null);
    setDefaultImageDniFrenteApoderado(null);
    setDefaultImageDniDorsoApoderado(null);
    if(contacto?.cuentaNueva){
      InfContactoService.getAll({cuentaId: contacto?.cuentaNueva}).then((repp)=>{
          setContactoImg(repp)
      })
    }else if(contacto?.idNuevoContacto){
      InfContactoService.getAll({contactoId: contacto?.idNuevoContacto}).then((repp)=>{
          setContactoImg(repp)
      })
    }
  }, [contacto])
  useEffect(() => {
      if(contactoImg && contactoImg?.pathFrente_icon){
          setDefaultImageDF(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathFrente_icon}&token=${token}`);
      }
      if(contactoImg && contactoImg?.pathDorso_icon){
          setDefaultImageDD(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDorso_icon}&token=${token}`);
      }
      if(contactoImg && contactoImg?.pathconstanciaAfip_icon){
        const extension = contactoImg?.pathconstanciaAfip_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
            setDefaultImageConstAfip('/img/pdf.png');
        }else{
            setDefaultImageConstAfip(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathconstanciaAfip_icon}&token=${token}`);
        }
      }
      if(contactoImg && contactoImg?.pathContratoSocialEstatuto_icon){
        const extension = contactoImg?.pathContratoSocialEstatuto_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
          setDefaultImageContratoSocialEstatuto('/img/pdf.png');
        }else{
          setDefaultImageContratoSocialEstatuto(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathContratoSocialEstatuto_icon}&token=${token}`);
        }
      }
      /* if(contactoImg && contactoImg?.pathDesigActaActualizada_icon){
        const extension = contactoImg?.pathDesigActaActualizada_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
          setDefaultImageDesigActaActualizada('/img/pdf.png');
        }else{
          setDefaultImageDesigActaActualizada(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDesigActaActualizada_icon}&token=${token}`);
        }
      } */
      if(contactoImg && contactoImg?.pathPoderApoderado_icon){
        const extension = contactoImg?.pathPoderApoderado_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
          setDefaultImagePoderApoderado('/img/pdf.png');
        }else{
          setDefaultImagePoderApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathPoderApoderado_icon}&token=${token}`);
        }
      }
      if(contactoImg && contactoImg?.pathDniFrenteApoderado_icon){
        const extension = contactoImg?.pathDniFrenteApoderado_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
          setDefaultImageDniFrenteApoderado('/img/pdf.png');
        }else{
          setDefaultImageDniFrenteApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDniFrenteApoderado_icon}&token=${token}`);
        }
      }
      if(contactoImg && contactoImg?.pathDniDorsoApoderado_icon){
        const extension = contactoImg?.pathDniDorsoApoderado_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
          setDefaultImageDniDorsoApoderado('/img/pdf.png');
        }else{
          setDefaultImageDniDorsoApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDniDorsoApoderado_icon}&token=${token}`);
        }
      }
      if(contactoImg && contactoImg?.tipoCuit_icon){
        setTipoPersona(contactoImg?.tipoCuit_icon)
      }
  }, [contactoImg])

  useEffect(() => {
    if(contacto?.iva_c === 'C'){
        if((defaultImageDF && defaultImageDD) || (imgPreviewDF && imgPreviewDD) ){
            setNextPageImg(true)
        }else{
            setNextPageImg(false)
        }
    }else{
      if(contacto?.iva_c !== 'C'){
        if(tipoPersona === 'JURIDICA'){
          if( (defaultImageConstAfip || imgPreviewConstAfip)
            && (defaultImageContratoSocialEstatuto || imgPreviewContratoSocialEstatuto)
            /* && (defaultImageDesigActaActualizada || imgPreviewDesigActaActualizada) */
            && (defaultImagePoderApoderado || imgPreviewPoderApoderado)
            && (defaultImageDniFrenteApoderado || imgPreviewDniFrenteApoderado)
            && (defaultImageDniDorsoApoderado || imgPreviewDniDorsoApoderado)
          ){
              setNextPageImg(true)
          }else{
            setNextPageImg(false)
          }
        }else if(tipoPersona === 'FISICA'){
          if( (defaultImageConstAfip || imgPreviewConstAfip)){
              setNextPageImg(true)
          }else{
            setNextPageImg(false)
          }
        }else{
          setNextPageImg(false)
        }
      }
    }
  }, [
    contacto,
    defaultImageDF,
    imgPreviewDF,
    defaultImageDD,
    imgPreviewDD,
    defaultImageConstAfip,
    imgPreviewConstAfip,
    defaultImageContratoSocialEstatuto,
    imgPreviewContratoSocialEstatuto,
    /* defaultImageDesigActaActualizada, */
    /* imgPreviewDesigActaActualizada, */
    defaultImagePoderApoderado,
    imgPreviewPoderApoderado,
    defaultImageDniFrenteApoderado,
    imgPreviewDniFrenteApoderado,
    defaultImageDniDorsoApoderado,
    imgPreviewDniDorsoApoderado,
    tipoPersona
  ])
  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2}>
        <Grid xs={12} md={12} lg={5.2}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12}>
                <Box display='flex' pl={2} pt={1}>
                    <SensorOccupiedIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>
                      { contacto?.iva_c === 'C' ? 'Documento de identidad' : 'Documentación requerida'}
                    </Typography>
                </Box>
                <Box display='flex' pl={2} pt={1}>
                  {contacto?.iva_c === 'C' ?(
                    <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                      Adjuntar DNI de ambos lados en los formatos permitidos: jpeg,png
                    </Typography>
                  ):(
                    <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                      Adjuntar los archivos en los formatos permitidos: jpeg, png, pdf
                    </Typography>
                  )}
                </Box>
              </Grid>
              {contacto?.iva_c === 'C' ?(
                <>
                  <Grid item xs={12}>
                  <Typography sx={{color: '#000', fontWeight: 'bold',paddingLeft: 2,marginTop:0.5,fontSize: 17}}>Frente</Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="dniFrente"
                        id="dniF"
                        key="dniF"
                        defaultImage={defaultImageDF}
                        onChange={setImgPreviewDF}
                        setFoto={setImgPreviewDF}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography sx={{color: '#000', fontWeight: 'bold',paddingLeft: 2,marginTop:0.5,fontSize: 17}}>Dorso</Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="dniDorso"
                        id="dniD"
                        key="dniD"
                        defaultImage={defaultImageDD}
                        onChange={setImgPreviewDD}
                        setFoto={setImgPreviewDD}
                      />
                    </Box>
                  </Grid>
                </>
              ):(
                <>
                  <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Tipo de Persona
                    </Typography>
                    <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={tipoPersona}
                        onChange={(e) => setTipoPersona(e.target.value)}
                        row
                      >
                        <FormControlLabel
                            value="FISICA"
                            control={ <Radio sx={{color: '#000'}} />}
                            label="Persona Física"
                            sx={{color: '#000'}}
                        />
                        <FormControlLabel
                            value="JURIDICA"
                            control={ <Radio sx={{color: '#000'}} /> }
                            label="Persona Jurídica"
                            sx={{color: '#000'}}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Constacia de AFIP
                    </Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="documentImg"
                        id="documentImg"
                        key="documentImg"
                        defaultImage={defaultImageConstAfip}
                        onChange={setImgPreviewConstAfip}
                        setFoto={setImgPreviewConstAfip}
                        accept={acceptInputFile}
                      />
                    </Box>
                  </Grid>
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        Contrato Social-Estatuto
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg2"
                          key="documentImg2"
                          defaultImage={defaultImageContratoSocialEstatuto}
                          onChange={setImgPreviewContratoSocialEstatuto}
                          setFoto={setImgPreviewContratoSocialEstatuto}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Designacion Acta Actualizada
                    </Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="documentImg"
                        id="documentImg3"
                        key="documentImg3"
                        defaultImage={defaultImageDesigActaActualizada}
                        onChange={setImgPreviewDesigActaActualizada}
                        setFoto={setImgPreviewDesigActaActualizada}
                        accept={acceptInputFile}
                      />
                    </Box>
                  </Grid> */}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        Poder del Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg4"
                          key="documentImg4"
                          defaultImage={defaultImagePoderApoderado}
                          onChange={setImgPreviewPoderApoderado}
                          setFoto={setImgPreviewPoderApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        DNI-Frente-Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg5"
                          key="documentImg5"
                          defaultImage={defaultImageDniFrenteApoderado}
                          onChange={setImgPreviewDniFrenteApoderado}
                          setFoto={setImgPreviewDniFrenteApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        DNI-Dorso-Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg6"
                          key="documentImg6"
                          defaultImage={defaultImageDniDorsoApoderado}
                          onChange={setImgPreviewDniDorsoApoderado}
                          setFoto={setImgPreviewDniDorsoApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} md={12} lg={6.6} ml={1} mb={5}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12}>
                <Box p={2}>
                  <Box display='flex' alignItems='center' justifyContent='center' pt={1}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',mr:1}} variant='h5'>{`Cuenta: ${contrato?.cuenta}`}</Typography>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',mr:1}} variant='h5'>{contrato?.descripcion}</Typography>
                  </Box>
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:1,fontSize: 14}}>{`Servicio #${contrato?.numero}`}</Typography>
                  <Card sx={{background: '#fff !important'}}>
                    <Box
                        sx={{ background: contrato?.estado?.descripcion === 'Servicio Habilitado' ? '#e6f8f2' : '#fce7e7', p: 2, display: 'flex',alignItems: 'center'}}
                    >
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',mr:2}} variant='h5'>{contrato?.articulo?.nombre}</Typography>
                        <TextEstadoServicio descripcion={contrato?.estado?.descripcion} />
                    </Box>
                    <Grid container>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Tipo:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.tipo}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Precio:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{`${contrato?.articulo?.precio} ${contrato?.articulo?.moneda}`}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Cantidad:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.articulo?.cantidad}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Vigencia:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.articulo?.vigencia === 'S' ? 'SI' : 'NO'}</Box></Typography>
                            </Box>
                        </Grid>
                        {contrato?.articulo?.vigencia_desde && (
                            <Grid xs={12} sm={4} pl={2}>
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Desde:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(contrato?.articulo?.vigencia_desde,'dd/MM/yyyy')}</Box></Typography>
                                </Box>
                            </Grid>
                        )}
                        {contrato?.articulo?.vigencia_hasta && (
                            <Grid xs={12} sm={4} pl={2}>
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Hasta:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(contrato?.articulo?.vigencia_hasta,'dd/MM/yyyy')}</Box></Typography>
                                </Box>
                            </Grid>
                        )}
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Condición pago:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.condicion_pago?.descripcion}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Contratista:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.contratista?.descripcion}</Box></Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={4} pl={2}>
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{contrato?.ubicacion?.domicilio}</Box></Typography>
                            </Box>
                        </Grid>
                        {contrato?.ubicacion?.latitud && contrato?.ubicacion?.longitud && (
                            <Grid xs={12} sm={4} pl={2}>
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>
                                        {`${contrato?.ubicacion?.latitud}, ${contrato?.ubicacion?.longitud}`}
                                    </Box></Typography>
                                </Box>
                            </Grid>
                        )}
                        {contrato?.estado?.alta && (
                            <Grid xs={12} sm={4} pl={2}>
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Fecha de Alta:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(contrato?.estado?.alta,'dd/MM/yyyy')}</Box></Typography>
                                </Box>
                            </Grid>
                        )}
                        {contrato?.estado?.modificacion && (
                            <Grid xs={12} sm={4} pl={2} mb={2}>
                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Ultima Modificación:<Box sx={{display: 'inline', fontWeight: 'normal',ml: 1}}>{FechaFunction.format(contrato?.estado?.modificacion,'dd/MM/yyyy')}</Box></Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          isLoading={isLoading}
          color='prevStep'
        />
        <ButtonBasic
          textButton='Finalizar'
          onClick={()=>onSubmit()}
          variant='wk'
          color='nextStep'
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso2Screen);