export let CONTACTOS_CURRENT_CORPORATIVO = 'CONTACTOS_CURRENT_CORPORATIVO';
export let CONTACTOS_CONTRATO_CORPORATIVO = 'CONTACTOS_CONTRATO_CORPORATIVO';
export let CONTACTOS_CREAR_CONTRATO_CORPORATIVO = 'CONTACTOS_CREAR_CONTRATO_CORPORATIVO';

const iniciarContactoCorporativo = (data = null) => ({
    type: CONTACTOS_CURRENT_CORPORATIVO,
    payload: data
});

const iniciarContratoCorporativo = (data = null) => ({
    type: CONTACTOS_CONTRATO_CORPORATIVO,
    payload: data
});

const habilitarCrearContratoCorporativo = (data = false) => ({
    type: CONTACTOS_CREAR_CONTRATO_CORPORATIVO,
    payload: data
});

export default {
    habilitarCrearContratoCorporativo,
    iniciarContactoCorporativo,
    iniciarContratoCorporativo,
}