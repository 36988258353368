import axios from 'axios';
const base = 'preticket';

const getAll = (query = null) => axios.get(base, { params: query });

function correoInvalido(data) {
    return axios.put([base, 'cancel' , data.id_ptke].join('/'), data);
}

function convertPtkToTk(data) {
    return axios.put([base, 'register' , data.id_ptke].join('/'), data);
}

function searchInfoServicio(data) {
    return axios.post('preticket/search/info', data);
}

function vincularPreTkToServicio(data) {
    return axios.post('preticket/vincular/pretk', data);
}

function restablecerPreTk(data) {
    return axios.post('preticket/reset/pretk', data);
}

const PreTicketsService = {
    getAll,
    correoInvalido,
    convertPtkToTk,
    searchInfoServicio,
    vincularPreTkToServicio,
    restablecerPreTk
};
export default PreTicketsService;
