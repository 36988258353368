import { Box, Card, Divider, Grid, TextField, Typography } from '@mui/material'
import { useState,useEffect, useMemo } from 'react'
import { connect } from 'react-redux';
import { ButtonBasic, SelectFilterTicket, SelectFilterStageTicket, CardViewTicket, SelectGrupos, SelectGruposUser, SelectEstadoVencimiento, SelectSubTipoTicket } from '../../components'
import TablaTicketService from '../../services/tablaTicket.service';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import usePermisos from '../../hooks/usePermisos';
import ModalNewTickets from '../../modals/ticket/ModalNewTickets';
import { showModal } from 'react-redux-modal-provider';

const TablaTicketScreen = ({
    ticketrefresh
}) => {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const [filtroGeneral, setFiltroGeneral] = useState('todos');
    const [filtroEstadoTk, setFiltroEstadoTk] = useState('todos');
    const [ticketsIndv, setTicketsIndv] = useState([]);
    const [ticketsGrupo, setTicketsGrupo] = useState([]);
    const [ticketsCreados, setTicketsCreados] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [ticketsView, setTicketsView] = useState([]);
    const [tkCreatedPage, setTkCreatedPage] = useState(0)
    const [hasMoreCreated, setHasMoreCreated] = useState(true)
    const [textSearch, setTextSearch] = useState('')
    const [selectGrupoId, setSelectGrupoId] = useState(null);
    const [selectSubTipoId, setSelectSubTipoId] = useState(null);
    const [selectSubTipoArr, setSelectSubTipoArr] = useState([]);
    const [selectVencido, setSelectVencido] = useState(null);

    const parseData = (arrOriginal,tipo,creados = false) => {
        const parseFinal = []
        if(Array.isArray(arrOriginal) && arrOriginal?.length > 0){
            if(creados){
                arrOriginal.forEach((descomp)=>{
                    parseFinal.push({
                        ticket: descomp,
                        tipoTicket: tipo,
                        id_uxt: uuidv4(),
                        nombreCompleto: `#${descomp?.id_tk} - ${descomp?.titulo_tk}`
                    })
                })
            }else{
                arrOriginal.forEach((descomp)=>{
                    parseFinal.push({
                        ...descomp,
                        id_uxt: uuidv4(),
                        nombreCompleto: `#${descomp?.ticket?.id_tk} - ${descomp?.ticket?.titulo_tk}`,
                        tipoTicket: tipo
                    })
                })
            }
            return parseFinal;
        }
    }
    const actualizarIndv = async() =>{
        await TablaTicketService.getAll({ consulta: 'individual' })
        .then((response)=>{
            const parse = parseData(response,'individual')
            setTicketsIndv(parse);
        })
    }
    const actualizarGrupo = async() =>{
        await TablaTicketService.getAll({ consulta: 'grupo' })
        .then((response)=>{
            const parse = parseData(response,'grupal')
            setTicketsGrupo(parse);
        })
    }
    const actualizarCreated = async(modal) =>{
        setIsLoading(true);
        await TablaTicketService.getAll({
            consulta: 'creados',
            page: tkCreatedPage
        })
        .then((response)=>{
            setIsLoading(false)
            if(!modal){
                const parse = parseData(response.content,'creado',true)
                setTicketsCreados((prev) => prev?.concat(parse));
                setHasMoreCreated((tkCreatedPage+1) < response.totalPages);
            }else{
                const parse = parseData(response.content,'creado',true)
                setTicketsCreados(parse);
                setTkCreatedPage(0)
                setHasMoreCreated(response.totalPages > 1)
            }
        })
        .catch(()=>{
            setIsLoading(false)
        })
    }
    const onClickNewTicket = () => {
        showModal(ModalNewTickets,{ onGuardar: actualizarCreated, permisos:permisos });
    }
    useEffect(() => {
        const tkInd = ticketsIndv || [];
        const tkGrup = ticketsGrupo || [];
        const tkCreados = ticketsCreados || [];
        const totalTk = tkInd?.concat(tkGrup?.concat(tkCreados))
        let ticketViewFilter = totalTk;
        if(filtroGeneral !== 'todos'){
            const filtroAct = ticketViewFilter.filter((tk)=> tk.tipoTicket === filtroGeneral)
            if(filtroGeneral === 'grupal' && selectGrupoId){
                const filtroActGrupoId = filtroAct.filter((tk)=> tk?.ticket?.idGrupo === selectGrupoId)
                ticketViewFilter = filtroActGrupoId;
            }else{
                setSelectGrupoId(null)
                ticketViewFilter = filtroAct;
            }
        }else{
            const arrIdTk = [];
            const arrFilterTk = [];
            totalTk.forEach((tk)=>{
                if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                    arrIdTk.push(tk?.ticket?.id_tk)
                    arrFilterTk.push(tk)
                }
            })
            ticketViewFilter = arrFilterTk;
        }
        if(filtroEstadoTk !== 'todos'){
            if(filtroEstadoTk === 'ABIERTO'){
                const filtroAct = ticketViewFilter?.filter((tk)=> !tk?.ticket?.cerrado_tk)
                const arrIdTk = [];
                const arrFilterTk = [];
                filtroAct.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = filtroAct;
            }else if(filtroEstadoTk === 'CERRADO'){
                const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.cerrado_tk)
                const arrIdTk = [];
                const arrFilterTk = [];
                filtroAct.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = filtroAct;
            }else{
                const filtroAct = ticketViewFilter.filter((tk)=> tk?.ticket?.estado_tk === filtroEstadoTk)
                ticketViewFilter = filtroAct;
            }
        }else{
            const arrIdTk = [];
            const arrFilterTk = [];
            totalTk.forEach((tk)=>{
                if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                    arrIdTk.push(tk?.ticket?.id_tk)
                    arrFilterTk.push(tk)
                }
            })
            ticketViewFilter = arrFilterTk;
        }
        if((selectVencido === 'VENCIDO' || selectVencido === 'NO VENCIDO')){
            if(selectVencido === 'VENCIDO'){
                ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'VENCIDO' )
            }else{
                ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'NO VENCIDO' )
            }
        }
        if(selectSubTipoId){
            ticketViewFilter = ticketViewFilter.filter((etk) => etk?.ticket?.subTipoTicketId_tk === selectSubTipoId);
        }
        const subTipoHabilitados = [];
        ticketViewFilter?.forEach((tkvf) => {
            subTipoHabilitados.push(tkvf?.ticket?.subTipoTicketId_tk);
        });
        setSelectSubTipoArr(subTipoHabilitados);
        setTickets(totalTk)
        ticketViewFilter?.sort((a, b) => b?.ticket?.id_tk - a?.ticket?.id_tk);
        setTicketsView(ticketViewFilter)
    }, [ticketsIndv, ticketsGrupo, ticketsCreados])

    useEffect(() => {
        if(ticketrefresh?.reloadingInd){
            actualizarIndv();
        }
        if(ticketrefresh?.reloadingGroup){
            actualizarGrupo();
        }
    }, [ticketrefresh])

    useEffect(() => {
        actualizarIndv();
        actualizarGrupo();
        actualizarCreated();
    }, [])

    useEffect(() => {
        if(tkCreatedPage > 0){
            actualizarCreated();
        }else{
            actualizarCreated(true);
        }
    }, [tkCreatedPage])

    useEffect(() => {
        let ticketViewFilter = tickets;
        if(filtroGeneral !== 'todos'){
            const filtroAct = ticketViewFilter.filter((tk)=> tk.tipoTicket === filtroGeneral)
            const arrIdTk = [];
            const arrFilterTk = [];
            filtroAct.forEach((tk)=>{
                if(!arrIdTk.includes(tk?.ticket?.id_tk || tk?.id_tk)){
                    arrIdTk.push(tk?.ticket?.id_tk || tk?.id_tk)
                    arrFilterTk.push(tk)
                }
            })
            if(filtroGeneral === 'grupal' && selectGrupoId){
                const filtroActGrupoId = filtroAct.filter((tk)=> tk?.ticket?.idGrupo === selectGrupoId)
                ticketViewFilter = filtroActGrupoId;
            }else{
                setSelectGrupoId(null)
                ticketViewFilter = filtroAct;
            }
        }else{
            const arrIdTk = [];
            const arrFilterTk = [];
            ticketViewFilter.forEach((tk)=>{
                if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                    arrIdTk.push(tk?.ticket?.id_tk)
                    arrFilterTk.push(tk)
                }
            })
            ticketViewFilter = arrFilterTk;
        }
        if(filtroEstadoTk){
            if(filtroEstadoTk !== 'todos'){
                if(filtroEstadoTk === 'ABIERTO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> !tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else if(filtroEstadoTk === 'CERRADO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else{
                    const filtroAct = ticketViewFilter.filter((tk)=> tk?.ticket?.estado_tk === filtroEstadoTk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }
            }else{
                const arrIdTk = [];
                const arrFilterTk = [];
                ticketViewFilter.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = arrFilterTk;
            }
        }
        if((selectVencido === 'VENCIDO' || selectVencido === 'NO VENCIDO')){
            if(selectVencido === 'VENCIDO'){
                ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'VENCIDO' )
            }else{
                ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'NO VENCIDO' )
            }
        }
        if(selectSubTipoId){
            ticketViewFilter = ticketViewFilter.filter((etk) => etk?.ticket?.subTipoTicketId_tk === selectSubTipoId);
        }
        const subTipoHabilitados = [];
        ticketViewFilter?.forEach((tkvf) => {
            subTipoHabilitados.push(tkvf?.ticket?.subTipoTicketId_tk);
        });
        setSelectSubTipoArr(subTipoHabilitados);
        ticketViewFilter?.sort((a, b) => b?.ticket?.id_tk - a?.ticket?.id_tk);
        setTicketsView(ticketViewFilter)
        setTextSearch('')
    }, [filtroGeneral,filtroEstadoTk,selectGrupoId,selectVencido,selectSubTipoId])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
            width: {xs:'120%', sm: 350, md: 450}
        }
    }), [])

    const inSearchText = (text) =>{
        if(text?.length > 0){
            let ticketViewFilter = tickets;
            if(filtroGeneral !== 'todos'){
                const filtroAct = ticketViewFilter?.filter((tk)=> tk.tipoTicket === filtroGeneral)
                const arrIdTk = [];
                const arrFilterTk = [];
                filtroAct.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                if(filtroGeneral === 'grupal' && selectGrupoId){
                    const filtroActGrupoId = filtroAct.filter((tk)=> tk?.ticket?.idGrupo === selectGrupoId)
                    ticketViewFilter = filtroActGrupoId;
                }else{
                    setSelectGrupoId(null)
                    ticketViewFilter = filtroAct;
                }
            }else{
                const arrIdTk = [];
                const arrFilterTk = [];
                ticketViewFilter.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = arrFilterTk;
            }
            if(filtroEstadoTk !== 'todos'){
                if(filtroEstadoTk === 'ABIERTO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> !tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else if(filtroEstadoTk === 'CERRADO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else{
                    const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.estado_tk === filtroEstadoTk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }
            }else{
                const arrIdTk = [];
                const arrFilterTk = [];
                ticketViewFilter.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = arrFilterTk;
            }
            if((selectVencido === 'VENCIDO' || selectVencido === 'NO VENCIDO')){
                if(selectVencido === 'VENCIDO'){
                    ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'VENCIDO' )
                }else{
                    ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'NO VENCIDO' )
                }
            }
            if(selectSubTipoId){
                ticketViewFilter = ticketViewFilter.filter((etk) => etk?.ticket?.subTipoTicketId_tk === selectSubTipoId);
            }
            const subTipoHabilitados = [];
            ticketViewFilter?.forEach((tkvf) => {
                subTipoHabilitados.push(tkvf?.ticket?.subTipoTicketId_tk);
            });
            setSelectSubTipoArr(subTipoHabilitados);
            const filtro = ticketViewFilter?.filter((tkv)=> tkv?.nombreCompleto?.toUpperCase()?.includes(text?.toUpperCase()))
            filtro?.sort((a, b) => b?.ticket?.id_tk - a?.ticket?.id_tk);
            setTicketsView(filtro)
        }else{
            let ticketViewFilter = tickets;
            if(filtroGeneral !== 'todos'){
                const filtroAct = ticketViewFilter?.filter((tk)=> tk?.tipoTicket === filtroGeneral)
                const arrIdTk = [];
                const arrFilterTk = [];
                filtroAct.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                if(filtroGeneral === 'grupal' && selectGrupoId){
                    const filtroActGrupoId = filtroAct.filter((tk)=> tk?.ticket?.idGrupo === selectGrupoId)
                    ticketViewFilter = filtroActGrupoId;
                }else{
                    setSelectGrupoId(null)
                    ticketViewFilter = filtroAct;
                }
            }else{
                const arrIdTk = [];
                const arrFilterTk = [];
                ticketViewFilter.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = arrFilterTk;
            }
            if(filtroEstadoTk !== 'todos'){
                if(filtroEstadoTk === 'ABIERTO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> !tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else if(filtroEstadoTk === 'CERRADO'){
                    const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.cerrado_tk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }else{
                    const filtroAct = ticketViewFilter?.filter((tk)=> tk?.ticket?.estado_tk === filtroEstadoTk)
                    const arrIdTk = [];
                    const arrFilterTk = [];
                    filtroAct.forEach((tk)=>{
                        if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                            arrIdTk.push(tk?.ticket?.id_tk)
                            arrFilterTk.push(tk)
                        }
                    })
                    ticketViewFilter = filtroAct;
                }
            }else{
                const arrIdTk = [];
                const arrFilterTk = [];
                ticketViewFilter.forEach((tk)=>{
                    if(!arrIdTk.includes(tk?.ticket?.id_tk)){
                        arrIdTk.push(tk?.ticket?.id_tk)
                        arrFilterTk.push(tk)
                    }
                })
                ticketViewFilter = arrFilterTk;
            }
            if((selectVencido === 'VENCIDO' || selectVencido === 'NO VENCIDO')){
                if(selectVencido === 'VENCIDO'){
                    ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'VENCIDO' )
                }else{
                    ticketViewFilter = ticketViewFilter.filter((etk)=> etk?.ticket?.estadoVencimiento_tk === 'NO VENCIDO' )
                }
            }
            if(selectSubTipoId){
                ticketViewFilter = ticketViewFilter.filter((etk) => etk?.ticket?.subTipoTicketId_tk === selectSubTipoId);
            }
            const subTipoHabilitados = [];
            ticketViewFilter?.forEach((tkvf) => {
                subTipoHabilitados.push(tkvf?.ticket?.subTipoTicketId_tk);
            });
            setSelectSubTipoArr(subTipoHabilitados);
            ticketViewFilter?.sort((a, b) => b?.ticket?.id_tk - a?.ticket?.id_tk);
            setTicketsView(ticketViewFilter)
        }
        setTextSearch(text)
    }
    useEffect(() => {
        document.title = `Tabla Tickets`;
    }, [])
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} spacing={1}>
                <Grid item xs={12}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Tabla de Tickets</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{display: 'flex', justifyContent: 'start',mt:3}}>
                    <Box sx={{width: {xs: '100%', sm: 250}}}>
                        <Typography sx={{color: '#000'}}>Filtro:</Typography>
                        <SelectFilterTicket
                            value={filtroGeneral}
                            onChange={(e)=>setFiltroGeneral(e.value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{display: 'flex', justifyContent: 'start',mt:3}}>
                    <Box sx={{width: {xs: '100%', sm: 200}}}>
                        <Typography sx={{color: '#000'}}>Estado:</Typography>
                        <SelectFilterStageTicket
                            isClearable
                            value={filtroEstadoTk}
                            onChange={(e)=>setFiltroEstadoTk(e?.value)}
                        />
                    </Box>
                </Grid>
                { filtroGeneral === 'grupal' && (
                    <Grid item xs={12} sm={4} md={3} lg={2} sx={{display: 'flex', justifyContent: 'start',mt:3}}>
                        <Box sx={{width: {xs: '100%', sm: 200}}}>
                            <Typography sx={{color: '#000'}}>Grupo:</Typography>
                            <SelectGruposUser
                                isClearable
                                value={selectGrupoId}
                                onChange={(e)=>setSelectGrupoId(e?.value)}
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{display: 'flex', justifyContent: 'start',mt:3}}>
                    <Box sx={{width: {xs: '100%', sm: 200}}}>
                        <Typography sx={{color: '#000'}}>SubTipo:</Typography>
                        <SelectSubTipoTicket
                            isClearable
                            value={selectSubTipoId}
                            onChange={(e)=>setSelectSubTipoId(e?.value)}
                            filterActivos={selectSubTipoArr}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{display: 'flex', justifyContent: 'start',mt:3}}>
                    <Box sx={{width: {xs: '100%', sm: 200}}}>
                        <Typography sx={{color: '#000'}}>Vencido/NoVencido:</Typography>
                        <SelectEstadoVencimiento
                            isClearable
                            value={selectVencido}
                            onChange={(e)=>setSelectVencido(e?.value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={11.9} sm={3.9} md={5.9} lg={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 3}}>
                    <ButtonBasic
                        iconStart={<AddIcon sx={{color: '#fff'}} />}
                        textButton="Nuevo Ticket"
                        sx={{width: {xs: '100%', sm: 160},maxHeight: {xs: '100%', sm: 40, md: 35} ,color: '#fff'}}
                        onClick={()=>onClickNewTicket()}
                        variant='wk'
                        color='nextStep'
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <Card sx={{background: '#fff !important'}}>
                        <Grid container>
                            <Grid item xs={10} sm={10} p={1}>
                                <TextField
                                    focused
                                    value={textSearch}
                                    autoComplete='off'
                                    onChange={(e)=>inSearchText(e.target.value)}
                                    InputProps={{
                                        type: 'search',
                                        startAdornment:(
                                            <SearchIcon sx={{color: '#000'}} size={20} />
                                        ),
                                    }}
                                    sx={styleInput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} display='flex' alignItems='center' justifyContent='center' p={1}>
                                <Typography sx={{color: '#000',fontWeight: 'bold'}}>{`${ticketsView?.length || '0'} resultados`}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{background: 'gray'}} />
                        <Grid container>
                            <Grid item xs={8} sm={10} p={1.5}>
                                <Typography sx={{color: '#000',fontWeight: 'bold',ml:10}}>Ticket</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} display='flex' alignItems='center'>
                                <Divider orientation='vertical' sx={{background: 'gray'}} />
                                <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',ml:4}}>Estado</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{background: 'gray'}} />
                        <Box mb={5}>
                            {ticketsView?.length > 0 && (
                                ticketsView.map((tkv)=> <CardViewTicket key={tkv?.id_uxt} item={tkv} /> )
                            )}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    ticketrefresh: state.ticketrefresh
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TablaTicketScreen)