import {useState, useEffect,Fragment} from 'react';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ContactosService from '../../services/contactos.service';
import CardSearchNav from '../card/CardSearchNav';
import TratoService from '../../services/tratos.service';

const InputSearchIdDirEntOne = ({value,disabled,onChange,isClearable,errorText,onClick,borderChange, ...props}) => {

    const [textSearch, setTextSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSearchContacto = async(query) => {
        setIsLoading(true);
        await TratoService.getAll({searchNubi: query,typoSearch: 'soft'}).then((data)=>{
            setOptions( data.map((item) =>{
                if(!item?.cuenta){
                    return {
                        ...item,
                        tituloCard: `${item?.id_t} - ${item?.nombre_t}`,
                        descripCard: `Pre-Servicio - ${item?.direccion_t || 'S/D'} - ${item?.categoria_t || ''}`,
                        empresaCard: 'NUBICOM',
                        keyCard: `${item?.id_t}preS`
                    }
                }
                return {
                    ...item,
                    tituloCard: `${item?.id_t} - ${item?.nombre_t}`,
                    descripCard: `Softland - ${item?.direccion_servicio || 'S/D'} - ${item?.segmento || ''}`,
                    empresaCard: 'NUBICOM',
                    keyCard: `${item?.id_t}dirent`
                }
            }))
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if(textSearch?.length > 2){
            const delayWrite = setTimeout(() => {
                handleSearchContacto(textSearch)
            }, 600)
            return () => clearTimeout(delayWrite)
        }
    }, [textSearch])

    return (
        <Box sx={{display: 'flex'}}>
            <Autocomplete
                id="asynchronous-demo"
                freeSolo
                isOptionEqualToValue={(option, value) => option?.tituloCard === value?.tituloCard}
                getOptionLabel={(option) => `${option?.tituloCard} ${option?.descripCard}`}
                options={options}
                filterOptions={(options) => options}
                loading={isLoading}
                disableClearable
                fullWidth
                sx={{
                    marginTop: 0.5,
                    marginLeft: -0.2,
                    '& fieldset': {
                        borderRadius: borderChange ? 2 : 7,
                        borderColor: 'gray',
                        borderWidth: 2,
                        height: 43.5,
                        color: '#000',
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline':{
                        borderRadius: borderChange ? 2 : 7,
                        borderColor: '#808080 !important',
                        border: 1,
                        color: '#000 !important',
                        background: '#f2f2f2',
                    },
                    '& input': {
                        color: '#000',
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        focused
                        autoComplete='off'
                        {...params}
                        onChange={(e)=>setTextSearch(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment:(
                                <SearchIcon sx={{color: '#000',marginTop:-1}} size={20} />
                            ),
                            endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress sx={{color: '#000',marginTop:-1}} size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                            ),
                        }}
                        sx={{
                            '& fieldset': {
                                borderRadius: 20
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: 'gray',
                                },
                            },
                            '& input': {
                                marginTop: -1,
                            },
                        }}
                    />
                )}
                renderOption={(props,option)=>{
                    let cortarTitulo = option?.tituloCard;
                    let cortarDescr = option?.descripCard?.replace(/<[^>]*>?/gm, '');
                    cortarDescr = cortarDescr?.replace(/&nbsp[;]?/ig, '');
                    if(cortarTitulo?.length > 30){
                        cortarTitulo = `${cortarTitulo?.substring(0,32)}...`
                    }
                    if(cortarDescr?.length > 80){
                        cortarDescr = `${cortarDescr?.substring(0,80)}...`
                    }
                    return (
                        <CardSearchNav
                            itemsProsp={props}
                            item={option}
                            descripcion={cortarDescr}
                            titulo={cortarTitulo}
                            key={option?.keyCard}
                            onClick={onClick}
                        />
                    )
                }}
            />
        </Box>
    );
};

InputSearchIdDirEntOne.defaultProps = {
    isClearable: false,
    value: null
};

export default InputSearchIdDirEntOne;