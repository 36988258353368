import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material"
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { ButtonBasic, CardItemServicios, InputImgDocument } from "../../../components";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { connect } from "react-redux";
import ContactosAction from '../../../store/actions/contactos';
import { useEffect,useState } from "react";
import usePermisos from "../../../hooks/usePermisos";
import ApiSoftland from "../../../services/apis.softland.service";
import InfContactoService from "../../../services/infContacto.service";
import ContactosService from "../../../services/contactos.service";

const FormPaso2Screen = ({
  contacto,
  contrato,
  nextStep,
  previousStep,
  iniciarContrato
}) => {
  const token = localStorage.getItem('token');
  const permisos = usePermisos();
  const [isLoading, setIsLoading] = useState(false);
  const [servicios, setServicios] = useState([]);
  const [tipoPersona, setTipoPersona] = useState('FISICA');

  const [imgPreviewDF, setImgPreviewDF] = useState(null);
  const [defaultImageDF, setDefaultImageDF] = useState(null);

  const [imgPreviewDD, setImgPreviewDD] = useState(null);
  const [defaultImageDD, setDefaultImageDD] = useState(null);

  const [imgPreviewConstAfip, setImgPreviewConstAfip] = useState(null);
  const [defaultImageConstAfip, setDefaultImageConstAfip] = useState(null);

  const [imgPreviewContratoSocialEstatuto, setImgPreviewContratoSocialEstatuto] = useState(null);
  const [defaultImageContratoSocialEstatuto, setDefaultImageContratoSocialEstatuto] = useState(null);

  /* const [imgPreviewDesigActaActualizada, setImgPreviewDesigActaActualizada] = useState(null);
  const [defaultImageDesigActaActualizada, setDefaultImageDesigActaActualizada] = useState(null); */

  const [imgPreviewPoderApoderado, setImgPreviewPoderApoderado] = useState(null);
  const [defaultImagePoderApoderado, setDefaultImagePoderApoderado] = useState(null);

  const [imgPreviewDniFrenteApoderado, setImgPreviewDniFrenteApoderado] = useState(null);
  const [defaultImageDniFrenteApoderado, setDefaultImageDniFrenteApoderado] = useState(null);

  const [imgPreviewDniDorsoApoderado, setImgPreviewDniDorsoApoderado] = useState(null);
  const [defaultImageDniDorsoApoderado, setDefaultImageDniDorsoApoderado] = useState(null);

  const [nextPageImg, setNextPageImg] = useState(false);
  const [planIdArr, setPlanIdArr] = useState(null);
  const [contactoImg, setContactoImg] = useState(null);
  const acceptInputFile = 'image/jpeg, image/png, image/jpg, application/pdf';
  const acceptInputFileImg = 'image/jpeg, image/png, image/jpg';

  const traerProductos = async(categoria) =>{
    await ApiSoftland.getProductos(categoria).then((resp)=>{
      if(resp?.length > 0){
        setServicios(resp)
      }else{
        setServicios([])
      }
    })
  }
  const onSubmit = async() => {
    setIsLoading(true);
    const form ={
        id_c: contacto?.id_c,
        cuenta: contacto?.cuenta,
        dniFrente: imgPreviewDF,
        dniDorso: imgPreviewDD,
        constanciaAfip: imgPreviewConstAfip,
        contratoSocualEstatuto: imgPreviewContratoSocialEstatuto,
        /* desigActaActualizada: imgPreviewDesigActaActualizada, */
        poderApoderado: imgPreviewPoderApoderado,
        dniFrenteApoderado: imgPreviewDniFrenteApoderado,
        dniDorsoApoderado: imgPreviewDniDorsoApoderado,
        type: 'nubicom',
        preview: 'venta',
        tipoCuit_icon: tipoPersona
    }
    if(
      (imgPreviewDF || imgPreviewDD) ||
      ( imgPreviewConstAfip
        || imgPreviewContratoSocialEstatuto
        /* || imgPreviewDesigActaActualizada */
        || imgPreviewPoderApoderado
        || imgPreviewDniFrenteApoderado
        || imgPreviewDniDorsoApoderado
      )
    ){
      await ContactosService.registerInfo(form).then(()=>{
          iniciarContrato({
              ...contrato,
              servicios: [{
                servicioIdSoftland_ds: planIdArr?.cod_producto,
                tipoServicio_s:  planIdArr?.tipo,
                nombre_s: planIdArr?.nombre,
                importe_s: planIdArr?.precio || 0,
                anchoBajada_s: planIdArr?.bajada || 0,
                anchoSubida_s: planIdArr?.subida || 0,
                tipoAnchoBajada_s: planIdArr?.unidad_bajada || '',
                tipoAnchoSubida_s: planIdArr?.unidad_subida || '',
                moneda_s:  planIdArr?.moneda || 'USD'
              }],
              precio: planIdArr?.precio || 0,
              prefactibilidad: planIdArr?.prefactibilidad || 'POSITIVA'
          })
          setIsLoading(false);
          nextStep();
      })
      .catch(()=>{
          setIsLoading(false);
      })
    }else{
        iniciarContrato({
            ...contrato,
            servicios: [{
              servicioIdSoftland_ds: planIdArr?.cod_producto,
              tipoServicio_s:  planIdArr?.tipo,
              nombre_s: planIdArr?.nombre,
              importe_s: planIdArr?.precio || 0,
              anchoBajada_s: planIdArr?.bajada || 0,
              anchoSubida_s: planIdArr?.subida || 0,
              tipoAnchoBajada_s: planIdArr?.unidad_bajada || '',
              tipoAnchoSubida_s: planIdArr?.unidad_subida || '',
              moneda_s:  planIdArr?.moneda || 'USD'
            }],
            precio: planIdArr?.precio || 0,
            prefactibilidad: planIdArr?.prefactibilidad || 'POSITIVA'
        })
        nextStep();
        setIsLoading(false);
    }
  };
  useEffect(() => {
    setDefaultImageDF(null);
    setDefaultImageDD(null);
    setDefaultImageConstAfip(null);
    setDefaultImageContratoSocialEstatuto(null);
    /* setDefaultImageDesigActaActualizada(null); */
    setDefaultImagePoderApoderado(null);
    setDefaultImageDniFrenteApoderado(null);
    setDefaultImageDniDorsoApoderado(null);
    if(contacto?.cuenta){
      InfContactoService.getAll({cuentaId: contacto?.cuenta}).then((repp)=>{
          setContactoImg(repp)
      })
    }else if(contacto?.id_c){
      InfContactoService.getAll({contactoId: contacto?.id_c}).then((repp)=>{
          setContactoImg(repp)
      })
    }
  }, [contacto])
  useEffect(() => {
    if(contactoImg && contactoImg?.pathFrente_icon){
        setDefaultImageDF(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathFrente_icon}&token=${token}`);
    }
    if(contactoImg && contactoImg?.pathDorso_icon){
        setDefaultImageDD(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDorso_icon}&token=${token}`);
    }
    if(contactoImg && contactoImg?.pathconstanciaAfip_icon){
        const extension = contactoImg?.pathconstanciaAfip_icon.split('.').pop();
        if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
            setDefaultImageConstAfip('/img/pdf.png');
        }else{
            setDefaultImageConstAfip(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathconstanciaAfip_icon}&token=${token}`);
        }
    }
    if(contactoImg && contactoImg?.pathContratoSocialEstatuto_icon){
      const extension = contactoImg?.pathContratoSocialEstatuto_icon.split('.').pop();
      if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
        setDefaultImageContratoSocialEstatuto('/img/pdf.png');
      }else{
        setDefaultImageContratoSocialEstatuto(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathContratoSocialEstatuto_icon}&token=${token}`);
      }
    }
    /* if(contactoImg && contactoImg?.pathDesigActaActualizada_icon){
      const extension = contactoImg?.pathDesigActaActualizada_icon.split('.').pop();
      if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
        setDefaultImageDesigActaActualizada('/img/pdf.png');
      }else{
        setDefaultImageDesigActaActualizada(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDesigActaActualizada_icon}&token=${token}`);
      }
    } */
    if(contactoImg && contactoImg?.pathPoderApoderado_icon){
      const extension = contactoImg?.pathPoderApoderado_icon.split('.').pop();
      if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
        setDefaultImagePoderApoderado('/img/pdf.png');
      }else{
        setDefaultImagePoderApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathPoderApoderado_icon}&token=${token}`);
      }
    }
    if(contactoImg && contactoImg?.pathDniFrenteApoderado_icon){
      const extension = contactoImg?.pathDniFrenteApoderado_icon.split('.').pop();
      if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
        setDefaultImageDniFrenteApoderado('/img/pdf.png');
      }else{
        setDefaultImageDniFrenteApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDniFrenteApoderado_icon}&token=${token}`);
      }
    }
    if(contactoImg && contactoImg?.pathDniDorsoApoderado_icon){
      const extension = contactoImg?.pathDniDorsoApoderado_icon.split('.').pop();
      if(extension === 'pdf' || extension === 'doc' || extension === 'docx'){
        setDefaultImageDniDorsoApoderado('/img/pdf.png');
      }else{
        setDefaultImageDniDorsoApoderado(`https://zeta.strongsystems.com.ar:8000/api/img?url=contacto/${contactoImg?.pathDniDorsoApoderado_icon}&token=${token}`);
      }
    }
    if(contactoImg && contactoImg?.tipoCuit_icon){
      setTipoPersona(contactoImg?.tipoCuit_icon)
    }
  }, [contactoImg])
  useEffect(() => {
    if(contacto?.categoria_cxe){
        let categoria = '';
        switch (contacto?.categoria_cxe) {
            case 'PYM':
            case 'PYMIN':
                categoria= 'PY'
                break;
            case 'GOB':
                categoria= 'GO'
                break;
            case 'VIP':
            case 'MAS':
            case 'MASIN':
                categoria= 'HO'
                break;
            case 'COR':
                categoria= 'CORP'
                break;
            default:
                break;
        }
        if(contacto?.tipoMapa === 'fibra'){
          categoria = `${categoria}FO`
        }else{
          categoria = `${categoria}RF`
        }
        traerProductos(categoria)
    }
    setPlanIdArr(null)
  }, [contacto])

  useEffect(() => {
    if(contacto?.iva_c !== 'C'){
      if(planIdArr?.prefactibilidad === 'NEGATIVA'){
        setNextPageImg(true)
      }else{
        if(tipoPersona === 'JURIDICA'){
          if( (defaultImageConstAfip || imgPreviewConstAfip)
            && (defaultImageContratoSocialEstatuto || imgPreviewContratoSocialEstatuto)
            /* && (defaultImageDesigActaActualizada || imgPreviewDesigActaActualizada) */
            && (defaultImagePoderApoderado || imgPreviewPoderApoderado)
            && (defaultImageDniFrenteApoderado || imgPreviewDniFrenteApoderado)
            && (defaultImageDniDorsoApoderado || imgPreviewDniDorsoApoderado)
          ){
              setNextPageImg(true)
          }else{
            setNextPageImg(false)
          }
        }else if(tipoPersona === 'FISICA'){
          if( (defaultImageConstAfip || imgPreviewConstAfip)){
              setNextPageImg(true)
          }else{
            setNextPageImg(false)
          }
        }else{
          setNextPageImg(false)
        }
      }
    }
  }, [
    contacto,
    planIdArr,
    defaultImageConstAfip,
    imgPreviewConstAfip,
    defaultImageContratoSocialEstatuto,
    imgPreviewContratoSocialEstatuto,
    /* defaultImageDesigActaActualizada,
    imgPreviewDesigActaActualizada, */
    defaultImagePoderApoderado,
    imgPreviewPoderApoderado,
    defaultImageDniFrenteApoderado,
    imgPreviewDniFrenteApoderado,
    defaultImageDniDorsoApoderado,
    imgPreviewDniDorsoApoderado,
    tipoPersona
  ])

  useEffect(() => {
    if(contacto?.iva_c === 'C'){
      if(planIdArr?.prefactibilidad === 'NEGATIVA'){
        setNextPageImg(true)
      }else{
        if((defaultImageDF && defaultImageDD) || (imgPreviewDF && imgPreviewDD) ){
          setNextPageImg(true)
        }else{
          setNextPageImg(false)
        }
      }
    }
  }, [contacto,
    planIdArr,
    defaultImageDF,
    imgPreviewDF,
    defaultImageDD,
    imgPreviewDD
  ])


  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2}>
        <Grid xs={12} md={12} lg={5.2}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12}>
                <Box display='flex' pl={2} pt={1}>
                    <SensorOccupiedIcon sx={{color: '#000',fontSize: 30}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>
                      { contacto?.iva_c === 'C' ? 'Documento de identidad' : 'Documentación requerida'}
                    </Typography>
                </Box>
                <Box display='flex' pl={2} pt={1}>
                  {contacto?.iva_c === 'C' ?(
                    <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                      Adjuntar DNI de ambos lados en los formatos permitidos: jpeg,png
                    </Typography>
                  ):(
                    <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                      Adjuntar los archivos en los formatos permitidos: jpeg, png, pdf
                    </Typography>
                  )}
                </Box>
              </Grid>
              {contacto?.iva_c === 'C' ? (
                <>
                  <Grid item xs={12}>
                  <Typography sx={{color: '#000', fontWeight: 'bold',paddingLeft: 2,marginTop:0.5,fontSize: 17}}>Frente</Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="dniFrente"
                        id="dniF"
                        key="dniF"
                        defaultImage={defaultImageDF}
                        setFoto={setImgPreviewDF}
                        onChange={setImgPreviewDF}
                        accept={acceptInputFileImg}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography sx={{color: '#000', fontWeight: 'bold',paddingLeft: 2,marginTop:0.5,fontSize: 17}}>Dorso</Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="dniDorso"
                        id="dniD"
                        key="dniD"
                        defaultImage={defaultImageDD}
                        setFoto={(e)=>setImgPreviewDD(e)}
                        onChange={(e)=>setImgPreviewDD(e)}
                        accept={acceptInputFileImg}
                      />
                    </Box>
                  </Grid>
                </>
              ):(
                <>
                  <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Tipo de Persona
                    </Typography>
                    <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={tipoPersona}
                        onChange={(e) => setTipoPersona(e.target.value)}
                        row
                      >
                        <FormControlLabel
                            value="FISICA"
                            control={ <Radio sx={{color: '#000'}} />}
                            label="Persona Física"
                            sx={{color: '#000'}}
                        />
                        <FormControlLabel
                            value="JURIDICA"
                            control={ <Radio sx={{color: '#000'}} /> }
                            label="Persona Jurídica"
                            sx={{color: '#000'}}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Constacia de AFIP
                    </Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="documentImg"
                        id="documentImg"
                        key="documentImg"
                        defaultImage={defaultImageConstAfip}
                        onChange={setImgPreviewConstAfip}
                        setFoto={setImgPreviewConstAfip}
                        accept={acceptInputFile}
                      />
                    </Box>
                  </Grid>
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        Contrato Social-Estatuto
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg2"
                          key="documentImg2"
                          defaultImage={defaultImageContratoSocialEstatuto}
                          onChange={setImgPreviewContratoSocialEstatuto}
                          setFoto={setImgPreviewContratoSocialEstatuto}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} mt={1}>
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                      Designacion Acta Actualizada
                    </Typography>
                    <Box display='flex' justifyContent='center'>
                      <InputImgDocument
                        backgroundImage="documentImg"
                        id="documentImg3"
                        key="documentImg3"
                        defaultImage={defaultImageDesigActaActualizada}
                        onChange={setImgPreviewDesigActaActualizada}
                        setFoto={setImgPreviewDesigActaActualizada}
                        accept={acceptInputFile}
                      />
                    </Box>
                  </Grid> */}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        Poder del Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg4"
                          key="documentImg4"
                          defaultImage={defaultImagePoderApoderado}
                          onChange={setImgPreviewPoderApoderado}
                          setFoto={setImgPreviewPoderApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        DNI-Frente-Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg5"
                          key="documentImg5"
                          defaultImage={defaultImageDniFrenteApoderado}
                          onChange={setImgPreviewDniFrenteApoderado}
                          setFoto={setImgPreviewDniFrenteApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                  { tipoPersona === 'JURIDICA' && (
                    <Grid item xs={12} mt={1}>
                      <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 3,marginTop:0.5,fontSize: 15}}>
                        DNI-Dorso-Apoderado
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <InputImgDocument
                          backgroundImage="documentImg"
                          id="documentImg6"
                          key="documentImg6"
                          defaultImage={defaultImageDniDorsoApoderado}
                          onChange={setImgPreviewDniDorsoApoderado}
                          setFoto={setImgPreviewDniDorsoApoderado}
                          accept={acceptInputFile}
                        />
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} md={12} lg={6.6} ml={1} mb={5}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12}>
                <Box display='flex' pl={2} pt={1}>
                  <ShoppingBagOutlinedIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Servicios</Typography>
                </Box>
                <Box display='flex' pl={2} pt={1}>
                  <Typography sx={{color: '#243757',marginTop:0.5,fontSize: 15}}>
                    {`Seleccionar un plan -  Cobertura máxima ${contacto?.cobertura || ''} Mb`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {servicios?.length > 0 && (
                  servicios.map((serv)=>(
                    <CardItemServicios
                      key={serv.cod_producto}
                      item={serv}
                      servicioSelect={planIdArr}
                      setServicioSelect={setPlanIdArr}
                      coberturaMaxima={contacto?.cobertura}
                      permisoEsp={permisos['sinCobertura.nubicom']}
                      permisoPrefa={permisos['solicitar.prefa.nubicom']}
                    />
                  ))
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Volver'
          iconStart={<ArrowBackOutlinedIcon />}
          onClick={()=>previousStep()}
          variant='wk'
          isLoading={isLoading}
          color='prevStep'
        />
        <ButtonBasic
          textButton='Continuar'
          iconEnd={<ArrowForwardIcon />}
          onClick={()=>onSubmit()}
          variant='wk'
          color='nextStep'
          isDisabled={!planIdArr || !nextPageImg}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso2Screen);