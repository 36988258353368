import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateRange, SelectCantidadSoftland, SelectCategoriaSoftland, SelectNivelBonificacion, SelectSaiSoftland, SelectSaiSoftlandZeta } from '../../components';
import SaiSoftlandService from '../../services/saiSoftland.service';
import FechaFunction from '../../function/FechaFunction';
import AuxiliarFunction from '../../function/AuxiliarFunction';

const ModalPlantillaBonificacion = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [formAll, setFormAll] = useState({
        nombre_ss: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        vigenciaMes_ss: {
            texto: 1,
            textoErr: 'La vigencia es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        idSoftServicio_ss: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoServicio_ss: {
            texto: 'SAI   ',
            textoErr: 'Los Sub-Tipos son requeridos.',
            estadoErr: false,
            tipo: 'string'
        },
        tipoServicioDesc_ss: {
            texto: 'SERVICOS ASOCIADOS A  LA INSTALACION',
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        porcentaje_ss: {
            texto: null,
            textoErr: 'Los Sub-Tipos son requeridos.',
            estadoErr: false,
            tipo: 'string'
        },
        segmento_ss: {
            texto: null,
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nivelBonificacion_ss: {
            texto: 'bonificacion_nivel1',
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        arrAcumulativo: {
            texto: [],
            textoErr: 'El titulo es requerido',
            estadoErr: false,
            tipo: 'string'
        }
    });
    const [fechaSelect, setFechaSelect] = useState({
        startDate: null,
        endDate: null
    });
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            formOld[input].estadoErr = false;
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const onSubmit = async() => {
        const form = {};
        let isOk = false;
        setIsLoading(true);
        const inputRequired = ['nombre_ss', 'vigenciaMes_ss', 'idSoftServicio_ss', 'tipoServicio_ss', 'porcentaje_ss', 'segmento_ss']
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
        propertyNames.forEach((name)=>{
        if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
            form[name] = formAll[name].texto
        }else{
            if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
            }
        }
        }))
        if(!isOk){
            if(fechaSelect?.startDate && fechaSelect?.endDate){
                form.fechaIni_ss = FechaFunction.format(fechaSelect?.startDate, 'yyyy-MM-dd')
                form.fechaFin_ss = FechaFunction.format(fechaSelect?.endDate, 'yyyy-MM-dd')
            }else{
                form.fechaIni_ss = null;
                form.fechaFin_ss = null;
            }
            if(item?.id_ss > 0) {
                form.id_ss = item.id_ss
                SaiSoftlandService.update(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .catch(()=>{
                        setIsLoading(false);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }else{
                SaiSoftlandService.register(form)
                    .then(() => {
                        onGuardar();
                        hideModal();
                    })
                    .catch(()=>{
                        setIsLoading(false);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }else{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(item){
            setTimeout(() => {
                onChangeForm(item?.nombre_ss,'nombre_ss')
                onChangeForm(item?.vigenciaMes_ss,'vigenciaMes_ss')
                onChangeForm(item?.arrAcum,'arrAcumulativo')
                onChangeForm(item?.idSoftServicio_ss || '  22','idSoftServicio_ss')
                onChangeForm(item?.tipoServicio_ss || 'SAI   ','tipoServicio_ss')
                onChangeForm(item?.tipoServicioDesc_ss || 'SERVICOS ASOCIADOS A  LA INSTALACION','tipoServicioDesc_ss')
                onChangeForm(item?.porcentaje_ss,'porcentaje_ss')
                onChangeForm(item?.segmento_ss,'segmento_ss')
                onChangeForm(item?.nivelBonificacion_ss || 'bonificacion_nivel1','nivelBonificacion_ss')
                if(item?.fechaIni_ss && item?.fechaFin_ss){
                    setFechaSelect({
                        startDate: item?.fechaIni_ss,
                        endDate: item?.fechaFin_ss
                    })
                }
            }, 100);
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.5px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_ss > 0 ? 'Editar Bonificacion SAI' : 'Registrar Bonificacion SAI'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>SAI-Softland</Typography>
                            <SelectSaiSoftland
                                value={formAll.idSoftServicio_ss.texto}
                                onChange={(e)=>{
                                    const arPorcentaje = e.porcentaje_bonif.split('.')
                                    if(arPorcentaje?.length > 0){
                                        const division = AuxiliarFunction.formatoNumberBonificacion(arPorcentaje[0])
                                        onChangeForm(division,'porcentaje_ss')
                                    }
                                    onChangeForm(e.label,'nombre_ss')
                                    onChangeForm(e.value,'idSoftServicio_ss')
                                    onChangeForm(e.tipo,'tipoServicio_ss')
                                    onChangeForm(e.tipoDescripcion,'tipoServicioDesc_ss')
                                }}
                                isInvalid={formAll.idSoftServicio_ss.estadoErr}
                            />
                            {formAll.idSoftServicio_ss.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.idSoftServicio_ss.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Vigencia por mes</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                type='number'
                                value={formAll.vigenciaMes_ss.texto}
                                error={formAll.vigenciaMes_ss.estadoErr}
                                helperText={formAll.vigenciaMes_ss.estadoErr && formAll.vigenciaMes_ss.textoErr}
                                onChange={(e)=>onChangeForm(e.target.value, 'vigenciaMes_ss')}
                                sx={styleInputHeight}
                                InputProps={{ inputProps: { min: 0, style:{ fontWeight: 'normal',fontSize:15,color: '#15294b' }} }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Porcentaje</Typography>
                            <SelectCantidadSoftland
                                value={formAll.porcentaje_ss.texto}
                                onChange={(e)=>onChangeForm(e.value,'porcentaje_ss')}
                                isInvalid={formAll.porcentaje_ss.estadoErr}
                                isDisabled
                            />
                            {formAll.porcentaje_ss.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.porcentaje_ss.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Segmento</Typography>
                            <SelectCategoriaSoftland
                                value={formAll.segmento_ss.texto}
                                onChange={(e)=>onChangeForm(e.value,'segmento_ss')}
                                isInvalid={formAll.segmento_ss.estadoErr}
                            />
                            {formAll.segmento_ss.estadoErr && (
                                <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.segmento_ss.textoErr}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#000'}}>Nivel</Typography>
                            <SelectNivelBonificacion
                                value={formAll.nivelBonificacion_ss.texto}
                                onChange={(e)=>onChangeForm(e.value,'nivelBonificacion_ss')}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography sx={{color: '#000'}}>Acumulativos</Typography>
                            <SelectSaiSoftlandZeta
                                value={formAll.arrAcumulativo.texto}
                                isMulti
                                getAll
                                excluirID={formAll.idSoftServicio_ss.texto}
                                onChange={(opt)=>onChangeForm(opt.map(op=>(op.value)),'arrAcumulativo')}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <DateRange
                                minDate={!item && new Date()}
                                initianDate={fechaSelect}
                                onChange={(e)=>setFechaSelect(e)}
                                isClearable
                            />
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalPlantillaBonificacion