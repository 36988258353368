import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic,
    SelectArtFallaTk,
    SelectEstadoCliente,
    SelectFilterStageTicket,
    SelectIvaSoftland,
    SelectLocalidadesSoftland,
    SelectMotivoBajaTk,
    SelectMotivoTicket,
    SelectNivelAdmTicket,
    SelectNodosMikrotikV2,
    SelectPanelesMikrotikV2,
    SelectPrioridadTkNubicom,
    SelectProblematicaTk,
    SelectReporteValidTipo,
    SelectSolucionTecTk,
    SelectStageTicket,
    SelectSubTipoTicket,
    SelectTipoDocSoftland,
    SelectTipoTicket,
    SelectUser,
    SelectValidPotCli,
    SelectValidTicket,
    SelectValidConsulta,
    SelectServicioSoftland,
    SelectCanalesSoftland,
    SelectValidTags,
    SelectUbicacionTags,
    SelectTags,
    SelectValidPreServicio,
    SelectCategoriaPreServicio,
    SelectCSCosto,
    SelectNivelBonificacion,
    SelectEstadoPreServicio,
    SelectEstadoVencimiento,
    SelectDerivoEnAsistencia,
    SelectLugaresCalendar,
    SelectEstadoConsulta,
    SelectLineaVista,
    SelectEstadoNoLeInteresa,
    SelectSubCierreTicket,
    SelectSubEstadoPreServicio, 
    SelectNivelVIP} from '../../components';
import SelectCierreTicket from '../../components/select/SelectCierreTicket';

const ModalReporteValidacion = ({show, hideModal, setValidaciones,categoria}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [itemValid, setItemValid] = useState(null);
    const [itemValidTitle, setItemValidTitle] = useState('');
    const [itemValidRadio, setItemValidRadio] = useState('Igual');
    const [itemValidTipo, setItemValidTipo] = useState(null);
    const [itemString, setItemString] = useState(null);
    const [itemArrString, setItemArrString] = useState(null);

    const handleClose = () => hideModal();

    const onSubmit = () => {
        const form = {
            id: uuidv4(),
            consulta: itemValidRadio,
            data: itemString,
            input: itemValid,
            inputTitle: itemValidTitle,
            itemValidTipo: itemValidTipo,
            itemArrString: itemArrString
        }
        setIsLoading(true);
        if( itemValidTipo === 'select' && !Array.isArray(itemString)){
            form.data = [itemString]
        }
        setValidaciones((e) => [...e,form])
        handleClose();
    };

    useEffect(() => {
        setItemString(null)
        setItemArrString(null)
    }, [itemValidTipo])
    useEffect(() => {
        setItemArrString(null)
        setItemString(null)
    }, [itemValid])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Agregar una nueva validacion
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Campo</Typography>
                            {categoria === 'potencialcliente' && (
                                <SelectValidPotCli
                                    value={itemValid}
                                    onChange={(e)=>{
                                        setItemValid(e.value)
                                        setItemValidTitle(e.label)
                                        setItemValidTipo(e.tipo)
                                    }}
                                />
                            )}
                            {categoria === 'ticket' && (
                                <SelectValidTicket
                                    value={itemValid}
                                    onChange={(e)=>{
                                        setItemValid(e.value)
                                        setItemValidTitle(e.label)
                                        setItemValidTipo(e.tipo)
                                    }}
                                />
                            )}
                            {categoria === 'contacto' && (
                                <></>
                            )}
                            {categoria === 'consultas' && (
                                <SelectValidConsulta
                                    value={itemValid}
                                    onChange={(e)=>{
                                        setItemValid(e.value)
                                        setItemValidTitle(e.label)
                                        setItemValidTipo(e.tipo)
                                    }}
                                />
                            )}
                            {categoria === 'tags' && (
                                <SelectValidTags
                                    value={itemValid}
                                    onChange={(e)=>{
                                        setItemValid(e.value)
                                        setItemValidTitle(e.label)
                                        setItemValidTipo(e.tipo)
                                    }}
                                />
                            )}
                            {categoria === 'pre-servicio' && (
                                <SelectValidPreServicio
                                    value={itemValid}
                                    onChange={(e)=>{
                                        setItemValid(e.value)
                                        setItemValidTitle(e.label)
                                        setItemValidTipo(e.tipo)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Validación</Typography>
                            <SelectReporteValidTipo
                                value={itemValidRadio}
                                onChange={(e)=>setItemValidRadio(e.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Ingrese la validacion</Typography>
                            {itemValidTipo === 'texto' && (
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    value={itemString}
                                    onChange={(e)=>{
                                        setItemString(e.target.value)
                                        setItemArrString(e.target.value)
                                    }}
                                    sx={styleInputHeight}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'tipoDoc_c' && (
                                <SelectTipoDocSoftland
                                    value={itemString}
                                    onChange={(e)=>{
                                        setItemString(e.value)
                                        setItemArrString(e.label)
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && (itemValid === 'cpostal_c' || itemValid === 'codpostal_t') && (
                                <SelectLocalidadesSoftland
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'iva_c' && (
                                <SelectIvaSoftland
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'estadoCliente_c' && (
                                <SelectEstadoCliente
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                    todos
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'motivo_tk' && (
                                <SelectMotivoTicket
                                    isMulti
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'stageTicket_tk' && (
                                <SelectStageTicket
                                    isMulti
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'estado_tk' && (
                                <SelectFilterStageTicket
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'estadoVencimiento_tk' && (
                                <SelectEstadoVencimiento
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'prioridad_tk' && (
                                <SelectPrioridadTkNubicom
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'tipoTicketId_tk' && (
                                <SelectTipoTicket
                                    emp='nubicom'
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'subTipoTicketId_tk' && (
                                <SelectSubTipoTicket
                                    emp='nubicom'
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'nivelAdmTkId_tk' && (
                                <SelectNivelAdmTicket
                                    isMulti
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'motivoBajaTk_tk' && (
                                <SelectMotivoBajaTk
                                    isMulti
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'cierreTicketId_tk' && (
                                <SelectCierreTicket
                                    isMulti
                                    reporte={true}
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'subCierreTicketId_tk' && (
                                <SelectSubCierreTicket
                                    isMulti
                                    reporte={true}
                                    emp='nubicom'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && (itemValid === 'createdPersonaId_tk' || itemValid ===  'closedPersonaId_tk' || itemValid === 'createdPersonaId_con' || itemValid === 'createdPersonaId_c' || itemValid === 'createdPersonaIdTag' || itemValid === 'createdPersonaId_t' || itemValid === 'updatedPersonaId_t' || itemValid === 'autorizadoxPersonalId_t') && (
                                <SelectUser
                                    isMulti
                                    filter='all'
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'nodoName_tk' && (
                                <SelectNodosMikrotikV2
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'panelName_tk' && (
                                <SelectPanelesMikrotikV2
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'articuloFallaId_tk' && (
                                <SelectArtFallaTk
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'problematicaTkId_tk' && (
                                <SelectProblematicaTk
                                    isMulti
                                    all
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'solucionTecTkId_tk' && (
                                <SelectSolucionTecTk
                                    isMulti
                                    all
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'estadoConsulta_con' && (
                                <SelectEstadoConsulta
                                    isMulti
                                    todos
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'subEstadoConsulta_con' && (
                                <SelectEstadoNoLeInteresa
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'planConsulta_con' && (
                                <SelectServicioSoftland
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && (itemValid === 'codCanal_tk' || itemValid === 'canalcomu_c' || itemValid === 'canal_con' || itemValid === 'contratistaSoft_t' ) && (
                                <SelectCanalesSoftland
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'ubicacionTag' && (
                                <SelectUbicacionTags
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'tag' && (
                                <SelectTags
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.label)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && (itemValid === 'categoria_t' || itemValid === 'segmento_con') && (
                                <SelectCategoriaPreServicio
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'cargoinstalacion_t' && (
                                <SelectCSCosto
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'nivelAutorizacion_t' && (
                                <SelectNivelBonificacion
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'nivelVip_t' && (
                                <SelectNivelVIP
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'estadoServicio_t' && (
                                <SelectEstadoPreServicio
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'subEstadoServicio_t' && (
                                <SelectSubEstadoPreServicio
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'derivoAsis_tk' && (
                                <SelectDerivoEnAsistencia
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && itemValid === 'lineaVista_tk' && (
                                <SelectLineaVista
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                            {itemValidTipo === 'select' && (itemValid === 'lugarId_c' || itemValid === 'localidadId_con') && (
                                <SelectLugaresCalendar
                                    isMulti
                                    value={itemString}
                                    onChange={(opt) => {
                                        setItemString(opt.map(op=>(op.value)))
                                        setItemArrString(opt.map(op=>(op.label)))
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!setItemString}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalReporteValidacion