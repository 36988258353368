import {Route, Routes} from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import LoginScreen from '../screens/login/LoginScreen';
import MainRoute from './MainRoute';
import LoadingPage from '../screens/loading/loadingPage';
import RecoverPasswordOKScreen from '../screens/login/RecoverPasswordOkScreen';
import RecoverPasswordScreen from '../screens/login/RecoverPasswordScreen';

const RouteValidate = () => {

    return (
        <Routes>
            <Route
                path="/login"
                element={(
                    <PublicRoute>
                        <LoginScreen />
                    </PublicRoute>
                )}
            />
            <Route
                path="/recover/password"
                element={(
                    <PublicRoute>
                        <RecoverPasswordScreen />
                    </PublicRoute>
                )}
            />
            <Route
                path="/recover/password/ok/:email"
                element={(
                    <PublicRoute>
                        <RecoverPasswordOKScreen />
                    </PublicRoute>
                )}
            />
            <Route
                path="/loading"
                element={(
                    <PrivateRoute>
                        <LoadingPage />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/*"
                element={(
                    <PrivateRoute>
                        <MainRoute />
                    </PrivateRoute>
                )}
            />
        </Routes>
    );
};

export default RouteValidate;

