import React, {useContext, useEffect, useState} from 'react'
import FechaFunction from '../../function/FechaFunction';
import { InputReadQuill } from '../index';
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player'
import {showModal} from 'react-redux-modal-provider';
import ComentarioTicketService from '../../services/comentarioTicket.service';
import { saveAs } from 'file-saver';
import { SocketContext } from '../../context/SocketContext';
import { Avatar, Box, Card, Grid, Typography } from '@mui/material';

const ComentarioTicketCard = ({
    item,
    user,
    estado,
    onGuardar,
    autorizado,
    cvv
}) => {
    /* eslint no-nested-ternary: "error" */
    const token = localStorage.getItem('token');
    const tokenNubi = localStorage.getItem('tokenNubi');
    const tokenStr = localStorage.getItem('tokenStr');
    const { sockets } = useContext( SocketContext );
    const esCreador = item?.createdPersonaId_com === user?.id_p;
    const comPrivado = item?.isVisible_com;
    const [autorizar, setAutorizar] = useState(false);
    const [tokenCvv, setTokenCvv] = useState(null);
    const urlNubi = 'https://zeta.strongsystems.com.ar:8000/api/img?url=comentarios/'
    const urlCvv = 'https://911.nubicom.com.ar:8000/api/img?url=comentarios/'

    useEffect(() => {
        const hoy = new Date();
        const hoyStr = FechaFunction.format(hoy)
        const coment = FechaFunction.format(new Date(item.createdAt))
        if(coment === hoyStr && !item?.isVisible_com && !estado && autorizado && cvv){
            setAutorizar(true)
        }else{
            setAutorizar(false)
        }
    }, [autorizado])


    return (
        <Grid container key={item.id_com} id={item.id_com} mb={4}>
            {!esCreador &&(
                <Grid item xs={1} md={0.5}>
                    <Avatar src={item?.autor?.foto_p} sx={{width:30,height:30,boxShadow:3}} />
                </Grid>
            )}
            <Grid item xs={11}>
                <Card
                    sx={{
                        background: esCreador ? '#e7e7e9 !important' : '#e6f0ff !important',
                        borderBottomLeftRadius:16,
                        borderBottomRightRadius:16,
                        borderTopLeftRadius: esCreador ? 16 : 1,
                        borderTopRightRadius: esCreador ? 1 : 16,
                        p: 1
                    }}
                >
                        <Typography sx={{color: '#000',fontWeight: 'bold',textAlign:'right'}} component='div'>{item?.autor?.nombre_p} - <Box fontWeight='normal' display='inline'>{item?.autor?.departamento_p || ''}</Box></Typography>
                    <Box>
                        <InputReadQuill
                            id="cuerpo_tk"
                            template={item.cuerpo_com}
                        />
                    </Box>
                </Card>
                <Grid container sx={{display: 'flex', flexDirection: 'row'}}>
                    {
                        item?.img?.map((img)=>{
                            const tipe = img.path_ic.split('.');
                            if(tipe[tipe?.length - 1] === 'jpg' || tipe[tipe?.length - 1] === 'png' || tipe[tipe?.length - 1] === 'jpeg' || tipe[tipe?.length - 1] === 'svg' || tipe[tipe?.length - 1] === 'x-png'){
                                return (
                                    <Grid item xs={6} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <PhotoConsumer
                                            key={`IMG${img.path_ic}`}
                                            src={`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`}
                                            intro={img.path_ic}
                                        >
                                            <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                                <img
                                                    src={`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`}
                                                    alt={`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`}
                                                    style={{width:50,height:50}}
                                                />
                                                <Typography className="text-center text-gray-600 ml-1" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                    {img?.nombre_ic}
                                                </Typography>
                                            </Card>
                                        </PhotoConsumer>
                                    </Grid>
                                )
                            }else if(tipe[tipe?.length - 1] === 'wmv' || tipe[tipe?.length - 1] === 'mp4' || tipe[tipe?.length - 1] === 'avi' || tipe[tipe?.length - 1] === 'mov'){
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={4} className='ml-2 mt-1 cursor-pointer'>
                                        <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                            <Box>
                                                <ReactPlayer
                                                    key={`Video${img.path_ic}`}
                                                    url={`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`}
                                                    width='155px'
                                                    height='60px'
                                                    controls
                                                />
                                            </Box>
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }else if(tipe[tipe?.length - 1] === 'pdf'){
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <Card
                                            sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                            aria-hidden="true"
                                            onClick={()=>saveAs(`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`,img.path_ic)}
                                        >
                                            <img
                                                src='/img/pdf.png'
                                                alt='pdf.png'
                                                style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }else if(tipe[tipe?.length - 1] === 'doc' || tipe[tipe?.length - 1] === 'docx'){
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <Card
                                            sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                            aria-hidden="true"
                                            onClick={()=>saveAs(`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`,img.path_ic)}
                                        >
                                            <img
                                                src='/img/doc.png'
                                                alt='doc.png'
                                                style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }else if(tipe[tipe?.length - 1] === 'xls' || tipe[tipe?.length - 1] === 'xlsx'){
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <Card
                                            sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                            aria-hidden="true"
                                            onClick={()=>saveAs(`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`,img.path_ic)}
                                        >
                                            <img
                                                src='/img/xls.png'
                                                alt='xls.png'
                                                style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }else if(tipe[tipe?.length - 1] === 'txt'){
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <Card
                                            sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                            aria-hidden="true"
                                            onClick={()=>saveAs(`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`,img.path_ic)}
                                        >
                                            <img
                                                src='/img/txt.png'
                                                alt='txt.png'
                                                style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }else{
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                        <Card
                                            sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                            aria-hidden="true"
                                            onClick={()=>saveAs(`${cvv ? urlCvv : urlNubi}${img.path_ic}&token=${cvv && (user?.razonSocial_p === 'Strong Systems SRL' ? tokenStr : tokenNubi) || ''}${!cvv && token || ''}`,img.path_ic)}
                                        >
                                            <img
                                                src='/img/documentAll.png'
                                                alt='documentAll.png'
                                                style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                            />
                                            <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            }
                            return ''
                        })
                    }
                </Grid>
                <Typography sx={{color: '#15294b',textAlign: !esCreador ? 'right' : 'left',marginTop:1}} variant='body2'>
                    {item && !item?.isVisible_com && cvv &&('Comentario Oculto - ')}
                    {`${FechaFunction.format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}`}
                </Typography>
            </Grid>
            {esCreador &&(
                <Grid item xs={1}>
                    <Avatar src={item?.autor?.foto_p} sx={{width:30,height:30,boxShadow:3,marginLeft:0.5}} />
                </Grid>
            )}
        </Grid>
    )
}

ComentarioTicketCard.defaultProps = {
    canShowMore: false,
    buttons: null,
};

export default ComentarioTicketCard;
