
const types = {

    authStartChecking: '[auth] Start checking login',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] login',
    authUserMe: '[auth] userMe',
    authStartLogout: '[auth] Start logout',

    ticketId: '[Tk] Id',
    ticketReloadStart: '[Tk] Reload Start',
    ticketReloadFinish: '[Tk] Reload Finish',

    ticketReloadIndStart: '[Tk] Reload Individual Start',
    ticketReloadIndFinish: '[Tk] Reload Individual Finish',

    ticketReloadGroupStart: '[Tk] Reload Group Start',
    ticketReloadGroupFinish: '[Tk] Reload Group Finish'
}

export default types