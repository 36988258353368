import {useState,useEffect,useContext} from 'react';
import { SocketContext } from '../../context/SocketContext';
import TicketService from '../../services/tickets.service';
import FechaFunction from '../../function/FechaFunction';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ButtonBasic, DateSingle, SelectGrupos } from '../../components';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';


function ModalReaperturaTicket({show, hideModal, onGuardar,ticket,type}) {
    const [isLoading, setIsLoading] = useState(false);
    const [fvencimiento, setFVencimeinto] = useState(null);
    const [grupoId, setGrupoId] = useState(null);
    const handleClose = () => hideModal();
    const { sockets } = useContext( SocketContext );

    useEffect(() => {
        if(ticket && ticket.estado_tk !== 'VENCIDO'){
            const diasRestantes =  FechaFunction.diffDays(ticket.cerrado_tk, ticket.fechavencimiento_tk);
            if(diasRestantes > 0){
                const fecha = FechaFunction.addDaysDate(new Date(),diasRestantes)
                setFVencimeinto(fecha)
            }else{
                const fecha = FechaFunction.addDaysDate(new Date(),1)
                setFVencimeinto(fecha)
            }
        }else{
            const fecha = FechaFunction.addDaysDate(new Date(),1)
            setFVencimeinto(fecha)
        }
    }, [])
    const onSubmit = async() =>{
        const form = {
            id_tk: ticket.id_tk,
            fechavencimiento_tk: fvencimiento,
            type,
            grupoId
        }
        await TicketService.reaperturaTicket(form).then(()=>{
            hideModal();
            onGuardar();
            sockets?.emit( 'editTicketPage', ticket.id_tk )
        })
    }
    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: {xs: '50%', md:'52%'},
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
                minHeight: 200
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {`Reapertura del ticket # ${ticket?.id_tk} `}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom:2}} />
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Box>
                        <Typography sx={{color: '#000'}}>Fecha de vencimiento</Typography>
                        <DateSingle
                            id="vtoTicket"
                            value={fvencimiento}
                            err
                            minDate={new Date()}
                            onChange={(val)=> setFVencimeinto(val)}
                        />
                    </Box>
                    <Box sx={{width: 320,mt: 3}}>
                        <Typography sx={{color: '#000'}}>Seleccione el destinatario</Typography>
                        <SelectGrupos
                            empresaId={1}
                            value={grupoId}
                            onChange={(e)=>setGrupoId(e?.value)}
                        />
                        {!grupoId && (
                            <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>
                                El grupo es requerido
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Divider color='gray' sx={{mt:3}}/>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!grupoId}
                    />
                </Box>
            </Box>
        </Modal>
    );
}
export default ModalReaperturaTicket;
