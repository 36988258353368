import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import {toast} from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import SelectCierreTicket from '../../components/select/SelectCierreTicket';
import { ButtonBasic, SelectSubCierreTicket } from '../../components';
import TicketService from '../../services/tickets.service';

function ModalCierreTicketNubicom({id, show, hideModal,ticket,setIsLoadingPage,confirm, trato}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [cierreTkId, setCierreTkId] = useState();
    const [subCierreTkId, setSubCierreTkId] = useState(null);
    const [arrSubCierres, setArrSubCierres] = useState([]);
    const onSubmit = async() =>{
        if(arrSubCierres?.length > 0 && !subCierreTkId){
            toast.error('Debe seleccionar un Sub-Cierre.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }else{
            const form = {
                id_tk: ticket?.id_tk,
                type: 'nubicom',
                cierreTicketId_tk: cierreTkId,
                subCierreTicketId_tk: null
            }
            if(subCierreTkId){
                form.subCierreTicketId_tk = subCierreTkId;
            }
            if((trato && trato?.id_t && trato?.estadoServicio_t !== 'INSTALADO' && !trato?.isDeleted_t)){
                form.idPreservicio = trato?.id_t
            }
            await TicketService.cerrarTicket(form).then(()=>{
                confirm();
                handleClose()
            })
            .finally(()=>{
                setIsLoadingPage(false);
            })
        }
    }
    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])

    return (
        <Modal open={show} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' fullWidth>
            <Box sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '95%',md:'40%'},
                background: '#fff',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
            }}>
                <Grid container xs={12}>
                    <Grid item xs={11} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='h4' sx={{textAlign: 'center',marginLeft: 4, color: '#000'}}>
                            {`¿Cerrar el Ticket Nro ${ticket?.id_tk}?`}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={()=>handleClose()}>
                            <CloseIcon sx={{color: '#000'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider color='gray' sx={{marginBottom: 1}} />
                <Typography sx={{color: '#000',textAlign: 'center'}} variant='h5'>¿Esta seguro que desea cerrar el Ticket?</Typography>
                <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                    <Box sx={{width: '100%',p:1}}>
                        <Typography sx={{color: '#000',marginTop:2}}>Por favor indique el cierre del ticket</Typography>
                        <SelectCierreTicket
                            value={cierreTkId}
                            onChange={(opt)=>{
                                setCierreTkId(opt.value)
                                setArrSubCierres(opt?.arrSubCierreId || [])
                                setSubCierreTkId(null);
                            }}
                            subTipo={ticket?.subTipoTicketId_tk}
                        />
                    </Box>
                </Box>
                {arrSubCierres?.length > 0 && (
                    <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: 'column',width: '100%'}}>
                        <Box sx={{width: '100%',p:1}}>
                            <Typography sx={{color: '#000',marginTop:0}}>Sub-Cierre del ticket</Typography>
                            <SelectSubCierreTicket
                                value={subCierreTkId}
                                onChange={(opt)=>setSubCierreTkId(opt.value)}
                                filterId={arrSubCierres}
                            />
                        </Box>
                    </Box>
                )}
                <Divider color='gray' sx={{marginTop:1}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:1}}>
                    <ButtonBasic
                        onClick={()=>handleClose()}
                        textButton='CANCELAR'
                        variant='wk'
                        color='cancel'
                    />
                    <ButtonBasic
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        textButton='GUARDAR'
                        sx={{ml:1}}
                        variant='wk'
                        color='nextStep'
                        isDisabled={!cierreTkId}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

ModalCierreTicketNubicom.propTypes = {
    show: PropTypes.bool
};

ModalCierreTicketNubicom.defaultProps = {
    show: false
};

export default ModalCierreTicketNubicom;