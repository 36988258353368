import { Grid, TextField, Typography } from "@mui/material"
import { useMemo,useState,useEffect } from "react"
import { ButtonBasic, SelectEstadoCliente, SelectLocalidadesSoftland, SelectTipoDocSoftland } from "../../../components";
import ContactosService from "../../../services/contactos.service";
import CorporativoService from "../../../services/corporativo.service";
import GobiernoService from "../../../services/gobierno.service";

const TabContactoDatos = ({contacto,onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [formAll, setFormAll] = useState({
        apellido_c: {
            texto: null,
            textoErr: 'El apellido es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nombre_c: {
            texto: null,
            textoErr: 'El nombre es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        tipoDoc_c: {
            texto: null,
            textoErr: 'El tipo de documento es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        documento_c: {
            texto: null,
            textoErr: 'El documento es requerido',
            estadoErr: false,
            tipo: 'number'
        },
        fnacimiento_icon: {
            texto: null,
            textoErr: 'El documento es requerido',
            estadoErr: false,
            tipo: 'number'
        },
        celular_c: {
            texto: null,
            textoErr: 'El celular es requerido',
            estadoErr: false,
            tipo: 'number'
        },
        telefono_c: {
            texto: null,
            textoErr: 'El telefono es requerido',
            estadoErr: false,
            tipo: 'number'
        },
        email_c: {
            texto: null,
            textoErr: 'El correo es requerido',
            estadoErr: false,
            tipo: 'email'
        },
        cpostal_c: {
            texto: null,
            textoErr: 'El CCPP es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        provincia_c: {
            texto: null,
            textoErr: 'La provincia es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        barrio_c: {
            texto: null,
            textoErr: 'El barrio es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        direccion_c: {
            texto: null,
            textoErr: 'La dirección es requerida',
            estadoErr: false,
            tipo: 'string'
        },
        estadoCliente_c: {
            texto: null,
            textoErr: 'El estado es requerido',
            estadoErr: false,
            tipo: 'string'
        },
        nombreCompleto_c: {
            texto: null,
            textoErr: 'La razón social es requerida',
            estadoErr: false,
            tipo: 'string'
        },
    });
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const onChangeForm = (value,input) =>{
        let formOld = formAll;
        if(!formOld[input]){
            formOld[input] = {
                texto: value,
                textoErr: `El ${input} es requerido`,
                estadoErr: false,
                tipo: 'string'
            }
        }else{
            formOld[input].texto = value
        }
        if((!value || value === '') && input !== 'telefono_c'){
            formOld[input].estadoErr = true
        }else{
            if(input === 'email_c'){
                if(validateEmail(value)){
                    formOld[input].estadoErr = false
                }else{
                    formOld[input].estadoErr = true
                }
            }else{
                formOld[input].estadoErr = false
            }
        }
        setFormAll({...formOld})
    }
    const onChangeFormSetError = (input,clear) =>{
        let formOld = formAll;
        formOld[input].estadoErr = !clear ? true : false
        setFormAll({...formOld})
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
            },
            '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
            },
            '&.Mui-focused': {
            '&:hover':{
                border:0,
                height: 40
            }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    useEffect(() => {
        if(contacto){
            onChangeForm(contacto?.apellido_c || '', 'apellido_c')
            onChangeForm(contacto?.barrio_c || '', 'barrio_c')
            onChangeForm(contacto?.celular_c || '', 'celular_c')
            onChangeForm(contacto?.cpostal_c || '', 'cpostal_c')
            onChangeForm(contacto?.direccion_c || '', 'direccion_c')
            onChangeForm(contacto?.documento_c || '', 'documento_c')
            onChangeForm(contacto?.email_c || '', 'email_c')
            onChangeForm(contacto?.nombre_c || '', 'nombre_c')
            onChangeForm(contacto?.provincia_c || '', 'provincia_c')
            onChangeForm(contacto?.tipoDoc_c || '', 'tipoDoc_c')
            onChangeForm(contacto?.telefono_c || '', 'telefono_c')
            onChangeForm(contacto?.estadoCliente_c || '', 'estadoCliente_c')
            onChangeForm(contacto?.nombreCompleto_c || '', 'nombreCompleto_c')
        }
    }, [contacto])
    const onSubmitContacto = async() => {
        const form = {...contacto,type: 'nubicom'};
        let isOk = false;
        setIsLoading(true);
        let inputRequired = ['tipoDoc_c','celular_c', 'barrio_c', 'direccion_c']
        if(contacto?.contxemp?.length > 0 && (contacto?.contxemp[0]?.categoria_cxe === 'COR' || contacto?.contxemp[0]?.categoria_cxe === 'GOB' )){
            inputRequired = ['nombreCompleto_c']
        }
        if(formAll.tipoDoc_c.texto === '80'){
            inputRequired.push('nombreCompleto_c');
        }else{
            inputRequired.push('apellido_c', 'nombre_c');
        }
        if(contacto?.documento_c){
            inputRequired.push('documento_c')
        }
        if(contacto?.email_c){
            inputRequired.push('email_c')
        }
        if(contacto?.cpostal_c){
            inputRequired.push('cpostal_c','provincia_c')
        }
        const propertyNames = Object.keys(formAll);
        await Promise.resolve(
            propertyNames.forEach((name)=>{
            if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
                form[name] = formAll[name].texto
            }else{
                if(inputRequired.includes(name)){
                isOk = true;
                onChangeFormSetError(name)
                }
            }
        }))
        if(!isOk){
            if(contacto?.contxemp?.length > 0 && contacto?.contxemp[0]?.categoria_cxe === 'COR'){
                form.apellido_c = form.nombreCompleto_c;
                form.nombre_c = form.nombreCompleto_c;
                CorporativoService.updateContacto({
                    ...contacto,
                    ...form
                }).then(()=>{
                    setIsLoading(false);
                    if(onGuardar){
                        onGuardar()
                    }
                }).catch(()=>{
                    setIsLoading(false);
                })
            }else if(contacto?.contxemp?.length > 0 && contacto?.contxemp[0]?.categoria_cxe === 'GOB'){
                form.apellido_c = form.nombreCompleto_c;
                form.nombre_c = form.nombreCompleto_c;
                GobiernoService.updateContacto({
                    ...contacto,
                    ...form
                }).then(()=>{
                    setIsLoading(false);
                    if(onGuardar){
                        onGuardar()
                    }
                }).catch(()=>{
                    setIsLoading(false);
                })
            }else{
                if(formAll.tipoDoc_c.texto === '96'){
                    form.nombreCompleto_c = `${form.apellido_c.toUpperCase()} ${form.nombre_c.toUpperCase()}`;
                }
                await ContactosService.update({
                    ...contacto,
                    ...form
                }).then(()=>{
                    setIsLoading(false);
                    if(onGuardar){
                        onGuardar()
                    }
                }).catch(()=>{
                    setIsLoading(false);
                })
            }
        }else{
            setIsLoading(false);
        }
    }
    return (
        <Grid container spacing={2}>
            {(contacto?.contxemp?.length > 0 && (contacto?.contxemp[0]?.categoria_cxe === 'COR' || contacto?.contxemp[0]?.categoria_cxe === 'GOB') || formAll.tipoDoc_c.texto === '80') ? (
                <Grid item xs={12} sm={8} md={8}>
                    <Typography sx={{color: '#000'}}>Razón Social</Typography>
                    <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll.nombreCompleto_c.texto}
                        onChange={(e)=>onChangeForm(e.target.value,'nombreCompleto_c')}
                        error={formAll.nombreCompleto_c.estadoErr}
                        helperText={formAll.nombreCompleto_c.estadoErr && formAll.nombreCompleto_c.textoErr}
                    />
                </Grid>
            ):(
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography sx={{color: '#000'}}>Apellido</Typography>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            sx={styleInputHeight}
                            value={formAll.apellido_c.texto}
                            onChange={(e)=>onChangeForm(e.target.value,'apellido_c')}
                            error={formAll.apellido_c.estadoErr}
                            helperText={formAll.apellido_c.estadoErr && formAll.apellido_c.textoErr}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography sx={{color: '#000'}}>Nombre</Typography>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            sx={styleInputHeight}
                            value={formAll.nombre_c.texto}
                            onChange={(e)=>onChangeForm(e.target.value,'nombre_c')}
                            error={formAll.nombre_c.estadoErr}
                            helperText={formAll.nombre_c.estadoErr && formAll.nombre_c.textoErr}
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={4} sm={3} md={1.5}>
                <Typography sx={{color: '#000'}}>Tipo Doc.</Typography>
                <SelectTipoDocSoftland
                    isInvalid={formAll.tipoDoc_c.estadoErr}
                    value={formAll.tipoDoc_c.texto}
                    onChange={(e)=>{
                        onChangeForm(e.value,'tipoDoc_c')
                        if(e.value === '96'){
                        onChangeForm('C','iva_c')
                        }else{
                        onChangeForm('I','iva_c')
                        }
                    }}
                />
                {formAll.tipoDoc_c.estadoErr && (
                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoDoc_c.textoErr}</Typography>
                )}
            </Grid>
            <Grid item xs={8} sm={9} md={2.5}>
                <Typography sx={{color: '#000'}}>Numero</Typography>
                <TextField
                    fullWidth
                    sx={styleInputHeight}
                    autoComplete='off'
                    type='number'
                    value={formAll.documento_c.texto}
                    onChange={(e)=>onChangeForm(Number(e.target.value),'documento_c')}
                    InputProps={{ inputProps: { min: 100000000 } }}
                    error={formAll.documento_c.estadoErr && contacto?.documento_c}
                    helperText={formAll.documento_c.estadoErr && formAll.documento_c.textoErr && contacto?.documento_c}
                    FormHelperTextProps={{ className: '-mt-1' }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Correo</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    value={formAll.email_c.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'email_c')}
                    error={formAll.email_c.estadoErr && contacto?.email_c}
                    helperText={formAll.email_c.estadoErr && formAll.email_c.textoErr && contacto?.email_c}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Celular</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    type='number'
                    value={formAll.celular_c.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'celular_c')}
                    InputLabelProps={{shrink: formAll.celular_c.texto}}
                    error={formAll.celular_c.estadoErr}
                    helperText={formAll.celular_c.estadoErr && formAll.celular_c.textoErr}
                    InputProps={{ inputProps: { min: 100000000 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Telefono</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    type='number'
                    value={formAll.telefono_c.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'telefono_c')}
                    error={formAll.telefono_c.estadoErr}
                    helperText={formAll.telefono_c.estadoErr && formAll.telefono_c.textoErr}
                    InputProps={{ inputProps: { min: 100000000 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Localidad</Typography>
                <SelectLocalidadesSoftland
                    value={formAll.cpostal_c.texto}
                    isInvalid={formAll.cpostal_c.estadoErr && contacto?.cpostal_c}
                    onChange={(e)=>{
                        onChangeForm(e.value,'cpostal_c')
                        onChangeForm(e.provinciaId,'provincia_c')
                    }}
                />
                {formAll.cpostal_c.estadoErr && contacto?.cpostal_c && (
                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cpostal_c.textoErr}</Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Barrio</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    value={formAll.barrio_c.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'barrio_c')}
                    error={formAll.barrio_c.estadoErr && contacto?.barrio_c}
                    helperText={formAll.barrio_c.estadoErr && formAll.barrio_c.textoErr && contacto?.barrio_c}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Calle</Typography>
                <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    value={formAll.direccion_c.texto}
                    onChange={(e)=>onChangeForm(e.target.value,'direccion_c')}
                    error={formAll.direccion_c.estadoErr && contacto?.direccion_c}
                    helperText={formAll.direccion_c.estadoErr && formAll.direccion_c.textoErr && contacto?.direccion_c}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{color: '#000'}}>Estado</Typography>
                <SelectEstadoCliente
                    value={formAll.estadoCliente_c.texto}
                    isInvalid={formAll.estadoCliente_c.estadoErr && contacto?.estadoCliente_c}
                    onChange={(e)=>onChangeForm(e.value,'estadoCliente_c')}
                />
                {formAll.estadoCliente_c.estadoErr && contacto?.estadoCliente_c && (
                    <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.estadoCliente_c.textoErr}</Typography>
                )}
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end'>
                <ButtonBasic
                    textButton="Guardar"
                    variant="wk"
                    color="nextStep"
                    sx={{width: 150}}
                    onClick={()=>onSubmitContacto()}
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    )
}

export default TabContactoDatos