import { Box, Card, IconButton, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
const CardUploadFile = ({item,onDeleted}) => {
    const [tipo, setTipo] = useState(null)
    useEffect(() => {
        const tipe = item?.path?.split('.');
        if(tipe?.length > 0){
            setTipo(tipe[tipe?.length - 1])
        }
    }, [])

    return (
        <Box sx={{position:'relative'}}>
            { tipo === 'wmv' || tipo === 'mp4' || tipo === 'avi' || tipo === 'mov'  ? (
                <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3,mt:1}}>
                    <Box>
                        <ReactPlayer
                            key={item?.id}
                            url={item?.preview}
                            width='155px'
                            height='60px'
                            controls
                        />
                    </Box>
                    <Typography className="text-center text-gray-600 ml-1" sx={{color: '#000',fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                        {item?.path || item?.name}
                    </Typography>
                    {onDeleted && (
                        <IconButton sx={{top:0,position: 'absolute',right: 0,boxShadow:1}} onClick={()=>onDeleted(item)}>
                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                        </IconButton>
                    )}
                </Card>
            ):(
                <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3,mt:1}}>
                    <img
                        key={item?.id}
                        src={item?.preview}
                        alt={item?.path}
                        style={{width:60,height:60}}
                    />
                    <Typography className="text-center text-gray-600 ml-1" style={{color: '#000' ,fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                        {item?.path || item?.name}
                    </Typography>
                    {onDeleted && (
                        <IconButton sx={{top:-4,position: 'absolute',right: 0,boxShadow:0,'&:hover':{background:'#fce7e7',boxShadow:4}}} onClick={()=>onDeleted(item)}>
                            <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                        </IconButton>
                    )}
                </Card>
            )}
        </Box>
    )
}

export default CardUploadFile