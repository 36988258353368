import { Card, Grid, IconButton, Typography } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const CardItemSAIPrefa = ({item,onEliminar}) => {
    return (
        <Card sx={{background: '#fff !important', boxShadow: 3, width: '90%', mt: 1}}>
            <Grid container p={3}>
                <Grid item xs={11} md={11} mb={2}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}} variant='h6'>
                        {item?.nombreServicio_ds || item?.nombre_s}
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                    <IconButton
                        sx={{
                            background: '#fff',
                            boxShadow: 4
                        }}
                        onClick={()=>onEliminar(item?.id_ds || item?.id)}
                    >
                        <DeleteOutlineOutlinedIcon sx={{color: 'red'}} />
                    </IconButton>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}}>
                        {`Cantidad: ${((item?.cantidad_ds || item?.cantidad_s) / 1.0000) * 100}%`}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold'}}>
                        {`Sub-Total: $${((item?.importeServicio_ds || item?.importe_s || 0) * (item?.cantidad_ds || item?.cantidad_s) || 1)?.toFixed(6)} ${item?.moneda_ds || item?.moneda_s || 'USD'}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{color: '#15294b'}}>
                        {`Observaciones: ${item?.obs_ds || item?.obs_s || '-'}`}
                    </Typography>
                </Grid>
                {(item?.nivelBonificacion === 'bonificacion_nivel2' || item?.nivelBonificacion === 'bonificacion_nivel3') && (
                    <Grid item xs={12}>
                        <Typography sx={{color: '#e01010',fontWeight: 'bold',textAlign:'center'}}>Se requiere autorización.</Typography>
                    </Grid>
                )}
            </Grid>
        </Card>
    )
}

export default CardItemSAIPrefa