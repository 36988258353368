import axios from 'axios';
const base = 'fileadj';

function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

const FileAdjContactoService = {
    getAll
};
export default FileAdjContactoService;
