import { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, tooltipClasses, Typography } from "@mui/material"
import {showModal} from 'react-redux-modal-provider';
import { TableBase } from "../../components"
import FechaFunction from "../../function/FechaFunction";
import { styled } from '@mui/material/styles';
import PreTicketsService from "../../services/preTickets.service";
import EmailIcon from '@mui/icons-material/Email';
import ModalVerPreTicket from "../../modals/preTicket/ModalVerPreTicket";
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MoverListaTicketsCreados from "../../modals/preTicket/MoverListaTicketsCreados";
import ModalCancelarPreTicketView from "../../modals/preTicket/ModalCancelarPreTicketView";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useNavigate } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const PreTicketsScreen = () => {
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState(false);
    const navigate = useNavigate();

    const actualizar = async () => {
        await PreTicketsService.getAll({all: allItems}).then((r)=>{
            setItems(r)
        })
    };

    const onOpenVer = (idCliente) => {
        const item = items.find((it)=> it.id_ptke === idCliente);
        showModal(ModalVerPreTicket,{item: item,onGuardar: actualizar})
    }

    const onOpenVerTkCreados = (idCliente) => {
        const item = items.find((it)=> it.id_ptke === idCliente);
        showModal(MoverListaTicketsCreados,{item: item,onGuardar: actualizar})
    }

    const onOpenVerTkInvalido = (idPtk) => {
        const item = items.find((it)=> it.id_ptke === idPtk);
        showModal(ModalCancelarPreTicketView,{item: item})
    }

    const onOpenVerTkVinculado = (idptk) => {
        const item = items.find((it)=> it.id_ptke === idptk);
        if(item?.ticketIdVinculado_ptke){
            navigate(`/ticket/${item?.ticketIdVinculado_ptke}/nubicom`)
        }
    }
    const restablecerPtk = async(idPtk) => {
        await PreTicketsService.restablecerPreTk({idPtk}).then(async()=>{
            await actualizar()
        })
    }
    const onOpenReaperturaPTK = (idptk) => {
        const item = items.find((it)=> it.id_ptke === idptk);
        if(item?.id_ptke){
            showModal(ConfirmationDialog,{
                title: 'Esta por restablecer el pre-ticket',
                message: '¿Esta seguro que desea continuar? Al confirmar se llevara a cabo el proceso de restauracion.',
                onConfirm: ()=>restablecerPtk(item?.id_ptke)
            })
        }
    }

    const columns = [
        {
            name: "id_ptke",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "createdAt",
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return FechaFunction.format(new Date(value),'dd/MM/yyyy HH:mm:ss');
                }
            }
        },
        {
            label: "Remitente",
            name: "from_ptke",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: 'Asunto',
            name: 'subject_ptke',
        },
        {
            name: "ticketIdVinculado_ptke",
            label: "Nro Vinculado",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "isDeleted_ptke",
            label: "isDeleted",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "isCreated_ptke",
            label: "isCreated",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "motivoCancelado_ptke",
            label: "motivoCancelado",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Box display='flex'>
                        {!tableMeta.rowData[5] && !tableMeta.rowData[6] && (
                            <LightTooltip  title="Ver Correo" placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVer(tableMeta.rowData[0])}>
                                    <EmailIcon fontSize="medium" sx={{color: '#012a6b'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {tableMeta.rowData[5] && !tableMeta.rowData[6] && (
                            <LightTooltip  title={`Motivo: ${tableMeta.rowData[7]}`} placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVerTkInvalido(tableMeta.rowData[0])}>
                                    <UnsubscribeIcon fontSize="medium" sx={{color: 'red'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {!tableMeta.rowData[5] && tableMeta.rowData[6] && !tableMeta.rowData[4] && (
                            <LightTooltip  title='Ver Tickets Creados' placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVerTkCreados(tableMeta.rowData[0])}>
                                    <AssignmentTurnedInIcon fontSize="medium" sx={{color: 'green'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {!tableMeta.rowData[5] && tableMeta.rowData[6] && tableMeta.rowData[4] && (
                            <LightTooltip  title={`Ticket Vinculado #${tableMeta.rowData[4]}`} placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenVerTkVinculado(tableMeta.rowData[0])}>
                                    <InsertLinkIcon fontSize="medium" sx={{color: 'green'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                        {(tableMeta.rowData[4] || tableMeta.rowData[5] || tableMeta.rowData[6] || tableMeta.rowData[7]) && (
                            <LightTooltip  title='Restaurar' placement="top">
                                <IconButton color='primary' aria-label="ver" onClick={()=>onOpenReaperturaPTK(tableMeta.rowData[0])}>
                                    <AutorenewIcon fontSize="medium" sx={{color: 'blue'}} />
                                </IconButton>
                            </LightTooltip>
                        )}
                    </Box>
                )
            }
        }
    ];
    useEffect(() => {
        actualizar()
    }, [allItems])
    useEffect(() => {
        document.title = `Pre-Tickets`;
    }, [])
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={4}>
                <Grid item xs={12} md={9} lg={9}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Pre-Tickets</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControlLabel
                        labelPlacement='end'
                        control={
                            <Checkbox
                                onClick={()=>setAllItems(!allItems)}
                                checked={allItems}
                                sx={{
                                    color: '#0247B5',
                                    ml: 2,
                                    '&.Mui-checked': {
                                        color: '#0247B5',
                                    },
                                }}
                            />
                        }
                        label="Obtener todos"
                        componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                    />
                </Grid>
                <Grid item xs={12} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PreTicketsScreen;

