import React from 'react';
import ReactDOM from 'react-dom/client';
import {toast} from 'react-toastify';
import './index.css';
import './utils/axios';
import './utils/date';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

toast.configure({
  autoClose: 3000,
  draggable: false,
  position: 'top-right',
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  pauseOnHover: true
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

