import { useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import FormPaso1Screen from './FormPaso1Screen';
import GobiernoAction from '../../../store/actions/gobierno';
import { Box } from '@mui/material';

const ContactoNuevoGobiernoScreen = ({
    iniciarContacto,
    iniciarContrato
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const { item } = (location && location.state) || { };

    useEffect(() => {
        if(item){
            iniciarContacto(item);
            if(item.contrato){
                iniciarContrato(item.contrato);
            }
        }
        document.title = `Nuevo Gobierno`;
        navigate('/contactos/nuevogobierno#step1');
        return () => {
            iniciarContacto(null);
            iniciarContrato(null);
        }
    }, []);

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto',maxWidth:'1280px', margin: 'auto'}} className='side'>
            <Box p={2} marginBottom={0}>
                <FormPaso1Screen item={item} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.gobierno.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(GobiernoAction.iniciarContactoGobierno(data)),
    iniciarContrato: (data) => dispatch(GobiernoAction.iniciarContratoGobierno(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoNuevoGobiernoScreen);
