import {useMemo, useState} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import AuxiliarFunction from '../../function/AuxiliarFunction';

function getOptionSelect(value,options){
    if(!Array.isArray(options)){
        return null;
    }
    if(AuxiliarFunction.isObject(value)){
        return value;
    }
    const option = options.find(opt => opt.value === value);
    if(option){
        return option;
    }
    return null;
}

const Select = ({
    id,
    options,
    defaultValue: externalDefaultValue,
    onChange,
    isInvalid,
    isClearable
}) => {
    const [defaultValue, setDefaultValue] = useState(getOptionSelect(externalDefaultValue,options));
    const onSelectValue = (ev) => {
        onChange(ev);
    };
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <ReactSelect
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            defaultValue={defaultValue}
            onChange={onSelectValue}
            isClearable={isClearable}
        />
    );
};

Select.propTypes = {
    isClearable: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
        })
    ),
};

Select.defaultProps = {
    isClearable: false,
    options: [],
};

export default Select;
