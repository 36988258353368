import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Box, InputAdornment, TextField } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FechaFunction from '../../function/FechaFunction';

const DateMonth = ({ onChange, isDisabled, value, showTimeSelect, isFullWidth, ...props }) => {
    const [fecha, setFecha] = useState(value ? FechaFunction.parse(value) : null);
    const today = new Date();
    const minSelectableDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const CustomInput = React.forwardRef(
        ({ value: valueField, onClick }, ref) => (
            <TextField
                onClick={onClick}
                value={valueField}
                ref={ref}
                readOnly
                autoComplete='off'
                focused
                InputProps={{
                    startAdornment: (
                        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',alignContent:'center', backgroundColor:'#b1cfff',height:{xs:41,xl:42},width:50,marginLeft:-1.7,borderTopLeftRadius:4,borderBottomLeftRadius:5}}>
                            <InputAdornment position="start">
                                <CalendarMonthIcon sx={{color: '#0364ff',marginLeft:1}} />
                            </InputAdornment>
                        </Box>
                    ),
                }}
                sx={{
                    '& fieldset': {
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        borderColor: '#b1cfff',
                        },
                    },
                    '& input': {
                        color: '#000',
                        background: '#fff',
                        borderBottomRightRadius: 10,
                        borderTopRightRadius:10,
                        height:10,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    },
                }}
            />
        )
    );

    const onDateSelected = (newDate) => {
        const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
        const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
        setFecha(newDate);
        onChange({
            startDate: firstDay,
            endDate: lastDay
        });
    };

    useEffect(() => {
        setFecha(minSelectableDate)
    }, []);

    return (
        <DatePicker
            selected={fecha}
            onChange={(date) => onDateSelected(date)}
            dateFormat={showTimeSelect ? "MM/yyyy h:mm aa" : "MM/yyyy"}
            showTimeSelect={showTimeSelect}
            disabled={isDisabled}
            customInput={<CustomInput />}
            popperPlacement="auto"
            showMonthYearPicker
            minDate={minSelectableDate}
            {...props}
        />
    );
}

export default DateMonth;