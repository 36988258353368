import axios from 'axios';
const base = 'reportes';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function update(data) {
    return axios.put([base,'update', data.id_rts].join('/'), data);
}

function compartir(data) {
    return axios.put([base,'compartir', data.id_rts].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function listaReport(query = null) {
    return axios.get([base, 'lista'].join('/'), {
        params: query
    });
}

function destroyListReport(id) {
    return axios.delete([base,'lista',id].join('/'));
}

function reporteServicioId(id) {
    return axios.get(`${base}/servicio/${id}`,{
        responseType: 'blob'
    });
}

const ReportesService = {
    getAll,
    register,
    update,
    compartir,
    destroy,
    listaReport,
    destroyListReport,
    reporteServicioId
};
export default ReportesService;
