import { useState, useMemo, useEffect } from 'react';
import AuxSoftland from '../../../function/AuxSoftland';
import {toast} from 'react-toastify';
import ContactosAction from '../../../store/actions/contactos';
import { connect } from 'react-redux';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateSingle, SelectCanalesSoftland, SelectListPrecioSoftland, SelectLocalidadesSoftland } from '../../../components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FechaFunction from '../../../function/FechaFunction';
const FormPaso2SinCuenta = ({
  item,
  contrato,
  nextStep,
  previousStep,
  iniciarContrato
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datosTrato, setDatosTrato] = useState(contrato);
  const [fechaAlta, setFechaAlta] = useState(new Date());

  const onChangeFormTrato = ( value, field ) => {
    setDatosTrato({
        ...datosTrato,
        [field]: value
    });
  }
  const onSubmit = async() => {
    const verify = await AuxSoftland.verificarContrato({...datosTrato, fechaAlta:fechaAlta})
    if(verify?.estado === 'ok'){
        iniciarContrato({...datosTrato, fechaAlta:fechaAlta})
        nextStep()
    }else if(Array.isArray(verify?.error) && verify?.error?.length > 0){
      verify?.error.forEach((e)=>{
        toast.error(`Verifique los datos ingresados en ${e}`, {
          position: "top-right",
          autoClose: 4500,
          pauseOnHover: false,
        });
      })
    }else{
      toast.error('Verifique los datos ingresados', {
          position: "top-right",
          autoClose: 4500,
          pauseOnHover: false,
      });
    }
  }

  useEffect(() => {
    if(contrato){
        setDatosTrato(contrato)
        if(contrato?.fechaInstalacionReal_t){
            setFechaAlta(contrato?.fechaInstalacionReal_t)
        }else{
          setFechaAlta(FechaFunction.format(new Date(),'yyyy-MM-dd'))
        }
    }
  }, [contrato]);
  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
        color: 'gray',
        fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
        marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
        },
        '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
        },
        '&.Mui-focused': {
            '&:hover':{
            border:0,
            height: 40
            }
        },
        color: 'gray',
        height: 40
    }
  }), [])
  return (
    <Box>
      <Card sx={{background: '#fff !important', p: 2,mb: 15}}>
        <Typography sx={{color: '#000', fontWeight: 'bold'}} variant='h3'>Datos del Contrato</Typography>
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{color: '#000',fontSize:12}}>Fecha de Alta</Typography>
            <DateSingle
              isFullWidth
              maxDate={new Date()}
              id="fechaAlta"
              value={fechaAlta}
              onChange={(value)=>setFechaAlta(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{color: '#000',fontSize:12}}>Empresa</Typography>
            <TextField
                fullWidth
                autoComplete='off'
                sx={styleInputHeight}
                value={datosTrato?.empresaSoft_t}
                inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{color: '#000',fontSize:12}}>Tipo Contrato</Typography>
            <TextField
                fullWidth
                autoComplete='off'
                sx={styleInputHeight}
                value={datosTrato?.tipoConSoft_t}
                inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography sx={{color: '#000',fontSize:12}}>Contratista</Typography>
            <SelectCanalesSoftland
                id="cod_contratista"
                value={datosTrato?.contratistaSoft_t}
                onChange={(value)=>onChangeFormTrato(value,'cod_contratista')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography sx={{color: '#000',fontSize:12}}>Lista de Precio</Typography>
            <SelectListPrecioSoftland
              value={datosTrato?.listaPrecioSoft_t}
              onChange={(opt)=>onChangeFormTrato(opt.value,'listaPrecioSoft_t')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography sx={{color: '#000',fontSize:12}}>Codigo Postal</Typography>
            <SelectLocalidadesSoftland
              id="codpostal_t"
              value={datosTrato?.codpostal_t}
              onChange={(value)=>{
                onChangeFormTrato(value.provinciaId,'codprovincia_t')
                onChangeFormTrato(value.value,'codpostal_t')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <Typography sx={{color: '#000',fontSize:12}}>Domicilio</Typography>
            <TextField
                fullWidth
                autoComplete='off'
                sx={styleInputHeight}
                value={datosTrato?.direccion_t}
                onChange={({target})=>onChangeFormTrato(target.value,'direccion_t')}
            />
          </Grid>
        </Grid>
      </Card>
      <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
        <ButtonBasic
          textButton='Volver'
          variant='wk'
          color='nextWhite'
          sx={{border: 1, borderColor: '#0364ff'}}
          onClick={()=>previousStep()}
          iconStart={<ArrowBackIcon sx={{color: '#0364ff'}} />}
        />
        <ButtonBasic
          textButton='Continuar'
          variant='wk'
          color='nextStep'
          iconEnd={<ArrowForwardIcon sx={{color: '#fff'}} />}
          sx={{ml:2}}
          onClick={()=>onSubmit()}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso2SinCuenta);