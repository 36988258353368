import { useState, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectReportUser} from '../../components';
import DownloadFile from '../../services/downloadFile.service';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from 'react-redux-modal-provider';
import ModalRepDownRangoFecha from './ModalRepDownRangoFecha';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FechaFunction from '../../function/FechaFunction';
import ConfirmationDialog from '../default/ConfirmationDialog';
import {toast} from 'react-toastify';

const ModalReporteProgramado = ({show, hideModal, item, reporteArr,onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reporteSelect, setReporteSelect] = useState(null);
    const [reporteTipoSelect, setReporteTipoSelect] = useState(null);
    const [rangoFechasArray, setRangoFechasArray] = useState([]);
    const [tipoPrograma, setTipoPrograma] = useState('DIA');
    const [tipoProgramaData, setTipoProgramaData] = useState({
        diaLunes_lr: false,
        diaMartes_lr: false,
        diaMiercoles_lr: false,
        diaJueves_lr: false,
        diaViernes_lr: false,
        diaSabado_lr: false,
        diaDomingo_lr: false,
    });
    const [tipoProgramTime, setTipoProgramTime] = useState('12:00');
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        if(tipoPrograma === 'DIA' &&
            ( !tipoProgramaData.diaLunes_lr &&
            !tipoProgramaData.diaMartes_lr &&
            !tipoProgramaData.diaMiercoles_lr &&
            !tipoProgramaData.diaJueves_lr &&
            !tipoProgramaData.diaViernes_lr &&
            !tipoProgramaData.diaSabado_lr &&
            !tipoProgramaData.diaDomingo_lr)
        ){
            toast.error('Debe seleccionar un día', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }else{
            setIsLoading(true)
            const form = {
                reporteId_lr: reporteSelect,
                rangoFechasArray: rangoFechasArray,
                tipo_lr: tipoPrograma,
                horario_lr: `${tipoProgramTime}:00`,
                ...tipoProgramaData,
            };
            if(item?.id_lr){
                await DownloadFile.updateReportExcelNubiV2({...item,...form})
                .then(()=>{
                    if(onGuardar){
                        onGuardar()
                    }
                    handleClose()
                })
                .catch(()=>{
                    setIsLoading(false)
                })
                .finally(()=>{
                    setIsLoading(false)
                })
            }else{
                await DownloadFile.reportExcelNubiV2(form)
                .then(()=>{
                    if(onGuardar){
                        onGuardar()
                    }
                    handleClose()
                })
                .catch(()=>{
                    setIsLoading(false)
                })
                .finally(()=>{
                    setIsLoading(false)
                })
            }
        }
    };
    const onOpenModalRangoFecha = () => {
        showModal(ModalRepDownRangoFecha, { tipo: reporteTipoSelect,rangoFechasArray: rangoFechasArray, setRangoFechasArray: setRangoFechasArray })
    }
    const onEliminarValid = (idValid) =>{
        const validFilter = rangoFechasArray?.filter((ivd)=> ivd?.keyCampo !== idValid)
        setRangoFechasArray(validFilter)
    }
    const onOpenModalDeleteValid = (idValid) =>{
        showModal(ConfirmationDialog, {
            title: 'Eliminar el filtro',
            message: '¿Esta seguro que desea eliminar el filtro?',
            onConfirm: () => onEliminarValid(idValid)
        });
    }
    const onSelectTipoReport = (repJson) => {
        setReporteSelect(repJson?.value)
        if(repJson?.tipo_rts !== reporteTipoSelect){
            setRangoFechasArray([]);
            setReporteTipoSelect(repJson?.tipo_rts)
        }
    }
    const styleBtnSelect = useMemo(() => ({
        border:1,borderColor: '#15294b', color: '#15294b',background:'white',
        '&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);

    const styleBtnNone = useMemo(() => ({
        background:'#dfe2e6',boxShadow:0,border:1,borderColor: '#8993a4','&:hover':{border:1,borderColor: '#15294b', color: '#15294b',background:'#dfe2e6'}
    }),[]);
    const onSelectTipoProg = (tipo) =>{
        setTipoPrograma(tipo)
        if(tipo === 'NUMERO'){
            setTipoProgramaData({numero_lr: 1})
        }else{
            setTipoProgramaData({
                diaLunes_lr: false,
                diaMartes_lr: false,
                diaMiercoles_lr: false,
                diaJueves_lr: false,
                diaViernes_lr: false,
                diaSabado_lr: false,
                diaDomingo_lr: false,
            })
        }
    }
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);

    useEffect(() => {
        if(item){
            setTimeout(() => {
                setReporteSelect(item?.reporteId_lr)
                setReporteTipoSelect(item?.['report.tipo_rts'])
                setRangoFechasArray(JSON.parse(item?.rangoFecha_lr))
                setTipoPrograma(item?.tipo_lr)
                const timeNew = item?.horario_lr.split(':')
                setTipoProgramTime(`${timeNew[0]}:${timeNew[1]}`);
                setTipoProgramaData({
                    diaLunes_lr: item?.diaLunes_lr,
                    diaMartes_lr: item?.diaMartes_lr,
                    diaMiercoles_lr: item?.diaMiercoles_lr,
                    diaJueves_lr: item?.diaJueves_lr,
                    diaViernes_lr: item?.diaViernes_lr,
                    diaSabado_lr: item?.diaSabado_lr,
                    diaDomingo_lr: item?.diaDomingo_lr,
                    numero_lr: item?.numero_lr
                })
            }, 300);
        }
    }, [])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Reporte Programado
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={12} sx={{flexDirection: 'column'}}>
                            <Typography sx={{color: '#000'}}>Reporte</Typography>
                            <SelectReportUser
                                dataArr={reporteArr}
                                value={reporteSelect}
                                onChange={(e)=>onSelectTipoReport(e)}
                            />
                        </Grid>
                        <Grid item xs={6} display='flex' justifyContent='flex-start' alignItems='center'>
                            <Typography sx={{color: '#000', fontWeight: 'bold'}}>Filtro de Fechas</Typography>
                        </Grid>
                        <Grid item xs={6} display='flex' justifyContent='end'>
                            <ButtonBasic
                                iconStart={<AddIcon sx={{color: '#0364ff'}} />}
                                textButton='Nuevo filtro'
                                variant='wk'
                                color='nextWhite'
                                sx={{border: 1 , borderColor: '#0364ff'}}
                                onClick={()=>onOpenModalRangoFecha()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            { rangoFechasArray?.length > 0 ? (
                                <Grid container spacing={1}>
                                    {rangoFechasArray.map((rnfecha)=>(
                                        <Grid item xs={12} md={6} key={rnfecha?.keyCampo}>
                                            <Card sx={{background: '#f5f5f5 !important', boxShadow: 3,p:1, borderRadius: 3}}>
                                                <Grid container>
                                                    <Grid item xs={10} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                                        <Typography sx={{color: '#000',fontWeight: 'bold'}}>{rnfecha?.itemsCampo?.label}</Typography>
                                                        <Typography sx={{color: '#000'}}>{`Desde: ${FechaFunction.format(rnfecha?.fechaIni,'dd-MM-yyyy')}`}</Typography>
                                                        <Typography sx={{color: '#000'}}>{`Hasta: ${FechaFunction.format(rnfecha?.fechaFin,'dd-MM-yyyy')}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <IconButton sx={{boxShadow: 3}} onClick={()=>onOpenModalDeleteValid(rnfecha?.keyCampo)}>
                                                            <DeleteOutlineOutlinedIcon sx={{color: '#15294b'}} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ):(
                                <Box sx={{background: '#f6f8fc',minHeight: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography sx={{color: '#808b9e', fontWeight: 'bold', textAlign: 'center'}}>Aún no se realizaron filtro de fechas</Typography>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Divider sx={{background: 'gray',marginTop: 2,marginBottom: 2}} />
                            <Typography sx={{color: '#000',fontSize:14,fontWeight: 'bold', textAlign: 'center'}}>
                                Seleccione su programación
                            </Typography>
                            <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center', background:'#dfe2e6',p:1,borderRadius:3,mb:2}}>
                                <ButtonBasic
                                    textButton='Dia de la semana'
                                    sx={tipoPrograma === 'DIA' ? styleBtnSelect : styleBtnNone}
                                    onClick={()=>onSelectTipoProg('DIA')}
                                />
                                <ButtonBasic
                                    textButton='Fecha del mes'
                                    sx={[{marginLeft:2},tipoPrograma === 'NUMERO' ? styleBtnSelect : styleBtnNone]}
                                    onClick={()=>onSelectTipoProg('NUMERO')}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {tipoPrograma === 'NUMERO' ? (
                                <Box>
                                    <TextField
                                        fullWidth
                                        autoComplete='off'
                                        value={tipoProgramaData?.numero_lr || 1}
                                        onChange={(e)=>setTipoProgramaData({numero_lr: e.target.value})}
                                        sx={styleInput}
                                        type='number'
                                        inputProps={{
                                            min: 1,
                                            max: 28,
                                            step: 1,
                                        }}
                                    />
                                </Box>
                            ):(
                                <Grid container spacing={1}>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaLunes_lr: !tipoProgramaData?.diaLunes_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaLunes_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Lunes"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaMartes_lr: !tipoProgramaData?.diaMartes_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaMartes_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Martes"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaMiercoles_lr: !tipoProgramaData?.diaMiercoles_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaMiercoles_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Miercoles"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaJueves_lr: !tipoProgramaData?.diaJueves_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaJueves_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Jueves"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaViernes_lr: !tipoProgramaData?.diaViernes_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaViernes_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Viernes"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaSabado_lr: !tipoProgramaData?.diaSabado_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaSabado_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Sabado"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={()=>setTipoProgramaData({
                                                        ...tipoProgramaData,
                                                        diaDomingo_lr: !tipoProgramaData?.diaDomingo_lr
                                                    })}
                                                    checked={tipoProgramaData?.diaDomingo_lr}
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#000',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Domingo"
                                            componentsProps={{ typography: { sx:{fontSize:14,color:'#000'} } }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Divider sx={{background: 'gray',marginTop: 2,marginBottom: 2}} />
                            <Typography sx={{color: '#000',fontSize:14,fontWeight: 'bold', textAlign: 'center'}}>
                                Seleccione el horario
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInput}
                                value={tipoProgramTime}
                                onChange={(e)=>setTipoProgramTime(e.target.value)}
                                type='time'
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        isDisabled={!rangoFechasArray || rangoFechasArray?.length === 0 || !reporteSelect}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalReporteProgramado