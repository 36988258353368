import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectReportUser = ({id, value, onChange, isInvalid, isClearable, dataArr}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        setItems(dataArr);
    }, [dataArr]);

    useEffect(() => {
        if(items?.length > 0){
            const newOptions = items.map((item) => {
                return {
                    value: item.id_rts,
                    label: item.titulo_rts,
                    ...item
                };
            });
            setOptions(newOptions);
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    newOptions.find((opt) => opt.value === value)
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:va.titulo_rts,value:va.id_rts})));
            }
        }
    }, [value,items]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectReportUser.defaultProps = {
    isClearable: false
};

export default SelectReportUser;
