import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';

const SelectRangoFechaTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    defaultProps,
    filtro
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([
        {
            label: 'Fecha de Creacion',
            value: 'createdAt',
            tipo: 'fechahora'
        },
        {
            label: 'Fecha de Vencimiento',
            value: 'fechavencimiento_tk',
            tipo: 'fecha'
        },
        {
            label: 'Fecha de Cierre',
            value: 'cerrado_tk',
            tipo: 'fechahora'
        },
        {
            label: 'Fecha de Reapertura',
            value: 'reopeningDate_tk',
            tipo: 'fechahora'
        },
    ]);

    useEffect(() => {
        if(Array.isArray(filtro) && filtro?.length > 0){
            const arrayNuevo = []
            items.forEach((item) => {
                if(!filtro.includes(item?.value)){
                    arrayNuevo.push(item);
                }
            });
            setOptions(arrayNuevo);
        }else{
            setOptions(items);
        }
    }, [filtro])

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value,options]);
    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={disabled}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectRangoFechaTicket.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectRangoFechaTicket;