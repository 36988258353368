import { Box, Card, IconButton, Grid, Typography, Chip } from '@mui/material';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const CardEstadoTicket = ({estado}) => {

    return (
        <Card sx={{background: '#fff !important',padding: 2,borderRadius:5}}>
            <Box sx={{display: 'flex',alignItems: 'center'}}>
                <Box sx={{borderWidth: 2,borderStyle: 'dashed !important',borderColor: '#1d5ae5 !important', width:45, height: 45,border: 1,borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ConfirmationNumberOutlinedIcon sx={{color: '#1d5ae5',fontSize: 35}} />
                </Box>
                <Typography sx={{color: '#000',marginLeft: 2}} variant='h4'>Estados de los Tickets</Typography>
            </Box>
            <Grid container mt={3} spacing={1}>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.estadoAbierto || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#018155',height:30, minWidth: 150,background: '#E6F8F2',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#018155'}}>• </Box> Abierto
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.fechaAsignada || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#018155',height:30, minWidth: 150,background: '#E6F8F2',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#018155'}}>• </Box> Fecha Asignada
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.enEspera || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#be7200',height:30, minWidth: 150,background: '#fff2d1',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#be7200'}}>• </Box> En Espera
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.fechaPendienteAsignar || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#be7200',height:30, minWidth: 210,background: '#fff2d1',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#be7200'}}>• </Box> Fecha Pendiente de Asignar
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.reprogramar || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#be7200',height:30, minWidth: 150,background: '#fff2d1',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#be7200'}}>• </Box> Reprogramar
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.enVerificaciónAdm || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#be7200',height:30, minWidth: 240,background: '#fff2d1',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#be7200'}}>• </Box> En Verificación de Administración
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.enGestionCobranza || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#74767f',height:30, minWidth: 180,background: '#e7e7e9',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#74767f'}}>• </Box> En Gestion de Cobranza
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='center'>
                    <Typography sx={{color: '#000',fontWeight: 'bold',marginRight: 1}} variant='h3'>{estado?.estadoCerrado || '-'}</Typography>
                    <Typography
                        sx={{fontWeight: 'bold',color: '#74767f',height:30, minWidth: 180,background: '#e7e7e9',display: 'flex', alignItems: 'center',justifyContent: 'center', borderRadius:20}}
                        component='div'
                    >
                        <Box fontWeight='bold' display='inline' sx={{fontSize: 20,color: '#74767f'}}>• </Box> Cerrado
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardEstadoTicket