export let CONTACTOS_CURRENT_GOBIERNO = 'CONTACTOS_CURRENT_GOBIERNO';
export let CONTACTOS_CONTRATO_GOBIERNO = 'CONTACTOS_CONTRATO_GOBIERNO';
export let CONTACTOS_CREAR_CONTRATO_GOBIERNO = 'CONTACTOS_CREAR_CONTRATO_GOBIERNO';

const iniciarContactoGobierno = (data = null) => ({
    type: CONTACTOS_CURRENT_GOBIERNO,
    payload: data
});

const iniciarContratoGobierno = (data = null) => ({
    type: CONTACTOS_CONTRATO_GOBIERNO,
    payload: data
});

const habilitarCrearContratoGobierno = (data = false) => ({
    type: CONTACTOS_CREAR_CONTRATO_GOBIERNO,
    payload: data
});

export default {
    habilitarCrearContratoGobierno,
    iniciarContactoGobierno,
    iniciarContratoGobierno,
}