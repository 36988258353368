import { Grid } from '@mui/material'
import { ButtonBasic, LogsCard } from '../../../components';
import { showModal } from 'react-redux-modal-provider';
import ModalAddHistorico from '../../../modals/contacto/ModalAddHistorico';

const TabCuentaHistorico = ({historico,infoCliente, onGuardar}) => {
    const onOpenModal = () => {
        showModal(ModalAddHistorico,{data: infoCliente,onGuardar:onGuardar})
    }
    return (
        <Grid container>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'right'}}>
                <ButtonBasic
                    textButton='Agregar Historico'
                    variant="wk"
                    color='nextStep'
                    onClick={()=>onOpenModal()}
                />
            </Grid>
            {historico?.length > 0 && (
                historico.map((hist)=>(
                    <Grid item xs={12} key={hist?.id_lc}>
                        <LogsCard item={hist} keyst={hist?.id_lc} />
                    </Grid>
                ))
            )}
        </Grid>
    )
}

export default TabCuentaHistorico