import { useEffect, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { TableBase } from "../../components"
import GrupoService from "../../services/grupo.service";

const GruposViewScreen = () => {
    const [itemsG, setItemsG] = useState([]);
    const datosGrupos = async () => {
        await GrupoService.getAll().then((r)=>{
            setItemsG(r)
        })
    };
    useEffect(() => {
        datosGrupos();
        document.title = 'Grupos'
    }, [])

    const columnsGrupo = [
        {
            name: "id_g",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_g",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "personal",
            label: "Integrantes",
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className="text-gray-900 font-bold" variant="h2">Grupos</Typography>
                        </Grid>
                        <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                            <TableBase title='Resultados' columns={columnsGrupo} data={itemsG} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GruposViewScreen