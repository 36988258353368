import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardViewTicket } from '../../components';

const MoverListaTicketsCreados = ({show, hideModal, item, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {`Correo #${item?.idCorreo_ptke} - ${item?.subject_ptke}`}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {item?.tickets?.length > 0 && (
                                item?.tickets?.map((it)=>(
                                    <CardViewTicket key={`${it?.id_tk}cvtkc`} item={it} onCloseModal={handleClose} />
                                ))
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='CERRAR'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{borderRadius: '8px !important'}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default MoverListaTicketsCreados;