import { Box, Card, Typography } from '@mui/material'

const ListArticuloSoft = ({
    articulos
})=>{
    return (
        <>
            {articulos && articulos?.length > 0 && (
                articulos?.map((ar,index)=>(
                        <Card sx={{background: '#0364ff !important',p:2,marginBottom: 1}} key={`${ar?.numero}${index}s`}>
                            <Typography sx={{color: '#fff',alignItems: 'center',fontWeight: 'bold',textAlign: 'center'}}>{ar?.articulo?.nombre || ''}</Typography>
                            <Box sx={{display: 'flex',marginTop:1}}>
                                <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Extension: <Box display='inline'>{ar?.extension || ''}</Box></Typography>
                                <Typography sx={{color: '#fff',fontWeight: 'bold',marginLeft:{xs:2,sm:6}}} component='div'>Item: <Box display='inline'>{ar?.item || ''}</Box></Typography>
                            </Box>
                            <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Precio: <Box display='inline' ml={{xs:2.5,sm:9.5}} fontWeight='normal'>{`${ar?.articulo?.moneda || 'USD'} ${ar?.articulo?.precio}`}</Box></Typography>
                            <Typography sx={{color: '#fff',fontWeight: 'bold'}} component='div'>Estado: <Box display='inline' ml={{xs:2,sm:9}} fontWeight='normal'>{ar?.estado?.descripcion}</Box></Typography>
                        </Card>
                    )
                )
            )}
        </>
    )
}

export default ListArticuloSoft