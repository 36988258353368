import FechaFunction from '../../function/FechaFunction';

const ConsultaCard = ({
    item
}) => {
    return (
        <div className='col-lg-12 col-md-12'>
            <div className='row'>
                <div className="col-12">
                    <div className='card-title col-12'>
                        { `#${item?.id_con} - ${item?.nombreContacto_con}`}
                        <br />
                        {item?.observaciones_con}
                        <br />
                    </div>
                    <br />
                    {item?.cuentaServicioId_con && (
                        <div className="card-subtitle mt-1">
                            Servicio:
                            <b>{` #${item?.cuentaServicioId_con}`}</b>
                        </div>
                    )}
                    <div className="card-subtitle mt-1">
                        Consulto por el plan:
                        <b>{` ${item?.planConsulta_con}`}</b>
                    </div>
                    {item?.canal_con && (
                        <div className="card-subtitle mt-1">
                            Canal de comunicacion:
                            <b>{` ${item?.canal_con}`}</b>
                        </div>
                    )}
                    {item?.segmento_con && (
                        <div className="card-subtitle mt-1">
                            Segmento:
                            <b>{` ${item?.segmento_con}`}</b>
                        </div>
                    )}
                    { item?.direccionConsulta_con &&(
                        <div className="card-subtitle mt-1">
                            Direccion:
                            <b>{` ${item?.direccionConsulta_con}`}</b>
                        </div>
                    )}
                    { item?.estadoConsulta_con &&(
                        <div className="card-subtitle mt-1">
                            Estado del cliente:
                            <b>{` ${item?.estadoConsulta_con}`}</b>
                        </div>
                    )}
                    { item?.coordenadasConsulta_con &&(
                        <div className="card-subtitle mt-1">
                            Coordenadas:
                            <b>{` ${item?.coordenadasConsulta_con}`}</b>
                        </div>
                    )}
                    <div className="blockquote-footer text-right mt-1">
                        {/* FALTA EL USUARIO QUE LO REALIZO */}
                        {`${item?.nombre_p || ''} - `}
                        { item?.createdAt && (
                            FechaFunction.format(new Date(item?.createdAt), 'dd/MM/yyyy HH:mm:ss')
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsultaCard
