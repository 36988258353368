import axios from 'axios';
const base = 'actionsext';

function registerComentario(data) {
    const form = new FormData();
    form.append('ticketId_com',data.ticketId_com);
    form.append('cuerpo_com',data.cuerpo_com);
    if(data?.foto){
        if(Array.isArray(data.foto) && data.foto.length>0){
            form.append('file0',data.foto[0]);
        } else {
            form.append('file0',data.foto);
        }
    }
    if(data?.foto2){
        if(Array.isArray(data.foto2) && data.foto2.length>0){
            form.append('file1',data.foto2[0]);
        } else {
            form.append('file1',data.foto2);
        }
    }
    if(data?.foto3){
        if(Array.isArray(data.foto3) && data.foto3.length>0){
            form.append('file2',data.foto3[0]);
        } else {
            form.append('file2',data.foto3);
        }
    }
    return axios.post('actionsext/addcom', form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function infoTicket(id) {
    return axios.get([base,'infoticket',id].join('/'));
}

function infoUserTicket(id) {
    return axios.get([base,'infouserticket',id].join('/'));
}

function infoOldTicket(id) {
    return axios.get([base,'infoldticket',id].join('/'));
}

function asignarTicket(data) {
    return axios.post([base,'asigcvv'].join('/'),data);
}

function obtenerNotifyTk911() {
    return axios.get([base,'tknotify'].join('/'));
}

function obtenerNotifyCom911() {
    return axios.get([base,'comnotify'].join('/'));
}

const ActionsCvvService = {
    registerComentario,
    infoTicket,
    infoUserTicket,
    infoOldTicket,
    asignarTicket,
    obtenerNotifyTk911,
    obtenerNotifyCom911
};
export default ActionsCvvService;
