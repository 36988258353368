import { Box, Grid, Typography } from "@mui/material"

const TextInfoTicket = ({titulo, descripcion,onClick}) => {
    return (
        <Grid container sx={{marginLeft:2,marginBottom:1, display: 'flex', width: '98%'}} key={titulo}>
            <Grid item xs={5.1} sm={5.1}>
                <Typography sx={{color: '#15294b',fontWeight: 'bold', fontSize: 12}}>{titulo}</Typography>
            </Grid>
            {descripcion?.length > 24 && (
                <Grid item xs={6}>

                </Grid>
            )}
            <Grid item xs={11} md={descripcion?.length > 24 ? 11 : 5.9 } lg={descripcion?.length > 24 ? 12 : 5.9}>
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:0, width: '100%'}}>
                    <Typography onClick={onClick} sx={{color: onClick ? '#0364ff' :'#5d6b82',fontWeight: 'bold', fontSize: descripcion?.length > 35 ? 10 : 12,textOverflow:'ellipsis',textAlign: 'right', cursor: onClick ? 'pointer' : 'default' }} component='div'>{descripcion}</Typography>
                </Box>
                {/* <Typography
                    sx={{color: onClick ? '#0364ff' :'#8993a4',fontWeight: 'bold', fontSize: 12,textOverflow:'ellipsis',textAlign: 'right', cursor: onClick ? 'pointer' : 'default' }}
                    onClick={onClick}
                >
                    {descripcion}
                </Typography> */}
            </Grid>
        </Grid>
    )
}

export default TextInfoTicket
