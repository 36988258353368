import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import { connect, useSelector } from 'react-redux';

const SelectTratoCamaras = ({id, value, onChange, isInvalid, isClearable, disabled, ...defaultProps}) => {
    const tratoscam = useSelector(state => state.default.tratoscam);
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_t === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_t === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {
        setItems(tratoscam)
    }, [tratoscam]);

    useEffect(() => {
        const newOptions = items?.map((item) => {
            return {
                value: item.id_t,
                label: item.direccion_t ? `${item.nombre_t} - ${item.direccion_t}` : item.nombre_t,
                lat: item.latitud_t,
                lng: item.longitud_t
            };
        });
        setOptions(newOptions);
    }, [items])
    useEffect(() => {
        if( options.length > 0 ){
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    options.find((opt) => opt.value === Number(value))
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:`${va.nombre_t} - ${va.direccion_t}`,value:va.id_t})));
            }
        }
    }, [options])

    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])

    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            isDisabled={disabled}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectTratoCamaras.defaultProps = {
    isClearable: false
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTratoCamaras)