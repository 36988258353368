import axios from 'axios';
function getAll(query = null) {
    return axios.get('ticketstab', {
        params: query
    });
}

function countTicketMe(query = null) {
    return axios.get('ticketstab/count',{
        params: query
    });
}

function countTicketEsp(query = null) {
    return axios.get('ticketstab/countesp',{
        params: query
    });
}
function countTicketTic(query = null) {
    return axios.get('ticketstab/counttic',{
        params: query
    });
}
function searchGlobal(query = null) {
    return axios.get('ticketstab/search',{
        params: query
    });
}
const TablaTicketService = {
    getAll,
    countTicketMe,
    countTicketEsp,
    countTicketTic,
    searchGlobal
};
export default TablaTicketService;