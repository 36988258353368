import { useEffect, useState, useContext } from "react"
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { SocketContext } from "../../context/SocketContext";
import CierreTicketService from "../../services/cierreTicket.service";
import ModalPlantillaCierreTk from "../../modals/plantillas/ModalPlantillaCierreTk";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ConfirmationDialog from "../../modals/default/ConfirmationDialog";

const PlantillaCierreTicketScreen = () => {
    const [items, setItems] = useState([]);
    const { sockets } = useContext( SocketContext );

    const actualizar = async() =>{
        await CierreTicketService.getAll({all: true}).then((resp)=>{
            setItems(resp)
        })
    }
    const onEliminar = (itemID) => {
        const searchItem = items.find((sItem)=> sItem.id_ct === itemID);
        if(searchItem?.id_ct){
            showModal(ConfirmationDialog, {
                title: `${searchItem?.isDeleted_ct ? 'Activar' : 'Eliminar'} cierre de ticket`,
                message: `¿Esta seguro que desea ${searchItem?.isDeleted_ct ? 'activar' : 'eliminar'} el cierre de ticket?`,
                onConfirm: async() => {
                    await CierreTicketService.destroy(searchItem.id_ct).then(() => {
                        actualizar();
                    });
                }
            });
        }
    };
    const columns = [
        {
            name: "id_ct",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false
            }
        },
        {
            name: "nombre_ct",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cadenaSubTipo",
            label: "Sub-Tipo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cadenaSubCierre",
            label: "Sub-Cierre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'isDeleted_ct',
            label: "Estado",
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                    return <Chip label="Activo" color="success" />
                    }
                    return <Chip label="Inactivo" color="error" />
                }
            }
        },
        {
            name: 'actions',
            label: "Acciones",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Box display='flex'>
                            <IconButton color='info' aria-label="edit" onClick={()=>onOpenEditar(tableMeta.rowData[0])}>
                                <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton color='info' aria-label="edit" onClick={()=>onEliminar(tableMeta.rowData[0])}>
                                { !tableMeta.rowData[4] ?(
                                    <DeleteIcon fontSize="medium" sx={{color: 'red'}} />
                                ):(
                                    <PublishedWithChangesIcon fontSize="medium" sx={{color: 'green'}} />
                                )}
                            </IconButton>
                        </Box>
                    </>
                )
            }
        }
    ];
    const onGuardar = () =>{
        actualizar();
        sockets?.emit('canales');
    }
    const onOpenEditar = (item) => {
        if(item){
            const searchItem = items.find((sItem)=> sItem.id_ct === item)
            showModal(ModalPlantillaCierreTk, {item: searchItem, onGuardar: onGuardar});
        }else{
            showModal(ModalPlantillaCierreTk, {onGuardar: onGuardar});
        }
    };

    useEffect(() => {
        actualizar();
        document.title = 'Cierre de Tickets';
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3}>
                <Grid item xs={6}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Cierre de Tickets</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <ButtonBasic
                        textButton="NUEVO"
                        sx={{width: 200, height: 30}}
                        onClick={()=>onOpenEditar()}
                        variant='wk'
                        color="nextStep"
                    />
                </Grid>
                <Grid item xs={12} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PlantillaCierreTicketScreen