import { useState, useMemo, useEffect } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, DateRangeMaxSeven, SelectEstadoVencimiento, SelectFilterStageTicket, SelectGrupos, SelectMotivoBajaTk, SelectMotivoTicket, SelectNivelAdmTicket, SelectStageTicket, SelectSubTipoTicket, SelectTipoTicket, SelectUser } from '../../components';
import SelectCierreTicket from '../../components/select/SelectCierreTicket';
import moment from 'moment';
import FechaFunction from '../../function/FechaFunction';
import DashboardService from '../../services/dashboard.service';

function ModalFilterDashTickets({
    show,
    hideModal,
    filtroTotal,
    setFiltroTotal,
    setCountFilter,
    itemsOriginal,
    primeraCarga,
    setItems,
    fechaSelect,
    setFechaSelect,
    setItemsOriginal
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [plantilla, setPlantilla] = useState(filtroTotal?.plantilla || []);
    const [estado, setEstado] = useState(filtroTotal?.estado || []);
    const [estadoVencimiento, setEstadoVencimiento] = useState(filtroTotal?.estadoVencimiento || []);
    const [tipo, setTipo] = useState(filtroTotal?.tipoTicket || []);
    const [subTipo, setSubTipo] = useState(filtroTotal?.subTipo || []);
    const [nivelAdm, setNivelAdm] = useState(filtroTotal?.nivelAdm || []);
    const [motivoBaja, setMotivoBaja] = useState(filtroTotal?.motivoBaja || []);
    const [etapa, setEtapa] = useState(filtroTotal?.etapa || []);
    const [motivoCierre, setMotivoCierre] = useState(filtroTotal?.cierresId || []);
    const [asignadoA, setAsignadoA] = useState(filtroTotal?.grupoId || []);
    const [creadoPor, seCreadoPor] = useState(filtroTotal?.autorTk || []);
    const [fechas, setFechas] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    const nuevoFiltro = (itemsOrigin) => {
        if(plantilla?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => plantilla.includes(objeto.motivo_tk));
        }
        if(estado?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => estado.includes(objeto.estado_tk));
        }
        if(estadoVencimiento?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => estadoVencimiento.includes(objeto.estadoVencimiento_tk));
        }
        if(tipo?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => tipo.includes(objeto.tipoTicketId_tk));
        }
        if(subTipo?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => subTipo.includes(objeto.subTipoTicketId_tk));
        }
        if(nivelAdm?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => nivelAdm.includes(objeto.nivelAdmTkId_tk));
        }
        if(motivoBaja?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => motivoBaja.includes(objeto.motivoBajaTk_tk));
        }
        if(etapa?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => etapa.includes(objeto.stageTicket_tk));
        }
        if(motivoCierre?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => motivoCierre.includes(objeto.cierreTicketId_tk));
        }
        if(asignadoA?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => asignadoA.includes(objeto.grupo));
        }
        if(creadoPor?.length > 0){
            itemsOrigin = itemsOrigin.filter(objeto => creadoPor.includes(objeto.createdPersonaId_tk));
        }
        primeraCarga(itemsOrigin)
        setItems(itemsOrigin)
    }
    const onSubmit = async() => {
        setFiltroTotal({
            ...filtroTotal,
            plantilla: plantilla,
            estado: estado,
            estadoVencimiento: estadoVencimiento,
            tipoTicket: tipo,
            subTipo: subTipo,
            nivelAdm: nivelAdm,
            motivoBaja: motivoBaja,
            etapa: etapa,
            cierresId: motivoCierre,
            grupoId: asignadoA,
            autorTk: creadoPor,
            tecnologia: [],
            departamento: [],
            departamentoAutorTk: [],
        })
        const suma = plantilla?.length + estado?.length + estadoVencimiento?.length + tipo?.length + subTipo?.length + nivelAdm?.length + motivoBaja?.length + etapa?.length + motivoCierre?.length + asignadoA?.length + creadoPor
        setCountFilter(suma)
        const fechaIni = FechaFunction.format(fechas?.startDate,'yyyy-MM-dd');
        const fechaFin = FechaFunction.format(fechas?.endDate,'yyyy-MM-dd');
        if((fechaSelect?.startDate !== fechaIni) || (fechaSelect?.endDate !== fechaFin)){
            await DashboardService.gralTicket({
                fecIni: fechaIni,
                fecFin: fechaFin
            }).then((resp)=>{
                setFechaSelect({
                    startDate: fechaIni,
                    endDate: fechaFin
                })
                setItemsOriginal(resp)
                nuevoFiltro(resp)
                handleClose()
            })
        }else{
            nuevoFiltro(itemsOriginal || [])
            handleClose()
        }
    }
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        FILTRAR DATOS - TICKETS
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Card sx={{boxShadow: 4, borderRadius: 4, background: '#fff !important', p:2}}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', textAlign: 'center'}}>
                                    Rango de Fechas
                                </Typography>
                                <DateRangeMaxSeven
                                    minDate={new Date('2023-11-01')}
                                    initianDate={fechas}
                                    onChange={(e)=>{
                                        let startDate;
                                        let endDate;
                                        if(e?.startDate){
                                            startDate = FechaFunction.format(e?.startDate,'yyyy-MM-dd')
                                        }
                                        if(e?.endDate){
                                            endDate = FechaFunction.format(e?.endDate,'yyyy-MM-dd')
                                        }else{
                                            endDate = FechaFunction.format(e?.startDate,'yyyy-MM-dd')
                                        }
                                        if(e?.startDate){
                                            setFechas({
                                                startDate,
                                                endDate
                                            })
                                        }
                                    }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={9}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Plantilla</Typography>
                            <SelectMotivoTicket
                                isMulti
                                emp='nubicom'
                                value={plantilla}
                                onChange={(opt) => {
                                    setPlantilla(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Estado</Typography>
                            <SelectFilterStageTicket
                                isMulti
                                value={estado}
                                onChange={(opt) => {
                                    setEstado(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Estado Vencimiento</Typography>
                            <SelectEstadoVencimiento
                                isMulti
                                value={estadoVencimiento}
                                onChange={(opt) => {
                                    setEstadoVencimiento(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Tipo</Typography>
                            <SelectTipoTicket
                                emp='nubicom'
                                isMulti
                                value={tipo}
                                onChange={(opt) => {
                                    setTipo(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Sub-Tipo</Typography>
                            <SelectSubTipoTicket
                                value={subTipo}
                                onChange={(opt) => setSubTipo(opt.map(op=>(op.value)))}
                                isMulti
                                emp='nubicom'
                                filter='all'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Nivel Administrativo</Typography>
                            <SelectNivelAdmTicket
                                isMulti
                                emp='nubicom'
                                value={nivelAdm}
                                onChange={(opt) => {
                                    setNivelAdm(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Motivo de Baja</Typography>
                            <SelectMotivoBajaTk
                                isMulti
                                emp='nubicom'
                                value={motivoBaja}
                                onChange={(opt) => {
                                    setMotivoBaja(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Etapa</Typography>
                            <SelectStageTicket
                                isMulti
                                emp='nubicom'
                                value={etapa}
                                onChange={(opt) => {
                                    setEtapa(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Motivo de Cierre</Typography>
                            <SelectCierreTicket
                                isMulti
                                emp='nubicom'
                                reporte
                                value={motivoCierre}
                                onChange={(opt) => {
                                    setMotivoCierre(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Asignado a:</Typography>
                                <SelectGrupos
                                    id="selectGrupo"
                                    allGroup
                                    isMulti
                                    value={asignadoA}
                                    onChange={(opt) => {
                                        setAsignadoA(opt.map(op=>(op.value)))
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000',fontWeight: 'bold'}}>Creado por:</Typography>
                            <SelectUser
                                isMulti
                                filter='all'
                                value={creadoPor}
                                onChange={(opt) => {
                                    seCreadoPor(opt.map(op=>(op.value)))
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalFilterDashTickets;