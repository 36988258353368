import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux'
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { showModal } from 'react-redux-modal-provider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ButtonBasic, TextInfoTicket } from '../../components';
import ContactosService from '../../services/contactos.service';
import InfContactoService from '../../services/infContacto.service';
import ContactosAction from '../../store/actions/contactos';
import TabCuentaTicket from './tabs/TabCuentaTicket';
import ConsultasService from '../../services/consultas.service';
import TabCuentaConsultas from './tabs/TabCuentaConsultas';
import LogService from '../../services/log.service';
import TabCuentaHistorico from './tabs/TabCuentaHistorico';
import TabContactoDatos from './tabs/TabContactoDatos';
import TabCuentaPreServicio from './tabs/TabCuentaPreServicio';
import TabCuentaDocumentacion from './tabs/TabCuentaDocumentacion';
import FileAdjContactoService from '../../services/fileAdjContacto.service';
import TabCuentaTareas from './tabs/TabCuentaTareas';
import CalendarioV2Service from '../../services/calendarioV2.service';
import usePermisos from '../../hooks/usePermisos';
import ModalConsultaVinculacion from '../../modals/consulta/ModalConsultaVinculacion';
import ModalForzarVinculacion from '../../modals/consulta/ModalForzarVinculacion';
import TabCuentaRecodatorios from './tabs/TabCuentaRecodatorios';
import RecordatorioService from '../../services/recordatorio.service';

function TabPanel({ children, value, index, ...other }) {
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
      {value === index && (
          <Box sx={{ p: 3 }}>{children}</Box>
      )}
      </div>
  );
}
function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ContactoFindByIdScreen = ({iniciarContacto}) => {
  const permisos = usePermisos();
  const [value, setValue] = useState(0);
  const {id,type} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [datosOK, setDatosOk] = useState(false);
  const [datosFaltantes, setDatosFaltantes] = useState(null);
  const [contacto, setContacto] = useState();
  const [contactoImg, setContactoImg] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [consultas, setConsultas] = useState([]);
  const [logs, setLogs] = useState([]);
  const [recordatorios, setRecordatorios] = useState([]);
  const [filesAdj, setFilesAdj] = useState([]);
  const [tareas, setTareas] = useState([]);

  const infContacto = async() => {
    await InfContactoService.getAll({contactoId: id}).then((repp)=>{
      setContactoImg(repp)
    })
  }
  const actualizar = async() =>{
    setIsLoading(true)
    await ContactosService.getById(id,{type}).then((response)=>{
        if(response?.cuentaNubi_c){
            navigate(`/contactos/${response.cuentaNubi_c}/cuenta`);
        }else{
          infContacto()
          iniciarContacto(response)
          setContacto(response)
          setIsLoading(false)
        }
    })
    .catch((e)=>{
      if(e?.response?.data?.msg){
        navigate('/')
      }
    })
    .finally(()=>{
        setIsLoading(false)
    })
  }
  const actualizarConsultas = async() =>{
    await ConsultasService.getAll({id: contacto?.id_c, webV2: true}).then((resp)=>{
        setConsultas(resp)
    })
  }
  const actualizarHistorico = async() =>{
    await LogService.getAllContacto({contactoId: id}).then((resp)=>{
      setLogs(resp)
    })
  }
  const actualizarFileAdjContacto = async() => {
    await FileAdjContactoService.getAll({contactoId: id}).then((resp)=>{
      setFilesAdj(resp)
    })
  }
  const actualizarTareas = async() =>{
    await CalendarioV2Service.tareasCalendar({contactoId: id}).then((resp)=>{
      setTareas(resp)
    })
  }
  const actualizarRecordatorios = async() =>{
    await RecordatorioService.getAll({contactoId: id}).then((resp)=>{
      setRecordatorios(resp)
    })
  }
  const onGuardar = () =>{
    actualizar();
    actualizarHistorico();
    actualizarFileAdjContacto();
    actualizarTareas();
    actualizarRecordatorios();
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onForzarVinculacion = async() => {
    showModal(ModalForzarVinculacion,{id:id})
  }
  const onConsultarVinculacion = async() => {
    setIsLoading(true);
    await ConsultasService.consultarVinculacion(id).then((respCV)=>{
      if(respCV?.cuenta){
        setIsLoading(false)
        showModal(ModalConsultaVinculacion,{id:id})
      }
    }).catch(()=>{
      setIsLoading(false)
    })
  }

  useEffect(() => {
    actualizar();
    actualizarHistorico();
    actualizarFileAdjContacto();
    actualizarTareas();
    actualizarRecordatorios();
    document.title = `Contacto #${id}`;
  }, [id])
  useEffect(() => {
    if(contacto?.id_c){
      actualizarConsultas();
    }
  }, [contacto])
  useEffect(() => {
    let textErr = null;
    if((!contacto?.nombreCompleto_c || !contacto?.barrio_c || !contacto.celular_c || !contacto.cobrador_c
        || !contacto.canalcomu_c || !contacto.condicionPago_c || !contacto.cpostal_c || !contacto.direccion_c
        || !contacto.documento_c || !contacto.email_c || !contacto.iva_c || !contacto.latitud_c || !contacto.listPrecio_c
        || !contacto.longitud_c || !contacto.pais_c || !contacto.provincia_c || !contacto.tipoDoc_c
        || !contacto.vendedor_c || !contacto.zona_c
    )){
        setDatosOk(false)
        textErr = 'Datos del contacto'
    }else{
        setDatosOk(true)
    }
    if(contacto?.tipoDoc_c === '80' && !contactoImg?.pathconstanciaAfip_icon){
        setDatosOk(false)
        if(textErr){
          textErr = `${textErr} - Constancia de Afip`
        }else{
          textErr = 'Constancia de Afip'
        }
    }
    if(contacto?.tipoDoc_c === '80' && contactoImg?.tipoCuit_icon === 'JURIDICA' && !contactoImg?.pathContratoSocialEstatuto_icon){
      setDatosOk(false)
      if(textErr){
        textErr = `${textErr} - Contrato Social-Estatuto`
      }else{
        textErr = 'Contrato Social-Estatuto'
      }
    }
    /* if(contacto?.tipoDoc_c === '80' && !contactoImg?.pathDesigActaActualizada_icon){
      setDatosOk(false)
      if(textErr){
        textErr = `${textErr} - Desig. Acta Actualizada`
      }else{
        textErr = 'Desig. Acta Actualizada'
      }
    } */
    if(contacto?.tipoDoc_c === '80' && contactoImg?.tipoCuit_icon === 'JURIDICA' && !contactoImg?.pathPoderApoderado_icon){
      setDatosOk(false)
      if(textErr){
        textErr = `${textErr} - Poder-Apoderado`
      }else{
        textErr = 'Poder-Apoderado'
      }
    }
    if(contacto?.tipoDoc_c === '80' && contactoImg?.tipoCuit_icon === 'JURIDICA' && !contactoImg?.pathDniFrenteApoderado_icon){
      setDatosOk(false)
      if(textErr){
        textErr = `${textErr} - Dni-Frente-Apoderado`
      }else{
        textErr = 'Dni-Frente-Apoderado'
      }
    }
    if(contacto?.tipoDoc_c === '80' && contactoImg?.tipoCuit_icon === 'JURIDICA' && !contactoImg?.pathDniDorsoApoderado_icon){
      setDatosOk(false)
      if(textErr){
        textErr = `${textErr} - Dni-Dorso-Apoderado`
      }else{
        textErr = 'Dni-Dorso-Apoderado'
      }
    }
    if(contacto?.tipoDoc_c === '96' && (!contactoImg?.pathDorso_icon || !contactoImg?.pathFrente_icon)){
        setDatosOk(false)
        if(textErr){
          textErr = `${textErr} - Imagenes del DNI`
        }else{
          textErr = 'Imagenes del DNI'
        }
    }
    setDatosFaltantes(textErr)

  }, [contacto,contactoImg])
  return (
    <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
      <Grid container p={3} mb={5} spacing={1}>
        <Grid item xs={12} md={3}>
          <Card sx={{background: '#fff !important',p:2,borderRadius:4,boxShadow: "0px -3px 0px 0px #0364ff"}}>
            <IconButton sx={{boxShadow: 3}} onClick={()=>navigate(-1)}>
              <ArrowBackIcon sx={{color: '#000'}} />
            </IconButton>
            <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
              <Box sx={{background: '#e6f0ff', borderRadius:50, width:50, height: 50, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <AccountCircleIcon sx={{color: '#0364ff',fontSize: 40}} />
              </Box>
              <Typography sx={{color: '#0364ff',fontWeight: 'bold',textAlign: 'center',mb:2}} variant='h4'>{contacto?.nombreCompleto_c}</Typography>
              <TextInfoTicket titulo='Ultimo Estado' descripcion={contacto?.estadoCliente_c} />
              <TextInfoTicket titulo='Total Pre-Servicios' descripcion={contacto?.tratos?.length || '0'} />
              <TextInfoTicket titulo='Total Ticket' descripcion={contacto?.tickets?.length || '0'} />
              { contacto?.contxemp?.length > 0 && (
                <TextInfoTicket titulo='Categoria' descripcion={contacto?.contxemp[0]?.categoria_cxe || '-'} />
              )}
            </Box>
              <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
              <Typography sx={{color: '#000',fontWeight: 'bold',mb:2}} variant='h5'>Datos personales</Typography>
              <TextInfoTicket titulo='DNI/CUIT' descripcion={contacto?.documento_c || '-'} />
              <TextInfoTicket titulo='Email' descripcion={contacto?.email_c || '-'} />
              <TextInfoTicket titulo='Telefono' descripcion={contacto?.celular_c || '-'} />
              <TextInfoTicket titulo='Domicilio de Facturacion' descripcion={`${contacto?.barrio_c || ''} - ${contacto?.direccion_c || ''}`} />
              {contacto?.coordenadas_c && (
                  <TextInfoTicket titulo='Coordenadas' descripcion={contacto?.coordenadas_c || ''} />
              )}
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column', mt: 2}}>
                { permisos['vincular.potClienteToCuenta'] && (
                  <ButtonBasic
                    variant="wk"
                    sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                    color='nextWhite'
                    textButton='Consultar Vinculación'
                    isLoading={isLoading}
                    onClick={()=>onConsultarVinculacion()}
                  />
                )}
                { permisos['vincular.forzosa.potClienteToCuenta'] && (
                  <ButtonBasic
                    variant="wk"
                    sx={{width: 220,fontWeight: 'bold', border:1,borderColor: '#0364ff',mt: 2}}
                    color='nextWhite'
                    textButton='Forzar Vinculación'
                    isLoading={isLoading}
                    onClick={()=>onForzarVinculacion()}
                  />
                )}
              </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          <Card sx={{background: '#fff !important',p:2,borderRadius:4}}>
            <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    sx={{
                        '& .MuiTabs-indicator':{
                            background: '#0364ff'
                        },
                        '& .MuiButtonBase-root':{
                          color: '#6b788e',
                          fontWeight: 'bold'
                        },
                        '& .MuiButtonBase-root.Mui-selected':{
                            color: '#0364ff'
                        }
                    }}
                >
                    <Tab label="Tickets" {...a11yProps(0)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Consultas" {...a11yProps(1)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Pre-Servicios" {...a11yProps(2)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Datos" {...a11yProps(3)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Historico" {...a11yProps(4)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Documentación" {...a11yProps(5)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Tareas" {...a11yProps(6)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                    <Tab label="Recordatorios" {...a11yProps(7)} sx={{ minWidth: "fit-content", flex: 1 }}/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TabCuentaTicket tickets={contacto?.tickets} preservicio={id} nombreCompleto={contacto?.nombreCompleto_c} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TabCuentaConsultas
                consultas={consultas}
                actualizar={actualizarConsultas}
                actualizarRecordatorios={actualizarRecordatorios}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabCuentaPreServicio preServicios={contacto?.tratos} datosOK={datosOK} datosFaltantes={datosFaltantes} contacto={contacto} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TabContactoDatos contacto={contacto} onGuardar={onGuardar} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TabCuentaHistorico
                historico={logs}
                infoCliente={{ contactoId: id,type: 'nubicom'}}
                onGuardar={actualizarHistorico}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <TabCuentaDocumentacion infoIni={contactoImg} filesAdj={filesAdj} token={token} onGuardar={onGuardar} consulta={{id_c: id}} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <TabCuentaTareas tareas={tareas} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <TabCuentaRecodatorios
                recordatoriosAll={recordatorios}
                contactoId={id}
                nombreCompleto={contacto?.nombreCompleto_c}
                onGuardar={actualizarRecordatorios}
              />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactoFindByIdScreen)