import { useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import FormPaso1Screen from './FormPaso1Screen';
import FormPaso2Screen from './FormPaso2Screen';
import FormPaso3Screen from './FormPaso3Screen';
import FormPaso4Screen from './FormPaso4Screen';
import { Wizard } from '../../../components/index';
import ContactosAction from '../../../store/actions/contactos';
import { Box } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const ContactoNuevoNUBIAsesorScreen = ({
    iniciarContacto,
    iniciarContrato
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const { item, tipo } = (location && location.state) || { };
    const options = [
        {
            title: 'Contacto',
            subtitle: 'Datos del cliente',
            icon: <AccountBoxIcon sx={{fontSize: 35}} />
        },
        {
            title: 'Adjuntos',
            subtitle: '',
            icon: <DescriptionOutlinedIcon sx={{fontSize: 35}} />
        },
        {
            title: 'Servicio',
            subtitle: '',
            icon: <RuleIcon sx={{fontSize: 35}} />
        },{
            title: 'Calendario',
            subtitle: '',
            icon: <TaskAltIcon sx={{fontSize: 35}} />
        }
    ];

    useEffect(() => {
        if(item){
            iniciarContacto(item);
            if(item.contrato){
                iniciarContrato(item.contrato);
            }
        }
        document.title = 'Nueva Venta';
        navigate('/contactos/nuevonubi#step1');
        return () => {
            iniciarContacto(null);
            iniciarContrato(null);
        }
    }, []);

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto',maxWidth:'1280px', margin: 'auto'}} className='side'>
            <Box p={2} marginBottom={0}>
                <Wizard options={options}>
                    <FormPaso1Screen item={item} tipo={tipo} />
                    <FormPaso2Screen />
                    <FormPaso3Screen />
                    <FormPaso4Screen />
                </Wizard>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoNuevoNUBIAsesorScreen);
