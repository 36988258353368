import { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import { toast } from 'react-toastify';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { ButtonBasic, InputEditQuill, CardUploadFile, InputDropZoneComment } from '../../components';
import { SocketContext } from '../../context/SocketContext';
import TratoService from '../../services/tratos.service';

function ModalConfirmarPrefactibilidad({show, hideModal,onGuardar,ticketId,tratoId}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const { sockets } = useContext( SocketContext );
    const [files, setFiles] = useState([]);
    const [estadoPrefa, setEstadoPrefa] = useState(null);
    const [textComentario, setTextComentario] = useState(null);
    const [mbPrefa, setMbPrefa] = useState(null);
    const [tecnologiaPrefa, setTecnologiaPrefa] = useState(null);
    const [filesCompress, setFilesCompress] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = '';
    const onCompressImg = async(imgx) =>{
        const nombreCortado = imgx?.name?.split('.');
        let extension = nombreCortado[ nombreCortado?.length - 1 ];
        extension = extension?.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx?.id
                    result.preview=imgx?.preview
                    setIsLoading(false);
                    setFilesCompress(old => [...old,result]);
                }
            })
        }else{
            setFilesCompress(old => [...old,imgx]);
        }
    }

    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    const onSubmit = async() =>{
        if(textComentario && textComentario.length > 16){
            setIsLoading(true);
            let banderaErr = false;
            const form = {
                estado: estadoPrefa,
                ticketId: ticketId,
                tratoId: tratoId,
                comentario: textComentario,
                tecnologiaPrefa_t: tecnologiaPrefa
            };
            if(estadoPrefa === 'POSITIVA'){
                if(mbPrefa > 0){
                    form.mbPrefa_t = mbPrefa;
                }else{
                    banderaErr = true;
                }
            }
            if(filesCompress?.length > 0){
                form.filesCompress = filesCompress;
            }
            if(!banderaErr){
                setIsLoading(true);
                await TratoService.confirmChangePrefa(form).then((resp)=>{
                    if(resp?.id_tk){
                        if(onGuardar){
                            onGuardar()
                        }
                        hideModal();
                    }else{
                        setIsLoading(false);
                    }
                })
                .catch(()=>{
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }else{
                setIsLoading(false);
                toast.error('Debe ingresar los Mbs.', {
                    position: "top-right",
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            }
        }else{
            setIsLoading(false);
            toast.error('El comentario debe tener al menos 10 caractares.', {
                position: "top-right",
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
    }
    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])
    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            height: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                height: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    height: 45,
                }
            },
            color: 'gray',
            height: 45,
        }
    }), []);
    return (
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth className='side'>
            <Box sx={{background: '#fff'}} className='side'>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Confirmar Prefactibilidad
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent className='side'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} display='flex' justifyContent='center' flexDirection='column'>
                            <Card sx={{background: '#fff !important', boxShadow: 4,p: 1, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                    Resultado de Prefactibilidad
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={estadoPrefa}
                                        onChange={(e) => setEstadoPrefa(e.target.value)}
                                        row
                                    >
                                        <FormControlLabel
                                            value="POSITIVA"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#02B879',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="POSITIVA"
                                            sx={{color: '#000'}}
                                        />
                                        <FormControlLabel
                                            value="NEGATIVA"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#E01010',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="NEGATIVA"
                                            sx={{color: '#000'}}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{background: '#fff !important', boxShadow: 4,p: 1, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                    Tecnología
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={tecnologiaPrefa}
                                        onChange={(e) => setTecnologiaPrefa(e.target.value)}
                                        row
                                    >
                                        <FormControlLabel
                                            value="RF"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#0364ff',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="RF"
                                            sx={{color: '#000'}}
                                        />
                                        <FormControlLabel
                                            value="FO"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#000',
                                                        '&.Mui-checked': {
                                                            color: '#0364ff',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="FO"
                                            sx={{color: '#000'}}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Card>
                        </Grid>
                        {estadoPrefa === 'POSITIVA' && (
                            <Grid item xs={12} md={4}>
                                <Card sx={{background: '#fff !important', boxShadow: 4,p: 1, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                    <Typography sx={{color: '#000', fontWeight: 'bold', fontSize: 14, mb: 0.5}}>
                                        Cant. de Megabits
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        autoComplete='off'
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{ inputProps: { min: 1, max: 1000 } }}
                                        type='number'
                                        value={mbPrefa}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value) && value >= 0 && value <= 10000) {
                                                setMbPrefa(value);
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        sx={styleInput}
                                    />
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography sx={{flex:1,textAlign: 'start', color: '#000',fontWeight: 'bold',mt: 0.5}}>
                                Comentario
                            </Typography>
                            <InputEditQuill
                                template={textComentario}
                                onChangeText={(text)=>setTextComentario(text)}
                            />
                        </Grid>
                        { filesCompress?.length > 0 &&(
                            filesCompress.map((fil)=>(
                            <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                            </Grid>
                            ))
                        )}
                    </Grid>
                    { filesCompress?.length < 5 &&(
                        <Box sx={{mt:2,mb:2,border: 2, borderColor: '#a6aebb',borderStyle: 'dashed',mt:4,mb:1,borderRadius: 3,'&:hover':{border: 2,borderColor: '#505f79',borderStyle: 'dashed'}}}>
                            <InputDropZoneComment
                                id="otImg"
                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                setFoto={setFiles}
                                accept={acceptInputFile}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        sx={{mb:2}}
                    />
                    <ButtonBasic
                        textButton='Confirmar'
                        variant='wk'
                        color='nextStep'
                        onClick={()=>onSubmit()}
                        sx={{mb:2}}
                        isLoading={isLoading}
                        isDisabled={
                            !estadoPrefa
                            || !ticketId
                            || !tratoId
                            || !textComentario
                        }
                    />
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default ModalConfirmarPrefactibilidad;