import { Box, Grid, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';

const RecoverPassworOKdScreen = () => {
    const {email} = useParams();
    const navigate = useNavigate()
    if(!email){
        navigate('/login')
    }
    return (
        <Box sx={{height: {xs:'100%',md:'100vh'}, width: '100%', background: '#fff',padding:17}}>
            <img src='/img/Zeta-black.png' style={{height: 80,width:170}} />
            <Grid container>
                <Grid item xs={12} md={8} lg={6} xl={5} sx={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                    <img src='/img/recoverPasswordOK.png' style={{width:400, height: 400}} />
                </Grid>
                <Grid item xs={12} md={4} lg={6} xl={7} sx={{display: 'flex',justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} variant='h2'>¡Link enviado!</Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                        <Typography sx={{color: '#000'}} component='div'>Ya enviamos el link para restablecer la contraseña a <Box fontWeight='bold' display='inline'>{email}</Box>, revisa tus email y segui las instrucciones</Typography>
                    </Box>
                    <Typography
                        sx={{color: 'gray',marginTop:2,cursor: 'pointer'}}
                        onClick={()=>navigate('/login')}
                    >
                        Volver al inicio de sesión
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    )
}

export default RecoverPassworOKdScreen