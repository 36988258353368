import { useEffect, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { ButtonBasic, TableBase } from "../../components"
import { showModal } from "react-redux-modal-provider";
import { useNavigate } from "react-router-dom";
import usePermisos from '../../hooks/usePermisos';
import FibraNapService from "../../services/fibraNap.service";
import FechaFunction from "../../function/FechaFunction";
import ModalCargarCoberturaRangoFO from "../../modals/fibraOptica/ModalCargarCoberturaRangoFO";

const TablaFORangoCoberturaScreen = () => {
    const [items, setItems] = useState([]);
    const permisos = usePermisos()
    const navigate = useNavigate()
    const actualizar = async() =>{
        await FibraNapService.getAllCoberturaFO().then((resp)=>{
            setItems(resp);
        }).catch(()=>{
            navigate('/')
        })
    }

    const columns = [
        {
            name: 'createdAt',
            label: "Registrado",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value) return FechaFunction.format(new Date(value),'dd-MM-yyyy HH:mm:ss');
                    return ''
                }
            }
        },
        {
            name: "obs_ad",
            label: "Radio",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "autor",
            label: "Autor",
            options: {
                filter: true,
                sort: true,
            }
        }
    ];
    const onGuardar = () => actualizar();

    const onOpenEditar = () => {
        showModal(ModalCargarCoberturaRangoFO, { onGuardar: onGuardar});
    };

    useEffect(() => {
        actualizar();
        document.title = `Rango Cobertura FO`;
    }, [])

    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
            <Grid container p={3} display='flex' justifyContent='center'>
                <Grid item xs={12}>
                    <Typography className="text-gray-900 font-bold" variant="h2">Rango Cobertura FO</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    {permisos['fo.rango.cobertura'] && (
                        <ButtonBasic
                            textButton="Nuevo Rango"
                            sx={{width: 200, height: 30}}
                            onClick={()=>onOpenEditar()}
                            variant='wk'
                            color="nextStep"
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={8} mt={2} sx={{marginBottom: 15}}>
                    <TableBase title='Resultados' columns={columns} data={items} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TablaFORangoCoberturaScreen