import { Box, Card, Grid, Typography } from "@mui/material";
import ButtonBasic from "../buttons/ButtonBasic";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useNavigate } from "react-router-dom";
import TextEstadoServicio from "../text/TextEstadoServicio";

const CardServicioSoftland = ({item, onClickTk}) => {
    const navigate = useNavigate();
    const onOpenRedirect = () =>{
        navigate(`/servicio/${item?.contrato}/cuenta`)
    };
    return (
        <Card
            sx={{background:'#fff !important', boxShadow: 3,p:2,marginTop: 2,borderRadius:4 }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                <Typography sx={{fontWeight: 'bold', color: '#000',fontSize: 15}}>{`Servicio #${item?.contrato || '0'} - ${item?.nombre}`}</Typography>
                { item?.estado?.descripcion && (
                    <TextEstadoServicio descripcion={item?.estado?.descripcion} />
                )}
            </Box>
            { item?.vip && (
                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Nivel VIP:<Box fontWeight='normal' display='inline'>{item?.vip}</Box></Typography>
                </Box>
            )}
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Dirección:<Box fontWeight='normal' display='inline'>{item?.ubicacion?.domicilio || item?.direccion}</Box></Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Coordenadas:
                    <Box fontWeight='normal' display='inline'>{(item?.ubicacion?.latitud || item?.latitud) ? `${item?.ubicacion?.latitud || item?.latitud}, ${item?.ubicacion?.longitud || item?.longitud}` : 'S/C'}</Box>
                </Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop:2}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Plan:<Box fontWeight='normal' display='inline'>{item?.plan}</Box></Typography>
            </Box>
            <Grid container spacing={2} mt={0.5}>
                {item?.extensiones?.length > 0 && (
                    <>
                        <Grid item xs={12}>
                                <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center',fontSize: 17}}>EXTENSIONES</Typography>
                        </Grid>
                        {item?.extensiones?.map((ext)=>(
                            <Grid item xs={12} md={4}>
                                <Card sx={{background: '#fff !important', boxShadow: 3,p:1}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Numero:<Box fontWeight='normal' display='inline'>{ext?.numero}</Box></Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Estado:<Box fontWeight='normal' display='inline'>{ext?.nombreEstado === 'Activo' ? 'Habilitado' : ext?.nombreEstado}</Box></Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop:1}}>
                                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>Plan:<Box fontWeight='normal' display='inline'>{ext?.plan}</Box></Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                        <Grid item xs={12}></Grid>
                    </>
                )}
                <Grid item xs={12} sm={6} sx={{display: 'flex', justifyContent: 'end', alignItems:'center'}}>
                    <ButtonBasic
                        iconStart={<LocalOfferOutlinedIcon sx={{color: '#0364ff'}} />}
                        textButton="Ver Tickets"
                        variant="wk"
                        color='nextWhite'
                        sx={{width: 320,fontWeight: 'bold', border:1,borderColor: '#0364ff'}}
                        onClick={()=>onClickTk(item?.contrato)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} sx={{display: 'flex', justifyContent: 'start', alignItems:'center'}}>
                    <ButtonBasic
                        iconStart={<LocalMallOutlinedIcon sx={{color: '#fff'}} />}
                        textButton="Ver Servicio"
                        variant="wk"
                        color='nextStep'
                        sx={{width: 320}}
                        onClick={()=>onOpenRedirect()}
                    />
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardServicioSoftland