import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateSingle } from '../../components';
import FechaFunction from '../../function/FechaFunction';
import NovedadesService from '../../services/novedades.service';

const ModalAddNovedad = ({show, hideModal, item, onGuardar, type}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fecha, setFecha] = useState(FechaFunction.format(new Date()));
    const [cuerpo, setCuerpo] = useState('');
    const [titulo, setTitulo] = useState('');
    const handleClose = () => hideModal();

    const onSubmit = async() => {
        const form = {
            fechaLimit_nov: fecha,
            cuerpo_nov: cuerpo,
            titulo_nov: titulo
        }
        if(item?.id_nov > 0) {
            form.id_nov = item.id_nov
            await NovedadesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }else{
            await NovedadesService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    useEffect(() => {
        if(item){
            setTimeout(() => {
                setTitulo(item?.titulo_nov)
                setCuerpo(item?.cuerpo_nov)
                setFecha(item?.fechaLimit_nov)
            }, 100);
        }
    }, [item])

    const styleInput = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 14,
            marginTop: -0.4
        },
        '& fieldset': {
            borderRadius: 2,
            minHeight: 50,
            border: 1,
            borderColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                color: '#000',
                minHeight: 50,
            },
            '&:hover':{
                border: 0.1,
                borderRadius: 2,
                borderColor: 'gray',
                minHeight: 45,
            },
            '&.Mui-focused': {
                '&:hover':{
                    border: 0,
                    borderRadius: 2,
                    minHeight: 45,
                }
            },
            color: 'gray',
            minHeight: 45,
        }
    }), []);

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        {item?.id_nov > 0 ? 'Editar Novedad' : 'Registrar Novedad'}
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1} sx={{minHeight: 300}}>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Titulo</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={titulo}
                                error={!titulo}
                                helperText={!titulo && 'El titulo es requerido'}
                                onChange={(e)=>setTitulo(e.target.value)}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Cuerpo</Typography>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                autoComplete='off'
                                value={cuerpo}
                                error={!cuerpo}
                                helperText={!cuerpo && 'El cuerpo es requerido'}
                                onChange={(e)=>setCuerpo(e.target.value)}
                                sx={styleInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Fecha Limite</Typography>
                            <DateSingle
                                minDate={new Date()}
                                value={fecha}
                                onChange={date => setFecha(date)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalAddNovedad