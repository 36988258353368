import { useState,useEffect,useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuxSoftland from '../../../function/AuxSoftland';
import ContactosService from '../../../services/contactos.service';
import {toast} from 'react-toastify';
import {showModal} from 'react-redux-modal-provider';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import { Box, Card, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, DateSingle, SelectCantidadSoftland, SelectVigenciaSoftland } from '../../../components';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmationDialog from '../../../modals/default/ConfirmationDialog';
import ModalServiciosSoftland from '../../../modals/softland/ModalServiciosSoftland';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePermisos from '../../../hooks/usePermisos';
import ModalLoadingSoftland from '../../../modals/loading/ModalLoadingSoftland';

const FormPaso3SinCuenta = ({contacto,contrato,previousStep}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formTratoItems, setFormTratoItems] = useState({});
  const navigate = useNavigate();
  const permisos = usePermisos();

  const onSubmit = async(data) => {
      let bandera = false;
      setIsLoading(true)
      await Promise.all( formTratoItems.map(async(item)=>{
        if(item?.importeServicio_ds && !isNaN(item.importeServicio_ds)){
          item.importeServicio_ds = Number(item?.importeServicio_ds).toFixed(6);
        }else{
          bandera = true;
        }
        const verify = await AuxSoftland.verificarItemContrato(item)
        if(verify?.estado === 'error'){
          bandera = true;
          if(Array.isArray(verify?.error) && verify?.error?.length > 0){
            verify?.error.forEach((e)=>{
              toast.error(`Verifique los datos ingresados en ${e} de ${item?.nombre}`, {
                position: "top-right",
                autoClose: 4500,
                pauseOnHover: false,
              });
            })
          }
        }
        item.cod_empresa = contrato.empresaSoft_t;
        item.tipo_contrato = contrato.tipoConSoft_t;
        item.domicilio = contrato.direccion_t;
        return ''
      }))
      if(!bandera){
          const form = {
              ...contacto,
              ...contrato,
              servicios: formTratoItems
          }
          await ContactosService.registerCuenta(form)
          .then((resp)=>{
            if(resp.cuentaId_t){
              showModal(ModalLoadingSoftland)
              setTimeout(() => {
                navigate(`/contactos/${resp.cuentaId_t}/cuenta`)
              }, 60500);
            }else{
              setIsLoading(false);
            }
          })
          .catch(()=>{
              setIsLoading(false)
          })
      }else{
          setIsLoading(false)
          toast.error('Verifique los datos ingresados', {
              position: "top-right",
              autoClose: 4500,
              pauseOnHover: false,
          });
      }
  }

  const onChangeFormTratoItems = ( idDS, value, field ) => {
      const indice = formTratoItems.findIndex(i => i.id === idDS);
      switch (field) {
          case 'cantidad_ds':
          case 'importeServicio_ds':
              formTratoItems[indice][field] = value
              if(field === 'cantidad_ds' || field === 'importeServicio_ds'){
                  formTratoItems[indice]['subtotal'] = (formTratoItems[indice]['cantidad_ds'] * formTratoItems[indice]['importeServicio_ds']).toFixed(6)
              }
          break;
          default:
              formTratoItems[indice][field] = value
              break;
      }
      setFormTratoItems([
          ...formTratoItems,
      ])
  }
  const AddItem = () =>{
      let precio = 20.661157;
      if(contrato?.tratoserv?.length > 0){
          precio = contrato?.tratoserv[0].importeServicio_ds
      }
      showModal(ModalServiciosSoftland,{setAdd: setFormTratoItems,finalStep: true,precioPlan: precio});
  }
  const onEliminar = (itemSer) => {
      showModal(ConfirmationDialog, {
          title: 'Remover el Servicio Seleccionado',
          message: '¿Esta seguro que desea remover el Servicio Seleccionado?',
          onConfirm: () => {
              const filtro = formTratoItems.filter((serv)=> serv.id !== itemSer.id)
              setFormTratoItems(filtro)
          }
      });
  };
  useEffect(() => {
    if(contrato && contrato?.tratoserv?.length > 0){
      const form = [];
      const hoyStr = moment(contrato?.fechaAlta).format('YYYY-MM-DD')
      const separarDay = hoyStr.split('-')
      contrato?.tratoserv?.map((trat)=>{
          const plan = {
              ...trat,
              id: uuidv4()
          }
          if(!plan.nombre){
              plan.nombre = plan.nombreServicio_ds;
          }
          if(!plan.tipo_descripcion){
              plan.tipo_descripcion = AuxSoftland.tipoServicioSoft(plan.tipoServicio_ds);
          }
          if(!plan.vigencia_ds){
              plan.vigencia_ds = 'N';
          }
          if(plan.vigencia_ds === 'S' && plan?.cantMeses_ds > 0){
              if(separarDay[2] === '01' || separarDay[2] === '1'){
                  const initMont = moment().startOf('month').format('YYYY-MM-DD')
                  const finMont = moment().add(plan?.cantMeses_ds, 'M').endOf('month').format('YYYY-MM-DD')
                  plan.vigencia_desde = initMont
                  plan.vigencia_hasta = finMont
              }else{
                  const startOfNextMonth = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
                  const finMont = moment(startOfNextMonth).add(plan?.cantMeses_ds, 'M').endOf('month').format('YYYY-MM-DD')
                  plan.vigencia_desde = startOfNextMonth
                  plan.vigencia_hasta = finMont
              }
          }else if(separarDay[2] === '01' || separarDay[2] === '1'){
              const initMont = moment().startOf('month').format('YYYY-MM-DD')
              const finMont = moment().endOf('month').format('YYYY-MM-DD')
              plan.vigencia_desde = initMont;
              plan.vigencia_hasta = finMont;
          }else{
              const startOfNextMonth = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
              const finMont = moment(startOfNextMonth).add(1, 'M').endOf('month').format('YYYY-MM-DD')
              plan.vigencia_desde = startOfNextMonth
              plan.vigencia_hasta = finMont
          }
          if(!plan.subtotal){
              plan.subtotal = (plan.importeServicio_ds * plan.cantidad_ds).toFixed(6);
          }
          form.push(plan)
          return ''
      })
      setFormTratoItems(form)
    }
  }, [contrato]);
  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
        color: 'gray',
        fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
        marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#15294b'
        },
        '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
        },
        '&.Mui-focused': {
            '&:hover':{
            border:0,
            height: 40
            }
        },
        color: 'gray',
        height: 40
    }
  }), [])
  return (
    <Box>
      <Card sx={{background: '#fff !important', pl: 3,pr:3,pb:2,mb: 5}}>
        <Grid container mt={4} spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{color: '#15294b', fontWeight: 'bold'}} variant='h3'>Items del Contrato</Typography>
          </Grid>
          <Grid item xs={6} display='flex' justifyContent='end'>
            {permisos && permisos['add-bonificaciones-step-softland'] && (
              <ButtonBasic
                textButton='Agregar items'
                iconStart={<AddOutlinedIcon sx={{color: '#0364ff'}} />}
                onClick={()=>AddItem()}
                variant='wk'
                color='nextWhite'
                sx={{border:1, borderColor: '#0364ff'}}
              />
            )}
          </Grid>
          {formTratoItems?.length > 0 &&(
            formTratoItems?.map((plan)=>(
              <Grid item xs={12} sx={{position: 'block'}}>
                <Card sx={{background: '#fff !important',boxShadow: 4,borderRadius: 3,position: 'relative',minHeight:250}}>
                  <Grid container>
                    <Grid item xs={12} sm={3} lg={2} p={2} display='flex' justifyContent='center' alignItems='center'>
                      <Card
                        sx={{
                          background: '#e6f0ff !important',
                          p: 2,
                          minHeight: 150,
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          borderRadius: 3,
                          boxShadow: "-3px 2px 0px 0px #5697ff"
                        }}>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',opacity: 0.9}}variant='h6'>{plan?.tipo_descripcion}</Typography>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold'}}variant='h4'>{plan?.nombre}</Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={10}>
                      <Grid container spacing={2} mt={1} mb={1}>
                        <Grid item xs={12} md={5}>
                          <Typography sx={{color: '#15294b',fontSize:12}}>Precio</Typography>
                          <TextField
                              fullWidth
                              autoComplete='off'
                              sx={styleInputHeight}
                              value={plan.importeServicio_ds}
                              onChange={({target})=>onChangeFormTratoItems(plan.id,target.value,'importeServicio_ds')}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography sx={{color: '#15294b',fontSize:12}}>Cantidad</Typography>
                          <SelectCantidadSoftland
                              value={plan.cantidad_ds}
                              onChange={(opt)=>onChangeFormTratoItems(plan.id,opt.value,'cantidad_ds')}
                              isDisabled={plan?.tipoServicio_ds !== 'SAI   '}
                          />
                        </Grid>
                        {plan?.tipoServicio_ds === 'SAI   ' && (
                          <Grid item xs={12} md={3}>
                            <Typography sx={{color: '#15294b',fontSize:12}}>Vigencia</Typography>
                            <SelectVigenciaSoftland
                              value={plan.vigencia_ds}
                              onChange={({target})=>onChangeFormTratoItems(plan.id,target.value,'vigencia_ds')}
                            />
                          </Grid>
                        )}
                        {plan?.tipoServicio_ds === 'SAI   ' && (
                          <Grid item xs={12} sm={5.5}>
                            <Typography sx={{color: '#15294b',fontSize:12}}>Desde:</Typography>
                            <DateSingle
                              isFullWidth
                              id="vigencia_desde"
                              value={plan.vigencia_desde}
                              onChange={(value)=>onChangeFormTratoItems(plan.id,value, 'vigencia_desde')}
                            />
                          </Grid>
                        )}
                        {plan?.tipoServicio_ds === 'SAI   ' && (
                          <Grid item xs={12} sm={5.5}>
                            <Typography sx={{color: '#15294b',fontSize:12}}>Hasta:</Typography>
                            <DateSingle
                              isFullWidth
                              id="vigencia_hasta"
                              value={plan.vigencia_hasta}
                              minDate={new Date(plan.vigencia_desde)}
                              onChange={(value)=>onChangeFormTratoItems(plan.id,value, 'vigencia_hasta')}
                            />
                          </Grid>
                        )}
                        {plan?.tipoServicio_ds === 'SAI   ' ?(
                          <>
                            <Grid item xs={6}>
                              <Typography sx={{color: '#15294b',fontSize:15,fontWeight: 'bold'}}>Sub Total</Typography>
                            </Grid>
                            <Grid item xs={6} display='flex' justifyContent='end'>
                              <Typography sx={{color: '#15294b',fontSize:15,fontWeight: 'bold',mr: 3}}>{`${plan?.subtotal} ${plan?.moneda_ds || ''}`}</Typography>
                            </Grid>
                          </>
                        ):(
                          <Grid item xs={12} display='flex'>
                            <Typography sx={{color: '#15294b',fontSize:15,fontWeight: 'bold'}}>Sub Total</Typography>
                            <Typography sx={{color: '#15294b',fontSize:15,fontWeight: 'bold',ml:2}}>{`${plan?.subtotal} ${plan?.moneda_ds || ''}`}</Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{color: '#15294b',fontWeight: 'bold'}} component='div'>Observaciones: <Box fontWeight='normal' display='inline'>{plan?.obs_ds || plan?.obs_s || '-'}</Box></Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                { plan?.tipoServicio_ds === 'SAI   ' && permisos && permisos['add-bonificaciones-step-softland'] && (
                  <IconButton
                    sx={{position: 'absolute', top:10,right: 15,boxShadow: 3}}
                    onClick={() => onEliminar(plan)}
                  >
                    <DeleteOutlineOutlinedIcon sx={{color: '#15294b'}} />
                  </IconButton>
                )}
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Card>
      <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center',mb:15}}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          isLoading={isLoading}
          color='nextWhite'
          sx={{border: 1, borderColor: '#0364ff'}}
          onClick={()=>previousStep()}
          iconStart={<ArrowBackIcon sx={{color: '#0364ff'}} />}
        />
        <ButtonBasic
          textButton='Enviar Datos'
          variant='wk'
          color='nextStep'
          sx={{ml:2}}
          isLoading={isLoading}
          onClick={()=>onSubmit()}
        />
      </Box>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
  contrato: state.contactos.contrato
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso3SinCuenta);