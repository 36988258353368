import {memo,useState,useEffect,useCallback} from 'react';
import {GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import ControlPanel from './ControlPanel';
import { CircularProgress } from '@mui/material';

const libraries = ['places','drawing'];

function MapBase({
    id,
    zoom,
    options,
    controlPanelOptions,
    coordinates,
    isSearchable,
    marks,
    mapContainerStyle,
    onChange,
    onClick,
    onSearch,
    children,
    defaultProps,
    insertCoord
}) {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBtCQByICBfwfxHs-ny0LftBhY6iMwzMGg',
        libraries,
    });
    const [map, setMap] = useState(null);
    const [lastCoordinatesSend, setLastCoordinatesSend] = useState(coordinates);
    const [mapTypeId, setMapTypeId] = useState('roadmap');
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const onDragEnd = () => {
        if(!(map && map.center)){
            return;
        }
        const newCoordinates = {
            lat: map.center.lat(),
            lng: map.center.lng()
        };
    };

    const onClickMap = (ev) => {
        onClick({
            lat: ev.latLng.lat(),
            lng: ev.latLng.lng()
        });
    };

    const onLoad = useCallback(function callback(mapLoaded) {
        setMap(mapLoaded);
    }, []);

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    const onZoomChanged = (a) => {
        if(map){
            setCurrentZoom(map.getZoom());
        }
    }

    useEffect(() => {
        if(map){
            map.setCenter(coordinates);
            onDragEnd();
        }
        setCurrentZoom(zoom);
    }, [coordinates]);

    useEffect(() => {
        if(marks.length>0 && map){
            const bounds = new window.google.maps.LatLngBounds();
            marks.forEach(mark=>{
                bounds.extend(mark);
            })
            map.fitBounds(bounds);
        }
    }, [marks,map]);

    return isLoaded ? (
        <GoogleMap
            {...defaultProps}
            id={id}
            center={coordinates}
            mapContainerStyle={mapContainerStyle}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                ...options,
            }}
            zoom={currentZoom}
            mapTypeId={mapTypeId}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onDragEnd={onDragEnd}
            onClick={onClickMap}
            onZoomChanged={onZoomChanged}
        >
            <ControlPanel
                zoom={currentZoom}
                options={options}
                map={map}
                coordinates={coordinates}
                isSearchable={isSearchable}
                mapTypeId={mapTypeId}
                onChangeMapType={setMapTypeId}
                onChangeZoom={setCurrentZoom}
                onSearch={onSearch}
                insertCoord={insertCoord}
                {...controlPanelOptions}
            />
            {children}
        </GoogleMap>
    ) : (
        <CircularProgress sx={{color: '#000'}} />
    );
}

MapBase.defaultProps = {
    zoom: 10,
    marks: [],
    isSearchable: true,
    coordinates: {
        lat: -24.7821,
        lng: -65.4232
    },
    mapContainerStyle: {
        width: '100%',
        height: '100%'
    },
    options: {
        minZoom: 14,
        maxZoom: 19
    },
    controlPanelOptions: {},
    onChange: () => {},
    onClick: () => {},
    onSearch: () => {},
};

export default memo(MapBase);
