import { useEffect, useMemo, useState } from 'react';
import { Box, Card, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import BadgeIcon from '@mui/icons-material/Badge';
import { BoxPrefactibilidad, ButtonBasic, DateSingle, InputSearchContactNubicom, MapBase, SelectCanalesSoftland, SelectCategoriaSoftland, SelectEstadoCliente, SelectEstadoConsulta, SelectIvaSoftland, SelectLocalidadesSoftland, SelectLugaresCalendar, SelectTipoDocSoftland, SwitchCustom } from '../../../components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExploreIcon from '@mui/icons-material/Explore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AuxSoftland from '../../../function/AuxSoftland';
import ContactosService from '../../../services/contactos.service';
import ContactosAction from '../../../store/actions/contactos';
import InfContactoService from '../../../services/infContacto.service';
import {toast} from 'react-toastify';
import ModalConsultaContacto from '../../../modals/consulta/ModalConsultaContacto';
import { showModal } from 'react-redux-modal-provider';
import AuxiliarFunction from '../../../function/AuxiliarFunction';
import usePermisos from '../../../hooks/usePermisos';
import FechaFunction from '../../../function/FechaFunction';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import KmzService from '../../../services/kmz.service';

const FormPaso1Screen = ({
  item,
  tipo,
  contacto,
  nextStep,
  iniciarContacto,
  iniciarContrato
}) => {
  const permisos = usePermisos();
  const [tipoRegistro, setTipoRegistro] = useState(tipo || 'Consulta');
  const [tipoMapa, setTipoMapa] = useState('fibra');
  const [isLoading, setIsLoading] = useState(false);
  const [igualDomicilio, setIgualDomicilio] = useState(false);
  const [isLoadingPrefa, setIsLoadingPrefa] = useState(false);
  const [contactoExist, setContactoExist] = useState(false);
  const [coberturaMax, setCoberturaMax] = useState(0);
  const [isDni, setIsDni] = useState(item?.tipoDoc_c || '96');
  const [nroIdContacto, setNroIdContacto] = useState(null);
  const [existeSoftland, setExisteSoftland] = useState(false);
  const [err, setErr] = useState(false);
  const [coordenadas, setCoordenadas] = useState({texto: null,estadoErr: false})

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [formAll, setFormAll] = useState({
    apellido_c: {
      texto: null,
      textoErr: 'El apellido es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombre_c: {
      texto: null,
      textoErr: 'El nombre es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    tipoDoc_c: {
      texto: '96',
      textoErr: 'El tipo de documento es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    documento_c: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    fnacimiento_icon: {
      texto: null,
      textoErr: 'El documento es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    categoria_cxe: {
      texto: 'MAS',
      textoErr: 'La categoria es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    iva_c: {
      texto: 'C',
      textoErr: 'El iva es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    canalcomu_c: {
      texto: '09',
      textoErr: 'El canal es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    celular_c: {
      texto: null,
      textoErr: 'El celular es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    telefono_c: {
      texto: null,
      textoErr: 'El telefono es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    email_c: {
      texto: null,
      textoErr: 'El correo es requerido',
      estadoErr: false,
      tipo: 'email'
    },
    cpostal_c: {
      texto: '4400',
      textoErr: 'El CCPP es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    provincia_c: {
      texto: '917',
      textoErr: 'La provincia es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    barrio_c: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    direccion_c: {
      texto: null,
      textoErr: 'La dirección es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    lugarId_t: {
      texto: 1,
      textoErr: 'El lugar del calendario es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    lugarId_c: {
      texto: 1,
      textoErr: 'El lugar del calendario es requerido',
      estadoErr: false,
      tipo: 'number'
    },
    barrio_t: {
      texto: null,
      textoErr: 'El barrio es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    calle_t: {
      texto: null,
      textoErr: 'La dirección es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    estadoCliente_c: {
      texto: 'En espera de documentacion',
      textoErr: 'El estado de la consulta es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    latitud_c: {
      texto: null,
      textoErr: 'Las coordenadas son requerida',
      estadoErr: false,
      tipo: 'string'
    },
    longitud_c: {
      texto: null,
      textoErr: 'La longitud es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    coordenadas_c: {
      texto: null,
      textoErr: 'La coordenada es requerida',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompleto_c: {
      texto: null,
      textoErr: 'El nombre completo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    nombreCompletoContacto1_c: {
      texto: null,
      textoErr: 'El nombre completo es requerido',
      estadoErr: false,
      tipo: 'string'
    },
    dniContacto1_c: {
      texto: null,
      textoErr: 'El dni es requerido',
      estadoErr: false,
      tipo: 'string'
    },
  });
  const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const onChangeForm = (value,input) =>{
    let formOld = formAll;
    if(!formOld[input]){
      formOld[input] = {
        texto: value,
        textoErr: `El ${input} es requerido`,
        estadoErr: false,
        tipo: 'string'
      }
    }else{
      formOld[input].texto = value
    }
    if((!value || value === '') && input !== 'telefono_c'){
      formOld[input].estadoErr = true
    }else{
      if(input === 'email_c'){
        if(validateEmail(value)){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else if(input === 'documento_c'){
        if(value > 1000000){
          formOld[input].estadoErr = false
        }else{
          formOld[input].estadoErr = true
        }
      }else if(input === 'canalcomu_c'){
        formOld[input].estadoErr = false;
        if(value === '06'){
          onChangeFormSetError('celular_c',true)
        }else if(value === '03'){
          onChangeFormSetError('email_c',true)
        }
      }else{
        formOld[input].estadoErr = false
      }
    }
    setFormAll({...formOld})
  }
  const onChangeFormSetError = (input,clear) =>{
    let formOld = formAll;
    formOld[input].estadoErr = !clear ? true : false
    setFormAll({...formOld})
  }
  const [prefactibilidad, setPrefactibilidad] = useState({
    color: "#ebedf0",
    descripcion: 'Estado'
});
  const [mapLocation, setMapLocation] = useState({
    lat: -24.789251,
    lng: -65.410393
  });
  const [prefactibilidadLocation, setPrefactibilidadLocation] = useState({
    lat: -24.789251,
    lng: -65.410393
  });

  const styleInputHeight = useMemo(() => ({
    '& .MuiInputLabel-root':{
      color: 'gray',
      fontSize: 15
    },
    '& fieldset': {
        borderRadius: 2,
        border: 1,
        borderColor: 'gray',
        height: 45
    },
    '& label':{
      marginTop: -0.5,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border:1,
        height: 45,
        color: '#000'
      },
      '&:hover':{
        border: 1,
        borderRadius: 2,
        borderColor: 'gray',
        height: 40
      },
      '&.Mui-focused': {
        '&:hover':{
          border:0,
          height: 40
        }
      },
      color: 'gray',
      height: 40
    }
  }), [])

  const onPrefa = async(newCoord) =>{
    onChangeForm(newCoord.lat,'latitud_c')
    onChangeForm(newCoord.lng,'longitud_c')
    onChangeForm(`${newCoord.lat},${newCoord.lng}`,'coordenadas_c')
    setCoordenadas({texto: `${newCoord.lat},${newCoord.lng}`, estadoErr: false})
    setIsLoadingPrefa(true)
    await KmzService.coberturaRF({lat: newCoord.lat, lng:newCoord.lng}).then((r)=>{
      if(r?.descripcion && (r?.descripcion === 'Sin Disponibilidad para la venta' || r?.descripcion === 'Sin Cobertura')){
        onChangeForm(r.descripcion,'estadoCliente_c')
      }else{
        onChangeForm('Evaluando propuesta','estadoCliente_c')
      }
      setPrefactibilidad(r);
      if(r?.rango?.length > 0){
          setCoberturaMax(r.rango[1])
      }
      setIsLoadingPrefa(false)
    }).catch((err)=>{
      setIsLoadingPrefa(false)
    })
  }
  const onPrefaFO = async(newCoord) =>{
    onChangeForm(newCoord.lat,'latitud_c')
    onChangeForm(newCoord.lng,'longitud_c')
    onChangeForm(`${newCoord.lat},${newCoord.lng}`,'coordenadas_c')
    setCoordenadas({texto: `${newCoord.lat},${newCoord.lng}`, estadoErr: false})
    setIsLoadingPrefa(true)
    await KmzService.prefaCoberturaFO({lat: newCoord.lat, lng:newCoord.lng}).then((r)=>{
      if(r?.cobertura && (r?.cobertura === 'Sin Cobertura')){
        onChangeForm(r.cobertura,'estadoCliente_c')
      }else{
        onChangeForm('Evaluando propuesta','estadoCliente_c')
      }
      const form = {
          color: (r?.cobertura === 'Cobertura hasta 600Mbs' || r?.cobertura === 'Con Cobertura') ? '#2ECC71' : '#EA4335',
          descripcion: r?.cobertura
      }
      if(r?.cobertura === 'Sin disponibilidad para la venta'){
        form.color = '#FBBC05';
      }
      setPrefactibilidad(form);
      if((r?.cobertura === 'Cobertura hasta 600Mbs' || r?.cobertura === 'Con Cobertura')){
          setCoberturaMax(600)
      }else{
        setCoberturaMax(0)
      }
      setIsLoadingPrefa(false)
    }).catch((err)=>{
      setIsLoadingPrefa(false)
    })
  }
  const onClickMap = async(newCoord,moverCamara) => {
      if(moverCamara){
          setMapLocation(newCoord)
      }
      if(tipoMapa === 'radio'){
        await onPrefa(newCoord)
      }else if(tipoMapa === 'fibra'){
        await onPrefaFO(newCoord)
      }
      setPrefactibilidadLocation(newCoord);
  }
  const onSubmitContacto = async(btnSalir) => {
    const form = {...item,...contacto,type: 'asesor'};
    let isOk = false;
    setIsLoading(true);
    const inputRequired = ['tipoDoc_c','documento_c', 'categoria_cxe',
    'iva_c', 'canalcomu_c', 'celular_c', 'email_c', 'cpostal_c', 'provincia_c', 'barrio_c', 'direccion_c',
    'lugarId_t', 'barrio_t', 'calle_t', 'latitud_c','longitud_c']
    if(isDni === '96'){
      inputRequired.push('apellido_c', 'nombre_c');
    }else{
      inputRequired.push('nombreCompleto_c', 'nombreCompletoContacto1_c', 'dniContacto1_c');
    }
    const propertyNames = Object.keys(formAll);
    await Promise.resolve(
      propertyNames.forEach((name)=>{
      if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
        form[name] = formAll[name].texto
      }else{
        if(inputRequired.includes(name)){
          isOk = true;
          onChangeFormSetError(name)
        }
      }
    }))
    if(!isOk){
      if(!form?.tipoConSoft_t || !form?.listPrecio_c || !form?.listaPrecioSoft_t){
        if(form.categoria_cxe){
            const aux = AuxSoftland.armadoCategoria(form.categoria_cxe);
            form.listPrecio_c = aux?.listaPrecio;
            form.listaPrecioSoft_t = aux?.listaPrecio;
            form.tipoConSoft_t = aux?.tipoContrato;
        }
      }
      if(form?.nombreCompletoContacto1_c){
        form.nombreCompletoContacto1_c = form?.nombreCompletoContacto1_c?.toUpperCase();
      }
      if(form?.cuenta){
        toast.success('Los datos del contacto son validos.', {
          position: "top-right",
          autoClose: 3500,
          pauseOnHover: false,
        });
        if(btnSalir){
          navigate('/contactos')
        }else{
          form.cobertura = coberturaMax;
          form.tipoMapa = tipoMapa;
          iniciarContacto(form)
          iniciarContrato({
              listaPrecioSoft_t: form.listaPrecioSoft_t,
              tipoConSoft_t: form.tipoConSoft_t,
              empresaSoft_t: 'EMP001',
              contactoXempId_t: form.id_cxe,
              barrio_t: form.barrio_t,
              calle_t: form.calle_t,
              codpostal_t: form.codpostal_t,
              codprovincia_t: form.codprovincia_t,
              lugarId_t: form.lugarId_t
          })
          if(form?.fnacimiento_icon){
              ContactosService.registerInfo({
                  type: 'nubicom',
                  cuenta: form?.cuenta,
                  fnacimiento_icon: form?.fnacimiento_icon
              })
          }
          setIsLoading(false)
          nextStep();
        }
      }else if(form?.id_c > 0 || contacto?.id_c > 0){
        if(isDni === '96'){
          form.tipoDoc_c = '96';
          form.nombreCompleto_c = `${form?.apellido_c?.toUpperCase()} ${form?.nombre_c?.toUpperCase()}`;
        }else{
          form.tipoDoc_c = '80';
          form.nombreCompleto_c = form.nombreCompleto_c?.toUpperCase()
        }
        if(!btnSalir){
          form.estadoCliente_c = 'En espera de documentacion';
        }
        ContactosService.update({
            ...contacto,
            ...form
        }).then((response)=>{
          toast.success('Se modifico el contacto correctamente.', {
            position: "top-right",
            autoClose: 3500,
            pauseOnHover: false,
          });
          if(btnSalir){
            navigate('/contactos')
          }else{
            const recuperarDatos={
                ...form,
                ...response,
                contactoId: response.id_c,
                id_cxe: form.id_cxe,
                cobertura: coberturaMax,
                tipoMapa: tipoMapa
            }
            iniciarContacto(recuperarDatos);
            iniciarContrato({
                listaPrecioSoft_t: form.listaPrecioSoft_t,
                tipoConSoft_t: form.tipoConSoft_t,
                empresaSoft_t: 'EMP001',
                contactoXempId_t: form.id_cxe,
                barrio_t: form.barrio_t,
                calle_t: form.calle_t,
                codpostal_t: form.codpostal_t,
                codprovincia_t: form.codprovincia_t,
                lugarId_t: form.lugarId_t
            });
            setIsLoading(false)
            nextStep();
          }
        })
        .finally(()=>setIsLoading(false));
      }else{
        form.empresaId_cxe = 1;
        if(isDni === '96'){
          form.tipoDoc_c = '96';
          form.nombreCompleto_c = `${form?.apellido_c?.toUpperCase()} ${form?.nombre_c?.toUpperCase()}`;
        }else{
          form.tipoDoc_c = '80';
          form.nombreCompleto_c = form.nombreCompleto_c?.toUpperCase()
        }
        if(!btnSalir){
          form.estadoCliente_c = 'En espera de documentacion';
        }
        ContactosService.register(form).then((response)=>{
          toast.success('Se registro el contacto correctamente.', {
            position: "top-right",
            autoClose: 3500,
            pauseOnHover: false,
          });
          if(btnSalir){
            navigate('/contactos')
          }else{
            const recuperarDatos={
                ...form,
                ...response,
                cobertura: coberturaMax,
                tipoMapa: tipoMapa
            }
            recuperarDatos.contactoXempId_t = response.id_cxe;
            recuperarDatos.id_cxe = response.id_cxe;
            recuperarDatos.id_c = response.id_cxe;
            iniciarContacto(recuperarDatos);
            iniciarContrato({
                listaPrecioSoft_t: form.listaPrecioSoft_t,
                tipoConSoft_t: form.tipoConSoft_t,
                empresaSoft_t: 'EMP001',
                contactoXempId_t: response.id_cxe,
                barrio_t: form.barrio_t,
                calle_t: form.calle_t,
                codpostal_t: form.codpostal_t,
                codprovincia_t: form.codprovincia_t,
                lugarId_t: form.lugarId_t
            });
            setIsLoading(false);
            nextStep();
          }
        })
        .finally(()=>setIsLoading(false));
      }
    }else{
      setIsLoading(false);
    }
  }
  const cleanAll = () =>{
    dispatch(ContactosAction.iniciarContacto(null))
    dispatch(ContactosAction.iniciarContrato(null))
    navigate('/load',{state:'/contactos/nuevonubi'})
}
  useEffect(() => {
    if(item?.cuenta){
      onSelectContact(item)
    }else{
      const form = {
        apellido_c: {
          texto: item?.apellido_c,
          textoErr: 'El apellido es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        nombre_c: {
          texto: item?.nombre_c,
          textoErr: 'El nombre es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        tipoDoc_c: {
          texto: item?.tipoDoc_c || '96',
          textoErr: 'El tipo de documento es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        documento_c: {
          texto: item?.documento_c,
          textoErr: 'El documento es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        fnacimiento_icon: {
          texto: null,
          textoErr: 'El documento es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        categoria_cxe: {
          texto: item?.categoria_cxe || 'MAS',
          textoErr: 'La categoria es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        iva_c: {
          texto: item?.iva_c || 'C',
          textoErr: 'El iva es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        canalcomu_c: {
          texto: item?.canalcomu_c || '09',
          textoErr: 'El canal es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        celular_c: {
          texto: item?.celular_c,
          textoErr: 'El celular es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        telefono_c: {
          texto: item?.telefono_c,
          textoErr: 'El telefono es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        email_c: {
          texto: item?.email_c,
          textoErr: 'El correo es requerido',
          estadoErr: false,
          tipo: 'email'
        },
        cpostal_c: {
          texto: item?.cpostal_c || '4400',
          textoErr: 'El CCPP es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        provincia_c: {
          texto: item?.provincia_c || '917',
          textoErr: 'La provincia es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        barrio_c: {
          texto: item?.barrio_c,
          textoErr: 'El barrio es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        direccion_c: {
          texto: item?.direccion_c,
          textoErr: 'La dirección es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        lugarId_t: {
          texto: item?.lugarId_c || 1,
          textoErr: 'El lugar del calendario es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        lugarId_c: {
          texto: item?.lugarId_c || 1,
          textoErr: 'El lugar del calendario es requerido',
          estadoErr: false,
          tipo: 'number'
        },
        barrio_t: {
          texto: '',
          textoErr: 'El barrio es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        calle_t: {
          texto: '',
          textoErr: 'La dirección es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        estadoCliente_c: {
          texto: 'En espera de documentacion',
          textoErr: 'El estado de la consulta es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        latitud_c: {
          texto: item?.latitud_c,
          textoErr: 'Las coordenadas son requerida',
          estadoErr: false,
          tipo: 'string'
        },
        longitud_c: {
          texto: item?.longitud_c,
          textoErr: 'La longitud es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        coordenadas_c: {
          texto: null,
          textoErr: 'La coordenada es requerida',
          estadoErr: false,
          tipo: 'string'
        },
        nombreCompleto_c: {
          texto: item?.nombreCompleto_c,
          textoErr: 'El nombre completo es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        nombreCompletoContacto1_c: {
          texto: item?.nombreCompletoContacto1_c,
          textoErr: 'El nombre completo es requerido',
          estadoErr: false,
          tipo: 'string'
        },
        dniContacto1_c: {
          texto: item?.dniContacto1_c,
          textoErr: 'El dni es requerido',
          estadoErr: false,
          tipo: 'string'
        },
      }
      setNroIdContacto({contactoId: item?.id_c})
      setFormAll(form)
    }
  }, [])
  useEffect(() => {
    if(nroIdContacto?.cuentaId || nroIdContacto?.contactoId ){
        InfContactoService.getAll(nroIdContacto).then((ress)=>{
            if(ress?.fnacimiento_icon){
              onChangeForm(ress.fnacimiento_icon,'fnacimiento_icon')
            }else{
              onChangeForm(null,'fnacimiento_icon')
            }
        })
    }
  }, [nroIdContacto])

  const categoriaSoftland = (cat) =>{
    const aux = AuxSoftland.armadoCategoria(cat);
    onChangeForm(aux?.listaPrecio, 'listPrecio_c')
    onChangeForm(aux?.listaPrecio, 'listaPrecioSoft_t');
    onChangeForm(aux?.tipoContrato, 'tipoConSoft_t')
  }
  const onSelectContact = (datos) =>{
    setContactoExist(true)
    if(datos?.id_c){
      if(datos?.cuentaNubi_c){
        setNroIdContacto({cuentaId: datos?.cuenta})
        setExisteSoftland(true);
        onChangeForm(datos?.nombre_completo, 'apellido_c');
        onChangeForm(datos?.nombre_completo, 'nombre_c');
        onChangeForm( datos?.nombre_completo, 'nombreCompleto_c')
        onChangeForm( datos?.dni_cuit?.numero, 'documento_c')
        onChangeForm( datos?.dni_cuit?.codigo || '96', 'tipoDoc_c')
        setIsDni(datos?.dni_cuit?.codigo)
        onChangeForm( datos?.categoria?.codigo || 'MAS', 'categoria_cxe')
        onChangeForm( datos?.contacto?.email?.trim(), 'email_c')
        onChangeForm( datos?.contacto?.telefono, 'celular_c')
        onChangeForm( datos?.iva?.codigo || 'C', 'iva_c')
        onChangeForm( datos?.ubicacion?.direccion, 'direccion_c')
        onChangeForm( datos?.ubicacion?.codigo_postal || '4400', 'cpostal_c')
        onChangeForm( datos?.ubicacion?.codigo_provincia || '917', 'provincia_c')
        onChangeForm( datos?.ubicacion?.codigo_postal || '4400', 'codpostal_t')
        onChangeForm( datos?.ubicacion?.codigo_provincia || '917', 'codprovincia_t')
        onChangeForm( '010', 'condicionPago_c')
        onChangeForm( datos?.lista_precio?.codigo || 'HO01      ', 'listPrecio_c')
        onChangeForm( datos?.cuenta, 'cuenta')
        onChangeForm( datos?.canalcomu_c || '09', 'canalcomu_c')
        onChangeForm( datos?.barrio_c, 'barrio_c')
        onChangeForm( datos?.telefono_c, 'telefono_c')
        onChangeForm( datos?.id_cxe, 'id_cxe')
        onChangeForm( datos?.id_c, 'id_c')
        onChangeForm( datos?.lugarId_c || 1, 'lugarId_c')
        onChangeForm( datos?.lugarId_c || 1, 'lugarId_t')
        if(datos?.ubicacion?.latitud && datos?.ubicacion?.longitud){
          onClickMap({
              lat:Number(datos?.ubicacion?.latitud),
              lng:Number(datos?.ubicacion?.longitud)
          },true)
        }
        if(datos?.lista_precio?.codigo){
          categoriaSoftland(datos?.lista_precio?.codigo)
        }
        onChangeForm(datos?.latitud_c, 'latitud_c')
        onChangeForm(datos?.longitud_c, 'longitud_c')
      }else{
        setExisteSoftland(false);
        setIsDni(datos?.tipoDoc_c || '96')
        setNroIdContacto({contactoId: datos?.id_c})
        onChangeForm(datos?.apellido_c || '', 'apellido_c');
        onChangeForm(datos?.nombre_c || '', 'nombre_c');
        onChangeForm(datos?.nombreCompleto_c, 'nombreCompleto_c');
        onChangeForm(datos?.documento_c || '', 'documento_c');
        onChangeForm(datos?.tipoDoc_c || '96', 'tipoDoc_c');
        onChangeForm(datos?.categoria_cxe || 'MAS', 'categoria_cxe');
        onChangeForm(datos?.email_c?.trim() || '', 'email_c');
        onChangeForm(datos?.celular_c || '', 'celular_c');
        onChangeForm(datos?.iva_c || 'C', 'iva_c');
        onChangeForm(datos?.direccion_c || '', 'direccion_c');
        onChangeForm(datos?.cpostal_c || '4400', 'cpostal_c');
        onChangeForm(datos?.provincia_c || '917', 'provincia_c');
        onChangeForm(datos?.cpostal_c || '4400', 'codpostal_t');
        onChangeForm(datos?.provincia_c || '917', 'codprovincia_t');
        onChangeForm('010', 'condicionPago_c');
        onChangeForm(datos?.listPrecio_c || 'HO01      ', 'listPrecio_c');
        onChangeForm(datos?.canalcomu_c || '09', 'canalcomu_c');
        onChangeForm(datos?.barrio_c || '', 'barrio_c');
        onChangeForm(datos?.telefono_c || '', 'telefono_c');
        onChangeForm(datos?.id_cxe, 'id_cxe');
        onChangeForm(datos?.lugarId_c || 1, 'lugarId_c');
        onChangeForm(datos?.lugarId_c || 1, 'lugarId_t');
        onChangeForm(datos?.id_c, 'id_c');
        onChangeForm(datos?.nombreCompletoContacto1_c || '', 'nombreCompletoContacto1_c');
        onChangeForm(datos?.dniContacto1_c || '', 'dniContacto1_c');
        if(datos?.latitud_c && datos?.longitud_c){
          onClickMap({
            lat:Number(datos?.latitud_c),
            lng:Number(datos?.longitud_c)
          },true)
        }else{
          onChangeForm('', 'coordenadas_c');
          setCoordenadas({texto: '', estadoErr: true})
        }
        if(datos?.categoria_cxe){
          categoriaSoftland(datos?.categoria_cxe)
        }
        onChangeForm(datos?.latitud_c || '', 'latitud_c');
        onChangeForm(datos?.longitud_c || '', 'longitud_c');
      }
    }else{
      if(datos?.cuenta){
        setExisteSoftland(true);
        setNroIdContacto({cuentaId: datos?.cuenta})
        setIsDni(datos?.dni_cuit?.codigo)
        onChangeForm(datos?.nombre_completo || '', 'apellido_c')
        onChangeForm(datos?.nombre_completo || '', 'nombre_c')
        onChangeForm(datos?.nombre_completo || '', 'nombreCompleto_c')
        onChangeForm(datos?.dni_cuit?.numero || '', 'documento_c')
        onChangeForm(datos?.dni_cuit?.codigo || '96', 'tipoDoc_c')
        onChangeForm(datos?.categoria?.codigo || 'MAS', 'categoria_cxe')
        onChangeForm(datos?.contacto?.email?.trim() || '', 'email_c')
        onChangeForm(datos?.contacto?.telefono || '', 'celular_c')
        onChangeForm(datos?.iva?.codigo || 'C', 'iva_c')
        onChangeForm(datos?.ubicacion?.direccion || '', 'direccion_c')
        onChangeForm(datos?.ubicacion?.codigo_postal || '4400', 'cpostal_c')
        onChangeForm(datos?.ubicacion?.codigo_provincia || '917', 'provincia_c')
        onChangeForm(datos?.ubicacion?.codigo_postal || '4400', 'codpostal_t')
        onChangeForm(datos?.ubicacion?.codigo_provincia || '917', 'codprovincia_t')
        onChangeForm('010', 'condicionPago_c')
        onChangeForm(datos?.lista_precio?.codigo || 'HO01      ', 'listPrecio_c')
        onChangeForm(datos?.cuenta, 'cuenta')
        onChangeForm(datos?.ubicacion?.direccion || '', 'barrio_c')
        onChangeForm('', 'telefono_c')
        onChangeForm(datos?.ubicacion?.latitud_c || '', 'latitud_c')
        onChangeForm(datos?.ubicacion?.longitud_c || '', 'longitud_c')
        onChangeForm(datos?.lugarId_c || 1, 'lugarId_c')
        onChangeForm(datos?.lugarId_c || 1, 'lugarId_t')
        if(datos?.lista_precio?.codigo){
          categoriaSoftland(datos?.lista_precio?.codigo)
        }
        if(datos?.ubicacion?.latitud && datos?.ubicacion?.longitud){
          onChangeForm(`${datos?.ubicacion?.latitud},${datos?.ubicacion?.longitud}`,'coordenadas_c')
          setCoordenadas({texto: `${datos?.ubicacion?.latitud},${datos?.ubicacion?.longitud}`,estadoErr: false})
          onClickMap({
              lat:Number(datos?.ubicacion?.latitud),
              lng:Number(datos?.ubicacion?.longitud)
          },true)
        }else{
            onClickMap({
                lat:Number('-24.789251'),
                lng:Number('-65.410393')
            },true)
          onChangeForm('-24.789251,-65.410393','coordenadas_c')
          setCoordenadas({texto: '-24.789251,-65.410393',estadoErr: false})
            toast.error('Cliente sin Ubicacion.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
      }
      onChangeForm(null, 'id_cxe');
      onChangeForm(null, 'id_c');
    }
    setIgualDomicilio(false);
    onChangeForm('', 'calle_t');
    onChangeForm('', 'barrio_t');
  }
  const onMismoDomicilio = () =>{
    if(!igualDomicilio){
      onChangeForm(formAll?.direccion_c?.texto || '', 'calle_t');
      onChangeForm(formAll?.barrio_c?.texto || '', 'barrio_t');
    }else{
      onChangeForm('', 'calle_t');
      onChangeForm('', 'barrio_t');
    }
    setIgualDomicilio(!igualDomicilio)
  }
  const onSubmitConsulta = async()=>{
    const form = {...item,...contacto,type: 'nubicom'};
    let isOk = false;
    setIsLoading(true);
    const inputRequired = ['canalcomu_c','categoria_cxe','estadoCliente_c']
    if(formAll.canalcomu_c.texto === '06'){
      inputRequired.push('email_c');
      onChangeFormSetError('celular_c',true)
    }else if(formAll.canalcomu_c.texto === '03'){
      inputRequired.push('celular_c');
      onChangeFormSetError('email_c',true)
    }
    if(isDni === '96'){
      inputRequired.push('apellido_c', 'nombre_c');
    }else{
      inputRequired.push('nombreCompleto_c', 'nombreCompletoContacto1_c');
    }
    const propertyNames = Object.keys(formAll);
    await Promise.resolve(
      propertyNames.forEach((name)=>{
      if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
        form[name] = formAll[name].texto
      }else{
        if(inputRequired.includes(name)){
          isOk = true;
          onChangeFormSetError(name)
        }
      }
    }))
    if(!isOk){
      if(form?.nombreCompletoContacto1_c){
        form.nombreCompletoContacto1_c = form?.nombreCompletoContacto1_c?.toUpperCase();
      }
      if(form?.barrio_t === '' || !form?.barrio_t){
        form.barrio_t = 'S/B'
      }
      if(form?.calle_t === '' || !form?.calle_t){
        form.calle_t = 'S/C'
      }
      form.barrio_c =  form?.barrio_t || 'S/B';
      form.direccion_c =  form?.calle_t || 'S/C';
      if(isDni === '96'){
        form.nombreCompleto_c = `${form.apellido_c.toUpperCase()} ${form.nombre_c.toUpperCase()}`;
      }
      if(coberturaMax && coberturaMax > 0){
        form.cobertura = coberturaMax
      }
      form.tipoMapa = tipoMapa;
      const direccionCompleta = `${form?.barrio_c.toUpperCase()} - ${form?.direccion_c?.toUpperCase()}`;
      let coordCompleta = null;
      if(form?.latitud_c && form?.longitud_c){
        coordCompleta = `${form?.latitud_c},${form?.longitud_c}`;
      }
      if(!contactoExist && !form?.id_c ){
        await ContactosService.registerConsulta(form).then((resp)=>{
          toast.success('Se registro el contacto correctamente.', {
            position: "top-right",
            autoClose: 3500,
            pauseOnHover: false,
          });
          if(coberturaMax && coberturaMax > 0){
            resp.cobertura = coberturaMax
          }
          resp.tipoMapa = tipoMapa;
          resp.canal_con = form.canalcomu_c;
          resp.segmento_con = form.categoria_cxe;
          iniciarContacto({...form,...resp})
          showModal(ModalConsultaContacto,{
              item:resp,
              nueva: true,
              estadoCliente: form?.estadoCliente_c,
              direccionCompleta: direccionCompleta,
              coordCompleta: coordCompleta
          })
        }).catch(()=>{
          setIsLoading(false)
        })
      }else{
        if(form?.id_c){
          form.consulta = true;
          await ContactosService.update(form).then((resp)=>{
            toast.success('Se modifico el contacto correctamente.', {
              position: "top-right",
              autoClose: 3500,
              pauseOnHover: false,
            });
            if(coberturaMax && coberturaMax > 0){
              resp.cobertura = coberturaMax
            }
            resp.tipoMapa = tipoMapa;
            resp.canal_con = form.canalcomu_c;
            resp.segmento_con = form.categoria_cxe;
            iniciarContacto({...form,...resp})
            showModal(ModalConsultaContacto,{
                item:resp,
                nueva: true,
                estadoCliente: form?.estadoCliente_c,
                direccionCompleta: direccionCompleta,
                coordCompleta: coordCompleta
            })
          }).catch(()=>{
            setIsLoading(false)
          })
        }
        if(form?.cuenta){
          form.canal_con = form.canalcomu_c;
          form.segmento_con = form.categoria_cxe;
          showModal(ModalConsultaContacto,{
            item:form,
            nueva: true,
            estadoCliente: form?.estadoCliente_c,
            direccionCompleta: direccionCompleta,
            coordCompleta: coordCompleta
          })
        }
      }
    }else{
      setIsLoading(false)
    }
  }
  const cleanErrTipoRegistro = async(registro) =>{
    /* if(registro === 'Consulta'){
      const inputRequired = ['apellido_c','nombre_c','latitud_c','longitud_c','barrio_t','calle_t','canalcomu_c','categoria_cxe','celular_c','estadoCliente_c']
      const propertyNames = Object.keys(formAll);
      await Promise.resolve(
        propertyNames.forEach((name)=>{
          if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
          }else{
            if(inputRequired.includes(name)){
              onChangeFormSetError(name)
            }else{
              onChangeFormSetError(name,true)
            }
          }
      }))
    }else if(registro === 'Contacto'){
      const inputRequired = ['apellido_c', 'nombre_c','tipoDoc_c','documento_c', 'categoria_cxe',
      'iva_c', 'canalcomu_c', 'celular_c', 'email_c', 'cpostal_c', 'provincia_c', 'barrio_c', 'direccion_c',
      'lugarId_t', 'barrio_t', 'calle_t', 'latitud_c','longitud_c']
      const propertyNames = Object.keys(formAll);
      await Promise.resolve(
        propertyNames.forEach((name)=>{
          if(formAll[name]?.texto && formAll[name]?.texto !== '' && !formAll[name]?.estadoErr){
          }else{
            if(inputRequired.includes(name)){
              onChangeFormSetError(name)
            }else{
              onChangeFormSetError(name,true)
            }
          }
      }))
    } */
    setTipoRegistro(registro)
  }
  const onSubmitCoordenadas = () =>{
    setIsLoading(true)
    const quitarEspacios = coordenadas?.texto?.replace(/ /g, "")
    const separador = quitarEspacios?.split(',')
    if(separador?.length === 2){
        const validCoord = `${separador[0]}, ${separador[1]}`
        const valid = AuxiliarFunction.isValidCoordinates(validCoord)
        if(valid){
          onClickMap({lat:Number(separador[0]),lng:Number(separador[1])},true)
          setIsLoading(false)
        }else{
            setIsLoading(false)
            setCoordenadas({texto: coordenadas.texto, estadoErr: true})
        }
    }else{
        setIsLoading(false)
        setCoordenadas({texto: coordenadas.texto, estadoErr: true})
    }
  }
  const changeTipoMapa = async() => {
    if(tipoMapa === 'radio'){
      await onPrefa({lat: formAll.latitud_c.texto,lng: formAll.longitud_c.texto })
    }else if(tipoMapa === 'fibra'){
      await onPrefaFO({lat: formAll.latitud_c.texto,lng: formAll.longitud_c.texto })
    }
  }
  useEffect(() => {
    if(tipoMapa && formAll.latitud_c.texto && formAll.longitud_c.texto){
      changeTipoMapa()
    }
  }, [tipoMapa])

  return (
    <Box sx={{overflow: 'overlay',height: '76vh'}} className='side'>
      <Grid container mt={2}>
        <Grid xs={12} md={12} lg={5.9}>
          <Card sx={{background: '#fff !important'}}>
            <Grid container p={2}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft:5}} variant='h5'>Tipo de registro</Typography>
                <Typography sx={{color: '#000',marginLeft:5,fontSize:14, opacity: 0.6,marginTop:1}}>Datos obligatorios según el tipo de registro</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ToggleButtonGroup
                  color="primary"
                  value={tipoRegistro}
                  exclusive
                  onChange={(e)=>cleanErrTipoRegistro(e.target.value)}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="Contacto"
                    sx={{
                      background: '#e7e7e9',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                      '&.MuiToggleButton-root':{
                        border:2,
                      },
                      '&.Mui-selected':{
                        background: '#e6f0ff',
                        color: '#0364FF',
                        borderColor: '#0364FF',
                        border:2,
                        '&:hover':{
                          background: '#e6f0ff',
                          color: '#0364FF',
                          borderColor: '#0364FF',
                          border:2,
                        }
                      },
                      '&:hover':{
                        background: '#e6f0ff',
                        color: '#0364FF',
                        borderColor: '#0364FF',
                        border:2,
                      }
                    }}
                  >
                    Contacto
                  </ToggleButton>
                  <ToggleButton
                    value="Consulta"
                    sx={{
                      background: '#e7e7e9',borderWidth:2,borderWidth:1,fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                      '&.MuiToggleButton-root':{
                        border:2,
                      },
                      '&.Mui-selected':{
                        background: '#e6f8f2',
                        color: '#018155',
                        borderColor: '#018155',
                        border:2,
                        '&:hover':{
                          background: '#e6f8f2',
                          color: '#018155',
                          borderColor: '#018155',
                          border:2,
                        }
                      },
                      '&:hover':{
                        background: '#e6f8f2',
                        color: '#018155',
                        borderColor: '#018155',
                        border:2,
                      }
                    }}
                  >
                    Consulta
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{background: '#fff !important', marginTop:2}}>
            <Box display='flex' justifyContent='space-between' p={1}>
              <Box display='flex' pl={2} pt={1}>
                  <BadgeIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Datos Personales</Typography>
              </Box>
              <Box display='flex'>
                  <ButtonBasic
                    textButton='Limpiar registro'
                    variant="wk"
                    color="downloadHome"
                    sx={{border: 1,marginTop:0.5}}
                    onClick={()=>cleanAll()}
                  />
              </Box>
            </Box>
            <Grid container p={2} spacing={1}>
              {/* <Grid item xs={12}>
                <InputSearchContactNubicom
                  onClick={(e)=>onSelectContact(e)}
                />
              </Grid> */}
              {existeSoftland ? (
                <Grid item xs={12} mt={1}>
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                      * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    autoComplete='off'
                    sx={styleInputHeight}
                    value={formAll.nombreCompleto_c.texto}
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
              ):(
                <>
                { isDni === '80' ? (
                  <Grid item xs={12} md={12}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Razón Social</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.nombreCompleto_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'nombreCompleto_c')}
                      InputLabelProps={{shrink: formAll.nombreCompleto_c.texto}}
                      error={formAll.nombreCompleto_c.estadoErr}
                      helperText={formAll.nombreCompleto_c.estadoErr && formAll.nombreCompleto_c.textoErr}
                    />
                  </Grid>
                ):(
                  <>
                    <Grid item xs={12} md={6}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Apellido</Box>
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll.apellido_c.texto}
                        onChange={(e)=>onChangeForm(e.target.value,'apellido_c')}
                        InputLabelProps={{shrink: formAll.apellido_c.texto}}
                        error={formAll.apellido_c.estadoErr}
                        helperText={formAll.apellido_c.estadoErr && formAll.apellido_c.textoErr}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre</Box>
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        autoComplete='off'
                        sx={styleInputHeight}
                        value={formAll.nombre_c.texto}
                        onChange={(e)=>onChangeForm(e.target.value,'nombre_c')}
                        InputLabelProps={{shrink: formAll.nombre_c.texto}}
                        error={formAll.nombre_c.estadoErr}
                        helperText={formAll.nombre_c.estadoErr && formAll.nombre_c.textoErr}
                      />
                    </Grid>
                  </>
                )}
                </>
              )}
              { isDni === '80' && (
                <Grid item xs={12}>
                  <Card
                    sx={{
                      background: '#fff !important',
                      borderRadius: 1,
                      borderStyle: 'solid',
                      borderWidth:1,
                      borderColor: '#000',
                      boxShadow: 4,
                      padding: 1
                    }}
                  >
                    <Typography sx={{color: '#000',fontWeight: 'bold', fontSize: 14}}>* Apoderado/Responsable</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={8}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                            * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Nombre Completo</Box>
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          autoComplete='off'
                          sx={styleInputHeight}
                          value={formAll.nombreCompletoContacto1_c.texto}
                          onChange={(e)=>onChangeForm(e.target.value,'nombreCompletoContacto1_c')}
                          InputLabelProps={{shrink: formAll.nombreCompletoContacto1_c.texto}}
                          error={formAll.nombreCompletoContacto1_c.estadoErr}
                          helperText={formAll.nombreCompletoContacto1_c.estadoErr && formAll.nombreCompletoContacto1_c.textoErr}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                            * <Box sx={{fontWeight: 'normal',display: 'inline' }}>DNI</Box>
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          autoComplete='off'
                          sx={styleInputHeight}
                          value={formAll.dniContacto1_c.texto}
                          onChange={(e)=>onChangeForm(e.target.value,'dniContacto1_c')}
                          InputLabelProps={{shrink: formAll.dniContacto1_c.texto}}
                          error={formAll.dniContacto1_c.estadoErr}
                          helperText={formAll.dniContacto1_c.estadoErr && formAll.dniContacto1_c.textoErr}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
              {/* <Grid item xs={4} md={4}>
                <Typography sx={{color: '#000'}}>Tipo Doc.</Typography>
                <SelectTipoDocSoftland
                  isInvalid={formAll.tipoDoc_c.estadoErr}
                  value={formAll.tipoDoc_c.texto}
                  onChange={(e)=>{
                    onChangeForm(e.value,'tipoDoc_c')
                    if(e.value === '96'){
                      onChangeForm('C','iva_c')
                    }else{
                      onChangeForm('I','iva_c')
                    }
                    setIsDni(e.value)
                  }}
                  disabled={existeSoftland}
                />
                {formAll.tipoDoc_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.tipoDoc_c.textoErr}</Typography>
                )}
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>DNI</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  sx={styleInputHeight}
                  autoComplete='off'
                  type='number'
                  value={formAll.documento_c.texto}
                  onChange={(e)=>onChangeForm(Number(e.target.value),'documento_c')}
                  InputLabelProps={{shrink: formAll.documento_c.texto}}
                  inputProps={{ readOnly: existeSoftland, min: 1000000 , inputProps: { min: 1000000 } }}
                  error={formAll.documento_c.estadoErr}
                  helperText={formAll.documento_c.estadoErr && formAll.documento_c.textoErr}
                  FormHelperTextProps={{ className: '-mt-1' }}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <Typography sx={{color: '#000'}}>Fecha de Nacimiento</Typography>
                <DateSingle
                  fullWidth
                  maxDate={FechaFunction.subtractYears(new Date(),18)}
                  value={formAll.fnacimiento_icon.texto}
                  onChange={(e)=>onChangeForm(e,'fnacimiento_icon')}
                  isClearable
                  showYearDropdown
                  showMonthDropdown
                  isFullWidth
                  dropdownMode="select"
                  yearDropdownItemNumber={15}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Categoria</Box>
                  </Typography>
                </Box>
                <SelectCategoriaSoftland
                  isInvalid={formAll.categoria_cxe.estadoErr}
                  value={formAll.categoria_cxe.texto}
                  onChange={(e)=>onChangeForm(e.value,'categoria_cxe')}
                />
                {formAll.categoria_cxe.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.categoria_cxe.textoErr}</Typography>
                )}
              </Grid> */}
              {/* <Grid item xs={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>IVA</Box>
                  </Typography>
                </Box>
                <SelectIvaSoftland
                  filter
                  isInvalid={formAll.iva_c.estadoErr}
                  value={formAll.iva_c.texto}
                  onChange={(e)=>onChangeForm(e.value,'iva_c')}
                  disabled={isDni === '96'}
                  tipoDoc={isDni}
                />
                {formAll.iva_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.iva_c.textoErr}</Typography>
                )}
              </Grid> */}
              {/* <Grid item xs={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    * <Box sx={{fontWeight: 'normal',display: 'inline' }}>Canal</Box>
                  </Typography>
                </Box>
                <SelectCanalesSoftland
                  isInvalid={formAll.canalcomu_c.estadoErr}
                  value={formAll.canalcomu_c.texto}
                  onChange={(e)=>onChangeForm(e.value,'canalcomu_c')}
                />
                {formAll.canalcomu_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.canalcomu_c.textoErr}</Typography>
                )}
              </Grid> */}
              {/* <Grid item xs={12} mt={1}>
                <Box display='flex' alignItems='center'>
                    <CallIcon sx={{color: '#000',fontSize: 20}} />
                    <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 0.5}} variant='h5'>Datos de Contacto</Typography>
                </Box>
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Telefono</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  sx={styleInputHeight}
                  autoComplete='off'
                  type='number'
                  value={formAll.celular_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'celular_c')}
                  InputLabelProps={{shrink: formAll.celular_c.texto}}
                  error={formAll.celular_c.estadoErr}
                  helperText={formAll.celular_c.estadoErr && formAll.celular_c.textoErr}
                  InputProps={{ inputProps: { min: 100000000 } }}
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Typography sx={{color: '#000'}}>Telefono</Typography>
                <TextField
                  fullWidth
                  sx={styleInputHeight}
                  autoComplete='off'
                  type='number'
                  value={formAll.telefono_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'telefono_c')}
                  InputLabelProps={{shrink: formAll.telefono_c.texto}}
                  error={formAll.telefono_c.estadoErr}
                  helperText={formAll.telefono_c.estadoErr && formAll.telefono_c.textoErr}
                  InputProps={{ inputProps: { min: 100000000 } }}
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                    {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                    <Box sx={{fontWeight: 'normal',display: 'inline' }}>Email</Box>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  autoComplete='off'
                  sx={styleInputHeight}
                  value={formAll.email_c.texto}
                  onChange={(e)=>onChangeForm(e.target.value,'email_c')}
                  InputLabelProps={{shrink: formAll.email_c.texto}}
                  inputProps={{ readOnly: existeSoftland }}
                  error={formAll.email_c.estadoErr}
                  helperText={formAll.email_c.estadoErr && formAll.email_c.textoErr}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Box display='flex' alignItems='center'>
                    <HomeIcon sx={{color: '#000',fontSize: 24}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 0.5}} variant='h5'>Domicilio de Facturación</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                      {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                      <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad</Box>
                    </Typography>
                </Box>
                <SelectLocalidadesSoftland
                  value={formAll.cpostal_c.texto}
                  isInvalid={formAll.cpostal_c.estadoErr}
                  onChange={(e)=>{
                    onChangeForm(e.value,'cpostal_c')
                    onChangeForm(e.provinciaId,'provincia_c')
                  }}
                />
                {formAll.cpostal_c.estadoErr && (
                  <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.cpostal_c.textoErr}</Typography>
                )}
              </Grid>
              {existeSoftland ?(
                  <Grid item xs={7}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                      </Typography>
                    </Box>
                    <TextField
                      autoComplete='off'
                      fullWidth
                      sx={styleInputHeight}
                      value={formAll.direccion_c.texto}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
              ):(
                <>
                  <Grid item xs={12} md={7}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.barrio_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'barrio_c')}
                      InputLabelProps={{shrink: formAll.barrio_c.texto}}
                      error={formAll.barrio_c.estadoErr}
                      helperText={formAll.barrio_c.estadoErr && formAll.barrio_c.textoErr}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.direccion_c.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'direccion_c')}
                      InputLabelProps={{shrink: formAll.direccion_c.texto}}
                      error={formAll.direccion_c.estadoErr}
                      helperText={formAll.direccion_c.estadoErr && formAll.direccion_c.textoErr}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6} mt={1}>
                {!existeSoftland && (
                  <Box display='flex'>
                    <Typography sx={{color: '#000', opacity: 0.8}}>La dirección de residencia es la misma que la dirección de instalación</Typography>
                    <SwitchCustom value={igualDomicilio} onChange={()=>onMismoDomicilio()} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} md={12} lg={5.9} ml={0.5} mr={0.5} mb={1} mt={{xs: 2, sm: 0}}>
          <Card sx={{background: '#fff !important'}}>
            <Box p={2}>
              <Box display='flex' pt={1}>
                  <LocationOnIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Direccion de instalación</Typography>
              </Box>
              <Grid container mb={1} mt={1} spacing={1}>
                  {/* <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Localidad calendario</Box>
                      </Typography>
                    </Box>
                    <SelectLugaresCalendar
                      value={formAll.lugarId_t.texto}
                      isInvalid={formAll.lugarId_t.estadoErr}
                      onChange={(e)=>{
                        onChangeForm(e.codProvincia,'codprovincia_t')
                        onChangeForm(e.codLocalidad,'codpostal_t')
                        onChangeForm(e.value,'lugarId_t')
                        onChangeForm(e.value,'lugarId_c')
                      }}
                    />
                    {formAll.lugarId_t.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.lugarId_t.textoErr}</Typography>
                    )}
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Barrio</Box>
                      </Typography>
                    </Box>
                    <Typography sx={{color: '#000'}}> </Typography>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.barrio_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'barrio_t')}
                      InputLabelProps={{shrink: formAll.barrio_t.texto}}
                      error={formAll.barrio_t.estadoErr}
                      helperText={formAll.barrio_t.estadoErr && formAll.barrio_t.textoErr}
                    />
                  </Grid>
                  <Grid xs={12} md={5.5} ml={0.6} mt={1}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                        {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                        <Box sx={{fontWeight: 'normal',display: 'inline' }}>Direccion</Box>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      sx={styleInputHeight}
                      value={formAll.calle_t.texto}
                      onChange={(e)=>onChangeForm(e.target.value,'calle_t')}
                      InputLabelProps={{shrink: formAll.calle_t.texto}}
                      error={formAll.calle_t.estadoErr}
                      helperText={formAll.calle_t.estadoErr && formAll.calle_t.textoErr}
                    />
                  </Grid>
                  {/* <Grid xs={12} md={6} mt={0} ml={0.6}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                          {`${tipoRegistro === 'Consulta' ? '*' : ''} `}
                          <Box sx={{fontWeight: 'normal',display: 'inline' }}>Estado de consulta</Box>
                        </Typography>
                    </Box>
                    <SelectEstadoConsulta
                      disabled={(formAll.estadoCliente_c.texto === 'Sin Disponibilidad para la venta' || formAll.estadoCliente_c.texto === 'Sin Cobertura') && coordenadas?.texto?.length > 0 && !permisos['sinCobertura.nubicom']}
                      isInvalid={formAll.estadoCliente_c.estadoErr}
                      value={formAll.estadoCliente_c.texto}
                      onChange={(e)=>onChangeForm(e.value,'estadoCliente_c')}
                    />
                    {formAll.estadoCliente_c.estadoErr && (
                      <Typography sx={{color: 'red',fontSize:11,marginLeft:1}}>{formAll.estadoCliente_c.textoErr}</Typography>
                    )}
                  </Grid> */}
              </Grid>
              <Box display='flex' pt={1} mb={2}>
                  <ExploreIcon sx={{color: '#000',fontSize: 30}} />
                  <Typography sx={{color: '#000', fontWeight: 'bold',marginLeft: 1,marginTop:0.5,fontSize: 17}}>Mapa</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Typography sx={{color: '#000',fontWeight: 'bold'}} component='div'>
                  {`${tipoRegistro === 'Contacto' ? '*' : ''} `}
                  <Box sx={{fontWeight: 'normal',display: 'inline' }}>Coordenadas</Box>
                </Typography>
              </Box>
              <TextField
                fullWidth
                autoComplete='off'
                sx={styleInputHeight}
                InputLabelProps={{shrink: true}}
                value={coordenadas?.texto}
                error={formAll.latitud_c.estadoErr || coordenadas?.estadoErr}
                helperText={formAll.latitud_c.estadoErr && formAll.latitud_c.textoErr}
                onChange={(e)=>setCoordenadas({texto: e.target.value, estadoErr: false})}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={()=>onSubmitCoordenadas()}>
                      <GpsFixedIcon sx={{color: '#000'}} size={35} />
                    </IconButton>
                  ),
                }}
              />
              {coordenadas?.estadoErr && (
                <Typography sx={{color: 'red',fontSize:10,marginLeft:2}}>No son unas coordenadas validas</Typography>
              )}
              {/* <ToggleButtonGroup
                color="primary"
                value={tipoMapa}
                exclusive
                onChange={(e)=>setTipoMapa(e.target.value)}
                aria-label="Platform"
                sx={{marginTop: 2,marginBottom: 1}}
              >
                <ToggleButton
                  value="radio"
                  sx={{
                    background: '#fff',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                    '&.MuiToggleButton-root':{
                      border:2,
                    },
                    '&.Mui-selected':{
                      background: '#fff',
                      color: '#0364FF',
                      borderColor: '#0364FF',
                      border:2,
                      '&:hover':{
                        background: '#fff',
                        color: '#0364FF',
                        borderColor: '#0364FF',
                        border:2,
                      }
                    },
                    '&:hover':{
                      background: '#fff',
                      color: '#0364FF',
                      borderColor: '#0364FF',
                      border:2,
                    }
                  }}
                >
                  Radio enlace
                </ToggleButton>
                <ToggleButton
                  value="fibra"
                  sx={{
                    background: '#fff',fontWeight: 'bold',color:'gray',borderRadius:2,width: 100, height: 35,borderColor: 'gray',opacity:0.6,textTransform: 'unset !important',
                    '&.MuiToggleButton-root':{
                      border:2,
                    },
                    '&.Mui-selected':{
                      background: '#fff',
                      color: '#0364FF',
                      borderColor: '#0364FF',
                      border:2,
                      '&:hover':{
                        background: '#fff',
                        color: '#0364FF',
                        borderColor: '#0364FF',
                        border:2,
                      }
                    },
                    '&:hover':{
                      background: '#fff',
                      color: '#0364FF',
                      borderColor: '#0364FF',
                      border:2,
                    }
                  }}
                >
                  Fibra optica
                </ToggleButton>
              </ToggleButtonGroup> */}
              {/* <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-between'}}>
                <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 12}}>Ultimo Kmz:</Typography>
                {prefactibilidad?.epmp && (
                  <Typography sx={{color: '#000',fontWeight: 'bold',fontSize: 12}} component='div'>ePMP: <Box fontWeight='normal' display='inline'>{FechaFunction.format(prefactibilidad?.epmp,'dd-MM-yyyy')}</Box></Typography>
                )}
                {prefactibilidad?.pmp450 && (
                  <Typography sx={{color: '#000', fontWeight: 'bold',fontSize: 12}} component='div'>PMP450m: <Box fontWeight='normal' display='inline'>{FechaFunction.format(prefactibilidad?.pmp450,'dd-MM-yyyy')}</Box></Typography>
                )}
              </Box> */}
              {/* {tipoMapa === 'radio' && (
                <MapBase
                  id="mapaComercial"
                  options={{
                      maxZoom: 19,
                      minZoom: 10,
                  }}
                  zoom={15}
                  coordinates={mapLocation}
                  mapContainerStyle={{
                      marginTop: 0,
                      marginBottom: 10,
                      width: '100%',
                      height: 450
                  }}
                  onClick={onClickMap}
                  onSearch={(e)=>{
                      setPrefactibilidadLocation(e.coordenadas)
                      onPrefa(e.coordenadas)
                  }}
                  insertCoord
                >
                  <BoxPrefactibilidad
                      coordinates={prefactibilidadLocation}
                      prefactibilidad={prefactibilidad}
                      isLoading={isLoadingPrefa}
                  />
                </MapBase>
              )} */}
              {tipoMapa === 'fibra' && (
                <MapBase
                  id="mapaComercial"
                  options={{
                      maxZoom: 19,
                      minZoom: 10,
                  }}
                  zoom={15}
                  coordinates={mapLocation}
                  mapContainerStyle={{
                      marginTop: 0,
                      marginBottom: 10,
                      width: '100%',
                      height: 450
                  }}
                  onClick={onClickMap}
                  onSearch={(e)=>{
                      setPrefactibilidadLocation(e.coordenadas)
                      onPrefaFO(e.coordenadas)
                  }}
                  insertCoord
                >
                  <BoxPrefactibilidad
                      coordinates={prefactibilidadLocation}
                      prefactibilidad={prefactibilidad}
                      isLoading={isLoadingPrefa}
                  />
                </MapBase>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Box pr={3} display='flex' justifyContent='end' marginBottom={15}>
        <ButtonBasic
          textButton='Cancelar'
          variant='wk'
          color='downloadHome'
          sx={{border:1, borderColor: '#000'}}
          onClick={()=>navigate(-2)}
        />
        <ButtonBasic
          textButton={tipoRegistro === 'Consulta' ? 'Registrar consulta y salir' : 'Guardar y Salir'}
          variant='wk'
          color='prevStep'
          sx={{border:1,marginLeft: 1}}
          onClick={tipoRegistro === 'Consulta' ? ()=>onSubmitConsulta() : ()=>onSubmitContacto(true)}
        />
        {tipoRegistro === 'Contacto' && (
          <ButtonBasic
            textButton='Continuar'
            iconEnd={<ArrowForwardIcon />}
            isLoading={isLoading}
            onClick={()=>onSubmitContacto()}
            variant='wk'
            color='nextStep'
            sx={{border:1, borderColor: '#fff',marginLeft: 1}}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
  iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
  iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPaso1Screen);