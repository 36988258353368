import {useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import NivelAdmTkService from '../../services/nivelAdmTicket.service';

const SelectNivelAdmTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    subTipoTicket,
    isDisabled,
    emp,
    isMulti
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const nivelAdmTk = useSelector(state => state.default.nivelAdmTk);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    useEffect(() => {
        if(emp){
            NivelAdmTkService.getAll({emp}).then((resp)=>{
                setItems(resp)
            })
        }else{
            setItems(nivelAdmTk);
        }
    }, [nivelAdmTk]);

    useEffect(() => {
        let filtro = items;
        if(subTipoTicket){
            filtro = items.filter((it)=>it?.arrSubTipo?.includes(subTipoTicket))
        }
        const newOptions = filtro.map((item) => {
            return {
                value: item.id_natk,
                label: item.nombre_natk
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue( newOptions.find((opt) => opt.value === Number(value)) );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_natk === va);
                if(item){
                    return {
                        label:item.nombre_natk,
                        value:item.id_natk,
                    };
                }
                return null
            }))
        }else{
            setSelectedValue(null);
        }
    }, [items,subTipoTicket,value])

    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])

    return (
        <Select
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectNivelAdmTicket.defaultProps = {
    isClearable: false
};

export default SelectNivelAdmTicket;
