import {useState, useEffect,useMemo} from 'react';
import Select from 'react-select';
import UserService from '../../services/personas.service';


const SelectUser = ({id, value, onChange, isInvalid, isClearable,grupo, filter,isMulti,...defaultProps}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_p === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_p === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    const actualizar = async() =>{
        const form = {
            type:filter
        }
        if(grupo){
            form.group = grupo;
        }
        await UserService.getAll(form).then((response) => {
            setItems(response);
        })
    }
    useEffect(() => {
        actualizar()
    }, [])

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id_p,
                label: item.nombre_p,
                dni: item.dni_p
            };
        });
        setOptions(newOptions);
    }, [items]);
    useEffect(() => {
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                options.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_p === va);
                if(item){
                    return {
                        value:item.id_p,
                        label:item.nombre_p,
                        dni: item.dni_p
                    };
                }
                return null
            }))
        }else{
            setSelectedValue(null)
        }
    }, [options,value])

    const customStyles = useMemo(() => ({
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#000',
            background: state.isSelected ? '#0364ff' : '#fff',
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#0364ff',
                borderRadius: 5
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#02378c',
                color: '#fff',
            },
        }),
        control: (styles)=>({
            ...styles,
            borderRadius: 7,
            borderColor: 'gray'
        })
    }), [])
    return (
        <Select
            {...defaultProps}
            inputId={id}
            className={`react-select-container ${
                isInvalid ? 'is-invalid' : ''
            }`}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            placeholder="Seleccione..."
            options={options}
            value={selectedValue}
            onChange={onSelectValue}
            isClearable={isClearable}
            isMulti={isMulti}
            menuShouldScrollIntoView
            menuPosition='absolute'
            menuPlacement='auto'
        />
    );
};

SelectUser.defaultProps = {
    isClearable: false
};

export default SelectUser;
