import { Box,Grid } from "@mui/material"
import {FormLogin} from "../../components";

const LoginScreen = () => {
    return (
        <Grid container xs={12} sx={{width: '100%', height: '100vh'}}>
            <Grid item xs={12} md={6} className='bg-white'>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: '100%', height: '100vh'}}>
                    <FormLogin />
                </Box>
            </Grid>
            <Grid item xs={0} md={6}>
                <Box
                    sx={{
                        backgroundImage: 'url(/img/logoFondo.png)',
                        width: '100%',
                        height: '99vh',
                        backgroundSize:'cover',
                        backgroundRepeat:'no-repeat',
                        position: 'relative',
                        display: 'inline-block',
                        overflow: 'hidden',
                        backgroundPosition: 'center'
                    }}
                >
                    <img
                        src="/img/Zeta-black.png"
                        style={{position: 'absolute', top:'37%',left: '33%', transform:'scale(0.8)'}}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default LoginScreen