import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ButtonBasic, CardNotifications, CardNotificationsMentions, CardNotifyGral } from '../../components';
import MenuOptionsNotificaciones from '../../components/menu/MenuOptionsNotificaciones';
import { useSelector } from 'react-redux';
import DialogTitleDefault from '../default/DialogTitleDefault';
import FilterListIcon from '@mui/icons-material/FilterList';
import { showModal } from 'react-redux-modal-provider';
import ModalNotifyFilter from './ModalNotifyFilter';
import NotifyService from '../../services/notify.service';

const ModalNotificacionesZeta = ({show, hideModal, item, onGuardar}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notifyTkArr, setNotifyTkArr] = useState([]);
    const [notifyMentionArr, setNotifyMentionArr] = useState([]);
    const [notifyGralArr, setNotifyGralArr] = useState([]);
    const mentions = useSelector(state => state.default.mentions);
    const notifications = useSelector(state => state.default.notifiTk);
    const notifyGral = useSelector(state => state.default.notifyGral);
    const handleClose = () => hideModal();

    const [totalNotify, setTotalNotify] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [maxPage, setMaxPage] = useState(1)
    const [totalPage, setTotalPage] = useState({
        ticket: 1,
        etiqueta: 1,
        gral: 1
    })
    const [filtro, setFiltro] = useState({
        etiquetas: true,
        ticket: true,
        gral: true,
        soloNoVistas: false
    });
    const onOpenModalFiltro = () => {
        showModal(ModalNotifyFilter,{filtro: filtro,setFiltro: setFiltro})
    }

    useEffect(() => {
        if(Array.isArray(notifications?.content) && notifications?.content?.length > 0){
            setNotifyTkArr(notifications.content)
        }else{
            setNotifyTkArr([])
        }
    }, [])


    useEffect(() => {
        if(Array.isArray(mentions?.content) && mentions?.content?.length > 0){
            setNotifyMentionArr(mentions.content)
        }else{
            setNotifyMentionArr([])
        }
    }, [])
    useEffect(() => {
        if(Array.isArray(notifyGral?.content) && notifyGral?.content?.length > 0){
            setNotifyGralArr(notifyGral.content)
        }else{
            setNotifyGralArr([])
        }
    }, [])
    const onSubmit = async() => {
        let bandera = false;
        setIsLoading(true)
        if( totalPage.ticket > (maxPage + 1) ){
            await NotifyService.getAll({type: 'ticket',page: maxPage + 1 }).then((resp)=>{
                bandera = true;
                if(Array.isArray(resp?.content) && resp?.content?.length > 0){
                    setNotifyTkArr(oldArray => [...oldArray, ...resp.content])
                }
            })
        }
        if( totalPage.etiqueta > (maxPage + 1) ){
            await NotifyService.getAll({type: 'mention',page: maxPage + 1 }).then((resp)=>{
                bandera = true;
                if(Array.isArray(resp?.content) && resp?.content?.length > 0){
                    setNotifyMentionArr(oldArray => [...oldArray, ...resp.content])
                }
            })
        }
        if( totalPage.ticket > (maxPage + 1) ){
            await NotifyService.getAll({type: 'gral',page: maxPage + 1 }).then((resp)=>{
                bandera = true;
                if(Array.isArray(resp?.content) && resp?.content?.length > 0){
                    setNotifyGralArr(oldArray => [...oldArray, ...resp.content])
                }
            })
        }
        setIsLoading(false)
        setMaxPage(maxPage + 1)
        if(!bandera){
            setHasMore(false);
        }
    }
    useEffect(() => {
        let arrayTotal = [];
        if(filtro?.ticket && Array.isArray(notifyTkArr) && notifyTkArr?.length > 0){
            if(filtro.soloNoVistas){
                const filtroNoView = notifyTkArr.filter((ite)=> !ite?.visto_notf);
                const nuevo = arrayTotal.concat(filtroNoView);
                arrayTotal= nuevo;
            }else{
                const nuevo = arrayTotal.concat(notifyTkArr);
                arrayTotal= nuevo;
            }
        }
        if(filtro?.etiquetas && Array.isArray(notifyMentionArr) && notifyMentionArr?.length > 0){
            if(filtro.soloNoVistas){
                const filtroNoView = notifyMentionArr.filter((ite)=> !ite?.visto_ment);
                const nuevo = arrayTotal.concat(filtroNoView);
                arrayTotal= nuevo;
            }else{
                const nuevo = arrayTotal.concat(notifyMentionArr);
                arrayTotal= nuevo;
            }
        }
        if(filtro?.gral && Array.isArray(notifyGralArr) && notifyGralArr?.length > 0){
            if(filtro.soloNoVistas){
                const filtroNoView = notifyGralArr.filter((ite)=> !ite?.visto_notfg);
                const nuevo = arrayTotal.concat(filtroNoView);
                arrayTotal= nuevo;
            }else{
                const nuevo = arrayTotal.concat(notifyGralArr);
                arrayTotal= nuevo;
            }
        }
        if(arrayTotal?.length > 0){
            arrayTotal.sort(function (a, b) {
                if (a.createdAt < b.createdAt) return 1;
                if (a.createdAt > b.createdAt) return -1;
                return 0;
            });
            setTotalNotify(arrayTotal);
        }else{
            setTotalNotify([]);
        }
    }, [filtro,notifyTkArr, notifyMentionArr, notifyGralArr])

    useEffect(() => {
        let maximoTk = 1;
        let maximoEtiqueta = 1;
        let maximoGral = 1;
        if(notifications?.totalPages > 1){
            setHasMore(true);
            maximoTk = notifications?.totalPages;
        }
        if(mentions?.totalPages > 1){
            setHasMore(true);
            maximoEtiqueta = mentions?.totalPages;
        }
        if(notifyGral?.totalPages > 1){
            setHasMore(true);
            maximoGral = notifyGral?.totalPages;
        }
        setTotalPage({
            ticket: maximoTk,
            etiqueta: maximoEtiqueta,
            gral: maximoGral
        });
    }, [])
    useEffect(() => {
        if(totalPage?.etiqueta >= maxPage + 1 || totalPage?.gral >= maxPage + 1 || totalPage?.ticket >= maxPage + 1 ){
            setHasMore(true)
        }else{
            setHasMore(false)
        }
    }, [totalPage,maxPage])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='sm' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Notificaciones Zeta
                    </Typography>
                    <Divider sx={{background: 'gray',mt: 1}} />
                    <Grid container spacing={1}>
                        <Grid item xs={6} mt={1}>
                            <ButtonBasic
                                textButton='Filtro'
                                variant='wk'
                                onClick={()=>onOpenModalFiltro()}
                                color='nextWhite'
                                iconStart={<FilterListIcon />}
                                sx={{border: 1, borderColor: '#0364ff',width: 150}}
                            />
                        </Grid>
                        <Grid item xs={6} display='flex' justifyContent='center' alignItems='center'>
                            <MenuOptionsNotificaciones />
                        </Grid>
                    </Grid>
                </DialogTitleDefault>
                <DialogContent sx={{height: '67vh',marginTop: -1}} className='side'>
                    <Grid container spacing={1}>
                        {totalNotify?.length > 0 && (
                            totalNotify.map((item)=>{
                                if(item?.id_ment){
                                    return (
                                        <Grid item xs={12}>
                                            <CardNotificationsMentions item={item} onCloseModal={handleClose} />
                                        </Grid>
                                    )
                                }
                                if(item?.id_notf){
                                    return (
                                        <Grid item xs={12}>
                                            <CardNotifications item={item} onCloseModal={handleClose} />
                                        </Grid>
                                    )
                                }
                                return (
                                    <Grid item xs={12}>
                                        <CardNotifyGral item={item} onCloseModal={handleClose} />
                                    </Grid>
                                )
                            })
                        )}
                        { hasMore && (
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <ButtonBasic
                                    textButton='Cargar más'
                                    variant='wk'
                                    onClick={()=>onSubmit()}
                                    color='nextStep'
                                    sx={{border: 1, borderColor: '#0364ff',width: 200}}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cerrar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                        sx={{width: 200}}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNotificacionesZeta