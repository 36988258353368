import React, {useEffect, useState} from 'react';
import { Circle, Marker } from '@react-google-maps/api';

function MarkerFibraCliente({
    item,
    rangoBd,
    ...defaultProps
}) {
    const [rangoRadio, setRangoRadio] = useState(80)
    const [center, setCenter] = useState({
        lat: -24.806998,
        lng: -65.416439
    })
    useEffect(() => {
        if(rangoBd){
            setRangoRadio(rangoBd || 80)
        }
    }, [rangoBd])

    const options = {
    strokeColor: '#2ECC71',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#2ECC71',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: rangoRadio,
    zIndex: 1
    }

    useEffect(() => {
        if(item?.lat && item?.lng){
            setCenter({
                lat: Number(item.lat),
                lng: Number(item.lng),
            })
        }
    }, [item])

    return (
        <>
            <Circle
                center={center}
                options={options}
                {...defaultProps}
            />
            <Marker
            {...defaultProps}
            position={center}
            icon={{
                url:'/img/location_home.svg',
                scaledSize: {
                    width:50,
                    height:50,
                }
            }}
            ></Marker>
        </>
    );
}

export default React.memo(MarkerFibraCliente);
